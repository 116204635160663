import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { Rating } from "models/new/Rating";
import { useWindowSize } from "hooks/useWindowSize";
import PictureBoxComponent from "./PictureBoxComponent";

interface RatingProps {
  rating: Rating;
}

const RatingComponent: React.FC<RatingProps> = (props) => {
  const { isMobile } = useWindowSize();

  const showStars = (): JSX.Element => {
    return (
      <div className="d-flex fd-r ai-e-flex jc-s-flex">
        {[1, 2, 3, 4, 5].map((i) => {
          if (props.rating.rate >= i) return <Icon.StarFillSVG className="c-yellow" />;
          else if (props.rating.rate < i && props.rating.rate > i - 1) return <Icon.StarHalfSVG className="c-yellow" />;
          else return <Icon.StarSVG className="c-yellow" />;
        })}
        <Label fontSize="sm" fontStyle="semibold" wrap content={props.rating.rate.toString()} />
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c pad-16 gap-16 rounded-12 border" style={{ height: "100%", boxSizing: "border-box" }}>
        <div className="d-flex fd-r ai-center gap-8">
          {props.rating.reviewer?.photoUrl ? (
            <PictureBoxComponent className="picture-50" url={props.rating.reviewer.photoUrl} />
          ) : (
            <Icon.PersonSVG className="icon-color picture-50" />
          )}
          <div className="d-flex fd-c gap-4">
            <Label
              className="pad-l-4"
              fontSize="sm"
              fontStyle="bold"
              content={`${props.rating.reviewer?.firstName} ${props.rating.reviewer?.lastName}`}
            />
            <Label
              className="pad-l-4"
              fontSize="sml"
              fontStyle="regular"
              content={new Date(props.rating.date).toFormat("dd.MM.yyyy hh:mm")}
            />
          </div>
        </div>
        <div className="d-flex fd-c ai-s-flex gap-16">
          {showStars()}
          <Label fontSize="sm" fontStyle="semibold" wrap content={props.rating.comment} />
        </div>
      </div>
    );
  }

  return (
    <div className="width-fill d-flex fd-c pad-16 gap-16 rounded-12 border">
      <div className="d-flex fd-r ai-center gap-8">
        {props.rating.reviewer?.photoUrl ? (
          <PictureBoxComponent className="picture-50" url={props.rating.reviewer.photoUrl} />
        ) : (
          <Icon.PersonSVG className="icon-color picture-50" />
        )}
        <div className="d-flex fd-c gap-4">
          <Label
            className="pad-l-4"
            fontSize="sm"
            fontStyle="bold"
            content={`${props.rating.reviewer?.firstName} ${props.rating.reviewer?.lastName}`}
          />
          <Label
            className="pad-l-4"
            fontSize="sml"
            fontStyle="regular"
            content={new Date(props.rating.date).toFormat("dd.MM.yyyy hh:mm")}
          />
        </div>
      </div>
      <div className="d-flex fd-c ai-s-flex gap-16">
        {showStars()}
        <Label fontSize="sm" fontStyle="semibold" wrap content={props.rating.comment} />
      </div>
    </div>
  );
};

export default RatingComponent;
