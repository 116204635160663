import React from "react";
import * as SupplierApi from "api/SupplierApi";
import * as GoogleMaps from "commons/googleManager";
import { useDispatch, useSelector } from "react-redux";
import { changeSearchState, clearSearched } from "reducers/searchReducer";
import { MainState, SearchState } from "models/new/State";
import { Response } from "models/new/Response";
import { useWindowSize } from "hooks/useWindowSize";
import { SearchProductFilter } from "models/new/SearchProductFilter";
import SearchDesktopManagerComponent from "./components/SearchDesktopManagerComponent";
import SearchMobileManagerComponent from "./components/mobile/SearchMobileManagerComponent";

const SearchPage: React.FC = () => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const searchState: SearchState = useSelector((state: any) => state.search);
  const mainState: MainState = useSelector((state: any) => state.main);

  React.useEffect(() => {
    document.title = "Ogrosa | Szukaj produktu";
    return () => {
      dispatch(clearSearched());
    };
  }, []);

  React.useEffect(() => {
    dispatch(clearSearched());
    const timeout = setTimeout(() => {
      if (!searchState.geoLocationState) {
        GoogleMaps.getGeoLocation()
          .then((value) => {
            GoogleMaps.searchByLoc(value.lat, value.lng)
              .then((addresses) => {
                const name = GoogleMaps.getStringAddress(addresses[0].address_components);
                localStorage.setItem("geoLocation", JSON.stringify({ lat: value.lat, lng: value.lng, name: name }));
                dispatch(
                  changeSearchState({
                    ...searchState,
                    geoLocationState: true,
                    geoLocation: { lat: value.lat, lng: value.lng, name: name },
                    mapCenter: { lat: value.lat, lng: value.lng, name: name },
                  })
                );
              })
              .catch((err) => alert(err));
          })
          .catch((err) => {
            const geoLocation = localStorage.getItem("geoLocation");
            if (geoLocation) {
              dispatch(
                changeSearchState({
                  ...searchState,
                  geoLocationState: true,
                  geoLocation: JSON.parse(geoLocation),
                  mapCenter: JSON.parse(geoLocation),
                })
              );
            }
          });
      }
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchState.filters, searchState.geoLocation]);

  const downloadData = async () => {
    dispatch(changeSearchState({ isLoading: true }));
    let searchProductFilter: SearchProductFilter | undefined = undefined;

    if (searchState.geoLocationState) {
      const bounds = GoogleMaps.getBoundsForPoint(searchState.geoLocation, searchState.filters.distance);
      searchProductFilter = {
        locLat: searchState.geoLocation.lat,
        locLng: searchState.geoLocation.lng,
        latMin: bounds.latLo,
        latMax: bounds.latHi,
        lngMin: bounds.lngLo,
        lngMax: bounds.lngHi,
        zoom: 10,
        phrase: searchState.filters.phrase,
        distance: searchState.filters.distance,
        promotion: searchState.filters.promotion,
        sort: searchState.filters.sort,
        priceFrom: searchState.filters.priceFrom,
        priceTo: searchState.filters.priceTo,
        stars: searchState.filters.stars,
        take: searchState.filters.take,
        categoryId: searchState.filters.categoryId,
      };
    } else if (mainState.mapBounds) {
      searchProductFilter = {
        locLat: searchState.geoLocation.lat,
        locLng: searchState.geoLocation.lng,
        latMin: mainState.mapBounds.latLo,
        latMax: mainState.mapBounds.latHi,
        lngMin: mainState.mapBounds.lngLo,
        lngMax: mainState.mapBounds.lngHi,
        zoom: mainState.mapBounds.zoom,
        phrase: searchState.filters.phrase,
        distance: searchState.filters.distance,
        promotion: searchState.filters.promotion,
        sort: searchState.filters.sort,
        priceFrom: searchState.filters.priceFrom,
        priceTo: searchState.filters.priceTo,
        stars: searchState.filters.stars,
        take: searchState.filters.take,
        categoryId: searchState.filters.categoryId,
      };
    }

    if (!searchProductFilter) return;

    await SupplierApi.getSuppliers(searchProductFilter)
      .then((values) => {
        dispatch(changeSearchState({ suppliersInArea: values, isLoading: false }));
      })
      .catch((err: Response) => {
        console.log(`SearchManagerComponent - ${err}`);
      });
  };

  return (
    <div className="f-fill">{isMobile ? <SearchMobileManagerComponent /> : <SearchDesktopManagerComponent />}</div>
  );
};

export default SearchPage;
