import { createAction, createReducer } from "@reduxjs/toolkit";
import { BasketState, BasketValideError } from "../models/new/State";
import { Supplier } from "models/new/Supplier";
import { OrderProduct } from "models/new/OrderProduct";
import { OrderAddress } from "models/new/OrderAddress";
import { OrderCreateDto } from "models/DTO/OrderCreateDto";

const basketValideError: BasketValideError = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  deliveryType: "",
  pickUpDate: "",
  paymentType: "",
  buildingnumber: "",
  city: "",
  postalcode: "",
  street: "",
  country: "",
};

const initialState = { baskets: [], valideError: basketValideError } as BasketState;

export const setBaskets = createAction<OrderCreateDto[]>("basket/setBaskets");
export const addProductToBasket = createAction<{ orderProducts: OrderProduct; dealer: Supplier }>(
  "basket/addProductToBasket"
);
export const updateProductInBasket = createAction<OrderProduct>("basket/updateProductInBasket");
export const removeProductFromBasket = createAction<OrderProduct>("basket/removeProductFromBasket");
export const changePriceProductInBasket = createAction<{
  productId: string;
  price: number;
  salePrice?: number;
  error?: string;
}>("basket/changePriceProductInBasket");
export const deleteBaskets = createAction("basket/deleteBaskets");
export const deleteBasket = createAction<string>("basket/deleteBasket");

export const changeSummaryBasket = createAction<{
  basket: Partial<OrderCreateDto> | undefined;
  errors?: Partial<BasketValideError>;
}>("basket/changeSummaryBasket");
export const setBasketValideError = createAction<BasketValideError>("basket/setBasketValideError");
export const clearBasketValideError = createAction("basket/clearBasketValideError");
export const clearSummaryBasket = createAction("basket/clearSummaryBasket");
export const setSummaryBasketAddress = createAction<{
  address: Partial<OrderAddress> | undefined;
  errors?: Partial<BasketValideError>;
}>("basket/setSummaryBasketAddress");

export const basketReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setBaskets, (state, action) => {
      state.baskets = action.payload;
    })
    .addCase(addProductToBasket, (state, action) => {
      if (state.baskets === undefined) {
        state.baskets = [];
      }
      state.lastAddedProduct = action.payload.orderProducts;
      const find = state.baskets?.find((x) => x.supplier?.id === action.payload.dealer.id);
      if (find) {
        const findProduct = find.products.find((x) => x.productId === action.payload.orderProducts.productId);
        if (findProduct) {
          const qty = Math.max(0, action.payload.orderProducts.quantity + findProduct.quantity);
          findProduct.quantity = qty;
        } else {
          find.products.push(action.payload.orderProducts);
        }
      } else {
        state.baskets?.push({
          supplierId: action.payload.dealer.id,
          supplier: action.payload.dealer,
          products: [action.payload.orderProducts],
          pickUpDate: undefined,
          paymentType: undefined,
          deliveryType: undefined,
        });
      }
    })
    .addCase(updateProductInBasket, (state, action) => {
      if (state.baskets === undefined) {
        state.baskets = [];
      }
      state.baskets = state.baskets?.map((x) => {
        if (x.products.find((x) => x.productId === action.payload.productId)) {
          return {
            ...x,
            products: x.products.map((z) =>
              z.productId === action.payload.productId
                ? { ...z, quantity: action.payload.quantity, error: action.payload.error }
                : z
            ),
          };
        } else return x;
      });

      if (state.summary) {
        state.summary = {
          ...state.summary,
          products: state.summary.products.map((x) =>
            x.productId === action.payload.productId
              ? { ...x, quantity: action.payload.quantity, error: action.payload.error }
              : x
          ),
        };
      }
    })
    .addCase(removeProductFromBasket, (state, action) => {
      if (state.baskets) {
        state.baskets = state.baskets?.map((x) => {
          if (x.products.find((x) => x.productId === action.payload.productId)) {
            return { ...x, products: x.products.filter((z) => z.productId !== action.payload.productId) };
          } else return x;
        });
        state.baskets = state.baskets.filter((x) => x.products.length > 0);
      }
      if (state.summary) {
        state.summary.products = state.summary.products.filter((x) => x.productId !== action.payload.productId);
      }
    })
    .addCase(changePriceProductInBasket, (state, action) => {
      if (state.baskets) {
        state.baskets = state.baskets?.map((x) => {
          if (x.products.find((x) => x.productId === action.payload.productId)) {
            const products = x.products.map((z) =>
              z.productId === action.payload.productId
                ? ({
                    ...z,
                    price: action.payload.price,
                    salePrice: action.payload.salePrice,
                    error: action.payload.error,
                  } as OrderProduct)
                : z
            );
            return { ...x, products: products };
          } else return x;
        });
      }
    })
    .addCase(deleteBaskets, (state) => {
      state.baskets = [];
    })
    .addCase(deleteBasket, (state, action) => {
      state.baskets = state.baskets.filter((x) => x.supplierId !== action.payload);
    })
    .addCase(changeSummaryBasket, (state, action) => {
      state.summary = { ...state.summary!, ...action.payload.basket };
      state.valideError = { ...state.valideError!, ...action.payload.errors };
    })
    .addCase(setBasketValideError, (state, action) => {
      state.valideError = action.payload;
    })
    .addCase(clearBasketValideError, (state) => {
      state.valideError = basketValideError;
    })
    .addCase(clearSummaryBasket, (state) => {
      state.summary = undefined;
    })
    .addCase(setSummaryBasketAddress, (state, action) => {
      state.summary!.address = { ...state.summary?.address, ...action.payload.address };
      state.valideError = { ...state.valideError!, ...action.payload.errors };
    });
});
