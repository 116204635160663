import React from "react";
import * as Icon from "commons/iconManager";
import EmptyComponent from "components/EmptyComponent";
import SalesMobileItemComponent from "./SalesMobileItemComponent";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { SaleState } from "models/new/State";
import { changeMainState } from "reducers/mainReducer";
import { InputDate } from "controls/InputDate";
import { changeSaleFilter } from "reducers/saleReducer";
import { DeliveryType, GroupByDate, StatusOrder } from "models/new/Dictionaries";
import { PopUp } from "controls/PopUp";
import { Label } from "controls/Label";
import { groupByDate } from "commons/listManager";
import { Sale } from "models/new/Sale";
import { startOfWeek, format, endOfWeek } from "date-fns";
import InfoBox from "controls/InfoBox";
import SalesMobileSummayComponent from "./SalesMobileSummayComponent";
import { LazyLoadComponent } from "components/LazyLoadComponent ";

const SalesMobilePage: React.FC = () => {
  const saleState: SaleState = useSelector((state: any) => state.sale);
  const dispatch = useDispatch();

  const btnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const checkFilter = (): number => {
    let result = 0;
    if (saleState.filter.statusOrder !== StatusOrder.All) result++;
    if (saleState.filter.deliveryType !== DeliveryType.All) result++;
    return result;
  };

  const getIcon = (groupBy: GroupByDate) => {
    switch (groupBy) {
      case GroupByDate.Day:
        return <Icon.TodaySVG />;
      case GroupByDate.Week:
        return <Icon.DateRangeSVG />;
      case GroupByDate.Month:
        return <Icon.CalendarMonthSVG />;
      case GroupByDate.All:
        return <Icon.CalendarEmptySVG />;
    }
  };

  const groupBy = async () => {
    PopUp.show(
      btnRef.current,
      <div className="d-flex fd-c width-fill">
        <Label className="mag-b-16" fontSize="sm" fontStyle="bold" content="Grupuj zamówienia" />
        {groupByDate.map((item) => {
          return (
            <Button
              style={{ width: "100%", marginBlock: "2px" }}
              sizeFrame="lg"
              kind={saleState.filter.groupByDate === item.value ? "menu-seleceted" : "menu"}
              label={item.name}
              icon={getIcon(item.value)}
              onClick={() => {
                dispatch(changeSaleFilter({ groupByDate: item.value }));
                PopUp.onHide();
              }}
            />
          );
        })}
      </div>
    );
  };

  function groupByDay(): Record<string, Sale[]> {
    return saleState.sales.reduce((groups, sale) => {
      const dateKey = new Date(sale.createdDate).toFormatMoment("DD MMMM YYYY");
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(sale);
      return groups;
    }, {} as Record<string, Sale[]>);
  };

  function groupByWeek(): Record<string, Sale[]> {
    return saleState.sales.reduce((groups, sale) => {
      const weekStart = startOfWeek(new Date(sale.createdDate), { weekStartsOn: 1 });
      const weekEnd = endOfWeek(new Date(sale.createdDate), { weekStartsOn: 1 });
      const dateKey = `Od ${weekStart.toFormatMoment("DD MMMM YYYY")} do ${weekEnd.toFormatMoment("DD MMMM YYYY")}`;
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(sale);
      return groups;
    }, {} as Record<string, Sale[]>);
  };

  function groupByMonth(): Record<string, Sale[]> {
    return saleState.sales.reduce((groups, sale) => {
      const dateKey = new Date(sale.createdDate).toFormatMoment("MMMM YYYY");
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(sale);
      return groups;
    }, {} as Record<string, Sale[]>);
  };

  const getGroupBy = () => {
    return saleState.filter.groupByDate === GroupByDate.Day
      ? groupByDay()
      : saleState.filter.groupByDate === GroupByDate.Week
      ? groupByWeek()
      : groupByMonth();
  };

  const showSummary = async (key: string, sales: Sale[]) => {
    PopUp.show(btnRef.current, <SalesMobileSummayComponent title={key} sales={sales} />);
  };

  const showGroup = () => {
    const group = getGroupBy();

    return Object.keys(group).map((item) => {
      return (
        <>
          <LazyLoadComponent>
            <div className="d-flex ai-center jc-sb width-fill pad-t-16">
              <Label className="pad-t-8 pad-b-8" fontSize="sml" fontStyle="bold" content={item} />
              <Button
                sizeFrame="sm"
                kind="text"
                icon={<Icon.MoreHorizSVG />}
                onClick={() => showSummary(item, group[item])}
              />
            </div>
            <InfoBox className="width-fill" backgroundColor="secondary">
              <div className="d-flex fd-c ai-center width-fill">
                {group[item].map((val, idx) => {
                  return (
                    <SalesMobileItemComponent
                      sales={val}
                      className={`rounded-0 ${group[item].length - 1 !== idx ? "border-bottom " : ""}`}
                    />
                  );
                })}
              </div>
            </InfoBox>
          </LazyLoadComponent>
        </>
      );
    });
  };

  return (
    <>
      <div id="top-page" className="f-page-top">
        <div className="f-scroll-hidden-r gap-8">
          <InputDate
            className="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            rangeValue={{ from: saleState.filter.dateFrom, to: saleState.filter.dateTo }}
            onChangeRange={(e) => dispatch(changeSaleFilter({ ...saleState.filter, dateFrom: e.from, dateTo: e.to }))}
          />
          <Button
            ref={btnRef}
            id="group-button"
            sizeFrame="md"
            kind={"tertiary"}
            icon={<Icon.EventListSVG />}
            onClick={() => groupBy()}
            style={{ marginLeft: "auto" }}
          />
          <Button
            id="filter-button"
            sizeFrame="md"
            kind={"tertiary"}
            icon={<Icon.TuneSVG />}
            count={checkFilter()}
            onClick={() => dispatch(changeMainState({ showFilterSale: true }))}
            style={{ marginLeft: "auto" }}
          />
        </div>
      </div>
      <div className="f-page-center fd-c mag-b-16">
        {saleState.sales && saleState.sales?.length > 0 ? (
          <>
            <Label
              className="pad-t-8 pad-b-8"
              fontSize="sml"
              fontStyle="bold"
              style={{ marginLeft: "auto" }}
              content={`Ilość wszystkich zamówień: ${saleState.sales.length ?? 0}`}
            />
            {showGroup()}
          </>
        ) : (
          <EmptyComponent style={{ height: "50%" }} icon={<Icon.DescriptionSVG />} message="Brak zamówień" />
        )}
      </div>
    </>
  );
};

export default SalesMobilePage;
