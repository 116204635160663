import React from "react";
import { Label } from "controls/Label";
import { ProfileTabProps } from "./ProfileManagerComponent";
import InfoBox from "controls/InfoBox";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { ModalDialog } from "controls/ModalDialog";
import SubscriptionWizardComponent from "./SubscriptionWizardComponent";
import { changeSubscriptionWizard, initialError } from "reducers/subscriptionWizardReducer";
import { generateGUID } from "commons/stringManager";
import { useWindowSize } from "hooks/useWindowSize";
import { useLogin } from "hooks/useLogin";
import { changeMainState } from "reducers/mainReducer";

const ProfileSubscriptionComponent: React.FC<ProfileTabProps> = (props) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { reLogin } = useLogin();

  const showWizzard = async () => {
    dispatch(
      changeSubscriptionWizard({
        appUserId: userState.user?.id,
        supplierId: userState.supplier?.id ?? generateGUID(),
        subscriptionType: undefined,
        firstName: userState.user?.firstName,
        lastName: userState.user?.lastName,
        email: userState.user?.email,
        phoneNumber: userState.user?.phoneNumber,
        companyName: userState.supplier?.companyName,
        address: userState.supplier?.addresses.find((x) => x.isMainAddress),
        arimr: userState.supplier?.arimr,
        rhd: userState.supplier?.rhd,
        pictures: userState.supplier?.pictures,
        description: userState.supplier?.description,
        certificates: userState.supplier?.certificates,
        valideError: initialError,
      })
    );
    if (isMobile) {
      dispatch(changeMainState({ showSubscriptionWizzard: true }));
    } else {
      await ModalDialog.show(<SubscriptionWizardComponent />, "Aktywacja subskrypcji");
    }
    reLogin();
  };

  const showRefresh = async () => {
    alert("Funkcjionalość w budowie");
  };

  if (isMobile) {
    return (
      <div id={props.id} className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill">
        <Label fontStyle="bold" fontSize="md" content="Subskrypcja" />
        {userState.supplier?.subscription ? (
          <InfoBox className="width-fill ai-center" sizeFrame="sm" backgroundColor="green">
            <div className="width-fill d-flex fd-c gap-8 ai-s-flex ">
              <Label wrap fontSize="lg" fontStyle="bold" fontColor="black" content="Masz aktualna subskrybcje" />
              <Label
                wrap
                fontStyle="bold"
                fontColor="green"
                content={`ważność subskrypcji do ${new Date(userState.supplier.subscription.dateTo).toFormatMoment(
                  "DD.MM.YYYY"
                )}`}
              />
              <Button
                className="width-fill"
                sizeFrame="sm"
                kind="primary"
                label="Przedłuż teraz!"
                fontSize="md"
                fontStyle="bold"
                fontColor="black"
                onClick={showRefresh}
              />
            </div>
          </InfoBox>
        ) : userState.user?.isSupplier ? (
          <InfoBox className="width-fill" sizeFrame="sm" backgroundColor="green">
            <div className="width-fill d-flex fd-c gap-16 ai-s-flex ">
              <Label wrap fontSize="lg" fontStyle="bold" fontColor="black" content="Twoja subskrypcja wygasła" />
              <Label
                wrap
                fontSize="sm"
                fontStyle="semibold"
                fontColor="black"
                content="Twoje ogłoszenia produktów nie są teraz widoczne na naszym sklepie."
              />
              <Button
                className="width-fill"
                sizeFrame="sm"
                kind="primary"
                label="Przedłuż teraz!"
                fontSize="md"
                fontStyle="bold"
                fontColor="black"
                onClick={showRefresh}
              />
            </div>
          </InfoBox>
        ) : (
          <InfoBox className="width-fill" sizeFrame="sm" backgroundColor="green">
            <div className="width-fill d-flex fd-c gap-16 ai-s-flex ">
              <Label
                wrap
                fontSize="sm"
                fontStyle="semibold"
                fontColor="black"
                content="Rozwiń swój biznes z naszą siecią sprzedaży warzyw i owoców!"
              />
              <Label wrap fontSize="lg" fontStyle="bold" fontColor="black" content="Rozpocznij darmowe 6 miesięcy" />
              <Label
                wrap
                fontSize="sm"
                fontStyle="semibold"
                fontColor="black"
                content="Czy jesteś gotowy na poszerzenie swojego biznesu i dotarcie do nowych klientów? Myślimy, że tak! Zapraszamy do dołączenia do naszej subskrypcji jako sprzedawca warzyw i owoców. Współpracując z nami, zyskasz dostęp do szerokiej bazy klientów, którzy cenią sobie zdrowe, ekologiczne produkty prosto od zaufanych dostawców."
              />
              <Button
                className="width-fill"
                sizeFrame="sm"
                kind="primary"
                label="Dołącz teraz!"
                fontSize="md"
                fontStyle="bold"
                fontColor="black"
                onClick={showWizzard}
              />
            </div>
          </InfoBox>
        )}
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Subskrypcja" />
      {userState.supplier?.subscription ? (
        <InfoBox className="width-fill ai-center" sizeFrame="sm" backgroundColor="green">
          <div className="width-fill d-flex fd-c gap-8 ai-s-flex ">
            <Label wrap fontSize="xl" fontStyle="bold" fontColor="black" content="Masz aktualna subskrybcje" />
            <Label
              wrap
              fontStyle="bold"
              fontColor="green"
              content={`ważność subskrypcji do ${new Date(userState.supplier.subscription.dateTo).toFormatMoment(
                "DD.MM.YYYY"
              )}`}
            />
            <Button
              className="width-fill"
              sizeFrame="sm"
              kind="primary"
              label="Przedłuż teraz!"
              fontSize="md"
              fontStyle="bold"
              fontColor="black"
              onClick={showRefresh}
            />
          </div>
        </InfoBox>
      ) : userState.user?.isSupplier ? (
        <InfoBox className="width-fill" sizeFrame="sm" backgroundColor="green">
          <div className="width-fill d-flex fd-c gap-16 ai-s-flex ">
            <Label wrap fontSize="xl" fontStyle="bold" fontColor="black" content="Twoja subskrypcja wygasła" />
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              fontColor="black"
              content="Twoje ogłoszenia produktów nie są teraz widoczne na naszym sklepie."
            />
            <Button
              className="width-fill"
              sizeFrame="sm"
              kind="primary"
              label="Przedłuż teraz!"
              fontSize="md"
              fontStyle="bold"
              fontColor="black"
              onClick={showRefresh}
            />
          </div>
        </InfoBox>
      ) : (
        <InfoBox className="width-fill" sizeFrame="sm" backgroundColor="green">
          <div className="width-fill d-flex fd-c gap-16 ai-s-flex ">
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              fontColor="black"
              content="Rozwiń swój biznes z naszą siecią sprzedaży warzyw i owoców!"
            />
            <Label wrap fontSize="xl" fontStyle="bold" fontColor="black" content="Rozpocznij darmowe 6 miesięcy" />
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              fontColor="black"
              content="Czy jesteś gotowy na poszerzenie swojego biznesu i dotarcie do nowych klientów? Myślimy, że tak! Zapraszamy do dołączenia do naszej subskrypcji jako sprzedawca warzyw i owoców. Współpracując z nami, zyskasz dostęp do szerokiej bazy klientów, którzy cenią sobie zdrowe, ekologiczne produkty prosto od zaufanych dostawców."
            />
            <Button
              className="width-fill"
              sizeFrame="sm"
              kind="primary"
              label="Dołącz teraz!"
              fontSize="md"
              fontStyle="bold"
              fontColor="black"
              onClick={showWizzard}
            />
          </div>
        </InfoBox>
      )}
    </div>
  );
};

export default ProfileSubscriptionComponent;
