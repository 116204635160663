import React from "react";
import * as Icon from "commons/iconManager";
import { Rating } from "models/new/Rating";
import { Label } from "controls/Label";

interface RatingStarProps {
  ratings?: Rating[];
  ratingQty?: number;
}

const RatingCommentComponent: React.FC<RatingStarProps> = (props) => {
  return (
    <div className="d-flex fd-r ai-center gap-4">
      <Icon.CommentSVG className="c-primary " />
      <Label fontSize="sm" fontStyle="semibold" content="Komentarze" />
      <Label
        fontSize="sm"
        fontStyle="regular"
        content={`(${props.ratings?.filter((x) => x.comment !== "").length ?? props.ratingQty ?? 0})`}
      />
    </div>
  );
};

export default RatingCommentComponent;
