import { createAction, createReducer } from "@reduxjs/toolkit";
import { CategoryState } from "models/new/State";

const initialState = { categories: [],  } as CategoryState;

export const changeCategoryState = createAction<Partial<CategoryState>>("category/changeCategoryState");

export const categoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeCategoryState, (state, action) => {
      return { ...state, ...action.payload };
    })
});
