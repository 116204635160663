import React from "react";
import * as Icon from "commons/iconManager";
import InfoBox from "controls/InfoBox";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { GroceryTabProps } from "./GroceryManagerComponent";
import { InputComboBox } from "controls/InputComboBox";
import { EntryDictionary } from "models/new/EntryDictionary";
import { InputNumber } from "controls/InputNumber";
import { useDispatch, useSelector } from "react-redux";
import { ProductState } from "models/new/State";
import { updateProduct } from "reducers/productReducer";
import { InputCheckBox } from "controls/InputCheckBox";
import { InputDate } from "controls/InputDate";
import { ModalDialog } from "controls/ModalDialog";
import { useURLParams } from "hooks/useURLParams";
import { InputText } from "controls/InputText";
import { useWindowSize } from "hooks/useWindowSize";

const jm: EntryDictionary[] = [
  { code: "kg", name: "kg" },
  { code: "g", name: "g" },
  { code: "l", name: "l" },
  { code: "ml", name: "ml" },
  { code: "szt.", name: "szt." },
  { code: "pęczek", name: "pęczek" },
  { code: "worek", name: "worek" },
  { code: "skrzynka", name: "skrzynka" },
  { code: "słoik", name: "słoik" },
  { code: "inna", name: "inna" },
];

const GroceryAvailabilityComponent: React.FC<GroceryTabProps> = (props) => {
  const productState: ProductState = useSelector((state: any) => state.product);
  const [unit, setUnit] = React.useState<EntryDictionary | undefined>(
    jm.find((x) => x.code === productState.selected?.unit) 
  );
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { params } = useURLParams();
  const action = params.get("action") ?? "";

  React.useEffect(() => {
    action !== 'create' && setUnit(jm.find((x) => x.code === productState.selected?.unit) ?? { code: "inna", name: "inna" });
  }, [productState.selected]);

  const getInfo = (): JSX.Element => {
    return (
      <div className="frame-main" style={{ width: "450px", height: "50vh" }}>
        <div className="frame-scroll gap-16">
          <div className="d-flex fd-c gap-4">
            <Label wrap fontSize="sm" fontStyle="extra-bold" content="Dostępny - Produkt widoczny dla klientów" />
            <Label
              wrap
              content={`Ta opcja sprawia, że produkt jest natychmiast widoczny i dostępny dla wszystkich klientów przeglądających platformę. Idealna do szybkiego wprowadzania produktów na rynek i umożliwia klientom natychmiastowy zakup.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label
              wrap
              fontSize="sm"
              fontStyle="extra-bold"
              content="Aktualnie niedostępny - Użytkownik musi podać datę, od kiedy produkt jest dostępny"
            />
            <Label
              wrap
              content={`Pozwala to na zaplanowanie premiery produktu na konkretny dzień. Produkt zostanie automatycznie ustawiony jako widoczny dla klientów od wskazanej daty, co jest przydatne przy planowaniu kampanii marketingowych lub wprowadzaniu produktów sezonowych.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label wrap fontSize="sm" fontStyle="extra-bold" content="Niedostępny - Nie widoczny dla klienta" />
            <Label
              wrap
              content={`Ta opcja umożliwia ukrycie produktu przed oczami klientów. Produkt nie jest widoczny na liście dostępnych ofert, co może być przydatne, gdy produkt jest tymczasowo wycofany, oczekuje na uzupełnienie zapasów, lub jest w fazie aktualizacji.`}
            />
          </div>
        </div>
      </div>
    );
  };

  const showInfo = async () => {
    await ModalDialog.show(getInfo(), "Zarządzanie dostępnością produktu");
  };

  if (isMobile) {
    return (
      <div
        id={props.id}
        className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill"
        style={{ maxWidth: "500px" }}
      >
        <Label fontStyle="bold" fontSize="md" content="Dostępność" />
        <InputComboBox
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Jednostka miary"
          placeholder="Wybierz jednostkę miary"
          selectedItem={unit}
          onSelectionChanged={(item) =>
            dispatch(updateProduct({ product: { unit: item?.["name"] }, errors: { unit: "" } }))
          }
          itemSource={jm}
          displayMember="name"
          disabled={action === "preview"}
          error={productState.valideError.unit}
        />
        {unit?.code === "inna" && (
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Własna jednostka miary"
            disabled={action === "preview"}
            value={productState.selected?.unit}
            placeholder="Wprowadź własną jednostkę miary produktu"
            onChange={(e) =>
              dispatch(updateProduct({ product: { unit: e.currentTarget.value }, errors: { unit: "" } }))
            }
            error={productState.valideError.unit}
          />
        )}
        <InputNumber
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Ilość"
          placeholder="Wprowadź ilość produktu"
          unit={productState.selected?.unit}
          fixed={2}
          step={"0.5"}
          min={0.01}
          disabled={action === "preview"}
          error={productState.valideError.quantity}
          value={productState.selected?.quantity}
          onSubmit={(e) =>
            dispatch(
              updateProduct({
                product: { quantity: isNaN(e.currentTarget.valueAsNumber) ? undefined : e.currentTarget.valueAsNumber },
                errors: { quantity: "" },
              })
            )
          }
        />
        <InfoBox className="width-fill" fontStyle="semibold" fontSize="sm" icon="Information" iconSize="sm">
          <div className="d-flex fd-c gap-8 pad-8">
            <Label fontSize="md" fontColor="black" fontStyle="extra-bold" content="Blokada rezerwacji online" />
            <Label
              wrap
              fontSize="sm"
              fontColor="black"
              content={`Blokada rezerwacji i wymóg wcześniejszego kontaktu ze sprzedawcą to proces, który ogranicza możliwość dokonywania rezerwacji bezpośrednio przez systemy online. W takim modelu, klienci zainteresowani zakupem lub rezerwacją usługi muszą najpierw skontaktować się ze sprzedawcą – może to być kontakt telefoniczny, mailowy lub poprzez inne kanały komunikacji określone przez firmę. Celem tego podejścia jest umożliwienie bardziej spersonalizowanej obsługi, weryfikacja dostępności oferty lub szczegółowe omówienie warunków usługi.`}
            />
            <InputCheckBox
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="transparent"
              checked={productState.selected?.blockReservation}
              checkboxLabel="Blokuj rezerwacje produktu"
              fontStyle="bold"
              fontSize="sm"
              fontColor="black"
              disabled={action === "preview"}
              onChange={(e) => dispatch(updateProduct({ product: { blockReservation: e.target.checked } }))}
              iconPosition="right"
            />
          </div>
        </InfoBox>
        <div className="d-flex ai-center">
          <Label fontSize="md" wrap fontStyle="bold" content="Zarządzaj dostępnością produktu" />
          <Button
            sizeFrame="sml"
            kind="transparent"
            icon={<Icon.InfoSVG />}
            toolTip={"Kliknij aby zobaczyć więcej..."}
            toolTipPosition="right"
            onClick={showInfo}
          />
        </div>
        {productState.valideError.availableType.length > 0 && (
          <InfoBox
            className="width-fill ai-center"
            icon="Error"
            iconSize="sml"
            fontStyle="semibold"
            backgroundColor="red"
            value={productState.valideError.availableType}
          />
        )}
        <div className="choose-button" style={{ width: "calc(100% - 8px)" }}>
          <Button
            style={{ flex: "1" }}
            sizeFrame="sm"
            kind={productState.selected?.availableType === "Available" ? "primary" : "text"}
            label="Dostępny"
            disabled={action === "preview"}
            onClick={() => dispatch(updateProduct({ product: { availableType: "Available" } }))}
          />
          <Button
            style={{ flex: "1" }}
            sizeFrame="sm"
            kind={productState.selected?.availableType === "TemporarilyUnavailable" ? "primary" : "text"}
            label="Aktualnie niedostępny"
            disabled={action === "preview"}
            onClick={() => dispatch(updateProduct({ product: { availableType: "TemporarilyUnavailable" } }))}
          />
          <Button
            style={{ flex: "1" }}
            sizeFrame="sm"
            kind={productState.selected?.availableType === "Unavailable" ? "primary" : "text"}
            label="Niedostępny"
            disabled={action === "preview"}
            onClick={() => dispatch(updateProduct({ product: { availableType: "Unavailable" } }))}
          />
        </div>
        {productState.selected?.availableType === "TemporarilyUnavailable" && (
          <InputDate
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Dostępny od"
            error={productState.valideError.whenAvailable}
            dateValue={
              productState.selected?.whenAvailable ? new Date(productState.selected?.whenAvailable) : new Date()
            }
            onChange={(e) =>
              dispatch(
                updateProduct({ product: { whenAvailable: e.target.valueAsDate! }, errors: { whenAvailable: "" } })
              )
            }
          />
        )}
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Dostępność" />
      <InputComboBox
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Jednostka miary"
        placeholder="Wybierz jednostkę miary"
        selectedItem={unit}
        onSelectionChanged={(item) =>
          dispatch(updateProduct({ product: { unit: item?.["name"] }, errors: { unit: "" } }))
        }
        itemSource={jm}
        displayMember="name"
        disabled={action === "preview"}
        error={productState.valideError.unit}
      />
      {unit?.code === "inna" && (
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Własna jednostka miary"
          disabled={action === "preview"}
          value={productState.selected?.unit}
          placeholder="Wprowadź własną jednostkę miary produktu"
          onChange={(e) => dispatch(updateProduct({ product: { unit: e.currentTarget.value }, errors: { unit: "" } }))}
          error={productState.valideError.unit}
        />
      )}
      <InputNumber
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Ilość"
        placeholder="Wprowadź ilość produktu"
        unit={productState.selected?.unit}
        fixed={2}
        step={0.5}
        min={0.01}
        disabled={action === "preview"}
        error={productState.valideError.quantity}
        value={productState.selected?.quantity}
        onSubmit={(e) =>
          dispatch(
            updateProduct({
              product: { quantity: isNaN(e.currentTarget.valueAsNumber) ? undefined : e.currentTarget.valueAsNumber },
              errors: { quantity: "" },
            })
          )
        }
      />
      <InfoBox
        style={{ width: "calc(100% - 16px)" }}
        fontStyle="semibold"
        fontSize="sm"
        icon="Information"
        iconSize="sm"
      >
        <div className="d-flex fd-c gap-8 pad-8">
          <Label fontSize="md" fontColor="black" fontStyle="extra-bold" content="Blokada rezerwacji online" />
          <Label
            wrap
            fontSize="sm"
            fontColor="black"
            content={`Blokada rezerwacji i wymóg wcześniejszego kontaktu ze sprzedawcą to proces, który ogranicza możliwość dokonywania rezerwacji bezpośrednio przez systemy online. W takim modelu, klienci zainteresowani zakupem lub rezerwacją usługi muszą najpierw skontaktować się ze sprzedawcą – może to być kontakt telefoniczny, mailowy lub poprzez inne kanały komunikacji określone przez firmę. Celem tego podejścia jest umożliwienie bardziej spersonalizowanej obsługi, weryfikacja dostępności oferty lub szczegółowe omówienie warunków usługi.`}
          />
          <InputCheckBox
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="transparent"
            checked={productState.selected?.blockReservation}
            checkboxLabel="Blokuj rezerwacje produktu"
            fontStyle="bold"
            fontSize="sm"
            fontColor="black"
            disabled={action === "preview"}
            onChange={(e) => dispatch(updateProduct({ product: { blockReservation: e.target.checked } }))}
            iconPosition="right"
          />
        </div>
      </InfoBox>
      <div className="d-flex">
        <Label fontSize="xl" fontStyle="bold" content="Zarządzaj dostępnością produktu" />
        <Button
          sizeFrame="sml"
          kind="transparent"
          icon={<Icon.InfoSVG />}
          toolTip={"Kliknij aby zobaczyć więcej..."}
          toolTipPosition="right"
          onClick={showInfo}
        />
      </div>
      {productState.valideError.availableType.length > 0 && (
        <InfoBox
          className="width-fill ai-center"
          icon="Error"
          iconSize="sml"
          fontStyle="semibold"
          backgroundColor="red"
          value={productState.valideError.availableType}
        />
      )}
      <div className="choose-button" style={{ width: "calc(100% - 8px)" }}>
        <Button
          style={{ flex: "1" }}
          sizeFrame="sm"
          kind={productState.selected?.availableType === "Available" ? "primary" : "text"}
          label="Dostępny"
          disabled={action === "preview"}
          onClick={() => dispatch(updateProduct({ product: { availableType: "Available" } }))}
        />
        <Button
          style={{ flex: "1" }}
          sizeFrame="sm"
          kind={productState.selected?.availableType === "TemporarilyUnavailable" ? "primary" : "text"}
          label="Aktualnie niedostępny"
          disabled={action === "preview"}
          onClick={() => dispatch(updateProduct({ product: { availableType: "TemporarilyUnavailable" } }))}
        />
        <Button
          style={{ flex: "1" }}
          sizeFrame="sm"
          kind={productState.selected?.availableType === "Unavailable" ? "primary" : "text"}
          label="Niedostępny"
          disabled={action === "preview"}
          onClick={() => dispatch(updateProduct({ product: { availableType: "Unavailable" } }))}
        />
      </div>
      {productState.selected?.availableType === "TemporarilyUnavailable" && (
        <InputDate
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Dostępny od"
          error={productState.valideError.whenAvailable}
          dateValue={productState.selected?.whenAvailable ? new Date(productState.selected?.whenAvailable) : new Date()}
          onChange={(e) =>
            dispatch(
              updateProduct({ product: { whenAvailable: e.target.valueAsDate! }, errors: { whenAvailable: "" } })
            )
          }
        />
      )}
    </div>
  );
};

export default GroceryAvailabilityComponent;
