import React from "react";
import * as OrderApi from "api/OrderApi";
import { useDispatch, useSelector } from "react-redux";
import { OrderState, UserState } from "models/new/State";
import { Label } from "controls/Label";
import { OrderProduct } from "models/new/OrderProduct";
import { InputArea } from "controls/InputArea";
import { Button } from "controls/Button";
import { changeMainState } from "reducers/mainReducer";
import { NotificationType } from "models/new/Dictionaries";
import { useWindowSize } from "hooks/useWindowSize";
import { Order } from "models/new/Order";
import { Rating } from "models/new/Rating";
import { generateGUID } from "commons/stringManager";
import StarSelectionMobileComponent from "components/StarSelectionMobileComponent";
import StarSelectionComponent from "components/StarSelectionComponent";
import { setSelectedOrder } from "reducers/orderReducer";
import { useNotify } from "hooks/useNotify";

const OrderRatingComponent: React.FC = () => {
  const orderState: OrderState = useSelector((state: any) => state.order);
  const userState: UserState = useSelector((state: any) => state.user);
  const [orderRate, setOrderRate] = React.useState<Order>();
  const { isMobile } = useWindowSize();
  const { addNotify } = useNotify();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setOrderRate(orderState.selected);
  }, [orderState.selected]);

  const productRating = (product: OrderProduct) => {
    const find = orderRate?.productRatings?.find((x) => x.productId === product.productId);

    return (
      <div className="d-flex fd-c pad-8 pad-b-16 gap-4 bg-secondary rounded-12 width-fill">
        <div className="d-flex gap-8 ai-center">
          <img className="picture-35" src={product.photoUrl} />
          <Label fontStyle="bold" fontSize="sm" content={product.name} />
        </div>
        <Label fontStyle="bold" fontSize="sml" content="Ocena" />
        {isMobile ? (
          <StarSelectionMobileComponent
            rating={find?.rate}
            hover={true}
            onClick={(rate) => {
              productRate({ productId: product.productId, rate: rate, reviewerId: userState.user?.id });
            }}
          />
        ) : (
          <StarSelectionComponent
            rating={find?.rate}
            hover={true}
            onClick={(rate) => {
              productRate({ productId: product.productId, rate: rate, reviewerId: userState.user?.id });
            }}
          />
        )}
        <Label fontStyle="bold" fontSize="sml" content="Komentarz" />
        <InputArea
          kind="secondary"
          sizeFrame="sm"
          frameClassName="width-fill mag-b-8"
          maxLength={250}
          frameStyle={{ height: "80px" }}
          value={find?.comment}
          onChange={(e) =>
            productRate({
              productId: product.productId,
              comment: e.currentTarget.value,
              reviewerId: userState.user?.id,
            })
          }
        />
      </div>
    );
  };

  const submit = async () => {
    await OrderApi.ratingOrder({
      orderId: orderRate!.id,
      supplierRating: orderRate?.supplierRating,
      productRatings: orderRate?.productRatings,
    })
      .then(() => {
        dispatch(setSelectedOrder(orderRate));
        dispatch(changeMainState({ showOrderRating: false }));
        addNotify({
          title: "",
          content: "Oceny i komentarze zostały dodane",
          type: NotificationType.Success,
          date: new Date(),
        });
      })
      .catch((err) => alert(`OrderRatingComponent - ${err}`));
  };

  const rateSupplier = (rate: Partial<Rating>) => {
    let order = { ...orderRate! };
    order.supplierRating = { ...order.supplierRating, ...rate };
    setOrderRate(order);
  };

  const productRate = (rate: Partial<Rating>) => {
    let order = { ...orderRate! };
    const find = order!.productRatings.find((x) => x.productId === rate.productId);
    if (find) {
      order!.productRatings = order!.productRatings.map((x) =>
        x.productId === rate.productId ? { ...x, ...rate } : x
      );
    } else {
      const newRating: Rating = {
        id: generateGUID(),
        date: new Date(),
        comment: rate.comment ?? "",
        rate: rate.rate ?? 0,
        reviewerId: rate.reviewerId ?? "",
        productId: rate.productId ?? "",
      };
      order.productRatings = [...order.productRatings, newRating];
    }
    setOrderRate(order);
  };

  return (
    <div className="frame-scroll pad-8 gap-8">
      <Label fontStyle="bold" content="Oceń sprzedawce" />
      <div className="d-flex fd-c pad-8 pad-b-16 gap-4 bg-secondary rounded-12 width-fill">
        <div className="d-flex gap-8 ai-center">
          <img className="picture-35" src={orderRate?.supplier.logoUrl} />
          <Label fontStyle="bold" fontSize="sm" content={orderRate?.supplier.companyName} />
        </div>
        <Label fontStyle="bold" fontSize="sml" content="Ocena" />
        {isMobile ? (
          <StarSelectionMobileComponent
            rating={orderRate?.supplierRating?.rate}
            hover={true}
            onClick={(rate) =>
              rateSupplier({
                supplierId: orderRate?.supplier.id,
                rate: rate,
                reviewerId: userState.user?.id,
              })
            }
          />
        ) : (
          <StarSelectionComponent
            rating={orderRate?.supplierRating?.rate}
            hover={true}
            onClick={(rate) =>
              rateSupplier({
                supplierId: orderRate?.supplier.id,
                rate: rate,
                reviewerId: userState.user?.id,
              })
            }
          />
        )}
        <Label fontStyle="bold" fontSize="sml" content="Komentarz" />
        <InputArea
          kind="secondary"
          sizeFrame="sm"
          frameClassName="width-fill mag-b-8"
          maxLength={250}
          frameStyle={{ height: "80px" }}
          value={orderRate?.supplierRating?.comment}
          onChange={(e) =>
            rateSupplier({
              supplierId: orderRate?.supplier.id,
              comment: e.currentTarget.value,
              reviewerId: userState.user?.id,
            })
          }
        />
      </div>
      <Label fontStyle="bold" content="Oceń produkt/y" />
      {orderRate?.products?.map((p) => {
        return productRating(p);
      })}
      <Button
        className="width-fill mag-t-16 mag-b-8"
        kind="primary"
        sizeFrame="md"
        fontSize="lg"
        fontStyle="bold"
        label="Oceń zamówienie"
        onClick={submit}
      />
    </div>
  );
};

export default OrderRatingComponent;
