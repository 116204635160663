import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import InfoBox from "controls/InfoBox";
import { useDispatch, useSelector } from "react-redux";
import { BasketState } from "models/new/State";
import { changeSummaryBasket, setSummaryBasketAddress } from "reducers/basketReducer";
import AddressComponent from "components/AddressComponent";
import OpenHourComponent from "components/OpenHourComponent";
import { InputComboBox } from "controls/InputComboBox";
import { InputDate } from "controls/InputDate";
import { EntryDictionary } from "models/new/EntryDictionary";
import { Button } from "controls/Button";
import { InputText } from "controls/InputText";
import EmptyComponent from "components/EmptyComponent";
import { DeliveryType } from "models/new/Dictionaries";
import { useWindowSize } from "hooks/useWindowSize";
import { Address } from "models/new/Address";
import { checkIntterOpenHours } from "commons/dataTimeManager";
import { PopUp } from "controls/PopUp";
import SelectOnMapComponent from "components/SelectOnMapComponent";
import { ModalDialog } from "controls/ModalDialog";

const BasketSummaryDeliveryComponent: React.FC = () => {
  const { isMobile } = useWindowSize();
  const basketState: BasketState = useSelector((state: any) => state.basket);
  const dispatch = useDispatch();

  const mainAddress = basketState.summary?.supplier?.addresses?.find((x) => x.isMainAddress);

  const btnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const onChangePickUpDate = (date: Date | null) => {
    if (!basketState.summary || !date) return;

    let error = "";

    const address: Address | undefined =
      basketState.summary?.deliveryType === DeliveryType.PickupOnSite
        ? mainAddress
        : basketState.summary.selectedAddress?.value;

    if (address) {
      const dayOfWeek = date.getDay() - 1;
      const openHours = address.openHours?.find((x) => x.day === dayOfWeek);
      if (!openHours) {
        error = "Dla wskazanego terminu sprzedaż jest zamknięta";
      } else if (!checkIntterOpenHours(date, openHours.startHour, openHours.closeHour)) {
        error = `Wybierz poprawny przedział godzinowy`;
      }
    }

    dispatch(
      changeSummaryBasket({
        basket: { pickUpDate: date ?? new Date() },
        errors: { pickUpDate: error },
      })
    );
  };

  const getPersonalAddress = (): JSX.Element => {
    return (
      <div className="d-flex fd-c gap-32 pad-16 border rounded-12">
        <div className="d-flex fd-c gap-32">
          <Label fontStyle="bold" content="Adres gospodarstwa" />
          <AddressComponent address={mainAddress} />
          <Label fontStyle="bold" content="Godziny otwarcia" />
          <OpenHourComponent address={mainAddress} canEdit={false} />
          <InputDate
            frameClassName="width-fill"
            label="Zaplanuj datę i czas odbioru"
            format="dddd, DD.MM.yyyy HH:mm"
            placeholder="Wybierz dzień i godzine odbioru"
            sizeFrame="sm"
            kind="tertiary"
            error={basketState.valideError?.pickUpDate}
            dateValue={basketState.summary?.pickUpDate}
            onChange={(e) => onChangePickUpDate(e.target.valueAsDate)}
          />
        </div>
      </div>
    );
  };

  const showMap = async () => {
    const result = await ModalDialog.show(
      <SelectOnMapComponent addresses={basketState.summary?.supplier?.addresses} />,
      "Wybierz adres"
    );
    if (result.result === "Approve") {
      const selAddress: EntryDictionary = {
        code: result.value.id,
        name: `${result.value.street} ${result.value.buildingNumber}, ${result.value.postalCode} ${result.value.city}`,
        value: result.value,
      } as EntryDictionary;
      dispatch(
        changeSummaryBasket({ basket: { selectedAddress: selAddress }, errors: { ...basketState.valideError } })
      );
    }
  };

  const onDisplayContentAddressCombobox = (item: EntryDictionary): JSX.Element => {
    return (
      <Button
        style={{ width: "100%", marginBlock: "2px" }}
        sizeFrame={isMobile ? "lg" : "sm"}
        kind={basketState.summary?.selectedAddress?.code === item.code ? "menu-seleceted" : "menu"}
        label={item.name}
        wrapLabel
        icon={<Icon.LocationOnSVG />}
      />
    );
  };
  const getLocalAddress = (): JSX.Element => {
    if (!basketState.summary?.supplier?.addresses?.some((x) => !x.isMainAddress)) {
      return (
        <div className="d-flex fd-c gap-32 pad-16 border rounded-12">
          <EmptyComponent icon={<Icon.DescriptionSVG />} message="Brak punktów odbioru" />
        </div>
      );
    }

    const address = basketState.summary?.supplier?.addresses?.filter((x) => !x.isMainAddress);
    if (address) {
      const addressList: EntryDictionary[] = address.map((x) => {
        return {
          code: x.id,
          name: `${x.street} ${x.buildingNumber}, ${x.postalCode} ${x.city}`,
          value: x,
        } as EntryDictionary;
      });

      return (
        <div className="d-flex fd-c gap-32 pad-16 border rounded-12">
          <div className="d-flex ai-e-flex gap-8">
            <InputComboBox
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="tertiary"
              label="Punktu odbioru"
              placeholder="Wybierz adres odbioru"
              displayMember="name"
              itemSource={addressList}
              selectedItem={basketState.summary.selectedAddress}
              onDisplayContent={onDisplayContentAddressCombobox}
              onSelectionChanged={(e) =>
                dispatch(
                  changeSummaryBasket({ basket: { selectedAddress: e }, errors: { ...basketState.valideError } })
                )
              }
            />
            {!isMobile && (
              <Button
                ref={btnRef}
                sizeFrame="md"
                kind="tertiary"
                icon={<Icon.MapSVG />}
                toolTip={"Wybierz na mapie"}
                onClick={showMap}
              />
            )}
          </div>
          {basketState.summary.selectedAddress && (
            <div className="d-flex fd-c gap-32">
              <Label fontStyle="bold" content="Adres punktu odbioru" />
              <AddressComponent address={basketState.summary.selectedAddress?.value} />
              <Label fontStyle="bold" content="Godziny otwarcia" />
              <OpenHourComponent address={basketState.summary.selectedAddress?.value} canEdit={false} />
              <InputDate
                frameClassName="width-fill"
                label="Zaplanuj datę i czas odbioru"
                format="dddd, DD.MM.yyyy HH:mm"
                placeholder="Wybierz dzień i godzine odbioru"
                sizeFrame="sm"
                kind="tertiary"
                error={basketState.valideError?.pickUpDate}
                dateValue={basketState.summary?.pickUpDate}
                onChange={(e) => onChangePickUpDate(e.target.valueAsDate)}
              />
            </div>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  };
  const getCourierAddress = (): JSX.Element => {
    return (
      <div className="d-flex fd-c gap-16 pad-16 border rounded-12">
        <Label fontStyle="bold" content="Wybór kuriera" />
        <Label fontStyle="bold" content="Dane do przesyłki" />

        <div className="d-flex ai-center gap-8">
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Ulica"
            error={basketState.valideError?.street}
            value={basketState.summary?.address?.street}
            onChange={(e) =>
              dispatch(setSummaryBasketAddress({ address: { street: e.currentTarget.value }, errors: { street: "" } }))
            }
          />

          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer domu"
            error={basketState.valideError?.buildingnumber}
            value={basketState.summary?.address?.buildingNumber}
            onChange={(e) =>
              dispatch(
                setSummaryBasketAddress({
                  address: { buildingNumber: e.currentTarget.value },
                  errors: { buildingnumber: "" },
                })
              )
            }
          />
          <Label className="pad-t-16" content="/" />

          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer lokalu"
            value={basketState.summary?.address?.localNumber}
            onChange={(e) => dispatch(setSummaryBasketAddress({ address: { localNumber: e.currentTarget.value } }))}
          />
        </div>
        <div className="d-flex ai-center gap-8">
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Kod pocztowy"
            error={basketState.valideError?.postalcode}
            value={basketState.summary?.address?.postalCode}
            onChange={(e) =>
              dispatch(
                setSummaryBasketAddress({ address: { postalCode: e.currentTarget.value }, errors: { postalcode: "" } })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Miejscowość"
            error={basketState.valideError?.city}
            value={basketState.summary?.address?.city}
            onChange={(e) =>
              dispatch(setSummaryBasketAddress({ address: { city: e.currentTarget.value }, errors: { city: "" } }))
            }
          />
        </div>
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c width-fill pad-t-32 pad-b-32 gap-16 border-bottom">
        <Label fontStyle="bold" wrap content={"Wybierz dostawe lub odbierz osobiscie"} />
        <div className="d-flex fd-r gap-16">
          <div className="d-flex fd-c gap-16">
            {basketState.valideError?.deliveryType.length > 0 && (
              <InfoBox
                className="width-fill ai-center"
                fontStyle="bold"
                sizeFrame="sml"
                iconSize="sml"
                backgroundColor="red"
                icon="Error"
                value={basketState.valideError?.deliveryType}
              />
            )}
            <InfoBox
              className="c-pointer width-fill hover-box"
              sizeFrame="sm"
              backgroundColor={basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "green" : "secondary"}
              onClick={() =>
                dispatch(
                  changeSummaryBasket({
                    basket: { deliveryType: DeliveryType.PickupOnSite, deliveryCost: 0 },
                    errors: { deliveryType: "" },
                  })
                )
              }
            >
              <div className="d-flex ai-center gap-16 c-pointer">
                <Icon.HomePinSVG
                  className={
                    basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "icon-dark" : "icon-color"
                  }
                  style={{ minHeight: "48px", minWidth: "48px" }}
                />
                <div className="d-flex fd-c gap-2">
                  <Label
                    pointer
                    fontStyle="bold"
                    fontColor={basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "black" : "primary"}
                    content="Odbiór osobisty"
                  />
                  <Label
                    pointer
                    fontSize="sml"
                    fontStyle="semibold"
                    fontColor={basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "black" : "primary"}
                    wrap
                    content="Odbiór osobisty to opcja, która pozwala klientom na samodzielne odebranie zakupionych produktów w wyznaczonym miejscu i czasie."
                  />
                </div>
              </div>
            </InfoBox>

            {basketState.summary!.supplier!.addresses?.length > 1 && (
              <InfoBox
                className="c-pointer width-fill hover-box"
                sizeFrame="sm"
                backgroundColor={
                  basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "green" : "secondary"
                }
                onClick={() =>
                  dispatch(
                    changeSummaryBasket({
                      basket: { deliveryType: DeliveryType.PickUpAtThePoint, deliveryCost: 0 },
                      errors: { deliveryType: "" },
                    })
                  )
                }
              >
                <div className="d-flex ai-center gap-16 c-pointer">
                  <Icon.StorefrontSVG
                    className={
                      basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "icon-dark" : "icon-color"
                    }
                    style={{ minHeight: "48px", minWidth: "48px" }}
                  />
                  <div className="d-flex fd-c gap-2">
                    <Label
                      pointer
                      fontStyle="bold"
                      fontColor={
                        basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "black" : "primary"
                      }
                      content="Dostawa lokalna"
                    />
                    <Label
                      pointer
                      fontSize="sml"
                      fontStyle="semibold"
                      fontColor={
                        basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "black" : "primary"
                      }
                      wrap
                      content="Dostawa lokalna jest dostępna dla klientów znajdujących się w określonym obszarze, którzy mogą odebrać swoje zmówienie w punktach odbioru."
                    />
                  </div>
                </div>
              </InfoBox>
            )}

            {basketState.summary?.deliveryType === DeliveryType.Post
              ? getCourierAddress()
              : basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint
              ? getLocalAddress()
              : basketState.summary?.deliveryType === DeliveryType.PickupOnSite
              ? getPersonalAddress()
              : null}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c width-fill pad-16 gap-16 border-bottom">
      <Label fontSize="lg" fontStyle="bold" wrap content={"Wybierz dostawe lub odbierz osobiscie"} />
      <div className="d-flex fd-r gap-16">
        <div className="d-flex fd-c gap-16">
          {basketState.valideError?.deliveryType.length > 0 && (
            <InfoBox
              className="width-fill ai-center"
              fontStyle="bold"
              sizeFrame="sml"
              iconSize="sml"
              backgroundColor="red"
              icon="Error"
              value={basketState.valideError?.deliveryType}
            />
          )}
          <InfoBox
            className="c-pointer width-fill hover-box"
            sizeFrame="sm"
            backgroundColor={basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "green" : "secondary"}
            onClick={() =>
              dispatch(
                changeSummaryBasket({
                  basket: { deliveryType: DeliveryType.PickupOnSite, deliveryCost: 0 },
                  errors: { deliveryType: "" },
                })
              )
            }
          >
            <div className="d-flex ai-center gap-16 c-pointer">
              <Icon.HomePinSVG
                className={basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "icon-dark" : "icon-color"}
                style={{ minHeight: "32px", minWidth: "32px" }}
              />
              <div className="d-flex fd-c gap-2">
                <Label
                  pointer
                  fontStyle="bold"
                  fontColor={basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "black" : "primary"}
                  content="Odbiór osobisty"
                />
                <Label
                  pointer
                  fontSize="sml"
                  fontStyle="semibold"
                  fontColor={basketState.summary?.deliveryType === DeliveryType.PickupOnSite ? "black" : "primary"}
                  wrap
                  content="Odbiór osobisty to opcja, która pozwala klientom na samodzielne odebranie zakupionych produktów w wyznaczonym miejscu i czasie."
                />
              </div>
            </div>
          </InfoBox>

          {basketState.summary!.supplier!.addresses?.length > 1 && (
            <InfoBox
              className="c-pointer width-fill hover-box"
              sizeFrame="sm"
              backgroundColor={
                basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "green" : "secondary"
              }
              onClick={() =>
                dispatch(
                  changeSummaryBasket({
                    basket: { deliveryType: DeliveryType.PickUpAtThePoint, deliveryCost: 0 },
                    errors: { deliveryType: "" },
                  })
                )
              }
            >
              <div className="d-flex ai-center gap-16 c-pointer">
                <Icon.StorefrontSVG
                  className={
                    basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "icon-dark" : "icon-color"
                  }
                  style={{ minHeight: "32px", minWidth: "32px" }}
                />
                <div className="d-flex fd-c gap-2">
                  <Label
                    pointer
                    fontStyle="bold"
                    fontColor={
                      basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "black" : "primary"
                    }
                    content="Dostawa lokalna"
                  />
                  <Label
                    pointer
                    fontSize="sml"
                    fontStyle="semibold"
                    fontColor={
                      basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint ? "black" : "primary"
                    }
                    wrap
                    content="Dostawa lokalna jest dostępna dla klientów znajdujących się w określonym obszarze, którzy mogą odebrać swoje zmówienie w punktach odbioru."
                  />
                </div>
              </div>
            </InfoBox>
          )}

          {basketState.summary?.deliveryType === DeliveryType.Post
            ? getCourierAddress()
            : basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint
            ? getLocalAddress()
            : basketState.summary?.deliveryType === DeliveryType.PickupOnSite
            ? getPersonalAddress()
            : null}
        </div>
      </div>
    </div>
  );
};

export default BasketSummaryDeliveryComponent;

/*
mobilna 
<InfoBox
              className="c-pointer width-fill hover-box"
              sizeFrame="sm"
              backgroundColor={basketState.summary?.deliveryType === DeliveryType.Post ? "green" : "secondary"}
              onClick={() =>
                dispatch(
                  changeSummaryBasket({
                    basket: { deliveryType: DeliveryType.Post, deliveryCost: 12.5 },
                  })
                )
              }
            >
              <div className="d-flex ai-center gap-16 c-pointer">
                <Icon.LocalShippingSVG
                  className={basketState.summary?.deliveryType === DeliveryType.Post ? "icon-dark" : "icon-color"}
                  style={{ minHeight: "48px", minWidth: "48px" }}
                />
                <div className="d-flex fd-c gap-2">
                  <Label
                    pointer
                    fontStyle="bold"
                    fontColor={basketState.summary?.deliveryType === DeliveryType.Post ? "black" : "primary"}
                    content="Dostawa kurierska"
                  />
                  <Label
                    pointer
                    fontSize="sml"
                    fontStyle="semibold"
                    fontColor={basketState.summary?.deliveryType === DeliveryType.Post ? "black" : "primary"}
                    wrap
                    content="Dostawa kurierska to wygodna opcja dla klientów, którzy chcą otrzymać swoje zamówienie bezpośrednio pod wskazany adres."
                  />
                </div>
              </div>
            </InfoBox>
*/

/*

  <InfoBox
            className="c-pointer width-fill hover-box"
            sizeFrame="sm"
            backgroundColor={basketState.summary?.deliveryType === DeliveryType.Post ? "green" : "secondary"}
            onClick={() =>
              dispatch(
                changeSummaryBasket({
                  basket: { deliveryType: DeliveryType.Post, deliveryCost: 12.5 },
                })
              )
            }
          >
            <div className="d-flex ai-center gap-16 c-pointer">
              <Icon.LocalShippingSVG
                className={basketState.summary?.deliveryType === DeliveryType.Post ? "icon-dark" : "icon-color"}
                style={{ minHeight: "32px", minWidth: "32px" }}
              />
              <div className="d-flex fd-c gap-2">
                <Label
                  pointer
                  fontStyle="bold"
                  fontColor={basketState.summary?.deliveryType === DeliveryType.Post ? "black" : "primary"}
                  content="Dostawa kurierska"
                />
                <Label
                  pointer
                  fontSize="sml"
                  fontStyle="semibold"
                  fontColor={basketState.summary?.deliveryType === DeliveryType.Post ? "black" : "primary"}
                  wrap
                  content="Dostawa kurierska to wygodna opcja dla klientów, którzy chcą otrzymać swoje zamówienie bezpośrednio pod wskazany adres."
                />
              </div>
            </div>
          </InfoBox>

*/
