import { NavigateOptions, useNavigate } from "react-router-dom";
import { TypeRoutePage } from "../models/new/Dictionaries";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLogin } from "./useLogin";
import { setConversation } from "reducers/conversationReducer";
import { changeView, logOut } from "../reducers/userReducer";
import { changeMainRoute, changeMainState, changeTypeRoutePage } from "../reducers/mainReducer";
import RoutePage from "../models/new/RoutePage";
import SearchPage from "../pages/search/SearchPage";
import GroceryPage from "../pages/grocery/GroceryPage";
import SalePage from "../pages/sale/SalePage";
import ProfilePage from "../pages/profile/ProfilePage";
import SettingsPage from "../pages/settings/SettingsPage";
import OrderPage from "../pages/order/OrderPage";
import OrdersManagerComponent from "../pages/order/components/OrdersManagerComponent";
import GroceryManagerComponent from "../pages/grocery/components/GroceryManagerComponent";
import BasketPage from "../pages/basket/BasketPage";
import SalesManagerComponent from "../pages/sale/components/SalesManagerComponent";
import WallPage from "src/pages/wall/WallPage";
import LandingPage from "src/pages/landing/LandingPage";

const routeStack: RoutePage[] = [
  /*{
    id: "landing",
    parent: undefined,
    path: "/",
    name: undefined,
    isLogin: false,
    view: "both",
    element: <LandingPage />,
  },*/
  {
    id: "landing",
    parent: undefined,
    path: "/landing",
    name: undefined,
    isLogin: false,
    view: "both",
    element: <LandingPage />,
  },
  {
    id: "main",
    parent: undefined,
    path: "/",
    name: undefined,
    isLogin: false,
    view: "both",
    element: <SearchPage />,
  },
  {
    id: "search",
    parent: undefined,
    path: "/search",
    name: undefined,
    isLogin: false,
    view: "both",
    element: <SearchPage />,
  },
  { id: "main", parent: undefined, path: "/", name: undefined, isLogin: true, view: "both", element: <SearchPage /> },
  {
    id: "search",
    parent: undefined,
    path: "/search",
    name: undefined,
    isLogin: true,
    view: "both",
    element: <SearchPage />,
  },
  {
    id: "grocery",
    parent: undefined,
    path: "/grocery",
    name: "Produkty",
    isLogin: true,
    view: "both",
    element: <GroceryPage />,
  },
  {
    id: "grocery_manager",
    parent: "grocery",
    path: "/grocery/grocery_manager",
    name: "Manager produktów",
    isLogin: true,
    view: "both",
    element: <GroceryManagerComponent />,
  },
  {
    id: "sales",
    parent: undefined,
    path: "/sales",
    name: "Moja sprzedaż",
    isLogin: true,
    view: "both",
    element: <SalePage />,
  },
  {
    id: "sale_manager",
    parent: "sales",
    path: "/sales/sale_manager",
    name: "Manager sprzedaży",
    isLogin: true,
    view: "both",
    element: <SalesManagerComponent />,
  },
  {
    id: "profile",
    parent: undefined,
    path: "/profile",
    name: "Profil",
    isLogin: true,
    view: "both",
    element: <ProfilePage />,
  },
  {
    id: "settings",
    parent: undefined,
    path: "/settings",
    name: "Ustawienia",
    isLogin: true,
    view: "both",
    element: <SettingsPage />,
  },
  {
    id: "orders",
    parent: undefined,
    path: "/orders",
    name: "Moja zamówienia",
    isLogin: true,
    view: "both",
    element: <OrderPage />,
  },
  {
    id: "order_manager",
    parent: "orders",
    path: "/orders/order_manager",
    name: "Manager zamówienia",
    isLogin: true,
    view: "both",
    element: <OrdersManagerComponent />,
  },
  {
    id: "basket",
    parent: undefined,
    path: "/basket",
    name: "Twoje zamówienie",
    isLogin: true,
    view: "both",
    element: <BasketPage />,
  },
  {
    id: "wall",
    parent: undefined,
    path: "/wall",
    name: "Tablica",
    isLogin: true,
    view: "both",
    element: <WallPage />,
  },
];

export const useNavigation = () => {
  const { removeToken } = useLogin();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const findPage = routeStack.find((x) => x.path === location.pathname);
    if (findPage) {
      dispatch(changeTypeRoutePage(findPage.id));
      dispatch(changeMainRoute(findPage.parent ?? findPage.id));
    }
  }, []);

  const navigateTo = (path: TypeRoutePage, paramsURL: string = "", options?: NavigateOptions) => {
    const findPage = routeStack.find((x) => x.id === path);
    if (findPage) {
      navigate(`${findPage?.path}${paramsURL}`, options);
      dispatch(changeTypeRoutePage(findPage.id));
      dispatch(changeMainRoute(findPage.parent ?? findPage.id));
    }
  };

  const navigateLogOut = () => {
    dispatch(changeMainState({ mainRoute: undefined, typeRoutePage: undefined }));
    dispatch(setConversation([]));
    dispatch(changeView("client"));
    dispatch(logOut());
    removeToken();
    navigate("/");
  };

  return { navigateTo, routeStack, navigate, navigateLogOut };
};
