import React from "react";
import * as Icon from "commons/iconManager";
import * as SupplierApi from "api/SupplierApi";
import * as ProductApi from "api/ProductApi";
import PathComponent from "components/PathPageComponent";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { Grid } from "controls/Grid";
import { GridColumn } from "controls/GridColumn";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { Label } from "controls/Label";
import { Tag } from "controls/Tag";
import { calculatePercentFromOneHundred } from "commons/numberManager";
import RatingStarComponent from "components/RatingStarComponent";
import RatingCommentComponent from "components/RatingCommentComponent";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import PictureBoxComponent from "components/PictureBoxComponent";
import { Product } from "models/new/Product";
import { changeSupplier } from "reducers/userReducer";
import EmptyComponent from "components/EmptyComponent";
import { useWindowSize } from "hooks/useWindowSize";
import GroceryMobilePage from "./components/mobile/GroceryMobilePage";
import { NotificationType } from "models/new/Dictionaries";
import { useNotify } from "hooks/useNotify";

const GroceryPage: React.FC = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const { isMobile } = useWindowSize();
  const { addNotify } = useNotify();
  const { navigateTo } = useNavigation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = "Ogrosa | Produkty";
  }, []);

  React.useEffect(() => {
    downloadData();
  }, [dispatch]);

  const downloadData = async () => {
    if (!userState.supplier) return;

    SupplierApi.getSupplierProducts(userState.supplier.id)
      .then((value) => {
        dispatch(changeSupplier({ supplier: { products: value } }));
      })
      .catch((err) => alert(err));
  };

  function onRefresh(): void {
    downloadData();
  }

  const onDeleteItem = async (product: Product) => {
    const result = await ModalDialog.show(
      <MessageBox icon="Question" content={`Czy napewno chcesz usunąć produkt ${product.name}?`} />,
      "Usuwanie produktu",
      ["YesNo"]
    );
    if (result.result === "Yes") {
      await ProductApi.deleteProduct(product.id)
        .then((value) => {
          addNotify({ title: "Usunięto", content: "Produkt został usunięty!", type: NotificationType.Success });
          downloadData();
        })
        .catch((err) => alert(err));
    }
  };

  const onDisplayContentName = (item: Product): JSX.Element => {
    return (
      <div className="d-flex fd-c ai-s-flex jc-center gap-2">
        <Label
          islink
          fontSize="lg"
          fontStyle="semibold"
          content={item.name}
          onClick={() => navigateTo("grocery_manager", `?action=edit&tab=data&id=${item.id}`)}
        />
        <Label fontSize="sm" fontColor="secondary" content={item.categoryName} />
      </div>
    );
  };
  const onDisplayContentPicture = (item: Product): JSX.Element => {
    return (
      <div className="d-flex ai-center jc-center">
        <PictureBoxComponent url={item.photoUrl} className="image-on-list" />
      </div>
    );
  };
  const onDisplayContentQuantity = (item: Product): JSX.Element => {
    return (
      <div className="d-flex fd-c ai-s-flex jc-center gap-8">
        <div className="d-flex gap-2">
          <Label fontStyle="semibold" content={item.quantity?.toString()} />
          <Label
            className="pad-b-8"
            fontSize="sml"
            fontColor="secondary"
            fontStyle="semibold"
            pointer
            wrap
            content={item.unit}
          />
        </div>
        {item.availableType === "Available" ? (
          <Tag
            sizeFrame="xsm"
            fontStyle="bold"
            fontSize="sml"
            kind="green"
            label="Dostępny"
            onClick={() => navigateTo("grocery_manager", `?action=edit&tab=available&id=${item.id}`)}
          />
        ) : item.availableType === "Unavailable" ? (
          <Tag
            sizeFrame="xsm"
            fontStyle="bold"
            fontSize="sml"
            kind="red"
            label="Niedostępny"
            onClick={() => navigateTo("grocery_manager", `?action=edit&tab=available&id=${item.id}`)}
          />
        ) : (
          <Tag
            sizeFrame="xsm"
            fontStyle="bold"
            fontSize="sml"
            kind="yellow"
            label={`Dostępny od ${new Date(item.whenAvailable ?? "")?.toFormat("dd.MM.yyyy")}`}
            onClick={() => navigateTo("grocery_manager", `?action=edit&tab=available&id=${item.id}`)}
          />
        )}
      </div>
    );
  };
  const onDisplayContentPrice = (item: Product): JSX.Element => {
    return (
      <div className="d-flex gap-2">
        <Label fontSize="sm" fontStyle="semibold" content={`${item.price} zł/`} />
        <Label
          className="pad-b-8"
          fontSize="sml"
          fontColor="secondary"
          fontStyle="semibold"
          pointer
          wrap
          content={item.unit}
        />
      </div>
    );
  };
  const onDisplayContentSalePrice = (item: Product): JSX.Element => {
    if (!item.salePrice)
      return (
        <div className="d-flex fd-c ai-s-flex jc-center gap-4">
          <Button
            sizeFrame="sml"
            kind="text"
            icon={<Icon.AddSVG />}
            onClick={() => navigateTo("grocery_manager", `?action=edit&tab=sale&id=${item.id}`)}
            toolTip={"Dodaj promocje"}
          />
        </div>
      );

    return (
      <div
        className="d-flex fd-r ai-center jc-s-flex gap-8 c-pointer"
        onClick={() => navigateTo("grocery_manager", `?action=edit&tab=sale&id=${item.id}`)}
      >
        <div className="d-flex gap-2">
          <Label fontSize="sm" fontStyle="semibold" content={`${item.salePrice} zł/`} />
          <Label
            className="pad-b-8"
            fontSize="sml"
            fontColor="secondary"
            fontStyle="semibold"
            pointer
            wrap
            content={item.unit}
          />
        </div>
        <Tag
          sizeFrame="xsm"
          fontStyle="bold"
          kind="red"
          label={`-${calculatePercentFromOneHundred(item.price, item.salePrice!)}%`}
        />
      </div>
    );
  };
  const onDisplayContentRate = (item: Product): JSX.Element => {
    return (
      <div
        className="d-flex fd-c ai-s-flex jc-center gap-2 c-pointer"
        onClick={() => navigateTo("grocery_manager", `?action=preview&tab=rate&id=${item.id}`)}
      >
        <RatingStarComponent ratingQty={item.ratingQty} rating={item.rating} />
        <RatingCommentComponent ratingQty={item.ratingQty} />
        <Label fontSize="sml" wrap content={`Wyświetlenia: ${item?.visitorsQty?.toString()}`} />
      </div>
    );
  };
  const onDisplayContentButtons = (item: Product): JSX.Element => {
    return (
      <div className="d-flex fd-r ai-center jc-e-flex gap-8">
        <Button
          style={{ paddingInline: "8px" }}
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.DeleteSVG />}
          onClick={() => onDeleteItem(item)}
          toolTip={"Usuń"}
          toolTipPosition="left"
        />
        <Button
          style={{ paddingInline: "8px" }}
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.EditSVG />}
          onClick={() => navigateTo("grocery_manager", `?action=edit&tab=data&id=${item.id}`)}
          toolTip={"Edycja"}
          toolTipPosition="left"
        />
      </div>
    );
  };

  if (isMobile) return <GroceryMobilePage />;

  return (
    <div className="f-fill">
      <div className="frame-header jc-sb">
        <PathComponent onRefreshClick={onRefresh} />
        <div className="d-flex fd-r gap-8">
          <Button
            sizeFrame="sm"
            kind="primary"
            label="Dodaj produkt"
            fontStyle="bold"
            icon={<Icon.AddSVG />}
            onClick={() => navigateTo("grocery_manager", "?action=create&tab=data")}
          />
        </div>
      </div>
      {userState.supplier?.products && userState.supplier?.products.length > 0 ? (
        <Grid itemSource={userState.supplier.products}>
          <GridColumn width={125} onDisplayContent={onDisplayContentPicture} />
          <GridColumn width={"*"} header="Nazwa" binding="name" onDisplayContent={onDisplayContentName} />
          <GridColumn
            width={200}
            header="Stan magazynowy"
            binding="quantity"
            sortableBy="quantity"
            onDisplayContent={onDisplayContentQuantity}
          />
          <GridColumn
            width={150}
            header="Cena"
            binding="price"
            sortableBy="price"
            onDisplayContent={onDisplayContentPrice}
          />
          <GridColumn width={175} header="Cena promocyjna" onDisplayContent={onDisplayContentSalePrice} />
          <GridColumn width={200} header="Ocena" onDisplayContent={onDisplayContentRate} />
          <GridColumn width={80} algin="center" onDisplayContent={onDisplayContentButtons} />
        </Grid>
      ) : (
        <EmptyComponent style={{ height: "50%" }} icon={<Icon.GrocerySVG />} message="Brak produktów" />
      )}
    </div>
  );
};

export default GroceryPage;
