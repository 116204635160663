import { createAction, createReducer } from "@reduxjs/toolkit";
import { SaleState } from "../models/new/State";
import { Sale } from "../models/new/Sale";
import { DeliveryType, GroupByDate, StatusOrder } from "../models/new/Dictionaries";
import { SearchOrderFilter } from "models/new/SearchOrderFilter";

const dateFrom= new Date();
dateFrom.setDate(dateFrom.getDate() - 60);

export const initialFilter: SearchOrderFilter = {
  deliveryType: DeliveryType.All,
  statusOrder: StatusOrder.All,
  groupByDate: GroupByDate.Day,
  sort: "DESC",
  take: 1000,
  dateFrom: dateFrom,
  dateTo: new Date(),
  view: 'list'
};

const initialState = {
  sales: [],
  filter: initialFilter,
} as SaleState;

export const getSales = createAction<Sale[]>("sale/getSales");
export const setSelectedSale = createAction<Sale | undefined>("sale/setSelectedSale");
export const createSale = createAction<Sale>("sale/createSale");
export const updateSale = createAction<Sale>("sale/updateSale");
export const deleteSale = createAction<Sale>("sale/deleteSale");
export const changeSaleFilter = createAction<Partial<SearchOrderFilter>>("sale/changeSaleFilter");
export const clearSaleFilter = createAction("sale/clearSaleFilter");

export const saleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSales, (state, action) => {
      state.sales = action.payload;
    })
    .addCase(setSelectedSale, (state, action) => {
      state.selected = action.payload;
    })
    .addCase(createSale, (state, action) => {
      state.sales.push(action.payload);
    })
    .addCase(updateSale, (state, action) => {
      state.sales = state.sales.map((x) => (x.id === action.payload.id ? action.payload : x));
    })
    .addCase(deleteSale, (state, action) => {
      state.sales = state.sales.filter((x) => x.id !== action.payload.id);
    })
    .addCase(changeSaleFilter, (state, action) => {
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      };
    })
    .addCase(clearSaleFilter, (state) => {
      state.filter = initialFilter;
    });
});
