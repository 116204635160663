import React from "react";
import LandingHeaderComponent from "./components/LandingHeaderComponent";
import LandingMainClientInfoComponent from "./components/LandingMainClientInfoComponent";
import LandingVerificationComponent from "./components/LandingVerificationComponent";
import LandingShipmentComponent from "./components/LandingShipmentComponent";
import LandingCTAComponent from "./components/LandingCTAComponent";
import LandingFooterComponent from "./components/LandingFooterComponent";
import LandingMainDealerInfoComponent from "./components/LandingMainDealerInfoComponent";
import LandingDealerBenefitsComponent from "./components/LandingDealerBenefitsComponent";
import LandingOptionsComponent from "./components/LandingOptionsComponent";
import { useSelector } from "react-redux";
import { LandingState } from "models/new/State";

const LandingPage: React.FC = () => {
  const landingState: LandingState = useSelector((state: any) => state.landing);

  return (
    <div className="frame-scroll ai-center gap-32" style={{ marginBlock: "-8px" }}>
      <LandingHeaderComponent />
      <LandingOptionsComponent />
      {landingState.who === "supplier" && <LandingMainDealerInfoComponent />}
      {landingState.who === "supplier" && <LandingDealerBenefitsComponent />}
      {landingState.who === "client" && <LandingMainClientInfoComponent />}
      {landingState.who === "client" && <LandingVerificationComponent />}
      {landingState.who === "client" && <LandingShipmentComponent />}
      <LandingCTAComponent />
      <LandingFooterComponent />
    </div>
  );
};

export default LandingPage;
