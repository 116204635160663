import React from "react";
import { Button } from "./Button";
import * as Icon from "commons/iconManager";
import { PopUp } from "./PopUp";
import DataPickerComponent from "components/DataPickerComponent";
import DataRangePickerComponent from "components/DataRangePickerComponent";
import { InputTextProps, getFontFamily, getKind, getSize, getSizeForIcon } from "./InputText";
import { Label } from "./Label";

export interface InputDateProps extends InputTextProps, React.InputHTMLAttributes<HTMLInputElement> {
  dateValue?: Date;
  rangeValue?: { from: Date; to?: Date };
  onChangeRange?: (range: { from: Date; to: Date }) => void;
  format?: string;
}

const initializeRange = (range: { from: Date; to?: Date } | undefined): { from: Date; to: Date } | undefined => {
  if (!range) return undefined;

  let from = new Date(range.from);
  let to = new Date(range.to ?? "");

  if (isNaN(from.getTime())) {
    from = new Date();
  }

  if (isNaN(to.getTime())) {
    to = new Date();
  }

  return { from, to };
};

export const InputDate = React.forwardRef<HTMLInputElement, InputDateProps>((props, ref) => {
  const [focused, setFocused] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date | undefined>(props.dateValue);
  const [range, setRangeDate] = React.useState<{ from: Date; to: Date } | undefined>(initializeRange(props.rangeValue));
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const divRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useImperativeHandle(ref, () => inputRef.current);
  const format = props.format ?? "DD.MM.yyyy";

  React.useEffect(() => {
    if(!props.dateValue){
      inputRef.current.value = 'Wybierz datę';
      setDate(props.dateValue);
    } else {
    inputRef.current.value = props.dateValue.toFormatMoment(format);
    setDate(props.dateValue);
    }
  }, [props.dateValue]);

  React.useEffect(() => {
    if (props.rangeValue) {
      inputRef.current.value = `${props.rangeValue?.from.toFormatMoment(
        "DD.MM.yyyy"
      )} - ${props.rangeValue.to?.toFormatMoment("DD.MM.yyyy")}`;
      setRangeDate(initializeRange(props.rangeValue));
    }
  }, [props.rangeValue]);

  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += getFontFamily(props.family);

    if (focused) {
      style += " input-focused";
    }
    if (props.error) {
      style += " mag-b-16";
    }
    if (props.label) {
      style += " mag-t-16";
    }
    if (props.error) {
      style += " input-error";
    }

    return `${style} ${props.frameClassName}`;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }
  const onFocus = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onFocus?.(evt);
    setFocused(true);
  };
  const onBlur = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onBlur?.(evt);
    setFocused(false);
  };

  const onClose = (date: Date) => {
    PopUp.onHide();
    setDate(date);
    const simulatedEvent = {
      target: { ...inputRef.current, value: date.toFormat(format), valueAsDate: date },
    };
    props.onChange?.(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
  };

  const onCloseRange = (range: { from: Date; to: Date }) => {
    PopUp.onHide();
    setRangeDate(range);
    props.onChangeRange?.(range);
  };

  const showPopUp = async () => {
    if (range) {
      await PopUp.show(
        divRef.current,
        <DataRangePickerComponent range={range} onClose={onCloseRange} format={format} />,
        "bottom",
        150
      );
    } else {
      await PopUp.show(
        divRef.current,
        <DataPickerComponent onClose={onClose} date={date} format={format} />,
        "bottom",
        150
      );
    }
  };

  return (
    <div ref={divRef} className={getStyle()} style={{ ...props.frameStyle }}>
      {clonedIcon}
      <div className="title d-flex gap-4 ai-center">
        <Label content={props.label} fontSize="sml" fontStyle="semibold" />
        {props.isRequired && <Label content="*" fontColor="red" fontSize="sml" fontStyle="bold" />}
      </div>
      <input
        {...props}
        ref={inputRef}
        className={`${getFontFamily()}`}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        disabled
        onClick={showPopUp}
      />
      {props.disabled !== true && (
        <Button tabIndex={-1} sizeFrame="sml" kind="text" icon={<Icon.CalendarMonthSVG />} onClick={showPopUp} />
      )}
      <label className="error">{props.error}</label>
    </div>
  );
});
