import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "./Label";
import { Button } from "./Button";
import { InputTextProps, getFontFamily, getKind, getSize, getSizeForIcon } from "./InputText";
import { useWindowSize } from "hooks/useWindowSize";

export interface InputNumberProps extends InputTextProps, React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  unit?: string;
  canClear?: boolean;
  fixed?: number;
  hideButton?: boolean;
}

export const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  const [focused, setFocused] = React.useState<boolean>(false);
  const [showClear, setShowClear] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string | number | readonly string[] | undefined>();
  const [error, setError] = React.useState<string>();
  const { isMobile } = useWindowSize();
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const lastValue = React.useRef<string>("");
  React.useImperativeHandle(ref, () => inputRef.current);

  React.useEffect(() => {
    const valueAsNumber = Number(props.value?.toString()?.replace(",", "."));
    setValues(isNaN(valueAsNumber) ? "" : valueAsNumber.toFixed(props.fixed).replace(".", ","));
  }, [props.value]);

  React.useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += getFontFamily(props.family);

    if (focused) {
      style += " input-focused";
    }
    if (error) {
      style += " mag-b-16";
    }
    if (props.label) {
      style += " mag-t-16";
    }
    if (error) {
      style += " input-error";
    }

    return `${style} ${props.frameClassName}`;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }
  const onFocus = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onFocus?.(evt);
    setFocused(true);
  };
  const onBlur = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onBlur?.(evt);
    setFocused(false);
    submitChanges(lastValue.current);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setFocused(false);
    submitChanges(lastValue.current);
  };
  const onChange = (e) => {
    const newValue = e.target.value;
    const regex = /^-?\d{1,30}(,\d{0,30})?$/;

    if (newValue === "-" || newValue === "" || regex.test(newValue)) {
      setValues(newValue);
    }
  };

  const setValues = (v: string) => {
    setValue(v);
    lastValue.current = v;
    setShowClear(v.length > 0);
    valideValue();
  };

  const valideValue = () => {
    setError(undefined);
    const value = parseFloat(lastValue.current);

    if (props.min !== undefined && parseFloat(props.min.toString()) > value) {
      setError(`Wartość nie może być mniejsza niż ${props.min}.`);
    }

    if (props.max !== undefined && parseFloat(props.max.toString()) < value) {
      setError(`Wartość nie może być wieksza niż ${props.max}.`);
    }
  };

  const onClear = () => {
    submitChanges("");
    setError(undefined);
    setValues("");
    inputRef.current.focus();
  };

  const convertValue = (value: any): number => {
    let normalizedValue = value.toString().replace(/,/g, ".").replace(/\s+/g, "");
    const number = parseFloat(normalizedValue);
    return isNaN(number) ? 0 : number;
  };

  const addValue = (increase: number) => {
    let newValue = convertValue(inputRef.current.value?.replace(",", ".") ?? 0);

    let step = 1;
    if (props.step && !isNaN(Number(props.step))) {
      step = Number(props.step);
    }

    if (isNaN(newValue)) newValue = step * increase;
    else newValue += step * increase;

    submitChanges(newValue.toString());
    inputRef.current.focus();
  };

  const submitChanges = (value: string) => {
    if (value.length === 0) {
      setValues("");
      props.onSubmit?.(setTargetValue());
      return;
    }

    let newValue = convertValue(value?.replace(",", ".") ?? 0);

    if (props.min != undefined) {
      newValue = Math.max(newValue, Number(props.min));
    }

    if (props.max != undefined) {
      newValue = Math.min(newValue, Number(props.max));
    }

    if (props.fixed !== undefined) {
      setValues(newValue.toFixed(props.fixed).replace(".", ","));
    } else {
      setValues(newValue.toString().replace(".", ","));
    }

    props.onSubmit?.(setTargetValue());
  };

  const displayClear = (): boolean => {
    return props.canClear !== false && props.disabled !== true && showClear;
  };

  const showButton = (): boolean => {
    if (isMobile) return false;
    return !props.disabled && props.hideButton !== true;
  };

  const setTargetValue = (): React.ChangeEvent<HTMLInputElement> => {
    const valueAsNumber = lastValue.current.length === 0 ? NaN : Number(lastValue.current.replace(",", "."));
    const simulatedEvent = {
      target: { value: lastValue.current, valueAsNumber: valueAsNumber },
      currentTarget: { value: lastValue.current, valueAsNumber: valueAsNumber },
    };
    return simulatedEvent as React.ChangeEvent<HTMLInputElement>;
  };

  return (
    <div ref={props.frameRef} className={getStyle()} style={{ ...props.frameStyle }}>
      {props.iconPosition !== "right" && clonedIcon}
      <div className="title d-flex gap-4 ai-center">
        <Label content={props.label} fontSize="sml" fontStyle="semibold" />
        {props.isRequired && <Label content="*" fontColor="red" fontSize="sml" fontStyle="bold" />}
      </div>
      <form className="width-fill" onSubmit={onSubmit}>
        <input
          {...props}
          ref={inputRef}
          className={`${getFontFamily()}`}
          type="text"
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      </form>
      <Button
        tabIndex={-1}
        style={{ padding: "0px", visibility: displayClear() ? "visible" : "hidden" }}
        sizeFrame="sml"
        kind="text"
        icon={<Icon.CloseSVG className="close" />}
        onClick={onClear}
      />
      {showButton() && (
        <div className="d-flex ai-center gap-2">
          <Button
            tabIndex={-1}
            style={{ padding: "0px" }}
            sizeFrame={"sml"}
            kind="text"
            icon={<Icon.RemoveSVG />}
            onClick={() => addValue(-1)}
          />
          <Label style={{ userSelect: "none" }} fontColor="tertiary" fontSize="sml" content="|" />
          <Button
            tabIndex={-1}
            style={{ padding: "0px" }}
            sizeFrame={"sml"}
            kind="text"
            icon={<Icon.AddSVG />}
            onClick={() => addValue(1)}
          />
        </div>
      )}
      {props.iconPosition === "right" && clonedIcon}
      {props.unit && <Label style={{ userSelect: "none" }} fontStyle="bold" content={props.unit} />}
      <label className="error">{error}</label>
    </div>
  );
});
