import React from "react";
import { Label } from "controls/Label";
import { OpenHour } from "models/new/OpenHour";
import { convertToHHmm, daysArray } from "commons/dataTimeManager";
import { OpenHourProps } from "./OpenHourComponent";

const OpenHourBoxComponent: React.FC<OpenHourProps> = (props) => {
  if (!props.address) return <></>;

  const dayRow = (idx: number) => {
    const openHour: OpenHour | undefined = props.address?.openHours?.find((x) => x.day === idx);
    if (!openHour) {
      return (
        <div className="d-flex">
          <Label
            style={{ width: "125px" }}
            fontSize={props.fontSize ?? "sm"}
            fontStyle={props.fontStyle ?? "semibold"}
            fontColor={props.fontColor ?? "primary"}
            content={daysArray[idx]}
          />
          <Label
            fontSize={props.fontSize ?? "sm"}
            fontStyle={props.fontStyle ?? "semibold"}
            fontColor={props.fontColor ?? "primary"}
            content={"Nieczynne"}
          />
        </div>
      );
    } else {
      return (
        <div className="d-flex">
          <Label
            style={{ width: "125px" }}
            fontSize={props.fontSize ?? "sm"}
            fontStyle={props.fontStyle ?? "semibold"}
            fontColor={props.fontColor ?? "primary"}
            content={daysArray[idx]}
          />
          <Label
            fontSize={props.fontSize ?? "sm"}
            fontStyle={props.fontStyle ?? "semibold"}
            fontColor={props.fontColor ?? "primary"}
            content={`${convertToHHmm(openHour.startHour)} - ${convertToHHmm(openHour.closeHour)}`}
          />
        </div>
      );
    }
  };

  return (
    <div className="width-fill d-flex fd-c gap-8">
      {daysArray.map((x, idx) => {
        return dayRow(idx);
      })}
    </div>
  );
};

export default OpenHourBoxComponent;
