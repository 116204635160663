import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";
import { Certificate } from "models/new/Certificate";
import { Label } from "controls/Label";

interface CertificateProps {
  style?: React.CSSProperties;
  certificate: Certificate;
  onEdit: (certificate: Certificate) => void;
  onDelete: (certificate: Certificate) => void;
  disable?: boolean;
}

const CertificateComponent: React.FC<CertificateProps> = (props) => {
  const [certificate, setCertificate] = React.useState<Certificate>(props.certificate);

  React.useEffect(() => {
    setCertificate(props.certificate);
  }, [props.certificate]);

  const prev = () => {
    window.open(certificate.url, "_blank")?.focus();
  };

  return (
    <div style={{ ...props.style }} className="d-flex fd-c ai-s-flex gap-8 pad-b-16 border-bottom">
      <Label fontStyle="bold" fontSize="md" content={certificate.name} />
      <div className="d-flex fd-r ai-center gap-8">
        <Label fontStyle="semibold" fontSize="sm" content={certificate.docNumber} />
        <Button
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.DescriptionSVG />}
          onClick={prev}
          toolTip={"Podgląd dokumentu"}
        />
        <Button
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.EditSVG />}
          disabled={props.disable}
          onClick={() => props.onEdit(certificate)}
          toolTip={"Edytuj certyfikat"}
        />
        <Button
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.DeleteSVG />}
          disabled={props.disable}
          onClick={() => props.onDelete(props.certificate)}
          toolTip={"Usuń certyfikat"}
        />
      </div>
    </div>
  );
};

export default CertificateComponent;
