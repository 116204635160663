import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "./Button";
import { InputTextProps, getFontFamily, getKind, getSize, getSizeForIcon } from "./InputText";
import { Label } from "./Label";

export const InputPassword = React.forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const [focused, setFocused] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [showClear, setShowClear] = React.useState<boolean>(false);
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useImperativeHandle(ref, () => inputRef.current);

  const onKeyPress = React.useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === "Enter") {
        const simulatedEvent = {
          target: { value: inputRef.current.value },
          currentTarget: { value: inputRef.current.value },
        };
        props.onSubmit?.(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
        ev.preventDefault();
        //inputRef.current.value = "";
      }
    },
    [inputRef.current]
  );

  React.useEffect(() => {
    inputRef.current?.addEventListener("keypress", onKeyPress);
    return () => {
      inputRef.current?.removeEventListener("keypress", onKeyPress);
    };
  }, [onKeyPress]);

  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += getFontFamily(props.family);

    if (focused) {
      style += " input-focused";
    }
    if (props.error) {
      style += " mag-b-16";
    }
    if (props.label) {
      style += " mag-t-16";
    }
    if (props.error) {
      style += " input-error";
    }

    return `${style} ${props.frameClassName}`;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }

  let passwordIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    passwordIcon = React.cloneElement(show ? <Icon.VisibilityOffSVG /> : <Icon.VisibilitySVG />, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
        cursor: "pointer",
      },
      onClick: () => setShow(!show),
    });
  }

  const onFocus = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onFocus?.(evt);
    setFocused(true);
  };
  const onBlur = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onBlur?.(evt);
    setFocused(false);
  };
  const onClear = () => {
    inputRef.current.value = "";
    const simulatedEvent = {
      target: { value: "" },
      currentTarget: { value: "" },
    };
    props.onChange?.(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
    props.onSubmit?.(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
    inputRef.current.focus();
    setShowClear(false);
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowClear(event.target.value.length > 0);
    props.onChange?.(event);
  };

  return (
    <div ref={props.frameRef} className={getStyle()} style={{ ...props.frameStyle }}>
      {clonedIcon}
      <div className="title d-flex gap-4 ai-center">
        <Label content={props.label} fontSize="sml" fontStyle="semibold" />
        {props.isRequired && <Label content="*" fontColor="red" fontSize="sml" fontStyle="bold" />}
      </div>
      <input
        {...props}
        ref={inputRef}
        className={`${getFontFamily()}`}
        type={show ? "text" : "password"}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
      {props.canClear !== false && props.disabled !== true && showClear && (
        <Button
          tabIndex={-1}
          style={{ padding: "4px" }}
          sizeFrame="sml"
          kind="text"
          icon={<Icon.CloseSVG className="close" />}
          onClick={onClear}
        />
      )}
      {passwordIcon}
      <label className="error">{props.error}</label>
    </div>
  );
});
