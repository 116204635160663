import React from "react";
import * as Icon from "commons/iconManager";
import EmptyComponent from "../EmptyComponent";
import NotificationManagerItemComponent from "./NotificationManagerItemComponent";
import { useSelector } from "react-redux";
import { NotificationState } from "models/new/State";
import { Button } from "controls/Button";
import { useNotify } from "hooks/useNotify";

const NotificationManagerComponent: React.FC = () => {
  const notificationState: NotificationState = useSelector((state: any) => state.notification);
  const { deleteAllNotify } = useNotify();

  return (
    <div className="frame-main">
      {notificationState.notification?.length === 0 ? (
        <EmptyComponent message="Brak nowych powiadomień" icon={<Icon.NotificationsSVG />} />
      ) : (
        <div className="frame-main">
          <Button
            style={{ marginRight: "8px", marginLeft: "auto" }}
            sizeFrame="sml"
            fontStyle="extra-bold"
            kind="tertiary"
            label="Wyczyść powiadomienia"
            onClick={deleteAllNotify}
          />
          <div className="frame-scroll gap-16 pad-r-4 mag-8">
            {[...notificationState.notification]
              ?.sort((a, b) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
              })
              ?.map((item) => {
                return <NotificationManagerItemComponent key={item.id} notification={item} />;
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationManagerComponent;
