export { ReactComponent as OgrosaSVG } from "../assets/svg/ogrosa_logo.svg";
export { ReactComponent as OgrosaFullSVG } from "../assets/svg/ogrosa_logo_full.svg";

export { ReactComponent as OgrosaColorSVG } from "../assets/svg/ogrosa_color.svg";
export { ReactComponent as OgrosaGraySVG } from "../assets/svg/ogrosa_grey.svg";

export { ReactComponent as MenuSVG } from "../assets/svg/menu.svg";
export { ReactComponent as CloseSVG } from "../assets/svg/close.svg";

export { ReactComponent as TableRowsNarrowSVG } from "../assets/svg/table_rows_narrow.svg";
export { ReactComponent as ViewAgendaSVG } from "../assets/svg/view_agenda.svg";
export { ReactComponent as ViewListSVG } from "../assets/svg/view_list.svg";

export { ReactComponent as SentimentSatisfiedSVG } from "../assets/svg/sentiment_satisfied.svg";
export { ReactComponent as AddPhotoAlternateSVG } from "../assets/svg/add_photo_alternate.svg";
export { ReactComponent as MapSVG } from "../assets/svg/map.svg";

export { ReactComponent as DarkModeSVG } from "../assets/svg/darkMode.svg";
export { ReactComponent as LightModeSVG } from "../assets/svg/sunTheme.svg";

export { ReactComponent as CancelSVG } from "../assets/svg/cancel.svg";

export { ReactComponent as FormatListBulletedSVG } from "../assets/svg/format_list_bulleted.svg";
export { ReactComponent as DistanceSVG } from "../assets/svg/distance.svg";

export { ReactComponent as DoneSVG } from "../assets/svg/done.svg";
export { ReactComponent as SwitchAccountSVG } from "../assets/svg/switch_account.svg";
export { ReactComponent as AddSVG } from "../assets/svg/add.svg";
export { ReactComponent as RemoveSVG } from "../assets/svg/remove.svg";
export { ReactComponent as EditSVG } from "../assets/svg/edit.svg";
export { ReactComponent as DeleteSVG } from "../assets/svg/delete.svg";
export { ReactComponent as SearchSVG } from "../assets/svg/search.svg";
export { ReactComponent as GrocerySVG } from "../assets/svg/grocery.svg";
export { ReactComponent as ShoppingBagSVG } from "../assets/svg/shopping_bag.svg";
export { ReactComponent as ShoppingCartSVG } from "../assets/svg/shopping_cart.svg";
export { ReactComponent as ReceiptSVG } from "../assets/svg/receipt.svg";
export { ReactComponent as ReceiptLongSVG } from "../assets/svg/receipt_long.svg";
export { ReactComponent as CalendarMonthSVG } from "../assets/svg/calendar_month.svg";
export { ReactComponent as OrderPlaySVG } from "../assets/svg/order_play.svg";
export { ReactComponent as InactiveOrderSVG } from "../assets/svg/inactive_order.svg";
export { ReactComponent as OrderApproveSVG } from "../assets/svg/order_approve.svg";
export { ReactComponent as CallSVG } from "../assets/svg/call.svg";
export { ReactComponent as NavigationSVG } from "../assets/svg/navigation.svg";
export { ReactComponent as AddNavigationSVG } from "../assets/svg/add_location.svg";
export { ReactComponent as LocationOffSVG } from "../assets/svg/location_off.svg";
export { ReactComponent as PrintSVG } from "../assets/svg/print.svg";
export { ReactComponent as ShareSVG } from "../assets/svg/share.svg";
export { ReactComponent as DescriptionSVG } from "../assets/svg/description.svg";
export { ReactComponent as SaveSVG } from "../assets/svg/save.svg";
export { ReactComponent as NoPhotographySVG } from "../assets/svg/no_photography.svg";
export { ReactComponent as UnlicenseSVG } from "../assets/svg/unlicense.svg";
export { ReactComponent as PersonPinSVG } from "../assets/svg/person_pin.svg";
export { ReactComponent as EventListSVG } from "../assets/svg/event_list.svg";
export { ReactComponent as TodaySVG } from "../assets/svg/today.svg";
export { ReactComponent as DateRangeSVG } from "../assets/svg/date_range.svg";
export { ReactComponent as CalendarEmptySVG } from "../assets/svg/calendar_empty.svg";
export { ReactComponent as PaidSVG } from "../assets/svg/paid.svg";
export { ReactComponent as GuardianSVG } from "../assets/svg/guardian.svg";
export { ReactComponent as AddShoppingCartSVG } from "../assets/svg/add_shopping_cart.svg";
export { ReactComponent as StoreSVG } from "../assets/svg/store.svg";
export { ReactComponent as SubdirectoryArrowRightSVG } from "../assets/svg/subdirectory_arrow_right.svg";
export { ReactComponent as FlagSVG } from "../assets/svg/flag.svg";

export { ReactComponent as StarSVG } from "../assets/svg/star.svg";
export { ReactComponent as StarHalfSVG } from "../assets/svg/star_half.svg";
export { ReactComponent as StarFillSVG } from "../assets/svg/star_fill.svg";
export { ReactComponent as CommentSVG } from "../assets/svg/comment.svg";
export { ReactComponent as LogoutSVG } from "../assets/svg/logout.svg";
export { ReactComponent as AccountCircleSVG } from "../assets/svg/account_circle.svg";
export { ReactComponent as SettingsSVG } from "../assets/svg/settings.svg";
export { ReactComponent as ShoppingBasketSVG } from "../assets/svg/shopping_basket.svg";
export { ReactComponent as NotificationsSVG } from "../assets/svg/notifications.svg";
export { ReactComponent as LocationOnSVG } from "../assets/svg/location_on.svg";
export { ReactComponent as ArrowForwardSVG } from "../assets/svg/arrow_forward.svg";
export { ReactComponent as ArrowBackSVG } from "../assets/svg/arrow_back.svg";
export { ReactComponent as ExpandLessSVG } from "../assets/svg/expand_less.svg";
export { ReactComponent as ExpandMoreSVG } from "../assets/svg/expand_more.svg";
export { ReactComponent as TuneSVG } from "../assets/svg/tune.svg";
export { ReactComponent as MyLocationSVG } from "../assets/svg/my_location.svg";
export { ReactComponent as SortDescSVG } from "../assets/svg/south.svg";
export { ReactComponent as SortSVG } from "../assets/svg/swap_vert.svg";
export { ReactComponent as SortAscSVG } from "../assets/svg/north.svg";
export { ReactComponent as RefreshSVG } from "../assets/svg/refresh.svg";
export { ReactComponent as VisibilityOffSVG } from "../assets/svg/visibility_off.svg";
export { ReactComponent as VisibilitySVG } from "../assets/svg/visibility.svg";
export { ReactComponent as InventorySVG } from "../assets/svg/inventory.svg";
export { ReactComponent as ShoppingModeSVG } from "../assets/svg/shoppingmode.svg";
export { ReactComponent as TrendingUpSVG } from "../assets/svg/trending_up.svg";
export { ReactComponent as HistorySVG } from "../assets/svg/history.svg";
export { ReactComponent as StorefrontSVG } from "../assets/svg/storefront.svg";
export { ReactComponent as StorefrontFillSVG } from "../assets/svg/storefront_fill.svg";
export { ReactComponent as HomePinSVG } from "../assets/svg/home_pin.svg";
export { ReactComponent as InfoSVG } from "../assets/svg/info.svg";
export { ReactComponent as WarehouseSVG } from "../assets/svg/warehouse.svg";
export { ReactComponent as LocalShippingSVG } from "../assets/svg/local_shipping.svg";
export { ReactComponent as PhotoCameraSVG } from "../assets/svg/photo_camera.svg";
export { ReactComponent as KeySVG } from "../assets/svg/key.svg";
export { ReactComponent as MailSVG } from "../assets/svg/mail.svg";
export { ReactComponent as MarkEmailUnreadSVG } from "../assets/svg/mark_email_unread.svg";
export { ReactComponent as DraftsSVG } from "../assets/svg/drafts.svg";
export { ReactComponent as MinimizeSVG } from "../assets/svg/minimize.svg";
export { ReactComponent as WebAssetSVG } from "../assets/svg/web_asset.svg";
export { ReactComponent as SendSVG } from "../assets/svg/send.svg";
export { ReactComponent as LicenseSVG } from "../assets/svg/license.svg";
export { ReactComponent as PublicSVG } from "../assets/svg/public.svg";
export { ReactComponent as PersonSVG } from "../assets/svg/person.svg";
export { ReactComponent as PersonAddSVG } from "../assets/svg/person_add.svg";
export { ReactComponent as PersonRemoveSVG } from "../assets/svg/person_remove.svg";
export { ReactComponent as MoreHorizSVG } from "../assets/svg/more_horiz.svg";
export { ReactComponent as MoreVertSVG } from "../assets/svg/more_vert.svg";
export { ReactComponent as HideImageSVG } from "../assets/svg/hide_image.svg";
export { ReactComponent as FilterCenterSVG } from "../assets/svg/filter_center.svg";
export { ReactComponent as LocationOnFillSVG } from "../assets/svg/location_on_fill.svg";
export { ReactComponent as TimerSVG } from "../assets/svg/timer.svg";

export { ReactComponent as BlikSVG } from "../assets/svg/blik.svg";
export { ReactComponent as CreditCardSVG } from "../assets/svg/credit_card.svg";
export { ReactComponent as PaymentsSVG } from "../assets/svg/payments.svg";
export { ReactComponent as AccountBalanceSVG } from "../assets/svg/account_balance.svg";

export { ReactComponent as Filter1SVG } from "../assets/svg/filter_1.svg";
export { ReactComponent as Filter2SVG } from "../assets/svg/filter_2.svg";
export { ReactComponent as Filter3SVG } from "../assets/svg/filter_3.svg";
export { ReactComponent as Filter4SVG } from "../assets/svg/filter_4.svg";
export { ReactComponent as Filter5SVG } from "../assets/svg/filter_5.svg";
export { ReactComponent as Filter6SVG } from "../assets/svg/filter_6.svg";
export { ReactComponent as Filter7SVG } from "../assets/svg/filter_7.svg";
export { ReactComponent as Filter8SVG } from "../assets/svg/filter_8.svg";
export { ReactComponent as Filter9SVG } from "../assets/svg/filter_9.svg";
export { ReactComponent as Filter9PlusSVG } from "../assets/svg/filter_9_plus.svg";

export { ReactComponent as PendingSVG } from "../assets/svg/pending.svg";
export { ReactComponent as OrdersSVG } from "../assets/svg/orders.svg";
export { ReactComponent as DangerousSVG } from "../assets/svg/dangerous.svg";
export { ReactComponent as AddBusinessSVG } from "../assets/svg/add_business.svg";

export { ReactComponent as LoadingSVG } from "../assets/svg/ring.svg";
export { ReactComponent as LoadingGraySVG } from "../assets/svg/ring_gray.svg";

export { ReactComponent as GoogleSVG } from "../assets/svg/google.svg";
export { ReactComponent as FacebookSVG } from "../assets/svg/facebook.svg";
export { ReactComponent as InstagramSVG } from "../assets/svg/instagram.svg";

export { ReactComponent as WarningSVG } from "../assets/svg/warning.svg";
export { ReactComponent as ErrorSVG } from "../assets/svg/error.svg";
export { ReactComponent as HelpSVG } from "../assets/svg/help.svg";
export { ReactComponent as CheckCircleSVG } from "../assets/svg/check_circle.svg";
export { ReactComponent as ReportSVG } from "../assets/svg/report.svg";
export { ReactComponent as DragHandleSVG } from "../assets/svg/drag_handle.svg";

export { ReactComponent as DirectionsCarSVG } from "../assets/svg/directions_car.svg";
export { ReactComponent as HomeSVG } from "../assets/svg/home.svg";

export { ReactComponent as RadioButtonUncheckedSVG } from "../assets/svg/radio_button_unchecked.svg";
export { ReactComponent as RadioButtonCheckedSVG } from "../assets/svg/radio_button_checked.svg";
export { ReactComponent as CheckCircleFillSVG } from "../assets/svg/check_circle_fill.svg";
export { ReactComponent as ErrorFillSVG } from "../assets/svg/error_fill.svg";

const randomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const imageProductStock = (): string => {
  const random = randomInt(1, 10);
  switch (random) {
    case 1:
      return "https://images.pexels.com/photos/144248/potatoes-vegetables-erdfrucht-bio-144248.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 2:
      return "https://images.pexels.com/photos/533280/pexels-photo-533280.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 3:
      return "https://images.pexels.com/photos/533342/pexels-photo-533342.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 4:
      return "https://images.pexels.com/photos/3650647/pexels-photo-3650647.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 5:
      return "https://images.pexels.com/photos/5758850/pexels-photo-5758850.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 6:
      return "https://images.pexels.com/photos/1510392/pexels-photo-1510392.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 7:
      return "https://images.pexels.com/photos/207085/pexels-photo-207085.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 8:
      return "https://images.pexels.com/photos/2238309/pexels-photo-2238309.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 9:
      return "https://images.pexels.com/photos/2894205/pexels-photo-2894205.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 10:
      return "https://images.pexels.com/photos/70746/strawberries-red-fruit-royalty-free-70746.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    default:
      return "https://images.pexels.com/photos/70746/strawberries-red-fruit-royalty-free-70746.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
  }
};
//jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1
export const imageDealerStock = (clientId?: number): string => {
  const random = clientId ?? randomInt(1, 10);
  switch (random) {
    case 1:
      return "https://images.pexels.com/photos/45852/farmer-smile-man-person-45852.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 2:
      return "https://images.pexels.com/photos/5029764/pexels-photo-5029764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 3:
      return "https://images.pexels.com/photos/4918159/pexels-photo-4918159.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 4:
      return "https://images.pexels.com/photos/3019836/pexels-photo-3019836.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 5:
      return "https://images.pexels.com/photos/5231219/pexels-photo-5231219.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 6:
      return "https://images.pexels.com/photos/5231217/pexels-photo-5231217.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 7:
      return "https://images.pexels.com/photos/5231144/pexels-photo-5231144.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 8:
      return "https://images.pexels.com/photos/4918156/pexels-photo-4918156.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 9:
      return "https://images.pexels.com/photos/4911744/pexels-photo-4911744.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    case 10:
      return "https://images.pexels.com/photos/5529940/pexels-photo-5529940.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
    default:
      return "https://images.pexels.com/photos/5529940/pexels-photo-5529940.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
  }
};
