import React from "react";
import { OrderProduct } from "models/new/OrderProduct";
import BasketProductComponent from "./BasketProductComponent";

interface BasketProductsProps {
  products: OrderProduct[];
}

const BasketProductsComponent: React.FC<BasketProductsProps> = (props) => {
  return (
    <div className="d-flex fd-c">
      {props.products.map((product) => {
        return <BasketProductComponent product={product} />;
      })}
    </div>
  );
};

export default BasketProductsComponent;
