import React from "react";
import { UserState } from "models/new/State";
import { useSelector } from "react-redux";
import { ProfileTabProps } from "./ProfileManagerComponent";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import InfoBox from "controls/InfoBox";
import RatingStarComponent from "components/RatingStarComponent";
import RatingCommentComponent from "components/RatingCommentComponent";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";
import RatingComponent from "components/RatingComponent";
import { useWindowSize } from "hooks/useWindowSize";

const ProfileRatingComponent: React.FC<ProfileTabProps> = (props) => {
  const [selectedRate, setSelectedRate] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [seeMore, setSeeMore] = React.useState<boolean>(false);
  const userState: UserState = useSelector((state: any) => state.user);
  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    if (userState.supplier?.ratings) setIsLoading(false);
  }, [userState.supplier?.ratings]);

  if (isMobile) {
    return (
      <div id={props.id} className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill">
        <Label fontStyle="bold" fontSize="md" content="Oceny i komentarze" />
        {isLoading ? (
          <WaitOnLoadingComponent
            isLoading={isLoading}
            count={1}
            className="width-fill rounded-12"
            style={{ minHeight: "350px", marginTop: "8px", marginRight: "4px" }}
          />
        ) : (
          <>
            <div id={props.id} className="d-flex fd-c gap-8 width-fill">
              <RatingStarComponent
                ratings={userState.supplier?.ratings}
                selectedRate={selectedRate}
                setSelectedRate={setSelectedRate}
              />
              <RatingCommentComponent ratings={userState.supplier?.ratings} />
            </div>
            <div className="f-scroll-hidden-r gap-8" style={{ maxHeight: "500px" }}>
              {userState.supplier?.ratings
                ?.filter((x) => !selectedRate || x.rate === selectedRate)
                .map((rating, idx) => {
                  return seeMore ? (
                    <RatingComponent rating={rating} />
                  ) : idx < 1 ? (
                    <RatingComponent rating={rating} />
                  ) : null;
                })}
            </div>
            <Button
              sizeFrame="sml"
              kind="tertiary"
              label={seeMore ? "Mniej" : "Wiecej"}
              onClick={() => setSeeMore(!seeMore)}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div id={props.id} className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 width-fill" style={{ maxWidth: "500px" }}>
      <Label fontStyle="bold" fontSize="xl" content="Oceny i komentarze" />
      {isLoading ? (
        <WaitOnLoadingComponent
          isLoading={isLoading}
          count={1}
          className="width-fill rounded-12"
          style={{ minHeight: "350px", marginTop: "8px", marginRight: "4px" }}
        />
      ) : (
        <>
          <div id={props.id} className="d-flex fd-c gap-8 width-fill">
            <RatingStarComponent
              ratings={userState.supplier?.ratings}
              selectedRate={selectedRate}
              setSelectedRate={setSelectedRate}
            />
            <RatingCommentComponent ratings={userState.supplier?.ratings} />
          </div>
          <div className="frame-scroll gap-8" style={{ width: "516px", maxHeight: "500px", paddingRight: "8px" }}>
            {userState.supplier?.ratings
              ?.filter((x) => !selectedRate || x.rate === selectedRate)
              .map((rating, idx) => {
                return seeMore ? (
                  <RatingComponent rating={rating} />
                ) : idx < 1 ? (
                  <RatingComponent rating={rating} />
                ) : null;
              })}
          </div>
          <Button
            sizeFrame="sml"
            kind="tertiary"
            label={seeMore ? "Mniej" : "Wiecej"}
            onClick={() => setSeeMore(!seeMore)}
          />
        </>
      )}
    </div>
  );
};

export default ProfileRatingComponent;
