import { createAction, createReducer } from "@reduxjs/toolkit";
import { WallState } from "../models/new/State";
import { PostComment, PostLike } from "models/new/Post";


const initialState = { posts: [], filter: 'all', refresh: 0 } as WallState;

export const changeWallState = createAction<Partial<WallState>>("wall/changeWallState");
export const setPostLike = createAction<PostLike>("search/setPostLike");
export const setPostUnlike = createAction<PostLike>("search/setPostUnlike");
export const setPostComment = createAction<PostComment>("search/setPostComment");


export const wallReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeWallState, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(setPostLike, (state, action) => {
      state.posts = state.posts.map((post) =>
        post.id === action.payload.postId ? { ...post, likes: [...(post?.likes ?? []), action.payload] } : post
      );
    })
    .addCase(setPostUnlike, (state, action) => {
      state.posts = state.posts.map((post) =>
        post.id === action.payload.postId
          ? { ...post, likes: post?.likes?.filter((x) => x.id !== action.payload.id) }
          : post
      );
    })
    .addCase(setPostComment, (state, action) => {
      state.posts = state.posts.map((post) =>
        post.id === action.payload.postId ? { ...post, comments: [...(post.comments ?? []), action.payload] } : post
      );
    })
});
