import React from "react";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionWizardState } from "models/new/State";
import { InputText } from "controls/InputText";
import { changeSubscriptionWizard, setSubscriptionValideError } from "reducers/subscriptionWizardReducer";
import { useWindowSize } from "hooks/useWindowSize";

const SubscriptionWizardDataComponent: React.FC = () => {
  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const wizardState = React.useRef<SubscriptionWizardState>(subscriptionWizardState);

  React.useEffect(() => {
    wizardState.current = subscriptionWizardState;
  }, [subscriptionWizardState]);

  React.useEffect(() => {
    return () => {
      !isMobile &&
        dispatch(
          setSubscriptionValideError({
            companyName:
              wizardState.current.companyName && wizardState.current.companyName?.length > 0
                ? undefined
                : subscriptionWizardState.valideError?.companyName ?? "Nazwa gospodarstwa nie może być pusta.",
            firstName:
            wizardState.current.firstName && wizardState.current.firstName?.length > 0
                ? undefined
                : subscriptionWizardState.valideError?.firstName ?? "Imię nie może być puste.",
            lastName:
            wizardState.current.lastName && wizardState.current.lastName?.length > 0
                ? undefined
                : subscriptionWizardState.valideError?.lastName ?? "Nazwisko nie może być puste.",
            email:
            wizardState.current.email && wizardState.current.email?.length > 0
                ? undefined
                : subscriptionWizardState.valideError?.email ?? "Email nie może być pusty.",
            phoneNumber:
            wizardState.current.phoneNumber && wizardState.current.phoneNumber?.length > 0
                ? undefined
                : subscriptionWizardState.valideError?.phoneNumber ?? "Numer telefonu nie może być pusty",
          })
        );
    };
  }, []);

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-32 pad-8 pad-b-32 width-fill border-bottom">
        <div className="d-flex fd-c gap-16 pad-b-16">
          <Label fontStyle="bold" fontSize="lg" content="Dane gospodarstwa" />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Nazwa gospodarstwa"
            placeholder="np. Zielony zakątek"
            isRequired
            error={subscriptionWizardState.valideError?.companyName}
            value={subscriptionWizardState.companyName}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  companyName: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, companyName: "" },
                })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Imię"
            placeholder="np. Jan"
            autoComplete="given-name"
            isRequired
            error={subscriptionWizardState.valideError?.firstName}
            value={subscriptionWizardState.firstName}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  firstName: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, firstName: "" },
                })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Nazwisko"
            placeholder="np. Kowalski"
            autoComplete="family-name"
            isRequired
            error={subscriptionWizardState.valideError?.lastName}
            value={subscriptionWizardState.lastName}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  lastName: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, lastName: "" },
                })
              )
            }
          />
        </div>

        <div className="d-flex fd-c gap-16 pad-b-16">
          <Label fontStyle="bold" fontSize="lg" content="Kontakt" />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer telefonu"
            autoComplete="tel"
            maxLength={9}
            placeholder="np. 789456123"
            isRequired
            error={subscriptionWizardState.valideError?.phoneNumber}
            value={subscriptionWizardState.phoneNumber}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  phoneNumber: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, phoneNumber: "" },
                })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Email"
            autoComplete="email"
            placeholder="np. jan.kowalski@gmail.com"
            isRequired
            error={subscriptionWizardState.valideError?.email}
            value={subscriptionWizardState.email}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  email: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, email: "" },
                })
              )
            }
          />
        </div>

        <div className="d-flex fd-c gap-16 ">
          <Label fontStyle="bold" fontSize="lg" content="Numery gospodarstwa" />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer producenta rolnego(ARiMR)"
            value={subscriptionWizardState.arimr}
            onChange={(e) => dispatch(changeSubscriptionWizard({ arimr: e.currentTarget.value }))}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer sprzedaży bezpośredniej(RHD)"
            value={subscriptionWizardState.rhd}
            onChange={(e) => dispatch(changeSubscriptionWizard({ rhd: e.currentTarget.value }))}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="frame-scroll gap-32">
      <div className="d-flex fd-c gap-32 width-fill" style={{ maxWidth: "600px" }}>
        <div className="d-flex fd-c gap-16 pad-b-16">
          <Label fontStyle="bold" fontSize="md" content="Dane gospodarstwa" />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Nazwa gospodarstwa"
            placeholder="np. Zielony zakątek"
            isRequired
            error={subscriptionWizardState.valideError?.companyName}
            value={subscriptionWizardState.companyName}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  companyName: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, companyName: "" },
                })
              )
            }
          />
          <div className="d-flex gap-16 width-fill">
            <InputText
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="tertiary"
              label="Imię"
              placeholder="np. Jan"
              autoComplete="given-name"
              isRequired
              error={subscriptionWizardState.valideError?.firstName}
              value={subscriptionWizardState.firstName}
              onChange={(e) =>
                dispatch(
                  changeSubscriptionWizard({
                    firstName: e.currentTarget.value,
                    valideError: { ...subscriptionWizardState.valideError, firstName: "" },
                  })
                )
              }
            />
            <InputText
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="tertiary"
              label="Nazwisko"
              placeholder="np. Kowalski"
              autoComplete="family-name"
              isRequired
              error={subscriptionWizardState.valideError?.lastName}
              value={subscriptionWizardState.lastName}
              onChange={(e) =>
                dispatch(
                  changeSubscriptionWizard({
                    lastName: e.currentTarget.value,
                    valideError: { ...subscriptionWizardState.valideError, lastName: "" },
                  })
                )
              }
            />
          </div>
        </div>

        <div className="d-flex fd-c gap-16 pad-b-16">
          <Label fontStyle="bold" fontSize="md" content="Kontakt" />
          <div className="d-flex gap-16 width-fill">
            <InputText
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="tertiary"
              label="Numer telefonu"
              maxLength={9}
              placeholder="np. 789456123"
              autoComplete="tel"
              isRequired
              error={subscriptionWizardState.valideError?.phoneNumber}
              value={subscriptionWizardState.phoneNumber}
              onChange={(e) =>
                dispatch(
                  changeSubscriptionWizard({
                    phoneNumber: e.currentTarget.value,
                    valideError: { ...subscriptionWizardState.valideError, phoneNumber: "" },
                  })
                )
              }
            />
            <InputText
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="tertiary"
              label="Email"
              autoComplete="email"
              placeholder="np. jan.kowalski@gmail.com"
              isRequired
              error={subscriptionWizardState.valideError?.email}
              value={subscriptionWizardState.email}
              onChange={(e) =>
                dispatch(
                  changeSubscriptionWizard({
                    email: e.currentTarget.value,
                    valideError: { ...subscriptionWizardState.valideError, email: "" },
                  })
                )
              }
            />
          </div>
        </div>

        <div className="d-flex fd-c gap-16 ">
          <Label fontStyle="bold" fontSize="md" content="Numery gospodarstwa" />
          <div className="d-flex fd-c gap-16 width-fill">
            <InputText
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="tertiary"
              label="Numer producenta rolnego(ARiMR)"
              value={subscriptionWizardState.arimr}
              onChange={(e) => dispatch(changeSubscriptionWizard({ arimr: e.currentTarget.value }))}
            />
            <InputText
              frameClassName="width-fill"
              sizeFrame="sm"
              kind="tertiary"
              label="Numer sprzedaży bezpośredniej(RHD)"
              value={subscriptionWizardState.rhd}
              onChange={(e) => dispatch(changeSubscriptionWizard({ rhd: e.currentTarget.value }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionWizardDataComponent;
