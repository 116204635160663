import { Picture } from "models/new/Picture";
import { generateGUID } from "./stringManager";

export const processFile = (file: File, pictures?: Picture[]): Picture => {
  let picture = pictures?.find((x) => x.id === file.name);
  if (!picture) {
    const guid = generateGUID();
    const newFile = new File([file], guid, {
      type: file.type,
      lastModified: file.lastModified,
    });
    picture = {
      id: guid,
      url: "",
      file: newFile,
    };
  } else {
    picture = { ...picture, file: file };
  }
  return picture;
};

//Jeśli nie mozna pobrać zdjecia to url jest wrzucany w type - średni myk
export const fetchFile = async (url: string, id: string, type: string): Promise<File> => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], id, { type: blob.type });
  } catch (error) {
    return new File([], id, { type });
  }
};

export const getFileOrFetch = async (img: any): Promise<File> => {
  if (img.file) {
    return img.file;
  } else {
    if (typeof img === "string") {
      return fetchFile(img, generateGUID(), img);
    }
    return fetchFile(img.url, img.id, img.url);
  }
};
