import React from "react";
import * as Icon from "commons/iconManager";
import * as PostApi from "api/PostApi";
import { Label } from "controls/Label";
import { Post, PostComment } from "models/new/Post";
import PictureBoxComponent from "../../../components/PictureBoxComponent";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { InputArea } from "controls/InputArea";
import { setSuppliersPostComment, setSuppliersPostLike, setSuppliersPostUnlike } from "reducers/searchReducer";
import { generateGUID } from "commons/stringManager";
import { useLogin } from "hooks/useLogin";
import { useWindowSize } from "hooks/useWindowSize";
import { setPostComment, setPostLike, setPostUnlike } from "reducers/wallReducer";
import { setMyPostComment, setMyPostLike, setMyPostUnlike } from "reducers/userReducer";
import { InputText } from "controls/InputText";

const PostManagerComponent: React.FC<{ post?: Post }> = ({ post }) => {
  const { isMobile, windowSize } = useWindowSize();
  const [pictureNumber, setPictureNumber] = React.useState<number>(0);
  const [comment, setComment] = React.useState<string>();
  const userState: UserState = useSelector((state: any) => state.user);
  const { navigateTo } = useNavigation();
  const { signIn } = useLogin();
  const dispatch = useDispatch();

  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  if (!post) return <></>;

  const changePicture = (value: number) => {
    const length = post.pictures?.length ?? 0;
    if (value > 0) {
      const num = (pictureNumber + 1) % length;
      setPictureNumber(num);
    } else {
      const num = (pictureNumber - 1 + length) % length;
      setPictureNumber(num);
    }
  };

  const showDealer = (): JSX.Element => {
    return (
      <div className="d-flex width-fill ai-center gap-16">
        <PictureBoxComponent className="picture-50" url={post.supplier?.logoUrl} />
        <div className="d-flex fd-c gap-4">
          <Label
            fontSize="sm"
            wrap
            islink
            fontStyle="bold"
            content={`${post.supplier?.firstName} ${post.supplier?.lastName} ● ${post.supplier?.companyName}`}
            onClick={() => navigateTo("search", `?dealer_id=${post.supplierId}`)}
          />
          <Label
            fontSize="sml"
            wrap
            fontStyle="semibold"
            fontColor="secondary"
            content={new Date(post.date).toFormatMoment("DD MMM yyyy HH:mm")}
          />
        </div>
        <Button kind="text" sizeFrame="sm" icon={<Icon.MoreHorizSVG />} style={{ marginLeft: "auto" }} />
      </div>
    );
  };

  const showPicture = (): JSX.Element => {
    if (!post.pictures) return <></>;
    if (post.pictures.length === 1)
      return <PictureBoxComponent className="image-on-post" url={post.pictures?.[pictureNumber]?.url} />;

    return (
      <div className="width-fill" style={{ position: "relative" }}>
        <Icon.ArrowBackSVG className="icon-back-absolute i-button i-button-small" onClick={() => changePicture(-1)} />
        <PictureBoxComponent className="image-on-post" url={post.pictures?.[pictureNumber]?.url} />;
        <Icon.ArrowForwardSVG
          className="icon-forward-absolute i-button i-button-small"
          onClick={() => changePicture(1)}
        />
      </div>
    );
  };

  const showPictureFull = (): JSX.Element => {
    if (!post.pictures) return <></>;
    if (post.pictures.length === 1)
      return <PictureBoxComponent className="image-on-post-full" url={post.pictures?.[pictureNumber]?.url} />;
    return (
      <>
        <Icon.ArrowBackSVG className="icon-back-absolute i-button i-button-small" onClick={() => changePicture(-1)} />
        <PictureBoxComponent className="image-on-post-full" url={post.pictures?.[pictureNumber]?.url} />;
        <Icon.ArrowForwardSVG
          className="icon-forward-absolute i-button i-button-small"
          onClick={() => changePicture(1)}
        />
      </>
    );
  };

  const setLike = async () => {
    if (!userState.token) {
      await signIn();
      return;
    }

    let like = post.likes?.find((x) => x.appUserId == userState.user?.id);
    if (like) {
      await PostApi.delPostLike(like.id);
      dispatch(setSuppliersPostUnlike(like));
      dispatch(setPostUnlike(like));
      dispatch(setMyPostUnlike(like));
    } else {
      like = { id: generateGUID(), appUserId: userState.user!.id, postId: post.id! };
      await PostApi.addPostLike(like);
      dispatch(setSuppliersPostLike(like));
      dispatch(setPostLike(like));
      dispatch(setMyPostLike(like));
    }
  };

  const showOptions = (): JSX.Element => {
    return (
      <div className="d-flex width-fill jc-sb ai-center pad-b-4 border-bottom">
        <Button
          style={{ padding: "0px 4px", gap: "0px" }}
          sizeFrame="sm"
          label={`${post?.likes?.length?.toString() ?? 0}`}
          icon={
            <Icon.OgrosaColorSVG
              className={post?.likes?.some((x) => x.appUserId === userState.user?.id) ? "" : "icon-unlike"}
            />
          }
          kind="text"
          onClick={setLike}
        />
      </div>
    );
  };

  const showPostComment = (): JSX.Element => {
    if (!post.comments) return <></>;
    return (
      <>
        {[...post.comments]
          .sort((a, b) => {
            return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
          })
          .map((comment, idx) => {
            return (
              <div className="d-flex width-fill gap-8">
                <PictureBoxComponent className="pad-t-4 picture-35" url={comment.appUser?.photoUrl} />
                <div className="d-flex fd-c comment-box width-fill">
                  <div className="d-flex width-fill ai-center jc-sb">
                    <div className="d-flex fd-c">
                      <Label
                        fontSize="sm"
                        wrap
                        fontStyle="bold"
                        content={`${comment.appUser?.firstName} ${comment.appUser?.lastName}`}
                      />
                      <Label
                        fontSize="xsml"
                        wrap
                        fontStyle="semibold"
                        fontColor="secondary"
                        content={new Date(comment.createdDate).toFormatMoment("DD MMM yyyy HH:mm")}
                      />
                    </div>
                    <Button kind="text" sizeFrame="sml" icon={<Icon.MoreHorizSVG />} />
                  </div>
                  <Label className="mag-t-8" fontSize="sm" wrap fontStyle="semibold" content={comment.comment} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const sendComment = async (com?: string) => {
    if (!userState.token) {
      await signIn();
      return;
    }

    const newComment = com ?? comment;

    if (!newComment) return;

    const postComment: PostComment = {
      id: generateGUID(),
      appUserId: userState.user!.id,
      postId: post.id!,
      comment: newComment,
      createdDate: new Date(),
    };
    await PostApi.addPostComment(postComment);
    dispatch(setSuppliersPostComment({ ...postComment, appUser: userState.user }));
    dispatch(setPostComment({ ...postComment, appUser: userState.user }));
    dispatch(setMyPostComment({ ...postComment, appUser: userState.user }));
    setTimeout(() => {
      setComment("");
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight,
          behavior: "smooth",
        });
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 200);
  };

  const onSubmit = (e: any) => {
    sendComment(e);
  };

  const addComment = (): React.ReactNode => {
    if (!userState.token) return <></>;

    return (
      <div className="bg-primary d-flex gap-8 pad-8 width-fill" style={{ position: "fixed", bottom: "0px" }}>
        <PictureBoxComponent className="pad-t-4 picture-35" url={userState.user?.photoUrl} />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="secondary"
          placeholder="Dodaj komentarz"
          value={comment}
          onChange={(e) => setComment(e.currentTarget.value)}
          onSubmit={(e) => onSubmit((e.target as HTMLInputElement).value)}
        />
        <Button
          sizeFrame={isMobile ? "md" : "sm"}
          kind="secondary"
          icon={<Icon.SendSVG />}
          toolTip={"Skomentuj"}
          onClick={() => sendComment()}
        />
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c width-fill" style={{ position: "relative" }}>
        <div
          ref={scrollRef}
          className="f-scroll d-flex width-fill fd-c gap-16 pad-16"
          style={{ paddingBottom: !userState.token ? "16px" : "64px" }}
        >
          {showDealer()}
          <Label fontSize="sm" wrap fontStyle="semibold" content={post.description} />
          {showPicture()}
          {showOptions()}
          {showPostComment()}
        </div>
        {addComment()}
      </div>
    );
  }

  return (
    <div className="frame-main fd-r">
      <div
        className="d-flex fd-c ai-center jc-center pad-l-16"
        style={{ width: windowSize.width - 532 + "px", height: "100%", position: "relative" }}
      >
        {showPictureFull()}
      </div>
      <div className="frame-main bg-primary" style={{ width: "500px" }}>
        <div
          ref={scrollRef}
          style={{ paddingBottom: !userState.token ? "16px" : "64px" }}
          className="frame-scroll d-flex width-fill fd-c gap-16 pad-16 bg-primary"
        >
          {showDealer()}
          <Label fontSize="sm" wrap fontStyle="semibold" content={post.description} />
          {showOptions()}
          {showPostComment()}
        </div>
        {addComment()}
      </div>
    </div>
  );
};

export default PostManagerComponent;

/*
 <InputArea
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="secondary"
          heightAuto={true}
          placeholder="Dodaj komentarz"
          value={comment}
          onChange={(e) => setComment(e.currentTarget.value)}
        />
*/
