import React, { KeyboardEvent } from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import EmptyComponent from "components/EmptyComponent";

interface SearchProps {
  value?: string;
  itemSource: any[];
  onChange: (search: string) => void;
  onDisplayItem: (item: any, selected?: any) => JSX.Element;
  onSubmit?: (item: any) => void;
}

const SearchBoxLocalizationComponent: React.FC<SearchProps> = (props) => {
  const [itemSource, setItemSource] = React.useState<any[]>(props.itemSource);
  const [value, setValue] = React.useState<string | undefined>(props.value);
  const [selected, setSelected] = React.useState<any>();
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const currentIndex = React.useRef<number>(-1);
  const searchAddres = React.useRef<string>();

  React.useEffect(() => {
    setItemSource(props.itemSource);
  }, [props.itemSource]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    inputRef.current.focus();
  }, [inputRef.current]);

  const handleKeyNavigation = React.useCallback(
    (e: KeyboardEvent) => {
      const itemsLength = itemSource.length;

      if (e.key === "ArrowDown") {
        currentIndex.current = (currentIndex.current + 1) % itemsLength;
        setSelected(itemSource[currentIndex.current]);
        e.preventDefault();
      } else if (e.key === "ArrowUp") {
        currentIndex.current = (currentIndex.current - 1 + itemsLength) % itemsLength;
        setSelected(itemSource[currentIndex.current]);
        e.preventDefault();
      } else if (e.key === "Enter") {
        if (!selected && itemSource.length > 0) props.onSubmit?.(itemSource[0]);
        else props.onSubmit?.(selected);
      }
    },
    [selected, itemSource, props.onSubmit]
  );

  React.useEffect(() => {
    const keydownListener = (evt: any) => handleKeyNavigation(evt);

    document.addEventListener("keydown", keydownListener);
    return () => document.removeEventListener("keydown", keydownListener);
  }, [handleKeyNavigation]);

  const onChange = (value: string) => {
    props.onChange(value);
    inputRef.current.value = value;
  };

  return (
    <div className="frame-main" style={{ maxHeight: "350px" }}>
      <InputText
        frameClassName="width-fill"
        ref={inputRef}
        sizeFrame="sm"
        kind="tertiary"
        placeholder="Wyszukaj (min. 3 znaki)"
        icon={<Icon.SearchSVG />}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="frame-scroll jc-stretch gap-4">
        {itemSource.map((item) => {
          return props.onDisplayItem(item, selected);
        })}
      </div>
      {itemSource.length === 0 ? (
        <div className="d-flex jc-center ai-center gap-4" style={{ height: "200px" }}>
          <EmptyComponent icon={<Icon.SearchSVG />} message="Brak wyszukiwań" />
        </div>
      ) : null}
    </div>
  );
};

export default SearchBoxLocalizationComponent;
