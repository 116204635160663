import React from "react";
import * as Icon from "commons/iconManager";
import { useSelector } from "react-redux";
import { UserState } from "models/new/State";
import EmptyComponent from "components/EmptyComponent";
import GroceryMobileItemPage from "./GroceryMobileItemPage";
import { InputText } from "controls/InputText";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";

const GroceryMobilePage: React.FC = () => {
  const [search, setSearch] = React.useState<string>();
  const { navigateTo } = useNavigation();
  const userState: UserState = useSelector((state: any) => state.user);

  return (
    <>
      <div id="top-page" className="f-page-top">
        <InputText
          frameClassName="width-fill"
          icon={<Icon.SearchSVG />}
          kind="tertiary"
          placeholder="Szukaj produktu"
          sizeFrame="sm"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </div>
      <div className="f-page-center fd-c">
        {userState.supplier?.products && userState.supplier?.products?.length > 0 ? (
          <>
            {userState.supplier.products
              .filter((x) => !search || search === "" || x.name.toLowerCase().includes(search.toLowerCase()))
              .map((prod) => {
                return <GroceryMobileItemPage product={prod} />;
              })}
          </>
        ) : (
          <EmptyComponent style={{ height: "50%" }} icon={<Icon.GrocerySVG />} message="Brak produktów" />
        )}
        <Button
          sizeFrame="lg"
          kind="primary"
          style={{ position: "fixed", bottom: "16px", right: "16px" }}
          icon={<Icon.AddSVG />}
          onClick={() => navigateTo("grocery_manager", "?action=create&tab=data")}
        />
      </div>
    </>
  );
};

export default GroceryMobilePage;
