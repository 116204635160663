import React from "react";
import ReactDOM from "react-dom/client";
import { Label } from "./Label";
import { Button } from "./Button";

export const UserGuide = {
  show: (id: string, description: string): Promise<{ result: "next" | "skip" }> => {
    return new Promise(async (resolve) => {
      const container = createContainer();
      const root: ReactDOM.Root | null = null;

      const action = (result: "next" | "skip") => {
        const component: HTMLElement | null = document.getElementById("clone-" + id);
        if (component) {
          component.remove();
        }
        const componentMain: HTMLElement | null = document.getElementById("user-guide-id");
        if (componentMain) {
          componentMain.remove();
        }
        resolve({ result });
      };

      const showPopUp = () => {
        if (!container) return;

        const component: HTMLElement | null = document.getElementById(id);
        if (component) {
          const location = component.getBoundingClientRect();
          const message: React.ReactNode = (
            <UserGuideComponent
              location={location}
              description={description}
              onClose={() => action("skip")}
              onNext={() => action("next")}
            />
          );
          document.body.appendChild(container!);
          container!.style.opacity = "1";
          const root = ReactDOM.createRoot(container!);
          root.render(message);

          const clonedComponent = component.cloneNode(true) as HTMLElement;
          clonedComponent.id = "clone-" + id;
          clonedComponent.style.position = "absolute";
          clonedComponent.style.left = location.left + "px";
          clonedComponent.style.top = location.top + "px";
          //clonedComponent.style.height = location.height + "px";
          clonedComponent.style.width = location.width + "px";
          clonedComponent.style.zIndex = "9999";
          document.body.appendChild(clonedComponent);
        }
      };

      showPopUp();
    });
  },
};

const createContainer = (): HTMLDivElement | undefined => {
  const existComponent: HTMLElement | null = document.getElementById("user-guide-id");
  if (!existComponent) {
    const container = document.createElement("div");
    container.className = "user-guide-cover";
    container.id = "user-guide-id";
    return container;
  }
  return undefined;
};

interface UserGuideComponentProps {
  location: DOMRect;
  description: string;
  onClose: () => void;
  onNext: () => void;
}

const UserGuideComponent: React.FC<UserGuideComponentProps> = (props) => {
  return (
    <div
      className="user-guide-content"
      style={{ left: props.location.left, top: props.location.top + props.location.height + 16 }}
    >
      <Label fontStyle="bold" wrap content={props.description} />
      <div className="d-flex width-fill jc-sb">
        <Button sizeFrame="sml" kind="secondary" fontStyle="bold" label="Zamknij" onClick={props.onClose} />
        <Button sizeFrame="sml" kind="secondary" fontStyle="bold" label="Dalej" onClick={props.onNext} />
      </div>
    </div>
  );
};
