import React from "react";
import * as Icon from "commons/iconManager";
import * as ConversationApi from "api/ConversationApi";
import InfoBox from "controls/InfoBox";
import { useDispatch, useSelector } from "react-redux";
import { setShowMail } from "reducers/mainReducer";
import { addConversation, markAsRead } from "reducers/conversationReducer";
import { UserState } from "models/new/State";
import { AppUser } from "models/new/AppUser";
import { Label } from "controls/Label";
import { Chat } from "models/new/Chat";
import { MessageStatusType } from "models/new/Dictionaries";

interface MessagerItemProps {
  conversation: Chat;
}

const MessagerItemComponent: React.FC<MessagerItemProps> = (props) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const data = new Date(props.conversation.chatMessages?.[0].sentAt ?? "");

  const dispatch = useDispatch();
  const readed: boolean =
    props.conversation.chatMessages
      .find((x) => x.appUserId !== userState.user?.id)
      ?.chatMessageStatuses.some((x) => x.status !== MessageStatusType.Read) ?? false;

  let appUser: AppUser | undefined = props.conversation.chatMembers.find(
    (x) => x.appUserId !== userState.user!.id
  )?.appUser;

  const addToActive = async () => {
    await ConversationApi.putMarkAsRead(props.conversation.id, userState.user!.id);
    dispatch(addConversation({ chat: props.conversation, isOpen: true }));
    setTimeout(() => {
      dispatch(markAsRead({ conversationId: props.conversation.id, senderId: userState.user!.id }));
    }, 300);
    dispatch(setShowMail(false));
  };

  return (
    <InfoBox
      className={`width-fill c-pointer  hover`}
      sizeFrame="sml"
      backgroundColor={readed ? "secondary" : "transparent"}
      onClick={addToActive}
    >
      <div className="frame-main d-flex fd-c gap-16 pad-4">
        <div className="d-flex fd-r ai-center gap-16">
          {appUser?.photoUrl ? (
            <img className="picture-50" src={appUser?.photoUrl} />
          ) : (
            <Icon.PersonSVG className="icon-color picture-50" />
          )}
          <div className="d-flex fd-c ai-stretch gap-2" style={{ flex: "1", overflow: "hidden" }}>
            <Label
              fontSize="md"
              fontStyle="semibold"
              pointer
              content={`${appUser?.firstName ?? "Brak"} ${appUser?.lastName ?? ""}`}
            />
            <Label
              fontSize="sm"
              fontStyle={readed ? "semibold" : "regular"}
              pointer
              fontColor={readed ? "secondary" : "primary"}
              content={props.conversation.chatMessages?.[0].content}
            />
          </div>
          <div className="d-flex fd-c ai-center gap-4">
            {readed ? <Icon.MarkEmailUnreadSVG className="c-red" /> : <Icon.DraftsSVG className="c-secondary" />}
            <Label
              fontSize="sml"
              fontStyle={readed ? "regular" : "semibold"}
              pointer
              fontColor={readed ? "secondary" : "primary"}
              content={
                new Date().toFormatMoment("DD.MM") === data.toFormatMoment("DD.MM")
                  ? data.toFormatMoment("hh:mm")
                  : data.toFormatMoment("DD MMM")
              }
            />
          </div>
        </div>
      </div>
    </InfoBox>
  );
};

export default MessagerItemComponent;
