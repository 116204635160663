import React from "react";
import * as Icon from "../commons/iconManager";
import { Label, StandardLabelProps } from "./Label";
import { ToolTip } from "./ToolTip";
import { useWindowSize } from "hooks/useWindowSize";

export interface ButtonProps extends StandardLabelProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: React.ReactElement;
  iconPosition?: "left" | "right";
  sizeFrame?: "sml" | "sm" | "md" | "lg" | "xl";
  kind?:
    | "primary"
    | "secondary"
    | "text"
    | "primary-text"
    | "approve"
    | "cancel"
    | "menu"
    | "menu-seleceted"
    | "tertiary"
    | "transparent";
  count?: number;
  toolTip?: string | JSX.Element;
  toolTipPosition?: "left" | "right" | "top" | "bottom";
  error?: boolean;
  wrapLabel?: boolean;
  isLoading?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const btnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const { isMobile } = useWindowSize();

  React.useImperativeHandle(ref, () => btnRef.current);

  React.useEffect(() => {
    if (props.toolTip && !isMobile) {
      ToolTip.show(btnRef.current, props.toolTip, props.toolTipPosition);
    }
  }, [props.toolTip]);

  const getKind = (
    kind?:
      | "primary"
      | "secondary"
      | "text"
      | "primary-text"
      | "approve"
      | "cancel"
      | "menu"
      | "menu-seleceted"
      | "tertiary"
      | "transparent"
  ): string => {
    if (props.disabled) return "disable-button";

    switch (kind) {
      case "primary":
        return "primary-button";
      case "secondary":
        return "secondary-button";
      case "approve":
        return "approve-button";
      case "text":
        return "text-button";
      case "primary-text":
        return "primary-text-button";
      case "menu":
        return "menu-button";
      case "menu-seleceted":
        return "menu-button selected";
      case "tertiary":
        return "tertiary-button";
      case "transparent":
        return "transparent-button";
      case "cancel":
        return "cancel-button";
      default:
        return "secondary-button";
    }
  };
  const getSize = (sizeFrame?: "sml" | "sm" | "md" | "lg" | "xl"): string => {
    switch (sizeFrame) {
      case "sml":
        return " button-h32";
      case "sm":
        return " button-h40";
      case "md":
        return " button-h48";
      case "lg":
        return " button-h56";
      case "xl":
        return " button-h64";
      default:
        return " ";
    }
  };
  const getSizeForIcon = (sizeFrame?: "sml" | "sm" | "md" | "lg" | "xl"): number => {
    switch (sizeFrame) {
      case "sml":
        return 20;
      case "sm":
        return 24;
      case "md":
        return 24;
      case "lg":
        return 28;
      case "xl":
        return 32;
      default:
        return 24;
    }
  };
  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += " " + props.className;
    return style;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: `icon-on-button ${props.icon.props.className}`,
      style: {
        ...props.icon.props.style,
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }

  let errorIcon;
  if (props.error) {
    const size = getSizeForIcon(props.sizeFrame);
    errorIcon = React.cloneElement(<Icon.ErrorSVG />, {
      className: "icon-on-button" + " c-red",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }

  const showCount = () => {
    if (!props.count) return null;

    return (
      <div className="count-box">
        <Label fontSize="sm" fontStyle="semibold" content={props.count?.toString()} />
      </div>
    );
  };

  return (
    <button {...props} ref={btnRef} className={getStyle()}>
      {props.isLoading ? (
        <Icon.LoadingGraySVG className={getSize(props.sizeFrame)} width={48} />
      ) : (
        <>
          {props.iconPosition !== "right" && clonedIcon}
          {props.label && (
            <Label
              wrap={props.wrapLabel}
              content={props.label}
              fontColor={props.fontColor ?? "primary"}
              fontSize={props.fontSize ?? "sm"}
              fontStyle={props.fontStyle ?? "semibold"}
            />
          )}
          {showCount()}
          {props.iconPosition === "right" && clonedIcon}
          {props.error && errorIcon}
        </>
      )}
    </button>
  );
});
