import React from "react";
import * as Icon from "commons/iconManager";
import { AccountProps } from "./AccountComponent";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { InputText } from "controls/InputText";
import * as UserApi from "api/UserApi";
import { InputPassword } from "controls/InputPassword";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import InfoBox from "controls/InfoBox";

const AccountRestartPasswordComponent: React.FC<AccountProps> = (props) => {
  const [errorLink, setErrorLink] = React.useState();
  const [confirm, setConfirm] = React.useState<boolean>(false);
  const [passwordRepetError, setPasswordRepetError] = React.useState<string>("");
  const refEmail = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const refPassword = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const refPasswordRepeat = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const searchParams = new URLSearchParams(window.location.search);
  const resetCode = searchParams.get("resetCode")?.replaceAll(" ", "+");
  const email = searchParams.get("email");

  const onClick = async () => {
    setPasswordRepetError("");
    if (refPassword.current.value.length === 0 || refPasswordRepeat.current.value.length === 0) {
      setPasswordRepetError("Podaj hasło!");
      return;
    }

    if (refPassword.current.value !== refPasswordRepeat.current.value) {
      setPasswordRepetError("Podane hasła róznią się!");
      return;
    }

    if (resetCode) {
      const decodedResetCode = decodeURIComponent(resetCode);
      await UserApi.resetPassword(refEmail.current.value, decodedResetCode, refPassword.current.value)
        .then(() => {
          setConfirm(true);
        })
        .catch(async (err) => {
          setErrorLink(err);
        })
        .finally(() => {
          const location = new URL(window.location.href);
          location.searchParams.delete("email");
          location.searchParams.delete("resetCode");
          window.history.replaceState({}, "", location);
        });
    }
  };

  return (
    <div className="frame-main">
      <div className="d-flex ai-center jc-center fd-c gap-16">
        <Label fontStyle="bold" fontSize="xl" content={"Nadawanie nowego hasła"} />
        {confirm ? (
          <>
            <div className="d-flex gap-16 ai-center">
              <Icon.CheckCircleSVG className="c-green" height={64} width={64} />
              <Label
                wrap
                fontStyle="bold"
                fontSize="sm"
                content={"Gotowe! Twoje hasło zostało zmienione. Możesz czuć się bezpieczniej z nowym hasłem."}
                onClick={() => {
                  props.setPageType("sign-up");
                }}
              />
            </div>
            <Button
              style={{ width: "100%" }}
              sizeFrame="md"
              fontSize="lg"
              fontStyle="bold"
              kind="primary"
              label="Zamknij"
              onClick={() => props.onCloseModalDialog?.("Cancel")}
            />
          </>
        ) : (
          <>
            <Label
              fontStyle="semibold"
              fontSize="sm"
              wrap
              style={{ textAlign: "center" }}
              content={
                "Po pomyślnym ustawieniu nowego hasła, otrzymasz potwierdzenie na stronie oraz dodatkowy e-mail potwierdzający, że hasło zostało zmienione."
              }
            />
            <InputText
              ref={refEmail}
              frameClassName="width-fill"
              label="E-mail"
              placeholder="Wprowadź e-mail"
              kind="tertiary"
              sizeFrame="sm"
              disabled
              defaultValue={email ?? ""}
              icon={<Icon.MailSVG />}
            />
            <InputPassword
              ref={refPassword}
              frameClassName="width-fill"
              label="Nowe hasło"
              placeholder="Wprowadź nowe hasło"
              kind="tertiary"
              sizeFrame="sm"
              autoFocus
              icon={<Icon.KeySVG />}
              onSubmit={onClick}
            />
            <InputPassword
              ref={refPasswordRepeat}
              frameClassName="width-fill"
              label="Powtórz hasło"
              placeholder="Powtórz hasło"
              kind="tertiary"
              sizeFrame="sm"
              icon={<Icon.KeySVG />}
              onSubmit={onClick}
              error={passwordRepetError}
            />
            {errorLink && (
              <InfoBox
                className="width-fill ai-center"
                backgroundColor="red"
                fontStyle="bold"
                sizeFrame="xsm"
                value={errorLink}
                icon="Error"
                iconSize="sml"
              />
            )}
            <Button
              style={{ width: "100%" }}
              sizeFrame="md"
              fontSize="lg"
              fontStyle="bold"
              kind="primary"
              label="Zmień hasło"
              onClick={onClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AccountRestartPasswordComponent;
