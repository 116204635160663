import React from "react";
import * as Icon from "commons/iconManager";
import * as OrderApi from "api/OrderApi";
import PathComponent from "components/PathPageComponent";
import { Button } from "controls/Button";
import { EntryDictionary } from "models/new/EntryDictionary";
import { SaleState, UserState } from "models/new/State";
import { useDispatch, useSelector } from "react-redux";
import { changeSaleFilter, getSales } from "reducers/saleReducer";
import { InputComboBox } from "controls/InputComboBox";
import { Grid } from "controls/Grid";
import { GridColumn } from "controls/GridColumn";
import { Sale } from "models/new/Sale";
import { Label } from "controls/Label";
import { InputDate } from "controls/InputDate";
import { useNavigation } from "hooks/useNavigation";
import { DeliveryType, GroupByDate, StatusOrder, showStatusOrderCodeTag } from "models/new/Dictionaries";
import EmptyComponent from "components/EmptyComponent";
import WaitComponent from "components/WaitComponent";
import { OrderProduct } from "models/new/OrderProduct";
import AddressComponent from "components/AddressComponent";
import { SearchOrderFilter } from "models/new/SearchOrderFilter";
import { delivertType, groupByDate, orderStatus, sort } from "commons/listManager";
import { useWindowSize } from "hooks/useWindowSize";
import SalesMobilePage from "./components/mobile/SalesMobilePage";
import { getLastStatus } from "models/new/OrderStatus";
import SalesSummayComponent from "./components/SalesSummayComponent";
import { endOfWeek, startOfWeek } from "date-fns";
import { LazyLoadComponent } from "components/LazyLoadComponent ";

const SalePage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { isMobile } = useWindowSize();
  const userState: UserState = useSelector((state: any) => state.user);
  const saleState: SaleState = useSelector((state: any) => state.sale);
  const { navigateTo } = useNavigation();
  const dispatch = useDispatch();

  React.useEffect(() => {    
    document.title = "Ogrosa | Moja sprzedaż";
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [saleState.filter, userState.supplier]);

  const downloadData = async () => {
    if (!userState.supplier) return;
    setIsLoading(true);

    const searchOrderFilter: SearchOrderFilter = {
      ...saleState.filter,
      supplierId: userState.supplier!.id,
      statusOrder: saleState.filter.statusOrder === StatusOrder.All ? undefined : saleState.filter.statusOrder,
      deliveryType: saleState.filter.deliveryType === DeliveryType.All ? undefined : saleState.filter.deliveryType,
    };

    OrderApi.getOrders(searchOrderFilter)
      .then((value) => {
        dispatch(getSales(value));
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  function onRefresh(): void {
    downloadData();
  }

  const onDisplayContentCode = (sale: Sale): JSX.Element => {
    return (
      <Label
        fontSize="sm"
        fontStyle="semibold"
        islink
        content={sale.code}
        onClick={() => navigateTo("sale_manager", `?id=${sale.id}`)}
      />
    );
  };
  const onDisplayContentClient = (sale: Sale): JSX.Element => {
    return (
      <div className="d-flex fd-r ai-center gap-16">
        <img className="picture-50" src={sale.appUser?.photoUrl} />
        <Label
          fontSize="md"
          fontStyle="semibold"
          content={`${sale.address?.firstName ?? ""} ${sale.address?.lastName ?? ""}`}
        />
        {sale.description && sale.description.length > 0 && (
          <Button
            sizeFrame="sml"
            kind="text"
            toolTip={sale.description}
            toolTipPosition="right"
            icon={<Icon.MarkEmailUnreadSVG className="c-red" />}
            onClick={() => navigateTo("sale_manager", `?id=${sale.id}`)}
          />
        )}
      </div>
    );
  };
  const onDisplayContentAddress = (sale: Sale): JSX.Element => {
    return (
      <div className="d-flex ai-center gap-16">
        {sale.deliveryType === DeliveryType.Post ? (
          <Icon.LocalShippingSVG className="icon-color" height={28} width={28} />
        ) : sale.deliveryType === DeliveryType.PickupOnSite ? (
          <Icon.HomePinSVG className="icon-color" height={32} width={32} />
        ) : (
          <Icon.AddNavigationSVG className="icon-color" height={32} width={32} />
        )}
        <AddressComponent address={sale.address} />
      </div>
    );
  };
  const onDisplayContentState = (sale: Sale): JSX.Element => {
    return showStatusOrderCodeTag(getLastStatus(sale.statuses), "xsm");
  };
  const onDisplayContentDate = (sale: Sale): JSX.Element => {
    return (
      <div className="d-flex fd-c ai-s-flex jc-center gap-4">
        <Label fontSize="sml" fontStyle="bold" content={new Date(sale.createdDate).toFormatMoment("DD MMMM yyyy")} />
        <Label fontSize="sml" fontStyle="semibold" content={new Date(sale.createdDate).toFormatMoment("HH:mm")} />
      </div>
    );
  };
  const onDisplayContentPickUpTime = (sale: Sale): JSX.Element => {
    return (
      <div className="d-flex fd-c ai-s-flex jc-center gap-4">
        <Label
          fontSize="sml"
          fontStyle="bold"
          content={new Date(sale.pickUpDate ?? "").toFormatMoment("DD MMMM yyyy")}
        />
        <Label fontSize="sml" fontStyle="semibold" content={new Date(sale.pickUpDate ?? "").toFormatMoment("HH:mm")} />
      </div>
    );
  };
  const calculateSummary = (product: OrderProduct): number => {
    let price = product.salePrice ?? product.price;
    /*if (product.saleCouponsCode) {
      price = calculateValueAfterPercent(price, product.saleCouponsPercent, 2);
    }*/

    price = price * product.quantity;
    return price;
  };
  const onDisplayContentSummary = (sale: Sale): JSX.Element => {
    let summary: number = 0;
    sale.products.forEach((product) => {
      summary += calculateSummary(product);
    });

    return <Label fontSize="sm" fontStyle="bold" content={`${summary.toLocaleFixed(2)} zł`} />;
  };
  const onDisplayContentButtons = (sale: Sale): JSX.Element => {
    return (
      <div className="d-flex fd-r ai-center jc-s-flex gap-8">
        {getLastStatus(sale.statuses) === StatusOrder.Completed ||
        getLastStatus(sale.statuses) === StatusOrder.Canceled ? (
          <Button
            sizeFrame="sml"
            kind="tertiary"
            fontStyle="bold"
            icon={<Icon.VisibilitySVG />}
            toolTip={"Podgląd zamówienia"}
            toolTipPosition="left"
            onClick={() => navigateTo("sale_manager", `?id=${sale.id}`)}
          />
        ) : (
          <Button
            sizeFrame="sml"
            kind="tertiary"
            fontStyle="bold"
            icon={<Icon.EditSVG />}
            toolTip={"Edytuj zamówienie"}
            toolTipPosition="left"
            onClick={() => navigateTo("sale_manager", `?id=${sale.id}`)}
          />
        )}
      </div>
    );
  };
  const onDisplayContentOrderStatusCombobox = (item: EntryDictionary): JSX.Element => {
    let icon;
    switch (item.value) {
      case StatusOrder.New:
        icon = <Icon.AddSVG />;
        break;
      case StatusOrder.Canceled:
        icon = <Icon.DangerousSVG />;
        break;
      case StatusOrder.InProgress:
        icon = <Icon.GrocerySVG />;
        break;
      case StatusOrder.Ready:
        icon = <Icon.CheckCircleSVG />;
        break;
      case StatusOrder.Sent:
        icon = <Icon.LocalShippingSVG />;
        break;
      case StatusOrder.Completed:
        icon = <Icon.OrdersSVG />;
        break;
      case StatusOrder.WaitingForPayment:
        icon = <Icon.PaymentsSVG />;
        break;
    }
    return (
      <Button
        style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
        sizeFrame="sml"
        kind={saleState.filter.statusOrder === item.value ? "menu-seleceted" : "menu"}
        label={item.name}
        icon={icon}
      />
    );
  };
  const onDisplayContentAddressCombobox = (item: EntryDictionary): JSX.Element => {
    if (item.value === DeliveryType.All) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={saleState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={"Wszystkie"}
        />
      );
    }
    if (item.value === DeliveryType.PickUpAtThePoint) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={saleState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.AddNavigationSVG />}
        />
      );
    }
    if (item.value === DeliveryType.PickupOnSite) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={saleState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.HomePinSVG />}
        />
      );
    }
    if (item.value === DeliveryType.Post) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={saleState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.LocalShippingSVG />}
        />
      );
    }
    return <></>;
  };

  function groupByDay(): Record<string, Sale[]> {
    return saleState.sales.reduce((groups, sale) => {
      const dateKey = new Date(sale.createdDate).toFormatMoment("DD MMMM YYYY");
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(sale);
      return groups;
    }, {} as Record<string, Sale[]>);
  }

  function groupByWeek(): Record<string, Sale[]> {
    return saleState.sales.reduce((groups, sale) => {
      const weekStart = startOfWeek(new Date(sale.createdDate), { weekStartsOn: 1 });
      const weekEnd = endOfWeek(new Date(sale.createdDate), { weekStartsOn: 1 });
      const dateKey = `Od ${weekStart.toFormatMoment("DD MMMM YYYY")} do ${weekEnd.toFormatMoment("DD MMMM YYYY")}`;
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(sale);
      return groups;
    }, {} as Record<string, Sale[]>);
  }

  function groupByMonth(): Record<string, Sale[]> {
    return saleState.sales.reduce((groups, sale) => {
      const dateKey = new Date(sale.createdDate).toFormatMoment("MMMM YYYY");
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(sale);
      return groups;
    }, {} as Record<string, Sale[]>);
  }

  const getGroupBy = () => {
    return saleState.filter.groupByDate === GroupByDate.Day
      ? groupByDay()
      : saleState.filter.groupByDate === GroupByDate.Week
      ? groupByWeek()
      : groupByMonth();
  };

  const showGroup = () => {
    const group = getGroupBy();

    return Object.keys(group).map((item) => {
      return (
        <>
          <LazyLoadComponent>
            <SalesSummayComponent title={item} sales={group[item]} />
          </LazyLoadComponent>
        </>
      );
    });
  };

  if (isMobile) return <SalesMobilePage />;

  return (
    <div className="f-fill" style={{ position: "relative" }}>
      <div className="frame-header jc-sb">
        <PathComponent onRefreshClick={onRefresh} />
        <div className="d-flex fd-r gap-8">
          <Button
            sizeFrame="sm"
            kind="tertiary"
            label="Export"
            fontStyle="bold"
            icon={<Icon.ShareSVG />}
            onClick={() => alert("Funkcjonalność w budowie")}
          />
          <Button
            sizeFrame="sm"
            kind="tertiary"
            label="Drukuj"
            fontStyle="bold"
            icon={<Icon.PrintSVG />}
            onClick={() => alert("Funkcjonalność w budowie")}
          />
        </div>
      </div>
      <div className="frame-top ai-center jc-sb gap-8">
        <div className="d-flex ai-e-flex gap-8">
          <InputDate
            label="Zakres dat"
            placeholder="Zakres dat"
            sizeFrame="sm"
            kind="tertiary"
            rangeValue={{ from: saleState.filter.dateFrom, to: saleState.filter.dateTo }}
            onChangeRange={(e) => dispatch(changeSaleFilter({ ...saleState.filter, dateFrom: e.from, dateTo: e.to }))}
          />
          <InputComboBox
            label="Status zamówienia"
            placeholder="Status zamówienia"
            sizeFrame="sm"
            kind="tertiary"
            displayMember="name"
            canClear={false}
            itemSource={orderStatus}
            selectedItem={orderStatus.find((x) => x.value === saleState.filter.statusOrder)}
            onSelectionChanged={(e) => dispatch(changeSaleFilter({ ...saleState.filter, statusOrder: e.value }))}
            onDisplayContent={onDisplayContentOrderStatusCombobox}
          />
          <InputComboBox
            label="Typ przesyłki"
            placeholder="Typ przesyłki"
            sizeFrame="sm"
            kind="tertiary"
            displayMember="name"
            canClear={false}
            itemSource={delivertType}
            selectedItem={delivertType.find((x) => x.value === saleState.filter.deliveryType)}
            onSelectionChanged={(e) => dispatch(changeSaleFilter({ ...saleState.filter, deliveryType: e.value }))}
            onDisplayContent={onDisplayContentAddressCombobox}
          />
        </div>
        <div className="d-flex gap-8 ai-e-flex">
          <Button
            sizeFrame="sm"
            kind={saleState.filter.view === "list" ? "primary" : "tertiary"}
            icon={<Icon.TableRowsNarrowSVG />}
            toolTip={"Widok listy"}
            onClick={() => {
              dispatch(changeSaleFilter({ ...saleState.filter, view: "list" }));
            }}
          />
          <Button
            sizeFrame="sm"
            kind={saleState.filter.view === "summary" ? "primary" : "tertiary"}
            icon={<Icon.ViewAgendaSVG />}
            toolTip={"Widok podsumowania"}
            onClick={() => {
              dispatch(changeSaleFilter({ ...saleState.filter, view: "summary" }));
            }}
          />
          {saleState.filter.view === "summary" && (
            <InputComboBox
              label="Grupuj według"
              placeholder="Grupuj według"
              sizeFrame="sm"
              kind="tertiary"
              displayMember="name"
              canClear={false}
              itemSource={groupByDate}
              selectedItem={groupByDate.find((x) => x.value === saleState.filter.groupByDate)}
              onSelectionChanged={(e) => dispatch(changeSaleFilter({ ...saleState.filter, groupByDate: e.value }))}
            />
          )}
          <InputComboBox
            label="Sortuj według"
            placeholder="Sortuj według"
            sizeFrame="sm"
            kind="tertiary"
            displayMember="name"
            canClear={false}
            itemSource={sort}
            selectedItem={sort.find((x) => x.code === saleState.filter.sort)}
            onSelectionChanged={(e) => dispatch(changeSaleFilter({ ...saleState.filter, sort: e.code }))}
          />
        </div>
      </div>
      <div className="frame-main" style={{ position: "relative" }}>
        <WaitComponent isLoading={isLoading} message={"Proszę czekać trwa pobieranie listy zamówień..."} />
        {saleState.sales.length === 0 ? (
          <EmptyComponent style={{ height: "50%" }} icon={<Icon.DescriptionSVG />} message="Brak zamówień" />
        ) : (
          <>
            {saleState.filter.view === "list" ? (
              <Grid itemSource={saleState.sales}>
                <GridColumn width={120} header="Kod zamówienia" onDisplayContent={onDisplayContentCode} />
                <GridColumn width={"*"} header="Klient" onDisplayContent={onDisplayContentClient} />
                <GridColumn width={"*"} header="Adres" onDisplayContent={onDisplayContentAddress} />
                <GridColumn width={150} header="Status" onDisplayContent={onDisplayContentState} />
                <GridColumn width={150} header="Data zamówienia" onDisplayContent={onDisplayContentDate} />
                <GridColumn width={150} header="Data odbioru" onDisplayContent={onDisplayContentPickUpTime} />
                <GridColumn width={150} header="Suma" onDisplayContent={onDisplayContentSummary} />
                <GridColumn width={50} header="Akcje" onDisplayContent={onDisplayContentButtons} />
              </Grid>
            ) : (
              <div className="frame-scroll pad-r-8">{showGroup()}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SalePage;

//<SaleSettlementsComponent />
