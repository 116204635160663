import React from "react";
import * as Icon from "commons/iconManager";
import { LabelProps } from "controls/Label";
import { Button } from "controls/Button";
import { ModalDialog } from "controls/ModalDialog";
import OpenHourEditComponent from "./OpenHourEditComponent";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { changeUser, changeUserState } from "reducers/userReducer";
import OpenHourBoxComponent from "./OpenHourBoxComponent";
import { AppUser } from "models/new/AppUser";
import { Address } from "models/new/Address";
import { Supplier } from "models/new/Supplier";

export interface OpenHourProps extends LabelProps {
  address?: Address;
  canEdit: boolean;
}

const OpenHourComponent: React.FC<OpenHourProps> = (props) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  if (!props.address) return <></>;

  const editOpenHours = async () => {
    if (!props.address) return;

    const result = await ModalDialog.show(<OpenHourEditComponent address={props.address} />, "Edycja godzin otwarcia");
    if (result.result === "Approve") {
      let user = structuredClone(userState.supplier) as Supplier;
      const adres = structuredClone(props.address);
      adres.openHours = result.value;
      user.addresses = user.addresses?.map((x) => (x.id !== adres.id ? x : adres));
      dispatch(changeUserState({ supplier: user }));
    }
  };

  return (
    <div className="width-fill d-flex fd-c gap-8">
      <OpenHourBoxComponent {...props} />

      {props.canEdit && (
        <Button
          style={{ marginLeft: "auto" }}
          sizeFrame="sml"
          kind="tertiary"
          toolTip={"Edytuj godziny otwarcia"}
          label="Edytuj"
          icon={<Icon.EditSVG />}
          onClick={editOpenHours}
        />
      )}
    </div>
  );
};

export default OpenHourComponent;
