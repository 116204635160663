import React from "react";
import { useSelector } from "react-redux";
import { UserState, WallState } from "models/new/State";
import * as Icon from "commons/iconManager";
import PostComponent from "src/pages/wall/components/PostComponent";
import EmptyComponent from "components/EmptyComponent";
import { Label } from "controls/Label";

const PostListComponent: React.FC = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const wallState: WallState = useSelector((state: any) => state.wall);

  return (
    <div className="d-flex fd-c gap-16">
      {wallState.posts.length === 0 ? (
        <div className="d-flex ai-center jc-center" style={{ height: "80vh", maxWidth: "70vw" }}>
          <EmptyComponent icon={<Icon.GuardianSVG />} message="Nie obserwujesz jeszcze nikogo.">
            <Label
              fontSize="lg"
              wrap
              content={`Dodaj sprzedawców do obserujących i zacznij śledzić ich aktywność tutaj.`}
            />
          </EmptyComponent>
        </div>
      ) : (
        <>
          {wallState.filter === "all" ? (
            <>
              {[...wallState.posts]
                .sort((a, b) => {
                  return new Date(b.date).getTime() - new Date(a.date).getTime();
                })
                .map((post) => {
                  return <PostComponent post={post} />;
                })}
            </>
          ) : (
            <>
              {[...(userState.supplier?.posts ?? [])]
                .sort((a, b) => {
                  return new Date(b.date).getTime() - new Date(a.date).getTime();
                })
                .map((post) => {
                  return <PostComponent post={post} />;
                })}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PostListComponent;
