import { DeliveryType, GroupByDate, StatusOrder } from "models/new/Dictionaries";
import { EntryDictionary } from "models/new/EntryDictionary";

export const sort: EntryDictionary[] = [
  { code: "DESC", name: "Od najnowszych" },
  { code: "ASC", name: "Od najstarszych" },
];

export const delivertType: EntryDictionary[] = [
  { code: "ALL", name: "Wszystkie", value: DeliveryType.All },
  { code: "PickUpAtThePoint", name: "Odbiór w punkcie", value: DeliveryType.PickUpAtThePoint },
  { code: "PickupOnSite", name: "Odbiór w gospodarstwie", value: DeliveryType.PickupOnSite },
  { code: "Post", name: "Przesyłka kurierska", value: DeliveryType.Post },
];

export const orderStatus: EntryDictionary[] = [
  { code: "ALL", name: "Wszystkie", value: StatusOrder.All },
  { code: "New", name: "Nowy", value: StatusOrder.New },
  { code: "InProgress", name: "W przygotowaniu", value: StatusOrder.InProgress },
  { code: "Ready", name: "Gotowe do odbioru/wysyłki", value: StatusOrder.Ready },
  { code: "Sent", name: "Wysłane", value: StatusOrder.Sent },
  { code: "WaitingForPayment", name: "Oczekuje na płatność", value: StatusOrder.WaitingForPayment },
  { code: "Completed", name: "Zrealizowane", value: StatusOrder.Completed },
  { code: "Canceled", name: "Odrzucone", value: StatusOrder.Canceled },
];

export const distance: EntryDictionary[] = [
  { code: "1", name: "+5 km", value: 5 },
  { code: "2", name: "+10 km", value: 10 },
  { code: "3", name: "+15 km", value: 15 },
  { code: "4", name: "+25 km", value: 25 },
  { code: "5", name: "+50 km", value: 50 },
  { code: "6", name: "+100 km", value: 100 },
  //{ code: "7", name: "Cała Polska", value: 1000 },
];

export const groupByDate: EntryDictionary[] = [
 // { code: "all", name: "Bez grupowania", value: GroupByDate.All },
  { code: "d", name: "Dnia", value: GroupByDate.Day },
  { code: "w", name: "Tygodnia", value: GroupByDate.Week },
  { code: "m", name: "Miesiąca", value: GroupByDate.Month },
];
