import React from "react";
import * as Icon from "commons/iconManager";
import { Sale } from "models/new/Sale";
import { Label } from "controls/Label";
import { PopUp } from "controls/PopUp";
import { OrderProduct } from "models/new/OrderProduct";

const SalesMobileSummayComponent: React.FC<{ title: string; sales: Sale[] }> = ({ title, sales }) => {
  function groupByProductName(): Record<string, OrderProduct[]> {
    const products: OrderProduct[] = sales
      .flatMap((x) => x.products)
      .sort((a, b) => a.productId.localeCompare(b.productId));

    return products.reduce((groups, product) => {
      const dateKey = product.productId;
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(product);
      return groups;
    }, {} as Record<string, OrderProduct[]>);
  }

  const showSummary = () => {
    const group = groupByProductName();

    return Object.keys(group).map((item) => {
      return (
        <div className="d-flex ai-e-flex jc-sb width-fill mag-b-4">
          <Label fontSize="sm" fontStyle="bold" content={group[item][0].name} />
          <div className="dot-line" />
          <Label fontSize="sm" fontStyle="bold" content={`${group[item].sum("quantity")} ${group[item][0].unit}`} />
        </div>
      );
    });
  };

  return (
    <div className="d-flex fd-c width-fill" style={{ maxHeight: "90dvh" }}>
      <div className="d-flex jc-sb ai-center width-fill gap-8 pad-b-16">
        <Label wrap fontSize="sm" fontStyle="bold" content={title} />
        <Icon.CloseSVG className="i-button close rounded-12" onClick={() => PopUp.onHide()} />
      </div>
      <div className="f-scroll">{showSummary()}</div>
    </div>
  );
};

export default SalesMobileSummayComponent;
