import React from "react";
import * as Icon from "commons/iconManager";
import { TypeMessageBoxIcon } from "models/new/Dictionaries";
import { Label } from "./Label";

export interface MessageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: TypeMessageBoxIcon;
  iconSize?: "xsm" | "sml" | "sm" | "md" | "lg" | "xl";
  content: string;
}

const MessageBox: React.FC<MessageBoxProps> = (props) => {
  const getIconStyle = () => {
    switch (props.iconSize) {
      case "xsm":
        return " icon-24";
      case "sml":
        return " icon-32";
      case "sm":
        return " icon-40";
      case "md":
        return " icon-48";
      case "lg":
        return " icon-56";
      case "xl":
        return " icon-64";
      default:
        return " icon-80";
    }
  };
  const showIcon = (): JSX.Element => {
    switch (props.icon) {
      case "Success":
        return <Icon.CheckCircleSVG className={`${getIconStyle()} c-green`} />;
      case "Error":
        return <Icon.ErrorSVG className={`${getIconStyle()} c-red`} />;
      case "Question":
        return <Icon.HelpSVG className={`${getIconStyle()} c-blue`} />;
      case "Information":
        return <Icon.InfoSVG className={`${getIconStyle()} c-blue`} />;
      case "Warning":
        return <Icon.WarningSVG className={`${getIconStyle()} c-yellow`} />;
      case "Report":
        return <Icon.ReportSVG className={`${getIconStyle()} c-red`} />;
      default:
        return <></>;
    }
  };

  return (
    <div {...props} className="message-box">
      {showIcon()}
      <Label fontSize="md" fontStyle="semibold" content={props.content} wrap={true} />
    </div>
  );
};

export default MessageBox;
