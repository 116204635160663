import React from "react";
import * as Icon from "commons/iconManager";
import { useDispatch, useSelector } from "react-redux";
import { MainState, UserState } from "models/new/State";
import { changeOpenMenu } from "reducers/mainReducer";
import { useNavigation } from "hooks/useNavigation";
import { useWindowSize } from "hooks/useWindowSize";
import { useLogin } from "hooks/useLogin";

const MenuLeftComponent: React.FC = () => {
  const { isMobile } = useWindowSize();
  const { refreshToken } = useLogin();
  const mainState: MainState = useSelector((state: any) => state.main);
  const userState: UserState = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const { navigateTo } = useNavigation();

  React.useEffect(() => {
    const interval = setInterval(() => {
      console.log("refresh");
      refreshToken();
    }, 600000);
    return () => clearInterval(interval);
  }, []);

  const onClick = () => {
    dispatch(changeOpenMenu());
  };

  const onLogoClick = () => {
    navigateTo("main");
  };

  return (
    <div className="d-flex ai-center">
      {userState.token === undefined ? null : (
        <>
          {mainState.menuOpened ? (
            <Icon.CloseSVG className="i-button close" onClick={onClick} />
          ) : (
            <Icon.MenuSVG className="i-button" onClick={onClick} />
          )}
        </>
      )}
      <Icon.OgrosaSVG
        height={isMobile ? 28 : 34}
        style={{
          paddingInline: isMobile ? "6px" : "12px",
          marginBottom: isMobile ? "4px" : "8px",
          cursor: "pointer",
        }}
        onClick={onLogoClick}
      />
      {userState.view === "supplier" && <Icon.StorefrontFillSVG className="c-secondary" />}
    </div>
  );
};

export default MenuLeftComponent;
