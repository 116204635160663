declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_API: string;
  REACT_APP_GOOGLE_MAPS_API: string;
};
export const env: EnvType = { ...process.env, ...window.env };
