import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";

const LandingFooterComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div
        className="d-flex fd-c width-fill gap-32 pad-16 pad-t-32 bg-secondary"
        style={{ borderTopLeftRadius: "35px", borderTopRightRadius: "35px" }}
      >
        <div className="d-flex fd-c jc-sb width-fill gap-32">
          <div className="d-flex fd-c ai-s-flex gap-8 mag-b-32">
            <Icon.OgrosaSVG height={isMobile ? 25 : 35} />
            <Label fontStyle="extra-bold" fontSize="lg" content="Wybieraj lokalnie, jedz naturalnie!" />
            <Label
              fontStyle="semibold"
              wrap
              content="Aplikacja wspierająca lokalnych rolników, świeżość i najwyższej jakości produkty"
            />
          </div>
          <div className="d-flex fd-c ai-s-flex gap-8">
            <Label fontStyle="extra-bold" fontSize="lg" content="Ważne linki" />
            <Label fontStyle="semibold" islink content="Polityka prywatności" />
            <Label fontStyle="semibold" islink content="Regulamin" />
          </div>
          <div className="d-flex fd-c ai-s-flex gap-8">
            <Label fontStyle="extra-bold" fontSize="lg" wrap content="Social media" />
            <div className="d-flex gap-8">
              <Icon.FacebookSVG className="i-button c-pointer" height={36} width={36} />
              <Icon.InstagramSVG className="i-button c-pointer" height={36} width={36} />
            </div>
          </div>
        </div>
        <Label fontSize="sm" content="Copyright © 2024 Ogrosa" />
      </div>
    );
  }

  return (
    <div
      className="d-flex fd-c width-fill gap-32 pad-16 pad-t-32 bg-secondary"
      style={{ maxWidth: "1136px", borderTopLeftRadius: "35px", borderTopRightRadius: "35px" }}
    >
      <div className="d-flex jc-sb width-fill gap-32">
        <div className="d-flex fd-c ai-s-flex gap-8 mag-b-32">
          <Icon.OgrosaSVG height={isMobile ? 25 : 35} />
          <Label fontStyle="extra-bold" fontSize="lg" content="Wybieraj lokalnie, jedz naturalnie!" />
          <Label
            fontStyle="semibold"
            wrap
            content="Aplikacja wspierająca lokalnych rolników, świeżość i najwyższej jakości produkty"
          />
        </div>
        <div className="d-flex fd-c ai-s-flex gap-8">
          <Label fontStyle="extra-bold" fontSize="lg" content="Ważne linki" />
          <Label fontStyle="semibold" islink content="Polityka prywatności" />
          <Label fontStyle="semibold" islink content="Regulamin" />
        </div>
        <div className="d-flex fd-c ai-s-flex gap-8">
          <Label fontStyle="extra-bold" fontSize="lg" wrap content="Social media" />
          <div className="d-flex gap-8">
            <Icon.FacebookSVG className="i-button c-pointer" height={36} width={36} />
            <Icon.InstagramSVG className="i-button c-pointer" height={36} width={36} />
          </div>
        </div>
      </div>
      <Label fontSize="sm" content="Copyright © 2024 Ogrosa" />
    </div>
  );
};

export default LandingFooterComponent;
