import { Post, PostComment, PostLike } from "models/new/Post";
import * as api from "./api";
import { Picture } from "models/new/Picture";

export function createPost(post: Post): Promise<string> {
  return api.post(`/posts`, { post: post });
}

export function addPostLike(postLike: PostLike): Promise<string> {
  return api.post(`/posts/like`, { postLike: postLike });
}
export function delPostLike(id: string): Promise<string> {
  return api.del(`/posts/${id}/like`);
}

export function addPostComment(postComment: PostComment): Promise<string> {
  return api.post(`/posts/comment`, { postComment: postComment });
}
export function delPostComment(id: string): Promise<string> {
  return api.del(`/posts/${id}/comment`);
}

export function savePostPicture(file: FormData): Promise<Picture[]> {
  return api.postFile(`/files`, file);
}

export function deletePostPicture(id: string): Promise<string> {
  return api.del(`/files/${id}`);
}
