import React from "react";
import { Label, LabelProps } from "controls/Label";

interface EmptyProps extends LabelProps {
  icon: React.ReactElement;
  message: string;
  iconSize?: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const EmptyComponent: React.FC<EmptyProps> = (props) => {
  let clonedIcon;
  if (props.icon) {
    const size = props.iconSize ?? 60;
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-color",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }
  return (
    <div className="f-fill fd-c ai-center jc-center gap-16" style={{ ...props.style }}>
      {clonedIcon}
      <Label
        style={{ textAlign: "center" }}
        wrap
        fontStyle={props.fontStyle ?? "bold"}
        fontSize={props.fontSize ?? "sm"}
        fontColor={props.fontColor ?? "primary"}
        content={props.message}
      />
      {props.children}
    </div>
  );
};

export default EmptyComponent;
