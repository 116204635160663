import { env } from "../env";
import * as signalR from "@microsoft/signalr";

const API_ROOT = env.REACT_APP_API;

let connection: signalR.HubConnection;

export const getConnection = async (userId: string, supplierId?: string): Promise<signalR.HubConnection> => {
  if (connection === undefined || connection.state === signalR.HubConnectionState.Disconnected) {
    connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API_ROOT}/chathub?userId=${userId}&supplierId=${supplierId}`)
      .build();
    await connection.start();
  } else if (connection.state === signalR.HubConnectionState.Connecting) {
    const timeout = (delay) => {
      return new Promise((res) => setTimeout(res, delay));
    };
    await timeout(1000);
  }

  return connection;
};
