import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { PopUp } from "controls/PopUp";
import { OrderProduct } from "models/new/OrderProduct";
import { Order } from "models/new/Order";

const OrderMobileSummayComponent: React.FC<{ title: string; orders: Order[] }> = ({ title, orders }) => {
  function groupByProductName(): Record<string, OrderProduct[]> {
    const products: OrderProduct[] = orders.flatMap((x) => x.products).sort((a, b) => a.name.localeCompare(b.name));

    return products.reduce((groups, product) => {
      const dateKey = product.name;
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(product);
      return groups;
    }, {} as Record<string, OrderProduct[]>);
  }

  const showSummary = () => {
    const group = groupByProductName();

    return Object.keys(group).map((item) => {
      return (
        <div className="d-flex ai-e-flex jc-sb width-fill mag-b-4">
          <Label fontSize="sm" fontStyle="bold" content={item} />
          <div className="dot-line" />
          <Label fontSize="sm" fontStyle="bold" content={`${group[item].sum("quantity")} ${group[item][0].unit}`} />
        </div>
      );
    });
  };

  return (
    <div className="d-flex fd-c width-fill" style={{ maxHeight: "90dvh" }}>
      <div className="d-flex jc-sb ai-center width-fill gap-8 pad-b-16">
        <Label wrap fontSize="sm" fontStyle="bold" content={`Podsumowanie dla ${title}`} />
        <Icon.CloseSVG className="i-button close rounded-12" onClick={() => PopUp.onHide()} />
      </div>
      <div className="f-scroll">{showSummary()}</div>
    </div>
  );
};

export default OrderMobileSummayComponent;
