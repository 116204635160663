import React from "react";
import * as Icon from "commons/iconManager";
import * as PostApi from "api/PostApi";
import InfoBox from "controls/InfoBox";
import PictureBoxComponent from "../../../components/PictureBoxComponent";
import { Label } from "controls/Label";
import { Post } from "models/new/Post";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { setShowPost } from "reducers/mainReducer";
import { setSuppliersPost, setSuppliersPostLike, setSuppliersPostUnlike } from "reducers/searchReducer";
import { generateGUID } from "commons/stringManager";
import { useLogin } from "hooks/useLogin";
import { setPostLike, setPostUnlike } from "reducers/wallReducer";
import { setMyPostLike, setMyPostUnlike } from "reducers/userReducer";

const PostComponent: React.FC<{ post: Post }> = ({ post }) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const { navigateTo } = useNavigation();
  const { signIn } = useLogin();
  const dispatch = useDispatch();

  const showDealer = (): JSX.Element => {
    return (
      <div className="d-flex ai-center gap-16">
        <PictureBoxComponent className="picture-50" url={post.supplier?.logoUrl} />
        <div className="d-flex fd-c gap-4">
          <Label
            fontSize="sm"
            wrap
            islink
            fontStyle="bold"
            content={`${post.supplier?.firstName} ${post.supplier?.lastName} ● ${post.supplier?.companyName}`}
            onClick={() => navigateTo("search", `?dealer_id=${post.supplierId}`)}
          />
          <Label
            fontSize="sml"
            wrap
            fontStyle="semibold"
            fontColor="secondary"
            content={new Date(post.date).toFormatMoment("DD MMM yyyy HH:mm")}
          />
        </div>

        <Button kind="text" sizeFrame="sm" icon={<Icon.MoreHorizSVG />} style={{ marginLeft: "auto" }} />
      </div>
    );
  };

  const showPicture = (): JSX.Element => {
    if (post.pictures?.length === 1)
      return (
        <div className="c-pointer" onClick={showPost}>
          <PictureBoxComponent className="image-on-post" url={post.pictures?.[0]?.url} />
        </div>
      );

    if (post.pictures?.length === 2)
      return (
        <div className="c-pointer" onClick={showPost}>
          <div className="image-grid">
            <PictureBoxComponent className="image-on-post-main" url={post.pictures?.[0]?.url} />
            <PictureBoxComponent className="image-on-post-additional-1" url={post.pictures?.[1]?.url} />
          </div>
        </div>
      );

    if (post.pictures?.length === 3)
      return (
        <div className="c-pointer" onClick={showPost}>
          <div className="image-grid">
            <PictureBoxComponent className="image-on-post-main" url={post.pictures?.[0]?.url} />
            <PictureBoxComponent className="image-on-post-additional-1" url={post.pictures?.[1]?.url} />
            <PictureBoxComponent className="image-on-post-additional-2" url={post.pictures?.[2]?.url} />
          </div>
        </div>
      );

    return <></>;
  };

  const showPost = () => {
    dispatch(setShowPost(true));
    dispatch(setSuppliersPost(post));
  };

  const setLike = async () => {
    if (!userState.token) {
      await signIn();
      return;
    }

    let like = post.likes?.find((x) => x.appUserId == userState.user?.id);
    if (like) {
      await PostApi.delPostLike(like.id);
      dispatch(setSuppliersPostUnlike(like));
      dispatch(setPostUnlike(like));
      dispatch(setMyPostUnlike(like));
    } else {
      like = { id: generateGUID(), appUserId: userState.user!.id, postId: post.id! };
      await PostApi.addPostLike(like);
      dispatch(setSuppliersPostLike(like));
      dispatch(setPostLike(like));
      dispatch(setMyPostLike(like));
    }
  };

  const showOptions = (): JSX.Element => {
    return (
      <div className="d-flex jc-sb ai-center pad-b-4">
        <Button
          style={{ padding: "0px 4px", gap: "0px" }}
          sizeFrame="sm"
          label={`Lubie to! (${post?.likes?.length?.toString() ?? 0})`}
          icon={
            <Icon.OgrosaColorSVG
              className={post?.likes?.some((x) => x.appUserId === userState.user?.id) ? "" : "icon-unlike"}
            />
          }
          kind="text"
          onClick={setLike}
        />
        <Button
          style={{ padding: "0px 4px", gap: "4px" }}
          sizeFrame="sm"
          label={`Komentarze (${post?.comments?.length?.toString() ?? 0})`}
          icon={<Icon.CommentSVG />}
          kind="text"
          onClick={showPost}
        />
      </div>
    );
  };

  return (
    <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sml">
      <div className="d-flex width-fill fd-c gap-16 pad-4">
        {showDealer()}
        <Label fontSize="sm" wrap fontStyle="semibold" content={post.description} pointer onClick={showPost} />
        {showPicture()}
        {showOptions()}
      </div>
    </InfoBox>
  );
};

export default PostComponent;
