export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generateGUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function generateRandom(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.toUpperCase();
}

export const convertToString = (value?: string | number | readonly string[]): string => {
  // if value is a string, just return it
  if (typeof value === "string") {
    return value;
  }

  // if value is a number, convert it to a string
  if (typeof value === "number") {
    return value.toString();
  }

  // if value is an array of strings, join them into a single string
  if (Array.isArray(value)) {
    return value.join(", ");
  }

  // in case the input is none of the above
  return "";
};

export function maxStringChar(length: number = 20, value?: string): string {
  if (!value) return "";
  if (value?.length <= length) return value;

  return value.slice(0, length) + "...";
}
