import React from "react";
import AccountSignInComponent from "./AccountSignInComponent";
import { ModalDialogProps, ModalDialogRef } from "models/new/ModalDialogProps";
import AccountSignUpComponent from "./AccountSignUpComponent";
import AccountRestartPasswordComponent from "./AccountRestartPasswordComponent";
import { AccountPageType, TypeDialogResult } from "models/new/Dictionaries";
import { Token } from "models/new/Token";
import AccountForgotPasswordComponent from "./AccountForgotPasswordComponent";

export interface AccountProps extends ModalDialogProps {
  setPageType: (type: AccountPageType) => void;
  onClick: (type: AccountPageType, token?: Token, email?: string) => Promise<boolean>;
}

interface AccountDesktopProps extends ModalDialogProps {
  page: AccountPageType;
  onClick: (type: AccountPageType, token?: Token, email?: string) => Promise<boolean>;
}

const AccountComponent = React.forwardRef<ModalDialogRef, AccountDesktopProps>(
  (props, ref: React.Ref<ModalDialogRef>) => {
    const [pageType, setPageType] = React.useState<AccountPageType>(props.page);

    React.useImperativeHandle(ref, () => ({
      canClose: canClose,
    }));

    const canClose = (): boolean => {
      if (pageType === "opt") {
        return false;
      }

      return true;
    };

    const onCloseModalDialog = (dialogResult: TypeDialogResult) => {
      props.onCloseModalDialog?.(dialogResult);
    };

    return (
      <div className="frame-main" style={{ maxWidth: "450px" }}>
        {pageType === "sign-in" ? (
          <AccountSignInComponent
            setPageType={setPageType}
            onClick={props.onClick}
            onCloseModalDialog={onCloseModalDialog}
          />
        ) : pageType === "sign-up" ? (
          <AccountSignUpComponent
            setPageType={setPageType}
            onClick={props.onClick}
            onCloseModalDialog={onCloseModalDialog}
          />
        ) : pageType === "reset-password" ? (
          <AccountRestartPasswordComponent
            setPageType={setPageType}
            onClick={props.onClick}
            onCloseModalDialog={onCloseModalDialog}
          />
        ) : pageType === "forgot-password" ? (
          <AccountForgotPasswordComponent
            setPageType={setPageType}
            onClick={props.onClick}
            onCloseModalDialog={onCloseModalDialog}
          />
        ) : null}
      </div>
    );
  }
);

export default AccountComponent;
