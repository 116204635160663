import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { Button } from "controls/Button";
import { Certificate } from "models/new/Certificate";

interface SearchCertificateProps {
  certificate: Certificate;
}

const SearchCertificateComponent: React.FC<SearchCertificateProps> = (props) => {
  const prev = () => {
    window.open(props.certificate.url, "_blank")?.focus();
  };

  return (
    <div className="d-flex ai-center gap-8 pad-8 pad-l-16 border rounded-12 width-fill">
      <Icon.LicenseSVG className="icon-color"  />
      <Label fontStyle="semibold" fontSize="sm" fontColor="secondary" content={`${props.certificate.name} - `} />
      <Label fontStyle="semibold" fontSize="sm" content={props.certificate.docNumber} />
      <Button
        style={{ marginLeft: "auto" }}
        sizeFrame="sml"
        kind="tertiary"
        icon={<Icon.DescriptionSVG />}
        onClick={prev}
        toolTip={"Podgląd dokumentu"}
      />
    </div>
  );
};

export default SearchCertificateComponent;
