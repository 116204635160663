import React from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import EmptyComponent from "components/EmptyComponent";
import { Label } from "controls/Label";
import * as GoogleMaps from "commons/googleManager";
import { useDispatch, useSelector } from "react-redux";
import { SearchState } from "models/new/State";
import { changeSearchState } from "reducers/searchReducer";
import { changeMainState } from "reducers/mainReducer";

const SearchMobileLocationComponent: React.FC = () => {
  const [itemSource, setItemSource] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<string>();
  const searchState: SearchState = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setValue(searchState?.geoLocation?.name);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(async () => {
      const pred: { name: string; prediction: google.maps.places.AutocompletePrediction }[] = [];
      if (value && value.length >= 3) {
        const result = await GoogleMaps.autoCompleteService(value);
        result?.forEach((place) => {
          pred.push({ name: place.description, prediction: place });
        });
      }
      setItemSource(pred);
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const onSelectedChangeLocalization = async (item: any) => {
    if (!item) return;

    const result = await GoogleMaps.placesService(item.prediction.place_id);    
    localStorage.setItem("geoLocation", JSON.stringify({ lat: result.lat, lng: result.lng, name: item.name }));
    dispatch(
      changeSearchState({
        ...searchState,
        geoLocationState: true,
        geoLocation: { lat: result.lat, lng: result.lng, name: item.name },
        mapCenter: { lat: result.lat, lng: result.lng, name: item.name },
      })
    );
    dispatch(changeMainState({ showLocation: false }));
  };

  return (
    <div className="frame-main pad-8" style={{ maxHeight: "350px" }}>
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        placeholder="Wyszukaj (min. 3 znaki)"
        icon={<Icon.SearchSVG />}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="frame-scroll jc-stretch">
        {itemSource.map((item) => {
          return (
            <div className={`search-box border`} onClick={() => onSelectedChangeLocalization(item)}>
              <Icon.LocationOnSVG className="icon-search icon-color" />
              <Label fontSize="sm" fontStyle="bold" wrap pointer content={item.name} />
            </div>
          );
        })}
      </div>
      {itemSource.length === 0 ? (
        <div className="d-flex jc-center ai-center gap-4" style={{ height: "200px" }}>
          <EmptyComponent icon={<Icon.SearchSVG />} message="Brak wyszukiwań" />
        </div>
      ) : null}
    </div>
  );
};

export default SearchMobileLocationComponent;
