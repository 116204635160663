import React from "react";
import { useSelector } from "react-redux";
import MessagePanelItemComponent from "./MessagePanelItemComponent";
import { ConversationState } from "models/new/State";

const MessagePanelComponent: React.FC = () => {
  const conversationState: ConversationState = useSelector((state: any) => state.conversation);

  return (
    <div className="conversation-panel">
      {conversationState.activeConversations?.map((conv) => {
        return <MessagePanelItemComponent conversation={conv} />;
      })}
    </div>
  );
};

export default MessagePanelComponent;
