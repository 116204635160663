import React from "react";
import * as Icon from "commons/iconManager";
import { createRoot } from "react-dom/client";
import { Label } from "./Label";
import { Button } from "./Button";
import { TypeDialogResult, TypeMessageBoxButton } from "models/new/Dictionaries";
import { ModalDialogProps, ModalDialogRef } from "models/new/ModalDialogProps";
import { store } from "../store";
import { Provider } from "react-redux";
import { useWindowSize } from "hooks/useWindowSize";

const makeId = (length = 16): string => {
  const id = "ID" + Math.random().toString(length).replace(/\./g, "");
  return id.slice(0, length);
};

export const ModalDialog = {
  isOpen: false,
  show: (
    content: JSX.Element | string,
    title: string,
    buttons?: TypeMessageBoxButton[],
    isOpen?: boolean,
    canClose?: boolean
  ): Promise<{ result: TypeDialogResult; value?: any }> => {
    return new Promise(async (resolve) => {
      if (ModalDialog.isOpen) {
        return;
      }

      ModalDialog.isOpen = isOpen ?? false;
      let valueFromContent: any = undefined;
      const onChangeValue = (changedValue: any) => {
        valueFromContent = changedValue;
      };

      const onClick = (button: TypeDialogResult) => {
        switch (button) {
          case "Yes":
          case "Ok":
          case "Approve":
            resolve({ result: button, value: valueFromContent });
            break;
          default:
            resolve({ result: button, value: undefined });
            break;
        }

        ModalDialog.isOpen = false;
      };

      const idElement = "modal_" + makeId(10);

      const showContent = (): JSX.Element => {
        if (typeof content === "string") {
          return <Label fontSize="lg" fontStyle="semibold" content={content} />;
        }

        return content;
      };

      const modal = (
        <Provider store={store}>
          <ModalDialogComponent
            parentId={idElement}
            title={title}
            buttons={buttons}
            onChangeValue={onChangeValue}
            onCloseModalDialog={onClick}
            canClose={canClose}
          >
            {showContent()}
          </ModalDialogComponent>
        </Provider>
      );
      const template = document.createElement("div");
      template.id = idElement;
      const root = createRoot(template);
      root.render(modal);
      document.body.appendChild(template);
    });
  },
};

interface ModalDialogCompProps extends ModalDialogProps {
  parentId: string;
  title?: string;
  buttons?: TypeMessageBoxButton[];
  children?: React.ReactNode;
  canClose?: boolean;
}

const ModalDialogComponent: React.FC<ModalDialogCompProps> = (props) => {
  const idElement = React.useId();
  const refChilderen = React.useRef<any>();
  const { isMobile } = useWindowSize();
  const refModalDialog = React.useRef() as React.MutableRefObject<ModalDialogRef>;
  const refModalBg = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const refModal = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    setTimeout(() => {
      if (refModalBg.current && refModal.current) {
        const main = document.getElementById("main-view");
        refModalBg.current.style.opacity = "1";
        refModalBg.current.style.height = (main?.clientHeight ?? 1000) + "px";
        if (isMobile) {
          refModal.current.style.position = "fixed";
          refModal.current.style.bottom = "0px";
        }

        document.body.style.overflow = "hidden";
      }
    }, 100);
  }, []);

  const onCloseModalDialog = (dialogResult: TypeDialogResult) => {
    if (refModalDialog?.current?.canClose?.() !== false) {
      document.body.style.overflow = "unset";
      props.onCloseModalDialog?.(dialogResult);
      const findMe = document.getElementById(`${idElement}`);
      if (findMe) {
        findMe.style.opacity = "0";
        setTimeout(() => {
          const findParent = document.getElementById(`${props.parentId}`);
          findParent?.parentNode?.removeChild(findParent);
        }, 200);
      }
    }
  };

  if (!refChilderen.current && React.isValidElement(props.children)) {
    refChilderen.current = React.cloneElement(props.children as React.ReactElement, {
      onChangeValue: props.onChangeValue,
      onCloseModalDialog: onCloseModalDialog,
      ref: refModalDialog,
    });
  }

  const handleClick = (event) => {
    const clickedElement = event.target as HTMLElement;

    if (clickedElement.id === idElement) {
      props.canClose && onCloseModalDialog("Cancel");
    }
  };

  return (
    <div ref={refModalBg} id={idElement} className="modal-dialog" onClick={handleClick}>
      <div ref={refModal} className="modal-dialog-frame box-shadow-10">
        <div className="modal-dialog-header width-fill">
          <Label wrap fontSize="lg" fontStyle="bold" content={props.title} />
          <Icon.CloseSVG className="i-button close rounded-12" onClick={() => onCloseModalDialog("Cancel")} />
        </div>
        <div className="modal-dialog-body">{refChilderen.current}</div>
        {props.buttons && (
          <div className="modal-dialog-footer" style={{ justifyContent: "flex-end" }}>
            {props.buttons?.some(
              (x) =>
                x === "Cancel" ||
                x === "ApproveCancel" ||
                x === "OkCancel" ||
                x === "RetryCancel" ||
                x === "YesNoCancel" ||
                x === "SelectCancel" ||
                x === "AbortRetryIgnore"
            ) && (
              <Button
                className={isMobile ? "width-fill" : ""}
                label={"Anuluj"}
                sizeFrame={isMobile ? "md" : "sm"}
                onClick={() => onCloseModalDialog("Cancel")}
                fontStyle="bold"
                kind="text"
              />
            )}
            {props.buttons?.some((x) => x === "AbortRetryIgnore") && (
              <Button
                className={isMobile ? "width-fill" : ""}
                label={"Ignoruj"}
                sizeFrame={isMobile ? "md" : "sm"}
                onClick={() => onCloseModalDialog("Ignore")}
                fontStyle="bold"
                kind="text"
              />
            )}
            {props.buttons?.some((x) => x === "AbortRetryIgnore" || x === "RetryCancel") && (
              <Button
                className={isMobile ? "width-fill" : ""}
                label={"Ponów"}
                sizeFrame={isMobile ? "md" : "sm"}
                onClick={() => onCloseModalDialog("Retry")}
                fontStyle="bold"
              />
            )}
            {props.buttons?.some((x) => x === "YesNo" || x === "YesNoCancel") && (
              <Button
                className={isMobile ? "width-fill" : ""}
                label={"Nie"}
                sizeFrame={isMobile ? "md" : "sm"}
                onClick={() => onCloseModalDialog("No")}
                fontStyle="bold"
                kind="text"
              />
            )}
            {props.buttons?.some((x) => x === "Ok" || x === "OkCancel") && (
              <Button
                className={isMobile ? "width-fill" : ""}
                label={"Ok"}
                sizeFrame={isMobile ? "md" : "sm"}
                onClick={() => onCloseModalDialog("Ok")}
                fontStyle="bold"
                kind="approve"
              />
            )}
            {props.buttons?.some((x) => x === "YesNo" || x === "YesNoCancel") && (
              <Button
                className={isMobile ? "width-fill" : ""}
                label={"Tak"}
                sizeFrame={isMobile ? "md" : "sm"}
                onClick={() => onCloseModalDialog("Yes")}
                fontStyle="bold"
                kind="primary"
              />
            )}
            {props.buttons?.some((x) => x === "ApproveCancel") && (
              <Button
                className={isMobile ? "width-fill" : ""}
                label={"Zatwierdź"}
                sizeFrame={isMobile ? "md" : "sm"}
                onClick={() => onCloseModalDialog("Approve")}
                fontStyle="bold"
                kind="approve"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalDialogComponent;
