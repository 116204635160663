import React from "react";
import * as Icon from "commons/iconManager";
import { useDispatch, useSelector } from "react-redux";
import { MainState, UserState } from "models/new/State";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { useLoadScript } from "@react-google-maps/api";
import { changeOpenMenu, setMapLoaded } from "reducers/mainReducer";
import { env } from "../../env";
import { TypeRoutePage } from "models/new/Dictionaries";
import { useWindowSize } from "hooks/useWindowSize";
import { changeView } from "reducers/userReducer";
import MenuRightComponent from "./components/MenuRightComponent";
import MenuLeftComponent from "./components/MenuLeftComponent";
import MessagePanelComponent from "components/messager/MessagePanelComponent";
import MenuRoutesComponent from "./components/MenuRoutesComponent";
import MenuSlidersComponent from "./components/MenuSlidersComponent";
import { Label } from "controls/Label";

const MainPage: React.FC = () => {
  const { isMobile } = useWindowSize();
  const mainState: MainState = useSelector((state: any) => state.main);
  const userState: UserState = useSelector((state: any) => state.user);
  const { navigateTo } = useNavigation();
  const dispatch = useDispatch();
  const lastScrollYRef = React.useRef(0);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API,
    libraries: ["places"],
  });

  React.useEffect(() => {
    document.title = "Ogrosa | Wybieraj lokalnie, jedz naturalnie!";
  }, []);

  React.useEffect(() => {
    if (isLoaded) {
      dispatch(setMapLoaded(isLoaded));
    }
  }, [isLoaded]);

  const handleScroll = () => {
    if (!isMobile) return;

    const isScrollingUp = window.scrollY < lastScrollYRef.current;
    lastScrollYRef.current = window.scrollY;

    const top1ClassName = getStyle("f-top ", "f-show", isScrollingUp);
    const top2ClassName = getStyle("f-page-top ", "f-show-64", isScrollingUp);

    let translateYValue = "0px";
    let top2bg = "";
    if (!isScrollingUp) {
      translateYValue = window.scrollY > 150 ? "0px" : `-${window.scrollY}px`;
    }

    top2bg = window.scrollY < 5 ? "" : `f-page-top-bg`;

    const top1Element = document.getElementById("top-main");
    const top2Element = document.getElementById("top-page");

    if (top1Element) {
      top1Element.className = top1ClassName;
      top1Element.style.transform = `translateY(${translateYValue})`;
    }

    if (top2Element) {
      top2Element.className = `${top2ClassName} ${top2bg}`;
      top2Element.style.transform = `translateY(${translateYValue})`;
    }
  };

  const getStyle = (className: string, show: string, isScrollingUp: boolean): string => {
    let style = `${className}`;

    if (window.scrollY > 100) {
      style += isScrollingUp ? show : "f-hide";
    }

    return style;
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  const goTo = (path: TypeRoutePage, paramsUrl?: string) => {
    if (isMobile) {
      dispatch(changeOpenMenu());
    }
    navigateTo(path, paramsUrl);
  };

  const switchUser = () => {
    navigateTo("search");
    dispatch(changeView(userState.view === "client" ? "supplier" : "client"));
  };

  if (isMobile) {
    return (
      <div id="main-view" className="f-main">
        <MenuSlidersComponent />
        <div id="top-main" className="f-top">
          <MenuLeftComponent />
          <MenuRightComponent />
        </div>
        <div className="f-center">
          <MenuRoutesComponent />
        </div>
      </div>
    );
  } else {
    return (
      <div id="main-view" className="f-main">
        <MenuSlidersComponent />
        <div id="top1" className="f-top">
          <MenuLeftComponent />
          <MenuRightComponent />
        </div>
        <div className="f-panel box-shadow-5">
          {userState.token && (
            <div className={`f-menu ${mainState.menuOpened ? "f-menu-opened" : ""}`}>
              <div className="menu-top">
                <Button
                  label="Szukaj"
                  icon={<Icon.SearchSVG />}
                  kind={mainState.mainRoute === "search" ? "menu-seleceted" : "menu"}
                  onClick={() => goTo("search")}
                  toolTip={"Szukaj na mapie"}
                  toolTipPosition="right"
                />
                <Button
                  label="Tablica"
                  icon={<Icon.PublicSVG />}
                  kind={mainState.mainRoute === "wall" ? "menu-seleceted" : "menu"}
                  onClick={() => goTo("wall")}
                  toolTip={"Tablica"}
                  toolTipPosition="right"
                />
                <div className="sepparator">
                  <Label
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      top: "-10px",
                      paddingInline: "8px",
                      background: "var(--bg-secondary)",
                    }}
                    content={mainState.menuOpened ? "Zakupy" : "Z"}
                    fontColor="secondary"
                    fontStyle="bold"
                    fontSize="sm"
                  />
                </div>
                <Button
                  label="Moje zamówienia"
                  icon={<Icon.ShoppingBagSVG />}
                  kind={mainState.mainRoute === "orders" ? "menu-seleceted" : "menu"}
                  onClick={() => goTo("orders")}
                  toolTip={"Moje zamówienia"}
                  toolTipPosition="right"
                />
                <div className="sepparator">
                  <Label
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      top: "-10px",
                      paddingInline: "8px",
                      background: "var(--bg-secondary)",
                    }}
                    content={mainState.menuOpened ? "Sprzedaż" : "S"}
                    fontColor="secondary"
                    fontStyle="bold"
                    fontSize="sm"
                  />
                </div>
                {userState.user?.isSupplier && (
                  <Button
                    label="Produkty"
                    icon={<Icon.GrocerySVG />}
                    kind={mainState.mainRoute === "grocery" ? "menu-seleceted" : "menu"}
                    onClick={() => goTo("grocery")}
                    toolTip={"Produkty"}
                    toolTipPosition="right"
                  />
                )}
                {userState.user?.isSupplier && (
                  <Button
                    label="Moja sprzedaż"
                    icon={<Icon.StoreSVG />}
                    kind={mainState.mainRoute === "sales" ? "menu-seleceted" : "menu"}
                    onClick={() => goTo("sales")}
                    toolTip={"Moja sprzedaż"}
                    toolTipPosition="right"
                  />
                )}
                {!userState.supplier?.subscription && (
                  <Button
                    className="border"
                    label="Dołącz do sprzedawców"
                    icon={<Icon.AddBusinessSVG />}
                    kind={mainState.mainRoute === "sales" ? "menu-seleceted" : "menu"}
                    onClick={() => goTo("profile", `?tab=subscription`)}
                    fontStyle="bold"
                    toolTip={"Dołącz do sprzedawców"}
                    toolTipPosition="right"
                  />
                )}
              </div>
            </div>
          )}
          <div className="f-center">
            <MenuRoutesComponent />
            <MessagePanelComponent />
            <audio id="audio-notify">
              <source src="sounds/notify.wav" type="audio/wav" />
            </audio>
            <audio id="audio-notify-error">
              <source src="sounds/notify-error.wav" type="audio/wav" />
            </audio>
            <audio id="audio-new-conversation">
              <source src="sounds/new-conversation.wav" type="audio/wav" />
            </audio>
            <audio id="audio-new-message">
              <source src="sounds/new-message.wav" type="audio/wav" />
            </audio>
          </div>
        </div>
      </div>
    );
  }
};

export default MainPage;
