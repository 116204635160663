import { AppUser } from "models/new/AppUser";
import { Token } from "../models/new/Token";
import * as api from "./api";
import { Follower } from "models/new/Follower";
import { Post } from "models/new/Post";
import { Notify } from "models/new/Notify";

export function registrationUser(create: { email: string; password: string }): Promise<any> {
  return api.postWithoutToken(`/register`, { email: create.email, password: create.password });
}
export function authenticater(query: { email: string; password: string }): Promise<Token> {
  return api.postWithoutToken(`/login`, { email: query.email, password: query.password });
}
export function refresh(refreshToken: string): Promise<Token> {
  return api.postWithoutToken(`/refresh`, { refreshToken: refreshToken });
}
export function forgotPassword(email: string): Promise<Token> {
  return api.postWithoutToken(`/identity/forgotPassword`, { email: email });
}
export function resetPassword(email: string, resetCode: string, newPassword: string): Promise<any> {
  return api.postWithoutToken(`/identity/resetPassword`, {
    email: email,
    resetCode: resetCode,
    newPassword: newPassword,
  });
}
export function emailConfirmedMessage(email: string): Promise<Token> {
  return api.getWithoutToken(`/identity/${email}/emailConfirmedMessage`);
}
export function checkEmailConfirmed(email: string): Promise<any> {
  return api.getWithoutToken(`/identity/${email}/checkEmailConfirmed`);
}
export function emailConfirm(email: string, confirmCode: string): Promise<any> {
  return api.postWithoutToken(`/identity/emailConfirm`, { email: email, token: confirmCode });
}

export function getUserByEmail(email: string): Promise<AppUser> {
  return api.getWithoutToken(`/users`, { params: { email: email } });
}
export function getUserById(id: string): Promise<AppUser> {
  return api.getWithoutToken(`/users`, { params: { id: id } });
}
export function getFollowers(appUserId: string): Promise<Follower[]> {
  return api.get(`/users/${appUserId}/followers`);
}

export function getPosts(appUserId: string): Promise<Post[]> {
  return api.get(`/users/${appUserId}/posts`);
}

export function getNotifications(appUserId: string): Promise<Notify[]> {
  return api.get(`/users/${appUserId}/notifications`);
}