import React from "react";
import { Label } from "controls/Label";
import * as Icon from "commons/iconManager";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import InfoBox from "controls/InfoBox";
import { Button } from "controls/Button";

interface PictureManager {
  title?: string;
  subTitle?: string;
  file?: File;
  required: boolean;
  onChange: (file?: File) => void;
}

const PictureProfileComponent: React.FC<PictureManager> = (props) => {
  const [file, setFile] = React.useState<File | undefined>();
  const [overDrop, setOverDrop] = React.useState<boolean>(false);
  const [mouseOver, setMouseOver] = React.useState<number>(-1);
  const refInput = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useEffect(() => {
    setFile(props.file);
  }, [props.file]);

  const selectFile = (file?: File) => {
    if (file) {
      setFile(file);
      return;
    }
    refInput.current.click();
  };

  const handleFileInputChange = (event: any) => {
    const file: File = event.target.files[0];
    addImage(file);
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file: File = e.dataTransfer.files[0];
      addImage(file);
    }
  };
  const onDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onDragEnter = (e: any) => {
    setOverDrop(true);
    e.preventDefault();
    e.stopPropagation();
  };
  const onDragLeave = (e: any) => {
    setTimeout(() => {
      setOverDrop(false);
      e.preventDefault();
      e.stopPropagation();
    }, 2000);
  };

  const addImage = (file: File) => {
    if (file === undefined) {
      return;
    }
    if (file.size > 5242880) {
      ModalDialog.show(
        <MessageBox
          content={`Przekroczono limit rozmiaru zdjęcia! Maksymalny rozmiar zdjęcia to 5MB.`}
          icon="Information"
        />,
        "Limit",
        ["Ok"]
      );
      return;
    }
    if (file.type.includes("png") || file.type.includes("jpg") || file.type.includes("jpeg")) {
      setFile(file);
      props.onChange(file);
      setMouseOver(-1);
      refInput.current.value = "";
    } else {
      ModalDialog.show(
        <MessageBox content={`Wybierz zdjęcie z rozszerzeniem png, jpg lub jpeg.`} icon="Information" />,
        "Złe rozszrzenie pliku!",
        ["Ok"]
      );
      return;
    }
  };

  const clearImage = () => {
    setTimeout(() => {
      setFile(undefined);
      props.onChange(undefined);
    }, 200);
  };

  return (
    <div className="d-flex fd-c ai-s-flex gap-16">
      <div className="d-flex ai-center jc-sb width-fill gap-4">
        <div className="d-flex fd-c ai-s-flex gap-4">
          <div className="d-flex gap-4">
            <Label fontSize="lg" fontStyle="bold" content={props.title ?? "Zdjęcia profilowe"} />
            {props.required && <Label fontSize="lg" fontStyle="bold" fontColor="red" content={"*"} />}
          </div>
          <Label fontSize="md" fontStyle="semibold" content={props.subTitle ?? "Dodaj swoje zdjęcie profilowe."} />
        </div>
        <Button
          kind="transparent"
          sizeFrame="lg"
          style={{ padding: "0px" }}
          icon={<Icon.InfoSVG />}
          toolTip="Przeciągnij zdjęcie w miejsce obrazka aparatu lub wybierz z dysku klikając w aparat pamiętaj, że maksymalny rozmiar zdjęcia 5MB"
        />
      </div>

      <div className="d-flex fd-r ai-s-flex gap-16">
        <div
          className="add-picture-box"
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onMouseOver={() => setMouseOver(0)}
          onMouseLeave={() => setMouseOver(-1)}
          onClick={() => selectFile(file)}
        >
          {file ? (
            <>
              {mouseOver === 0 ? (
                <div className="d-flex fd-c jc-center ai-center gap-4" onClick={clearImage}>
                  <Icon.DeleteSVG className="icon" />
                  <Label fontSize="sml" fontStyle="semibold" pointer content="Usuń zdjęcie" />
                </div>
              ) : (
                <img
                  className="picture"
                  src={file.size === 0 ? file.type : URL.createObjectURL(file)}
                  alt={file.name}
                />
              )}
            </>
          ) : (
            <div className="d-flex fd-c jc-center ai-center gap-4">
              <Icon.PhotoCameraSVG className={`icon ${overDrop && "icon--move"}`} />
              <Label fontSize="sml" fontStyle="semibold" pointer content="Dodaj zdjęcie" />
            </div>
          )}
        </div>
      </div>
      <input
        style={{ display: "none" }}
        ref={refInput}
        type="file"
        accept=".png, .jpg, .jpeg"
        multiple={false}
        onChange={(e) => handleFileInputChange(e)}
      />
    </div>
  );
};

export default PictureProfileComponent;
