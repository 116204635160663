import React from "react";
import BasketSummaryComponent from "./components/BasketSummaryComponent";
import { useDispatch } from "react-redux";
import { clearBasketValideError } from "reducers/basketReducer";
import BasketMobilePage from "./components/mobile/BasketMobilePage";
import { useWindowSize } from "hooks/useWindowSize";

const BasketPage: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    document.title = "Ogrosa | Koszyk";
    return () => {
      dispatch(clearBasketValideError());
    };
  }, []);

  if (isMobile) return <BasketMobilePage />;

  return (
    <div className="f-fill">
      <BasketSummaryComponent />
    </div>
  );
};

export default BasketPage;
