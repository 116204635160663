import React from "react";
import * as Icon from "commons/iconManager";
import * as OrderApi from "api/OrderApi";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { OrderState, UserState } from "models/new/State";
import { useNavigation } from "hooks/useNavigation";
import { getLastStatus } from "models/new/OrderStatus";
import { StatusOrder } from "models/new/Dictionaries";
import { changeMainState } from "reducers/mainReducer";
import { ModalDialog } from "controls/ModalDialog";
import { setSelectedOrder } from "reducers/orderReducer";
import { PopUp } from "controls/PopUp";
import MessageBox from "controls/MessageBox";
import OrdersItemDetailsComponent from "../OrdersItemDetailsComponent";
import ProductSummaryOrderComponent from "components/ProductSummaryOrderComponent";

const OrderMobileManagerComponent: React.FC = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const orderState: OrderState = useSelector((state: any) => state.order);
  const dispatch = useDispatch();
  const { navigateTo } = useNavigation();
  const btnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  if (!orderState.selected) return <></>;

  function goTo(tab: string): void {
    navigateTo("order_manager", `?tab=${tab}&id=${orderState.selected!.id}`);
    const childElement = document.getElementById(tab);
    childElement?.scrollIntoView({ behavior: "smooth" });
  }

  const onChangeStatus = async () => {
    PopUp.onHide();
    const result = await ModalDialog.show(
      <MessageBox content={`Czy napewno chcesz anulować zamówienie?`} icon="Question" />,
      "Zmiana statusu",
      ["YesNo"]
    );
    if (result.result === "Yes") {
      OrderApi.putchangeOrderStatus(userState.user!.id, orderState.selected!.id, StatusOrder.Canceled)
        .then(() => {
          dispatch(setSelectedOrder(undefined));
        })
        .catch((err) => alert("SalesItemDetailsComponent onChangeStatus"));
    }
  };

  const showMore = async () => {
    PopUp.show(
      btnRef.current,
      <div className="d-flex fd-c width-fill gap-8">
        <Button
          className="width-fill"
          sizeFrame="lg"
          kind="tertiary"
          label="Export"
          fontStyle="bold"
          icon={<Icon.ShareSVG />}
          onClick={() => alert("Funkcjonalność w budowie")}
        />
        <Button
          className="width-fill"
          sizeFrame="lg"
          kind="tertiary"
          label="Drukuj"
          fontStyle="bold"
          icon={<Icon.PrintSVG />}
          onClick={() => alert("Funkcjonalność w budowie")}
        />
        {orderState.selected && getLastStatus(orderState.selected.statuses) === StatusOrder.Completed && (
          <Button
            className="width-fill"
            sizeFrame="lg"
            kind="tertiary"
            label="Oceń i skomentuj"
            fontStyle="bold"
            icon={<Icon.StarSVG />}
            onClick={() => {
              dispatch(changeMainState({ showOrderRating: true }));
              PopUp.onHide();
            }}
          />
        )}
        {orderState.selected && getLastStatus(orderState.selected.statuses) === StatusOrder.New && (
          <Button
            className="width-fill"
            sizeFrame="lg"
            kind="cancel"
            label="Anuluj zamówienie"
            fontStyle="bold"
            onClick={onChangeStatus}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div id="top-page" className="f-page-top">
        <Button
          sizeFrame="md"
          kind="tertiary"
          fontStyle="bold"
          icon={<Icon.ArrowBackSVG />}
          onClick={() => navigateTo("orders")}
        />
        <div className="f-scroll-hidden-r gap-8">
          <Button kind="tertiary" sizeFrame="md" label="Szczegóły zamówienia" onClick={() => goTo("details")} />
          <Button kind="tertiary" sizeFrame="md" label="Kupione produkty" onClick={() => goTo("products_summary")} />
        </div>
      </div>
      <div className="f-page-center fd-c gap-32" style={{ marginBottom: "78px" }}>
        <OrdersItemDetailsComponent order={orderState.selected} />
        <ProductSummaryOrderComponent
          products={orderState.selected.products}
          deliveryCost={orderState.selected.deliveryCost}
          ratings={orderState.selected.productRatings}
        />
        <Button
          ref={btnRef}
          sizeFrame="lg"
          kind="primary"
          style={{ position: "fixed", bottom: "16px", right: "16px" }}
          fontStyle="bold"
          fontSize="md"
          icon={<Icon.MoreVertSVG />}
          onClick={showMore}
        />
      </div>
    </>
  );
};

export default OrderMobileManagerComponent;
