import React from "react";
import * as Icon from "commons/iconManager";
import { SearchState } from "models/new/State";
import { useSelector } from "react-redux";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";
import EmptyComponent from "components/EmptyComponent";
import SearchCertificateComponent from "./SearchCertificateComponent";

const SearchSupplierInfoCertificationComponent: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const searchState: SearchState = useSelector((state: any) => state.search);

  React.useEffect(() => {
    if (searchState.supplierInfo?.certificates) {
      setIsLoading(false);
    }
  }, [searchState.supplierInfo?.certificates]);

  return (
    <div className="width-fill">
      {isLoading ? (
        <WaitOnLoadingComponent
          isLoading={isLoading}
          count={1}
          className="width-fill rounded-12"
          style={{ minHeight: "200px", marginTop: "8px", marginRight: "4px" }}
        />
      ) : (
        <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
          <div className="d-flex fd-c width-fill gap-8">
            <Label fontSize="lg" fontStyle="semibold" content="Certyfikaty sprzedawcy" />
            {!searchState.supplierInfo?.certificates || searchState.supplierInfo?.certificates?.length === 0 ? (
              <EmptyComponent
                fontStyle="semibold"
                message="Brak certyfikatów"
                iconSize={50}
                icon={<Icon.UnlicenseSVG />}
              />
            ) : (
              <>
                {searchState.supplierInfo.certificates.map((cert) => {
                  return <SearchCertificateComponent certificate={cert} />;
                })}
              </>
            )}
          </div>
        </InfoBox>
      )}
    </div>
  );
};

export default SearchSupplierInfoCertificationComponent;
