import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";
import { InputText } from "controls/InputText";
import { ModalDialogProps } from "models/new/ModalDialogProps";
import { generateGUID } from "commons/stringManager";
import { Certificate } from "models/new/Certificate";

interface CertificateManagerProps extends ModalDialogProps {
  productId?: string;
  supplierId: string;
  certificate?: Certificate;
}

const initialError = {
  errorName: "",
  errorDocNumber: "",
  errorURL: "",
};

const CertificateManagerComponent: React.FC<CertificateManagerProps> = (props) => {
  const [certificate, setCertificate] = React.useState<Certificate>(
    props.certificate ?? {
      id: generateGUID(),
      name: "",
      url: "",
      docNumber: "",
      supplierId: props.supplierId,
      productId: props.productId,
    }
  );
  const [error, setError] = React.useState(initialError);

  const onApproved = () => {
    if (!isValid()) return;

    props.onChangeValue?.(certificate);
    props.onCloseModalDialog?.("Approve");
  };

  const isValid = (): boolean => {
    let result: boolean = true;
    let newError = initialError;

    if (certificate.name.length === 0) {
      newError = { ...newError, errorName: "Brak nazwy certyfikatu!" };
      result = false;
    }
    if (certificate.docNumber.length === 0) {
      newError = { ...newError, errorDocNumber: "Brak numeru dokumentu!" };
      result = false;
    }
    if (certificate.url.length === 0) {
      newError = { ...newError, errorURL: "Brak linku do źródła certyfikatu!" };
      result = false;
    }

    if (!result) {
      setError({ ...error, ...newError });
    }

    return result;
  };

  return (
    <div className="d-flex fd-c ai-s-flex gap-16 pad-b-16 width-fill">
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Nazwa certyfikatu"
        placeholder="np. Polski produkt"
        error={error.errorName}
        value={certificate.name}
        onChange={(e) => setCertificate({ ...certificate, name: e.target.value })}
      />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Numer dokumentu"
        placeholder="np. PL-EKO-01.856-0015000.2024.001"
        error={error.errorDocNumber}
        value={certificate.docNumber}
        onChange={(e) => setCertificate({ ...certificate, docNumber: e.target.value })}
      />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Link do dokumentu"
        placeholder="https://"
        error={error.errorURL}
        value={certificate.url}
        onChange={(e) => setCertificate({ ...certificate, url: e.target.value })}
      />
      <div className="frame-footer width-fill">
        <Button
          className="width-fill"
          label="Zatwierdź"
          kind="primary"
          sizeFrame="md"
          fontStyle="bold"
          icon={<Icon.DoneSVG />}
          onClick={onApproved}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default CertificateManagerComponent;
