import React from "react";
import * as Icon from "commons/iconManager";
import * as OrderApi from "api/OrderApi";
import { Sale } from "models/new/Sale";
import OrdersProcessComponent from "../../order/components/OrdersProcessComponent";
import ClientCardComponent from "components/ClientCardComponent";
import AddressComponent from "components/AddressComponent";
import { Label } from "controls/Label";
import { Tag } from "controls/Tag";
import InfoBox from "controls/InfoBox";
import { EntryDictionary } from "models/new/EntryDictionary";
import { MultiButton } from "controls/MultiButton";
import {
  DeliveryType,
  PaymentType,
  StatusOrder,
  showStatusOrderCodeTag,
  statusOrderToString,
} from "models/new/Dictionaries";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSale } from "reducers/saleReducer";
import { useWindowSize } from "hooks/useWindowSize";
import DealerCardComponent from "components/DealerCardComponent";
import { UserState } from "models/new/State";
import { getLastStatus } from "models/new/OrderStatus";

const changeStatus: EntryDictionary[] = [
  {
    code: "1",
    name: "Oczekuje na zatwierdzenie",
    key: "bg-yellow",
    type: StatusOrder.New,
    data: [
      { name: "Odrzucono", value: StatusOrder.Canceled },
      { name: "W przygotowaniu", value: StatusOrder.InProgress },
    ],
    value: <Icon.PendingSVG className="icon-black" />,
  },
  {
    code: "2",
    name: "Odrzucono",
    key: "bg-red",
    type: StatusOrder.Canceled,
    data: [],
    value: <Icon.DangerousSVG className="icon-black" />,
  },
  {
    code: "3",
    name: "Oczekuje na płatność",
    key: "bg-blue",
    type: StatusOrder.WaitingForPayment,
    data: [
      { name: "W przygotowaniu", value: StatusOrder.InProgress },
      { name: "Gotowe do odbioru/wysyłki", value: StatusOrder.Ready },
    ],
    value: <Icon.PaymentsSVG className="icon-black" />,
  },
  {
    code: "4",
    name: "W przygotowaniu",
    key: "bg-blue",
    type: StatusOrder.InProgress,
    data: [
      { name: "Gotowe do odbioru/wysyłki", value: StatusOrder.Ready },
      { name: "Wysłane", value: StatusOrder.Sent },
    ],
    value: <Icon.GrocerySVG className="icon-black" />,
  },
  {
    code: "5",
    name: "Gotowe do odbioru/wysyłki",
    key: "bg-blue",
    type: StatusOrder.Ready,
    data: [
      { name: "Wysłane", value: StatusOrder.Sent },
      { name: "Zrealizowane", value: StatusOrder.Completed },
    ],
    value: <Icon.InventorySVG className="icon-black" />,
  },
  {
    code: "6",
    name: "Wysłane",
    key: "bg-yellow",
    type: StatusOrder.Sent,
    data: [{ name: "Zrealizowane", value: StatusOrder.Completed }],
    value: <Icon.LocalShippingSVG className="icon-black" />,
  },
  {
    code: "7",
    name: "Zrealizowane",
    key: "bg-green",
    type: StatusOrder.Completed,
    data: [],
    value: <Icon.OrdersSVG className="icon-black" />,
  },
];

const SalesItemDetailsComponent: React.FC<{ sale: Sale }> = ({ sale }) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const showTag = (state: any) => {
    return showStatusOrderCodeTag(state, "sml");
  };

  const getKindPayment = () => {
    const tag: React.ReactNode =
      sale.payment === PaymentType.Blik ? (
        <Icon.BlikSVG style={{ minHeight: "32px", minWidth: "32px", height: "32px", width: "32px" }} />
      ) : sale.payment === PaymentType.Transfer ? (
        <Icon.AccountBalanceSVG style={{ minHeight: "32px", minWidth: "32px" }} />
      ) : sale.payment === PaymentType.Cash ? (
        <Icon.PaymentsSVG style={{ minHeight: "32px", minWidth: "32px" }} />
      ) : sale.payment === PaymentType.Card ? (
        <Icon.CreditCardSVG style={{ minHeight: "32px", minWidth: "32px" }} />
      ) : (
        <Icon.BlikSVG style={{ minHeight: "32px", minWidth: "48px", height: "32px", width: "48px" }} />
      );
    return showComponent("Płatność", tag);
  };

  const getKindDelivery = () => {
    const tag: React.ReactNode =
      sale.deliveryType === DeliveryType.Post ? (
        <Tag sizeFrame="sml" kind="yellow" label="Przesyłka kurierska" icon={<Icon.LocalShippingSVG />} />
      ) : sale.deliveryType === DeliveryType.PickUpAtThePoint ? (
        <Tag sizeFrame="sml" kind="yellow" label="Odbiór w punkcie" icon={<Icon.StorefrontSVG />} />
      ) : sale.deliveryType === DeliveryType.PickupOnSite ? (
        <Tag sizeFrame="sml" kind="yellow" label="Odbiór osobity" icon={<Icon.GrocerySVG />} />
      ) : (
        <></>
      );
    return showComponent("Rodzaj przesyłki", tag);
  };

  const getAddressDelivery = () => {
    return (
      <>
        {sale.deliveryType === DeliveryType.Post ? (
          showComponent("Dane przesyłkowe", <AddressComponent address={sale.address} />)
        ) : sale.deliveryType === DeliveryType.PickUpAtThePoint ? (
          <div className="d-flex width-fill fd-c">
            {showComponent("Adres odbioru", <AddressComponent address={sale.address} />)}
            {showComponent(
              "Data i czas odbioru",
              <div className="d-flex fd-c">
                <Label
                  fontSize="sm"
                  fontStyle="semibold"
                  content={new Date(sale.pickUpDate ?? "").toFormatMoment("DD MMMM yyyy")}
                />
                <Label
                  fontSize="sm"
                  fontStyle="semibold"
                  content={new Date(sale.pickUpDate ?? "").toFormatMoment("HH:mm")}
                />
              </div>
            )}
          </div>
        ) : sale.deliveryType === DeliveryType.PickupOnSite ? (
          showComponent(
            "Data i czas odbioru",
            <div className="d-flex fd-c">
              <Label
                fontSize="sm"
                fontStyle="semibold"
                content={new Date(sale.pickUpDate ?? "").toFormatMoment("DD MMMM yyyy")}
              />
              <Label
                fontSize="sm"
                fontStyle="semibold"
                content={new Date(sale.pickUpDate ?? "").toFormatMoment("HH:mm")}
              />
            </div>
          )
        ) : null}
      </>
    );
  };

  const onChangeStatus = async (statusOrder: StatusOrder) => {
    const result = await ModalDialog.show(
      <MessageBox
        content={`Czy napewno chcesz zmienić status zamówienia na - ${statusOrderToString(statusOrder)}?`}
        icon="Information"
      />,
      "Zmiana statusu",
      ["YesNo"]
    );
    if (result.result === "Yes") {
      OrderApi.putchangeOrderStatus(userState.user!.id, sale.id, statusOrder)
        .then(() => {
          dispatch(setSelectedSale(undefined));
        })
        .catch((err) => alert("SalesItemDetailsComponent onChangeStatus"));
    }
  };

  const getChangeStateButton = (): JSX.Element => {
    const status = getLastStatus(sale.statuses);
    const find = changeStatus.find((x) => x.type === status);
    if (find && find.data.length > 0) {
      return (
        <MultiButton label={find.name} sizeFrame="sml" kind="transparent" className={find.key} icon={find.value}>
          {find.data.map((item) => {
            return (
              <div
                className="d-flex ai-center gap-4 hover-tertiary pad-4 rounded-12 c-pointer"
                onClick={() => onChangeStatus(item.value)}
              >
                {showTag(find.type)}
                <Icon.ArrowForwardSVG className="icon-color" height={16} />
                {showTag(item.value)}
              </div>
            );
          })}
        </MultiButton>
      );
    }
    return showTag(status);
  };

  const showComponent = (title: string, element: React.ReactNode): React.ReactNode => {
    return (
      <div
        className="width-fill pad-t-16 pad-b-16 border-bottom"
        style={{ display: "grid", gridTemplateColumns: "180px auto" }}
      >
        <div style={{ gridColumn: 1 }}>
          <Label fontSize="sm" fontColor="secondary" fontStyle="semibold" content={title} />
        </div>
        <div style={{ gridColumn: 2 }}>{element}</div>
      </div>
    );
  };

  const showComponentMobile = (title: string, element: React.ReactNode): React.ReactNode => {
    return (
      <div className="d-flex fd-c width-fill pad-t-16 pad-b-16 gap-4 border-bottom">
        <Label fontSize="sm" fontColor="secondary" fontStyle="semibold" content={title} />
        {element}
      </div>
    );
  };

  if (isMobile) {
    return (
      <div id="details" className="d-flex fd-c width-fill gap-8 pad-t-16" style={{ maxWidth: "700px" }}>
        <InfoBox
          className="width-fill"
          backgroundColor="secondary"
          style={{ minWidth: "300px", maxWidth: "700px" }}
          sizeFrame="md"
        >
          <div className="width-fill d-flex fd-c " style={{ flexWrap: "wrap", overflow: "hidden" }}>
            <Label fontSize="lg" fontStyle="bold" content="Szczegóły zamówienia" />
            {showComponentMobile("Numer zamówienia", <Label wrap fontStyle="semibold" content={sale.code} />)}
            {showComponentMobile("Klient", <ClientCardComponent client={sale.appUser} rating={sale.supplierRating}/>)}
            {showComponentMobile("Status zamówienia", getChangeStateButton())}
            {showComponentMobile("Stan zamówienia", <OrdersProcessComponent order={sale} />)}
            {getKindPayment()}
            {getKindDelivery()}
            {getAddressDelivery()}
            {showComponentMobile(
              "Dodatkowe informacje",
              <Label wrap fontStyle="semibold" content={sale.description} />
            )}
          </div>
        </InfoBox>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c width-fill gap-8 pad-t-16" style={{ maxWidth: "700px" }}>
      <Label fontSize="lg" fontStyle="bold" content="Szczegóły zamówienia" />
      <InfoBox
        className="width-fill"
        backgroundColor="secondary"
        style={{ minWidth: "300px", maxWidth: "700px" }}
        sizeFrame="md"
      >
        <div className="width-fill d-flex fd-c " style={{ flexWrap: "wrap", overflow: "hidden" }}>
          {showComponent("Numer zamówienia", <Label wrap fontStyle="semibold" content={sale.code} />)}
          {showComponent("Klient", <ClientCardComponent client={sale.appUser} rating={sale.supplierRating}/>)}
          {showComponent("Status zamówienia", getChangeStateButton())}
          {showComponent("Stan zamówienia", <OrdersProcessComponent order={sale} />)}
          {getKindPayment()}
          {getKindDelivery()}
          {getAddressDelivery()}
          {showComponent("Dodatkowe informacje", <Label wrap fontStyle="semibold" content={sale.description} />)}
        </div>
      </InfoBox>
    </div>
  );
};

export default SalesItemDetailsComponent;
