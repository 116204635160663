import { createAction, createReducer } from "@reduxjs/toolkit";
import { LandingState } from "../models/new/State";

const initialState = {
  who: "supplier",
  approval: false,
  email: "",
  error: {
    email: "",
    approval: "",
  },
} as LandingState;

export const changeLandingState = createAction<Partial<LandingState>>("landing/changeLandingState");

export const landingReducer = createReducer(initialState, (builder) => {
  builder.addCase(changeLandingState, (state, action) => {
    return { ...state, ...action.payload };
  });
});
