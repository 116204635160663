import React from "react";
import * as Icon from "commons/iconManager";
import PictureBoxComponent from "components/PictureBoxComponent";
import { SearchState } from "models/new/State";
import { useSelector } from "react-redux";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";

const SearchProductInfoPictureComponent: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pictureNumber, setPictureNumber] = React.useState<number>(0);
  const searchState: SearchState = useSelector((state: any) => state.search);

  React.useEffect(() => {
    if (searchState.supplierProduct?.pictures) setIsLoading(false);
  }, [searchState.supplierProduct?.pictures]);

  const changePicture = (value: number) => {
    const length = searchState.supplierProduct?.pictures?.length ?? 0;
    if (value > 0) {
      const num = (pictureNumber + 1) % length;
      setPictureNumber(num);
    } else {
      const num = (pictureNumber - 1 + length) % length;
      setPictureNumber(num);
    }
  };

  return (
    <div className="width-fill" style={{ flex: "1", position: "relative", userSelect: "none" }}>
      {isLoading ? (
        <WaitOnLoadingComponent
          isLoading={isLoading}
          count={1}
          className="width-fill rounded-12"
          style={{ minHeight: "300px", marginTop: "8px", marginRight: "4px" }}
        />
      ) : (
        <>
          {searchState.supplierProduct?.pictures && searchState.supplierProduct?.pictures?.length > 1 && (
            <Icon.ArrowBackSVG
              className="icon-back-absolute i-button i-button-small"
              onClick={() => changePicture(-1)}
            />
          )}
          <PictureBoxComponent
            className="image-on-card"
            url={searchState.supplierProduct?.pictures?.[pictureNumber]?.url}
          />
          {searchState.supplierProduct?.pictures && searchState.supplierProduct?.pictures?.length > 1 && (
            <Icon.ArrowForwardSVG
              className="icon-forward-absolute i-button i-button-small"
              onClick={() => changePicture(1)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SearchProductInfoPictureComponent;
