import React from "react";
import * as Icon from "commons/iconManager";
import { AccountProps } from "./AccountComponent";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { InputText } from "controls/InputText";
import { InputPassword } from "controls/InputPassword";
import * as UserApi from "api/UserApi";
import { InputCheckBox } from "controls/InputCheckBox";

const AccountSignUpComponent: React.FC<AccountProps> = (props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [acceptPolicy, setAcceptPolicy] = React.useState<boolean>(false);
  const [valideError, setValdeError] =
    React.useState<Partial<{ email: string; password: string; passwordRepeat: string; acceptPolicy: string }>>();
  const [valideErrors, setValdeErrors] = React.useState<any>();

  const refEmail = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const refPassword = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const refPasswordRepeat = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const onClick = async () => {
    setLoading(true);
    setValdeError({ password: "", email: "" });
    if (
      refEmail.current.value?.length === 0 ||
      refPassword.current.value?.length === 0 ||
      refPasswordRepeat.current.value?.length === 0
    ) {
      setValdeError({
        email: refEmail.current.value?.length === 0 ? "Wprowadź email" : "",
        password: refPassword.current.value?.length === 0 ? "Wprowadź hasło" : "",
        passwordRepeat: refPasswordRepeat.current.value?.length === 0 ? "Wprowadź hasło" : "",
      });
      setLoading(false);
      return;
    }

    if (!acceptPolicy) {
      setValdeError({
        acceptPolicy: "W celu korzystania z usługi musisz zaakceptować warunki korzystania i politykę prywatności",
      });
      setLoading(false);
      return;
    }

    if (refPassword.current.value !== refPasswordRepeat.current.value) {
      setValdeError({
        passwordRepeat: "Podane hasła róznią się!",
      });
      setLoading(false);
      return;
    }

    await UserApi.registrationUser({ email: refEmail.current.value, password: refPassword.current.value })
      .then((result) => {
        props.onCloseModalDialog?.("Approve");
        props.onClick("sign-up", undefined, refEmail.current.value);
      })
      .catch((err) => {
        setValdeErrors(err);
      })
      .finally(() => setLoading(false));
  };

  const showErrors = (): JSX.Element => {
    if (!valideErrors) return <></>;

    const errors: { key: string; value: any }[] = [];
    Object.entries(valideErrors).forEach(([key, value]) => {
      errors.push({ key, value: value });
    });

    return (
      <div className="d-flex fd-c">
        {errors.map((item) => {
          return <Label fontSize="sml" fontStyle="bold" wrap content={`${item.value?.[0]}`} fontColor="red" />;
        })}
      </div>
    );
  };

  return (
    <div className="frame-main">
      <div className="d-flex ai-stretch jc-center fd-c gap-16">
        <Icon.OgrosaSVG height={65} />
        <Label
          wrap
          fontStyle="bold"
          fontSize="sm"
          style={{ textAlign: "center" }}
          content={"Skorzystaj z poczty e-mail lub innej usługi, aby kontynuować korzystanie z Ogrosa"}
        />
        <div className="d-flex ai-stretch jc-center fd-c gap-16">
          <InputText
            ref={refEmail}
            frameClassName="width-fill"
            label="E-mail"
            placeholder="Wprowadź e-mail"
            kind="tertiary"
            autoComplete="email"
            sizeFrame="sm"
            autoFocus
            icon={<Icon.MailSVG />}
            isRequired
            error={valideError?.email}
          />
          <InputPassword
            ref={refPassword}
            frameClassName="width-fill"
            label="Hasło"
            placeholder="Wprowadź hasło"
            kind="tertiary"
            autoComplete="new-password"
            sizeFrame="sm"
            icon={<Icon.KeySVG />}
            isRequired
            error={valideError?.password}
          />
          <InputPassword
            ref={refPasswordRepeat}
            frameClassName="width-fill"
            label="Powtórz hasło"
            placeholder="Powtórz hasło"
            kind="tertiary"
            autoComplete="new-password"
            sizeFrame="sm"
            icon={<Icon.KeySVG />}
            isRequired
            error={valideError?.passwordRepeat}
            onSubmit={onClick}
          />
        </div>
        {showErrors()}
        <div className="d-flex fd-c gap-8">
          <div className="d-flex ai-center">
            <InputCheckBox
              sizeFrame="sml"
              kind="transparent"
              checked={acceptPolicy}
              onChange={(e) => setAcceptPolicy(e.currentTarget.checked)}
            />
            <Label
              wrap
              fontSize="sml"
              fontStyle="semibold"
              content="Akceptujesz warunki korzystania z usługi i politykę prywatości"
            />
          </div>
          <Label wrap fontSize="sml" fontStyle="semibold" fontColor="red" content={valideError?.acceptPolicy} />
          <div className="d-flex ai-s-flex">
            <InputCheckBox sizeFrame="sml" kind="transparent" />
            <Label
              wrap
              fontSize="sml"
              fontStyle="semibold"
              content="Wyrażam zgodę na otrzymywanie newslettera oraz informacji marketingowych od Ogrosa na podany adres e-mail zgodnie z Polityką Prywatności."
            />
          </div>
        </div>
        <Button
          style={{ width: "100%" }}
          sizeFrame="md"
          fontStyle="bold"
          fontSize="lg"
          kind="primary"
          label="Utwórz konto"
          isLoading={loading}
          onClick={onClick}
        />
        <Label
          wrap
          fontStyle="bold"
          fontSize="sml"
          content={"lub"}
          style={{ textAlign: "center", alignSelf: "center" }}
        />
        <div className="d-flex gap-8">
          <Button
            style={{ width: "100%" }}
            kind="tertiary"
            sizeFrame="sm"
            fontStyle="bold"
            label="Google"
            icon={<Icon.GoogleSVG />}
          />
          <Button
            style={{ width: "100%" }}
            kind="tertiary"
            sizeFrame="sm"
            fontStyle="bold"
            label="Facebook"
            icon={<Icon.FacebookSVG />}
          />
        </div>
        <Label
          wrap
          islink
          fontStyle="bold"
          fontColor="blue"
          fontSize="sm"
          content={"Posiadasz już konto? Zaloguj się"}
          style={{ textAlign: "center", alignSelf: "center" }}
          onClick={() => {
            props.setPageType("sign-in");
          }}
        />
      </div>
    </div>
  );
};

export default AccountSignUpComponent;
