import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";

const LandingHeaderComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  const goToJoin = () => {
    const cta = document.getElementById("cta-landing");
    cta && cta.scrollIntoView({ behavior: "smooth" });
  };

  if (isMobile) {
    return (
      <div
        className="d-flex ai-center mag-t-8"
        style={{ position: "relative", background: "transparent", marginBottom: "350px" }}
      >
        <img className="mag-8" width={'90%'} src="/img/desk_mobile.png" />
        <div
          className="d-flex fd-c gap-16 jc-center mag-16 pad-16 bg-secondary"
          style={{ position: "absolute", bottom: "-300px", borderRadius: "12px" }}
        >
          <Label
            fontSize="xl"
            fontStyle="extra-bold"
            wrap
            content={`Wybieraj lokalnie, 
jedz naturalnie!`}
          />
          <Label
            fontSize="lg"
            wrap
            fontStyle="semibold"
            content={`Ogrosa to portal łączący wielbicieli zdrowego, naturalnego jedzenia z lokalnymi rolnikami, sadownikami i hodowcami. 
Wejdź do świata, gdzie produkty są “BIO” nie tylko z nazwy!`}
        />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex gap-32" style={{ maxWidth: "1136px", marginBlock: '128px' }}>
      <div className="d-flex fd-c gap-16 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
        <Label
          fontSize="xxl"
          fontStyle="extra-bold"
          wrap
          content={`Wybieraj lokalnie, 
jedz naturalnie!`}
        /> 
        <Label
          fontSize="lg"
          wrap
          fontStyle="semibold"        
          content={`Ogrosa to portal łączący wielbicieli zdrowego, naturalnego jedzenia z lokalnymi rolnikami, sadownikami i hodowcami. 
Wejdź do świata, gdzie produkty są “BIO” nie tylko z nazwy!`}/>
      </div>
      <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
        <img  src="/img/desk_mobile.png" />
      </div>
    </div>
  );
};

export default LandingHeaderComponent;
