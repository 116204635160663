import React from "react";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionWizardState } from "models/new/State";
import { changeSubscriptionWizard, setSubscriptionValideError } from "reducers/subscriptionWizardReducer";
import { InputArea } from "controls/InputArea";
import { Picture } from "models/new/Picture";
import { useWindowSize } from "hooks/useWindowSize";
import { getFileOrFetch, processFile } from "commons/fileManager";
import PictureManagerComponent from "components/PictureManagerComponent";
import PictureProfileComponent from "components/PictureProfileComponent";
import InfoBox from "controls/InfoBox";

const SubscriptionWizardDescAndPicturesComponent: React.FC = () => {
  const [file, setFile] = React.useState<File>();
  const [files, setFiles] = React.useState<File[]>([]);
  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const wizardState = React.useRef<SubscriptionWizardState>(subscriptionWizardState);

  React.useEffect(() => {
    wizardState.current = subscriptionWizardState;
  }, [subscriptionWizardState]);

  React.useEffect(() => {
    return () => {
      !isMobile &&
        dispatch(
          setSubscriptionValideError({
            logoUrl: wizardState.current.logoUrl
              ? undefined
              : subscriptionWizardState.valideError?.logoUrl ?? "Logo nie może być puste. Wybierz zdjęcie na logo.",
            pictures:
              wizardState.current.pictures?.length === 0
                ? undefined
                : subscriptionWizardState.valideError?.pictures ?? "Brak zdjęć gospodarstwa. Wymagane jedno zdjęcie.",
            description:
              wizardState.current.description && wizardState.current.description?.length > 0
                ? undefined
                : subscriptionWizardState.valideError?.description ?? "Opis nie może być pusty",
          })
        );
    };
  }, []);

  React.useEffect(() => {
    if (!subscriptionWizardState?.pictures) return;

    getFiles().then((value) => {
      setFiles(value);
    });

    getFile().then((value) => {
      setFile(value);
    });
  }, [subscriptionWizardState?.pictures]);

  const onChangeProfil = async (file?: File) => {
    if (!file) return;
    let picture = processFile(file, subscriptionWizardState.logoUrl ? [subscriptionWizardState.logoUrl] : []);
    dispatch(
      changeSubscriptionWizard({
        logoUrl: picture,
        valideError: { ...subscriptionWizardState.valideError, logoUrl: "" },
      })
    );
  };
  const getFile = async (): Promise<File | undefined> => {
    if (!subscriptionWizardState?.logoUrl) return undefined;
    return getFileOrFetch(subscriptionWizardState.logoUrl);
  };

  const onChange = async (files?: File[]) => {
    const newPictures: Picture[] = [];
    files?.forEach((file) => {
      const picture = processFile(file, subscriptionWizardState.pictures);
      newPictures.push(picture);
    });

    dispatch(
      changeSubscriptionWizard({
        pictures: newPictures,
        valideError: { ...subscriptionWizardState.valideError, pictures: "" },
      })
    );
  };
  const getFiles = async (): Promise<File[]> => {
    if (!subscriptionWizardState?.pictures) return [];

    const promises = subscriptionWizardState.pictures.map((img) => getFileOrFetch(img));
    const results = await Promise.all(promises);

    return results.filter((file): file is File => file !== undefined);
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-32 pad-8 pad-b-32 width-fill border-bottom">
        <div className="d-flex fd-c gap-32 pad-b-16">
          <Label fontStyle="bold" fontSize="lg" content="Zdjęcia i opis gospodarstwa" />
          {subscriptionWizardState.valideError?.logoUrl && (
            <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="bold"
                fontColor="black"
                content={subscriptionWizardState.valideError?.logoUrl}
              />
            </InfoBox>
          )}
          <PictureProfileComponent
            file={file}
            onChange={onChangeProfil}
            required
            title="Logo"
            subTitle="Dodaj swój logotyp gospodarstwa"
          />
          {subscriptionWizardState.valideError?.pictures && (
            <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="bold"
                fontColor="black"
                content={subscriptionWizardState.valideError?.pictures}
              />
            </InfoBox>
          )}
          <PictureManagerComponent files={files} onChange={onChange} title="Zdjęcia gospodarstwa" />
          <InputArea
            style={{ height: "200px" }}
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Opis gospodarstwa"
            minLength={80}
            maxLength={500}
            placeholder="Opis musi zawierać od 80 do 500 znaków"
            isRequired
            error={subscriptionWizardState.valideError?.description}
            value={subscriptionWizardState.description}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  description: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, description: "" },
                })
              )
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="frame-scroll gap-32">
      <div className="d-flex fd-c gap-32 width-fill" style={{ maxWidth: "600px" }}>
        <div className="d-flex fd-c gap-16 pad-b-16">
          <Label fontStyle="bold" fontSize="md" content="Zdjęcia i opis gospodarstwa" />
          {subscriptionWizardState.valideError?.logoUrl && (
            <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
              <Label
                wrap
                pointer
                fontStyle="semibold"
                fontColor="black"
                content={subscriptionWizardState.valideError?.logoUrl}
              />
            </InfoBox>
          )}
          <PictureProfileComponent
            file={file}
            onChange={onChangeProfil}
            required
            title="Logo"
            subTitle="Dodaj swój logotyp gospodarstwa"
          />
          {subscriptionWizardState.valideError?.pictures && (
            <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
              <Label
                wrap
                pointer
                fontStyle="semibold"
                fontColor="black"
                content={subscriptionWizardState.valideError?.pictures}
              />
            </InfoBox>
          )}
          <PictureManagerComponent files={files} onChange={onChange} title="Zdjęcia gospodarstwa" />
          <InputArea
            style={{ height: "200px" }}
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Opis gospodarstwa"
            minLength={80}
            maxLength={500}
            placeholder="Opis musi zawierać od 80 do 500 znaków"
            isRequired
            error={subscriptionWizardState.valideError?.description}
            value={subscriptionWizardState.description}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  description: e.currentTarget.value,
                  valideError: { ...subscriptionWizardState.valideError, description: "" },
                })
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionWizardDescAndPicturesComponent;
