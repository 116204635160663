import React from "react";
import { Label } from "controls/Label";
import { useSelector } from "react-redux";
import { SubscriptionWizardState } from "models/new/State";
import { InputText } from "controls/InputText";
import { useWindowSize } from "hooks/useWindowSize";

const SubscriptionWizardSettlementsComponent: React.FC = () => {
  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-32 pad-8 pad-b-32 width-fill border-bottom">
        <div className="d-flex fd-c gap-16 width-fill">
          <Label fontStyle="bold" fontSize="lg" content="Dane do rozliczeń" />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer konta bankowego"
            placeholder="xx xxxx xxxx xxxx xxxx xxxx xxxx"
            value={subscriptionWizardState.accountNumber}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="frame-scroll gap-32">
      <div className="d-flex fd-c gap-32 width-fill" style={{ maxWidth: "600px" }}>
        <div className="d-flex fd-c gap-16 pad-b-16">
          <Label fontStyle="bold" fontSize="md" content="Dane do rozliczeń" />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer konta bankowego"
            placeholder="xx xxxx xxxx xxxx xxxx xxxx xxxx"
            value={subscriptionWizardState.accountNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionWizardSettlementsComponent;
