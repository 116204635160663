import React from "react";
import * as Icon from "commons/iconManager";
import RatingStarComponent from "components/RatingStarComponent";
import { Label } from "controls/Label";
import { useNavigation } from "hooks/useNavigation";
import PriceComponent from "components/PriceComponent";
import InfoBox from "controls/InfoBox";
import PictureBoxComponent from "components/PictureBoxComponent";
import { Supplier } from "models/new/Supplier";
import { Product } from "models/new/Product";
import DealerCardComponent from "components/DealerCardComponent";

interface SearchProductProps {
  supplier?: Supplier;
}

const ShowDealerOnCardComponent: React.FC<SearchProductProps> = (props) => {
  const { navigateTo } = useNavigation();
  const [position, setPosition] = React.useState<number>(0);
  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const positionRef = React.useRef<number>(0);

  let isScrolling = false;

  const simpleProductBox = (product: Product): JSX.Element => {
    return (
      <InfoBox id={product.id} className="width-fill border hover-box" backgroundColor="transparent" sizeFrame="sml">
        <div className="d-flex fd-c gap-4 c-pointer" onClick={() => navigateTo("search", `?product_id=${product.id}`)}>
          <PictureBoxComponent className="image-on-other-product-list" url={product.photoUrl} />
          <Label fontSize="sm" fontStyle="semibold" pointer content={product.name} />
          <RatingStarComponent rating={product.rating} ratingQty={product.ratingQty} />
          <PriceComponent product={product} />
        </div>
      </InfoBox>
    );
  };

  const setScrollPosition = (value: number) => {
    if (!props.supplier?.products) return;

    const exist = props.supplier?.products[value + positionRef.current];
    if (exist) {
      positionRef.current = positionRef.current + value;
      setPosition(positionRef.current);
      const itemWidth = scrollRef.current.scrollWidth / props.supplier.products.length;
      scrollRef.current.scrollTo({
        top: 0,
        left: itemWidth * positionRef.current,
        behavior: "smooth",
      });
    }
  };

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!props.supplier?.products || isScrolling) return;

    if (e.currentTarget.scrollLeft === 0) {
      setPosition(0);
    } else {
      const itemWidth = e.currentTarget.scrollWidth / props.supplier.products.length;
      const pos = Math.ceil(e.currentTarget.scrollLeft / itemWidth);
      positionRef.current = pos;
      setPosition(pos);
    }
  };

  return (
    <div className="d-flex fd-c gap-16">
      <DealerCardComponent supplier={props.supplier} showIcon />
      <Label
        fontSize="lg"
        fontStyle="semibold"
        content={`Inne produkty sprzedawcy (${props.supplier?.products?.length})`}
      />
      <div style={{ position: "relative" }}>
        {position !== 0 && (
          <div className="i-arrow-back">
            <Icon.ArrowBackSVG className="i-button i-button-xsmall" onClick={() => setScrollPosition(-1)} />
          </div>
        )}
        <div className="i-arrow-next">
          <Icon.ArrowForwardSVG className="i-button i-button-xsmall" onClick={() => setScrollPosition(1)} />
        </div>
        <div
          ref={scrollRef}
          className="frame-scroll-horizontal gap-4"
          style={{ position: "relative" }}
          onScroll={onScroll}
        >
          {props.supplier?.products?.map((product) => {
            return simpleProductBox(product);
          })}
        </div>
      </div>
    </div>
  );
};

export default ShowDealerOnCardComponent;
