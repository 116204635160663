import React from "react";
import { Button } from "controls/Button";
import { useWindowSize } from "hooks/useWindowSize";
import { LandingState } from "models/new/State";
import { useDispatch, useSelector } from "react-redux";
import { changeLandingState } from "reducers/landingReducer";

const LandingOptionsComponent: React.FC = () => {
  const landingState: LandingState = useSelector((state: any) => state.landing);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  if (isMobile) {
    return (
      <div className="d-flex fd-c width-fill pad-8 bg-secondary" style={{ borderRadius: "16px" }}>
        <div
          className="d-flex fd-c width-fill jc-center gap-16 pad-16"
          onClick={() => dispatch(changeLandingState({ who: "supplier" }))}
        >
          <img className={`p-farmer ${landingState.who !== "supplier" && "p-farmer-gray"}`} src="/img/supplier.jpg" />
          <Button
            kind={landingState.who === "supplier" ? "primary" : "secondary"}
            className="width-fill"
            sizeFrame="md"
            fontStyle="bold"
            fontSize="md"
            label="Chce sprzedawać"
          />
        </div>
        <div
          className="d-flex fd-c width-fill jc-center gap-16 pad-16"
          onClick={() => dispatch(changeLandingState({ who: "client" }))}
        >
          <img className={`p-farmer ${landingState.who === "supplier" && "p-farmer-gray"}`} src="/img/client_order2.jpg" />
          <Button
            kind={landingState.who === "client" ? "primary" : "secondary"}
            className="width-fill"
            sizeFrame="md"
            fontStyle="bold"
            fontSize="md"
            label="Chce kupować"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex width-fill bg-secondary" style={{ maxWidth: "1136px", borderRadius: "16px" }}>
      <div
        className="d-flex fd-c width-fill jc-center gap-16 pad-16"
        onClick={() => dispatch(changeLandingState({ who: "supplier" }))}
      >
        <img className={`p-farmer ${landingState.who !== "supplier" && "p-farmer-gray"}`} src="/img/supplier.jpg" />
        <Button
          kind={landingState.who === "supplier" ? "primary" : "secondary"}
          className="width-fill"
          sizeFrame="md"
          fontStyle="bold"
          fontSize="md"
          label="Chce sprzedawać"
        />
      </div>
      <div
        className="d-flex fd-c width-fill jc-center gap-16 pad-16"
        onClick={() => dispatch(changeLandingState({ who: "client" }))}
      >
        <img className={`p-farmer ${landingState.who === "supplier" && "p-farmer-gray"}`} src="/img/client_order2.jpg" />
        <Button
          kind={landingState.who === "client" ? "primary" : "secondary"}
          className="width-fill"
          sizeFrame="md"
          fontStyle="bold"
          fontSize="md"
          label="Chce kupować"
        />
      </div>
    </div>
  );
};

export default LandingOptionsComponent;
