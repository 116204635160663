import React from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { ProfileTabProps } from "./ProfileManagerComponent";
import InfoBox from "controls/InfoBox";
import { Button } from "controls/Button";
import { ModalDialog } from "controls/ModalDialog";
import PictureManagerComponent from "components/PictureManagerComponent";
import { Tag } from "controls/Tag";
import ProfileSelectAddressComponent from "./ProfileSelectAddressComponent";
import { changeSupplier } from "reducers/userReducer";
import { Address } from "models/new/Address";
import PictureProfileComponent from "components/PictureProfileComponent";
import OpenHourComponent from "components/OpenHourComponent";
import AddressComponent from "components/AddressComponent";
import { Supplier } from "models/new/Supplier";
import { getFileOrFetch, processFile } from "commons/fileManager";
import { Picture } from "models/new/Picture";
import { InputArea } from "controls/InputArea";
import { useWindowSize } from "hooks/useWindowSize";
import { generateGUID } from "commons/stringManager";

const ProfileDataDealerComponent: React.FC<ProfileTabProps> = (props) => {
  const [file, setFile] = React.useState<File>();
  const [files, setFiles] = React.useState<File[]>();
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    getFiles().then((value) => {
      setFiles(value);
    });

    getFile().then((value) => {
      setFile(value);
    });
  }, [userState.supplier?.pictures]);

  const onChangeLogo = async (file?: File) => {
    if (!file) {
      dispatch(
        changeSupplier({ supplier: { logoFile: undefined, logoUrl: undefined }, validError: { logoUrl: undefined } })
      );
      return;
    }
    let picture = processFile(file, userState.supplier?.logoFile ? [userState.supplier?.logoFile] : []);
    dispatch(
      changeSupplier({ supplier: { logoFile: picture, logoUrl: undefined }, validError: { logoUrl: undefined } })
    );
  };
  const getFile = async (): Promise<File | undefined> => {
    if (!userState.supplier?.logoUrl) return undefined;
    return getFileOrFetch(userState.supplier.logoUrl);
  };

  const onChange = async (files?: File[]) => {
    if (!files) {
      dispatch(changeSupplier({ supplier: { pictures: undefined }, validError: { pictures: undefined } }));
      return;
    }

    const newPictures: Picture[] = [];
    files?.forEach((file) => {
      const picture = processFile(file, userState.supplier?.pictures);
      newPictures.push(picture);
    });
    dispatch(changeSupplier({ supplier: { pictures: newPictures }, validError: { pictures: undefined } }));
  };
  const getFiles = async (): Promise<File[]> => {
    if (!userState.supplier?.pictures) return [];

    const promises = userState.supplier.pictures.map((img) => getFileOrFetch(img));
    const results = await Promise.all(promises);

    return results.filter((file): file is File => file !== undefined);
  };

  const showMap = async () => {
    let user = structuredClone(userState.supplier) as Supplier;
    const address = user.addresses?.find((x) => x.isMainAddress);
    const result = await ModalDialog.show(<ProfileSelectAddressComponent address={address} />, "Wskaż adres");
    if (result.result === "Approve") {
      const adres = result.value as Address;
      adres.isMainAddress = true;
      if (!user?.addresses) {
        user = { ...user, addresses: [] };
      }
      adres.openHours = address?.openHours;
      adres.id = generateGUID();
      user.addresses = user.addresses?.filter((x) => !x.isMainAddress);
      user?.addresses?.push(adres);
      dispatch(changeSupplier({ supplier: user }));
    }
  };
  const deleteAddress = (id: string | number) => {
    let user = structuredClone(userState.supplier) as Supplier;
    user.addresses = user.addresses?.filter((x) => x.id !== id);
    dispatch(changeSupplier({ supplier: user }));
  };

  const getMainAddress = () => {
    const find = userState.supplier?.addresses?.find((x) => x.isMainAddress);
    if (find) {
      return (
        <InfoBox className="width-fill border" backgroundColor="transparent" sizeFrame="sm">
          <div className="width-fill d-flex fd-r gap-8 ai-s-flex">
            <Icon.HomePinSVG className="icon-color" height={50} width={50} />
            <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
              <div className="d-flex gap-4">
                <Label wrap fontSize="md" fontStyle="bold" content="Lokalizacja twojego gospodarstwa" />
                <Label wrap fontSize="md" fontStyle="bold" fontColor="red" content="*" />
              </div>
              <AddressComponent address={find} />
              <div className="width-fill d-flex fd-r gap-8 ai-center jc-e-flex">
                <Button
                  sizeFrame="sml"
                  kind="tertiary"
                  label="Usuń"
                  toolTip="Usuń adres"
                  icon={<Icon.DeleteSVG />}
                  onClick={() => deleteAddress(find.id)}
                />
                <Button
                  sizeFrame="sml"
                  kind="tertiary"
                  label="Edytuj"
                  toolTip="Edytuj adres"
                  icon={<Icon.EditSVG />}
                  onClick={showMap}
                />
              </div>
              <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
                {find.openHours === undefined && (
                        <InfoBox
                          className="width-fill ai-center"
                          backgroundColor="red"
                          sizeFrame="xsm"
                          icon="Error"
                          iconSize="xsm"
                        >
                          <Label
                            wrap
                            pointer
                            fontSize="sm"
                            fontStyle="bold"
                            fontColor="black"
                            content="Uzupełnij godziny otwarcia w innym przypadku klienci nie będą wstanie złożyć zamówienia pod ten adres"
                          />
                        </InfoBox>
                      )}
                <Label wrap fontSize="md" fontStyle="bold" content="Godziny otwarcia" />
                <OpenHourComponent address={find} canEdit />
              </div>
            </div>
          </div>
        </InfoBox>
      );
    } else {
      return (
        <InfoBox className="width-fill border" backgroundColor="transparent" sizeFrame="sm">
          <div className="width-fill d-flex fd-r gap-8 ai-s-flex">
            <Icon.HomePinSVG className="icon-color" height={50} width={50} />
            <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
              <Tag sizeFrame="sml" kind="red" fontStyle="semibold" label="Wymagane" icon={<Icon.ErrorSVG />} />
              <Label wrap fontSize="md" fontStyle="bold" content="Dodaj lokalizacje gospodarstwa" />
              <Label
                wrap
                fontSize="sm"
                fontStyle="semibold"
                content="Udostępnianie lokalizacji pomaga skutecznie sprzedawać produkty lokalnym konsumentom i gastronomi."
              />
              <Button
                style={{ marginLeft: "auto" }}
                kind="primary"
                sizeFrame="sml"
                label="Wybierz"
                fontStyle="bold"
                icon={<Icon.LocationOnSVG />}
                onClick={() => showMap()}
              />
            </div>
          </div>
        </InfoBox>
      );
    }
  };

  if (isMobile) {
    return (
      <div id={props.id} className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill">
        <Label fontStyle="bold" fontSize="md" content="Dane sprzedawcy" />
        {userState.valideError?.logoUrl && (
          <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
            <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.logoUrl} />
          </InfoBox>
        )}
        <PictureProfileComponent
          file={file}
          onChange={onChangeLogo}
          title="Logo"
          subTitle="Dodaj swój logotyp gospodarstwa"
          required
        />
        {userState.valideError?.pictures && (
          <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
            <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.pictures} />
          </InfoBox>
        )}
        <PictureManagerComponent files={files} onChange={onChange} title="Zdjęcia gospodarstwa" />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Nazwa gospodarstwa"
          isRequired
          placeholder="np. Warzywny zakątek"
          error={userState.valideError?.companyName}
          value={userState.supplier?.companyName}
          onChange={(e) =>
            dispatch(
              changeSupplier({ supplier: { companyName: e.target.value }, validError: { companyName: undefined } })
            )
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Numer ARIMR"
          placeholder="np. Podaj numer ARIMR"
          value={userState.supplier?.arimr}
          onChange={(e) => dispatch(changeSupplier({ supplier: { arimr: e.target.value } }))}
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Numer RHD"
          placeholder="np. Podaj numer RHD"
          value={userState.supplier?.rhd}
          onChange={(e) => dispatch(changeSupplier({ supplier: { rhd: e.target.value } }))}
        />
        <InputArea
          style={{ height: "200px" }}
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Opis gospodarstwa"
          minLength={80}
          maxLength={500}
          isRequired
          placeholder="Opis musi zawierać od 80 do 500 znaków"
          error={userState.valideError?.description}
          value={userState.supplier?.description}
          onChange={(e) =>
            dispatch(
              changeSupplier({ supplier: { description: e.target.value }, validError: { description: undefined } })
            )
          }
        />
        <InfoBox className="width-fill" icon="Information" fontStyle="semibold" fontSize="sm" iconSize="sm">
          <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
            <Label wrap fontSize="md" fontStyle="bold" fontColor="black" content="Udostępnij swoją lokalizacje" />
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              fontColor="black"
              content="Udostępnianie lokalizacji gospodarstwa jest wymagane w celu sprzedaży produktów. "
            />
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              fontColor="black"
              content="Lokalizacja ułatwia klientom znalezienie Cię, potencjalnie zwiększając bazę klientów i sprzedaż."
            />
          </div>
        </InfoBox>
        {userState.valideError?.addresses && (
          <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
            <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.addresses} />
          </InfoBox>
        )}
        {getMainAddress()}
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Dane sprzedawcy" />
      {userState.valideError?.logoUrl && (
        <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
          <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.logoUrl} />
        </InfoBox>
      )}
      <PictureProfileComponent
        file={file}
        onChange={onChangeLogo}
        title="Logo"
        subTitle="Dodaj swój logotyp gospodarstwa"
        required
      />
      {userState.valideError?.pictures && (
        <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
          <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.pictures} />
        </InfoBox>
      )}
      <PictureManagerComponent files={files} onChange={onChange} title="Zdjęcia gospodarstwa" />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Nazwa gospodarstwa"
        isRequired
        placeholder="np. Warzywny zakątek"
        error={userState.valideError?.companyName}
        value={userState.supplier?.companyName}
        onChange={(e) =>
          dispatch(
            changeSupplier({ supplier: { companyName: e.target.value }, validError: { companyName: undefined } })
          )
        }
      />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Numer ARIMR"
        placeholder="np. Podaj numer ARIMR"
        value={userState.supplier?.arimr}
        onChange={(e) => dispatch(changeSupplier({ supplier: { arimr: e.target.value } }))}
      />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Numer RHD"
        placeholder="np. Podaj numer RHD"
        value={userState.supplier?.rhd}
        onChange={(e) => dispatch(changeSupplier({ supplier: { rhd: e.target.value } }))}
      />
      <InputArea
        style={{ height: "200px" }}
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Opis gospodarstwa"
        isRequired
        minLength={80}
        maxLength={500}
        placeholder="Opis musi zawierać od 80 do 500 znaków"
        error={userState.valideError?.description}
        value={userState.supplier?.description}
        onChange={(e) =>
          dispatch(
            changeSupplier({ supplier: { description: e.target.value }, validError: { description: undefined } })
          )
        }
      />
      <InfoBox className="width-fill" icon="Information" fontStyle="semibold" fontSize="sm" iconSize="sm">
        <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
          <Label wrap fontSize="md" fontStyle="bold" fontColor="black" content="Udostępnij swoją lokalizacje" />
          <Label
            wrap
            fontSize="sm"
            fontStyle="semibold"
            fontColor="black"
            content="Udostępnianie lokalizacji gospodarstwa jest wymagane w celu sprzedaży produktów. "
          />
          <Label
            wrap
            fontSize="sm"
            fontStyle="semibold"
            fontColor="black"
            content="Lokalizacja ułatwia klientom znalezienie Cię, potencjalnie zwiększając bazę klientów i sprzedaż."
          />
        </div>
      </InfoBox>
      {userState.valideError?.addresses && (
        <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
          <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.addresses} />
        </InfoBox>
      )}
      {getMainAddress()}
    </div>
  );
};

export default ProfileDataDealerComponent;
