import React from "react";
import { Label, StandardLabelProps } from "./Label";
import { SizeFrame } from "models/new/Dictionaries";
import { InputTextProps, getKind, getSizeForIcon } from "./InputText";

export interface InputCheckBoxProps
  extends StandardLabelProps,
    InputTextProps,
    React.InputHTMLAttributes<HTMLInputElement> {
  checkboxLabel?: string;
}

export const InputCheckBox = React.forwardRef<HTMLInputElement, InputCheckBoxProps>((props, ref) => {
  const [focused, setFocused] = React.useState<boolean>(false);
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useImperativeHandle(ref, () => inputRef.current);

  const getSize = (size?: SizeFrame): string => {
    switch (size) {
      case "sml":
        return " checkbox-h32";
      case "sm":
        return " checkbox-h40";
      case "md":
        return " checkbox-h48";
      case "lg":
        return " checkbox-h56";
      case "xl":
        return " checkbox-h64";
      default:
        return " ";
    }
  };
  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);

    if (focused) {
      style += " input-focused";
    }
    if (props.error) {
      style += " mag-b-16";
    }
    if (props.label) {
      style += " mag-t-16";
    }
    if (props.error) {
      style += " input-error";
    }

    return `${style} ${props.frameClassName}`;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }
  const onFocus = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onFocus?.(evt);
    setFocused(true);
  };
  const onBlur = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onBlur?.(evt);
    setFocused(false);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(e);
  };
  const onLabelClicked = () => {
    if (props.disabled) return;
    inputRef.current.checked = !inputRef.current.checked;
    const event = { target: inputRef.current } as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

  return (
    <div ref={props.frameRef} className={getStyle()} style={{ ...props.frameStyle, justifyContent: "flex-start" }}>
      {props.iconPosition !== "right" && clonedIcon}
      <div className="title d-flex gap-4 ai-center">
        <Label content={props.label} fontSize="sml" fontStyle="semibold" />
        {props.isRequired && <Label content="*" fontColor="red" fontSize="sml" fontStyle="bold" />}
      </div>
      <input
        {...props}
        style={{ width: "24px" }}
        ref={inputRef}
        type="checkbox"
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => onChange(e)}
      />
      <Label
        style={{ width: "100%", userSelect: "none", whiteSpace: "break-spaces" }}
        wrap
        fontStyle={props.fontStyle}
        fontColor={props.fontColor}
        fontSize={props.fontSize}
        content={props.checkboxLabel}
        onClick={onLabelClicked}
      />
      {props.iconPosition === "right" && clonedIcon}
      <label className="error">{props.error}</label>
    </div>
  );
});
