import React from "react";
import * as Icon from "commons/iconManager";
import CertificateManagerComponent from "components/CertificateManagerComponent";
import CertificateComponent from "components/CertificateComponent";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionWizardState } from "models/new/State";
import { Certificate } from "models/new/Certificate";
import { ModalDialog } from "controls/ModalDialog";
import { changeSubscriptionWizard, setSubscriptionValideError } from "reducers/subscriptionWizardReducer";
import { useWindowSize } from "hooks/useWindowSize";

const SubscriptionWizardCertificatesComponent: React.FC = () => {
  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(setSubscriptionValideError({ certificate: "" }));
    };
  }, []);

  const onEdit = async (certificate: Certificate) => {
    const result = await ModalDialog.show(
      <CertificateManagerComponent certificate={certificate} supplierId={subscriptionWizardState.supplierId} />,
      "Edytuj certyfikat"
    );
    if (result.result === "Approve") {
      const certificates = structuredClone(subscriptionWizardState?.certificates)?.map((x) =>
        x.id === certificate.id ? result.value : x
      );
      if (certificates && certificates.length > 0) {
        dispatch(changeSubscriptionWizard({ certificates: certificates }));
      }
    }
  };
  const onDelete = (coupon: Certificate) => {
    const certificates = structuredClone(subscriptionWizardState?.certificates)?.filter((x) => x.id !== coupon.id);
    dispatch(changeSubscriptionWizard({ certificates: certificates }));
  };
  const onAdd = async () => {
    const result = await ModalDialog.show(
      <CertificateManagerComponent supplierId={subscriptionWizardState.supplierId} />,
      "Dodaj certyfikat"
    );
    if (result.result === "Approve") {
      const certificate: Certificate = { ...result.value };
      let certificates = structuredClone(subscriptionWizardState?.certificates);
      if (!certificates) {
        certificates = [];
      }

      certificates.push(certificate);
      dispatch(changeSubscriptionWizard({ certificates: certificates }));
    }
  };

  const getInfo = (): JSX.Element => {
    return (
      <div className="frame-main" style={{ width: "450px", height: "75vh" }}>
        <div className="frame-scroll gap-16">
          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Zapewnienie jakości" />
            <Label
              wrap
              content={`Certyfikaty produktu potwierdzają, że produkt został przetestowany i spełnia określone standardy jakości. Dla konsumentów jest to gwarancja, że produkt jest wysokiej jakości i będzie działał zgodnie z oczekiwaniami.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Bezpieczeństwo" />
            <Label
              wrap
              content={`Wiele certyfikatów koncentruje się na aspektach bezpieczeństwa produktu. To szczególnie ważne w przypadku artykułów spożywczych, zabawek, elektroniki i innych produktów, które mogą bezpośrednio wpływać na zdrowie i bezpieczeństwo użytkowników.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Zgodność z regulacjami" />
            <Label
              wrap
              content={`Certyfikaty mogą świadczyć o tym, że produkt jest zgodny z lokalnymi, krajowymi lub międzynarodowymi przepisami i standardami. Dla producentów i dystrybutorów jest to kluczowe, aby móc wprowadzać produkty na różne rynki.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Budowanie zaufania" />
            <Label
              wrap
              content={`Certyfikaty produktu budują zaufanie między producentami a konsumentami. Klienci, widząc znane i szanowane certyfikaty, mogą czuć się pewniej, dokonując zakupu, wiedząc, że produkt jest bezpieczny, etyczny i wysokiej jakości.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Przewaga konkurencyjna" />
            <Label
              wrap
              content={`Produkty posiadające certyfikaty mogą wyróżniać się na rynku, oferując producentom przewagę konkurencyjną. Certyfikowane produkty mogą być postrzegane jako lepsze lub bardziej pożądane niż te bez certyfikatów, co może przekładać się na wyższe ceny i lepszą sprzedaż.`}
            />
          </div>
        </div>
      </div>
    );
  };

  const showInfo = async () => {
    await ModalDialog.show(getInfo(), "Cretyfikaty");
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-32 pad-8 pad-b-32 width-fill border-bottom">
        <div className="d-flex fd-c gap-8">
          <Label fontStyle="bold" fontSize="lg" content="Twoje certyfikaty" />
          <Label
            fontStyle="semibold"
            fontSize="sm"
            fontColor="secondary"
            wrap
            content="Certyfikaty produktu potwierdzają, że produkt został przetestowany i spełnia określone standardy jakości. "
          />
        </div>
        {subscriptionWizardState?.certificates?.map((certificate) => {
          return <CertificateComponent certificate={certificate} onEdit={onEdit} onDelete={onDelete} />;
        })}
        <Button
          sizeFrame="sm"
          kind="primary"
          label="Dodaj certyfikat"
          fontStyle="bold"
          icon={<Icon.AddSVG />}
          onClick={onAdd}
        />
      </div>
    );
  }

  return (
    <div className="frame-scroll d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 width-fill" style={{ maxWidth: "500px" }}>
      <div className="d-flex fd-c gap-8">
        <Label fontStyle="bold" content="Twoje certyfikaty" />
        <Label
          fontStyle="semibold"
          fontSize="sm"
          fontColor="secondary"
          wrap
          content="Certyfikaty produktu potwierdzają, że produkt został przetestowany i spełnia określone standardy jakości. "
        />
      </div>
      {subscriptionWizardState?.certificates?.map((certificate) => {
        return <CertificateComponent certificate={certificate} onEdit={onEdit} onDelete={onDelete} />;
      })}
      <Button
        sizeFrame="sm"
        kind="primary"
        label="Dodaj certyfikat"
        fontStyle="bold"
        icon={<Icon.AddSVG />}
        onClick={onAdd}
      />
    </div>
  );
};

export default SubscriptionWizardCertificatesComponent;
