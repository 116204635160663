import React from "react";
import { SearchState } from "models/new/State";
import { useSelector } from "react-redux";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import RatingStarComponent from "components/RatingStarComponent";

const SearchProductInfoNameComponent: React.FC = () => {
  const searchState: SearchState = useSelector((state: any) => state.search);

  return (
    <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
      <div className="d-flex fd-c gap-8">
        <Label fontSize="lg" fontStyle="semibold" content={searchState.supplierProduct?.name} />
        <Label
          fontSize="sm"
          fontStyle="semibold"
          fontColor="secondary"
          content={searchState.supplierProduct?.companyName}
        />
        <RatingStarComponent
          ratingQty={searchState.supplierProduct?.ratingQty}
          rating={searchState.supplierProduct?.rating}
        />
      </div>
    </InfoBox>
  );
};

export default SearchProductInfoNameComponent;
