import { useState, useEffect } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const resizeListener = () => {
      setTimeout(() => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        setIsMobile( window.innerWidth < 768);
      }, 0);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return { windowSize, isMobile };
};
