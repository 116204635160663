import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "./Button";
import { InputFrameProps, getFontFamily, getKind, getSizeForIcon } from "./InputText";
import { FontStyle, IconPositionType, KindType, SizeFrame } from "models/new/Dictionaries";
import { Label } from "./Label";

export interface InputAreaProps extends InputFrameProps, React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  id?: string;
  icon?: React.ReactElement;
  iconPosition?: IconPositionType;
  sizeFrame?: SizeFrame;
  kind?: KindType;
  family?: FontStyle;
  count?: number;
  error?: string;
  canClear?: boolean;
  frameStyle?: React.CSSProperties;
  resize?: boolean;
  heightAuto?: boolean;
  isRequired?: boolean;
}

export const InputArea = React.forwardRef<HTMLTextAreaElement, InputAreaProps>((props, ref) => {
  const [focused, setFocused] = React.useState<boolean>(false);
  const [lenght, setLenght] = React.useState<number>(0);
  const inputRef = React.useRef() as React.MutableRefObject<HTMLTextAreaElement>;
  React.useImperativeHandle(ref, () => inputRef.current);

  const getSize = (size?: SizeFrame): string => {
    switch (size) {
      case "sml":
        return " textarea-h32";
      case "sm":
        return " textarea-h40";
      case "md":
        return " textarea-h48";
      case "lg":
        return " textarea-h56";
      case "xl":
        return " textarea-h64";
      default:
        return " ";
    }
  };
  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += getFontFamily(props.family);

    if (focused) {
      style += " input-focused";
    }
    if (props.error) {
      style += " mag-b-16";
    }
    if (props.label) {
      style += " mag-t-16";
    }
    if (props.error) {
      style += " input-error";
    }

    style += " ai-s-flex";
    return `${style} ${props.frameClassName}`;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }
  const onFocus = (evt?: React.FocusEvent<HTMLTextAreaElement>) => {
    evt?.preventDefault();
    evt && props.onFocus?.(evt);
    setFocused(true);
  };
  const onBlur = (evt?: React.FocusEvent<HTMLTextAreaElement>) => {
    evt && props.onBlur?.(evt);
    setFocused(false);
  };
  const onClear = () => {
    inputRef.current.value = "";
    const simulatedEvent = {
      target: { value: "" },
      currentTarget: { value: "" },
    };
    props.onChange?.(simulatedEvent as React.ChangeEvent<HTMLTextAreaElement>);
    props.onSubmit?.(simulatedEvent as React.ChangeEvent<HTMLTextAreaElement>);
    inputRef.current.focus();
  };  

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.heightAuto) {
      const lines = e.target.value.split("\n").length;
      if (inputRef.current.clientHeight < 100) {
        const baseWidth = 25; // Base width in pixels
        const widthPerLine = 20; // Additional width per line in pixels

        // Adjust the width of the textarea
        inputRef.current.style.height = `${baseWidth + lines * widthPerLine}px`;
      }

      if (lines < 2) {
        inputRef.current.style.height = `45px`;
      }
    }
    setLenght(e.target.value.length);
    props.onChange?.(e);
  };

  return (
    <div ref={props.frameRef} className={getStyle()} style={{ ...props.frameStyle }}>
      {props.iconPosition !== "right" && clonedIcon}
      <div className="title d-flex gap-4 ai-center">
        <Label content={props.label} fontSize="sml" fontStyle="semibold" />
        {props.isRequired && <Label content="*" fontColor="red" fontSize="sml" fontStyle="bold" />}
      </div>
      <textarea
        {...props}
        style={{ ...props.style, resize: props.resize ? "both" : "none" }}
        ref={inputRef}
        className={`${getFontFamily()}`}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
      {props.canClear !== false && props.disabled !== true && (
        <Button
          tabIndex={-1}
          style={{ padding: "4px" }}
          sizeFrame="sml"
          kind="text"
          icon={<Icon.CloseSVG className="close" />}
          onClick={onClear}
        />
      )}
      {props.iconPosition === "right" && clonedIcon}
      <label className="error">{props.error}</label>
      {props.maxLength && <Label style={{position: 'absolute', bottom: '-18px', right: '8px'}} fontSize="sml" content={`${lenght}/${props.maxLength}`} />}
    </div>
  );
});
