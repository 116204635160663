import React from "react";
import * as Icon from "commons/iconManager";
import { AccountProps } from "./AccountComponent";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { InputText } from "controls/InputText";
import * as UserApi from "api/UserApi";

const AccountForgotPasswordComponent: React.FC<AccountProps> = (props) => {
  const [confirm, setConfirm] = React.useState<boolean>(false);
  const refEmail = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const onClick = async () => {
    await UserApi.forgotPassword(refEmail.current.value).then(() => {
      setConfirm(true);
    });
  };

  return (
    <div className="frame-main">
      <div className="d-flex ai-center jc-center fd-c gap-16">
        <Label fontStyle="bold" fontSize="xl" content={"Odzyskiwanie hasła"} />
        {confirm ? (
          <>
            <div className="d-flex gap-16 ai-center">
              <Icon.CheckCircleSVG className="c-green" height={64} width={64} />
              <Label
                wrap
                fontStyle="bold"
                fontSize="sm"
                content={
                  "Jeśli Twój e-mail istnieje w bazie, system automatycznie wyśle Ci wiadomość z dalszymi instrukcjami!"
                }
              />
            </div>
            <Button
              style={{ width: "100%" }}
              sizeFrame="md"
              fontSize="lg"
              fontStyle="bold"
              kind="primary"
              label="Zamknij"
              onClick={() => props.onCloseModalDialog?.("Cancel")}
            />
          </>
        ) : (
          <>
            <Label
              wrap
              fontStyle="bold"
              fontSize="sm"
              style={{ textAlign: "center" }}
              content={
                "Aby zmienić hasło, wpisz swój adres e-mail. Otrzymasz wiadomość e-mail z instrukcjami i linkiem do resetowania hasła. Po kliknięciu w link, będziesz mógł ustawić nowe hasło i ponownie uzyskać dostęp do swojego konta."
              }
            />
            <InputText
              ref={refEmail}
              frameClassName="width-fill"
              label="E-mail"
              placeholder="Wprowadź e-mail"
              kind="tertiary"
              sizeFrame="sm"
              autoFocus
              icon={<Icon.MailSVG />}
              onSubmit={onClick}
            />
            <Button
              style={{ width: "100%" }}
              sizeFrame="md"
              fontSize="lg"
              fontStyle="bold"
              kind="primary"
              label="Wyślij prośbę o zmianę hasła"
              onClick={onClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AccountForgotPasswordComponent;
