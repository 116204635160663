import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { GroceryTabProps } from "./GroceryManagerComponent";
import EmptyComponent from "components/EmptyComponent";
import { useSelector } from "react-redux";
import { ProductState } from "models/new/State";
import { useWindowSize } from "hooks/useWindowSize";

const GroceryStatisticComponent: React.FC<GroceryTabProps> = (props) => {
  const productState: ProductState = useSelector((state: any) => state.product);
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div
        id={props.id}
        className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill"
        style={{ maxWidth: "500px" }}
      >
        <Label fontStyle="bold" fontSize="md" content="Statystyki" />
        {productState.selected?.ratings?.length === 0 && (
          <EmptyComponent message="Brak statystyk" icon={<Icon.TrendingUpSVG />} />
        )}
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Statystyki" />
      {productState.selected?.ratings?.length === 0 && (
        <EmptyComponent message="Brak statystyk" icon={<Icon.TrendingUpSVG />} />
      )}
    </div>
  );
};

export default GroceryStatisticComponent;
