import React from "react";
import * as Icon from "commons/iconManager";
import { Label, LabelProps } from "controls/Label";

interface WaitProps extends LabelProps {
  isLoading: boolean;
  message: string;
}

const WaitComponent: React.FC<WaitProps> = (props) => {
  if (!props.isLoading) return null;

  return (
    <div
      className="frame-main width-fill ai-center jc-center bg-primary"
      style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", height: "unset" }}
    >
      <Icon.LoadingSVG height={100} width={100} />
      <Label
        fontSize={props.fontSize ?? "md"}
        fontStyle={props.fontStyle ?? "semibold"}
        fontColor={props.fontColor ?? "primary"}
        content={props.message}
      />
    </div>
  );
};

export default WaitComponent;
