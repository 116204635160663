import React from "react";
import * as Icon from "commons/iconManager";
import { BasketState, SearchState } from "models/new/State";
import { useDispatch, useSelector } from "react-redux";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import PriceComponent from "components/PriceComponent";
import { InputNumber } from "controls/InputNumber";
import { Button } from "controls/Button";
import { calculatePriceValue } from "commons/numberManager";
import { addProductToBasket } from "reducers/basketReducer";
import { useURLParams } from "hooks/useURLParams";
import { Tag } from "controls/Tag";
import { convertProductToOrderProduct } from "commons/productManager";
import { NotificationType } from "models/new/Dictionaries";
import { useNotify } from "hooks/useNotify";

const SearchProductInfoOrderingComponent: React.FC = () => {
  const [value, setValue] = React.useState<number>(0);
  const [qty, setQty] = React.useState<number | undefined>(0);
  const { params } = useURLParams();
  const { addNotify } = useNotify();
  const productId = params.get("product_id") ?? "";

  const searchState: SearchState = useSelector((state: any) => state.search);
  const basketState: BasketState = useSelector((state: any) => state.basket);

  React.useEffect(() => {
    setQty(0);
    setValue(0);
  }, [productId]);

  const dispatch = useDispatch();

  const calculateValue = (qty: number) => {
    const price = searchState.supplierProduct?.salePrice ?? searchState.supplierProduct?.price ?? 0;
    setQty(qty);
    setValue((qty * price).toNumberFixed(2));
  };

  const getInfo = (): JSX.Element => {
    const findBasket = basketState.baskets?.find((x) => x.products.some((z) => z.productId === productId));
    const find = findBasket?.products.find((z) => z.productId === productId);
    if (find) {
      return (
        <InfoBox
          className="width-fill"
          sizeFrame="sml"
          iconSize="sml"
          icon="Information"
          fontSize="sm"
          fontStyle="semibold"
          value={`Ten produkt już znajduję się w koszyku. Ponowne dodanie do koszyka spowoduje zsumowanie ilości produktu. Aktualny stan - ${
            find.quantity
          } ${find.unit} wartość ${calculatePriceValue(find)}zł`}
        />
      );
    }
    return <></>;
  };
  const onAddToBasket = async () => {
    if (!qty || qty > (searchState.supplierProduct?.quantity ?? 0)) {
      addNotify({
        title: "Niepoprawna ilość",
        content: "Stan magazynowy nie pozwala na dodanie takiej ilości produktu do zamówienia.",
        type: NotificationType.Error,
      });
      return;
    }

    if (qty) {
      const prod = convertProductToOrderProduct(searchState.supplierProduct!);
      dispatch(
        addProductToBasket({
          dealer: searchState.supplierInfo!,
          orderProducts: { ...prod, quantity: qty },
        })
      );
      setQty(0);
      setValue(0);
      addNotify({
        title: "Dodano",
        content: `Dodano do koszyka produkt ${searchState.supplierProduct?.name} ilość ${qty} ${searchState.supplierProduct?.unit}!`,
        type: NotificationType.Success,
      });
    }
  };

  return (
    <>
      {searchState.supplierProduct?.blockReservation ? (
        <InfoBox className="width-fill" backgroundColor="blue" sizeFrame="sm" icon="Information" iconSize="sm">
          <div className="d-flex fd-c gap-16" style={{ overflow: "hidden" }}>
            <Label fontSize="lg" fontStyle="semibold" fontColor="black" content="Blokada rezerwacji" />
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              fontColor="black"
              content={`Z powodu blokady rezerwacji, konieczne jest wcześniejsze skontaktowanie się ze sprzedawcą przed dokonaniem rezerwacji. Oznacza to, że nie można dokonywać rezerwacji bezpośrednio przez systemy online. Aby dokonać zakupu, prosimy o kontakt telefoniczny lub za pośrednictwem komunikatora.`}
            />
            <Label
              fontSize="sm"
              fontStyle="bold"
              fontColor="black"
              content="Poniżej znajdziesz dane kontaktowe do sprzedawcy"
            />
          </div>
        </InfoBox>
      ) : (
        <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
          <div className="d-flex fd-c gap-16 width-fill" style={{ overflow: "hidden" }}>
            <Label fontSize="lg" fontStyle="semibold" content="Dodaj do kosza" />
            <div className="d-flex gap-16">
              <InputNumber
                frameClassName="width-fill"
                sizeFrame="sm"
                kind="secondary"
                label="Ilość produktu"
                placeholder="Ilość"
                min={0}
                max={searchState.supplierProduct?.quantity}
                value={qty}
                fixed={0}
                onSubmit={(e) => calculateValue(e.currentTarget.valueAsNumber)}
              />
              <InputNumber
                frameClassName="width-fill"
                sizeFrame="sm"
                kind="secondary"
                label="Wartość zakupu"
                disabled
                fixed={2}
                placeholder="Przeliczona wartość"
                value={value}
              />
            </div>
            <div className="d-flex ai-e-flex gap-4">
              <Icon.WarehouseSVG className="icon-color" />
              <Label
                content={`${searchState.supplierProduct?.quantity}${searchState.supplierProduct?.unit}`}
                fontStyle="bold"
                fontSize="md"
              />
            </div>
            <div className="d-flex fd-r ai-center jc-sb gap-16">
              <PriceComponent product={searchState.supplierProduct} />
              {searchState.supplierProduct?.availableType === "Unavailable" ? (
                <Tag sizeFrame="xsm" fontStyle="bold" kind="red" label="Niedostępny" />
              ) : (
                <Button
                  sizeFrame="sm"
                  kind="primary"
                  fontStyle="bold"
                  label="Dodaj do koszyka"
                  icon={<Icon.AddSVG />}
                  onClick={onAddToBasket}
                />
              )}
            </div>
          </div>
        </InfoBox>
      )}
    </>
  );
};

export default SearchProductInfoOrderingComponent;
