import React from "react";
import * as Icon from "commons/iconManager";
import * as OrderApi from "api/OrderApi";
import { useDispatch, useSelector } from "react-redux";
import { BasketState, BasketValideError, UserState } from "models/new/State";
import { useURLParams } from "hooks/useURLParams";
import BasketSummaryDeliveryComponent from "../BasketSummaryDeliveryComponent";
import BasketSummaryPaymentComponent from "../BasketSummaryPaymentComponent";
import { Button } from "controls/Button";
import { InputArea } from "controls/InputArea";
import ProductSummaryOrderComponent from "components/ProductSummaryOrderComponent";
import BasketSummaryPersonalDataComponent from "../BasketSummaryPersonalDataComponent";
import EmptyComponent from "components/EmptyComponent";
import { deleteBasket, setBasketValideError, changeSummaryBasket, clearSummaryBasket } from "reducers/basketReducer";
import MessageBox from "controls/MessageBox";
import { ModalDialog } from "controls/ModalDialog";
import { useNavigation } from "hooks/useNavigation";
import { summaryOrderProduct } from "commons/productManager";
import { DeliveryType, NotificationType, StatusOrder } from "models/new/Dictionaries";
import { ValidationFailure } from "models/new/ValidationFailure";
import { OrderCreateDto } from "models/DTO/OrderCreateDto";
import { OrderAddress } from "models/new/OrderAddress";
import { Address } from "models/new/Address";
import { checkIntterOpenHours } from "commons/dataTimeManager";
import { useNotify } from "hooks/useNotify";

const BasketMobilePage: React.FC = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const basketState: BasketState = useSelector((state: any) => state.basket);
  const { navigateTo } = useNavigation();
  const { addNotify } = useNotify();
  const { params } = useURLParams();
  const dealerId = params.get("dealer_id") ?? "";

  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(clearSummaryBasket());
    };
  }, []);

  React.useEffect(() => {
    dispatch(
      changeSummaryBasket({
        basket: basketState.baskets.find((x) => x.supplierId === dealerId),
        errors: basketState.valideError,
      })
    );
  }, [dealerId]);

  React.useEffect(() => {
    if (basketState.summary?.products?.length === 0) {
      deleteOrder();
    }
  }, [basketState.summary?.products]);

  const deleteOrder = async () => {
    await ModalDialog.show(
      <MessageBox
        content="Brak produktów w Twoim koszyku. Za chwile zostaniesz przeniesiony na kartę sprzedawcy!"
        icon="Information"
      />,
      "Brak produktów!",
      ["Ok"]
    );
    dispatch(deleteBasket(dealerId));
    navigateTo("search", `?dealer_id=${dealerId}`);
  };

  const onChangePickUpDate = (date: Date | undefined, address: Address): boolean => {
    let error = "";
    if (!date) {
      error = "Wybierz datę";
    } else {
      if (address) {
        const dayOfWeek = date.getDay() - 1;
        const openHours = address.openHours?.find((x) => x.day === dayOfWeek);
        if (!openHours) {
          error = "Dla wskazanego terminu sprzedaż jest zamknięta";
        } else if (!checkIntterOpenHours(date, openHours.startHour, openHours.closeHour)) {
          error = `Wybierz poprawny przedział godzinowy`;
        }
      }
    }

    dispatch(
      changeSummaryBasket({
        basket: { pickUpDate: date ?? new Date() },
        errors: { pickUpDate: error },
      })
    );
    return error.length == 0;
  };

  const submitOrder = async () => {
    let address: Partial<OrderAddress> | undefined = { ...basketState.summary?.address, country: "Poland" };
    if (basketState.summary?.deliveryType === DeliveryType.PickUpAtThePoint) {
      const addressSupplier = basketState.summary.selectedAddress?.value;
      if (addressSupplier) {
        if (!onChangePickUpDate(basketState.summary.pickUpDate, addressSupplier)) {
          return;
        }
        address = {
          ...address,
          latitude: addressSupplier.latitude,
          longitude: addressSupplier.longitude,
          street: addressSupplier.street,
          buildingNumber: addressSupplier.buildingNumber,
          localNumber: addressSupplier.localNumber,
          city: addressSupplier.city,
          postalCode: addressSupplier.postalCode,
        };
      }
    } else if (basketState.summary?.deliveryType === DeliveryType.PickupOnSite) {
      const addressSupplier = basketState.summary.supplier?.addresses.find((x) => x.isMainAddress);
      if (addressSupplier) {
        if (!onChangePickUpDate(basketState.summary.pickUpDate, addressSupplier)) {
          return;
        }
        address = {
          ...address,
          latitude: addressSupplier.latitude,
          longitude: addressSupplier.longitude,
          street: addressSupplier.street,
          buildingNumber: addressSupplier.buildingNumber,
          localNumber: addressSupplier.localNumber,
          city: addressSupplier.city,
          postalCode: addressSupplier.postalCode,
        };
      }
    }

    const orderCreate: OrderCreateDto = {
      ...basketState.summary!,
      address: address,
      appUserId: userState.user!.id,
      statusOrder: StatusOrder.New,
      totalPrice: summaryOrderProduct(basketState.summary!.products) + (basketState.summary?.deliveryCost ?? 0),
      productsPrice: summaryOrderProduct(basketState.summary!.products),
    };

    console.log(orderCreate);

    await OrderApi.createOrder(orderCreate)
      .then(async (value) => {
        await ModalDialog.show(
          <MessageBox
            content="Dziekujemy za złożenie rezerwacji. Teraz kolej na farmera. Czekaj na potwierdzenie rezerwacji więcej szczegółów znajduje się w zakładce Moje zamówienia."
            icon="Success"
          />,
          "Udało się!",
          ["Ok"]
        );
        dispatch(deleteBasket(dealerId));
        navigateTo("search");
      })
      .catch((err) => setError(err));
  };

  const setError = (err: ValidationFailure[] | string) => {
    if (typeof err === "string") {
      addNotify({
        title: "Błąd",
        content: `Wystąpił błąd podczas składania rezerwacji: ${err}`,
        type: NotificationType.Error,
      });
    } else {
      const valideError: BasketValideError = {
        firstName: err?.find((x) => x.PropertyName?.toLowerCase() === "address.firstname")?.ErrorMessage ?? "",
        lastName: err?.find((x) => x.PropertyName?.toLowerCase() === "address.lastname")?.ErrorMessage ?? "",
        phoneNumber: err?.find((x) => x.PropertyName?.toLowerCase() === "address.phonenumber")?.ErrorMessage ?? "",
        email: err?.find((x) => x.PropertyName?.toLowerCase() === "address.email")?.ErrorMessage ?? "",
        deliveryType: err?.find((x) => x.PropertyName?.toLowerCase() === "deliverytype")?.ErrorMessage ?? "",
        pickUpDate: err?.find((x) => x.PropertyName?.toLowerCase() === "pickupdate")?.ErrorMessage ?? "",
        paymentType: err?.find((x) => x.PropertyName?.toLowerCase() === "paymenttype")?.ErrorMessage ?? "",
        buildingnumber:
          err?.find((x) => x.PropertyName?.toLowerCase() === "address.buildingnumber")?.ErrorMessage ?? "",
        city: err?.find((x) => x.PropertyName?.toLowerCase() === "address.city")?.ErrorMessage ?? "",
        postalcode: err?.find((x) => x.PropertyName?.toLowerCase() === "address.postalcode")?.ErrorMessage ?? "",
        street: err?.find((x) => x.PropertyName?.toLowerCase() === "address.street")?.ErrorMessage ?? "",
        country: err?.find((x) => x.PropertyName?.toLowerCase() === "address.country")?.ErrorMessage ?? "",
      };
      const valideErrorString = `
      ${valideError.firstName.length > 0 ? valideError.firstName : ""}
      ${valideError.lastName.length > 0 ? valideError.lastName : ""}
      ${valideError.phoneNumber.length > 0 ? valideError.phoneNumber : ""}
      ${valideError.email.length > 0 ? valideError.email : ""}
      ${valideError.deliveryType.length > 0 ? valideError.deliveryType : ""}
      ${valideError.pickUpDate.length > 0 ? valideError.pickUpDate : ""}
      ${valideError.paymentType.length > 0 ? valideError.paymentType : ""}
      ${valideError.buildingnumber.length > 0 ? valideError.buildingnumber : ""}
      ${valideError.city.length > 0 ? valideError.city : ""}
      ${valideError.street.length > 0 ? valideError.street : ""}
      ${valideError.country.length > 0 ? valideError.country : ""}
          `.trim();
      dispatch(setBasketValideError(valideError));
      addNotify({
        title: "Błąd",
        content: `Wystąpił błąd podczas składania rezerwacji: ${valideErrorString}`,
        type: NotificationType.Error,
      });
    }
  };

  function goTo(tab: string): void {
    navigateTo("basket", `?tab=${tab}&dealer_id=${dealerId}`);
    const childElement = document.getElementById(tab);
    childElement?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <div id="top-page" className="f-page-top">
        <Button
          sizeFrame="md"
          kind="tertiary"
          fontStyle="bold"
          icon={<Icon.ArrowBackSVG />}
          onClick={() => navigateTo("orders")}
        />
        <div className="f-scroll-hidden-r gap-8">
          <Button kind="tertiary" sizeFrame="md" label="Szczegóły zamówienia" onClick={() => goTo("details")} />
          <Button kind="tertiary" sizeFrame="md" label="Kupione produkty" onClick={() => goTo("products_summary")} />
        </div>
      </div>
      <div className="f-page-center fd-c mag-b-32 pad-b-32">
        {basketState.summary ? (
          <div>
            <BasketSummaryPersonalDataComponent />
            <BasketSummaryDeliveryComponent />
            <BasketSummaryPaymentComponent />
            <div className="d-flex fd-c width-fill pad-2 pad-t-32 pad-b-32">
              <InputArea
                frameClassName="width-fill"
                style={{
                  minHeight: "100px",
                  resize: "none",
                }}
                sizeFrame="sm"
                kind="tertiary"
                label="Dodaj komentarz"
                placeholder="max. 250 znaków"
                canClear={false}
                value={basketState.summary?.description}
                onChange={(e) =>
                  dispatch(
                    changeSummaryBasket({
                      basket: { description: e.target.value },
                      errors: { ...basketState.valideError },
                    })
                  )
                }
              />
            </div>
            <ProductSummaryOrderComponent
              isEdit={true}
              products={basketState.summary.products}
              dealer={basketState.summary.supplier}
              deliveryCost={basketState.summary.deliveryCost}
            />
            <Button
              className="width-fill mag-t-32 mag-b-16"
              sizeFrame="lg"
              kind="primary"
              fontStyle="bold"
              fontSize="md"
              label={`Złóż rezerwacje (${(
                summaryOrderProduct(basketState.summary.products) + (basketState.summary.deliveryCost ?? 0)
              ).toLocaleFixed(2)} zł)`}
              icon={<Icon.ShoppingBagSVG />}
              onClick={submitOrder}
            />
          </div>
        ) : (
          <EmptyComponent message="Brak produktów w koszyku" icon={<Icon.ShoppingBasketSVG />} />
        )}
      </div>
    </>
  );
};

export default BasketMobilePage;
