import React from "react";
import * as Icon from "commons/iconManager";
import * as ConversationApi from "api/ConversationApi";
import { useDispatch, useSelector } from "react-redux";
import { convertToString } from "commons/stringManager";
import { ConversationItemComponent } from "./MessagePanelItemComponent";
import { Label } from "controls/Label";
import { Button } from "controls/Button";
import { InputArea } from "controls/InputArea";
import { UserState } from "models/new/State";
import { ChatMessage } from "models/new/ChatMessage";
import { closeConversation } from "reducers/conversationReducer";
import { InputText } from "controls/InputText";
var moment = require("moment");

const MessageContentComponent: React.FC<ConversationItemComponent> = (props) => {
  const [content, setContent] = React.useState<string | number | readonly string[] | undefined>("");
  const userState: UserState = useSelector((state: any) => state.user);

  const dispatch = useDispatch();

  const isMyMessage = (message: ChatMessage): string => {
    if (message.appUserId === userState.user?.id) {
      return "conversation-content-me";
    } else {
      return "conversation-content-you";
    }
  };

  const getTime = (message: ChatMessage): string => {
    const chatMessages = [...props.conversation.chatMessages].reverse();
    const index = chatMessages.findIndex((obj) => obj.id === message.id);
    if (
      index !== 0 &&
      chatMessages
        .slice(0, index + 1)
        .some(
          (x) =>
            x.id !== message.id &&
            moment(x.sentAt).format("DD MMM YYYY HH:mm") === moment(message?.sentAt).format("DD MMM YYYY HH:mm")
        )
    ) {
      return "";
    }

    if (moment(message?.sentAt).format("YYYY") !== moment().format("YYYY"))
      return moment(message?.sentAt).format("DD MMM YYYY o HH:mm");
    if (moment(message?.sentAt).week() !== moment().week()) return moment(message?.sentAt).format("DD MMM o HH:mm");
    return moment(message?.sentAt).format("HH:mm");
  };

  const getConversation = (message: ChatMessage): JSX.Element => {
    const time = getTime(message);
    return (
      <div className="d-flex fd-c ai-e-flex gap-8 width-fill">
        {time.length > 0 && (
          <Label
            style={{ margin: "auto", marginTop: "16px" }}
            fontColor="secondary"
            fontSize="xsml"
            fontStyle="semibold"
            wrap
            content={getTime(message)}
          />
        )}
        <div className={isMyMessage(message)} style={{ gap: "4px" }}>
          <Label fontSize="sm" fontStyle="semibold" fontColor="black" wrap content={message.content} />
        </div>
      </div>
    );
  };

  const onSubmit = (value: string | number | readonly string[] | undefined) => {
    const message = convertToString(value);
    if (message.length > 0) {
      ConversationApi.sendMessage({
        senderId: userState.user!.id,
        receiverId: props.conversation.chatMembers.find((x) => x.appUserId !== userState.user!.id)!.appUserId,
        content: message,
      });
    }
    setContent("");
  };

  return (
    <div className="frame-main conversation-content">
      <div className="frame-scroll gap-4 pad-r-4" style={{ flexDirection: "column-reverse" }}>
        {props.conversation.chatMessages?.map((x) => {
          return getConversation(x);
        })}
      </div>
      <div className="width-fill d-flex ai-center pad-t-8 gap-8">
        <InputText
          frameClassName="width-fill"
          placeholder="Napisz wiadomość"
          sizeFrame="sm"
          kind="secondary"
          value={content}
          canClear={false}
          onChange={(e) => setContent(e.target.value)}
          onSubmit={(e) => onSubmit((e.target as HTMLInputElement).value)}
        />
        <Button sizeFrame="sm" kind="secondary" icon={<Icon.SendSVG />} onClick={() => onSubmit(content)} />
      </div>
    </div>
  );
};

export default MessageContentComponent;

/*
 <InputArea
          frameClassName="width-fill"
          placeholder="Napisz wiadomość"
          sizeFrame="sm"
          kind="secondary"
          value={content}
          canClear={false}
          //heightAuto={true}
          onChange={(e) => setContent(e.target.value)}
          onSubmit={(e) => onSubmit((e.target as HTMLInputElement).value)}
        />
*/
