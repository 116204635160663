import React from "react";
import { useSelector } from "react-redux";
import { SearchState } from "models/new/State";
import { LazyLoadComponent } from "components/LazyLoadComponent ";
import SearchProductComponent from "./SearchProductComponent";

const SearchListProductComponent: React.FC = () => {
  const searchState: SearchState = useSelector((state: any) => state.search);

  return (
    <div className="f-scroll">
      {searchState.suppliersProducts.map((prod) => {
        return (
          <LazyLoadComponent key={prod.id}>
            <SearchProductComponent product={prod} />
          </LazyLoadComponent>
        );
      })}
    </div>
  );
};

export default SearchListProductComponent;
