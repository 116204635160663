import React from "react";
import * as Icon from "commons/iconManager";
import AddressComponent from "components/AddressComponent";
import { Label } from "controls/Label";
import { DeliveryType, showStatusOrderCodeTag } from "models/new/Dictionaries";
import { OrderProduct } from "models/new/OrderProduct";
import { useNavigation } from "hooks/useNavigation";
import { Sale } from "models/new/Sale";
import InfoBox from "controls/InfoBox";
import { getLastStatus } from "models/new/OrderStatus";

const SalesMobileItemComponent: React.FC<{ sales: Sale; className?: string }> = ({ sales, className }) => {
  const { navigateTo } = useNavigation();

  const onDisplayContentAddress = (): JSX.Element => {
    return (
      <div className="d-flex gap-4 ">
        {sales.deliveryType === DeliveryType.Post ? (
          <Icon.LocalShippingSVG className="icon-color" height={20} width={20} />
        ) : sales.deliveryType === DeliveryType.PickupOnSite ? (
          <Icon.LocationOnSVG className="icon-color" height={20} width={20} />
        ) : (
          <Icon.AddNavigationSVG className="icon-color" height={20} width={20} />
        )}
        <AddressComponent address={sales.address} showName={false} />
      </div>
    );
  };
  const calculateSummary = (product: OrderProduct): number => {
    let price = product.salePrice ?? product.price;
    /*if (product.saleCouponsCode) {
      price = calculateValueAfterPercent(price, product.saleCouponsPercent, 2);
    }*/

    price = price * product.quantity;
    return price;
  };

  const onPickUp = (): JSX.Element => {
    if (!sales.pickUpDate) return <></>;
    return (
      <div className="d-flex gap-4 ai-center ">
        <Icon.TimerSVG className="icon-color" height={20} width={20} />
        <Label
          fontSize="sml"
          fontStyle="bold"
          content={new Date(sales.pickUpDate).toFormatMoment("DD MMMM yyyy HH:mm")}
        />
      </div>
    );
  };

  const onDisplayContentSummary = (): JSX.Element => {
    let summary: number = 0;
    sales.products.forEach((product) => {
      summary += calculateSummary(product);
    });

    return (
      <div className="d-flex gap-4 ai-center ">
        <Icon.PaymentsSVG className="icon-color" height={20} width={20} />
        <Label fontSize="sm" fontStyle="bold" content={`${summary.toLocaleFixed(2)} zł`} />
      </div>
    );
  };

  return (
    <InfoBox
      className={`width-fill mag-b-8 ${className}`}
      backgroundColor="secondary"
      onClick={() => navigateTo("sale_manager", `?id=${sales.id}`)}
    >
      <div className="d-flex fd-c width-fill gap-8 pad-8">
        <div className="d-flex ai-center jc-sb width-fill">
          <Label
            fontSize="md"
            fontStyle="semibold"
            islink
            content={`${sales.address?.firstName ?? ""} ${sales.address?.lastName ?? ""}`}
          />
          {showStatusOrderCodeTag(getLastStatus(sales.statuses), "xsml")}
        </div>
        {onDisplayContentAddress()}
        <div className="d-flex jc-sb ai-e-flex">
          <div className="d-flex fd-c gap-4">
            <div className="d-flex gap-4 ai-center ">
              <Icon.CalendarMonthSVG className="icon-color" height={20} width={20} />
              <Label
                fontSize="sml"
                fontStyle="bold"
                content={new Date(sales.createdDate).toFormatMoment("DD MMMM yyyy HH:mm")}
              />
            </div>
            {onPickUp()}
          </div>
          {onDisplayContentSummary()}
        </div>
        <div className="d-flex ai-center jc-sb width-fill">
          <Label fontColor="secondary" fontSize="xsml" fontStyle="semibold" content={"Numer zamówienia"} />
          <Label fontColor="secondary" fontSize="xsml" fontStyle="bold" content={sales.code} />
        </div>
      </div>
    </InfoBox>
  );
};

export default SalesMobileItemComponent;
