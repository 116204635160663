import React from "react";
import * as Icon from "commons/iconManager";
import * as GoogleMaps from "commons/googleManager";
import * as SupplierApi from "api/SupplierApi";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionValideError, SubscriptionWizardState } from "models/new/State";
import { Button } from "controls/Button";
import { generateGUID } from "commons/stringManager";
import { Picture } from "models/new/Picture";
import { ValidationFailure } from "models/new/ValidationFailure";
import { setSubscriptionValideError } from "reducers/subscriptionWizardReducer";
import { ModalDialogProps } from "models/new/ModalDialogProps";
import { SubscriptionDto } from "models/DTO/SubscriptionDto";
import { NotificationType, SubscriptionStep, SubscriptionType } from "models/new/Dictionaries";
import SubscriptionWizardDataComponent from "./SubscriptionWizardDataComponent";
import SubscriptionWizardAddressComponent from "./SubscriptionWizardAddressComponent";
import SubscriptionWizardSettlementsComponent from "./SubscriptionWizardSettlementsComponent";
import SubscriptionWizardTypeComponent from "./SubscriptionWizardTypeComponent";
import SubscriptionWizardSummaryComponent from "./SubscriptionWizardSummaryComponent";
import SubscriptionWizardDescAndPicturesComponent from "./SubscriptionWizardDescAndPicturesComponent";
import SubscriptionWizardCertificatesComponent from "./SubscriptionWizardCertificatesComponent";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import { useWindowSize } from "hooks/useWindowSize";
import { useNotify } from "hooks/useNotify";

//const steps: SubscriptionStep[] = ["subscriptionType", "farm", "descpic", "cert", "address", "settlements", "summary"];
const steps: SubscriptionStep[] = ["subscriptionType", "farm", "descpic", "address", "cert", "summary"];

const SubscriptionWizardComponent: React.FC<ModalDialogProps> = (props) => {
  const [currentStep, setCurrentStep] = React.useState<SubscriptionStep>("subscriptionType");
  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const { isMobile } = useWindowSize();
  const { addNotify } = useNotify();
  const dispatch = useDispatch();

  const nextStep = () => {
    const currentIndex = steps.indexOf(currentStep);
    const nextIndex = currentIndex + 1;
    if (nextIndex < steps.length) {
      setCurrentStep(steps[nextIndex]);
    }
  };

  const prevStep = () => {
    const currentIndex = steps.indexOf(currentStep);
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentStep(steps[prevIndex]);
    }
  };

  const showHeader = (): React.ReactNode => {
    const index = steps.findIndex((x) => x === currentStep) + 1;
    return <Label fontStyle="bold" fontSize="lg" content={`Krok ${index} z ${steps.length} `} />;
  };

  const chcekIsValid = (errors: string[]): boolean | undefined => {
    const unValid = errors.every((key) => {
      const value = subscriptionWizardState.valideError?.[key];
      return value === undefined;
    });

    if (unValid) return undefined;

    return errors.every((key) => {
      const value = subscriptionWizardState.valideError?.[key];
      return value?.trim() === "";
    });
  };

  const showStep = (title: string, step: SubscriptionStep, errors: string[]): React.ReactNode => {
    let isValid: boolean | undefined = undefined;
    isValid = chcekIsValid(errors);

    return (
      <div className="d-flex ai-center gap-8" onClick={() => setCurrentStep(step)}>
        {step === currentStep ? (
          <Icon.RadioButtonCheckedSVG className="c-yellow" height={24} width={24} />
        ) : isValid ? (
          <Icon.CheckCircleFillSVG className="c-green" height={24} width={24} />
        ) : isValid === false ? (
          <Icon.ErrorFillSVG className="c-red" height={24} width={24} />
        ) : (
          <Icon.RadioButtonUncheckedSVG className="icon-color" height={24} width={24} />
        )}
        <Label pointer fontStyle={step === currentStep ? "extra-bold" : "regular"} content={title} />
      </div>
    );
  };

  const valide = (): boolean => {
    let resultError: string | undefined = undefined;

    if (!subscriptionWizardState.address?.openHours || subscriptionWizardState.address?.openHours?.length === 0) {
      dispatch(setSubscriptionValideError({ openHour: "Wypełnij godziny otwarcia" }));
      return false;
    }

    const regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
    const valideOpenHours = subscriptionWizardState.address?.openHours?.forEach((element) => {
      if (!regex.test(element.startHour)) {
        resultError = "Jedna z godzin jest niepoprawna";
      }

      if (!regex.test(element.closeHour)) {
        resultError = "Jedna z godzin jest niepoprawna";
      }

      if (element.startHour >= element.closeHour) {
        resultError = "Zakres godzin niepoprawny";
      }
    });

    dispatch(setSubscriptionValideError({ openHour: resultError }));
    return resultError === undefined;
  };

  const getSubscription = async () => {
    /*if (!valide()) {
      return;
    }*/
    const toDelete: string[] = [];
    let savePicture: Picture[] =
      subscriptionWizardState.pictures?.map((pic) => {
        return { id: pic.id, url: pic.url };
      }) ?? [];

    const formData = new FormData();
    if (subscriptionWizardState.pictures && subscriptionWizardState.pictures?.length > 0) {
      subscriptionWizardState.pictures.forEach((file, index) => {
        if (file.file && !file.url) {
          formData.append(`files`, file.file!);
          toDelete.push(file.id);
        }
      });
      const logoUrlPicture = subscriptionWizardState.logoUrl;
      if (logoUrlPicture) {
        if (logoUrlPicture.file && !logoUrlPicture.url) {
          formData.append(`files`, logoUrlPicture.file!);
          toDelete.push(logoUrlPicture.id);
          savePicture.push({ id: logoUrlPicture.id, url: "" });
        }
      }
    }
    if (toDelete.length > 0) {
      await SupplierApi.saveSupplierPicture(formData)
        .then((value) => {
          savePicture = savePicture?.map((picture) => {
            const saved = value.find((x) => x.id === picture.id);
            if (saved) {
              return saved;
            }
            return picture;
          });
        })
        .catch((err) => {
          addNotify({ title: "Błąd", content: err, type: NotificationType.Error });
          return;
        });
    }

    const date = new Date();
    const month = date.getMonth();
    date.setMonth(
      subscriptionWizardState.subscriptionType === SubscriptionType.Free
        ? month + 6
        : subscriptionWizardState.subscriptionType === SubscriptionType.Advance
        ? month + 1
        : month + 12
    );

    let address = structuredClone(subscriptionWizardState.address);
    if (address) {
      address = {
        ...address,
        isMainAddress: true,
        country: "Poland",
        openHours:
          address.openHours?.map((x) => {
            return {
              ...x,
              supplierAddressId: address?.id,
              closeHour: `${x.closeHour.slice(0, 5)}:00`,
              startHour: `${x.startHour.slice(0, 5)}:00`,
            };
          }) ?? [],
        supplierId: subscriptionWizardState.supplierId,
      };
      if (!address?.latitude || !address?.longitude) {
        const findAddress = await GoogleMaps.autoCompleteService(
          `${address?.street} ${address?.buildingNumber} ${address?.postalCode} ${address?.city}`
        );
        const first = findAddress?.[0];
        if (first) {
          const result = await GoogleMaps.placesService(first.place_id);
          address = { ...address, latitude: result.lat, longitude: result.lng };
        }
      }
    }

    const subscription: SubscriptionDto = {
      id: generateGUID(),
      appUserId: subscriptionWizardState.appUserId,
      supplierId: subscriptionWizardState.supplierId,
      type: subscriptionWizardState.subscriptionType,
      dateTo: date,
      usedTrial: subscriptionWizardState.subscriptionType === SubscriptionType.Free ? true : false,
      isActive: true,
      firstName: subscriptionWizardState.firstName,
      lastName: subscriptionWizardState.lastName,
      email: subscriptionWizardState.email,
      phoneNumber: subscriptionWizardState.phoneNumber,
      address: address,
      certificates: subscriptionWizardState.certificates,
      companyName: subscriptionWizardState.companyName,
      description: subscriptionWizardState.description,
      arimr: subscriptionWizardState.arimr,
      rhd: subscriptionWizardState.rhd,
      policy: subscriptionWizardState.policy,
      regulations: subscriptionWizardState.regulations,
      logoUrl: savePicture.find((x) => x.id === subscriptionWizardState.logoUrl?.id)?.url,
      pictures: savePicture,
    };

    await SupplierApi.saveSupplierSubscriptions(subscription)
      .then(async (value) => {
        await ModalDialog.show(
          <MessageBox content="Super! Udało Ci się dołączyć do sprzedawców!" icon="Success" />,
          "Sukces"
        );
        props.onCloseModalDialog?.("Ok");
      })
      .catch((err) => {
        setError(err);
        toDelete.forEach((del) => {
          SupplierApi.deleteSupplierPicture(del);
        });
      });
  };

  const setError = (err: ValidationFailure[] | string) => {
    if (typeof err === "string") {
      addNotify({
        title: "Błąd",
        content: `Wystąpił błąd podczas zapisu produktu: ${err}`,
        type: NotificationType.Error,
      });
    } else {
      const valideError: SubscriptionValideError = {
        subscriptionType: err?.find((x) => x.PropertyName?.toLowerCase() === "type")?.ErrorMessage ?? "",
        firstName: err?.find((x) => x.PropertyName?.toLowerCase() === "firstname")?.ErrorMessage ?? "",
        lastName: err?.find((x) => x.PropertyName?.toLowerCase() === "lastname")?.ErrorMessage ?? "",
        companyName: err?.find((x) => x.PropertyName?.toLowerCase() === "companyname")?.ErrorMessage ?? "",
        phoneNumber: err?.find((x) => x.PropertyName?.toLowerCase() === "phonenumber")?.ErrorMessage ?? "",
        email: err?.find((x) => x.PropertyName?.toLowerCase() === "email")?.ErrorMessage ?? "",
        description: err?.find((x) => x.PropertyName?.toLowerCase() === "description")?.ErrorMessage ?? "",
        logoUrl: err?.find((x) => x.PropertyName?.toLowerCase() === "logourl")?.ErrorMessage ?? "",
        pictures: err?.find((x) => x.PropertyName?.toLowerCase() === "pictures")?.ErrorMessage ?? "",
        address: err?.find((x) => x.PropertyName?.toLowerCase() === "address")?.ErrorMessage ?? "",
        addressBuildingNumber:
          err?.find((x) => x.PropertyName?.toLowerCase() === "address.buildingnumber")?.ErrorMessage ?? "",
        addressCity: err?.find((x) => x.PropertyName?.toLowerCase() === "address.city")?.ErrorMessage ?? "",
        addressPostalCode: err?.find((x) => x.PropertyName?.toLowerCase() === "address.postalcode")?.ErrorMessage ?? "",
        certificate: err?.find((x) => x.PropertyName?.toLowerCase() === "certificate")?.ErrorMessage ?? "",
        longitude: err?.find((x) => x.PropertyName?.toLowerCase() === "longitude")?.ErrorMessage ?? "",
        latitude: err?.find((x) => x.PropertyName?.toLowerCase() === "latitude")?.ErrorMessage ?? "",
        openHour: err?.find((x) => x.PropertyName?.toLowerCase() === "address.openhours")?.ErrorMessage ?? "",
        policy: err?.find((x) => x.PropertyName?.toLowerCase() === "policy")?.ErrorMessage ?? "",
        regulations: err?.find((x) => x.PropertyName?.toLowerCase() === "regulations")?.ErrorMessage ?? "",
      };
      dispatch(setSubscriptionValideError(valideError));
      addNotify({ title: "Błąd", content: "Wystąpił błąd dodawania subskrypcji", type: NotificationType.Error });
    }
  };

  if (isMobile) {
    return (
      <div className="frame-scroll gap-32">
        <SubscriptionWizardTypeComponent />
        <SubscriptionWizardDataComponent />
        <SubscriptionWizardDescAndPicturesComponent />
        <SubscriptionWizardCertificatesComponent />
        <SubscriptionWizardAddressComponent />
        <SubscriptionWizardSummaryComponent />
        <Button
          className="width-fill"
          style={{ marginBottom: "32px", marginInline: "8px" }}
          sizeFrame="lg"
          fontSize="md"
          fontStyle="bold"
          fontColor="white"
          kind="approve"
          label="Dołącz"
          onClick={getSubscription}
        />
      </div>
    );
  }

  return (
    <div className="frame-main" style={{ height: "75vh", maxWidth: "1200px", width: "75vw" }}>
      <div className="frame-top">{showHeader()}</div>
      <div className="frame-main fd-r gap-32" style={{ height: "auto" }}>
        <div
          className="frame-scroll d-flex fd-c jc-center pad-16 height-fill gap-32 border-right"
          style={{ minWidth: "220px", maxWidth: "220px" }}
        >
          {showStep("Subskrypcja", "subscriptionType", ["subscriptionType"])}
          {showStep("Dane gospodarstwa", "farm", ["firstName", "lastName", "companyName", "phoneNumber", "email"])}
          {showStep("Opis i zdjęcia", "descpic", ["description", "logoUrl", "pictures"])}
          {showStep("Adres gospodarstwa", "address", [
            "address",
            "addressCity",
            "addressPostalCode",
            "addressBuildingNumber",
            "longitude",
            "latitude",
            "openHour",
          ])}
          {showStep("Certyfikaty", "cert", ["certificate"])}
          {showStep("Podsumowanie", "summary", [])}
        </div>
        <div className="frame-main">
          {currentStep === "subscriptionType" && <SubscriptionWizardTypeComponent />}
          {currentStep === "farm" && <SubscriptionWizardDataComponent />}
          {currentStep === "descpic" && <SubscriptionWizardDescAndPicturesComponent />}
          {currentStep === "cert" && <SubscriptionWizardCertificatesComponent />}
          {currentStep === "address" && <SubscriptionWizardAddressComponent />}
          {currentStep === "settlements" && <SubscriptionWizardSettlementsComponent />}
          {currentStep === "summary" && <SubscriptionWizardSummaryComponent />}
        </div>
      </div>
      <div className="frame-footer jc-e-flex mag-t-16" style={{ minHeight: "48px" }}>
        {currentStep !== "subscriptionType" && (
          <Button
            sizeFrame="md"
            fontSize="md"
            fontStyle="bold"
            kind="text"
            label="Powrót"
            onClick={prevStep}
            icon={<Icon.ArrowBackSVG />}
          />
        )}
        {currentStep === "summary" ? (
          <Button
            sizeFrame="md"
            fontSize="md"
            fontStyle="bold"
            fontColor="white"
            kind="approve"
            label="Dołącz"
            onClick={getSubscription}
          />
        ) : (
          <Button
            sizeFrame="md"
            fontSize="md"
            fontStyle="bold"
            kind="primary"
            label="Dalej"
            onClick={nextStep}
            icon={<Icon.ArrowForwardSVG />}
            iconPosition="right"
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionWizardComponent;
