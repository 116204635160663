import { createAction, createReducer } from "@reduxjs/toolkit";
import { OrderState } from "../models/new/State";
import { Order } from "../models/new/Order";
import { DeliveryType, GroupByDate, StatusOrder } from "../models/new/Dictionaries";
import { SearchOrderFilter } from "models/new/SearchOrderFilter";
import { Rating } from "models/new/Rating";
import { generateGUID } from "commons/stringManager";

const dateFrom = new Date();
dateFrom.setDate(dateFrom.getDate() - 60);

export const initialFilter: SearchOrderFilter = {
  deliveryType: DeliveryType.All,
  statusOrder: StatusOrder.All,
  groupByDate: GroupByDate.Day,
  sort: "DESC",
  take: 1000,
  dateFrom: dateFrom,
  dateTo: new Date(),
};

const initialState = {
  orders: [],
  filter: initialFilter,
} as OrderState;

export const getOrders = createAction<Order[]>("order/getOrders");
export const setSelectedOrder = createAction<Order | undefined>("order/setSelectedOrder");
export const createOrder = createAction<Order>("order/createOrder");
export const updateOrder = createAction<Order>("order/updateOrder");
export const deleteOrder = createAction<Order>("order/deleteOrder");
export const changeOrderFilter = createAction<Partial<SearchOrderFilter>>("order/changeOrderFilter");
export const clearOrderFilter = createAction("order/clearOrderFilter");

export const setProductRate = createAction<Partial<Rating>>("order/setProductRate");
export const setSupplierRate = createAction<Partial<Rating>>("order/setSupplierRate");

export const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOrders, (state, action) => {
      state.orders = action.payload;
    })
    .addCase(setSelectedOrder, (state, action) => {
      state.selected = action.payload;
    })
    .addCase(createOrder, (state, action) => {
      state.orders.push(action.payload);
    })
    .addCase(updateOrder, (state, action) => {
      state.orders = state.orders.map((x) => (x.id === action.payload.id ? action.payload : x));
    })
    .addCase(deleteOrder, (state, action) => {
      state.orders = state.orders.filter((x) => x.id !== action.payload.id);
    })
    .addCase(setProductRate, (state, action) => {
      if (state.selected) {
        const find = state.selected.productRatings.find((x) => x.productId === action.payload.productId);
        if (find) {
          state.selected.productRatings = state.selected.productRatings.map((x) =>
            x.productId === action.payload.productId ? { ...x, ...action.payload } : x
          );
        } else {
          const newRating: Rating = {
            id: generateGUID(),
            date: new Date(),
            comment: action.payload.comment ?? "",
            rate: action.payload.rate ?? 0,
            reviewerId: action.payload.reviewerId ?? "",
            productId: action.payload.productId ?? ""
          };
          state.selected.productRatings = [...state.selected.productRatings, newRating];
        }
      }
    })
    .addCase(setSupplierRate, (state, action) => {
      if (state.selected) {
        state.selected.supplierRating = { ...state.selected?.supplierRating, ...action.payload };
      }
    })
    .addCase(changeOrderFilter, (state, action) => {
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      };
    })
    .addCase(clearOrderFilter, (state) => {
      state.filter = initialFilter;
    });
});
