import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import * as SupplierApi from "api/SupplierApi";
import * as Icon from "commons/iconManager";
import * as GoogleMaps from "commons/googleManager";
import { useDispatch, useSelector } from "react-redux";
import { MainState, SearchState } from "models/new/State";
import ShowDealerOnMapComponent from "../ShowDealerOnMapComponent";
import { changeMainState, setMapBounds } from "reducers/mainReducer";
import { useNavigation } from "hooks/useNavigation";
import { Label } from "controls/Label";
import { SupplierInArea } from "models/new/SupplierInArea";
import { Button } from "controls/Button";
import { Supplier } from "models/new/Supplier";
import { setSupplierInfo } from "reducers/searchReducer";
import { useURLParams } from "hooks/useURLParams";

const SearchMobileMapComponent: React.FC = () => {
  const [mapRef, setMapRef] = React.useState<google.maps.Map>();
  const [supplierInArea, setSupplierInArea] = React.useState<SupplierInArea>();
  const [showRecenter, setShowRecenter] = React.useState<boolean>(false);
  const [styleMaps, setStyleMaps] = React.useState<google.maps.MapTypeStyle[] | undefined>();

  const { navigateTo } = useNavigation();
  const { params } = useURLParams();
  const dealerId = params.get("dealer_id");
  const productId = params.get("product_id");

  const mainState: MainState = useSelector((state: any) => state.main);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  const markersOnMap = React.useRef<google.maps.Marker[]>([]);

  React.useEffect(() => {
    onBoundsChanged();
  }, []);

  React.useEffect(() => {
    mainState.theme === "dark" ? setStyleMaps(GoogleMaps.darkModeForMaps) : setStyleMaps(GoogleMaps.lightModeForMaps);
  }, [mainState.theme]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      getMarkerDealersFilter();
    }, 200);
    return () => clearTimeout(timeout);
  }, [
    searchState.supplierInfo,
    searchState.suppliersProducts,
    searchState.suppliersInArea,
    searchState.geoLocationState,
    mapRef,
  ]);

  React.useEffect(() => {
    if (mapRef && mainState.mapBounds?.zoom) {
      mapRef?.setZoom(mainState.mapBounds.zoom);
    }
  }, [mainState.mapBounds?.zoom]);

  React.useEffect(() => {
    downloadData();
  }, [supplierInArea]);

  React.useEffect(() => {
    if ((dealerId || productId) && searchState.supplierInfo) {
      setSupplierInArea(searchState.supplierInfo);
    }
  }, [dealerId]);

  const debounceTimeoutRef = React.useRef<any>(null);
  const prevInfoWindow = React.useRef<google.maps.InfoWindow>();

  const downloadData = async () => {
    if (!supplierInArea) {
      dispatch(setSupplierInfo(undefined));
      return;
    }

    if (searchState.supplierInfo && searchState.supplierInfo.id === supplierInArea.id) {
      return;
    }

    const supplier = SupplierApi.getSupplier(supplierInArea.id);
    const supplierProducts = SupplierApi.getSupplierProducts(supplierInArea.id);
    const supplierAddresses = SupplierApi.getSupplierAddresses(supplierInArea.id);
    const supplierRatings = SupplierApi.getSupplierRatings(supplierInArea.id);
    const supplierPosts = SupplierApi.getSupplierPosts(supplierInArea.id);
    const supplierCertificates = SupplierApi.getSupplierCertificates(supplierInArea.id);
    const supplierPictures = SupplierApi.getSupplierPictures(supplierInArea.id);

    await Promise.all([
      supplier,
      supplierProducts,
      supplierAddresses,
      supplierRatings,
      supplierPosts,
      supplierCertificates,
      supplierPictures,
    ])
      .then((values) => {
        const supplierInfo: Supplier = values[0];
        supplierInfo.products = values[1];
        supplierInfo.addresses = values[2];
        supplierInfo.ratings = values[3];
        supplierInfo.posts = values[4];
        supplierInfo.certificates = values[5];
        supplierInfo.pictures = values[6];
        dispatch(setSupplierInfo(supplierInfo));
      })
      .catch((err) => console.log("SearchDealerInfoComponent - downloadData"));
  };

  const goToDealer = async (dealer: SupplierInArea) => {
    dispatch(changeMainState({ showDealerOnMap: false }));
    navigateTo("search", `?dealer_id=${dealer.id}`);
  };

  const onMarkerClick = (latlang?: google.maps.LatLng | null) => {
    latlang && mapRef?.panTo(latlang);
    prevInfoWindow && prevInfoWindow.current?.close();
  };

  const onBoundsChanged = (delay: number = 500) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (mapRef) {
        const zoom = mapRef.getZoom();
        const bounds: google.maps.LatLngBounds | undefined = mapRef.getBounds();
        if (bounds && zoom) {
          const point = new google.maps.LatLng(searchState.geoLocation.lat, searchState.geoLocation.lng);
          if (!bounds.contains(point)) {
            setShowRecenter(true);
          }
          const currentBounds: { latLo: number; latHi: number; lngLo: number; lngHi: number; zoom: number } = {
            latLo: bounds.getSouthWest().lat(),
            latHi: bounds.getNorthEast().lat(),
            lngLo: bounds.getSouthWest().lng(),
            lngHi: bounds.getNorthEast().lng(),
            zoom,
          };
          dispatch(setMapBounds(currentBounds));
        }
      }
    }, delay);
  };

  const getDealerProductCount = (supplier: SupplierInArea): { isFilter: boolean; products: string[] } => {
    const filter = (searchState.filters?.phrase && searchState.filters?.phrase?.length > 0) ?? false;
    return { isFilter: filter, products: supplier.searched };
  };

  const getPin = (supplier: SupplierInArea): string => {
    const result = getDealerProductCount(supplier);

    if (result.isFilter) {
      if (result.products.length > 9) {
        return "markers/pin_10.png";
      }
      return result.products.length ? `markers/pin_${result.products.length}.png` : "markers/default.png";
    }

    return "markers/default.png";
  };

  const getMarkerDealersFilter = () => {
    const markers: google.maps.Marker[] = [];
    clearMarkers();

    const zoom = mapRef?.getZoom();

    if (searchState.geoLocationState) {
      const marker = new google.maps.Marker({
        position: { lat: searchState.geoLocation.lat, lng: searchState.geoLocation.lng },
        map: mapRef,
        icon: {
          url: "markers/person_pin_fill.png",
          scaledSize: new google.maps.Size(28, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(24, 24),
        },
      });
      markers.push(marker);
    }

    if (searchState.supplierInfo) {
      setTimeout(() => {
        searchState.supplierInfo?.addresses?.forEach((address, idx) => {
          if (address.isMainAddress) {
            onMarkerClick(new google.maps.LatLng(address.latitude, address.longitude));
          }
          const marker = new google.maps.Marker({
            position: { lat: address.latitude, lng: address.longitude },
            map: mapRef,
            icon: {
              url: address.isMainAddress ? "markers/home_pin_fill.png" : "markers/add_location_fill.png",
              scaledSize: new google.maps.Size(28, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(24, 24),
            },
          });
          markers.push(marker);
        });

        markersOnMap.current = markers;
        //mapRef?.setZoom(12);
      }, 0);
      //return;
    }

    if (zoom) {
      if (searchState.suppliersInArea.length === 0 && zoom > 12) {
        mapRef?.setZoom(zoom - 1);
        return;
      }
      searchState.suppliersInArea.forEach((dealer) => {
        if (dealer.id === searchState.supplierInfo?.id) {
          return;
        }
        const marker = new google.maps.Marker({
          position: { lat: dealer.latitude, lng: dealer.longitude },
          map: mapRef,
          icon: {
            url: getPin(dealer),
            scaledSize: new google.maps.Size(28, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 24),
          },
        });

        marker.addListener("click", (event: google.maps.MapMouseEvent) => {
          clickOnDealer(dealer, event.latLng);
        });

        markers.push(marker);
      });
    }
    markersOnMap.current = markers;
  };

  const clearMarkers = () => {
    for (let i = 0; i < markersOnMap.current.length; i++) {
      markersOnMap.current[i].setMap(null);
    }
    markersOnMap.current = [];
  };

  const clickOnDealer = async (supplier: SupplierInArea, latlang?: google.maps.LatLng | null) => {
    //goToDealer(dealer);
    setSupplierInArea(supplier);
    onMarkerClick(latlang);
  };

  const reCenter = () => {
    if (mapRef) {
      mapRef.panTo({ lat: searchState.geoLocation.lat, lng: searchState.geoLocation.lng });
      setShowRecenter(false);
    }
  };

  if (!mainState.isMapLoaded) {
    return (
      <div className="frame-main ai-center jc-center">
        <Label fontStyle="semibold" fontSize="lg" content="Wczytywanie mapy...." />
      </div>
    );
  }

  return (
    <div style={{ position: "static", paddingTop: "64px" }}>
      {showRecenter && (
        <Button
          label="Wyśrodkuj"
          kind="primary"
          sizeFrame="sm"
          fontStyle="bold"
          icon={<Icon.FilterCenterSVG />}
          style={{
            position: "absolute",
            zIndex: 10,
            bottom: "16px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          onClick={reCenter}
        />
      )}
      {supplierInArea && (
        <div className="box-on-map">
          <Button
            sizeFrame="sm"
            style={{ marginLeft: "auto" }}
            kind="text"
            icon={<Icon.CloseSVG />}
            onClick={() => setSupplierInArea(undefined)}
          />
          <ShowDealerOnMapComponent dealer={supplierInArea} searchResult={getDealerProductCount(supplierInArea)} />
          <Button
            className="width-fill"
            sizeFrame="md"
            label="Sprawdź sprzedawce"
            kind="primary"
            fontStyle="bold"
            onClick={() => goToDealer(supplierInArea)}
          />
        </div>
      )}
      <GoogleMap
        zoom={10}
        center={searchState.mapCenter}
        mapContainerClassName="map-container"
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          styles: styleMaps,
          fullscreenControl: false,
          zoomControl: false,
          gestureHandling: "greedy",
        }}
        onLoad={(map) => {
          setMapRef(map);
        }}
        onBoundsChanged={onBoundsChanged}
      />
    </div>
  );
};

export default SearchMobileMapComponent;
