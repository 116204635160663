import React from "react";
import * as Icon from "commons/iconManager";
import { ToolTip } from "./ToolTip";
import { Button } from "./Button";
import { PopUp } from "./PopUp";
import { FontStyle } from "models/new/Dictionaries";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: React.ReactElement;
  sizeFrame?: "sml" | "sm" | "md" | "lg" | "xl";
  kind?:
    | "primary"
    | "secondary"
    | "text"
    | "primary-text"
    | "approve"
    | "menu"
    | "menu-seleceted"
    | "tertiary"
    | "transparent";
  family?: FontStyle;
  count?: number;
  toolTip?: string | JSX.Element;
  toolTipPosition?: "left" | "right" | "top" | "bottom";
  children: React.ReactElement;
}

export const MultiButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const [isExpand, setIsExpand] = React.useState<boolean>(false);
  const divRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const btnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  React.useImperativeHandle(ref, () => btnRef.current);

  React.useEffect(() => {
    if (props.toolTip) {
      ToolTip.show(btnRef.current, props.toolTip, props.toolTipPosition);
    }
  }, [props.toolTip]);

  const getKind = (
    kind?:
      | "primary"
      | "secondary"
      | "text"
      | "primary-text"
      | "approve"
      | "menu"
      | "menu-seleceted"
      | "tertiary"
      | "transparent"
  ): string => {
    switch (kind) {
      case "primary":
        return "primary-button";
      case "secondary":
        return "secondary-button";
      case "approve":
        return "approve-button";
      case "text":
        return "text-button";
      case "primary-text":
        return "primary-text-button";
      case "menu":
        return "menu-button";
      case "menu-seleceted":
        return "menu-button selected";
      case "tertiary":
        return "tertiary-button";
      case "transparent":
        return "transparent-button";
      default:
        return "secondary-button";
    }
  };
  const getSize = (sizeFrame?: "sml" | "sm" | "md" | "lg" | "xl"): string => {
    switch (sizeFrame) {
      case "sml":
        return " button-h32";
      case "sm":
        return " button-h40";
      case "md":
        return " button-h48";
      case "lg":
        return " button-h56";
      case "xl":
        return " button-h64";
      default:
        return "  button-h40";
    }
  };
  const getSizeForIcon = (sizeFrame?: "sml" | "sm" | "md" | "lg" | "xl"): number => {
    switch (sizeFrame) {
      case "sml":
        return 20;
      case "sm":
        return 24;
      case "md":
        return 24;
      case "lg":
        return 28;
      case "xl":
        return 32;
      default:
        return 24;
    }
  };
  const getFontFamily = () => {
    switch (props.family) {
      case "italic":
        return " f-italic ";
      case "regular":
        return " f-regular ";
      case "medium":
        return " f-medium ";
      case "medium-italic":
        return " f-medium-italic ";
      case "semibold":
        return " f-semibold ";
      case "semibold-italic":
        return " f-semibold-italic ";
      case "bold":
        return " f-bold ";
      case "bold-italic":
        return " f-bold-italic ";
      case "extra-bold":
        return " f-extra-bold ";
      case "extra-bold-italic":
        return " f-extra-bold-italic ";
      default:
        return " f-semibold ";
    }
  };
  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += getFontFamily();
    style += " " + props.className;
    return style;
  };
 
  const getList = (): JSX.Element => {
    return (
      <div
        className="d-flex fd-c gap-4"
        onClick={() => {
          PopUp.onHide();
        }}
      >
        {props.children}
      </div>
    );
  };

  const showPopUp = async (expand: boolean) => {
    if (expand) {
      await PopUp.show(divRef.current, getList(), "bottom", 150, () => setIsExpand(false));
    }
    setIsExpand(expand);
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.onClick) props.onClick(e);
    else showPopUp(!isExpand);
  };

  return (
    <div className={`d-flex ai-center ${getStyle()}`} ref={divRef}>
      <Button {...props} kind="transparent" fontColor="black" sizeFrame="sml" onClick={(e) => onClick(e)} />
      <div className="border-right height-fill" />
      <Button
        style={{ padding: "0px" }}
        sizeFrame={props.sizeFrame}
        kind="transparent"
        icon={<Icon.ExpandMoreSVG className={`icon-expand ${isExpand && "icon-expanded"} icon-black`} />}
        onClick={() => showPopUp(!isExpand)}
      />
    </div>
  );
});
