import React from "react";
import * as Icon from "commons/iconManager";
import * as GoogleMaps from "commons/googleManager";
import * as ProductApi from "api/ProductApi";
import { Button } from "controls/Button";
import { InputText } from "controls/InputText";
import { useDispatch, useSelector } from "react-redux";
import { changeSearchFilter, changeSearchState, setSuppliersProducts } from "reducers/searchReducer";
import { MainState, SearchState } from "models/new/State";
import { PopUp } from "controls/PopUp";
import { Label } from "controls/Label";
import SearchBoxLocalizationComponent from "src/pages/search/components/SearchBoxLocalizationComponent";
import SearchListProductComponent from "./SearchListProductComponent";
import { useURLParams } from "hooks/useURLParams";
import SearchProductInfoComponent from "./SearchProductInfoComponent";
import SearchSupplierInfoComponent from "./SearchSupplierInfoComponent";
import { Response } from "models/new/Response";
import EmptyComponent from "components/EmptyComponent";
import { changeMainState, setMapBounds } from "reducers/mainReducer";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";
import { InputComboBox } from "controls/InputComboBox";
import SearchBoxProductComponent from "./SearchBoxProductComponent";
import InfoBox from "controls/InfoBox";
import { maxStringChar } from "commons/stringManager";
import { useWindowSize } from "hooks/useWindowSize";
import { UserGuide } from "controls/UserGuide";
import { SearchProductFilter } from "models/new/SearchProductFilter";
import SearchMobileMapComponent from "./mobile/SearchMobileMapComponent";
import { distance } from "commons/listManager";
import WaitComponent from "components/WaitComponent";

const SearchManagerComponent: React.FC = () => {
  const [searchGeolocation, setSearchGeolocation] = React.useState<
    { name: string; prediction: google.maps.places.AutocompletePrediction }[]
  >([]);
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const mainState: MainState = useSelector((state: any) => state.main);
  const { params } = useURLParams();
  const productId = params.get("product_id") ?? "";
  const dealerId = params.get("dealer_id") ?? "";

  const dispatch = useDispatch();

  const inputLocalizationRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const divLocalizationRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const divProductRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const distanceBtnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const handleKeyNavigation = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "/") {
        showPopUpProduct();
      }
    },
    [searchState.filters]
  );

  React.useEffect(() => {
    const keydownListener = (evt: any) => handleKeyNavigation(evt);

    document.addEventListener("keydown", keydownListener);
    return () => document.removeEventListener("keydown", keydownListener);
  }, [handleKeyNavigation]);

  React.useEffect(() => {
    // userGuid();
  }, []);

  const userGuid = async () => {
    let result = await UserGuide.show(
      "location-box",
      "Wprowadź adres żebyśmy mogli odszukać sprzedawców w Twojej okolicy."
    );
    if (result.result === "skip") return;
    result = await UserGuide.show(
      "distance-box",
      "Zwiekszaj zakres swoich poszukiwań jeśli najbliższa oferta jest niesatysfakcjonująca."
    );
    if (result.result === "skip") return;
    result = await UserGuide.show("search-box", "Wybierz kilka produktów, które Cię interesują.");
    if (result.result === "skip") return;
    result = await UserGuide.show("filter-button", "Dodaj odpowiednie filtry aby poszukać jak najlepszej oferty.");
    if (result.result === "skip") return;
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchState.filters, searchState.geoLocation]);

  const downloadData = async () => {
    setIsLoading(true);
    let searchProductFilter: SearchProductFilter | undefined = undefined;

    if (searchState.geoLocationState) {
      const bounds = GoogleMaps.getBoundsForPoint(searchState.geoLocation, searchState.filters.distance);
      searchProductFilter = {
        locLat: searchState.geoLocation.lat,
        locLng: searchState.geoLocation.lng,
        latMin: bounds.latLo,
        latMax: bounds.latHi,
        lngMin: bounds.lngLo,
        lngMax: bounds.lngHi,
        zoom: 10,
        phrase: searchState.filters.phrase,
        distance: searchState.filters.distance,
        promotion: searchState.filters.promotion,
        sort: searchState.filters.sort,
        priceFrom: searchState.filters.priceFrom,
        priceTo: searchState.filters.priceTo,
        stars: searchState.filters.stars,
        take: searchState.filters.take,
        categoryId: searchState.filters.categoryId,
      };
    } else if (mainState.mapBounds) {
      searchProductFilter = {
        locLat: searchState.geoLocation.lat,
        locLng: searchState.geoLocation.lng,
        latMin: mainState.mapBounds.latLo,
        latMax: mainState.mapBounds.latHi,
        lngMin: mainState.mapBounds.lngLo,
        lngMax: mainState.mapBounds.lngHi,
        zoom: mainState.mapBounds.zoom,
        phrase: searchState.filters.phrase,
        distance: searchState.filters.distance,
        promotion: searchState.filters.promotion,
        sort: searchState.filters.sort,
        priceFrom: searchState.filters.priceFrom,
        priceTo: searchState.filters.priceTo,
        stars: searchState.filters.stars,
        take: searchState.filters.take,
        categoryId: searchState.filters.categoryId,
      };
    }

    if (!searchProductFilter) return;

    await ProductApi.getProducts(searchProductFilter)
      .then((values) => {
        dispatch(setSuppliersProducts(values));
      })
      .catch((err: Response) => {
        console.log(`SearchManagerComponent - ${err}`);
      })
      .finally(() => setIsLoading(false));
  };

  //SEARCH LOCALIZATION
  const onChangeLocalization = async (search: string) => {
    const pred: { name: string; prediction: google.maps.places.AutocompletePrediction }[] = [];

    if (search && search.length >= 3) {
      const result = await GoogleMaps.autoCompleteService(search);
      result?.forEach((place) => {
        pred.push({ name: place.description, prediction: place });
      });
    }

    setSearchGeolocation(pred);
    PopUp.onRefresh(
      <SearchBoxLocalizationComponent
        value={search}
        itemSource={pred}
        onChange={onChangeLocalization}
        onDisplayItem={onDisplayItemLocalization}
        onSubmit={(item) => onSelectedChangeLocalization(item)}
      />
    );
  };
  const onDisplayItemLocalization = (item: any, selected?: any) => {
    return (
      <div
        className={`search-box ${item === selected && "search-box-selected"}`}
        onClick={() => onSelectedChangeLocalization(item)}
      >
        <Icon.LocationOnSVG className="icon-search icon-color" />
        <Label fontSize="sm" fontStyle="bold" pointer content={item.name} />
      </div>
    );
  };
  const onSelectedChangeLocalization = async (item: any) => {
    PopUp.onHide();
    if (!item) return;

    inputLocalizationRef.current.value = item.name;
    const result = await GoogleMaps.placesService(item.prediction.place_id);
    localStorage.setItem("geoLocation", JSON.stringify({ lat: result.lat, lng: result.lng, name: item.name }));
    dispatch(
      changeSearchState({
        ...searchState,
        geoLocationState: true,
        geoLocation: { lat: result.lat, lng: result.lng, name: item.name },
        mapCenter: { lat: result.lat, lng: result.lng, name: item.name },
      })
    );
  };

  const showPopUpLocalization = async () => {
    await PopUp.show(
      divLocalizationRef.current,
      <SearchBoxLocalizationComponent
        value={searchState.geoLocation.name}
        itemSource={searchGeolocation}
        onChange={onChangeLocalization}
        onDisplayItem={onDisplayItemLocalization}
        onSubmit={(item) => onSelectedChangeLocalization(item)}
      />,
      "onto"
    );
  };
  const onChangeInputAddress = (value: string) => {
    if (value.length === 0) {
      setSearchGeolocation([]);
      dispatch(
        changeSearchState({
          ...searchState,
          geoLocationState: false,
          geoLocation: { ...searchState.geoLocation, name: "" },
        })
      );
    }
  };

  //SEARCH PRODUCT
  const onSelectedChangeProducts = async (item) => {
    PopUp.onHide();
    dispatch(changeSearchFilter({ ...searchState.filters, phrase: item }));
  };

  const showPopUpProduct = async () => {
    await PopUp.show(
      divProductRef.current,
      <SearchBoxProductComponent value="" selected={searchState.filters.phrase} onSubmit={onSelectedChangeProducts} />,
      "onto"
    );
  };

  const checkFilter = (): number => {
    let result = 0;
    //if (searchState.filters.distance) result++;
    if (searchState.filters.priceFrom) result++;
    if (searchState.filters.priceTo) result++;
    if (searchState.filters.promotion) result++;
    if (searchState.filters.stars) result++;
    if (searchState.filters.categoryId) result++;
    return result;
  };
  const extendSearchArea = () => {
    const findIndex = distance.findIndex((x) => x.value === searchState.filters.distance);
    dispatch(changeSearchFilter({ ...searchState.filters, distance: distance[findIndex + 1].value }));
    mainState.mapBounds?.zoom &&
      dispatch(setMapBounds({ ...mainState.mapBounds!, zoom: mainState.mapBounds!.zoom - 1 }));
  };

  const showSelectedProduct = (): React.ReactNode => {
    if (!searchState.filters.phrase) return <></>;

    const first = searchState.filters.phrase?.[0];
    const count = searchState.filters.phrase.length - 1;
    return (
      <div className="d-flex ai-center gap-4">
        {first && (
          <Button
            sizeFrame="sml"
            label={maxStringChar(12, first)}
            kind="primary"
            iconPosition="right"
            fontStyle="bold"
            icon={<Icon.CloseSVG />}
          />
        )}
        {count > 0 && (
          <Button
            sizeFrame="sml"
            label={`+${count.toString()}`}
            kind="primary"
            iconPosition="right"
            fontStyle="bold"
            icon={<Icon.CloseSVG />}
          />
        )}
      </div>
    );
  };

  const showOnMap = (): React.ReactNode => {
    return (
      <div className="d-flex ai-center jc-center box-shadow-5" style={{ position: "relative" }}>
        <img className="image-map" src="/img/maps.jpg" />
        <Button
          label="Zobacz na mapie"
          fontStyle="semibold"
          sizeFrame="sml"
          icon={<Icon.MapSVG />}
          style={{ position: "absolute" }}
          onClick={() => dispatch(changeMainState({ showMap: true }))}
        />
      </div>
    );
  };

  const emptySearching = (): React.ReactNode => {
    if (!searchState.geoLocationState) {
      return (
        <EmptyComponent icon={<Icon.LocationOffSVG />} message={`Nie znamy Twojej lokalizacji.`}>
          <Button
            sizeFrame="sml"
            kind="primary"
            fontStyle="bold"
            label="Ustaw lokalizacje"
            icon={<Icon.LocationOnSVG />}
            onClick={() => {
              if (isMobile) {
                dispatch(changeMainState({ showLocation: true }));
              } else {
                showPopUpLocalization();
              }
            }}
          />
        </EmptyComponent>
      );
    }
    if (searchState.isLoading) return <WaitComponent isLoading={searchState.isLoading} message="Wyszukiwanie..." />;
    return (
      <EmptyComponent icon={<Icon.GrocerySVG />} message={`Sprawdź czy wskazałeś swoją lokalizację.`}>
        <Button
          sizeFrame="sml"
          kind="primary"
          fontStyle="bold"
          label="Zwiększ zakres poszukiwań"
          onClick={extendSearchArea}
        />
      </EmptyComponent>
    );
  };

  const showDistance = () => {
    PopUp.show(
      distanceBtnRef.current,
      <>
        {distance.map((item) => {
          return (
            <Button
              className="width-fill"
              sizeFrame="lg"
              kind={item.value === searchState.filters.distance ? "menu-seleceted" : "menu"}
              label={item.name}
              onClick={() => {
                dispatch(changeSearchFilter({ ...searchState.filters, distance: item.value }));
                PopUp.onHide();
              }}
            />
          );
        })}
      </>
    );
  };

  if (productId) {
    return <SearchProductInfoComponent />;
  }

  if (dealerId) {
    return <SearchSupplierInfoComponent />;
  }

  document.title = "Ogrosa | Szukaj produktu";

  if (mainState.showMap) {
    return (
      <>
        <div id="top-page" className="f-page-top">
          <div className="f-scroll-hidden-r gap-8">
            <Button
              id="back-button"
              sizeFrame="md"
              kind={"tertiary"}
              icon={<Icon.ArrowBackSVG />}
              onClick={() => dispatch(changeMainState({ showMap: false }))}
            />
            <Button
              id="loc-button"
              sizeFrame="md"
              kind={"tertiary"}
              icon={
                searchState.geoLocationState ? (
                  <Icon.LocationOnSVG className="c-green" />
                ) : (
                  <Icon.LocationOnSVG className="c-red" />
                )
              }
              onClick={() => dispatch(changeMainState({ showLocation: true }))}
            />
            <Button
              id="search-button"
              sizeFrame="md"
              kind={"tertiary"}
              icon={<Icon.SearchSVG />}
              count={searchState?.filters?.phrase?.length}
              onClick={() => dispatch(changeMainState({ showSearch: true }))}
            />
            <Button
              id="filter-button"
              sizeFrame="md"
              kind={"tertiary"}
              icon={<Icon.TuneSVG />}
              count={checkFilter()}
              onClick={() => dispatch(changeMainState({ showFilter: true }))}
            />
            <Button
              ref={distanceBtnRef}
              id="distance-button"
              sizeFrame="md"
              className="width-fill"
              kind={"tertiary"}
              icon={<Icon.DistanceSVG />}
              label={distance.find((x) => x.value === searchState.filters.distance)?.name}
              onClick={showDistance}
            />
          </div>
        </div>
        <SearchMobileMapComponent />
      </>
    );
  }

  if (isMobile) {
    return (
      <>
        <div id="top-page" className="f-page-top">
          <div className="f-scroll-hidden-r gap-8">
            <Button
              id="loc-button"
              sizeFrame="md"
              kind={"tertiary"}
              icon={
                searchState.geoLocationState ? (
                  <Icon.LocationOnSVG className="c-green" />
                ) : (
                  <Icon.LocationOffSVG className="c-red" />
                )
              }
              onClick={() => dispatch(changeMainState({ showLocation: true }))}
            />
            <Button
              id="search-button"
              sizeFrame="md"
              kind={"tertiary"}
              icon={<Icon.SearchSVG />}
              count={searchState?.filters?.phrase?.length}
              onClick={() => dispatch(changeMainState({ showSearch: true }))}
            />
            <Button
              id="filter-button"
              sizeFrame="md"
              kind={"tertiary"}
              icon={<Icon.TuneSVG />}
              count={checkFilter()}
              onClick={() => dispatch(changeMainState({ showFilter: true }))}
            />
            <Button
              ref={distanceBtnRef}
              id="distance-button"
              sizeFrame="md"
              className="width-fill"
              kind={"tertiary"}
              icon={<Icon.DistanceSVG />}
              label={distance.find((x) => x.value === searchState.filters.distance)?.name}
              onClick={showDistance}
            />
          </div>
        </div>
        <div className="f-page-center">
          {searchState.suppliersProducts.length === 0 ? (
            <>{emptySearching()}</>
          ) : (
            <>
              {!isLoading ? (
                <div className="f-fill">
                  {showOnMap()}
                  <SearchListProductComponent />
                </div>
              ) : (
                <WaitOnLoadingComponent
                  isLoading={isLoading}
                  count={10}
                  className="width-fill rounded-12"
                  style={{ minHeight: "115px", marginTop: "8px", marginRight: "4px" }}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="d-flex fd-c gap-8 height-fill" style={{ overflow: "hidden" }}>
      <div className="d-flex fd-c gap-8">
        <div className="d-flex fd-r gap-8">
          <InputText
            frameId="location-box"
            frameClassName="width-fill"
            frameRef={divLocalizationRef}
            ref={inputLocalizationRef}
            sizeFrame="md"
            kind="tertiary"
            placeholder="np. Zamkowa 14/12 Warszawa"
            value={searchState.geoLocation.name}
            icon={
              searchState.geoLocationState ? (
                <Icon.LocationOnSVG className="c-green" />
              ) : (
                <Icon.LocationOffSVG className="c-red" />
              )
            }
            canClear={false}
            onClick={showPopUpLocalization}
            onChange={(e) => onChangeInputAddress(e.target.value)}
          />
          <InputComboBox
            frameId="distance-box"
            frameStyle={{ width: "160px", paddingInline: "0px", paddingLeft: "8px" }}
            sizeFrame="md"
            displayMember="name"
            selectedItem={distance.find((x) => x.value === searchState.filters.distance)}
            itemSource={distance}
            canClear={false}
            onSelectionChanged={(e) => dispatch(changeSearchFilter({ ...searchState.filters, distance: e.value }))}
            kind="tertiary"
          />
        </div>
        <div className="d-flex fd-r gap-8">
          <InfoBox
            id="search-box"
            ref={divProductRef}
            className="width-fill ai-center"
            style={{ cursor: "pointer", paddingLeft: "22px", overflow: "hidden" }}
            backgroundColor="secondary"
          >
            <div
              className="d-flex ai-center gap-8 width-fill"
              style={{ position: "relative" }}
              onClick={showPopUpProduct}
            >
              <Icon.SearchSVG className="icon-default" />
              {showSelectedProduct()}
              <Label pointer fontStyle="semibold" fontColor="secondary" content="Czego potrzebujesz?" />
              <div className="l-button" style={{ position: "absolute", right: "0" }}>
                <Label fontSize="sml" fontStyle="extra-bold" content="/" />
              </div>
            </div>
          </InfoBox>
          <Button
            id="filter-button"
            sizeFrame="md"
            style={{ flex: 1 }}
            kind={mainState.showFilter ? "primary" : "tertiary"}
            icon={<Icon.TuneSVG />}
            toolTip={"Filtruj"}
            count={checkFilter()}
            onClick={() => dispatch(changeMainState({ showFilter: !mainState.showFilter }))}
          />
        </div>
      </div>

      <div className="f-fill" style={{ position: "relative" }}>
        {searchState.suppliersProducts.length === 0 ? (
          <>{emptySearching()}</>
        ) : (
          <>
            {!isLoading ? (
              <SearchListProductComponent />
            ) : (
              <WaitOnLoadingComponent
                isLoading={isLoading}
                count={10}
                className="width-fill rounded-12"
                style={{ minHeight: "115px", marginTop: "8px", marginRight: "4px" }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchManagerComponent;
