import React from "react";
import * as Icon from "commons/iconManager";
import { Sale } from "models/new/Sale";
import { Label } from "controls/Label";
import { PopUp } from "controls/PopUp";
import { OrderProduct } from "models/new/OrderProduct";
import PictureBoxComponent from "components/PictureBoxComponent";

const SalesSummayComponent: React.FC<{ title: string; sales: Sale[] }> = ({ title, sales }) => {
  function groupByProductName(): Record<string, OrderProduct[]> {
    const products: OrderProduct[] = sales
      .flatMap((x) => x.products)
      .sort((a, b) => a.productId.localeCompare(b.productId));

    return products.reduce((groups, product) => {
      const dateKey = product.productId;
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(product);
      return groups;
    }, {} as Record<string, OrderProduct[]>);
  }

  const showSummary = () => {
    const group = groupByProductName();

    return Object.keys(group).map((item) => {
      return (
        <div className="d-flex width-fill">
          <PictureBoxComponent url={group[item][0].photoUrl} className="image-on-list" />
          <div className="d-flex fd-c jc-sb pad-8">
            <Label fontSize="lg" fontStyle="semibold" content={group[item][0].name} />
            <Label
              fontSize="lg"
              fontStyle="semibold"
              content={`${group[item].sum("quantity")} ${group[item][0].unit}`}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="d-flex fd-c width-fill gap-16 pad-16 mag-b-16 rounded-12 border">
      <div className="d-flex ai-center gap-8">
        <Icon.FormatListBulletedSVG className="icon-color" />
        <Label wrap fontSize="md" fontStyle="semibold" content={title} />
      </div>
      <div className="f-scroll gap-8">{showSummary()}</div>
    </div>
  );
};

export default SalesSummayComponent;
