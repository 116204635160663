import { Product } from "models/new/Product";
import * as api from "./api";
import { Picture } from "models/new/Picture";
import { Address } from "models/new/Address";
import { Certificate } from "models/new/Certificate";
import { ProductInfo } from "models/new/ProductInfo";
import { Rating } from "models/new/Rating";
import { SearchProductFilter } from "models/new/SearchProductFilter";
import { ProductDto } from "models/DTO/ProductDto";
import { SaleCoupon } from "models/new/SaleCoupon";
import { ProductQtyAndPriceDto } from "models/DTO/ProductQtyAndPriceDto";

export function getProducts(searchProductFilter: SearchProductFilter): Promise<Product[]> {
  let query = `/products/search?LocLat=${searchProductFilter.locLat}&LocLng=${searchProductFilter.locLng}&LatMin=${searchProductFilter.latMin}&LatMax=${searchProductFilter.latMax}&LngMin=${searchProductFilter.lngMin}&LngMax=${searchProductFilter.lngMax}&Zoom=${searchProductFilter.zoom}&Distance=${searchProductFilter.distance}&Promotion=${searchProductFilter.promotion}&Take=${searchProductFilter.take}`;

  if (searchProductFilter.sort !== undefined) {
    query += `&Sort=${searchProductFilter.sort}`;
  }
  if (searchProductFilter.priceFrom !== undefined) {
    query += `&PriceFrom=${searchProductFilter.priceFrom}`;
  }
  if (searchProductFilter.priceTo !== undefined) {
    query += `&PriceTo=${searchProductFilter.priceTo}`;
  }
  if (searchProductFilter.stars !== undefined) {
    query += `&Stars=${searchProductFilter.stars}`;
  }
  if (searchProductFilter.phrase && searchProductFilter.phrase.length > 0) {
    searchProductFilter.phrase.forEach((x) => {
      query += `&Phrase=${x}`;
    });
  }
  if (searchProductFilter.categoryId !== undefined) {
    query += `&CategoryId=${searchProductFilter.categoryId}`;
  }

  return api.getWithoutToken(query);
}

export function getProduct(id: string): Promise<ProductInfo> {
  return api.getWithoutToken(`/products/${id}`);
}
export function getProductRatings(id: string): Promise<Rating[]> {
  return api.getWithoutToken(`/products/${id}/ratings`);
}
export function getProductCertificates(id: string): Promise<Certificate[]> {
  return api.getWithoutToken(`/products/${id}/certificates`);
}
export function getProductAddresses(id: string): Promise<Address[]> {
  return api.getWithoutToken(`/products/${id}/addresses`);
}
export function getProductPictures(id: string): Promise<Picture[]> {
  return api.getWithoutToken(`/products/${id}/pictures`);
}
export function getProductSaleCoupons(id: string): Promise<SaleCoupon[]> {
  return api.getWithoutToken(`/products/${id}/salecoupons`);
}
export function getProductQuantity(id: string): Promise<ProductQtyAndPriceDto> {
  return api.getWithoutToken(`/products/${id}/quantity_price`);
}

export function saveProduct(product: ProductDto): Promise<string> {
  return api.post(`/products`, { product: product });
}

export function savePictureProduct(file: FormData): Promise<Picture[]> {
  return api.postFile(`/files`, file);
}

export function deleteProductPicture(id: string): Promise<string> {
  return api.del(`/files/${id}`);
}

export function deleteProduct(id: string): Promise<string> {
  return api.del(`/products/${id}`);
}
