import * as api from "./api";
import { Follower } from "models/new/Follower";

export function addFollow(follower: Follower): Promise<string> {
  return api.post(`/followers`, { follower: follower });
}

export function deleteFollow(appUserId: string, supplierId: string): Promise<string> {
  return api.del(`/followers?AppUserId=${appUserId}&SupplierId=${supplierId}`);
}
