import React from "react";
import * as Icon from "commons/iconManager";
import { Notify } from "models/new/Notify";
import { Label } from "controls/Label";
import { NotificationType } from "models/new/Dictionaries";
import { useNavigation } from "hooks/useNavigation";
import { useDispatch } from "react-redux";
import { changeMainState } from "reducers/mainReducer";
import { Button } from "controls/Button";
import { useNotify } from "hooks/useNotify";

const NotificationManagerItemComponent: React.FC<{ notification: Notify }> = ({ notification }) => {
  const { navigate } = useNavigation();
  const { deleteNotify, updateNotify } = useNotify();
  const dispatch = useDispatch();

  const getStyle = () => {
    switch (notification.type) {
      case NotificationType.Error:
        return notification.isRead ? "notification-error-readed" : "notification-error";
      case NotificationType.Info:
        return notification.isRead ? "notification-info-readed" : "notification-info";
      case NotificationType.Success:
        return notification.isRead ? "notification-success-readed" : "notification-success";
      case NotificationType.Warning:
        return notification.isRead ? "notification-warning-readed" : "notification-warning";
      default:
        return notification.isRead ? "notification-none-readed" : "notification-none";
    }
  };

  const getIcon = () => {
    if (!notification.showIcon || notification.type === NotificationType.None) return <></>;
    switch (notification.type) {
      case NotificationType.Error:
        return <Icon.ErrorSVG className={notification.isRead ? "notification-icon-readed" : "notification-icon"} />;
      case NotificationType.Info:
        return <Icon.InfoSVG className={notification.isRead ? "notification-icon-readed" : "notification-icon"} />;
      case NotificationType.Success:
        return (
          <Icon.CheckCircleSVG className={notification.isRead ? "notification-icon-readed" : "notification-icon"} />
        );
      case NotificationType.Warning:
        return <Icon.WarningSVG className={notification.isRead ? "notification-icon-readed" : "notification-icon"} />;
    }
  };

  const navigateTo = () => {
    navigate(notification.link!);
    dispatch(changeMainState({ showNotification: false }));
    updateNotify({ ...notification, isRead: true })
  };

  const showDate = () => {
    const data = new Date(notification.date);
    if (data.toFormatMoment("DD.MM.yyyy") === new Date().toFormatMoment("DD.MM.yyyy")) {
      return (
        <Label
          wrap
          fontSize="xsml"
          fontColor={notification.type === NotificationType.None || notification.isRead ? "primary" : "black"}
          content={data.toFormatMoment("HH:mm:ss")}
        />
      );
    }
    return (
      <Label
        wrap
        fontSize="xsml"
        fontColor={notification.type === NotificationType.None || notification.isRead ? "primary" : "black"}
        content={data.toFormatMoment("DD MMM yyyy r. HH:mm:ss")}
      />
    );
  };

  return (
    <div className={`${getStyle()} width-fill d-flex fd-c gap-2 notification-manager`}>
      <div className="width-fill d-flex gap-8 ai-center width-fill">
        {getIcon()}
        <div className="d-flex fd-c width-fill">
          <Label
            wrap
            fontSize="sml"
            fontStyle="bold"
            fontColor={notification.type === NotificationType.None || notification.isRead ? "primary" : "black"}
            content={notification.title}
          />
          {showDate()}
        </div>
        {!notification.isRead && (
          <Button
            sizeFrame="sml"
            kind="text"
            toolTipPosition="left"
            toolTip={"Oznacz jako odczytane"}
            icon={<Icon.MarkEmailUnreadSVG className="c-red" />}
            onClick={() => updateNotify({ ...notification, isRead: true })}
          />
        )}
        <Button
          sizeFrame="sml"
          kind="text"
          toolTipPosition="left"
          toolTip={"Usuń"}
          icon={<Icon.DeleteSVG className={notification.isRead ? "c-primary" : "c-black"} />}
          onClick={() => deleteNotify(notification)}
        />
      </div>
      <div className="d-flex pad-4">
        <Label
          fontSize="sm"
          wrap
          fontStyle="semibold"
          fontColor={notification.type === NotificationType.None || notification.isRead ? "primary" : "black"}
          content={notification.content}
        />
      </div>
      {notification.link && (
        <div className="d-flex pad-4" style={{ marginLeft: "auto" }}>
          <Label
            fontSize="sm"
            wrap
            fontStyle="bold"
            islink
            fontColor={notification.type === NotificationType.None || notification.isRead ? "primary" : "black"}
            content={"Zobacz więcej..."}
            style={{ marginLeft: "auto" }}
            onClick={navigateTo}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationManagerItemComponent;
