import React from "react";
import { SearchState } from "models/new/State";
import { useSelector } from "react-redux";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";

const SearchProductInfoDescriptionComponent: React.FC = () => {
  const searchState: SearchState = useSelector((state: any) => state.search);

  return (
    <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
      <div className="d-flex fd-c gap-16">
        <Label fontSize="lg" fontStyle="semibold" content="Opis produktu" />
        <Label fontSize="sm" wrap fontStyle="regular" content={searchState.supplierProduct?.description} />
      </div>
    </InfoBox>
  );
};

export default SearchProductInfoDescriptionComponent;
