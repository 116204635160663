import React from "react";
import { FontColor, FontStyle, FontSize } from "models/new/Dictionaries";

export interface StandardLabelProps {  
  fontStyle?: FontStyle;
  fontSize?: FontSize;
  fontColor?: FontColor;
  pointer?: boolean;
}


export interface LabelProps extends StandardLabelProps, Omit<React.LabelHTMLAttributes<HTMLLabelElement>, 'color'> {
  islink?: boolean;
  wrap?: boolean;
}

export const Label: React.FC<LabelProps> = (props) => {
  const getStyle = (): string => {
    let style = "m-label ";

    switch (props.fontSize) {
      case "xxsml":
        style += "s-xxsmall ";
        break;
      case "xsml":
        style += "s-xsmall ";
        break;
      case "sml":
        style += "s-smaller ";
        break;
      case "sm":
        style += "s-small ";
        break;
      case "md":
        style += "s-medium ";
        break;
      case "lg":
        style += "s-large ";
        break;
      case "xl":
        style += "s-xl ";
        break;
      case "xxl":
        style += "s-xxl ";
        break;
      default:
        style += "s-medium ";
        break;
    }

    switch (props.fontStyle) {
      case "italic":
        style += "f-italic ";
        break;
      case "regular":
        style += "f-regular ";
        break;
      case "medium":
        style += "f-medium ";
        break;
      case "medium-italic":
        style += "f-medium-italic ";
        break;
      case "semibold":
        style += "f-semibold ";
        break;
      case "semibold-italic":
        style += "f-semibold-italic ";
        break;
      case "bold":
        style += "f-bold ";
        break;
      case "bold-italic":
        style += "f-bold-italic ";
        break;
      case "extra-bold":
        style += "f-extra-bold ";
        break;
      case "extra-bold-italic":
        style += "f-extra-bold-italic ";
        break;
      default:
        style += "f-regular ";
        break;
    }

    switch (props.fontColor) {
      case "primary":
        style += " c-primary ";
        break;
      case "secondary":
        style += " c-secondary ";
        break;
      case "tertiary":
        style += " c-tertiary ";
        break;
      case "green":
        style += " c-green ";
        break;
      case "yellow":
        style += " c-yellow ";
        break;
      case "orange":
        style += " c-orange ";
        break;
      case "blue":
        style += " c-blue ";
        break;
      case "red":
        style += " c-red  ";
        break;
      case "gray":
        style += " c-gray ";
        break;
      case "white":
        style += " c-white ";
        break;
      case "black":
        style += " c-black ";
        break;
        case "tooltip":
          style += " c-tooltip ";
          break;
      default:
        style += " c-primary ";
        break;
    }

    if (props.islink === true) {
      style += " link ";
    }
    if (props.wrap === true) {
      style += " label-wrap";
    }
    if (props.pointer === true) {
      style += " label-pointer";
    }

    if (props.className) {
      return `${style} ${props.className}`;
    } else {
      return style;
    }
  };

  return (
    <label {...props} className={getStyle()}>
      {props.content}
    </label>
  );
};
