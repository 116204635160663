import React from "react";
import * as Icon from "commons/iconManager";
import { useURLParams } from "hooks/useURLParams";
import { Label } from "controls/Label";
import { GroceryTabProps } from "./GroceryManagerComponent";
import EmptyComponent from "components/EmptyComponent";
import { Product } from "models/new/Product";
import { showCouponOrderProduct, showSavedOrderProduct, showValueOrderProduct } from "commons/productManager";
import { useWindowSize } from "hooks/useWindowSize";

const GroceryHistoryComponent: React.FC<GroceryTabProps> = (props) => {
  const [orderProducts, setOrderProducts] = React.useState<Product[]>([]);
  const { params } = useURLParams();
  const { isMobile } = useWindowSize();
  const action = params.get("action") ?? "";

  React.useEffect(() => {
    downloadData();
  }, []);

  const downloadData = async () => {
    if (action === "create") {
    } else {
      /*await SaleApi.getOrderProductsByProductId(id)
        .then((value) => setOrderProducts(value))
        .catch((err) => alert(err));*/
    }
  };

  const getItem = (orderProducts: Product): JSX.Element => {
    return (
      <div className="d-flex ai-center gap-8 pad-8 border-bottom width-fill" style={{ maxWidth: "500px" }}>
        <img className="image-on-order" src={orderProducts.photoUrl} />
        <div className="d-flex fd-c ai-s-flex jc-center gap-4">
          <Label fontSize="sml" fontStyle="bold" content="Data zamówienia" />
          <Label
            fontSize="sml"
            fontStyle="bold"
            content={new Date(orderProducts.createdAt ?? "").toFormatMoment("MMM DD, yyyy ")}
          />
          <Label
            fontSize="sml"
            fontStyle="semibold"
            content={new Date(orderProducts.createdAt ?? "").toFormatMoment("HH:mm")}
          />
        </div>
        {showCouponOrderProduct(orderProducts)}
        {showSavedOrderProduct(orderProducts)}
        {showValueOrderProduct(orderProducts)}
        {/*<Button
          style={{ marginLeft: "auto" }}
          sizeFrame="sm"
          kind="text"
          icon={<Icon.VisibilitySVG />}
          toolTip={"Podgląd zamówienia"}
          onClick={() => navigateTo("sale_manager", `?id=${orderProducts.orderId}`)}
        />*/}
      </div>
    );
  };

  if (isMobile) {
    return (
      <div
        id={props.id}
        className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill"
        style={{ maxWidth: "516px" }}
      >
        <Label fontStyle="bold" fontSize="md" content="Historia zamówień" />
        {orderProducts.length === 0 ? (
          <EmptyComponent message="Historia zamówień jest pusta" icon={<Icon.OrdersSVG />} />
        ) : (
          <div className="frame-scroll pad-r-8" style={{ maxHeight: "500px", maxWidth: "516px" }}>
            {orderProducts.map((order) => {
              return getItem(order);
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "516px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Historia zamówień" />
      {orderProducts.length === 0 ? (
        <EmptyComponent message="Historia zamówień jest pusta" icon={<Icon.OrdersSVG />} />
      ) : (
        <div className="frame-scroll pad-r-8" style={{ maxHeight: "500px", maxWidth: "516px" }}>
          {orderProducts.map((order) => {
            return getItem(order);
          })}
        </div>
      )}
    </div>
  );
};

export default GroceryHistoryComponent;
