import React from "react";
import * as ConversationApi from "api/ConversationApi";
import { Slider } from "controls/Slider";
import BasketManagerComponent from "../../basket/components/BasketManagerComponent";
import { useDispatch, useSelector } from "react-redux";
import { BasketState, ConversationState, MainState, SearchState, UserState } from "models/new/State";
import {
  changeMainState,
  changeOpenMenu,
  changeTheme,
  setShoppingBasket,
  setShowMail,
  setShowNotification,
  setShowPost,
} from "reducers/mainReducer";
import { ToolTip } from "controls/ToolTip";
import MessagerManagerComponent from "components/messager/MessagerManagerComponent";
import { useLogin } from "hooks/useLogin";
import NotificationManagerComponent from "components/notification/NotificationManagerComponent";
import { useURLParams } from "hooks/useURLParams";
import { getConnection } from "api/SignalRApi";
import { addConversationSignal, getMessageConversation, setConversation } from "reducers/conversationReducer";
import { setBaskets } from "reducers/basketReducer";
import { useWindowSize } from "hooks/useWindowSize";
import { setSuppliersPost } from "reducers/searchReducer";
import MenuMobileComponent from "./mobile/MenuMobileComponent";
import PostManagerComponent from "src/pages/wall/components/PostManagerComponent";
import SearchMobileLocationComponent from "src/pages/search/components/mobile/SearchMobileLocationComponent";
import SearchMobileProductComponent from "src/pages/search/components/mobile/SearchMobileProductComponent";
import SearchMobileFilterComponent from "src/pages/search/components/mobile/SearchMobileFilterComponent";
import SearchMobilePostComponent from "src/pages/search/components/mobile/SearchMobilePostComponent";
import OrderMobileFilterComponent from "src/pages/order/components/mobile/OrderMobileFilterComponent";
import SalesMobileFilterComponent from "src/pages/sale/components/mobile/SalesMobileFilterComponent";
import { ChatMessage } from "models/new/ChatMessage";
import { Chat } from "models/new/Chat";
import { Notify } from "models/new/Notify";
import OrderRatingComponent from "src/pages/order/components/OrderRatingComponent";
import SubscriptionWizardComponent from "src/pages/profile/components/SubscriptionWizardComponent";
import { useNotify } from "hooks/useNotify";

const MenuSlidersComponent: React.FC = () => {
  const { params } = useURLParams();
  const { isMobile } = useWindowSize();
  const { addNotify } = useNotify();

  const { resetPassword, checkToken } = useLogin();

  const searchState: SearchState = useSelector((state: any) => state.search);
  const mainState: MainState = useSelector((state: any) => state.main);
  const userState: UserState = useSelector((state: any) => state.user);
  const basketState: BasketState = useSelector((state: any) => state.basket);
  const conversationState: ConversationState = useSelector((state: any) => state.conversation);

  const dispatch = useDispatch();

  const basketIconRef = React.useRef() as React.MutableRefObject<SVGSVGElement>;
  const mailIconRef = React.useRef() as React.MutableRefObject<SVGSVGElement>;
  const notifyIconRef = React.useRef() as React.MutableRefObject<SVGSVGElement>;

  React.useEffect(() => {
    checkToken();
    const resetCode = params.get("resetCode");
    if (resetCode) {
      setTimeout(() => {
        resetPassword();
      }, 200);
    }
  }, []);

  const changeThemeStyle = React.useCallback((theme: string | null) => {
    if (theme === "dark") {
      document.body.classList.replace("light", "dark");
      localStorage.setItem("theme", "dark");
      dispatch(changeTheme("dark"));
    } else {
      document.body.classList.replace("dark", "light");
      localStorage.setItem("theme", "light");
      dispatch(changeTheme("light"));
    }
  }, []);

  React.useEffect(() => {
    const theme = localStorage.getItem("theme");
    changeThemeStyle(theme);
  }, [dispatch]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (userState.user) {
        !userState.user.isSupplier && setSignalR();
        downloadConversation(userState.user.id);
      }
    }, 1500);
    return () => clearTimeout(timeout);
  }, [userState.user?.id]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (userState.supplier?.id) {
        setSignalR();
      }
    }, 1500);
    return () => clearTimeout(timeout);
  }, [userState.supplier]);

  React.useEffect(() => {
    if (!isMobile) {
      ToolTip.show(basketIconRef.current, "Koszyk produktów", "left");
      ToolTip.show(mailIconRef.current, "Wiadomości", "left");
      ToolTip.show(notifyIconRef.current, "Powiadomienia", "left");
    }

    if (basketState.baskets.length === 0) {
      const baskets = localStorage.getItem("baskets");
      if (baskets) {
        try {
          const parsedBaskets = JSON.parse(baskets);
          dispatch(setBaskets(parsedBaskets));
        } catch (error) {
          console.error("Failed to parse baskets cookie: ", error);
        }
      }
    }
  }, [userState.token]);

  React.useEffect(() => {
    if (isMobile && conversationState.activeConversations.length > 0) {
      dispatch(changeMainState({ showMail: true }));
    }
  }, [conversationState.activeConversations]);

  const onHide = () => {
    dispatch(setShowPost(false));
    dispatch(setSuppliersPost(undefined));
  };

  const downloadConversation = async (id: string) => {
    await ConversationApi.getUserConversations(id)
      .then((values) => {
        dispatch(setConversation(values));
      })
      .catch((err: Response) => {
        console.log(`SearchManagerComponent - ${err}`);
      });
  };

  const setSignalR = async () => {
    let connection: signalR.HubConnection = await getConnection(userState.user?.id!, userState.supplier?.id ?? "");
    connection.off("ReceiveMessage");
    connection.on("ReceiveMessage", (message: ChatMessage) => {
      dispatch(getMessageConversation(message));
      const notifySound = document.getElementById("audio-new-message") as HTMLAudioElement;
      notifySound?.play();
    });
    connection.off("NewConversation");
    connection.on("NewConversation", (conversation: Chat) => {
      dispatch(addConversationSignal(conversation));
      const notifySound = document.getElementById("audio-new-conversation") as HTMLAudioElement;
      notifySound?.play();
    });
    connection.off("SendNotification");
    connection.on("SendNotification", (notify: Notify) => {
      addNotify(notify);
    });
  };

  return (
    <>
      <Slider
        show={mainState.showShoppingBasket}
        size={550}
        header={"Koszyk"}
        onHide={() => dispatch(setShoppingBasket(false))}
      >
        <BasketManagerComponent />
      </Slider>

      <Slider
        show={mainState.showNotification}
        size={500}
        header={"Powiadomienia"}
        onHide={() => dispatch(setShowNotification(false))}
      >
        <NotificationManagerComponent />
      </Slider>

      <Slider show={mainState.showMail} size={500} header={"Wiadomości"} onHide={() => dispatch(setShowMail(false))}>
        <MessagerManagerComponent />
      </Slider>

      <Slider show={mainState.menuOpened && isMobile} header={"Menu"} onHide={() => dispatch(changeOpenMenu())}>
        <MenuMobileComponent />
      </Slider>

      <Slider
        show={mainState.showLocation && isMobile}
        header={"Lokalizacja"}
        onHide={() => dispatch(changeMainState({ showLocation: false }))}
      >
        <SearchMobileLocationComponent />
      </Slider>

      <Slider
        show={mainState.showFilter && isMobile}
        header={"Panel filtrowania"}
        onHide={() => dispatch(changeMainState({ showFilter: false }))}
      >
        <SearchMobileFilterComponent />
      </Slider>

      <Slider
        show={mainState.showSearch && isMobile}
        header={"Wyszukiwarka"}
        onHide={() => dispatch(changeMainState({ showSearch: false }))}
      >
        <SearchMobileProductComponent />
      </Slider>

      <Slider
        show={mainState.showBlog && isMobile}
        header={"Posty"}
        onHide={() => dispatch(changeMainState({ showBlog: false }))}
      >
        <SearchMobilePostComponent />
      </Slider>

      <Slider
        show={mainState.showFilterOrder && isMobile}
        header={"Filtruj zamówienia"}
        onHide={() => dispatch(changeMainState({ showFilterOrder: false }))}
      >
        <OrderMobileFilterComponent />
      </Slider>

      <Slider
        show={mainState.showFilterSale && isMobile}
        header={"Filtruj sprzedaży"}
        onHide={() => dispatch(changeMainState({ showFilterSale: false }))}
      >
        <SalesMobileFilterComponent />
      </Slider>

      <Slider
        show={mainState.showSubscriptionWizzard && isMobile}
        header={"Aktywacja subskrypcji"}
        onHide={() => dispatch(changeMainState({ showSubscriptionWizzard: false }))}
      >
        <SubscriptionWizardComponent />
      </Slider>

      <Slider
        className={isMobile ? "" : "bg-opacity"}
        show={mainState.showPost}
        header={"Post"}
        onHide={onHide}
        fontColor={isMobile ? undefined : "white"}
      >
        <PostManagerComponent post={searchState.supplierPost} />
      </Slider>

      <Slider
        show={mainState.showOrderRating}
        header={"Ocena i komentarz"}
        size={500}
        onHide={() => dispatch(changeMainState({ showOrderRating: false }))}
      >
        <OrderRatingComponent />
      </Slider>
    </>
  );
};

export default MenuSlidersComponent;
