import React from "react";
import * as Icon from "commons/iconManager";
import CertificateManagerComponent from "components/CertificateManagerComponent";
import CertificateComponent from "components/CertificateComponent";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { Certificate } from "models/new/Certificate";
import { ModalDialog } from "controls/ModalDialog";
import { ProfileTabProps } from "./ProfileManagerComponent";
import { changeSupplier } from "reducers/userReducer";
import { useWindowSize } from "hooks/useWindowSize";
import MessageBox from "controls/MessageBox";

const ProfileCertificatesComponent: React.FC<ProfileTabProps> = (props) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const onEdit = async (certificate: Certificate) => {
    const result = await ModalDialog.show(
      <CertificateManagerComponent certificate={certificate} supplierId={userState.supplier!.id} />,
      "Edytuj certyfikat"
    );
    if (result.result === "Approve") {
      const clone = structuredClone(userState.supplier);
      if (clone?.certificates && clone.certificates.length > 0) {
        clone.certificates = clone.certificates.map((x) => (x.id === certificate.id ? result.value : x));
        dispatch(changeSupplier({ supplier: clone }));
      }
    }
  };
  const onDelete = async (cert: Certificate) => {
    const result = await ModalDialog.show(
      <MessageBox icon="Question" content={`Czy napewno chcesz usunąć certyfikat ${cert.name}?`} />,
      "",
      ["YesNo"]
    );
    if (result.result === "Yes") {
      const clone = structuredClone(userState.supplier);
      if (clone?.certificates && clone.certificates.length > 0) {
        clone.certificates = clone.certificates.filter((x) => x.id !== cert.id);
        dispatch(changeSupplier({ supplier: clone }));
      }
    }
  };
  const onAdd = async () => {
    const result = await ModalDialog.show(
      <CertificateManagerComponent supplierId={userState.supplier!.id} />,
      "Dodaj certyfikat"
    );
    if (result.result === "Approve") {
      const certificate: Certificate = { ...result.value, supplierId: userState.supplier?.id };
      const clone = structuredClone(userState.supplier);
      if (clone) {
        if (!clone.certificates) {
          clone.certificates = [];
        }
        clone.certificates.push(certificate);
        dispatch(changeSupplier({ supplier: clone }));
      }
    }
  };

  const getInfo = (): JSX.Element => {
    return (
      <div className="frame-main" style={{ width: "450px", height: "75vh" }}>
        <div className="frame-scroll gap-16">
          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Zapewnienie jakości" />
            <Label
              wrap
              content={`Certyfikaty produktu potwierdzają, że produkt został przetestowany i spełnia określone standardy jakości. Dla konsumentów jest to gwarancja, że produkt jest wysokiej jakości i będzie działał zgodnie z oczekiwaniami.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Bezpieczeństwo" />
            <Label
              wrap
              content={`Wiele certyfikatów koncentruje się na aspektach bezpieczeństwa produktu. To szczególnie ważne w przypadku artykułów spożywczych, zabawek, elektroniki i innych produktów, które mogą bezpośrednio wpływać na zdrowie i bezpieczeństwo użytkowników.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Zgodność z regulacjami" />
            <Label
              wrap
              content={`Certyfikaty mogą świadczyć o tym, że produkt jest zgodny z lokalnymi, krajowymi lub międzynarodowymi przepisami i standardami. Dla producentów i dystrybutorów jest to kluczowe, aby móc wprowadzać produkty na różne rynki.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Budowanie zaufania" />
            <Label
              wrap
              content={`Certyfikaty produktu budują zaufanie między producentami a konsumentami. Klienci, widząc znane i szanowane certyfikaty, mogą czuć się pewniej, dokonując zakupu, wiedząc, że produkt jest bezpieczny, etyczny i wysokiej jakości.`}
            />
          </div>

          <div className="d-flex fd-c gap-4">
            <Label fontSize="sm" fontStyle="extra-bold" content="Przewaga konkurencyjna" />
            <Label
              wrap
              content={`Produkty posiadające certyfikaty mogą wyróżniać się na rynku, oferując producentom przewagę konkurencyjną. Certyfikowane produkty mogą być postrzegane jako lepsze lub bardziej pożądane niż te bez certyfikatów, co może przekładać się na wyższe ceny i lepszą sprzedaż.`}
            />
          </div>
        </div>
      </div>
    );
  };

  const showInfo = async () => {
    await ModalDialog.show(getInfo(), "Cretyfikaty");
  };

  if (isMobile) {
    return (
      <div id={props.id} className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill">
        <div className="d-flex ai-center">
          <Label fontStyle="bold" fontSize="md" content="Certyfikaty" />
          <Button
            sizeFrame="sml"
            kind="transparent"
            icon={<Icon.InfoSVG />}
            toolTip={
              "Certyfikaty produktu potwierdzają, że produkt został przetestowany i spełnia określone standardy jakości. Kliknij aby zobaczyć więcej..."
            }
            toolTipPosition="right"
            onClick={showInfo}
          />
        </div>
        {userState.supplier?.certificates?.map((certificate) => {
          return <CertificateComponent certificate={certificate} onEdit={onEdit} onDelete={onDelete} />;
        })}
        <Button
          sizeFrame="sm"
          kind="primary"
          label="Dodaj certyfikat"
          fontStyle="bold"
          icon={<Icon.AddSVG />}
          onClick={onAdd}
        />
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <div className="d-flex">
        <Label fontStyle="bold" fontSize="xl" content="Certyfikaty" />
        <Button
          sizeFrame="sml"
          kind="transparent"
          icon={<Icon.InfoSVG />}
          toolTip={
            "Certyfikaty produktu potwierdzają, że produkt został przetestowany i spełnia określone standardy jakości. Kliknij aby zobaczyć więcej..."
          }
          toolTipPosition="right"
          onClick={showInfo}
        />
      </div>
      {userState.supplier?.certificates?.map((certificate) => {
        return <CertificateComponent certificate={certificate} onEdit={onEdit} onDelete={onDelete} />;
      })}
      <Button
        sizeFrame="sm"
        kind="primary"
        label="Dodaj certyfikat"
        fontStyle="bold"
        icon={<Icon.AddSVG />}
        onClick={onAdd}
      />
    </div>
  );
};

export default ProfileCertificatesComponent;
