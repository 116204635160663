import React from "react";
import ProfileManagerComponent from "./components/ProfileManagerComponent";

const ProfilePage: React.FC = () => {
  return (
    <div className="f-fill fd-c">
      <ProfileManagerComponent/>
    </div>
  );
};

export default ProfilePage;
