import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";
import { useURLParams } from "hooks/useURLParams";
import { Label } from "controls/Label";
import { GroceryTabProps } from "./GroceryManagerComponent";
import { useDispatch, useSelector } from "react-redux";
import { ProductState, UserState } from "models/new/State";
import { InputNumber } from "controls/InputNumber";
import SaleCouponComponent from "components/SaleCouponComponent";
import { SaleCoupon } from "models/new/SaleCoupon";
import { updateProduct } from "reducers/productReducer";
import { calculatePercentFromOneHundred, calculateValueByPercent } from "commons/numberManager";
import { ModalDialog } from "controls/ModalDialog";
import SaleCouponManagerComponent from "components/SaleCouponManagerComponent";
import { useWindowSize } from "hooks/useWindowSize";
import MessageBox from "controls/MessageBox";

const GrocerySaleComponent: React.FC<GroceryTabProps> = (props) => {
  const productState: ProductState = useSelector((state: any) => state.product);
  const userState: UserState = useSelector((state: any) => state.user);
  const [percent, setPercent] = React.useState<number>();
  const dispatch = useDispatch();
  const { params } = useURLParams();
  const { isMobile } = useWindowSize();
  const id = params.get("id") ?? "";
  const action = params.get("action") ?? "";

  React.useEffect(() => {
    setPercent(calculatePercentFromOneHundred(productState.selected?.price, productState.selected?.salePrice, 0));
  }, [productState.selected]);

  const onEdit = async (coupon: SaleCoupon) => {
    const result = await ModalDialog.show(
      <SaleCouponManagerComponent
        productId={productState.selected!.id}
        supplierId={userState.supplier!.id}
        coupon={coupon}
      />,
      "Edytuj kupon"
    );
    if (result.result === "Approve") {
      const clone = structuredClone(productState.selected);
      if (clone?.saleCoupons && clone.saleCoupons.length > 0) {
        clone.saleCoupons = clone.saleCoupons.map((x) => (x.id === coupon.id ? result.value : x));
        dispatch(updateProduct({ product: clone }));
      }
    }
  };
  const onDelete = async (coupon: SaleCoupon) => {
    const result = await ModalDialog.show(
      <MessageBox icon="Question" content={`Czy napewno chcesz usunąć kupon ${coupon.code.toLocaleUpperCase()}?`} />,
      "",
      ["YesNo"]
    );
    if (result.result === "Yes") {
      const clone = structuredClone(productState.selected);
      if (clone?.saleCoupons && clone.saleCoupons.length > 0) {
        clone.saleCoupons = clone.saleCoupons.filter((x) => x.id !== coupon.id);
        dispatch(updateProduct({ product: clone }));
      }
    }
  };
  const onAdd = async () => {
    const result = await ModalDialog.show(
      <SaleCouponManagerComponent productId={productState.selected!.id} supplierId={userState.supplier!.id} />,
      "Dodaj kupon"
    );
    if (result.result === "Approve") {
      const clone = structuredClone(productState.selected);
      if (clone) {
        if (!clone.saleCoupons) {
          clone.saleCoupons = [];
        }
        clone?.saleCoupons?.push(result.value);
        dispatch(updateProduct({ product: clone }));
      }
    }
  };

  const recalculateByPrice = (price: number) => {
    dispatch(
      updateProduct({
        product: { price: isNaN(price) ? undefined : price, salePrice: undefined },
        errors: { price: "", salePrice: "" },
      })
    );
  };
  const recalculateBySalePercent = (salePrice: number) => {
    dispatch(
      updateProduct({
        product: { salePrice: isNaN(salePrice) ? undefined : salePrice },
        errors: { price: "", salePrice: "" },
      })
    );
  };
  const recalculateByPercent = (percent?: number) => {
    const price = productState.selected?.price;
    if (!percent) {
      dispatch(
        updateProduct({
          product: { salePrice: undefined },
          errors: { price: "", salePrice: "" },
        })
      );
    } else if (price) {
      const salePrice = calculateValueByPercent(price, 100 - percent, 2);
      dispatch(
        updateProduct({
          product: { salePrice: isNaN(percent) ? undefined : salePrice },
          errors: { price: "", salePrice: "" },
        })
      );
    }
  };

  if (isMobile) {
    return (
      <div
        id={props.id}
        className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill"
        style={{ maxWidth: "500px" }}
      >
        <div className="d-flex ai-center">
          <Label fontStyle="bold" fontSize="md" content="Cena i promocje" />
          <Button
            sizeFrame="sml"
            kind="transparent"
            icon={<Icon.InfoSVG />}
            toolTip={"Ustaw cene produktu. Dodatkowo możesz ustawić cene promocyjną lub procentowy rabat dla produktu"}
            toolTipPosition="right"
          />
        </div>
        <InputNumber
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Cena"
          unit="zł"
          placeholder="np. 3,69"
          min={0}
          step={0.5}
          fixed={2}
          disabled={action === "preview"}
          error={productState.valideError.price}
          value={productState.selected?.price}
          onSubmit={(e) => recalculateByPrice(e.currentTarget.valueAsNumber)}
        />
        <InputNumber
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Cena promocyjna"
          placeholder="np. 2,99"
          min={0}
          step={0.5}
          fixed={2}
          error={productState.valideError.salePrice}
          disabled={action === "preview" || !productState.selected?.price || productState.selected?.price === 0}
          value={productState.selected?.salePrice}
          unit="zł"
          onSubmit={(e) => recalculateBySalePercent(e.currentTarget.valueAsNumber)}
        />
        <InputNumber
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Rabat procentowy"
          placeholder="np. 5"
          disabled={action === "preview" || !productState.selected?.price || productState.selected?.price === 0}
          value={percent}
          unit="%"
          onSubmit={(e) => recalculateByPercent(e.currentTarget.valueAsNumber)}
        />
       
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <div className="d-flex">
        <Label fontStyle="bold" fontSize="xl" content="Cena i promocje" />
        <Button
          sizeFrame="sml"
          kind="transparent"
          icon={<Icon.InfoSVG />}
          toolTip={"Ustaw cene produktu. Dodatkowo możesz ustawić cene promocyjną lub procentowy rabat dla produktu"}
          toolTipPosition="right"
        />
      </div>
      <InputNumber
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Cena"
        unit="zł"
        placeholder="np. 3,69"
        min={0}
        step={0.5}
        fixed={2}
        disabled={action === "preview"}
        error={productState.valideError.price}
        value={productState.selected?.price}
        onSubmit={(e) => recalculateByPrice(e.currentTarget.valueAsNumber)}
      />
      <InputNumber
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Cena promocyjna"
        placeholder="np. 2,99"
        min={0}
        step={0.5}
        fixed={2}
        error={productState.valideError.salePrice}
        disabled={action === "preview" || !productState.selected?.price || productState.selected?.price === 0}
        value={productState.selected?.salePrice}
        unit="zł"
        onSubmit={(e) => recalculateBySalePercent(e.currentTarget.valueAsNumber)}
      />
      <InputNumber
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Rabat procentowy"
        placeholder="np. 5"
        min={0}
        max={99}
        disabled={action === "preview" || !productState.selected?.price || productState.selected?.price === 0}
        value={percent}
        unit="%"
        onSubmit={(e) => recalculateByPercent(e.currentTarget.valueAsNumber)}
      />
    </div>
  );
};

export default GrocerySaleComponent;


/*

      <div className="d-flex">
        <Label fontSize="xl" fontStyle="bold" content="Kupony promocyjne" />
        <Button
          sizeFrame="sml"
          kind="transparent"
          icon={<Icon.InfoSVG />}
          toolTip={
            "Kupony promocyjne to narzędzia marketingowe, które umożliwiają klientom zakup produktów lub usług po obniżonych cenach. Są one często stosowane do przyciągnięcia nowych klientów, zachęcenia do zakupów lub nagrodzenia lojalności."
          }
          toolTipPosition="right"
        />
      </div>
      {productState.selected?.saleCoupons?.map((coupon) => {
        return (
          <SaleCouponComponent coupon={coupon} onEdit={onEdit} onDelete={onDelete} disabled={action === "preview"} />
        );
      })}
      <Button
        sizeFrame="sm"
        kind="primary"
        label="Dodaj kupon"
        fontStyle="bold"
        disabled={action === "preview"}
        icon={<Icon.AddSVG />}
        onClick={onAdd}
      />
*/

/*
 <div className="d-flex ai-center">
          <Label fontSize="md" fontStyle="bold" content="Kupony promocyjne" />
          <Button
            sizeFrame="sml"
            kind="transparent"
            icon={<Icon.InfoSVG />}
            toolTip={
              "Kupony promocyjne to narzędzia marketingowe, które umożliwiają klientom zakup produktów lub usług po obniżonych cenach. Są one często stosowane do przyciągnięcia nowych klientów, zachęcenia do zakupów lub nagrodzenia lojalności."
            }
            toolTipPosition="right"
          />
        </div>
        {productState.selected?.saleCoupons?.map((coupon) => {
          return (
            <SaleCouponComponent coupon={coupon} onEdit={onEdit} onDelete={onDelete} disabled={action === "preview"} />
          );
        })}
        <Button
          sizeFrame="sm"
          kind="primary"
          label="Dodaj kupon"
          fontStyle="bold"
          disabled={action === "preview"}
          icon={<Icon.AddSVG />}
          onClick={onAdd}
        />
*/