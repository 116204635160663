import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { ProfileTabProps } from "./ProfileManagerComponent";
import InfoBox from "controls/InfoBox";
import { Button } from "controls/Button";
import { ModalDialog } from "controls/ModalDialog";
import { Tag } from "controls/Tag";
import ProfileSelectAddressComponent from "./ProfileSelectAddressComponent";
import { changeUserState } from "reducers/userReducer";
import { generateGUID } from "commons/stringManager";
import { Address } from "models/new/Address";
import { Supplier } from "models/new/Supplier";
import AddressComponent from "components/AddressComponent";
import OpenHourComponent from "components/OpenHourComponent";
import { useWindowSize } from "hooks/useWindowSize";

const ProfilePointsComponent: React.FC<ProfileTabProps> = (props) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const showMap = async (id?: string | number) => {
    let user = structuredClone(userState.supplier) as Supplier;
    const address = user.addresses?.find((x) => x.id === id);
    const result = await ModalDialog.show(<ProfileSelectAddressComponent address={address} />, "Wskaż adres");
    if (result.result === "Approve") {
      const adres = result.value as Address;
      adres.isMainAddress = false;
      if (!adres.id) {
        adres.id = generateGUID();
      }
      if (!user?.addresses) {
        user = { ...user, addresses: [] };
      }
      user.addresses = user.addresses?.filter((x) => x.id !== id);
      adres.openHours = address?.openHours;
      user?.addresses?.push(adres);
      dispatch(changeUserState({ supplier: user }));
    }
  };

  const deleteAddress = (id: string | number) => {
    let user = structuredClone(userState.supplier) as Supplier;
    user.addresses = user.addresses?.filter((x) => x.id !== id);
    dispatch(changeUserState({ supplier: user }));
  };

  if (isMobile) {
    return (
      <div id={props.id} className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill">
        <Label fontStyle="bold" fontSize="md" content="Dodatkowe lokalizacje" />
        <InfoBox className="width-fill border" backgroundColor="transparent" sizeFrame="sm">
          <div className="width-fill d-flex fd-r gap-8 ai-s-flex">
            <Icon.AddNavigationSVG className="icon-color" height={50} width={50} />
            <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
              <Tag sizeFrame="sml" kind="yellow" fontStyle="semibold" label="Opcjonalne" icon={<Icon.ErrorSVG />} />
              <Label wrap fontSize="md" fontStyle="bold" content="Dodaj lokalizacje punktu odbioru" />
              <Label
                wrap
                fontSize="sm"
                fontStyle="semibold"
                content="Można Cię spotkać w innej lokalizacji takiej jak np. targowisko?"
              />
              <Label
                wrap
                fontSize="sm"
                fontStyle="semibold"
                content="Udostępniając lokalizację swojego miejsca sprzedaży, możesz przyciągnąć więcej klientów zainteresowanych lokalnie uprawianymi, świeżymi produktami."
              />
              <Button
                style={{ marginLeft: "auto" }}
                kind="primary"
                sizeFrame="sml"
                label="Wybierz"
                fontStyle="bold"
                icon={<Icon.LocationOnSVG />}
                onClick={() => showMap()}
              />
            </div>
          </div>
        </InfoBox>
        {userState.supplier?.addresses
          ?.filter((x) => !x.isMainAddress)
          .map((address) => {
            return (
              <InfoBox className="width-fill border" backgroundColor="transparent" sizeFrame="sm">
                <div className="width-fill d-flex fd-r gap-8 ai-s-flex">
                  <Icon.AddNavigationSVG className="icon-color" height={50} width={50} />
                  <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
                    <Label wrap fontSize="md" fontStyle="bold" content="Dodatkowy punkt odbioru" />
                    <AddressComponent address={address} />
                    <div className="width-fill d-flex fd-r gap-8 ai-center jc-e-flex">
                      <Button
                        sizeFrame="sml"
                        kind="tertiary"
                        label="Usuń"
                        toolTip="Usuń adres"
                        icon={<Icon.DeleteSVG />}
                        onClick={() => deleteAddress(address.id)}
                      />
                      <Button
                        sizeFrame="sml"
                        kind="tertiary"
                        label="Edytuj"
                        toolTip="Edytuj adres"
                        icon={<Icon.EditSVG />}
                        onClick={() => showMap(address.id)}
                      />
                    </div>
                    <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
                      {address.openHours === undefined && (
                        <InfoBox
                          className="width-fill ai-center"
                          backgroundColor="red"
                          sizeFrame="xsm"
                          icon="Error"
                          iconSize="xsm"
                        >
                          <Label
                            wrap
                            pointer
                            fontSize="sm"
                            fontStyle="bold"
                            fontColor="black"
                            content="Uzupełnij godziny otwarcia w innym przypadku klienci nie będą wstanie złożyć zamówienia pod ten adres"
                          />
                        </InfoBox>
                      )}
                      <Label wrap fontSize="md" fontStyle="bold" content="Godziny otwarcia" />
                      <OpenHourComponent address={address} canEdit />
                    </div>
                  </div>
                </div>
              </InfoBox>
            );
          })}
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 width-fill border-bottom"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Dodatkowe lokalizacje" />
      <InfoBox className="width-fill border" backgroundColor="transparent" sizeFrame="sm">
        <div className="width-fill d-flex fd-r gap-8 ai-s-flex">
          <Icon.AddNavigationSVG className="icon-color" height={50} width={50} />
          <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
            <Tag sizeFrame="sml" kind="yellow" fontStyle="semibold" label="Opcjonalne" icon={<Icon.ErrorSVG />} />
            <Label wrap fontSize="md" fontStyle="bold" content="Dodaj lokalizacje punktu odbioru" />
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              content="Można Cię spotkać w innej lokalizacji takiej jak np. targowisko?"
            />
            <Label
              wrap
              fontSize="sm"
              fontStyle="semibold"
              content="Udostępniając lokalizację swojego miejsca sprzedaży, możesz przyciągnąć więcej klientów zainteresowanych lokalnie uprawianymi, świeżymi produktami."
            />
            <Button
              style={{ marginLeft: "auto" }}
              kind="primary"
              sizeFrame="sml"
              label="Wybierz"
              fontStyle="bold"
              icon={<Icon.LocationOnSVG />}
              onClick={() => showMap()}
            />
          </div>
        </div>
      </InfoBox>
      {userState.supplier?.addresses
        ?.filter((x) => !x.isMainAddress)
        .map((address) => {
          return (
            <InfoBox className="width-fill border" backgroundColor="transparent" sizeFrame="sm">
              <div className="width-fill d-flex fd-r gap-8 ai-s-flex">
                <Icon.AddNavigationSVG className="icon-color" height={50} width={50} />
                <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
                  <Label wrap fontSize="md" fontStyle="bold" content="Dodatkowy punkt odbioru" />
                  <AddressComponent address={address} />
                  <div className="width-fill d-flex fd-r gap-8 ai-center jc-e-flex">
                    <Button
                      sizeFrame="sml"
                      kind="tertiary"
                      label="Usuń"
                      toolTip="Usuń adres"
                      icon={<Icon.DeleteSVG />}
                      onClick={() => deleteAddress(address.id)}
                    />
                    <Button
                      sizeFrame="sml"
                      kind="tertiary"
                      label="Edytuj"
                      toolTip="Edytuj adres"
                      icon={<Icon.EditSVG />}
                      onClick={() => showMap(address.id)}
                    />
                  </div>
                  <div className="width-fill d-flex fd-c gap-8 ai-s-flex">
                    {address.openHours === undefined && (
                      <InfoBox
                        className="width-fill ai-center"
                        backgroundColor="red"
                        sizeFrame="xsm"
                        icon="Error"
                        iconSize="xsm"
                      >
                        <Label
                          wrap
                          pointer
                          fontSize="sm"
                          fontStyle="bold"
                          fontColor="black"
                          content="Uzupełnij godziny otwarcia w innym przypadku klienci nie będą wstanie złożyć zamówienia pod ten adres"
                        />
                      </InfoBox>
                    )}
                    <Label wrap fontSize="md" fontStyle="bold" content="Godziny otwarcia" />
                    <OpenHourComponent address={address} canEdit />
                  </div>
                </div>
              </div>
            </InfoBox>
          );
        })}
    </div>
  );
};

export default ProfilePointsComponent;
