import React from "react";

export interface GridColumnProps {
  header?: string;
  binding?: string;
  width?: string | number;
  alginHeader?: "start" | "center" | "end";
  verticalAlignHeader?: "top" | "middle" | "bottom";
  algin?: "start" | "center" | "end";
  verticalAlign?: "top" | "middle" | "bottom";
  sortableBy?: string;
  onDisplayContent?(item: any): JSX.Element;
}

export const GridColumn: React.FC<GridColumnProps> = (props) => {
  return <div>{props.header}</div>;
};
