import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";
import { useSelector } from "react-redux";
import { SaleState } from "models/new/State";
import { useNavigation } from "hooks/useNavigation";
import ProductSummaryOrderComponent from "components/ProductSummaryOrderComponent";
import SalesItemDetailsComponent from "../SalesItemDetailsComponent";
import { PopUp } from "controls/PopUp";

const SalesMobileManagerComponent: React.FC = () => {
  const { navigateTo } = useNavigation();
  const saleState: SaleState = useSelector((state: any) => state.sale);
  const btnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  if (!saleState.selected) return <></>;

  function goTo(tab: string): void {
    navigateTo("sale_manager", `?tab=${tab}&id=${saleState.selected!.id}`);
    const childElement = document.getElementById(tab);
    childElement?.scrollIntoView({ behavior: "smooth" });
  }
  const showMore = async () => {
    PopUp.show(
      btnRef.current,
      <div className="d-flex fd-c width-fill gap-8">
        <Button
          className="width-fill"
          sizeFrame="lg"
          kind="tertiary"
          label="Export"
          fontStyle="bold"
          icon={<Icon.ShareSVG />}
          onClick={() => alert("Funkcjonalność w budowie")}
        />
        <Button
          className="width-fill"
          sizeFrame="lg"
          kind="tertiary"
          label="Drukuj"
          fontStyle="bold"
          icon={<Icon.PrintSVG />}
          onClick={() => alert("Funkcjonalność w budowie")}
        />
      </div>
    );
  };

  return (
    <>
      <div id="top-page" className="f-page-top">
        <Button
          sizeFrame="md"
          kind="tertiary"
          fontStyle="bold"
          icon={<Icon.ArrowBackSVG />}
          onClick={() => navigateTo("sales")}
        />
        <div className="f-scroll-hidden-r gap-8">
          <Button kind="tertiary" sizeFrame="md" label="Szczegóły zamówienia" onClick={() => goTo("details")} />
          <Button kind="tertiary" sizeFrame="md" label="Kupione produkty" onClick={() => goTo("products_summary")} />
        </div>
      </div>
      <div className="f-page-center fd-c gap-32" style={{ marginBottom: "78px" }}>
        <SalesItemDetailsComponent sale={saleState.selected} />
        <ProductSummaryOrderComponent
          products={saleState.selected.products}
          deliveryCost={saleState.selected.deliveryCost}
          ratings={saleState.selected.productRatings}
        />
        <Button
          ref={btnRef}
          sizeFrame="lg"
          kind="primary"
          style={{ position: "fixed", bottom: "16px", right: "16px" }}
          fontStyle="bold"
          fontSize="md"
          icon={<Icon.MoreVertSVG />}
          onClick={showMore}
        />
      </div>
    </>
  );
};

export default SalesMobileManagerComponent;
