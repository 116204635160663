import InfoBox from "controls/InfoBox";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { Button } from "controls/Button";

const LandingMainClientInfoComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  const goToJoin = () => {
    const cta = document.getElementById("cta-landing");
    cta && cta.scrollIntoView({ behavior: "smooth" });
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c ai-center gap-32 pad-4">
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-l" src="/img/supplier_products.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-16 mag-r-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label
                fontSize="xl"
                fontStyle="extra-bold"
                wrap
                content="Wybieraj lokalnie, 
jedz naturalnie!"
              />
              <Label
                wrap
                fontStyle="semibold"
                content="Poszukujesz zdrowych i lokalnych produktów? Załóż konto na naszym portalu i zamawiaj bezpośrednio od sprawdzonych rolników z Twojej okolicy!
Rejestracja i korzystanie z aplikacji dla kupujących jest całkowicie darmowe!"
              />
            </div>
          </InfoBox>
        </div>
        <Label
          style={{ textAlign: "center" }}
          wrap
          fontStyle="extra-bold"
          fontSize="xl"
          content="Dołącz do wielbicieli zdrowej żywności!"
        />
      </div>
    );
  }

  return (
    <div className="d-flex fd-c gap-32 ai-center" style={{ maxWidth: "1136px" }}>
      <div className="d-flex gap-32" style={{ maxWidth: "1136px", marginBlock: "128px" }}>
        <div className="d-flex fd-c gap-16 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label
            fontSize="xxl"
            fontStyle="extra-bold"
            wrap
            content={`Wybieraj lokalnie, 
jedz naturalnie!`}
          />
          <Label
            fontSize="lg"
            wrap
            fontStyle="semibold"
            content={`Poszukujesz zdrowych i lokalnych produktów? Załóż konto na naszym portalu i zamawiaj bezpośrednio od sprawdzonych rolników z Twojej okolicy!
Rejestracja i korzystanie z aplikacji dla kupujących jest całkowicie darmowe!
`}
          />
          <Button
            className="mag-t-16"
            sizeFrame="lg"
            kind="primary"
            fontStyle="bold"
            fontSize="lg"
            label="Dołącz do nas!"
            onClick={goToJoin}
          />
        </div>
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-cta" src="/img/supplier_products.jpg" />
        </div>
      </div>
      <Label wrap fontStyle="extra-bold" fontSize="xxl" content="Dołącz do wielbicieli zdrowej żywności!" />
    </div>
  );
};

export default LandingMainClientInfoComponent;
