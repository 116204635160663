import React from "react";
import * as Icon from "commons/iconManager";

interface RatingStarProps {
  rating?: number;
  onClick?: (rate: number) => void;
  hover: boolean;
}

const StarSelectionMobileComponent: React.FC<RatingStarProps> = ({ rating, onClick, hover }) => {
  const [rate, setRate] = React.useState<number | undefined>();

  React.useEffect(() => {
    setRate(rating);
  }, [rating]);

  const onMouseLeave = () => {
    setRate(rating);
  };

  return (
    <div className="d-flex fd-r ai-e-flex gap-2" onMouseLeave={onMouseLeave}>
      {[1, 2, 3, 4, 5].map((i) => {
        const isFilled = rate && rate >= i;
        return isFilled ? (
          <Icon.StarFillSVG
            key={i}
            onClick={() => onClick?.(i)}
            className={`c-yellow ${hover && "c-pointer"}`}
            height={32}
            width={32}
          />
        ) : (
          <Icon.StarSVG
            className={`c-yellow ${hover && "c-pointer"}`}
            onClick={() => onClick?.(i)}
            height={32}
            width={32}
          />
        );
      })}
    </div>
  );
};

export default StarSelectionMobileComponent;
