import React from "react";
import * as Icon from "commons/iconManager";
import * as GoogleMaps from "commons/googleManager";
import AddressComponent from "./AddressComponent";
import OpenHourBoxComponent from "./OpenHourBoxComponent";
import ReactDOM from "react-dom/client";
import { Address } from "models/new/Address";
import { ModalDialogProps } from "models/new/ModalDialogProps";
import { GoogleMap } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { MainState, SearchState } from "models/new/State";
import { Button } from "controls/Button";
import { Label } from "controls/Label";

interface SelectOnMapProps extends ModalDialogProps {
  addresses?: Address[];
}

const SelectOnMapComponent: React.FC<SelectOnMapProps> = (props) => {
  const [styleMaps, setStyleMaps] = React.useState<google.maps.MapTypeStyle[] | undefined>();
  const [selectedAddress, setSelectedAddress] = React.useState<Address | undefined>(props.addresses?.[0]);
  const [mapRef, setMapRef] = React.useState<google.maps.Map>();
  const searchState: SearchState = useSelector((state: any) => state.search);
  const mainState: MainState = useSelector((state: any) => state.main);

  const markersOnMap = React.useRef<google.maps.Marker[]>([]);
  const prevInfoWindow = React.useRef<google.maps.InfoWindow>();

  React.useEffect(() => {
    getMarkerDealersFilter();
  }, [mapRef]);

  React.useEffect(() => {
    mainState.theme === "dark" ? setStyleMaps(GoogleMaps.darkModeForMaps) : setStyleMaps(GoogleMaps.lightModeForMaps);
  }, [mainState.theme]);

  const mouseOverLocation = (marker: google.maps.Marker, address: Address) => {
    setTimeout(() => {
      const infoWindowContent = document.createElement("div");
      const root = ReactDOM.createRoot(infoWindowContent);
      root.render(
        <div className="d-flex fd-c pad-4 gap-8">
          <div className="d-flex fd-c pad-4 gap-4">
            <Label fontStyle="bold" content="Adres" />
            <AddressComponent address={address} />
          </div>
          <div className="d-flex fd-c pad-4 gap-4">
            <Label fontStyle="bold" content="Godziny otwarcia" />
            <OpenHourBoxComponent canEdit={false} address={address} />
          </div>
        </div>
      );

      if (prevInfoWindow.current) {
        prevInfoWindow.current?.close();
      }

      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent,
      });
      prevInfoWindow.current = infoWindow;

      prevInfoWindow.current.open(mapRef, marker);
    }, 200);
  };
  const mouseOut = () => {
    setTimeout(() => {
      prevInfoWindow.current?.close();
    }, 200);
  };
  const onMarkerClick = (address: Address, latlang?: google.maps.LatLng | null) => {
    setSelectedAddress(address);
    latlang && mapRef?.setCenter(latlang);
    prevInfoWindow && prevInfoWindow.current?.close();
  };

  const getMarkerDealersFilter = () => {
    const markers: google.maps.Marker[] = [];
    clearMarkers();

    if (props.addresses) {
      setTimeout(() => {
        props.addresses?.forEach((address, idx) => {
          if (!address.isMainAddress) {
            const marker = new google.maps.Marker({
              position: { lat: address.latitude, lng: address.longitude },
              map: mapRef,
              icon: {
                url: address.isMainAddress ? "markers/home_pin_fill.png" : "markers/add_location_fill.png",
                scaledSize: new google.maps.Size(28, 50),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 24),
              },
            });
            marker.addListener("click", () => {
              onMarkerClick(address, new google.maps.LatLng(address.latitude, address.longitude));
            });
            marker.addListener("mouseover", (event: any) => {
              mouseOverLocation(marker, address);
            });
            marker.addListener("mouseout", (event: any) => {
              mouseOut();
            });
            markers.push(marker);
          }
        });

        markersOnMap.current = markers;
        mapRef?.setZoom(12);
      }, 0);
      return;
    }
  };

  const clearMarkers = () => {
    for (let i = 0; i < markersOnMap.current.length; i++) {
      markersOnMap.current[i].setMap(null);
    }
    markersOnMap.current = [];
  };

  const showAddress = (address: Address) => {
    return (
      <div
        className="d-flex ai-center gap-8 pad-8 pad-l-16 border rounded-12 width-fill"
        onClick={() => setSelectedAddress(address)}
      >
        {selectedAddress?.id === address.id ? (
          <Icon.CheckCircleFillSVG className="icon-color c-green c-pointer" height={32} width={32} />
        ) : (
          <Icon.RadioButtonUncheckedSVG className="icon-color c-pointer" height={32} width={32} />
        )}
        <div className="d-flex fd-c">
          <AddressComponent address={address} />
        </div>
        <Button
          style={{ marginLeft: "auto" }}
          kind="transparent"
          icon={<Icon.InfoSVG />}
          toolTip={<OpenHourBoxComponent canEdit={false} address={address} fontColor="tooltip" />}
          toolTipPosition="right"
        />
      </div>
    );
  };

  const approved = () => {
    props.onChangeValue?.(selectedAddress);
    props.onCloseModalDialog?.("Approve");
  };

  return (
    <div className="d-flex fd-c width-fill gap-16" style={{ width: "70vw", height: "80vh" }}>
      <div className="d-flex width-fill height-fill gap-8">
        <div className="d-flex fd-c gap-8" style={{ width: "400px" }}>
          {props.addresses
            ?.filter((a) => !a.isMainAddress)
            .map((adres) => {
              return showAddress(adres);
            })}
        </div>
        <GoogleMap
          zoom={12}
          center={{
            lat: selectedAddress?.latitude ?? searchState.geoLocation.lat ?? 51.73848,
            lng: selectedAddress?.longitude ?? searchState.geoLocation.lng ?? 19.46728,
          }}
          mapContainerClassName="map-container"
          options={{
            streetViewControl: false,
            styles: styleMaps,
            zoomControl: false,
            gestureHandling: "greedy",
          }}
          onLoad={(map) => {
            setMapRef(map);
          }}
        />
      </div>
      <Button
        sizeFrame="lg"
        fontSize="lg"
        fontStyle="bold"
        kind="primary"
        className="width-fill"
        label="Zatwierdź"
        onClick={approved}
      />
    </div>
  );
};

export default SelectOnMapComponent;
