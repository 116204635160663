import React from "react";
import { Button } from "./Button";
import * as Icon from "commons/iconManager";
import { FontStyle, IconPositionType, KindType, SizeFrame } from "models/new/Dictionaries";
import { Label } from "./Label";

export const getKind = (
  kind?: "primary" | "secondary" | "text" | "approve" | "error" | "tertiary" | "transparent"
): string => {
  switch (kind) {
    case "primary":
      return "primary-input";
    case "secondary":
      return "secondary-input";
    case "approve":
      return "approve-input";
    case "text":
      return "text-input";
    case "error":
      return "error-input";
    case "tertiary":
      return "tertiary-input";
    case "transparent":
      return "transparent-input";
    default:
      return "text-input";
  }
};
export const getSize = (size?: SizeFrame): string => {
  switch (size) {
    case "sml":
      return " input-h32";
    case "sm":
      return " input-h40";
    case "md":
      return " input-h48";
    case "lg":
      return " input-h56";
    case "xl":
      return " input-h64";
    default:
      return " ";
  }
};
export const getSizeForIcon = (size?: SizeFrame): number => {
  switch (size) {
    case "sm":
      return 24;
    case "md":
      return 24;
    case "lg":
      return 28;
    case "xl":
      return 32;
    default:
      return 24;
  }
};
export const getFontFamily = (family?: FontStyle) => {
  switch (family) {
    case "italic":
      return " f-italic ";
    case "regular":
      return " f-regular ";
    case "medium":
      return " f-medium ";
    case "medium-italic":
      return " f-medium-italic ";
    case "semibold":
      return " f-semibold ";
    case "semibold-italic":
      return " f-semibold-italic ";
    case "bold":
      return " f-bold ";
    case "bold-italic":
      return " f-bold-italic ";
    case "extra-bold":
      return " f-extra-bold ";
    case "extra-bold-italic":
      return " f-extra-bold-italic ";
    default:
      return " f-semibold ";
  }
};

export interface InputFrameProps {
  frameId?: string;
  frameClassName?: string;
  frameRef?: React.MutableRefObject<HTMLDivElement>;
  frameStyle?: React.CSSProperties;
}

export interface InputTextProps extends InputFrameProps, React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  id?: string;
  icon?: React.ReactElement;
  iconPosition?: IconPositionType;
  sizeFrame?: SizeFrame;
  kind?: KindType;
  family?: FontStyle;
  count?: number;
  error?: string;
  canClear?: boolean;
  isRequired?: boolean;
}

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const [focused, setFocused] = React.useState<boolean>(false);
  const [showClear, setShowClear] = React.useState<boolean>(false);
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useImperativeHandle(ref, () => inputRef.current);

  const onKeyPress = React.useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === "Enter") {
        const simulatedEvent = {
          target: { value: inputRef.current.value },
          currentTarget: { value: inputRef.current.value },
        };
        props.onSubmit?.(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
        ev.preventDefault();
        //inputRef.current.value = "";
      }
    },
    [inputRef.current]
  );

  React.useEffect(() => {
    inputRef.current?.addEventListener("keypress", onKeyPress);
    return () => {
      inputRef.current?.removeEventListener("keypress", onKeyPress);
    };
  }, [onKeyPress]);

  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += getFontFamily(props.family);

    if (focused) {
      style += " input-focused";
    }
    if (props.error) {
      style += " mag-b-16";
    }
    if (props.label) {
      style += " mag-t-16";
    }
    if (props.error) {
      style += " input-error";
    }

    return `${style} ${props.frameClassName}`;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }
  const onFocus = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onFocus?.(evt);
    setFocused(true);
  };
  const onBlur = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onBlur?.(evt);
    setFocused(false);
  };
  const onClear = () => {
    inputRef.current.value = "";
    const simulatedEvent = {
      target: { value: "" },
      currentTarget: { value: "" },
    };
    props.onChange?.(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
    props.onSubmit?.(simulatedEvent as React.ChangeEvent<HTMLInputElement>);
    inputRef.current.focus();
    setShowClear(false);
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowClear(event.target.value.length > 0);
    props.onChange?.(event);
  };

  const canClear = (): boolean => {
    return props.canClear !== false && props.disabled !== true && (showClear || inputRef.current?.value?.length > 0);
  };

  return (
    <div ref={props.frameRef} id={props.frameId} className={getStyle()} style={{ ...props.frameStyle }}>
      {props.iconPosition !== "right" && clonedIcon}
      <div className="title d-flex gap-4 ai-center">
        <Label content={props.label} fontSize="sml" fontStyle="semibold" />
        {props.isRequired && <Label content="*" fontColor="red" fontSize="sml" fontStyle="bold" />}
      </div>
      <input
        {...props}
        ref={inputRef}
        className={`${getFontFamily()} ${props.className}`}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
      {canClear() && (
        <Button
          tabIndex={-1}
          style={{ padding: "4px" }}
          sizeFrame="sml"
          kind="text"
          icon={<Icon.CloseSVG className="close" />}
          onClick={onClear}
        />
      )}
      {props.iconPosition === "right" && clonedIcon}
      <label className="error">{props.error}</label>
    </div>
  );
});
