import React from "react";
import { Label } from "controls/Label";
import RatingStarComponent from "components/RatingStarComponent";
import { SupplierInArea } from "models/new/SupplierInArea";
import { useWindowSize } from "hooks/useWindowSize";
import { Button } from "controls/Button";

const ShowDealerOnMapComponent: React.FC<{
  dealer: SupplierInArea;
  searchResult: { isFilter: boolean; products: string[] };
}> = ({ dealer, searchResult }) => {
  const { isMobile } = useWindowSize();

  const getSearched = (): JSX.Element => {
    if (!searchResult.isFilter || searchResult.products.length === 0) {
      return <></>;
    } else {
      return (
        <div className="d-flex fd-c gap-4">
          <Label fontSize="sml" content={`Wyszukane produkty`} />
          <div className="d-flex gap-4" style={{flexWrap: 'wrap'}}>
          {searchResult.products.map((item) => {
            return <Button fontStyle="bold" sizeFrame="sml" kind="secondary" label={item} />;
          })}
          </div>
        </div>
      );
    }
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-r ai-stretch gap-8">
        <img className="picture-50" src={dealer.logoUrl ?? ""} style={{ height: "65px", width: "65px" }} />
        <div className="d-flex fd-c ai-stretch">
          <div className="gap-4">
            <Label fontSize="sml" wrap content={`${dealer.firstName} ${dealer.lastName}`} />
          </div>
          <Label fontStyle="bold" wrap content={dealer.companyName} />
          <RatingStarComponent rating={dealer.rating} ratingQty={dealer.ratingQty} />
          <Label fontSize="sml" content={`Ilość produktów u sprzedawcy: ${dealer.productQty}`} />
          {getSearched()}
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c ai-e-flex gap-4 pad-4" style={{maxWidth: '400px'}}>
      <div className="d-flex fd-r ai-stretch gap-16">
        <img className="picture-50" src={dealer.logoUrl ?? ""} style={{ height: "65px", width: "65px" }} />
        <div className="d-flex fd-c ai-stretch gap-4">
          <div className="gap-4">
            <Label fontSize="sml" content={`${dealer.firstName} ${dealer.lastName}`} />
          </div>
          <Label fontStyle="bold" content={dealer.companyName} />
          <RatingStarComponent rating={dealer.rating} ratingQty={dealer.ratingQty} />
          <Label fontSize="sml" content={`Ilość produktów u sprzedawcy: ${dealer.productQty}`} />
          {getSearched()}
        </div>
      </div>
    </div>
  );
};

export default ShowDealerOnMapComponent;
