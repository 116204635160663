import React from "react";
import { Label } from "controls/Label";
import { Address } from "models/new/Address";
import { OrderAddress } from "models/new/OrderAddress";

const AddressComponent: React.FC<{ address?: Address | OrderAddress; showName?: boolean }> = ({
  address,
  showName,
}) => {
  if (!address) return <></>;

  return (
    <div className="d-flex fd-c gap-4">
      {showName !== false && (
        <Label fontSize="sm" fontStyle="semibold" content={`${address.firstName ?? ""} ${address.lastName ?? ""}`} />
      )}
      {address?.street ? (
        <Label
          fontSize="sm"
          fontStyle="semibold"
          content={`${address?.street} ${address?.buildingNumber}${
            address?.localNumber ? `/${address?.localNumber}` : ""
          }`}
        />
      ) : (
        <Label
          fontSize="sm"
          fontStyle="semibold"
          content={`${address?.city} ${address?.buildingNumber}${
            address?.localNumber ? `/${address?.localNumber}` : ""
          }`}
        />
      )}

      <Label fontSize="sm" fontStyle="semibold" content={`${address?.postalCode} ${address?.city ?? address.city}`} />
    </div>
  );
};

export default AddressComponent;
