/*import { createStore, applyMiddleware, combineReducers, Store, AnyAction } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
*/
// Example reducers and types
import { routerReducer } from "react-router-redux";
import { configureStore } from "@reduxjs/toolkit";
import { mainReducer } from "./reducers/mainReducer";
import { productReducer } from "./reducers/productReducer";
import { searchReducer } from "./reducers/searchReducer";
import { wallReducer } from "./reducers/wallReducer";
import { basketReducer } from "./reducers/basketReducer";
import { conversationReducer } from "./reducers/conversationReducer";
import { orderReducer } from "./reducers/orderReducer";
import { saleReducer } from "./reducers/saleReducer";
import { notificationReducer } from "./reducers/notificationReducer";
import { userReducer } from "./reducers/userReducer";
import { subscriptionWizardReducer } from "reducers/subscriptionWizardReducer";
import { landingReducer } from "reducers/landingReducer";
import { categoryReducer } from "reducers/categoryReducer";

export let store;

export const getStore = () => {
  store = configureStore({
    reducer: {
      main: mainReducer,
      product: productReducer,
      search: searchReducer,
      wall: wallReducer,
      basket: basketReducer,
      conversation: conversationReducer,
      order: orderReducer,
      sale: saleReducer,
      notification: notificationReducer,
      user: userReducer,
      subscriptionWizard: subscriptionWizardReducer,
      landing: landingReducer,
      category: categoryReducer,
      router: routerReducer,
    },
    // Redux Toolkit includes 'redux-thunk' by default, so you don't need to add it separately
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development
  });
  
  return store;
};
