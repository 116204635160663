import React from "react";
import * as Icon from "commons/iconManager";
import { Product } from "models/new/Product";
import { Label } from "controls/Label";
import PictureBoxComponent from "components/PictureBoxComponent";
import RatingStarComponent from "components/RatingStarComponent";
import { Tag } from "controls/Tag";
import { calculatePercentFromOneHundred } from "commons/numberManager";
import { useNavigation } from "hooks/useNavigation";

const GroceryMobileItemPage: React.FC<{ product: Product }> = ({ product }) => {
  const { navigateTo } = useNavigation();

  const getAvailableInfo = (): JSX.Element => {
    if (product.availableType === "Available")
      return <Tag sizeFrame="xsm" fontStyle="bold" kind="green" label="Dostępny" />;
    if (product.availableType === "TemporarilyUnavailable")
      return (
        <Tag
          sizeFrame="xsm"
          fontStyle="bold"
          kind="yellow"
          label={`Dostępny od ${new Date(product.whenAvailable ?? "")?.toFormat("dd.MM.yyyy")}`}
        />
      );
    if (product.availableType === "Unavailable")
      return <Tag sizeFrame="xsm" fontStyle="bold" kind="red" label="Niedostępny" />;
    return <></>;
  };

  const salePrice = (): JSX.Element => {
    if (!product.salePrice) return <></>;
    return (
      <div className="d-flex ai-e-flex gap-4" style={{ flexWrap: "wrap" }}>
        <Label fontSize="lg" fontStyle="bold" pointer content={`${product.salePrice} zł/`} />
        <Label
          className="pad-b-4"
          fontSize="sml"
          fontColor="secondary"
          fontStyle="semibold"
          pointer
          wrap
          content={product.unit}
        />
        <Tag
          sizeFrame="xsm"
          fontStyle="bold"
          kind="red"
          label={`-${calculatePercentFromOneHundred(product.price, product.salePrice!)}%`}
        />
      </div>
    );
  };

  return (
    <div className="product" onClick={() => navigateTo("grocery_manager", `?action=edit&tab=data&id=${product.id}`)}>
      <PictureBoxComponent className="image-on-search-list" url={product.photoUrl ?? ""} />
      <div className="d-flex fd-c ai-s-flex gap-2 width-fill">
        {getAvailableInfo()}
        <Label className="max-l-2" fontStyle="semibold" pointer wrap content={product.name} />
        <RatingStarComponent rating={product.rating} ratingQty={product.ratingQty} />
        <div className="d-flex ai-e-flex gap-4" style={{ flexWrap: "wrap" }}>
          <Label className="max-l-2" fontStyle="semibold" pointer wrap content={`${product.price.toString()} zł/`} />
          <Label
            className="pad-b-4"
            fontSize="sml"
            fontColor="secondary"
            fontStyle="semibold"
            pointer
            wrap
            content={product.unit}
          />
        </div>
        {salePrice()}
        <div className="d-flex ai-center gap-4">
          <Icon.GrocerySVG className="icon-color" />
          <Label
            className="max-l-2"
            fontSize="lg"
            fontStyle="semibold"
            pointer
            wrap
            content={product.quantity.toString()}
          />
          <Label
            className="pad-b-4"
            fontSize="sml"
            fontColor="secondary"
            fontStyle="semibold"
            pointer
            wrap
            content={product.unit}
          />
        </div>        
        <Label fontSize="sml" fontColor="secondary" wrap content={`Wyświetlenia: ${product?.visitorsQty?.toString()}`} />
      </div>
    </div>
  );
};

export default GroceryMobileItemPage;
