import * as Icon from "../../commons/iconManager";
import { Tag } from "../../controls/Tag";

export type CountryList = "PL" | "SK";

export type TypeRoutePage =
  | "landing"
  | "main"
  | "search"
  | "grocery"
  | "grocery_manager"
  | "orders"
  | "order_manager"
  | "sales"
  | "sale_manager"
  | "profile"
  | "settings"
  | "basket"
  | "wall";

export type TypeMessageBoxButton =
  | "None"
  | "Ok"
  | "Cancel"
  | "OkCancel"
  | "AbortRetryIgnore"
  | "YesNoCancel"
  | "YesNo"
  | "RetryCancel"
  | "Select"
  | "SelectCancel"
  | "ApproveCancel";

export type TypeMessageBoxIcon = "None" | "Success" | "Error" | "Report" | "Information" | "Question" | "Warning";

export type TypeDialogResult = "Ok" | "Yes" | "No" | "Retry" | "Ignore" | "Cancel" | "Abort" | "Approve" | "Select";

export type SubscriptionStep = "farm" | "descpic" | "address" | "cert" | "settlements" | "subscriptionType" | "summary";

export enum StatusOrder {
  New,
  InProgress,
  Ready,
  Sent,
  WaitingForPayment,
  Completed,
  Canceled,
  All,
}

export enum GroupByDate {
  Day,
  Week,
  Month,
  All,
}

export function stringToStatusOrder(value: string): StatusOrder {
  switch (value) {
    case "New":
      return StatusOrder.New;
    case "InProgress":
      return StatusOrder.InProgress;
    case "Ready":
      return StatusOrder.Ready;
    case "Sent":
      return StatusOrder.Sent;
    case "WaitingForPayment":
      return StatusOrder.WaitingForPayment;
    case "Completed":
      return StatusOrder.Completed;
    default:
      return StatusOrder.Canceled;
  }
}
export function statusOrderToString(status: StatusOrder): string {
  switch (status) {
    case StatusOrder.New:
      return "Nowy";
    case StatusOrder.InProgress:
      return "W przygotowaniu";
    case StatusOrder.Ready:
      return "Gotowe do odbioru/wysyłki";
    case StatusOrder.Sent:
      return "Wysłane";
    case StatusOrder.WaitingForPayment:
      return "Oczekuje na płatność";
    case StatusOrder.Completed:
      return "Zrealizowane";
    case StatusOrder.Canceled:
      return "Odrzucono";
    default:
      throw new Error("Invalid status order");
  }
}

export enum AvailableType {
  Available,
  TemporarilyUnavailable,
  Unavailable,
}

export function stringToAvailableType(value: string): AvailableType | undefined {
  switch (value) {
    case "Available":
      return AvailableType.Available;
    case "TemporarilyUnavailable":
      return AvailableType.TemporarilyUnavailable;
    case "Unavailable":
      return AvailableType.Unavailable;
    default:
      return undefined;
  }
}

export enum DeliveryType {
  PickupOnSite,
  PickUpAtThePoint,
  Post,

  All,
}

export function stringToDeliveryType(value: string): DeliveryType {
  switch (value) {
    case "PickupOnSite":
      return DeliveryType.PickupOnSite;
    case "PickUpAtThePoint":
      return DeliveryType.PickUpAtThePoint;
    case "Post":
      return DeliveryType.Post;
    default:
      return DeliveryType.PickupOnSite;
  }
}

export enum PaymentType {
  Blik,
  Cash,
  Transfer,
  Card,
}
export enum SubscriptionType {
  Free = 0,
  Basic = 1,
  Advance = 2,
  Pro = 3,
}

export enum ValideType {
  UnValid = 0,
  Valid = 1,
}

export enum MessageStatusType {
  Sent = 0,
  Delivered = 1,
  Read = 2,
}

export function stringToMessageStatusType(value: string): MessageStatusType {
  switch (value) {
    case "Sent":
      return MessageStatusType.Sent;
    case "Delivered":
      return MessageStatusType.Delivered;
    case "Read":
      return MessageStatusType.Read;
    default:
      return MessageStatusType.Sent;
  }
}
export enum NotificationType {
  None = 0,
  Info = 1,
  Warning = 2,
  Success = 3,
  Error = 4
}

export type AccountPageType = "sign-up" | "sign-in" | "opt" | "welcome" | "reset-password" | "forgot-password";

export type WelcomePageType = "welcome-thank" | "set-name" | "opt" | "welcome" | "reset-password";

export type SizeFrame = "xsml" | "xsm" | "sml" | "sm" | "md" | "lg" | "xl";

export type KindType = "primary" | "secondary" | "text" | "approve" | "error" | "tertiary" | "transparent";
export type KindTagType = KindType | "green" | "yellow" | "blue" | "red";

export type IconPositionType = "left" | "right";

export type FontStyle =
  | "italic"
  | "regular"
  | "medium"
  | "medium-italic"
  | "semibold"
  | "semibold-italic"
  | "bold"
  | "bold-italic"
  | "extra-bold"
  | "extra-bold-italic";

export type FontSize = "xxsml" | "xsml" | "sml" | "sm" | "md" | "lg" | "xl" | "xxl";

export type FontColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "green"
  | "yellow"
  | "orange"
  | "blue"
  | "red"
  | "gray"
  | "white"
  | "black"
  | "tooltip";

export const showStatusOrderCodeTag = (statusOrderCode: StatusOrder | number, size: SizeFrame): JSX.Element => {
  if (typeof statusOrderCode === "number") {
    switch (statusOrderCode) {
      case 0:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="yellow"
            label="Nowy"
            icon={<Icon.AddSVG />}
          />
        );
      case 1:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="W przygotowaniu"
            icon={<Icon.GrocerySVG />}
          />
        );
      case 2:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="Gotowe do odbioru/wysyłki"
            icon={<Icon.CheckCircleSVG />}
          />
        );
      case 3:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="Wysłane"
            icon={<Icon.LocalShippingSVG />}
          />
        );
      case 4:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="Oczekuje na płatność"
            icon={<Icon.PaymentsSVG />}
          />
        );
      case 5:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="green"
            label="Zrealizowane"
            icon={<Icon.OrdersSVG />}
          />
        );
      case 6:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="red"
            label="Anulowane"
            icon={<Icon.DangerousSVG />}
          />
        );
      default:
        return <></>;
    }
  } else {
    switch (statusOrderCode) {
      case StatusOrder.New:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="yellow"
            label="Nowy"
            icon={<Icon.AddSVG />}
          />
        );
      case StatusOrder.InProgress:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="W przygotowaniu"
            icon={<Icon.GrocerySVG />}
          />
        );
      case StatusOrder.Ready:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="Gotowe do odbioru/wysyłki"
            icon={<Icon.CheckCircleSVG />}
          />
        );
      case StatusOrder.Sent:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="Wysłane"
            icon={<Icon.LocalShippingSVG />}
          />
        );
      case StatusOrder.WaitingForPayment:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="blue"
            label="Oczekuje na płatność"
            icon={<Icon.PaymentsSVG />}
          />
        );
      case StatusOrder.Completed:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="green"
            label="Zrealizowane"
            icon={<Icon.OrdersSVG />}
          />
        );
      case StatusOrder.Canceled:
        return (
          <Tag
            sizeFrame={size}
            fontSize={size === "xsml" ? "sml" : "sm"}
            kind="red"
            label="Odrzucone"
            icon={<Icon.DangerousSVG />}
          />
        );
      default:
        return <></>;
    }
  }
};
