import React from "react";

const SettingsPage: React.FC = () => {
  return (
    <div className="page-main box-shadow-5">
      <label>Settings</label>
    </div>
  );
};

export default SettingsPage;
