import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";

import "moment/locale/pl";
import DataPickerComponent from "./DataPickerComponent";
import { useWindowSize } from "hooks/useWindowSize";
import { Label } from "controls/Label";
var moment = require("moment");

interface DataPickerProps {
  range: { from: Date; to: Date };
  format?: string;
  onClose: (range: { from: Date; to: Date }) => void;
}

const DataRangePickerComponent: React.FC<DataPickerProps> = (props) => {
  const [range, setRange] = React.useState<{ from: Date; to: Date }>(props.range);
  const [view, setView] = React.useState<"from" | "to">("from");
  const [selected, setSelected] = React.useState<string[]>([]);
  const { isMobile } = useWindowSize();
  const format = props.format ?? "DD.MM.yyyy";

  React.useEffect(() => {
    let from = range.from;
    let to = range.to;
    const selectedList: string[] = [];
    while (from <= to) {
      selectedList.push(from.toFormatMoment("DD.MM.yyyy"));
      from = from.addDays(1);
    }
    setSelected(selectedList);
  }, [range]);

  const onCloseFrom = (date: Date) => {
    setTimeout(() => {
      if (range.to < date) {
        setRange({ ...range, from: date, to: date });
      } else {
        setRange({ ...range, from: date });
      }
    }, 200);
    setView("to");
  };

  const onCloseTo = (date: Date) => {
    setTimeout(() => {
      if (range.from > date) {
        setRange({ ...range, from: date, to: date });
      } else {
        setRange({ ...range, to: date });
      }
    }, 200);
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-8">
        {view === "from" ? (
          <>
            <Label className="mag-t-16 mag-b-16" fontSize="sm" content="Wybierz date rozpoczęcia" />
            <DataPickerComponent
              onClose={onCloseFrom}
              onChange={onCloseFrom}
              date={range.from}
              format={format}
              selected={selected}
            />
          </>
        ) : (
          <>
            <div className="d-flex ai-center gap-16">
              <Icon.ArrowBackSVG className="i-button i-button-small" onClick={() => setView("from")} />
              <Label fontSize="sm" content="Wybierz datę zakończenia" />
            </div>
            <DataPickerComponent
              onClose={onCloseTo}
              date={range.to}
              onChange={onCloseTo}
              format={format}
              selected={selected}
            />
            <Label
              style={{ marginLeft: "auto" }}
              fontSize="sml"
              fontColor="secondary"
              content={`${range.from.toFormatMoment(format)} - ${range.to.toFormatMoment(format)}`}
            />
            <Button
              className="width-fill"
              sizeFrame="md"
              kind="primary"
              fontStyle="bold"
              fontSize="md"
              label="Zatwierdź"
              icon={<Icon.CheckCircleSVG />}
              onClick={() => props.onClose(range)}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div className="d-flex fd-c gap-16">
      <div className="d-flex gap-16">
        <DataPickerComponent
          onClose={onCloseFrom}
          onChange={onCloseFrom}
          date={range.from}
          format={format}
          selected={selected}
        />
        <div className="border-left" style={{ width: "4px", height: "auto" }} />
        <DataPickerComponent
          onClose={onCloseTo}
          date={range.to}
          onChange={onCloseTo}
          format={format}
          selected={selected}
        />
      </div>
      <Button
        style={{ marginLeft: "auto" }}
        sizeFrame="sml"
        kind="primary"
        fontStyle="bold"
        label="Zatwierdź"
        icon={<Icon.CheckCircleSVG />}
        onClick={() => props.onClose(range)}
      />
    </div>
  );
};

export default DataRangePickerComponent;
