import React from "react";
import PathComponent from "components/PathPageComponent";
import * as Icon from "commons/iconManager";
import * as OrderApi from "api/OrderApi";
import { Button } from "controls/Button";
import { InputComboBox } from "controls/InputComboBox";
import { EntryDictionary } from "models/new/EntryDictionary";
import { useDispatch, useSelector } from "react-redux";
import { OrderState, UserState } from "models/new/State";
import { changeOrderFilter, getOrders } from "reducers/orderReducer";
import WaitComponent from "components/WaitComponent";
import EmptyComponent from "components/EmptyComponent";
import { GridColumn } from "controls/GridColumn";
import { Grid } from "controls/Grid";
import { Label } from "controls/Label";
import { DeliveryType, StatusOrder, showStatusOrderCodeTag } from "models/new/Dictionaries";
import { Order } from "models/new/Order";
import { OrderProduct } from "models/new/OrderProduct";
import AddressComponent from "components/AddressComponent";
import { useNavigation } from "hooks/useNavigation";
import { InputDate } from "controls/InputDate";
import { SearchOrderFilter } from "models/new/SearchOrderFilter";
import { delivertType, orderStatus, sort } from "commons/listManager";
import OrderMobilePage from "./components/mobile/OrderMobilePage";
import { useWindowSize } from "hooks/useWindowSize";
import { getLastStatus } from "models/new/OrderStatus";

const OrderPage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { navigateTo } = useNavigation();
  const { isMobile } = useWindowSize();
  const orderState: OrderState = useSelector((state: any) => state.order);
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  
  React.useEffect(() => {    
    document.title = "Ogrosa | Moje zamówienia";
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [orderState.filter, userState.user]);

  const downloadData = async () => {
    if (!userState.user) return;
    setIsLoading(true);

    const searchOrderFilter: SearchOrderFilter = {
      ...orderState.filter,
      clientId: userState.user.id,
      statusOrder: orderState.filter.statusOrder === StatusOrder.All ? undefined : orderState.filter.statusOrder,
      deliveryType: orderState.filter.deliveryType === DeliveryType.All ? undefined : orderState.filter.deliveryType,
    };

    OrderApi.getOrders(searchOrderFilter)
      .then((value) => {
        dispatch(getOrders(value));
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  function onRefresh(): void {
    downloadData();
  }

  const onDisplayContentCode = (order: Order): JSX.Element => {
    return (
        <Label
          fontSize="sm"
          fontStyle="semibold"
          islink
          content={order.code}
          onClick={() => navigateTo("order_manager", `?id=${order.id}`)}
        />
    );
  };
  const onDisplayContentSupplier = (order: Order): JSX.Element => {
    return (
      <div className="d-flex fd-r ai-center gap-16">
        <img className="picture-50" src={order.supplier?.logoUrl} />
        <Label
          fontSize="md"
          fontStyle="semibold"
          islink
          content={order.supplier?.companyName}
          onClick={() => navigateTo("search", `?dealer_id=${order.supplier.id}&page=orders`)}
        />
        {order.description && order.description.length > 0 && (
          <Button
            sizeFrame="sml"
            kind="text"
            toolTip={order.description}
            toolTipPosition="right"
            icon={<Icon.MarkEmailUnreadSVG className="c-red" />}
            onClick={() => navigateTo("order_manager", `?id=${order.id}`)}
          />
        )}
      </div>
    );
  };
  const onDisplayContentAddress = (order: Order): JSX.Element => {
    return (
      <div className="d-flex ai-center gap-16">
        {order.deliveryType === DeliveryType.Post ? (
          <Icon.LocalShippingSVG className="icon-color" height={28} width={28} />
        ) : order.deliveryType === DeliveryType.PickupOnSite ? (
          <Icon.LocationOnSVG className="icon-color" height={28} width={28} />
        ) : (
          <Icon.AddNavigationSVG className="icon-color" height={28} width={28} />
        )}
        <AddressComponent address={order.address} />
      </div>
    );
  };
  const onDisplayContentState = (order: Order): JSX.Element => {
    return showStatusOrderCodeTag(getLastStatus(order.statuses), "xsm");
  };
  const onDisplayContentDate = (order: Order): JSX.Element => {
    return (
      <div className="d-flex fd-c ai-s-flex jc-center gap-4">
        <Label fontSize="sml" fontStyle="bold" content={new Date(order.createdDate).toFormatMoment("DD MMMM yyyy")} />
        <Label fontSize="sml" fontStyle="semibold" content={new Date(order.createdDate).toFormatMoment("HH:mm")} />
      </div>
    );
  };
  const onDisplayContentPickUpTime = (order: Order): JSX.Element => {
    return (
      <div className="d-flex fd-c ai-s-flex jc-center gap-4">
        <Label
          fontSize="sml"
          fontStyle="bold"
          content={new Date(order.pickUpDate ?? "").toFormatMoment("DD MMMM yyyy")}
        />
        <Label fontSize="sml" fontStyle="semibold" content={new Date(order.pickUpDate ?? "").toFormatMoment("HH:mm")} />
      </div>
    );
  };
  const calculateSummary = (product: OrderProduct): number => {
    let price = product.salePrice ?? product.price;
    /*if (product.saleCouponsCode) {
      price = calculateValueAfterPercent(price, product.saleCouponsPercent, 2);
    }*/

    price = price * product.quantity;
    return price;
  };
  const onDisplayContentSummary = (order: Order): JSX.Element => {
    let summary: number = 0;
    order.products.forEach((product) => {
      summary += calculateSummary(product);
    });

    return <Label fontSize="sm" fontStyle="bold" content={`${summary.toLocaleFixed(2)} zł`} />;
  };
  const onDisplayContentButtons = (order: Order): JSX.Element => {
    return (
      <div className="d-flex fd-r ai-center jc-s-flex gap-8">
        {getLastStatus(order.statuses) === StatusOrder.New ||
        getLastStatus(order.statuses) === StatusOrder.Completed ? (
          <Button
            sizeFrame="sml"
            kind="tertiary"
            fontStyle="bold"
            icon={<Icon.EditSVG />}
            toolTip={"Edycja zamówienia"}
            toolTipPosition="left"
            onClick={() => navigateTo("order_manager", `?id=${order.id}`)}
          />
        ) : (
          <Button
            sizeFrame="sml"
            kind="tertiary"
            fontStyle="bold"
            icon={<Icon.VisibilitySVG />}
            toolTip={"Podgląd zamówienia"}
            toolTipPosition="left"
            onClick={() => navigateTo("order_manager", `?id=${order.id}`)}
          />
        )}
      </div>
    );
  };
  const onDisplayContentOrderStatusCombobox = (item: EntryDictionary): JSX.Element => {
    let icon;
    switch (item.value) {
      case StatusOrder.New:
        icon = <Icon.AddSVG />;
        break;
      case StatusOrder.Canceled:
        icon = <Icon.DangerousSVG />;
        break;
      case StatusOrder.InProgress:
        icon = <Icon.GrocerySVG />;
        break;
      case StatusOrder.Ready:
        icon = <Icon.CheckCircleSVG />;
        break;
      case StatusOrder.Sent:
        icon = <Icon.LocalShippingSVG />;
        break;
      case StatusOrder.Completed:
        icon = <Icon.OrdersSVG />;
        break;
      case StatusOrder.WaitingForPayment:
        icon = <Icon.PaymentsSVG />;
        break;
    }
    return (
      <Button
        style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
        sizeFrame="sml"
        kind={orderState.filter.statusOrder === item.value ? "menu-seleceted" : "menu"}
        label={item.name}
        icon={icon}
      />
    );
  };
  const onDisplayContentAddressCombobox = (item: EntryDictionary): JSX.Element => {
    if (item.value === DeliveryType.All) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={orderState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={"Wszystkie"}
        />
      );
    }
    if (item.value === DeliveryType.PickUpAtThePoint) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={orderState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.AddNavigationSVG />}
        />
      );
    }
    if (item.value === DeliveryType.PickupOnSite) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={orderState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.LocationOnSVG />}
        />
      );
    }
    if (item.value === DeliveryType.Post) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="sml"
          kind={orderState.filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.LocalShippingSVG />}
        />
      );
    }
    return <></>;
  };

  if (isMobile) return <OrderMobilePage />;

  return (
    <div className="f-fill fd-c">
      <div className="frame-header jc-sb">
        <PathComponent onRefreshClick={onRefresh} />
      </div>
      <div className="frame-top ai-center jc-sb">
        <div className="d-flex ai-center gap-8">
          <InputDate
            label="Zakres dat"
            placeholder="Zakres dat"
            sizeFrame="sm"
            kind="tertiary"
            rangeValue={{ from: orderState.filter.dateFrom, to: orderState.filter.dateTo }}
            onChangeRange={(e) => dispatch(changeOrderFilter({ ...orderState.filter, dateFrom: e.from, dateTo: e.to }))}
          />
          <InputComboBox
            label="Status zamówienia"
            placeholder="Status zamówienia"
            sizeFrame="sm"
            kind="tertiary"
            displayMember="name"
            canClear={false}
            itemSource={orderStatus}
            selectedItem={orderStatus.find((x) => x.value === orderState.filter.statusOrder)}
            onSelectionChanged={(e) => dispatch(changeOrderFilter({ ...orderState.filter, statusOrder: e.value }))}
            onDisplayContent={onDisplayContentOrderStatusCombobox}
          />
          <InputComboBox
            label="Typ przesyłki"
            placeholder="Typ przesyłki"
            sizeFrame="sm"
            kind="tertiary"
            displayMember="name"
            canClear={false}
            itemSource={delivertType}
            selectedItem={delivertType.find((x) => x.value === orderState.filter.deliveryType)}
            onSelectionChanged={(e) => dispatch(changeOrderFilter({ ...orderState.filter, deliveryType: e.value }))}
            onDisplayContent={onDisplayContentAddressCombobox}
          />
        </div>
        <InputComboBox
          label="Sortuj według"
          placeholder="Sortuj według"
          sizeFrame="sm"
          kind="tertiary"
          displayMember="name"
          canClear={false}
          itemSource={sort}
          selectedItem={sort.find((x) => x.code === orderState.filter.sort)}
          onSelectionChanged={(e) => dispatch(changeOrderFilter({ ...orderState.filter, sort: e.code }))}
        />
      </div>
      <div className="frame-main" style={{ position: "relative" }}>
        <WaitComponent isLoading={isLoading} message={"Proszę czekać trwa pobieranie listy zamówień..."} />
        {orderState.orders.length === 0 ? (
          <EmptyComponent style={{ height: "50%" }} icon={<Icon.DescriptionSVG />} message="Brak zamówień" />
        ) : (
          <Grid itemSource={orderState.orders}>
            <GridColumn width={120} header="Kod zamówienia" onDisplayContent={onDisplayContentCode} />
            <GridColumn width={"*"} header="Sprzedający" onDisplayContent={onDisplayContentSupplier} />
            <GridColumn width={"*"} header="Adres" onDisplayContent={onDisplayContentAddress} />
            <GridColumn width={150} header="Status" onDisplayContent={onDisplayContentState} />
            <GridColumn width={150} header="Data zamówienia" onDisplayContent={onDisplayContentDate} />
            <GridColumn width={150} header="Data odbioru" onDisplayContent={onDisplayContentPickUpTime} />
            <GridColumn width={150} header="Suma" onDisplayContent={onDisplayContentSummary} />
            <GridColumn width={50} header="Akcje" onDisplayContent={onDisplayContentButtons} />
          </Grid>
        )}
      </div>
    </div>
  );
};

export default OrderPage;
