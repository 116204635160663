import React from "react";
import { Label } from "controls/Label";
import { InputCheckBox } from "controls/InputCheckBox";
import { InputText } from "controls/InputText";
import { OpenHourEdit } from "models/OpenHourEdit";
import { OpenHour } from "models/new/OpenHour";

interface OpenHourEditProps {
  openHours?: OpenHour[];
  onChangeOpenHours: (items: OpenHourEdit[]) => void;
}

const OpenHourEdit2Component: React.FC<OpenHourEditProps> = (props) => {
  const [openHours, setOpenHours] = React.useState<OpenHourEdit[]>([]);

  React.useEffect(() => {
    const prepareList: OpenHourEdit[] = [];
    [0, 1, 2, 3, 4, 5, 6].map((idx) => {
      const openHour: OpenHour | undefined = props.openHours?.find((x) => x.day === idx);
      if (openHour) {
        prepareList.push({
          hour: { ...openHour, startHour: openHour.startHour.slice(0, 5), closeHour: openHour.closeHour.slice(0, 5) },
          open: true,
          idx: idx,
        });
      } else {
        prepareList.push({ hour: { day: idx, startHour: "00:00", closeHour: "00:00" }, open: false, idx: idx });
      }
    });
    setOpenHours(prepareList);
  }, [props.openHours]);

  const days = ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"];

  const changeOpenHour = (openHour: OpenHourEdit) => {
    const changedOpenHours = openHours.map((x) => (x.idx === openHour.idx ? openHour : x));
    setOpenHours(changedOpenHours);
    props.onChangeOpenHours(changedOpenHours.filter((x) => x.open));
  };

  const dayRow = (openHour: OpenHourEdit) => {
    if (openHour.open) {
      return (
        <div className="d-flex fd-c gap-2">
          <div className="d-flex ai-center gap-8">
            <InputCheckBox
              kind="transparent"
              sizeFrame="sml"
              checked={openHour.open}
              onChange={(e) => changeOpenHour({ ...openHour, open: e.target.checked })}
            />
            <Label style={{ width: "90px" }} fontSize="sm" fontStyle="semibold" content={days[openHour.idx]} />
            <InputText
              style={{ width: "60px" }}
              sizeFrame="sml"
              kind="secondary"
              canClear={false}
              value={openHour.hour.startHour.slice(0, 5)}
              onChange={(e) =>
                changeOpenHour({ ...openHour, hour: { ...openHour.hour, startHour: e.target.value.slice(0, 5) } })
              }
            />
            <Label fontSize="md" fontStyle="semibold" content={"-"} />
            <InputText
              style={{ width: "60px" }}
              sizeFrame="sml"
              kind="secondary"
              canClear={false}
              value={openHour.hour.closeHour.slice(0, 5)}
              onChange={(e) =>
                changeOpenHour({ ...openHour, hour: { ...openHour.hour, closeHour: e.target.value.slice(0, 5) } })
              }
            />
          </div>
          {openHour.error && (
            <Label
              style={{ marginLeft: "auto" }}
              fontSize="sm"
              fontColor="red"
              fontStyle="semibold"
              content={openHour.error}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="d-flex ai-center gap-8">
          <InputCheckBox
            kind="transparent"
            sizeFrame="sml"
            checked={openHour.open}
            onChange={(e) => changeOpenHour({ ...openHour, open: e.target.checked })}
          />
          <Label style={{ width: "90px" }} fontSize="sm" fontStyle="semibold" content={days[openHour.idx]} />
          <InputText
            style={{ width: "70px" }}
            sizeFrame="sml"
            kind="secondary"
            canClear={false}
            disabled
            value={"Nieczynne"}
          />
        </div>
      );
    }
  };

  return (
    <div className="width-fill d-flex fd-c gap-8">
      {openHours.map((x) => {
        return dayRow(x);
      })}
    </div>
  );
};

export default OpenHourEdit2Component;
