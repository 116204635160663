import React from "react";
import { ToolTip } from "./ToolTip";
import { Label, StandardLabelProps } from "./Label";
import { KindTagType, SizeFrame } from "models/new/Dictionaries";

export interface TagProps extends StandardLabelProps, React.HtmlHTMLAttributes<HTMLDivElement> {
  label?: string;
  icon?: React.ReactElement;
  iconPosition?: "left" | "right";
  sizeFrame?: SizeFrame;
  kind?: KindTagType;
  pointer?: boolean;
  count?: number;
  toolTip?: string | JSX.Element;
  toolTipPosition?: "left" | "right" | "top" | "bottom";
}

export const Tag = React.forwardRef<HTMLDivElement, TagProps>((props, ref) => {
  const divRef =
    (ref as React.MutableRefObject<HTMLDivElement>) || (React.createRef() as React.MutableRefObject<HTMLDivElement>);

  React.useEffect(() => {
    if (props.toolTip) {
      ToolTip.show(divRef.current, props.toolTip, props.toolTipPosition);
    }
  }, [props.toolTip]);

  const getKind = (kind?: KindTagType): string => {
    switch (kind) {
      case "primary":
        return "primary-tag";
      case "secondary":
        return "secondary-tag";
      case "green":
        return "green-tag";
      case "yellow":
        return "yellow-tag";
      case "blue":
        return "blue-tag";
      case "red":
        return "red-tag";
      case "tertiary":
        return "tertiary-tag";
      default:
        return "secondary-tag";
    }
  };
  const getSize = (sizeFrame?: SizeFrame): string => {
    switch (sizeFrame) {
      case "xsml":
        return " tag-h20";
      case "xsm":
        return " tag-h24";
      case "sml":
        return " tag-h32";
      case "sm":
        return " tag-h40";
      case "md":
        return " tag-h48";
      case "lg":
        return " tag-h56";
      case "xl":
        return " tag-h64";
      default:
        return " ";
    }
  };
  const getSizeForIcon = (sizeFrame?: SizeFrame): number => {
    switch (sizeFrame) {
      case "xsml":
        return 14;
      case "xsm":
        return 18;
      case "sml":
        return 20;
      case "sm":
        return 24;
      case "md":
        return 24;
      case "lg":
        return 28;
      case "xl":
        return 32;
      default:
        return 24;
    }
  };
  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    return style;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-tag",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }

  return (
    <div {...props} ref={divRef} className={getStyle()}>
      {props.iconPosition !== "right" && clonedIcon}
      {props.label && (
        <Label
          content={props.label}
          fontColor={props.fontColor ?? "primary"}
          fontSize={props.fontSize ?? "sm"}
          fontStyle={props.fontStyle ?? "semibold"}
        />
      )}
      {props.iconPosition === "right" && clonedIcon}
    </div>
  );
});
