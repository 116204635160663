import React from "react";
import * as Icon from "commons/iconManager";
import { AccountProps } from "./AccountComponent";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { InputText } from "controls/InputText";
import { InputPassword } from "controls/InputPassword";
import * as UserApi from "api/UserApi";

const AccountSignInComponent: React.FC<AccountProps> = (props) => {
  const [valideErrors, setValdeErrors] = React.useState<Partial<{ email: string; password: string }>>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const refEmail = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const refPassword = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const onClick = async () => {
    setLoading(true);
    setValdeErrors({ password: "", email: "" });
    const email = refEmail.current.value;
    const password = refPassword.current.value;
    if (email?.length === 0 || password?.length === 0) {
      setValdeErrors({
        email: email?.length === 0 ? "Wprowadź email" : "",
        password: password?.length === 0 ? "Wprowadź hasło" : "",
      });
      setLoading(false);
      return;
    }

    const checkEmail = await UserApi.checkEmailConfirmed(email);
    if (!checkEmail) {
      setValdeErrors({ password: "Nieprawidłowy adres e‑mail lub hasło" });
      setLoading(false);
      return;
    }

    await UserApi.authenticater({ email: email, password: password })
      .then((token) => {
        props.onCloseModalDialog?.("Approve");
        props.onClick("sign-in", token, email);
      })
      .catch((err) => {
        setValdeErrors({ password: "Nieprawidłowy adres e‑mail lub hasło" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="frame-main">
      <div className="d-flex ai-stretch jc-center fd-c gap-16">
        <Icon.OgrosaSVG height={65} />
        <Label
          wrap
          fontStyle="bold"
          fontSize="sm"
          style={{ textAlign: "center" }}
          content={"Skorzystaj z poczty e-mail lub innej usługi, aby kontynuować korzystanie z Ogrosa"}
        />
        <div className="d-flex ai-stretch jc-center gap-16 fd-c">
          <InputText
            ref={refEmail}
            frameClassName="width-fill"
            label="E-mail"
            autoComplete="email"
            placeholder="Wprowadź e-mail"
            kind="tertiary"
            sizeFrame="sm"
            type="email"
            autoFocus
            isRequired
            error={valideErrors?.email}
            icon={<Icon.MailSVG />}
          />
          <Label
            style={{ position: "absolute", right: "24px", marginTop: "32px" }}
            wrap
            islink
            fontStyle="bold"
            fontColor="blue"
            fontSize="sm"
            content={"Nie pamiętam hasła?"}
            onClick={() => {
              props.setPageType("forgot-password");
            }}
          />
          <InputPassword
            ref={refPassword}
            frameClassName="width-fill"
            label="Hasło"
            autoComplete="current-password"
            placeholder="Wprowadź hasło"
            kind="tertiary"
            sizeFrame="sm"
            isRequired
            error={valideErrors?.password}
            icon={<Icon.KeySVG />}
            onSubmit={onClick}
          />
        </div>
        <Button
          style={{ width: "100%" }}
          sizeFrame="md"
          fontStyle="bold"
          fontSize="lg"
          kind="primary"
          label="Logowanie"
          isLoading={loading}
          onClick={onClick}
        />
        <Label
          wrap
          fontStyle="bold"
          fontSize="sml"
          content={"lub"}
          style={{ textAlign: "center", alignSelf: "center" }}
        />
        <div className="d-flex gap-8">
          <Button
            style={{ width: "100%" }}
            kind="tertiary"
            sizeFrame="sm"
            fontStyle="bold"
            label="Google"
            icon={<Icon.GoogleSVG />}
          />
          <Button
            style={{ width: "100%" }}
            kind="tertiary"
            sizeFrame="sm"
            fontStyle="bold"
            label="Facebook"
            icon={<Icon.FacebookSVG />}
          />
        </div>
        <Label
          wrap
          islink
          fontStyle="bold"
          fontColor="blue"
          fontSize="sm"
          content={"Nie masz konta? Utwórz konto"}
          style={{ textAlign: "center", alignSelf: "center" }}
          onClick={() => {
            props.setPageType("sign-up");
          }}
        />
      </div>
    </div>
  );
};

export default AccountSignInComponent;

/*


        <div className="d-flex jc-e-flex ai-center">
          <Label
            wrap
            islink
            fontStyle="bold"
            fontColor="blue"
            fontSize="sm"
            content={"Nie pamiętam hasła?"}
            onClick={() => {
              props.setPageType("forgot-password");
            }}
          />
        </div>

*/
