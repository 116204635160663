import "./styles/app.scss";
import MainPage from "./pages/main/MainPage";
import moment from "moment";

declare global {
  interface Array<T> {
    groupBy: (key: string) => { key: any; items: any[] }[];
    max: (key: string) => any;
    min: (key: string) => any;
    sum: (key: string) => any;
  }
  interface Number {
    toLocaleFixed: (n: number) => string;
    toNumberFixed: (n: number) => number;
  }
  interface Date {
    dayOfYear: () => number;
    isLeapYear: () => boolean;
    addDays: (days: number) => Date;
    toFormat: (format: string) => string;
    toFormatMoment: (format: string) => string;
    getDayName: () => string;
    getDayShortName: () => string;
    getDayLongName: () => string;
  }
  interface Math {
    sum: (values: number[], fixed?: number) => number;
    avg: (values: number[], fixed?: number) => number;
  }
}
Array.prototype.groupBy = function (n) {
  const newArray: { key: string; items: any[] }[] = [];

  this.forEach((e) => {
    const prop = e[n];
    if (prop !== undefined) {
      if (!newArray.some((x) => x.key === prop)) {
        newArray.push({ key: prop, items: this.filter((x) => x[n] === prop) });
      }
    }
  });

  return newArray;
};
Array.prototype.max = function (key) {
  if (!this.length || !key) return null;

  let maxValue: any = this[0][key];

  this.forEach((item) => {
    if (item[key] > maxValue) {
      maxValue = item[key];
    }
  });

  return maxValue;
};
Array.prototype.min = function (key) {
  if (!this.length || !key) return null;

  let minValue: any = this[0][key];

  this.forEach((item) => {
    if (item[key] < minValue) {
      minValue = item[key];
    }
  });

  return minValue;
};
Array.prototype.sum = function (key) {
  return this.reduce((acc, item) => {
    if (typeof item[key] === "number") {
      return acc + item[key];
    }
    return acc;
  }, 0);
};

Number.prototype.toLocaleFixed = function (n) {
  return this.toLocaleString(undefined, {
    minimumFractionDigits: n,
    maximumFractionDigits: n,
  });
};
Number.prototype.toNumberFixed = function (n) {
  return Number(this.toFixed(n));
};

Date.prototype.isLeapYear = function () {
  var year = this.getFullYear();
  if ((year & 3) != 0) return false;
  return year % 100 != 0 || year % 400 == 0;
};
Date.prototype.dayOfYear = function () {
  var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  var mn = this.getMonth();
  var dn = this.getDate();
  var dayOfYear = dayCount[mn] + dn;
  if (mn > 1 && this.isLeapYear()) dayOfYear++;
  return dayOfYear;
};
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
Date.prototype.toFormat = function (format) {
  var date = new Date(this.valueOf());
  if (date.toString() === "Invalid Date") {
    return "";
  }
  var z = {
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  };
  format = format.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
    return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
  });

  return format.replace(/(y+)/g, function (v) {
    return date.getFullYear().toString().slice(-v.length);
  });
};
Date.prototype.toFormatMoment = function (format) {
  var date = new Date(this.valueOf());
  if (date.toString() === "Invalid Date") {
    return "";
  }
  return moment(date).format(format);
};
Date.prototype.getDayName = function () {
  var days = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];

  return days[this.getDay()];
};
Date.prototype.getDayShortName = function () {
  var days = ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."];

  return days[this.getDay()];
};
Date.prototype.getDayLongName = function () {
  var days = ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"];

  return days[this.getDay()];
};

Math.sum = function (values: number[], fixed?: number) {
  const value: number = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return fixed ? value.toNumberFixed(fixed) : value;
};
Math.avg = function (values: number[], fixed?: number) {
  const value: number = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / values.length;
  return fixed ? value.toNumberFixed(fixed) : value;
};


const App: React.FC = () => {
  return <MainPage />;
}

export default App;
