import { Category } from "models/new/Category";
import * as api from "./api";
import { SearchProductFilter } from "models/new/SearchProductFilter";

export function get(): Promise<Category[]> {
  return api.getWithoutToken(`/category`);
}

export function getFiltered(searchProductFilter: SearchProductFilter): Promise<Category[]> {
  let query = `/category/search?LocLat=${searchProductFilter.locLat}&LocLng=${searchProductFilter.locLng}&LatMin=${searchProductFilter.latMin}&LatMax=${searchProductFilter.latMax}&LngMin=${searchProductFilter.lngMin}&LngMax=${searchProductFilter.lngMax}&Zoom=${searchProductFilter.zoom}&Distance=${searchProductFilter.distance}&Promotion=${searchProductFilter.promotion}&Take=${searchProductFilter.take}`;

  if (searchProductFilter.sort !== undefined) {
    query += `&Sort=${searchProductFilter.sort}`;
  }
  if (searchProductFilter.priceFrom !== undefined) {
    query += `&PriceFrom=${searchProductFilter.priceFrom}`;
  }
  if (searchProductFilter.priceTo !== undefined) {
    query += `&PriceTo=${searchProductFilter.priceTo}`;
  }
  if (searchProductFilter.stars !== undefined) {
    query += `&Stars=${searchProductFilter.stars}`;
  }
  if (searchProductFilter.phrase && searchProductFilter.phrase.length > 0) {
    searchProductFilter.phrase.forEach((x) => {
      query += `&Phrase=${x}`;
    });
  }

  return api.getWithoutToken(query);
}