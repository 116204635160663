import moment from "moment";
import "moment/locale/pl";

export const daysArray = ["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"];

export const getFormatDateFromString = (date: string | Date | undefined | null, format: string): string => {
  if (date === undefined || date === null) {
    return "∞";
  }

  let data = moment(date);

  if (format === "YYYY-MM-DD HH:mm" && data.hour() === 0 && data.minute() === 0) {
    data.add(-1, "day");
    format = "YYYY-MM-DD 24:mm";
  }
  const chcekData = moment(data).format(format);
  console.log(chcekData);
  return chcekData;
};

export const getFormatDateToString = (date: Date, format: string): string => {
  return moment(date).format(format);
};

export const getRandomDate = (startDate?: Date, endDate?: Date): Date => {
  if (
    (startDate instanceof Date && isNaN(startDate.valueOf())) ||
    (endDate instanceof Date && isNaN(endDate.valueOf()))
  ) {
    console.error("Invalid date provided");
    return new Date();
  }
  const today = endDate ?? new Date();
  const oneYearAgo = startDate ?? new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  if (oneYearAgo > today) {
    console.error("Start date is later than end date");
    return new Date();
  }

  const randomTimestamp = Math.random() * (today.getTime() - oneYearAgo.getTime()) + oneYearAgo.getTime();
  const randomDate = new Date(randomTimestamp);

  return randomDate;
};

export const convertToHHmm = (value: string): string => {
  const convertedTime = value.slice(0, 5);
  return convertedTime;
};


export function checkIntterOpenHours(date: Date, startHour: string, closeHour: string): boolean {
  const format = 'DD.MM.YYYY HH:mm';
  
  const startDate = moment(`${moment(date).format('DD.MM.YYYY')} ${startHour}`, format);
  const closeDate = moment(`${moment(date).format('DD.MM.YYYY')} ${closeHour}`, format);
  const currentDate = moment(date);
  
  return currentDate.isBetween(startDate, closeDate, undefined, '[)');
}