import { SearchOrderFilter } from "models/new/SearchOrderFilter";
import { Order } from "../models/new/Order";
import * as api from "./api";
import { StatusOrder, stringToDeliveryType, stringToStatusOrder } from "models/new/Dictionaries";
import { OrderCreateDto } from "models/DTO/OrderCreateDto";
import { OrderRatingDto } from "models/DTO/OrderRatingDto";

export function getById(id: string): Promise<Order> {
  return api.get(`/orders/${id}`);
}
export function getOrderForSupplier(supplierId: string): Promise<Order[]> {
  return api.get(`/orders`, { params: { supplierId: supplierId } });
}
export function getOrderForClient(clientId: string): Promise<Order[]> {
  return api.get(`/orders`, { params: { clientId: clientId } });
}

export function getOrders(filter: SearchOrderFilter): Promise<Order[]> {
  let query = `/orders?DateFrom=${filter.dateFrom.toFormatMoment("MM/DD/YYYY")}&DateTo=${filter.dateTo
    .addDays(1)
    .toFormatMoment("MM/DD/YYYY")}&Take=${filter.take}`;

  if (filter.sort !== undefined) {
    query += `&Sort=${filter.sort}`;
  }
  if (filter.clientId !== undefined) {
    query += `&ClientId=${filter.clientId}`;
  }
  if (filter.supplierId !== undefined) {
    query += `&SupplierId=${filter.supplierId}`;
  }
  if (filter.deliveryType !== undefined) {
    query += `&DeliveryType=${filter.deliveryType}`;
  }
  if (filter.statusOrder !== undefined) {
    query += `&OrderStatus=${filter.statusOrder}`;
  }

  return api.get(query);
}

export function putchangeOrderStatus(appUserId: string, orderId: string, orderStatus: StatusOrder): Promise<Order[]> {
  return api.put(`/orders/status`, { orderId: orderId, appUserId: appUserId, orderStatus: orderStatus });
}

export function createOrder(order: OrderCreateDto): Promise<string> {
  return api.post(`/orders`, { order: order });
}

export function ratingOrder(rating: OrderRatingDto): Promise<string> {
  return api.post(`/orders/rating`, { ratings: rating });
}