import { generateGUID } from "commons/stringManager";
import { OpenHour } from "./OpenHour";

export interface Address {
  id: string;
  supplierId?: string;
  firstName?: string;
  lastName?: string;
  latitude: number;
  longitude: number;
  street: string;
  buildingNumber: string;
  localNumber: string;
  city: string;
  postalCode: string;
  country: string;
  isMainAddress: boolean;
  openHours?: OpenHour[];
}


export const initialAddress: Address = {
  id: generateGUID(),
  latitude: 51.73848,
  longitude: 19.46728,
  street: "",
  buildingNumber: "",
  localNumber: "",
  city: "",
  postalCode: "",
  country: "",
  openHours: [
    { day: 0, startHour: "07:00", closeHour: "17:00" },
    { day: 1, startHour: "07:00", closeHour: "17:00" },
    { day: 2, startHour: "07:00", closeHour: "17:00" },
    { day: 3, startHour: "07:00", closeHour: "17:00" },
    { day: 4, startHour: "07:00", closeHour: "17:00" },
    { day: 5, startHour: "07:00", closeHour: "17:00" },
  ],
  isMainAddress: false,
};

export const getNewAddress = (): Address => {
  return { ...initialAddress, id: generateGUID() };
};