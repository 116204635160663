import React from "react";
import * as Icon from "commons/iconManager";
import { useDispatch, useSelector } from "react-redux";
import { MainState, UserState } from "models/new/State";
import { changeOpenMenu } from "reducers/mainReducer";
import { useNavigation } from "hooks/useNavigation";
import { useWindowSize } from "hooks/useWindowSize";
import { Label } from "controls/Label";
import { Button } from "controls/Button";
import ThemeModeComponent from "components/ThemeModeComponent";
import { TypeRoutePage } from "models/new/Dictionaries";
import { changeView } from "reducers/userReducer";

const MenuMobileComponent: React.FC = () => {
  const { isMobile } = useWindowSize();
  const mainState: MainState = useSelector((state: any) => state.main);
  const userState: UserState = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const { navigateTo, navigateLogOut } = useNavigation();

  const goTo = (path: TypeRoutePage, paramsUrl?: string) => {
    if (isMobile) {
      dispatch(changeOpenMenu());
    }
    navigateTo(path, paramsUrl);
  };

  const switchUser = () => {
    navigateTo("search");
    dispatch(changeView(userState.view === "client" ? "supplier" : "client"));
  };

  return (
    <div className={`height-fill menu ${mainState.menuOpened && !isMobile ? "menu-opened" : ""}`}>
      <div className="menu-top">
        <Button
          label="Szukaj"
          icon={<Icon.SearchSVG />}
          kind={mainState.mainRoute === "search" ? "menu-seleceted" : "menu"}
          onClick={() => goTo("search")}
          toolTip={"Szukaj na mapie"}
          toolTipPosition="right"
        />
        <Button
          label="Tablica"
          icon={<Icon.PublicSVG />}
          kind={mainState.mainRoute === "wall" ? "menu-seleceted" : "menu"}
          onClick={() => goTo("wall")}
          toolTip={"Tablica"}
          toolTipPosition="right"
        />
        <div className="sepparator">
          <Label
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "-10px",
              paddingInline: "8px",
              background: "var(--bg-primary)",
            }}
            content={mainState.menuOpened ? "Zakupy" : "Z"}
            fontColor="secondary"
            fontStyle="bold"
            fontSize="sm"
          />
        </div>
        <Button
          label="Moje zamówienia"
          icon={<Icon.ShoppingBagSVG />}
          kind={mainState.mainRoute === "orders" ? "menu-seleceted" : "menu"}
          onClick={() => goTo("orders")}
          toolTip={"Moje zamówienia"}
          toolTipPosition="right"
        />
        <div className="sepparator">
          <Label
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "-10px",
              paddingInline: "8px",
              background: "var(--bg-primary)",
            }}
            content={mainState.menuOpened ? "Sprzedaż" : "S"}
            fontColor="secondary"
            fontStyle="bold"
            fontSize="sm"
          />
        </div>
        {userState.user?.isSupplier && (
          <Button
            label="Produkty"
            icon={<Icon.GrocerySVG />}
            kind={mainState.mainRoute === "grocery" ? "menu-seleceted" : "menu"}
            onClick={() => goTo("grocery")}
            toolTip={"Produkty"}
            toolTipPosition="right"
          />
        )}
        {userState.user?.isSupplier && (
          <Button
            label="Moja sprzedaż"
            icon={<Icon.StoreSVG />}
            kind={mainState.mainRoute === "sales" ? "menu-seleceted" : "menu"}
            onClick={() => goTo("sales")}
            toolTip={"Moja sprzedaż"}
            toolTipPosition="right"
          />
        )}
        {!userState.supplier?.subscription && (
          <Button
            className="border"
            label="Dołącz do sprzedawców"
            icon={<Icon.AddBusinessSVG />}
            fontStyle="bold"
            kind={mainState.mainRoute === "sales" ? "menu-seleceted" : "menu"}
            onClick={() => goTo("profile", `?tab=subscription`)}
            toolTip={"Dołącz do sprzedawców"}
            toolTipPosition="right"
          />
        )}
      </div>
    </div>
  );
};

export default MenuMobileComponent;

/*
  {userState.user?.isSupplier && (
          <Button
            label={userState.view === "client" ? "Przełącz na sprzedawcę" : "Przełącz na klienta"}
            icon={<Icon.SwitchAccountSVG />}
            kind={"menu"}
            onClick={switchUser}
            toolTip={"Przełącz na konto"}
            toolTipPosition="right"
          />
        )}
*/

/*

      <div className="menu-bottom">
        {!userState.supplier?.subscription && (
          <Button
            className="bg-green"
            label="Dołącz do sprzedawców"
            icon={<Icon.AddBusinessSVG className="icon-black" />}
            fontColor="black"
            kind={mainState.mainRoute === "sales" ? "menu-seleceted" : "menu"}
            onClick={() => goTo("profile", `?tab=subscription`)}
            toolTip={"Dołącz do sprzedawców"}
            toolTipPosition="right"
          />
        )}

        <ThemeModeComponent />
        <Button label="Wyloguj" icon={<Icon.LogoutSVG />} kind={"menu"} onClick={navigateLogOut} />
      </div>
*/
