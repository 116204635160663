import React from "react";
import { GridColumn, GridColumnProps } from "./GridColumn";
import { GridRow } from "./GridRow";
import { Label } from "./Label";
import * as Icon from "../commons/iconManager";
import { Button } from "./Button";
import { sortArray, sortArrayDesc } from "../commons/arrayManager";

export interface GridProps extends React.TableHTMLAttributes<HTMLTableElement> {
  label?: string;
  children?: React.ReactNode;
  itemSource: any[];
}

export const Grid = React.forwardRef<HTMLTableElement, GridProps>((props, ref) => {
  const [columns, setColumns] = React.useState<GridColumnProps[]>([]);
  const [sort, setSort] = React.useState<{ sort: "desc" | "asc" | "none"; by: string }>({ sort: "none", by: "" });
  const [itemSource, setItemSource] = React.useState<any[]>([]);
  const tableRef =
    (ref as React.MutableRefObject<HTMLTableElement>) ||
    (React.createRef() as React.MutableRefObject<HTMLTableElement>);

  const column = React.useRef<GridColumnProps[]>([]);

  React.useEffect(() => {
    column.current = [];
    React.Children.forEach(props.children, (child) => {
      if (React.isValidElement(child) && child.type === GridColumn) {
        const props = child.props as GridColumnProps;
        column.current.push(props);
      }
    });
    setColumns(column.current);

    return () => {
      setColumns([]);
    };
  }, []);

  React.useEffect(() => {
    setItemSource(props.itemSource);
  }, [props.itemSource]);

  React.useEffect(() => {
    if (sort.sort === "none") {
      setItemSource(props.itemSource);
      return;
    }

    const sorted = [...props.itemSource].sort((a, b) =>
      sort.sort === "asc" ? sortArray(a, b, sort.by) : sortArrayDesc(a, b, sort.by)
    );
    setItemSource(sorted);
  }, [sort]);

  const getHeaderStyle = (column: GridColumnProps): string => {
    let style: string = "";
    switch (column.alginHeader) {
      case "start":
        style += " text-align-start";
        break;
      case "center":
        style += " text-align-center";
        break;
      case "end":
        style += " text-align-end";
        break;
      default:
        style += " text-align-start";
        break;
    }
    switch (column.verticalAlignHeader) {
      case "top":
        style += " vertical-align-top";
        break;
      case "middle":
        style += " vertical-align-middle";
        break;
      case "bottom":
        style += " vertical-align-bottom";
        break;
      default:
        style += " vertical-align-middle";
        break;
    }
    return style;
  };
  const getWidth = (width?: string | number): string => {
    if (!width || width === "*") return "auto";

    if (typeof width === "number") return width + "px";
    return width;
  };

  const getHeaderValue = (column: GridColumnProps): JSX.Element => {
    if (!column.sortableBy) return <Label fontSize="sm" fontStyle="semibold" content={column.header} />;

    return (
      <div className="d-flex ai-center gap-8" style={{ width: "min-content" }}>
        <Label fontSize="sm" fontStyle="semibold" content={column.header} />
        {sort.sort === "desc" && sort.by === column.sortableBy ? (
          <Button
            sizeFrame="sml"
            kind="text"
            icon={<Icon.SortDescSVG />}
            onClick={() => setSort({ sort: "none", by: "" })}
          />
        ) : sort.sort === "asc" && sort.by === column.sortableBy ? (
          <Button
            sizeFrame="sml"
            kind="text"
            icon={<Icon.SortAscSVG />}
            onClick={() => setSort({ sort: "desc", by: column.sortableBy! })}
          />
        ) : (
          <Button
            sizeFrame="sml"
            kind="text"
            icon={<Icon.SortSVG />}
            onClick={() => setSort({ sort: "asc", by: column.sortableBy! })}
          />
        )}
      </div>
    );
  };

  return (
    <div className="frame-main frame-scroll grid">
      <table className="width-fill">
        <thead>
          {column.current.map((column) => {
            return (
              <th className={getHeaderStyle(column)} style={{ width: getWidth(column.width) }}>
                {getHeaderValue(column)}
              </th>
            );
          })}
        </thead>
        <tbody>
          {itemSource.map((row) => {
            return <GridRow item={row} columns={column.current} />;
          })}
        </tbody>
      </table>
    </div>
  );
});
