import React from "react";
import * as Icon from "commons/iconManager";
import MessageContentComponent from "./MessageContentComponent";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "controls/Label";
import { closeConversation, showConversation } from "reducers/conversationReducer";
import { UserState } from "models/new/State";
import { AppUser } from "models/new/AppUser";
import { Chat } from "models/new/Chat";

export interface ConversationItemComponent {
  conversation: Chat;
}

const MessagePanelItemComponent: React.FC<ConversationItemComponent> = (props) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  let appUser: AppUser = props.conversation.chatMembers.find((x) => x.appUserId !== userState.user?.id)!.appUser;
  let readed: boolean = false;

  return (
    <div className={props.conversation.isOpen ? "conversation-panel-item-open" : "conversation-panel-item"}>
      <div className="d-flex gap-16 pad-4 ai-center">
        {appUser?.photoUrl ? (
          <img className="picture-25" src={appUser?.photoUrl} />
        ) : (
          <Icon.PersonSVG className="icon-color picture-25" />
        )}
        <Label
          style={{ minWidth: "135px", flex: "1" }}
          pointer
          wrap
          fontSize="sm"
          fontStyle="semibold"
          content={`${appUser?.firstName ?? "Brak"} ${appUser?.lastName ?? ""}`}
          onClick={() => dispatch(showConversation(props.conversation))}
        />
        <div className="d-flex gap-8">
          {readed && <Icon.MarkEmailUnreadSVG className="c-red" />}
          {props.conversation.isOpen ? (
            <Icon.MinimizeSVG
              style={{ cursor: "pointer" }}
              className="icon-color"
              onClick={() => dispatch(showConversation(props.conversation))}
            />
          ) : (
            <Icon.WebAssetSVG
              style={{ cursor: "pointer" }}
              className="icon-color"
              onClick={() => dispatch(showConversation(props.conversation))}
            />
          )}
          <Icon.CloseSVG
            style={{ cursor: "pointer" }}
            className="close"
            onClick={() => dispatch(closeConversation(props.conversation))}
          />
        </div>
      </div>

      <MessageContentComponent conversation={props.conversation} />
    </div>
  );
};

export default MessagePanelItemComponent;
