import React from "react";
import SearchMapComponent from "./SearchMapComponent";
import SearchManagerComponent from "./SearchManagerComponent";
import SearchFilterComponent from "./SearchFilterComponent";
import SearchBlogComponent from "./SearchSupplierPostComponent";
import { useSelector } from "react-redux";
import { MainState } from "models/new/State";

const SearchDesktopManagerComponent: React.FC = () => {
  const mainState: MainState = useSelector((state: any) => state.main);

  return (
    <div className="search-page">
      <div className="search-list">
        <SearchManagerComponent />
      </div>
      <div className={`search-filter ${mainState.showFilter && "search-filter-opened"}`}>
        <SearchFilterComponent />
      </div>
      <div className={`search-filter ${mainState.showBlog && "search-filter-opened"}`}>
        <SearchBlogComponent />
      </div>
      <div className="search-map">
        <SearchMapComponent />
      </div>
    </div>
  );
};

export default SearchDesktopManagerComponent;
