import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { calculatePercentFromOneHundred } from "commons/numberManager";
import { Tag } from "controls/Tag";
import { Product } from "models/new/Product";
import { OrderProduct } from "models/new/OrderProduct";

interface PriceProps {
  product?: Product | OrderProduct;
}

const PriceComponent: React.FC<PriceProps> = (props) => {
  if (!props.product) return <></>;

  if (props.product.salePrice) {
    return (
      <div className="d-flex fd-c" style={{ flexWrap: "wrap" }}>
        <div className="d-flex ai-e-flex gap-4" style={{ flexWrap: "wrap" }}>
          <Icon.PaymentsSVG className="icon-color" />
          <Label
            fontSize="lg"
            fontStyle="bold"
            pointer
            content={`${props.product.salePrice.toFixed(2)} zł/${props.product.unit}`}
          />
          <Tag
            sizeFrame="xsm"
            fontStyle="bold"
            kind="red"
            label={`-${calculatePercentFromOneHundred(props.product.price, props.product.salePrice!)}%`}
          />
        </div>
        <Label
          fontSize="sml"
          fontStyle="semibold"
          pointer
          content={` z ${props.product.price} zł/${props.product.unit}`}
        />
      </div>
    );
  }

  return (
    <div className="d-flex ai-e-flex gap-4" style={{ flexWrap: "wrap" }}>
      <Icon.PaymentsSVG className="icon-color" />
      <Label fontSize="md" fontStyle="semibold" pointer content={`${props.product.price.toFixed(2)} zł/${props.product.unit}`} />
    </div>
  );
};

export default PriceComponent;
