import React from "react";
import { Label } from "controls/Label";
import DealerCardComponent from "components/DealerCardComponent";
import InfoBox from "controls/InfoBox";
import { Button } from "controls/Button";
import { calculateSum } from "commons/numberManager";
import { useNavigation } from "hooks/useNavigation";
import { useDispatch, useSelector } from "react-redux";
import { setShoppingBasket } from "reducers/mainReducer";
import { UserState } from "models/new/State";
import { useLogin } from "hooks/useLogin";
import { OrderCreateDto } from "models/DTO/OrderCreateDto";
import BasketProductsComponent from "./BasketProductsComponent";
import MessageBox from "controls/MessageBox";
import { ModalDialog } from "controls/ModalDialog";

interface BasketItemProps {
  item: OrderCreateDto;
}

const BasketItemComponent: React.FC<BasketItemProps> = (props) => {
  const userState: UserState = useSelector((state: any) => state.user);
  const { navigateTo } = useNavigation();
  const dispatch = useDispatch();
  const { signIn } = useLogin();

  const goTo = async () => {
    if (!userState.token) {
      await signIn();
    } else if (props.item.products.some((x) => x.error && x.error?.length > 0)) {
      await ModalDialog.show(<MessageBox content="Nie wystarczająca ilość produktu." icon="Information" />, "", ["Ok"]);
    } else if (!userState.user?.firstName || !userState.user?.lastName) {
      await ModalDialog.show(<MessageBox content="Uzupełnij swój profil aby skorzystać z tej funkcji." />, "", ["Ok"]);
      dispatch(setShoppingBasket(false));
      navigateTo("profile");
    } else {
      if (!props.item.products.some((x) => x.quantity <= 0)) {
        dispatch(setShoppingBasket(false));
        navigateTo("basket", `?dealer_id=${props.item.supplierId}`);
      }
    }
  };

  return (
    <InfoBox className="width-fill" sizeFrame="sm" backgroundColor="secondary">
      <div className="frame-main d-flex fd-c gap-16">
        <Label fontSize="lg" fontStyle="semibold" content="Sprzedawca" />
        <DealerCardComponent supplier={props.item.supplier} />
        <Label fontSize="lg" fontStyle="semibold" content="Wybrane produkty" />
        <BasketProductsComponent products={props.item.products} />
        <div className="d-flex fd-r jc-sb ai-e-flex">
          <Label fontSize="lg" fontStyle="bold" content="Suma zakupów" />
          <Label
            fontSize="lg"
            fontStyle="bold"
            content={`${calculateSum(
              props.item.products.map((x) => {
                return x;
              })
            ).toFixed(2)} zł`}
          />
        </div>
        <Button
          className="width-fill"
          sizeFrame="md"
          kind="primary"
          fontStyle="bold"
          fontSize="md"
          label="Przejdź do podsumowania"
          onClick={goTo}
        />
      </div>
    </InfoBox>
  );
};

export default BasketItemComponent;
