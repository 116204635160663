import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { Button } from "controls/Button";

import "moment/locale/pl";
import { useWindowSize } from "hooks/useWindowSize";
var moment = require("moment");

interface DataPickerProps {
  date?: Date;
  format?: string;
  onClose: (date: Date) => void;
  onChange?: (date: Date) => void;
  selected?: string[];
}

const DataPickerComponent: React.FC<DataPickerProps> = (props) => {
  const [view, setView] = React.useState<"day" | "month" | "year">("day");
  const [date, setDate] = React.useState<Date>(props.date ?? new Date());
  const [selected, setSelected] = React.useState<string[]>(props.selected ?? []);
  const { isMobile } = useWindowSize();
  const format = props.format ?? "dd.MM.yyyy";

  React.useEffect(() => {
    setDate(props.date ?? new Date());
  }, [props.date]);

  React.useEffect(() => {
    setSelected(props.selected ?? []);
  }, [props.selected]);

  let selectedYear = date?.getFullYear() ?? 2024;
  let selectedMonth = date?.getMonth();
  let months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ];
  const onChangeDay = (day: { number: number; current: "down" | "current" | "up" }) => {
    let momentOne = moment(date);

    if (day.current === "down") {
      momentOne = moment(momentOne).add(-1, "M");
    } else if (day.current === "up") {
      momentOne = moment(momentOne).add(1, "M");
    }

    momentOne.set("date", day.number);
    setDate(momentOne.toDate());
    if (!format.includes("hh") && !format.includes("HH")) props.onClose(momentOne.toDate());
  };
  const onChangeMonth = (month: number) => {
    let momentOne = moment(date);
    momentOne.set("month", month);
    setDate(momentOne.toDate());
    setView("day");
  };
  const onChangeYear = (year: number) => {
    let momentOne = moment(date);
    momentOne.set("year", year);
    setDate(momentOne.toDate());
    setView("month");
  };
  const onChangeHour = (hour: number) => {
    let momentOne = moment(date);
    momentOne.set("hour", hour);
    setDate(momentOne.toDate());
  };
  const onChangeMinute = (minute: number) => {
    let momentOne = moment(date);
    momentOne.set("minute", minute);
    setDate(momentOne.toDate());
  };

  const onAddMonth = (value: number) => {
    let momentOne = moment(date);
    momentOne.add("month", value);
    props.onChange?.(momentOne.toDate());
    setDate(momentOne.toDate());
    setView("day");
  };

  return (
    <div className="d-flex fd-r ai-stretch gap-4" style={{ height: "290px", minWidth: "230px" }}>
      <div className="frame-main fd-c gap-8">
        <div className="d-flex fd-r ai-center jc-sb gap-4">
          <div className="d-flex fd-r ai-center jc-s-flex gap-4">
            <Button
              style={{ paddingInline: "4px", paddingBlock: "4px" }}
              sizeFrame="sm"
              kind="tertiary"
              label={selectedYear.toString()}
              onClick={() => setView("year")}
            />
            <Button
              style={{ paddingInline: "4px", paddingBlock: "4px" }}
              sizeFrame="sm"
              kind="tertiary"
              fontStyle="bold"
              label={months[selectedMonth]}
              onClick={() => setView("month")}
            />
          </div>
          {!isMobile && (
            <div className="d-flex fd-r ai-center jc-s-flex gap-4">
              <Button
                style={{ paddingInline: "4px", paddingBlock: "4px" }}
                sizeFrame="sml"
                kind="tertiary"
                icon={<Icon.ArrowBackSVG />}
                onClick={() => onAddMonth(-1)}
              />
              <Button
                style={{ paddingInline: "4px", paddingBlock: "4px" }}
                sizeFrame="sml"
                kind="tertiary"
                icon={<Icon.ArrowForwardSVG />}
                onClick={() => onAddMonth(1)}
              />
            </div>
          )}
        </div>
        <DayPickerComponent visible={view === "day"} date={date} onChange={onChangeDay} selected={selected} />
        <MonthPickerComponent visible={view === "month"} date={date} onChange={onChangeMonth} />
        <YearPickerComponent visible={view === "year"} date={date} onChange={onChangeYear} />
      </div>
      {(format.includes("hh") || format.includes("HH")) && (
        <div className="d-flex fd-c border-left mag-l-4 pad-l-8 gap-8">
          <TimePickerComponent date={date} onChangeHour={onChangeHour} onChangeMinute={onChangeMinute} />
          <Button
            style={{ width: "100%", paddingInline: "4px", paddingBlock: "4px" }}
            sizeFrame="sml"
            kind="primary"
            label="Zatwierdź"
            fontStyle="bold"
            icon={<Icon.CheckCircleSVG />}
            onClick={() => props.onClose(date)}
          />
        </div>
      )}
    </div>
  );
};

export default DataPickerComponent;

interface DayInfo {
  number: number;
  current: "down" | "current" | "up";
  date: Date;
}

const DayPickerComponent: React.FC<{
  visible: boolean;
  date?: Date;
  onChange: (day: DayInfo) => void;
  selected?: string[];
}> = (props) => {
  const [days, setDays] = React.useState<DayInfo[][]>([]);
  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    prepareView(props.date?.getFullYear() ?? 2024, props.date?.getMonth() ?? 0);
  }, [props.date]);

  let weekDays = ["pon", "wto", "śro", "czw", "pią", "sob", "nie"];
  let selectedDay = props.date?.getDate();

  const prepareView = (y: number, m: number) => {
    const month = m + 1;
    const year = y;
    let data = moment(`${year}-${month}`, "YYYY-MM");
    let beforeData = moment(`${year}-${month}`, "YYYY-MM").add(-1, "month");
    let afterData = moment(`${year}-${month}`, "YYYY-MM").add(1, "month");
    let firstDay = data.startOf("month").day() - 1;
    let numberOfDayInMonth = data.daysInMonth();
    let dayGrid: DayInfo[][] = [];
    let week: DayInfo[] = [];
    let lastDay = 0;
    if (firstDay > 0 || firstDay === -1) {
      let numberOfDayInMonthBefore = beforeData.daysInMonth();
      lastDay = moment(beforeData).endOf("month").day();

      for (let i = 1; i <= lastDay; i++) {
        const day = numberOfDayInMonthBefore - lastDay + i;
        week.push({
          number: day,
          current: "down",
          date: moment(beforeData)
            .add(day - 1, "days")
            .toDate(),
        });
      }
    }
    const lenght = 42 - lastDay;
    for (let j = 1; j <= lenght; j++) {
      if (numberOfDayInMonth < j) {
        const day = j - numberOfDayInMonth;
        week.push({
          number: j - numberOfDayInMonth,
          current: "up",
          date: moment(afterData)
            .add(day - 1, "days")
            .toDate(),
        });
      } else {
        week.push({
          number: j,
          current: "current",
          date: moment(data)
            .add(j - 1, "days")
            .toDate(),
        });
      }
      if ((firstDay + j) % 7 === 0) {
        dayGrid.push(week);
        week = [];
      }
    }

    setDays(dayGrid);
  };

  const getKindOfButton = (value: DayInfo) => {
    let momentOne = moment(props.date);

    if (value.current === "down") {
      momentOne = moment(momentOne).add(-1, "M");
    } else if (value.current === "up") {
      momentOne = moment(momentOne).add(1, "M");
    }

    momentOne.set("date", value.number);
    let sel: boolean = false;
    if (props.selected && props.selected?.length > 0) {
      sel =
        props.selected[0] === momentOne.format("DD.MM.yyyy") ||
        props.selected[props.selected.length - 1] === momentOne.format("DD.MM.yyyy");
      return props.selected?.includes(momentOne.format("DD.MM.yyyy"))
        ? (selectedDay === value.number && value.current === "current") || sel
          ? "primary"
          : "tertiary"
        : "text";
    }
    return selectedDay === value.number && value.current === "current" ? "primary" : "text";
  };

  const getOpacity = (value: DayInfo) => {
    let momentOne = moment(props.date);

    if (value.current === "down") {
      momentOne = moment(momentOne).add(-1, "M");
    } else if (value.current === "up") {
      momentOne = moment(momentOne).add(1, "M");
    }

    momentOne.set("date", value.number);
    return value.current === "current" ? "1" : props.selected?.includes(momentOne.format("DD.MM.yyyy")) ? "1" : "0.4";
  };

  return (
    <div className="d-flex fd-c jc-stretch ai-stretch gap-4" style={{ display: props.visible ? "flex" : "none" }}>
      <div className="d-flex fd-r jc-sb ai-stretch">
        {weekDays.map((day) => {
          return (
            <div className="d-flex fd-r jc-center ai-center" style={{ width: "30px", height: "20px" }}>
              <Label fontSize="sml" content={day.toString()} />
            </div>
          );
        })}
      </div>
      {days.map((day) => {
        {
          return (
            <div className="d-flex fd-r jc-sb ai-stretch">
              {day.map((d) => {
                return (
                  <Button
                    style={{ width: "30px", height: "20px", opacity: getOpacity(d) }}
                    sizeFrame={isMobile ? "sm" : "sml"}
                    kind={getKindOfButton(d)}
                    label={d.number.toString()}
                    onClick={() => props.onChange(d)}
                  />
                );
              })}
            </div>
          );
        }
      })}
    </div>
  );
};

const MonthPickerComponent: React.FC<{ visible: boolean; date?: Date; onChange: (value: number) => void }> = (
  props
) => {
  let months = [
    [
      { month: "styczeń", number: 0 },
      { month: "luty", number: 1 },
      { month: "marzec", number: 2 },
    ],
    [
      { month: "kwiecień", number: 3 },
      { month: "maj", number: 4 },
      { month: "czerwiec", number: 5 },
    ],
    [
      { month: "lipiec", number: 6 },
      { month: "sierpień", number: 7 },
      { month: "wrzesień", number: 8 },
    ],
    [
      { month: "październik", number: 9 },
      { month: "listopad", number: 10 },
      { month: "grudzień", number: 11 },
    ],
  ];

  let selectedMonth = props.date?.getMonth();
  return (
    <div
      className="d-flex fd-c jc-center ai-stretch gap-4  height-fill"
      style={{ display: props.visible ? "flex" : "none" }}
    >
      {months.map((month) => {
        {
          return (
            <div className="d-flex fd-r jc-sb ai-stretch">
              {month.map((m) => {
                return (
                  <Button
                    style={{ width: "75px", height: "45px" }}
                    sizeFrame="sml"
                    kind={m.number === selectedMonth ? "primary" : "text"}
                    label={m.month}
                    onClick={() => props.onChange(m.number)}
                  />
                );
              })}
            </div>
          );
        }
      })}
    </div>
  );
};

const YearPickerComponent: React.FC<{ visible: boolean; date?: Date; onChange: (value: number) => void }> = (props) => {
  const [years, setYears] = React.useState<number[]>([]);

  React.useEffect(() => {
    prepareYear();
  }, [props.date]);

  let selectedYear = props.date?.getFullYear() ?? 2024;
  const prepareYear = () => {
    let years: number[] = [];
    for (let index = 10; index > 0; index--) {
      years.push(selectedYear - index);
    }
    for (let index = 0; index < 10; index++) {
      years.push(selectedYear + index);
    }
    setYears(years);
  };

  return (
    <div
      className="d-flex fd-c ai-center jc-center"
      style={{ display: props.visible ? "flex" : "none", overflowY: "auto" }}
    >
      {years.map((year) => {
        {
          return (
            <Button
              style={{ width: "100%" }}
              sizeFrame="sm"
              kind={year === selectedYear ? "primary" : "text"}
              label={year.toString()}
              onClick={() => props.onChange(year)}
            />
          );
        }
      })}
    </div>
  );
};

const TimePickerComponent: React.FC<{
  date?: Date;
  onChangeHour: (value: number) => void;
  onChangeMinute: (value: number) => void;
}> = (props) => {
  const [hours, setHours] = React.useState<number[]>([]);
  const [minutes, setMinutes] = React.useState<number[]>([]);

  const scrollHoursRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const scrollMinutesRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    prepareHour();
  }, [props.date]);

  let selectedHour = props.date?.getHours() ?? 8;
  const prepareHour = () => {
    let hours: number[] = [];
    for (let index = 0; index < 24; index++) {
      hours.push(index);
    }
    setHours(hours);
    setTimeout(() => {
      //const childElementHour = document.getElementById(`hour_${selectedHour}`);
      //childElementHour?.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => {
        prepareMinutes();
      }, 400);
    }, 50);
  };

  let selectedMinutes = props.date?.getMinutes() ?? 0;
  const prepareMinutes = () => {
    let minutes: number[] = [];
    for (let index = 0; index < 60; index++) {
      minutes.push(index);
    }
    setMinutes(minutes);
    setTimeout(() => {
      //const childElement = document.getElementById(`minute_${selectedMinutes}`);
      // childElement?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 50);
  };

  return (
    <div className="frame-main fd-r jd-sb gap-8" style={{ flexDirection: "row", justifyContent: "space-between" }}>
      <div className="d-flex fd-c pad-t-8">
        <Button
          style={{ width: "100%", paddingInline: "4px", paddingBlock: "4px" }}
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.ExpandLessSVG />}
          onClick={() => props.onChangeHour(selectedHour - 1)}
        />
        <div ref={scrollHoursRef} className="frame-scroll-hidden pad-r-4">
          {hours.map((h) => {
            {
              return (
                <Button
                  id={`hour_${h}`}
                  style={{ width: "100%" }}
                  sizeFrame="sm"
                  kind={h === selectedHour ? "primary" : "text"}
                  label={h.toString()}
                  onClick={() => props.onChangeHour(h)}
                />
              );
            }
          })}
        </div>
        <Button
          style={{ width: "100%", paddingInline: "4px", paddingBlock: "4px" }}
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.ExpandMoreSVG />}
          onClick={() => props.onChangeHour(selectedHour + 1)}
        />
      </div>
      <div className="d-flex fd-c pad-t-8">
        <Button
          style={{ width: "100%", paddingInline: "4px", paddingBlock: "4px" }}
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.ExpandLessSVG />}
          onClick={() => props.onChangeMinute(selectedMinutes - 1)}
        />
        <div ref={scrollMinutesRef} className="frame-scroll-hidden pad-r-4">
          {minutes.map((m) => {
            {
              return (
                <Button
                  id={`minute_${m}`}
                  style={{ width: "100%" }}
                  sizeFrame="sm"
                  kind={m === selectedMinutes ? "primary" : "text"}
                  label={m.toString()}
                  onClick={() => props.onChangeMinute(m)}
                />
              );
            }
          })}
        </div>
        <Button
          style={{ width: "100%", paddingInline: "4px", paddingBlock: "4px" }}
          sizeFrame="sml"
          kind="tertiary"
          icon={<Icon.ExpandMoreSVG />}
          onClick={() => props.onChangeMinute(selectedMinutes + 1)}
        />
      </div>
    </div>
  );
};
