import React from "react";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionWizardState } from "models/new/State";
import { useWindowSize } from "hooks/useWindowSize";
import { InputCheckBox } from "controls/InputCheckBox";
import { changeSubscriptionWizard } from "reducers/subscriptionWizardReducer";

const SubscriptionWizardSummaryComponent: React.FC = () => {
  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const infoError = (info?: string): JSX.Element => {
    return info ? <Label wrap fontColor="red" fontSize="sml" fontStyle="bold" content={`- ${info}`} /> : <></>;
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-32 pad-8 pad-b-32 width-fill border-bottom">
        <div className="d-flex fd-c gap-16 width-fill">
          <Label fontStyle="bold" fontSize="lg" content="Regulaminy i oświadczenia" />
          <InputCheckBox
            frameClassName="width-fill"
            sizeFrame="sml"
            kind="transparent"
            fontSize="sml"
            fontStyle="semibold"
            checkboxLabel="Przeczytałem regulamin i go akceputję"
            error={subscriptionWizardState.valideError.regulations}
            checked={subscriptionWizardState.regulations}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  regulations: e.target.checked,
                  valideError: { ...subscriptionWizardState.valideError, regulations: "" },
                })
              )
            }
          />
          <InputCheckBox
            frameClassName="width-fill"
            sizeFrame="sml"
            kind="transparent"
            fontSize="sml"
            fontStyle="semibold"
            checkboxLabel="Przeczytałem politykę prywatności i ją akceptuję"
            error={subscriptionWizardState.valideError.policy}
            checked={subscriptionWizardState.policy}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  policy: e.target.checked,
                  valideError: { ...subscriptionWizardState.valideError, policy: "" },
                })
              )
            }
          />
        </div>
        <div className="d-flex fd-c gap-4 pad-b-16">
          {infoError(subscriptionWizardState.valideError.subscriptionType)}
          {infoError(subscriptionWizardState.valideError.companyName)}
          {infoError(subscriptionWizardState.valideError.firstName)}
          {infoError(subscriptionWizardState.valideError.lastName)}
          {infoError(subscriptionWizardState.valideError.email)}
          {infoError(subscriptionWizardState.valideError.phoneNumber)}
          {infoError(subscriptionWizardState.valideError.logoUrl)}
          {infoError(subscriptionWizardState.valideError.pictures)}
          {infoError(subscriptionWizardState.valideError.description)}
          {infoError(subscriptionWizardState.valideError.subscriptionType)}
          {infoError(subscriptionWizardState.valideError.address)}
          {infoError(subscriptionWizardState.valideError.addressBuildingNumber)}
          {infoError(subscriptionWizardState.valideError.addressCity)}
          {infoError(subscriptionWizardState.valideError.addressPostalCode)}
          {infoError(subscriptionWizardState.valideError.latitude)}
          {infoError(subscriptionWizardState.valideError.longitude)}
          {infoError(subscriptionWizardState.valideError.openHour)}
        </div>
      </div>
    );
  }

  return (
    <div className="frame-scroll gap-32">
      <div className="d-flex fd-c gap-32 width-fill" style={{ maxWidth: "600px" }}>
        <div className="d-flex fd-c gap-16 width-fill">
          <Label fontStyle="bold" fontSize="lg" content="Regulaminy i oświadczenia" />
          <InputCheckBox
            frameClassName="width-fill"
            sizeFrame="sml"
            kind="transparent"
            fontSize="sml"
            fontStyle="semibold"
            checkboxLabel="Przeczytałem regulamin i go akceputję"
            error={subscriptionWizardState.valideError.regulations}
            checked={subscriptionWizardState.regulations}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  regulations: e.target.checked,
                  valideError: { ...subscriptionWizardState.valideError, regulations: "" },
                })
              )
            }
          />
          <InputCheckBox
            frameClassName="width-fill"
            sizeFrame="sml"
            kind="transparent"
            fontSize="sml"
            fontStyle="semibold"
            checkboxLabel="Przeczytałem politykę prywatności i ją akceptuję"
            error={subscriptionWizardState.valideError.policy}
            checked={subscriptionWizardState.policy}
            onChange={(e) =>
              dispatch(
                changeSubscriptionWizard({
                  policy: e.target.checked,
                  valideError: { ...subscriptionWizardState.valideError, policy: "" },
                })
              )
            }
          />
        </div>
        <div className="d-flex fd-c gap-4 pad-b-16">
          {infoError(subscriptionWizardState.valideError.subscriptionType)}
          {infoError(subscriptionWizardState.valideError.companyName)}
          {infoError(subscriptionWizardState.valideError.firstName)}
          {infoError(subscriptionWizardState.valideError.lastName)}
          {infoError(subscriptionWizardState.valideError.email)}
          {infoError(subscriptionWizardState.valideError.phoneNumber)}
          {infoError(subscriptionWizardState.valideError.logoUrl)}
          {infoError(subscriptionWizardState.valideError.pictures)}
          {infoError(subscriptionWizardState.valideError.description)}
          {infoError(subscriptionWizardState.valideError.subscriptionType)}
          {infoError(subscriptionWizardState.valideError.address)}
          {infoError(subscriptionWizardState.valideError.addressBuildingNumber)}
          {infoError(subscriptionWizardState.valideError.addressCity)}
          {infoError(subscriptionWizardState.valideError.addressPostalCode)}
          {infoError(subscriptionWizardState.valideError.latitude)}
          {infoError(subscriptionWizardState.valideError.longitude)}
          {infoError(subscriptionWizardState.valideError.openHour)}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionWizardSummaryComponent;
