import React from "react";
import * as Icon from "commons/iconManager";
import { Rating } from "models/new/Rating";
import { Label } from "controls/Label";
import { calculateAVG, calculatePercent } from "commons/numberManager";

interface RatingStarProps {
  ratings?: Rating[];
  rating?: number;
  ratingQty?: number;
  selectedRate?: number;
  setSelectedRate?: (rate?: number) => void;
}

const RatingStarComponent: React.FC<RatingStarProps> = (props) => {
  const rating = calculateAVG(
    props.ratings?.map((x) => {
      return x.rate;
    })
  );

  const showStars = (): JSX.Element => {
    return (
      <div className="d-flex fd-r ai-center jc-s-flex">
        {[1, 2, 3, 4, 5].map((i) => {
          if (rating >= i) return <Icon.StarFillSVG className="c-yellow" />;
          else if (rating < i && rating > i - 1) return <Icon.StarHalfSVG className="c-yellow" />;
          else return <Icon.StarSVG className="c-yellow" />;
        })}
      </div>
    );
  };

  const lenghtOfRate = (rate: number): number => {
    return props.ratings?.filter((x) => x.rate === rate).length ?? 0;
  };

  const showProgres = (rate: number): JSX.Element => {
    const calculate = (rate: number): number => {
      const qty = lenghtOfRate(rate);
      return calculatePercent(props.ratings?.length, qty, 0);
    };

    return (
      <div
        className="d-flex fd-r gap-8 ai-center jc-s-flex width-fill"
        onClick={() => props.setSelectedRate?.(props.selectedRate === rate ? undefined : rate)}
      >
        <Label
          pointer
          fontSize="sm"
          fontStyle={props.selectedRate === rate ? "extra-bold" : "semibold"}
          content={rate.toString()}
        />
        <Label
          pointer
          fontSize="sm"
          fontStyle={props.selectedRate === rate ? "extra-bold" : "semibold"}
          content={`(${lenghtOfRate(rate)})`}
        />
        <div className="progress-track" style={{ opacity: props.selectedRate === rate ? 1 : 0.5 }}>
          <div className="progress-thumb" style={{ width: calculate(rate) + "%" }}></div>
        </div>
      </div>
    );
  };

  if (props.ratings) {
    return (
      <div className="d-flex gap-32">
        <div className="d-flex fd-c ai-center jc-sb gap-2">
          <Label
            style={{ fontSize: "56px" }}
            fontStyle="semibold"
            content={props.ratings ? rating.toString() : props.rating?.toString()}
          />
          {showStars()}
        </div>
        <div className="d-flex fd-c ai-center gap-2 width-fill">
          {[5, 4, 3, 2, 1].map((i) => {
            return showProgres(i);
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex fd-r ai-e-flex gap-2">
      <Label fontSize="sm" fontStyle="semibold" content={props.rating?.toString()} />
      <Icon.StarFillSVG className="c-yellow" />
      <Label fontSize="sm" fontStyle="regular" content={`(${props.ratingQty ?? 0})`} />
    </div>
  );
};

export default RatingStarComponent;
