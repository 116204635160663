import React from "react";
import * as SupplierApi from "api/SupplierApi";
import { SearchState } from "models/new/State";
import { useDispatch, useSelector } from "react-redux";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import ShowDealerOnCardComponent from "./ShowDealerOnCardComponent";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";
import { Supplier } from "models/new/Supplier";
import { setSupplierInfo } from "reducers/searchReducer";

const SearchProductInfoDealerComponent: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
    }, 250);
    return () => clearTimeout(timeout);
  }, [searchState.supplierProduct]);

  const downloadData = async () => {
    const supplierId = searchState.supplierProduct?.supplierId;
    if (!supplierId) return;
    if (searchState.supplierInfo && searchState.supplierInfo.id === supplierId) {
      setIsLoading(false);
      return;
    }

    const supplier = SupplierApi.getSupplier(supplierId);
    const supplierProducts = SupplierApi.getSupplierProducts(supplierId);
    const supplierAddresses = SupplierApi.getSupplierAddresses(supplierId);
    const supplierRatings = SupplierApi.getSupplierRatings(supplierId);
    const supplierPosts = SupplierApi.getSupplierPosts(supplierId);
    const supplierCertificates = SupplierApi.getSupplierCertificates(supplierId);
    const supplierPictures = SupplierApi.getSupplierPictures(supplierId);

    await Promise.all([
      supplier,
      supplierProducts,
      supplierAddresses,
      supplierRatings,
      supplierPosts,
      supplierCertificates,
      supplierPictures,
    ])
      .then((values) => {
        const supplierInfo: Supplier = values[0];
        supplierInfo.products = values[1];
        supplierInfo.addresses = values[2];
        supplierInfo.ratings = values[3];
        supplierInfo.posts = values[4];
        supplierInfo.certificates = values[5];
        supplierInfo.pictures = values[6];
        dispatch(setSupplierInfo(supplierInfo));
      })
      .catch((err) => console.log("SearchDealerInfoComponent - downloadData"))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="width-fill mag-b-16">
      {isLoading ? (
        <WaitOnLoadingComponent
          isLoading={isLoading}
          count={1}
          className="width-fill rounded-12"
          style={{ minHeight: "350px", marginTop: "8px", marginRight: "4px" }}
        />
      ) : (
        <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
          <div className="d-flex fd-c gap-16 width-fill" style={{ overflow: "hidden" }}>
            <Label fontSize="lg" fontStyle="semibold" content="Sprzedawca" />
            <ShowDealerOnCardComponent supplier={searchState.supplierInfo} />
          </div>
        </InfoBox>
      )}
    </div>
  );
};

export default SearchProductInfoDealerComponent;
