import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import AddressComponent from "components/AddressComponent";
import { Button } from "controls/Button";
import OpenHourBoxComponent from "components/OpenHourBoxComponent";
import { useDispatch } from "react-redux";
import { changeMapCenter, setOverDealer } from "reducers/searchReducer";
import { Address } from "models/new/Address";
import { useWindowSize } from "hooks/useWindowSize";
import { ModalDialog } from "controls/ModalDialog";

interface SearchAddressProps {
  address: Address;
}

const SearchAddressComponent: React.FC<SearchAddressProps> = (props) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const showPopUp = async () => {
    if (isMobile) {
      await ModalDialog.show(<OpenHourBoxComponent canEdit={false} address={props.address} />, "Godziny otwarcia");
    } else {
      dispatch(changeMapCenter({ lat: props.address.latitude, lng: props.address.longitude }));
    }
  };

  return (
    <div
      className="d-flex ai-center gap-8 pad-8 pad-l-16 border rounded-12 width-fill"
      onMouseOver={() =>
        dispatch(
          setOverDealer({
            userId: props.address.supplierId!,
            latitude: props.address.latitude,
            longitude: props.address.longitude,
          })
        )
      }
      onClick={showPopUp}
      onMouseLeave={() => dispatch(setOverDealer(undefined))}
    >
      {props.address.isMainAddress ? (
        <Icon.HomePinSVG className="icon-color" height={32} width={32} />
      ) : (
        <Icon.AddNavigationSVG className="icon-color" height={32} width={32} />
      )}
      <div className="d-flex fd-c">
        {props.address.isMainAddress ? (
          <Label fontSize="sm" fontStyle="bold" fontColor="green" content="Gospodarstwo rolne" />
        ) : (
          <Label fontSize="sm" fontStyle="bold" fontColor="green" content="Dodatkowy punkt odbioru" />
        )}
        <AddressComponent address={props.address} />
      </div>
      <Button
        style={{ marginLeft: "auto" }}
        kind="transparent"
        icon={<Icon.InfoSVG />}
        toolTip={<OpenHourBoxComponent canEdit={false} address={props.address} fontColor="tooltip" />}
        toolTipPosition="right"
      />
    </div>
  );
};

export default SearchAddressComponent;
