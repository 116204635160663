import React from "react";
import * as Icon from "commons/iconManager";
import * as ProductApi from "api/ProductApi";
import { useDispatch } from "react-redux";
import { Label } from "controls/Label";
import { InputNumber } from "controls/InputNumber";
import { changePriceProductInBasket, removeProductFromBasket, updateProductInBasket } from "reducers/basketReducer";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { OrderProduct } from "models/new/OrderProduct";
import { setShoppingBasket } from "reducers/mainReducer";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";

interface BasketProductProps {
  product: OrderProduct;
}

const BasketProductComponent: React.FC<BasketProductProps> = ({ product }) => {
  const [maxQty, setMaxQty] = React.useState<number | undefined>();
  const { navigateTo } = useNavigation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    getQuantity();
  }, []);

  const getQuantity = async () => {
    await ProductApi.getProductQuantity(product.productId)
      .then((value) => {
        setMaxQty(value.quantity);
        dispatch(
          changePriceProductInBasket({
            productId: product.productId,
            price: value.price,
            salePrice: value.salePrice,
            error: value.quantity < product.quantity ? `Dostępna ilość - ${value.quantity}` : undefined,
          })
        );
      })
      .catch((err) => alert(`BasketProductComponent - ${err}`));
  };

  const onSubmitQty = async (product: OrderProduct, qty: number) => {
    setTimeout(async () => {
      if (!qty || qty <= 0) {
        const result = await ModalDialog.show(
          <MessageBox
            content={`Ustawiłeś ilość produktu ${product.name} poniżej dopuszczalej wartości. 

Czy chcesz usunąć produkt z koszyka?`}
            icon="Question"
          />,
          "Pytanie",
          ["YesNo"]
        );
        if (result.result === "Yes") {
          dispatch(removeProductFromBasket(product));
        } else {
          dispatch(updateProductInBasket({ ...product, quantity: 1, error: undefined }));
        }
      } else {
        dispatch(updateProductInBasket({ ...product, quantity: qty, error: undefined }));
      }
    }, 200);
  };

  const goTo = (product: OrderProduct) => {
    navigateTo("search", `?product_id=${product.productId}`);
    dispatch(setShoppingBasket(false));
  };

  return (
    <div className="d-flex fd-c pad-8 gap-8 border-bottom">
      <div className="d-flex gap-4 jc-sb">
        <Label
          fontStyle="semibold"
          fontSize="sm"
          islink
          content={`${product.name} - ${product.salePrice?.toString() ?? product.price.toString()} zł/${product.unit}`}
          onClick={() => goTo(product)}
        />
        <Label
          fontStyle="semibold"
          fontSize="sm"
          content={`${((product.salePrice ?? product.price) * product.quantity).toNumberFixed(2).toFixed(2)} zł`}
        />
      </div>
      <div className="d-flex jc-sb gap-4">
        <div className="d-flex gap-4">
          <Button
            sizeFrame="sml"
            icon={<Icon.RemoveSVG />}
            disabled={product.quantity === 0}
            onClick={(e) => onSubmitQty(product, (product.quantity ?? 0) - 1)}
          />
          <InputNumber
            style={{ width: "50px" }}
            sizeFrame="sml"
            kind="secondary"
            canClear={false}
            min={0}
            fixed={0}
            max={maxQty}
            value={product.quantity}
            hideButton={true}
            onSubmit={(e) => onSubmitQty(product, e.currentTarget.valueAsNumber)}
            error={product.error}
          />
          <Button
            sizeFrame="sml"
            icon={<Icon.AddSVG />}
            onClick={(e) => onSubmitQty(product, (product.quantity ?? 0) + 1)}
          />
        </div>
        <Button
          sizeFrame="sml"
          icon={<Icon.DeleteSVG />}
          onClick={() => dispatch(removeProductFromBasket(product))}
        />
      </div>
    </div>
  );
};

export default BasketProductComponent;
