import React from "react";
import * as Icon from "commons/iconManager";
import * as ProductApi from "api/ProductApi";
import { Label } from "controls/Label";
import { useNavigation } from "hooks/useNavigation";
import { useURLParams } from "hooks/useURLParams";
import { useDispatch, useSelector } from "react-redux";
import { SearchState } from "models/new/State";
import { clearSearched, setSupplierProduct } from "reducers/searchReducer";
import SearchProductInfoPictureComponent from "./SearchProductInfoPictureComponent";
import SearchProductInfoRatingComponent from "./SearchProductInfoRatingComponent";
import SearchProductInfoDescriptionComponent from "./SearchProductInfoDescriptionComponent";
import SearchProductInfoCertificationComponent from "./SearchProductInfoCertificationComponent";
import SearchLocationComponent from "./SearchLocationComponent";
import SearchProductInfoNameComponent from "./SearchProductInfoNameComponent";
import SearchProductInfoDealerComponent from "./SearchProductInfoDealerComponent";
import SearchProductInfoOrderingComponent from "./SearchProductInfoOrderingComponent";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";
import { ProductInfo } from "models/new/ProductInfo";
import { changeMainState } from "reducers/mainReducer";
import { useWindowSize } from "hooks/useWindowSize";
import { Button } from "controls/Button";

const SearchProductInfoComponent: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { navigateTo, navigate } = useNavigation();
  const { isMobile } = useWindowSize();
  const { params } = useURLParams();
  const dealerId = params.get("dealer_id") ?? "";
  const productId = params.get("product_id") ?? "";
  const page = params.get("page");

  const searchState: SearchState = useSelector((state: any) => state.search);
  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(changeMainState({ showFilter: false }));
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
      window.scrollTo(0, 0);
    }, 250);
    return () => clearTimeout(timeout);
  }, [productId]);

  const downloadData = async () => {
    let downloadLoading: boolean = true;
    setTimeout(() => {
      setIsLoading(downloadLoading);
    }, 200);

    scrollRef.current.scroll({ top: 0, behavior: "smooth" });
    const product = ProductApi.getProduct(productId);
    const productAddresses = ProductApi.getProductAddresses(productId);
    const productRatings = ProductApi.getProductRatings(productId);
    const productCertificates = ProductApi.getProductCertificates(productId);
    const productPictures = ProductApi.getProductPictures(productId);

    await Promise.all([product, productAddresses, productRatings, productCertificates, productPictures])
      .then((values) => {
        const productInfo: ProductInfo = values[0];
        productInfo.addresses = values[1];
        productInfo.ratings = values[2];
        productInfo.certificates = values[3];
        productInfo.pictures = values[4];
        dispatch(setSupplierProduct(productInfo));
        downloadLoading = false;
        document.title = `Ogrosa | ${productInfo.name}`;
      })
      .catch((err) => console.log("SearchProductInfoComponent - downloadData"))
      .finally(() => setIsLoading(false));
  };

  const goTo = () => {
    if (page) {
      navigate(-1);
      dispatch(clearSearched());
      dispatch(changeMainState({ showBlog: false }));
    }
    if (dealerId) {
      navigateTo("search", `?dealer_id=${dealerId}`);
    } else {
      navigateTo("search");
      dispatch(clearSearched());
      dispatch(changeMainState({ showBlog: false }));
    }
  };

  if (isMobile) {
    return (
      <>
        <div id="top-page" className="f-page-top">
          <Icon.ArrowBackSVG className="i-button i-button-small" onClick={goTo} />
          <Label
            style={{ width: "84%", overflow: "hidden", textOverflow: "ellipsis" }}
            fontSize="lg"
            fontStyle="semibold"
            content={searchState.supplierProduct?.name}
          />
        </div>
        <div className="f-page-center">
          {isLoading ? (
            <WaitOnLoadingComponent
              isLoading={isLoading}
              count={10}
              className="width-fill rounded-12"
              style={{ minHeight: "215px", marginTop: "8px", marginRight: "4px" }}
            />
          ) : (
            <div ref={scrollRef} className="f-scroll gap-16">
              <SearchProductInfoPictureComponent />
              <SearchProductInfoOrderingComponent />
              <SearchProductInfoNameComponent />
              <SearchProductInfoDescriptionComponent />
              <SearchProductInfoCertificationComponent />
              <SearchLocationComponent addresses={searchState.supplierProduct?.addresses ?? []} />
              <SearchProductInfoRatingComponent />
              <SearchProductInfoDealerComponent />
              <div className="d-flex width-fill ai-center jc-sb mag-b-16">
                <Label
                  fontSize="sm"
                  wrap
                  fontStyle="regular"
                  content={`Wyświetlenia: ${searchState.supplierProduct?.visitorsQty?.toString()}`}
                />
                <Button
                  kind="text"
                  sizeFrame="sml"
                  label="Zgłoś"
                  fontColor="red"
                  icon={<Icon.FlagSVG className="c-red" />}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="f-fill fd-c gap-8">
      <div className="d-flex ai-center gap-8">
        <Icon.ArrowBackSVG className="i-button i-button-small" onClick={goTo} />
        <Label
          style={{ width: "86%", overflow: "hidden", textOverflow: "ellipsis" }}
          fontSize="lg"
          fontStyle="semibold"
          content={searchState.supplierProduct?.name}
        />
      </div>
      {isLoading ? (
        <WaitOnLoadingComponent
          isLoading={isLoading}
          count={10}
          className="width-fill rounded-12"
          style={{ minHeight: "215px", marginTop: "8px", marginRight: "4px" }}
        />
      ) : (
        <div ref={scrollRef} className="f-scroll pad-r-4 gap-8">
          <SearchProductInfoPictureComponent />
          <SearchProductInfoOrderingComponent />
          <SearchProductInfoNameComponent />
          <SearchProductInfoDescriptionComponent />
          <SearchProductInfoCertificationComponent />
          <SearchLocationComponent addresses={searchState.supplierProduct?.addresses ?? []} />
          <SearchProductInfoRatingComponent />
          <SearchProductInfoDealerComponent />
          <div className="d-flex width-fill ai-center jc-sb mag-b-8">
            <Label
              fontSize="sm"
              wrap
              fontStyle="regular"
              content={`Wyświetlenia: ${searchState.supplierProduct?.visitorsQty?.toString()}`}
            />
            <Button
              kind="text"
              sizeFrame="sml"
              label="Zgłoś"
              fontColor="red"
              icon={<Icon.FlagSVG className="c-red" />}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchProductInfoComponent;
