import React from "react";
import * as Icon from "commons/iconManager";
import EmptyComponent from "components/EmptyComponent";
import OrderMobileItemComponent from "./OrderMobileItemComponent";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { OrderState } from "models/new/State";
import { changeMainState } from "reducers/mainReducer";
import { InputDate } from "controls/InputDate";
import { changeOrderFilter } from "reducers/orderReducer";
import { DeliveryType, GroupByDate, StatusOrder } from "models/new/Dictionaries";
import { Label } from "controls/Label";
import { LazyLoadComponent } from "components/LazyLoadComponent ";
import InfoBox from "controls/InfoBox";
import { PopUp } from "controls/PopUp";
import { Order } from "models/new/Order";
import { endOfWeek, startOfWeek } from "date-fns";
import { groupByDate } from "commons/listManager";
import OrderMobileSummayComponent from "./OrderMobileSummayComponent";

const OrderMobilePage: React.FC = () => {
  const orderState: OrderState = useSelector((state: any) => state.order);
  const dispatch = useDispatch();
  const btnRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

  const checkFilter = (): number => {
    let result = 0;
    if (orderState.filter.statusOrder !== StatusOrder.All) result++;
    if (orderState.filter.deliveryType !== DeliveryType.All) result++;
    return result;
  };

  const getIcon = (groupBy: GroupByDate) => {
    switch (groupBy) {
      case GroupByDate.Day:
        return <Icon.TodaySVG />;
      case GroupByDate.Week:
        return <Icon.DateRangeSVG />;
      case GroupByDate.Month:
        return <Icon.CalendarMonthSVG />;
      case GroupByDate.All:
        return <Icon.CalendarEmptySVG />;
    }
  };

  const groupBy = async () => {
    PopUp.show(
      btnRef.current,
      <div className="d-flex fd-c width-fill">
        <Label className="mag-b-16" fontSize="sm" fontStyle="bold" content="Grupuj zamówienia" />
        {groupByDate.map((item) => {
          return (
            <Button
              style={{ width: "100%", marginBlock: "2px" }}
              sizeFrame="lg"
              kind={orderState.filter.groupByDate === item.value ? "menu-seleceted" : "menu"}
              label={item.name}
              icon={getIcon(item.value)}
              onClick={() => {
                dispatch(changeOrderFilter({ groupByDate: item.value }));
                PopUp.onHide();
              }}
            />
          );
        })}
      </div>
    );
  };

  function groupByDay(): Record<string, Order[]> {
    return orderState.orders.reduce((groups, order) => {
      const dateKey = new Date(order.createdDate).toFormatMoment("DD MMMM YYYY");
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(order);
      return groups;
    }, {} as Record<string, Order[]>);
  }

  function groupByWeek(): Record<string, Order[]> {
    return orderState.orders.reduce((groups, order) => {
      const weekStart = startOfWeek(new Date(order.createdDate), { weekStartsOn: 1 });
      const weekEnd = endOfWeek(new Date(order.createdDate), { weekStartsOn: 1 });
      const dateKey = `Od ${weekStart.toFormatMoment("DD MMMM YYYY")} do ${weekEnd.toFormatMoment("DD MMMM YYYY")}`;
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(order);
      return groups;
    }, {} as Record<string, Order[]>);
  }

  function groupByMonth(): Record<string, Order[]> {
    return orderState.orders.reduce((groups, order) => {
      const dateKey = new Date(order.createdDate).toFormatMoment("MMMM YYYY");
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(order);
      return groups;
    }, {} as Record<string, Order[]>);
  }

  const getGroupBy = () => {
    return orderState.filter.groupByDate === GroupByDate.Day
      ? groupByDay()
      : orderState.filter.groupByDate === GroupByDate.Week
      ? groupByWeek()
      : groupByMonth();
  };

  const showSummary = async (key: string, orders: Order[]) => {
    PopUp.show(btnRef.current, <OrderMobileSummayComponent title={key} orders={orders} />);
  };

  const showGroup = () => {
    const group = getGroupBy();

    return Object.keys(group).map((item) => {
      return (
        <>
          <LazyLoadComponent>
            <div className="d-flex ai-center jc-sb width-fill pad-t-16">
              <Label className="pad-t-8 pad-b-8" fontSize="sml" fontStyle="bold" content={item} />
              <Button
                sizeFrame="sm"
                kind="text"
                icon={<Icon.MoreHorizSVG />}
                onClick={() => showSummary(item, group[item])}
              />
            </div>
            <InfoBox className="width-fill" backgroundColor="secondary">
              <div className="d-flex fd-c ai-center width-fill">
                {group[item].map((val, idx) => {
                  return (
                    <OrderMobileItemComponent
                      order={val}
                      className={`rounded-0 ${group[item].length - 1 !== idx ? "border-bottom " : ""}`}
                    />
                  );
                })}
              </div>
            </InfoBox>
          </LazyLoadComponent>
        </>
      );
    });
  };

  return (
    <>
      <div id="top-page" className="f-page-top">
        <InputDate
          sizeFrame="sm"
          kind="tertiary"
          rangeValue={{ from: orderState.filter.dateFrom, to: orderState.filter.dateTo }}
          onChangeRange={(e) => dispatch(changeOrderFilter({ ...orderState.filter, dateFrom: e.from, dateTo: e.to }))}
        />
        <Button
          ref={btnRef}
          id="group-button"
          sizeFrame="md"
          kind={"tertiary"}
          icon={<Icon.EventListSVG />}
          onClick={() => groupBy()}
          style={{ marginLeft: "auto" }}
        />
        <Button
          id="filter-button"
          sizeFrame="md"
          kind={"tertiary"}
          icon={<Icon.TuneSVG />}
          onClick={() => dispatch(changeMainState({ showFilterOrder: true }))}
          count={checkFilter()}
          style={{ marginLeft: "auto" }}
        />
      </div>
      <div className="f-page-center fd-c mag-b-16">
        {orderState.orders && orderState.orders?.length > 0 ? (
          <>
            <>
              <Label
                className="pad-t-8 pad-b-8"
                fontSize="sml"
                fontStyle="bold"
                style={{ marginLeft: "auto" }}
                content={`Ilość wszystkich zamówień: ${orderState.orders.length ?? 0}`}
              />
              {showGroup()}
            </>
          </>
        ) : (
          <EmptyComponent style={{ height: "50%" }} icon={<Icon.DescriptionSVG />} message="Brak zamówień" />
        )}
      </div>
    </>
  );
};

export default OrderMobilePage;
