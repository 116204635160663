import React from "react";
import * as Icon from "commons/iconManager";
import * as OrderApi from "api/OrderApi";
import PathComponent from "components/PathPageComponent";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { OrderState, UserState } from "models/new/State";
import OrdersItemComponent from "./OrdersItemComponent";
import { useURLParams } from "hooks/useURLParams";
import { setSelectedOrder } from "reducers/orderReducer";
import { useWindowSize } from "hooks/useWindowSize";
import OrderMobileManagerComponent from "./mobile/OrderMobileManagerComponent";
import { getLastStatus } from "models/new/OrderStatus";
import { StatusOrder } from "models/new/Dictionaries";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import { changeMainState } from "reducers/mainReducer";

const OrdersManagerComponent: React.FC = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const orderState: OrderState = useSelector((state: any) => state.order);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { params } = useURLParams();
  const id = params.get("id") ?? "";

  React.useEffect(() => {
    return () => {
      dispatch(setSelectedOrder(undefined));
    };
  }, []);

  React.useEffect(() => {
    if (!orderState.selected) downloadData();
  }, [dispatch, orderState.selected]);

  const downloadData = async () => {
    await OrderApi.getById(id)
      .then((value) => {
        dispatch(setSelectedOrder(value));
        document.title = `Ogrosa | Zamówienie - ${value.code}`;
      })
      .catch((err) => alert("OrdersManagerComponent downloadData"));
  };

  const onChangeStatus = async () => {
    const result = await ModalDialog.show(
      <MessageBox content={`Czy napewno chcesz anulować zamówienie?`} icon="Question" />,
      "Zmiana statusu",
      ["YesNo"]
    );
    if (result.result === "Yes") {
      OrderApi.putchangeOrderStatus(userState.user!.id, orderState.selected!.id, StatusOrder.Canceled)
        .then(() => {
          dispatch(setSelectedOrder(undefined));
        })
        .catch((err) => alert("SalesItemDetailsComponent onChangeStatus"));
    }
  };

  if (isMobile) return <OrderMobileManagerComponent />;

  return (
    <div className="f-fill">
      <div className="frame-header jc-sb">
        <PathComponent />
        <div className="d-flex fd-r gap-8">
          <Button
            sizeFrame="sm"
            kind="tertiary"
            label="Export"
            fontStyle="bold"
            icon={<Icon.ShareSVG />}
            onClick={() => alert("Funkcjonalność w budowie")}
          />
          <Button
            sizeFrame="sm"
            kind="tertiary"
            label="Drukuj"
            fontStyle="bold"
            icon={<Icon.PrintSVG />}
            onClick={() => alert("Funkcjonalność w budowie")}
          />
          {orderState.selected && getLastStatus(orderState.selected.statuses) === StatusOrder.Completed && (
            <Button
              sizeFrame="sm"
              kind="tertiary"
              label="Oceń i skomentuj"
              fontStyle="bold"
              icon={<Icon.StarSVG />}
              onClick={() => dispatch(changeMainState({ showOrderRating: true }))}
            />
          )}
          {orderState.selected && getLastStatus(orderState.selected.statuses) === StatusOrder.New && (
            <Button sizeFrame="sm" kind="cancel" label="Anuluj zamówienie" fontStyle="bold" onClick={onChangeStatus} />
          )}
        </div>
      </div>
      <OrdersItemComponent order={orderState.selected} />
    </div>
  );
};

export default OrdersManagerComponent;
