import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { BasketState, ConversationState, NotificationState, UserState } from "models/new/State";
import { changeTheme, setShoppingBasket, setShowMail, setShowNotification } from "reducers/mainReducer";
import { ToolTip } from "controls/ToolTip";
import { useLogin } from "hooks/useLogin";
import { Badge } from "controls/Badge";
import { useURLParams } from "hooks/useURLParams";
import { setBaskets } from "reducers/basketReducer";
import { useWindowSize } from "hooks/useWindowSize";
import { useNavigation } from "hooks/useNavigation";
import { MessageStatusType } from "models/new/Dictionaries";
import { PopUp } from "controls/PopUp";
import { Label } from "controls/Label";
import ThemeModeComponent from "components/ThemeModeComponent";

const MenuRightComponent: React.FC = () => {
  const [showBasketBadge, setShowBasketBadge] = React.useState<boolean>(false);
  const [showNotificationBadge, setShowNotificationBadge] = React.useState<boolean>(false);
  const [showMailBadge, setShowMailBadge] = React.useState<boolean>(false);

  const { params } = useURLParams();
  const { isMobile } = useWindowSize();
  const { navigateTo, navigateLogOut } = useNavigation();

  const { signIn, resetPassword, checkToken, confirmEmail } = useLogin();

  const userState: UserState = useSelector((state: any) => state.user);
  const notificationState: NotificationState = useSelector((state: any) => state.notification);
  const basketState: BasketState = useSelector((state: any) => state.basket);
  const conversationState: ConversationState = useSelector((state: any) => state.conversation);

  const dispatch = useDispatch();

  const basketIconRef = React.useRef() as React.MutableRefObject<SVGSVGElement>;
  const mailIconRef = React.useRef() as React.MutableRefObject<SVGSVGElement>;
  const notifyIconRef = React.useRef() as React.MutableRefObject<SVGSVGElement>;
  const profileRef = React.useRef() as React.MutableRefObject<SVGSVGElement>;
  const imgRef = React.useRef() as React.MutableRefObject<HTMLImageElement>;

  React.useEffect(() => {
    checkToken();
    const resetCode = params.get("resetCode");
    if (resetCode) {
      setTimeout(() => {
        resetPassword();
      }, 200);
    }
    const confirmCode = params.get("confirmCode");
    if (confirmCode) {
      confirmEmail();
    }
  }, []);

  const changeThemeStyle = React.useCallback((theme: string | null) => {
    if (theme === "dark") {
      document.body.classList.replace("light", "dark");
      localStorage.setItem("theme", "dark");
      dispatch(changeTheme("dark"));
    } else {
      document.body.classList.replace("dark", "light");
      localStorage.setItem("theme", "light");
      dispatch(changeTheme("light"));
    }
  }, []);

  React.useEffect(() => {
    const theme = localStorage.getItem("theme");
    changeThemeStyle(theme);
  }, [dispatch]);

  React.useEffect(() => {
    if (!isMobile) {
      ToolTip.show(basketIconRef.current, "Koszyk produktów", "left");
      ToolTip.show(mailIconRef.current, "Wiadomości", "left");
      ToolTip.show(notifyIconRef.current, "Powiadomienia", "left");
      ToolTip.show(profileRef.current, "Profil użytkownika", "left");
    }

    if (basketState.baskets.length === 0) {
      const baskets = localStorage.getItem("baskets");
      if (baskets) {
        try {
          const parsedBaskets = JSON.parse(baskets);
          dispatch(setBaskets(parsedBaskets));
        } catch (error) {
          console.error("Failed to parse baskets cookie: ", error);
        }
      }
    }
  }, [userState.token]);

  React.useEffect(() => {
    setShowNotificationBadge(notificationState.notification.some((x) => !x.isRead));
  }, [notificationState.notification]);

  React.useEffect(() => {
    //localStorage.removeItem("baskets");
    setShowBasketBadge(basketState.baskets.length > 0);
    setTimeout(() => {
      localStorage.setItem("baskets", JSON.stringify(basketState.baskets));
    }, 1000);
  }, [basketState.baskets]);

  React.useEffect(() => {
    setShowMailBadge(
      conversationState.conversations.some((x) => {
        const messages = x.chatMessages.find((x) => x.appUserId !== userState.user?.id)?.chatMessageStatuses;
        return messages?.some((x) => x.status !== MessageStatusType.Read);
      })
    );
  }, [conversationState.conversations]);

  const showProfilPopup = async () => {
    await PopUp.show(imgRef.current ?? profileRef.current, popUpProfil());
  };

  const popUpProfil = () => {
    return (
      <div className="d-flex fd-c ai-center" style={{ minWidth: "200px" }}>
        <div className="d-flex fd-c ai-center gap-4 mag-16">
          {userState.supplier?.logoFile ? (
            <img className="picture-50" src={userState.supplier.logoUrl} />
          ) : userState.user?.photoUrl ? (
            <img className="picture-50" src={userState.user?.photoUrl} />
          ) : (
            <Icon.AccountCircleSVG className="icon-color" height={50} width={50} />
          )}
          <Label fontSize="sml" content={`${userState.user?.firstName ?? ""} ${userState.user?.lastName ?? ""}`} />
          <Label fontSize="sml" content={userState.user?.email ?? ""} />
        </div>
        <Button
          label="Przejdź na profil"
          sizeFrame="lg"
          icon={<Icon.PersonSVG />}
          kind={"menu"}
          onClick={() => {
            navigateTo("profile", `?tab=data`);
            PopUp.onHide();
          }}
          fontStyle="semibold"
        />
        <ThemeModeComponent />
        <Button
          label="Wyloguj"
          sizeFrame="lg"
          icon={<Icon.LogoutSVG />}
          kind={"menu"}
          onClick={() => {
            navigateLogOut();
            PopUp.onHide();
          }}
          fontStyle="semibold"
        />
      </div>
    );
  };

  const showBasket = () => {
    dispatch(setShoppingBasket(true));
    setShowBasketBadge(false);
  };

  const showNotification = () => {
    dispatch(setShowNotification(true));
  };

  //return <></>;

  return (
    <div className="menu-right">
      <Badge show={showBasketBadge}>
        <Icon.ShoppingCartSVG ref={basketIconRef} className="i-button i-button-small" onClick={showBasket} />
      </Badge>
      {userState.token !== undefined ? (
        <>
          <Badge show={showMailBadge}>
            <Icon.MailSVG
              ref={mailIconRef}
              className="i-button i-button-small"
              onClick={() => dispatch(setShowMail(true))}
            />
          </Badge>
          <Badge show={showNotificationBadge}>
            <Icon.NotificationsSVG ref={notifyIconRef} className="i-button i-button-small" onClick={showNotification} />
          </Badge>
          {userState.supplier?.logoFile ? (
            <img
              ref={imgRef}
              className="picture-25 c-pointer i-button i-button-small"
              src={userState.supplier.logoUrl}
              onClick={showProfilPopup}
            />
          ) : userState.user?.photoUrl ? (
            <img
              ref={imgRef}
              className="picture-25 c-pointer i-button i-button-small"
              src={userState.user?.photoUrl}
              onClick={showProfilPopup}
            />
          ) : (
            <Icon.AccountCircleSVG ref={profileRef} className="i-button i-button-small" onClick={showProfilPopup} />
          )}
        </>
      ) : (
        <>
          <Button
            sizeFrame="sm"
            kind="primary"
            fontStyle="bold"
            label="Zaloguj się"
            onClick={signIn}
            icon={<Icon.PersonSVG />}
          />
        </>
      )}
    </div>
  );
};

export default MenuRightComponent;
