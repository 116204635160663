import React from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import * as GoogleMaps from "commons/googleManager";
import { Label } from "controls/Label";
import { Address } from "models/new/Address";
import SearchComponent from "src/pages/search/components/SearchBoxLocalizationComponent";
import { PopUp } from "controls/PopUp";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionWizardState } from "models/new/State";
import { changeSubscriptionAddressWizard, setSubscriptionValideError } from "reducers/subscriptionWizardReducer";
import InfoBox from "controls/InfoBox";
import { useWindowSize } from "hooks/useWindowSize";
import { OpenHourEdit } from "models/OpenHourEdit";
import OpenHourEdit2Component from "components/OpenHourEdit2Component";

const SubscriptionWizardAddressComponent: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchGeolocation, setSearchGeolocation] = React.useState<
    { name: string; prediction: google.maps.places.AutocompletePrediction }[]
  >([]);
  const { isMobile } = useWindowSize();

  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const dispatch = useDispatch();

  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const divRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const wizardState = React.useRef<SubscriptionWizardState>(subscriptionWizardState);

  React.useEffect(() => {
    wizardState.current = subscriptionWizardState;
  }, [subscriptionWizardState]);

  React.useEffect(() => {
    return () => {
      !isMobile &&
        dispatch(
          setSubscriptionValideError({
            address: wizardState.current.address
              ? undefined
              : subscriptionWizardState.valideError?.address ?? "Wprowadź adres gospodarstwa.",
          })
        );
    };
  }, []);

  const onChangeInput = (value: string) => {
    if (value.length === 0) {
      setSearchGeolocation([]);
    }

    setSearchValue(value);
  };

  const onChange = async (search: string) => {
    const pred: { name: string; prediction: google.maps.places.AutocompletePrediction }[] = [];

    if (search && search.length >= 3) {
      const result = await GoogleMaps.autoCompleteService(search);
      result?.forEach((place) => {
        pred.push({ name: place.description, prediction: place });
      });
    }

    setSearchGeolocation(pred);
    PopUp.onRefresh(
      <SearchComponent
        value={search}
        itemSource={pred}
        onChange={onChange}
        onDisplayItem={onDisplayItem}
        onSubmit={(item) => onSelectedChange(item)}
      />
    );
  };

  const showPopUp = async () => {
    await PopUp.show(
      divRef.current,
      <SearchComponent
        value={searchValue}
        itemSource={searchGeolocation}
        onChange={onChange}
        onDisplayItem={onDisplayItem}
        onSubmit={(item) => onSelectedChange(item)}
      />,
      "onto"
    );
  };

  const onDisplayItem = (item: any, selected?: any) => {
    return (
      <div
        className={`search-box ${item === selected && "search-box-selected"}`}
        onClick={() => onSelectedChange(item)}
      >
        <Icon.LocationOnSVG className="icon-search icon-color" />
        <Label fontSize="sm" fontStyle="semibold" pointer content={item.name} />
      </div>
    );
  };

  const onSelectedChange = async (item: any) => {
    PopUp.onHide();
    inputRef.current.value = item.name;
    const result = await GoogleMaps.placesService(item.prediction.place_id);
    const findAddress = await GoogleMaps.searchByLoc(result.lat, result.lng);
    let newAddress: Address | undefined = GoogleMaps.getAddress(findAddress);
    newAddress &&
      dispatch(
        changeSubscriptionAddressWizard({
          address: newAddress,
          error: {
            address: "",
            addressBuildingNumber: "",
            addressCity: "",
            addressPostalCode: "",
            latitude: "",
            longitude: "",
          },
        })
      );
    setTimeout(() => {
      setSearchValue(item.name);
    }, 200);
  };

  const end = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const findAddress = await GoogleMaps.searchByLoc(e.latLng.lat(), e.latLng.lng());
      let newAddress: Address | undefined = GoogleMaps.getAddress(findAddress);
      if (newAddress) {
        newAddress = { ...newAddress, latitude: e.latLng.lat(), longitude: e.latLng.lng() };
        dispatch(
          changeSubscriptionAddressWizard({
            address: newAddress,
            error: {
              address: "",
              addressBuildingNumber: "",
              addressCity: "",
              addressPostalCode: "",
              latitude: "",
              longitude: "",
            },
          })
        );
      }
    }
  };

  const onChangeOpenHours = (items: OpenHourEdit[]) => {
    dispatch(changeSubscriptionAddressWizard({ address: { openHours: items.map((x) => x.hour) } }));
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-32 pad-8 pad-b-32 width-fill border-bottom">
        <div className="d-flex fd-c gap-4 width-fill">
          <Label fontStyle="bold" fontSize="lg" content="Adres gospodarstwa" />
          <Label
            fontStyle="semibold"
            fontSize="sm"
            fontColor="secondary"
            wrap
            content="W celu wskazania adresu gospodarstawa wyszukaj swoją lokalizacje, przeciągnij pineskę na mapie lub wprowadź dane ręcznie."
          />
        </div>
        <div className="d-flex fd-c gap-16 width-fill">
          <Label fontStyle="semibold" fontSize="md" wrap content="Użyj wyszukiwarki adresów" />
          <InputText
            frameClassName="width-fill"
            frameRef={divRef}
            ref={inputRef}
            sizeFrame="md"
            kind="tertiary"
            placeholder="np. Zamkowa 14/12 Warszawa"
            value={searchValue}
            icon={<Icon.MyLocationSVG />}
            onClick={showPopUp}
            onChange={(e) => onChangeInput(e.target.value)}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Ulica"
            autoComplete="address-line1"
            value={subscriptionWizardState?.address?.street}
            onChange={(e) => dispatch(changeSubscriptionAddressWizard({ address: { street: e.currentTarget.value } }))}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer domu"
            autoComplete="address-line2"
            isRequired
            error={subscriptionWizardState.valideError?.addressBuildingNumber}
            value={subscriptionWizardState?.address?.buildingNumber}
            onChange={(e) =>
              dispatch(
                changeSubscriptionAddressWizard({
                  address: { buildingNumber: e.currentTarget.value },
                  error: { addressBuildingNumber: "", address: "", latitude: "", longitude: "" },
                })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer lokalu"
            value={subscriptionWizardState?.address?.localNumber}
            onChange={(e) =>
              dispatch(changeSubscriptionAddressWizard({ address: { localNumber: e.currentTarget.value } }))
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Kod pocztowy"
            autoComplete="postal-code"
            isRequired
            error={subscriptionWizardState.valideError?.addressPostalCode}
            value={subscriptionWizardState?.address?.postalCode}
            onChange={(e) =>
              dispatch(
                changeSubscriptionAddressWizard({
                  address: { postalCode: e.currentTarget.value },
                  error: { addressPostalCode: "", address: "", latitude: "", longitude: "" },
                })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Miejscowość"
            autoComplete="address-level2"
            isRequired
            error={subscriptionWizardState.valideError?.addressCity}
            value={subscriptionWizardState?.address?.city}
            onChange={(e) =>
              dispatch(
                changeSubscriptionAddressWizard({
                  address: { city: e.currentTarget.value },
                  error: { addressCity: "", address: "", latitude: "", longitude: "" },
                })
              )
            }
          />
          <div className="width-fill d-flex fd-c gap-8 pad-8 ai-s-flex border rounded-12">
            {subscriptionWizardState.valideError?.openHour && (
              <InfoBox
                className="width-fill ai-center"
                backgroundColor="red"
                sizeFrame="xsm"
                icon="Error"
                iconSize="xsm"
              >
                <Label
                  wrap
                  pointer
                  fontSize="sm"
                  fontStyle="bold"
                  fontColor="black"
                  content={subscriptionWizardState.valideError?.openHour}
                />
              </InfoBox>
            )}
            <Label wrap fontSize="md" fontStyle="bold" content="Godziny otwarcia" />
            <OpenHourEdit2Component
              openHours={subscriptionWizardState.address?.openHours}
              onChangeOpenHours={onChangeOpenHours}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="frame-scroll gap-32 pad-r-4">
      <div className="d-flex gap-8 ai-center" style={{ width: "32px" }}>
        <Label fontStyle="bold" content="Adres gospodarstwa" />
        <Button
          kind="transparent"
          sizeFrame="sml"
          style={{ padding: "0px" }}
          icon={<Icon.InfoSVG />}
          toolTip="W celu wskazania adresu gospodarstawa wyszukaj swoją lokalizacje, przeciągnij pineskę na mapie lub wprowadź dane ręcznie"
        />
      </div>
      {subscriptionWizardState.valideError?.address && (
        <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
          <Label
            wrap
            pointer
            fontStyle="semibold"
            fontColor="black"
            content={subscriptionWizardState.valideError?.address}
          />
        </InfoBox>
      )}
      <InputText
        frameRef={divRef}
        ref={inputRef}
        style={{ width: "460px" }}
        sizeFrame="md"
        kind="tertiary"
        placeholder="np. Zamkowa 14/12 Warszawa"
        value={searchValue}
        icon={<Icon.MyLocationSVG />}
        onClick={showPopUp}
        onChange={(e) => onChangeInput(e.target.value)}
      />
      <div className="d-flex fd-r width-fill gap-32">
        <div className="d-flex fd-c gap-16 width-fill" style={{ maxWidth: "320px" }}>
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Ulica"
            autoComplete="address-line1"
            value={subscriptionWizardState?.address?.street}
            onChange={(e) => dispatch(changeSubscriptionAddressWizard({ address: { street: e.currentTarget.value } }))}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer domu"
            autoComplete="address-line2"
            isRequired
            error={subscriptionWizardState.valideError?.addressBuildingNumber}
            value={subscriptionWizardState?.address?.buildingNumber}
            onChange={(e) =>
              dispatch(
                changeSubscriptionAddressWizard({
                  address: { buildingNumber: e.currentTarget.value },
                  error: { addressBuildingNumber: "", address: "", latitude: "", longitude: "" },
                })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer lokalu"
            value={subscriptionWizardState?.address?.localNumber}
            onChange={(e) =>
              dispatch(changeSubscriptionAddressWizard({ address: { localNumber: e.currentTarget.value } }))
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Kod pocztowy"
            autoComplete="postal-code"
            isRequired
            error={subscriptionWizardState.valideError?.addressPostalCode}
            value={subscriptionWizardState?.address?.postalCode}
            onChange={(e) =>
              dispatch(
                changeSubscriptionAddressWizard({
                  address: { postalCode: e.currentTarget.value },
                  error: { addressPostalCode: "", address: "", latitude: "", longitude: "" },
                })
              )
            }
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Miejscowość"
            autoComplete="address-level2"
            isRequired
            error={subscriptionWizardState.valideError?.addressCity}
            value={subscriptionWizardState?.address?.city}
            onChange={(e) =>
              dispatch(
                changeSubscriptionAddressWizard({
                  address: { city: e.currentTarget.value },
                  error: { addressCity: "", address: "", latitude: "", longitude: "" },
                })
              )
            }
          />
        </div>
        <div className="d-flex fd-c width-fill">
          <div className="width-fill d-flex fd-c gap-8 pad-8 ai-s-flex border rounded-12">
            {subscriptionWizardState.valideError?.openHour && (
              <InfoBox
                className="width-fill ai-center"
                backgroundColor="red"
                sizeFrame="xsm"
                icon="Error"
                iconSize="xsm"
              >
                <Label
                  wrap
                  pointer
                  fontSize="sm"
                  fontStyle="bold"
                  fontColor="black"
                  content={subscriptionWizardState.valideError?.openHour}
                />
              </InfoBox>
            )}
            <Label wrap fontSize="md" fontStyle="bold" content="Godziny otwarcia" />
            <OpenHourEdit2Component
              openHours={subscriptionWizardState.address?.openHours}
              onChangeOpenHours={onChangeOpenHours}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionWizardAddressComponent;
