import InfoBox from "controls/InfoBox";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";

const LandingShipmentComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  const showInfo = (title: string, info: string) => {
    return (
      <div className="d-flex gap-8">        
        <Icon.CheckCircleFillSVG className="c-green" style={{ minHeight: "24px", minWidth: "24px" }} />
        <div className="d-flex fd-c">
          <Label wrap fontColor="black" fontSize="lg" fontStyle="bold" content={title} />
          <Label wrap fontColor="black" fontSize="sm" fontStyle="semibold" content={info} />
        </div>
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c ai-center gap-32 width-fill mag-t-32">
     <InfoBox sizeFrame="md" iconSize="sm" className="width-fill" style={{ borderRadius: "35px" }}>
          <div className="d-flex fd-c gap-16">
            <Label
              wrap
              fontColor="black"
              fontStyle="semibold"
              fontSize="lg"
              content="Odkrywaj lokalnych sprzedawców i ciesz się świeżymi produktami dzięki naszej aplikacji! W łatwy sposób znajdziesz rolników w swojej okolicy i będziesz mógł kupić ich produkty bezpośrednio od nich."
            />
            <Label wrap fontColor="black" fontStyle="extra-bold" fontSize="xl" content="Dlaczego warto?" />
            {showInfo(
              "Świeżość prosto z farmy",
              "Nasza aplikacja umożliwia szybkie wyszukiwanie lokalnych gospodarstw, co pozwala Ci cieszyć się produktami najwyższej jakości."
            )}
            {showInfo(
              "Odbiór osobisty",
              "Możesz odwiedzić gospodarstwa, aby zobaczyć, skąd pochodzi Twoje jedzenie, lub wybrać odbiór w punkcie sprzedawcy."
            )}
            {showInfo(
              "Wsparcie dla lokalnej społeczności",
              "Bezpośrednie zakupy od dostawców pomagają wspierać lokalnych przedsiębiorców i rozwijać społeczność."
            )}
            {showInfo(
              "Bezpośrednia interakcja",
              "Skontaktuj się bezpośrednio z rolnikami, by uzyskać najświeższe produkty dla siebie i swojej rodziny."
            )}
            <Label
              wrap
              fontColor="black"
              fontStyle="extra-bold"
              fontSize="lg"
              content={`Zacznij korzystać z naszej aplikacji już dziś i ciesz się zdrowym, lokalnym jedzeniem każdego dnia!`}
            />
          </div>
        </InfoBox>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c ai-center gap-32 width-fill" style={{ maxWidth: "1136px", marginBlock: "64px" }}>
      <InfoBox sizeFrame="md" icon="Information" iconSize="sm" className="width-fill" style={{ borderRadius: "35px" }}>
        <div className="d-flex fd-c gap-16">
          <Label
            wrap
            fontColor="black"
            fontStyle="semibold"
            fontSize="lg"
            content="Odkrywaj lokalnych sprzedawców i ciesz się świeżymi produktami dzięki naszej aplikacji! W łatwy sposób znajdziesz rolników w swojej okolicy i będziesz mógł kupić ich produkty bezpośrednio od nich."
          />
          <Label wrap fontColor="black" fontStyle="extra-bold" fontSize="xl" content="Dlaczego warto?" />
          {showInfo(
            "Świeżość prosto z farmy",
            "Nasza aplikacja umożliwia szybkie wyszukiwanie lokalnych gospodarstw, co pozwala Ci cieszyć się produktami najwyższej jakości."
          )}
          {showInfo(
            "Odbiór osobisty",
            "Możesz odwiedzić gospodarstwa, aby zobaczyć, skąd pochodzi Twoje jedzenie, lub wybrać odbiór w punkcie sprzedawcy."
          )}
          {showInfo(
            "Wsparcie dla lokalnej społeczności",
            "Bezpośrednie zakupy od dostawców pomagają wspierać lokalnych przedsiębiorców i rozwijać społeczność."
          )}
          {showInfo(
            "Bezpośrednia interakcja",
            "Skontaktuj się bezpośrednio z rolnikami, by uzyskać najświeższe produkty dla siebie i swojej rodziny."
          )}
          <Label
            wrap
            fontColor="black"
            fontStyle="extra-bold"
            fontSize="lg"
            content={`Zacznij korzystać z naszej aplikacji już dziś i ciesz się zdrowym, lokalnym jedzeniem każdego dnia!`}
          />
        </div>
      </InfoBox>      
    </div>
  );
};

export default LandingShipmentComponent;


/*
<Label fontSize="xxl" fontStyle="extra-bold" wrap content="Sposób dostawy" />
      <div className="d-flex jc-sb width-fill gap-32">
        <div className="d-flex fd-c ai-center gap-16">
          <img className="l-img-box-c" src="/img/farm7.jpg" height={300} />
          <Label wrap fontSize="xl" fontStyle="extra-bold" content="Odbiór osobisty" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ textAlign: "center" }}
            content="Skorzystaj z możliwości odbioru osobistego, aby spotkać się z lokalnymi dostawcami 
            i zobaczyć, jak tworzone są Twoje ulubione produkty. Odbierz świeże produkty bezpośrednio od rolnika w wyznaczonym miejscu i czasie."
          />
        </div>
        <div className="d-flex fd-c ai-center gap-16">
          <img className="l-img-box-c" src="/img/farm4.jpg" height={300} />
          <Label
            wrap
            fontSize="xl"
            fontStyle="extra-bold"
            style={{ textAlign: "center" }}
            content="Odbiór w punkcie, jak targowisko lub osiedle"
          />
          <Label
            wrap
            fontStyle="semibold"
            style={{ textAlign: "center" }}
            content="Wybierz odbiór w punkcie sprzedaży, taki jak lokalne targowisko czy wyznaczone miejsce na osiedlu. 
            To wygodna opcja, by połączyć zakupy z codziennymi wyjściami i cieszyć się świeżością lokalnych produktów."
          />
        </div>
        <div className="d-flex fd-c ai-center gap-16">
          <img className="l-img-box-c" src="/img/farm2.jpg" height={300} />
          <Label wrap fontSize="xl" fontStyle="extra-bold" content="Wysyłka pod adres" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ textAlign: "center" }}
            content="Dla wybranych produktów dostępna jest również opcja dostawy bezpośrednio pod Twój adres. 
            Idealne rozwiązanie, gdy potrzebujesz wygody i oszczędności czasu. Zamów, a my dostarczymy 
            zakupy prosto do Twoich drzwi."
          />
        </div>
      </div>
*/