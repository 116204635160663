import React from "react";
import ReactDOM from "react-dom/client";
import { getElementLeftRelativeToDocument, getElementTopRelativeToDocument } from "./PopUp";

export type ToolTipPosition = "left" | "right" | "top" | "bottom";

export const ToolTip = {
  isVisible: false,
  show: (
    element: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement,
    content: string | JSX.Element,
    position: ToolTipPosition = "top",
    delay: number = 500
  ) => {
    if (!element || !content) {
      return;
    }

    const observer = new MutationObserver(() => {
      if (!document.body.contains(element)) {
        ToolTip.isVisible = false;
        isMouseOver = false;
        container.remove();
        root && root.unmount();
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    const resizeListener = () => {
      ToolTip.isVisible = false;
      isMouseOver = false;
      container.remove();
      root && root.unmount();
    };
    window.addEventListener("resize", resizeListener);

    const container = createContainer();
    let root: ReactDOM.Root;

    let isMouseOver = false;

    const showToolTip = () => {
      if (!ToolTip.isVisible) {
        ToolTip.isVisible = true;
        const message: JSX.Element = <ToolTipComponent>{content}</ToolTipComponent>;
        document.body.appendChild(container);
        container.style.opacity = "0";
        root = ReactDOM.createRoot(container);
        root.render(message);
        setTimeout(() => {
          if (isMouseOver) {
            setToolTipPosition(element, container, position);
          }
        }, delay);
      }
    };

    element.addEventListener("mouseover", (evt) => {
      isMouseOver = true;
      showToolTip();
    });

    element.addEventListener("mouseleave", () => {
      ToolTip.isVisible = false;
      isMouseOver = false;
      container.remove();
      root && root.unmount();
    });
  },
};

const createContainer = (): HTMLDivElement => {
  const container = document.createElement("div");
  container.className = "tooltip";
  return container;
};

interface ToolTipComponentProps {
  children: React.ReactNode;
}

const ToolTipComponent: React.FC<ToolTipComponentProps> = (props) => {
  return <div className="tooltip-content">{props.children}</div>;
};

const setToolTipPosition = (
  parent: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement,
  toolTip: HTMLDivElement,
  position: ToolTipPosition
) => {
  const parentLocation = parent.getBoundingClientRect();
  const toolTipLocation = toolTip.getBoundingClientRect();

  const realTop = getElementTopRelativeToDocument(parent);
  const realLeft = getElementLeftRelativeToDocument(parent);

  switch (position) {
    case "top":
      toolTip.className = "tooltip ttbottom";
      var diff = (parentLocation.width - toolTipLocation.width) / 2;
      var top = realTop - toolTipLocation.height - 10;
      var left = realLeft + diff;
      if (checkPosition(parent, toolTip, position, toolTipLocation, top, left)) {
        toolTip.style.top = top + "px";
        toolTip.style.left = left + "px";
        toolTip.style.opacity = "1";
      }
      break;
    case "bottom":
      toolTip.className = "tooltip ttop";
      var diff = (parentLocation.width - toolTipLocation.width) / 2;
      var top = realTop + parentLocation.height + 10;
      var left = realLeft + diff;
      if (checkPosition(parent, toolTip, position, toolTipLocation, top, left)) {
        toolTip.style.top = top + "px";
        toolTip.style.left = left + "px";
        toolTip.style.opacity = "1";
      }
      break;
    case "left":
      toolTip.className = "tooltip ttright";
      var diff = (parentLocation.height - toolTipLocation.height) / 2;
      var top = realTop + diff;
      var left = realLeft - toolTipLocation.width - 10;
      if (checkPosition(parent, toolTip, position, toolTipLocation, top, left)) {
        toolTip.style.top = top + "px";
        toolTip.style.left = left + "px";
        toolTip.style.opacity = "1";
      }
      break;
    case "right":
      toolTip.className = "tooltip ttleft";
      var diff = (parentLocation.height - toolTipLocation.height) / 2;
      var top = realTop + diff;
      var left = realLeft + parentLocation.width + 10;
      if (checkPosition(parent, toolTip, position, toolTipLocation, top, left)) {
        toolTip.style.top = top + "px";
        toolTip.style.left = left + "px";
        toolTip.style.opacity = "1";
      }
      break;
  }
};

const checkPosition = (
  parent: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement,
  toolTip: HTMLDivElement,
  position: ToolTipPosition,
  toolTipRect: DOMRect,
  top: number,
  left: number
): boolean => {
  if (position === "left" && left < 0) {
    setToolTipPosition(parent, toolTip, "bottom");
    return false;
  } else if (position === "top" && top < 0) {
    setToolTipPosition(parent, toolTip, "bottom");
    return false;
  } else if (position === "right" && left + toolTipRect.width > window.outerWidth) {
    setToolTipPosition(parent, toolTip, "bottom");
    return false;
  } else if (position === "bottom" && top + toolTipRect.height > window.innerHeight) {
    return false;
  }
  return true;
};
