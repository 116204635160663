import { useDispatch, useSelector } from "react-redux";
import AccountComponent from "../pages/account/components/AccountComponent";
import { ModalDialog } from "../controls/ModalDialog";
import { changeUserState, setUserToken } from "../reducers/userReducer";
import { AccountPageType } from "../models/new/Dictionaries";
import WelcomeComponent from "../components/welcome/WelcomeComponent";
import Cookies from "universal-cookie";
import { Token } from "../models/new/Token";
import * as UserApi from "api/UserApi";
import * as SupplierApi from "api/SupplierApi";
import { UserState } from "models/new/State";
import { Supplier } from "models/new/Supplier";
import { AppUser } from "models/new/AppUser";
import { useURLParams } from "./useURLParams";
import MessageBox from "controls/MessageBox";

export const useLogin = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { params } = useURLParams();

  const onClick = async (type: AccountPageType, token?: Token, email?: string): Promise<boolean> => {
    let resultOnClick = false;
    if (type === "sign-in") {
      setToken(token, email);
      dispatch(setUserToken(token));
      logInOpWp(token, email);
    } else if (type === "sign-up") {
      welcome(email!);
    } else {
      return resultOnClick;
    }
    return resultOnClick;
  };

  const logInOpWp = async (token?: Token, email?: string) => {
    if (!token || !email) return;

    await UserApi.getUserByEmail(email)
      .then(async (user) => {
        let userInfo: AppUser = user;

        await UserApi.getFollowers(userInfo.id).then((values) => {
          userInfo.followes = values;
        });

        const cookies = new Cookies();
        cookies.set("appuserid", user.id, {
          secure: true,
          sameSite: "none"
        });

        if (userInfo.isSupplier) {
          await SupplierApi.getSupplierByAppUserId(userInfo.id)
            .then(async (suppInfo) => {
              const supplierProducts = SupplierApi.getSupplierProducts(suppInfo.id);
              const supplierAddresses = SupplierApi.getSupplierAddresses(suppInfo.id);
              const supplierRatings = SupplierApi.getSupplierRatings(suppInfo.id);
              const supplierPosts = SupplierApi.getSupplierPosts(suppInfo.id);
              const supplierCertificates = SupplierApi.getSupplierCertificates(suppInfo.id);
              const supplierPictures = SupplierApi.getSupplierPictures(suppInfo.id);
              const supplierSubscriptions = SupplierApi.getSupplierSubscriptions(suppInfo.id);
              const supplierSaleCoupons = SupplierApi.getSupplierSaleCoupons(suppInfo.id);
              await Promise.all([
                supplierProducts,
                supplierAddresses,
                supplierRatings,
                supplierPosts,
                supplierCertificates,
                supplierPictures,
                supplierSubscriptions,
                supplierSaleCoupons,
              ]).then((values) => {
                const supplierInfo: Supplier = suppInfo;
                supplierInfo.products = values[0];
                supplierInfo.addresses = values[1];
                supplierInfo.ratings = values[2];
                supplierInfo.posts = values[3];
                supplierInfo.certificates = values[4];
                supplierInfo.pictures = values[5];
                supplierInfo.subscription = values[6]?.[0]; //subskrypcja tylko aktualna
                supplierInfo.saleCoupons = values[7];
                dispatch(
                  changeUserState({
                    ...userState,
                    user: userInfo,
                    supplier: supplierInfo,
                    token: token,
                    //view: "supplier",
                  })
                );
              });
            })
            .catch((err) => alert("getSupplierByAppUserId onClick"));
        } else {
          dispatch(
            changeUserState({
              ...userState,
              user: userInfo,
              token: token,
              //view: "client"
            })
          );
        }
      })
      .catch((err) => alert("useLogin onClick"));
  };

  const checkToken = () => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const email = cookies.get("email");
    logInOpWp(token, email);
  };

  const reLogin = () => {
    const cookies = new Cookies();
    const token = cookies.get("token");
    const email = cookies.get("email");
    logInOpWp(token, email);
  };

  const setToken = (token?: Token, email?: string) => {
    if (!token) {
      removeToken();
      return;
    }
    const cookies = new Cookies();
    const maxAge = token.expiresIn;
    token && cookies.set("token", JSON.stringify(token), { maxAge });
    email && cookies.set("email", email, { maxAge });
  };

  const removeToken = () => {
    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("email");
    cookies.remove("appuserid");
  };

  const welcome = async (email: string) => {
    await UserApi.emailConfirmedMessage(email)
      .then(async () => {
        await ModalDialog.show(<WelcomeComponent />, "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signIn = async () => {
    await ModalDialog.show(<AccountComponent page="sign-in" onClick={onClick} />, "", undefined, true, true);
  };

  const signUp = async () => {
    await ModalDialog.show(<AccountComponent page="sign-up" onClick={onClick} />, "", undefined, true, true);
  };

  const resetPassword = async () => {
    await ModalDialog.show(<AccountComponent page="reset-password" onClick={onClick} />, "", undefined, true, true);
  };

  const confirmEmail = async () => {
    const confirmCode = params.get("confirmCode")?.replaceAll(" ", "+");
    const email = params.get("email");
    if (email && confirmCode) {
      await UserApi.emailConfirm(email, confirmCode)
        .then(() => {
          ModalDialog.show(
            <MessageBox content="Twoje konto zostało aktywowane - możesz się teraz zalogować." icon="Success" />,
            "Aktywacja konta",
            undefined,
            true,
            false
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          const location = new URL(window.location.href);
          location.searchParams.delete("email");
          location.searchParams.delete("confirmCode");
          window.history.replaceState({}, "", location);
        });
    }
  };

  const refreshToken = () => {
    const cookies = new Cookies();
    const token: Token | null = cookies.get("token");

    token &&
      UserApi.refresh(token?.refreshToken)
        .then((value) => setToken(token))
        .catch(() => removeToken());
  };

  return { signIn, signUp, resetPassword, checkToken, removeToken, confirmEmail, reLogin, refreshToken };
};
