import { createAction, createReducer } from "@reduxjs/toolkit";
import { SearchState } from "../models/new/State";
import { OverDealer } from "../models/new/OverDealer";
import { SearchFilter } from "../models/new/SearchFilter";
import { SupplierInArea } from "models/new/SupplierInArea";
import { Supplier } from "models/new/Supplier";
import { Product } from "models/new/Product";
import { ProductInfo } from "models/new/ProductInfo";
import { Post, PostComment, PostLike } from "models/new/Post";

export const initialFilter = {
  priceFrom: undefined,
  priceTo: undefined,
  stars: undefined,
  distance: 15,
  promotion: false,
  sort: undefined,
  take: 30,
  categoryId: undefined,
};

const initialState = {
  geoLocationState: false,
  geoLocation: { lat: 51.73848, lng: 19.46728 },
  mapCenter: { lat: 51.73848, lng: 19.46728 },
  isLoading: false,
  showFilters: false,
  showBlog: false,
  filters: initialFilter,
  showMap: "hidden",
  filterProductDealer: [],
  suppliersInArea: [],
  suppliersProducts: [],
  supplierPost: undefined,
  searchedDealers: [],
  searchedProducts: [],
} as SearchState;

export const changeMapCenter = createAction<{ lat: number; lng: number }>("search/changeMapCenter");
export const changeSearchState = createAction<Partial<SearchState>>("search/changeSearchState");
export const changeSearchFilter = createAction<SearchFilter>("search/changeSearchFilter");
export const setOverDealer = createAction<OverDealer | undefined>("search/setOverDealer");
export const setSupplierInfo = createAction<Supplier | undefined>("search/setSupplierInfo");
export const setSuppliersInArea = createAction<SupplierInArea[]>("search/setSuppliersInArea");
export const setSupplierProduct = createAction<ProductInfo>("search/setSupplierProduct");
export const setSuppliersProducts = createAction<Product[]>("search/setSuppliersProducts");
export const setSuppliersPost = createAction<Post | undefined>("search/setSuppliersPost");
export const setSuppliersPostLike = createAction<PostLike>("search/setSuppliersPostLike");
export const setSuppliersPostUnlike = createAction<PostLike>("search/setSuppliersPostUnlike");
export const setSuppliersPostComment = createAction<PostComment>("search/setSuppliersPostComment");

export const clearSearched = createAction("search/clearSearched");
export const clearSearchFilters = createAction("search/clearSearchFilters");

export const searchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeMapCenter, (state, action) => {
      state.mapCenter = action.payload;
    })
    .addCase(changeSearchState, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(changeSearchFilter, (state, action) => {
      state.filters = action.payload;
    })
    .addCase(setOverDealer, (state, action) => {
      state.overDealer = action.payload;
    })
    .addCase(clearSearched, (state) => {
      state.supplierInfo = undefined;
      state.supplierProduct = undefined;
    })
    .addCase(setSupplierInfo, (state, action) => {
      state.supplierInfo = action.payload;
    })
    .addCase(setSuppliersInArea, (state, action) => {
      state.suppliersInArea = action.payload;
    })
    .addCase(setSupplierProduct, (state, action) => {
      state.supplierProduct = action.payload;
    })
    .addCase(setSuppliersProducts, (state, action) => {
      state.suppliersProducts = action.payload;
    })
    .addCase(setSuppliersPost, (state, action) => {
      state.supplierPost = action.payload;
    })
    .addCase(setSuppliersPostLike, (state, action) => {
      if (state.supplierPost) {
        state.supplierPost.likes = [...(state.supplierPost?.likes ?? []), action.payload];
      }

      if (!state.supplierInfo) return;

      state!.supplierInfo!.posts = state.supplierInfo!.posts.map((post) =>
        post.id === action.payload.postId ? { ...post, likes: [...(post?.likes ?? []), action.payload] } : post
      );
    })
    .addCase(setSuppliersPostUnlike, (state, action) => {
      if (state.supplierPost) {
        state.supplierPost.likes = state.supplierPost?.likes?.filter((x) => x.id !== action.payload.id);
      }

      if (!state.supplierInfo) return;

      state!.supplierInfo!.posts = state.supplierInfo!.posts.map((post) =>
        post.id === action.payload.postId
          ? { ...post, likes: post?.likes?.filter((x) => x.id !== action.payload.id) }
          : post
      );
    })
    .addCase(setSuppliersPostComment, (state, action) => {
      if (state.supplierPost) {
        state.supplierPost.comments = [...(state.supplierPost.comments ?? []), action.payload];
      }

      if (!state.supplierInfo) return;

      state!.supplierInfo!.posts = state.supplierInfo!.posts.map((post) =>
        post.id === action.payload.postId ? { ...post, comments: [...(post.comments ?? []), action.payload] } : post
      );
    })
    .addCase(clearSearchFilters, (state) => {
      state.filters = initialFilter;
    });
});
