import React from "react";
import * as Icon from "commons/iconManager";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionWizardState } from "models/new/State";
import { SubscriptionType } from "models/new/Dictionaries";
import { changeSubscriptionWizard, setSubscriptionValideError } from "reducers/subscriptionWizardReducer";
import { useWindowSize } from "hooks/useWindowSize";

const SubscriptionWizardTypeComponent: React.FC = () => {
  const subscriptionWizardState: SubscriptionWizardState = useSelector((state: any) => state.subscriptionWizard);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const wizardState = React.useRef<SubscriptionWizardState>(subscriptionWizardState);

  React.useEffect(() => {
    wizardState.current = subscriptionWizardState;
  }, [subscriptionWizardState]);

  React.useEffect(() => {
    return () => {
      !isMobile &&
        dispatch(
          setSubscriptionValideError({
            subscriptionType: wizardState.current.subscriptionType !== undefined
              ? undefined
              : subscriptionWizardState.valideError?.subscriptionType ?? "Nie wybrano odpowiedniej subskrypcji.",
          })
        );
    };
  }, []);

  if (isMobile) {
    return (
      <div className="d-flex fd-c gap-32 pad-8 pad-b-32 width-fill border-bottom">
        <div className="d-flex fd-c gap-4 width-fill">
          <Label fontStyle="bold" fontSize="lg" content="Rodzaj subskrypcji" />
          <Label fontStyle="semibold" fontSize="sm" fontColor="secondary" content="Wybierz rodzaj subskrypcji" />
        </div>
        <div className="d-flex fd-c gap-16 width-fill">
          {subscriptionWizardState.valideError?.subscriptionType && (
            <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="bold"
                fontColor="black"
                content={subscriptionWizardState.valideError?.subscriptionType}
              />
            </InfoBox>
          )}
          <InfoBox
            className="width-fill c-pointer"
            backgroundColor={
              subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "green" : "secondary"
            }
            sizeFrame="md"
            onClick={() =>
              dispatch(
                changeSubscriptionWizard({
                  subscriptionType: SubscriptionType.Free,
                  valideError: { ...subscriptionWizardState.valideError, subscriptionType: "" },
                })
              )
            }
          >
            <div className="width-fill d-flex fd-c gap-8 ai-s-flex ">
              <div className="d-flex gap-8 ai-center">
                {subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? (
                  <Icon.CheckCircleFillSVG className="c-green" height={24} width={24} />
                ) : (
                  <Icon.RadioButtonUncheckedSVG className="c-pointer icon-color" height={24} width={24} />
                )}
                <Label
                  wrap
                  pointer
                  fontSize="sm"
                  fontStyle="semibold"
                  fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "black" : "primary"}
                  content="Darmowe 6 miesięcy"
                />
              </div>
              <Label
                wrap
                pointer
                fontSize="xl"
                fontStyle="extra-bold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "black" : "primary"}
                content="0 zł"
              />
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="semibold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "black" : "primary"}
                content="Umożliwia pełny dostęp do wszystkich funkcji i korzyści oferowanych przez usługę przez okres 6 miesięcy, całkowicie za darmo."
              />
            </div>
          </InfoBox>
          <InfoBox
            className="width-fill c-pointer"
            backgroundColor={
              subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "green" : "secondary"
            }
            sizeFrame="md"
            onClick={() =>
              dispatch(
                changeSubscriptionWizard({
                  subscriptionType: SubscriptionType.Advance,
                  valideError: { ...subscriptionWizardState.valideError, subscriptionType: "" },
                })
              )
            }
          >
            <div className="width-fill d-flex fd-c gap-8 ai-s-flex ">
              <div className="d-flex gap-8 ai-center">
                {subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? (
                  <Icon.CheckCircleFillSVG className="c-green" height={24} width={24} />
                ) : (
                  <Icon.RadioButtonUncheckedSVG className="c-pointer icon-color" height={24} width={24} />
                )}
                <Label
                  wrap
                  pointer
                  fontSize="sm"
                  fontStyle="semibold"
                  fontColor={
                    subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "black" : "primary"
                  }
                  content="1 miesiąc"
                />
              </div>
              <Label
                wrap
                pointer
                fontSize="xl"
                fontStyle="extra-bold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "black" : "primary"}
                content="99 zł"
              />
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="semibold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "black" : "primary"}
                content="Użytkownik uzyskuje pełen dostęp do wszystkich funkcji usługi, z możliwością anulowania subskrypcji w dowolnym momencie. Ta opcja jest doskonała dla osób, które wolą krótkoterminowe zobowiązania lub chcą mieć możliwość łatwego dostosowania swoich planów finansowych."
              />
            </div>
          </InfoBox>
          <InfoBox
            className="width-fill c-pointer"
            backgroundColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "green" : "secondary"}
            sizeFrame="md"
            onClick={() =>
              dispatch(
                changeSubscriptionWizard({
                  subscriptionType: SubscriptionType.Pro,
                  valideError: { ...subscriptionWizardState.valideError, subscriptionType: "" },
                })
              )
            }
          >
            <div className="width-fill d-flex fd-c gap-8 ai-s-flex ">
              <div className="d-flex gap-8 ai-center">
                {subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? (
                  <Icon.CheckCircleFillSVG className="c-green" height={24} width={24} />
                ) : (
                  <Icon.RadioButtonUncheckedSVG className="c-pointer icon-color" height={24} width={24} />
                )}
                <Label
                  wrap
                  pointer
                  fontSize="sm"
                  fontStyle="semibold"
                  fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "black" : "primary"}
                  content="12 miesięcy"
                />
              </div>
              <Label
                wrap
                pointer
                fontSize="xl"
                fontStyle="extra-bold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "black" : "primary"}
                content="999 zł"
              />
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="semibold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "black" : "primary"}
                content="To najlepszy wybór dla tych, którzy szukają największej oszczędności. Subskrypcja roczna oferuje wszystkie te same korzyści co miesięczna, ale z większą korzyścią finansową – całkowity koszt roczny jest niższy niż suma dwunastu miesięcznych opłat."
              />
            </div>
          </InfoBox>
        </div>
      </div>
    );
  }

  return (
    <div className="frame-scroll gap-32">
      <div className="d-flex fd-c gap-8 width-fill">
        <Label fontStyle="bold" fontSize="md" content="Rodzaj subskrypcji" />
        <Label fontStyle="semibold" fontSize="sm" fontColor="secondary" content="Wybierz rodzaj subskrypcji" />
        {subscriptionWizardState.valideError?.subscriptionType && (
          <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
            <Label
              wrap
              pointer
              fontStyle="semibold"
              fontColor="black"
              content={subscriptionWizardState.valideError?.subscriptionType}
            />
          </InfoBox>
        )}
      </div>
      <div className="d-flex gap-16 width-fill height-fill">
        <InfoBox
          className="width-fill c-pointer"
          backgroundColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "green" : "secondary"}
          sizeFrame="md"
          onClick={() =>
            dispatch(
              changeSubscriptionWizard({
                subscriptionType: SubscriptionType.Free,
                valideError: { ...subscriptionWizardState.valideError, subscriptionType: "" },
              })
            )
          }
        >
          <div className="width-fill height-fill d-flex fd-c gap-16 ai-s-flex ">
            <div className="d-flex gap-8 ai-center">
              {subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? (
                <Icon.CheckCircleFillSVG className="c-green" height={24} width={24} />
              ) : (
                <Icon.RadioButtonUncheckedSVG className="c-pointer icon-color" height={24} width={24} />
              )}
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="semibold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "black" : "primary"}
                content="Darmowe 6 miesięcy"
              />
            </div>
            <Label
              wrap
              pointer
              fontSize="xl"
              fontStyle="extra-bold"
              fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "black" : "primary"}
              content="0 zł"
            />
            <Label
              wrap
              pointer
              fontStyle="semibold"
              fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Free ? "black" : "primary"}
              content="Umożliwia pełny dostęp do wszystkich funkcji i korzyści oferowanych przez usługę przez okres 6 miesięcy, całkowicie za darmo."
            />
          </div>
        </InfoBox>
        <InfoBox
          className="width-fill c-pointer"
          backgroundColor={
            subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "green" : "secondary"
          }
          sizeFrame="md"
          onClick={() =>
            dispatch(
              changeSubscriptionWizard({
                subscriptionType: SubscriptionType.Advance,
                valideError: { ...subscriptionWizardState.valideError, subscriptionType: "" },
              })
            )
          }
        >
          <div className="width-fill height-fill d-flex fd-c gap-16 ai-s-flex ">
            <div className="d-flex gap-8 ai-center">
              {subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? (
                <Icon.CheckCircleFillSVG className="c-green" height={24} width={24} />
              ) : (
                <Icon.RadioButtonUncheckedSVG className="c-pointer icon-color" height={24} width={24} />
              )}
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="semibold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "black" : "primary"}
                content="1 miesiąc"
              />
            </div>
            <Label
              wrap
              pointer
              fontSize="xl"
              fontStyle="extra-bold"
              fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "black" : "primary"}
              content="99 zł"
            />
            <Label
              wrap
              pointer
              fontStyle="semibold"
              fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Advance ? "black" : "primary"}
              content="Użytkownik uzyskuje pełen dostęp do wszystkich funkcji usługi, z możliwością anulowania subskrypcji w dowolnym momencie. Ta opcja jest doskonała dla osób, które wolą krótkoterminowe zobowiązania lub chcą mieć możliwość łatwego dostosowania swoich planów finansowych."
            />
          </div>
        </InfoBox>
        <InfoBox
          className="width-fill c-pointer"
          backgroundColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "green" : "secondary"}
          sizeFrame="md"
          onClick={() =>
            dispatch(
              changeSubscriptionWizard({
                subscriptionType: SubscriptionType.Pro,
                valideError: { ...subscriptionWizardState.valideError, subscriptionType: "" },
              })
            )
          }
        >
          <div className="width-fill height-fill d-flex fd-c gap-16 ai-s-flex ">
            <div className="d-flex gap-8 ai-center">
              {subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? (
                <Icon.CheckCircleFillSVG className="c-green" height={24} width={24} />
              ) : (
                <Icon.RadioButtonUncheckedSVG className="c-pointer icon-color" height={24} width={24} />
              )}
              <Label
                wrap
                pointer
                fontSize="sm"
                fontStyle="semibold"
                fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "black" : "primary"}
                content="12 miesięcy"
              />
            </div>
            <Label
              wrap
              pointer
              fontSize="xl"
              fontStyle="extra-bold"
              fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "black" : "primary"}
              content="999 zł"
            />
            <Label
              wrap
              pointer
              fontStyle="semibold"
              fontColor={subscriptionWizardState?.subscriptionType === SubscriptionType.Pro ? "black" : "primary"}
              content="To najlepszy wybór dla tych, którzy szukają największej oszczędności. Subskrypcja roczna oferuje wszystkie te same korzyści co miesięczna, ale z większą korzyścią finansową – całkowity koszt roczny jest niższy niż suma dwunastu miesięcznych opłat."
            />
          </div>
        </InfoBox>
      </div>
    </div>
  );
};

export default SubscriptionWizardTypeComponent;
