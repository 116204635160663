import React from "react";
import { SearchState } from "models/new/State";
import { useSelector } from "react-redux";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";
import SearchProductComponent from "./SearchProductComponent";

const SearchSupplierInfoProductsComponent: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const searchState: SearchState = useSelector((state: any) => state.search);

  React.useEffect(() => {
    if (searchState.supplierInfo?.ratings) {
      setIsLoading(false);
    }
  }, [searchState.supplierInfo]);

  return (
    <div className="width-fill">
      {isLoading ? (
        <WaitOnLoadingComponent
          isLoading={isLoading}
          count={1}
          className="width-fill rounded-12"
          style={{ minHeight: "350px", marginTop: "8px", marginRight: "4px" }}
        />
      ) : (
        <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
          <div className="d-flex fd-c width-fill">
            <Label
              fontSize="lg"
              fontStyle="semibold"
              content={`Produkty sprzedawcy (${searchState.supplierInfo?.products?.length})`}
            />
            {searchState.supplierInfo?.products?.map((product) => {
              return <SearchProductComponent product={product} withDealer={true} />;
            })}
          </div>
        </InfoBox>
      )}
    </div>
  );
};

export default SearchSupplierInfoProductsComponent;
