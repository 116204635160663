import React from "react";
import * as Icon from "commons/iconManager";
import * as OrderApi from "api/OrderApi";
import PathComponent from "components/PathPageComponent";
import { Button } from "controls/Button";
import { useURLParams } from "hooks/useURLParams";
import { useDispatch, useSelector } from "react-redux";
import { SaleState } from "models/new/State";
import SalesItemComponent from "./SalesItemComponent";
import { setSelectedSale } from "reducers/saleReducer";
import { useWindowSize } from "hooks/useWindowSize";
import SalesMobileManagerComponent from "./mobile/SalesMobileManagerComponent";

const SalesManagerComponent: React.FC = () => {
  const saleState: SaleState = useSelector((state: any) => state.sale);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { params } = useURLParams();
  const id = params.get("id") ?? "";

  React.useEffect(() => {
    return () => {
      dispatch(setSelectedSale(undefined));
    };
  }, []);

  React.useEffect(() => {
    if (!saleState.selected) downloadData();
  }, [dispatch, saleState.selected]);

  const downloadData = async () => {
    await OrderApi.getById(id)
      .then((value) => {
        dispatch(setSelectedSale(value));
        document.title = `Ogrosa | Zamówienie ${value.code}`;
      })
      .catch((err) => alert("SalesManagerComponent downloadData"));
  };

  if (isMobile) return <SalesMobileManagerComponent />;

  return (
    <div className="f-fill">
      <div className="frame-header jc-sb">
        <PathComponent />
        <div className="d-flex fd-r gap-8">
          <Button sizeFrame="sm" kind="tertiary" label="Export" fontStyle="bold" icon={<Icon.ShareSVG />} />
          <Button sizeFrame="sm" kind="tertiary" label="Drukuj" fontStyle="bold" icon={<Icon.PrintSVG />} />
        </div>
      </div>
      <SalesItemComponent sale={saleState.selected} />
    </div>
  );
};

export default SalesManagerComponent;
