import React from "react";

interface WaitOnLoadingProps {
  isLoading: boolean;
  count: number;
  className?: string;
  style?: React.CSSProperties;
}

const WaitOnLoadingComponent: React.FC<WaitOnLoadingProps> = (props) => {
  if (!props.isLoading) return <></>;

  return (
    <div className="frame-scroll">
      {[...Array(props.count).keys()].map((x) => {
        return <div className={`wait-on-loading ${props.className}`} style={{ ...props.style }}></div>;
      })}
    </div>
  );
};

export default WaitOnLoadingComponent;
