import { createAction, createReducer } from "@reduxjs/toolkit";
import { NotificationState } from "../models/new/State";
import { Notify } from "../models/new/Notify";

const initialState = {
  notification: [],
} as NotificationState;

export const clearNotification = createAction("notification/clearNotification");
export const setNotifications = createAction<Notify[]>("notification/setNotifications");

export const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearNotification, (state) => {
      state.notification = [];
    })
    .addCase(setNotifications, (state, action) => {
      state.notification = action.payload;
    });
});
