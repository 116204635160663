import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";

const WelcomeComponent: React.FC = () => {
  return (
    <div className="frame-main" style={{ maxWidth: "500px" }}>
      <Icon.OgrosaSVG height={64} style={{ paddingInline: "15px", marginBottom: "32px" }} />
      <div className="d-flex fd-c gap-16 pad-16">
        <Label
          fontSize="lg"
          fontStyle="bold"
          wrap
          content="Witaj w naszej aplikacji do kupowania produktów bezpośrednio od rolnika!"
        />
        <Label
          fontSize="sm"
          fontColor="secondary"
          wrap
          content="Cieszymy się, że dołączyłeś do naszej społeczności, gdzie świeżość i jakość produktów są na pierwszym miejscu. Dzięki naszej platformie możesz bezpośrednio kupować od lokalnych rolników, wspierając ich działalność i ciesząc się najzdrowszymi produktami."
        />
        <Label
          fontSize="sm"
          wrap
          fontStyle="bold"
          content="Na Twój adres e-mail wysłaliśmy wiadomość powitalną oraz link weryfikacyjny. Prosimy o jego potwierdzenie, aby w pełni korzystać z możliwości naszej aplikacji."
        />
        <Label fontSize="sml" fontColor="secondary" content="Życzymy udanych zakupów i smacznych posiłków!" />
        <Label fontSize="sml" fontColor="secondary" content="Zespół Ogrosa" />  
      </div>
    </div>
  );
};

export default WelcomeComponent;
