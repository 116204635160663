import { Rating } from "../models/new/Rating";

export const getAVG = (rating: Rating[]): number => {
  const sum = rating.reduce((a, b) => a + b.rate, 0);
  return (sum / rating.length).toNumberFixed(1);
};

export class MyLock {
  isLocked = false;

  acquire() {
    return new Promise((resolve) => {
      if (!this.isLocked) {
        this.isLocked = true;
        resolve(true);
      } else {
        const tryAcquire = setInterval(() => {
          if (!this.isLocked) {
            this.isLocked = true;
            resolve(true);
            clearInterval(tryAcquire);
          }
        }, 100);
      }
    });
  }

  release() {
    this.isLocked = false;
  }
}
