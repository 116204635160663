import React from "react";
import * as Icon from "commons/iconManager";
import SearchManagerComponent from "../SearchManagerComponent";
import { MainState } from "models/new/State";
import { useDispatch, useSelector } from "react-redux";
import SearchMobileMapComponent from "./SearchMobileMapComponent";
import { Button } from "controls/Button";
import { changeMainState } from "reducers/mainReducer";

const SearchMobileManagerComponent: React.FC = () => {
  const mainState: MainState = useSelector((state: any) => state.main);
  const dispatch = useDispatch();

  if (mainState.showDealerOnMap) {
    return (
      <>
        <div id="top-page" className="f-page-top">
          <Button
            id="back-button"
            sizeFrame="md"
            kind={"tertiary"}
            icon={<Icon.ArrowBackSVG />}
            onClick={() => dispatch(changeMainState({ showDealerOnMap: false }))}
          />
        </div>
        <SearchMobileMapComponent />
      </>
    );
  }

  return <SearchManagerComponent />;
};

export default SearchMobileManagerComponent;
