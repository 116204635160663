import React from "react";
import * as UserApi from "api/UserApi";
import * as SupplierApi from "api/SupplierApi";
import AddPostComponent from "./components/AddPostComponent";
import PostListComponent from "./components/PostListComponent";
import { useDispatch, useSelector } from "react-redux";
import { UserState, WallState } from "models/new/State";
import { changeWallState } from "reducers/wallReducer";
import { Button } from "controls/Button";
import { useWindowSize } from "hooks/useWindowSize";
import { Post } from "../../models/new/Post";
import { setMyPost } from "reducers/userReducer";

const WallPage: React.FC = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const wallState: WallState = useSelector((state: any) => state.wall);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = "Ogrosa | Tablica";
    return () => {
      dispatch(changeWallState({ posts: [] }));
    };
  }, []);

  React.useEffect(() => {
    downloadData();
  }, [dispatch, userState.user, wallState.refresh]);

  const mergeUniquePosts = (list1: Post[], list2: Post[]): Post[] => {
    const combinedList = [...list1, ...list2];
    const postMap = new Map<string, Post>();

    combinedList.forEach((post) => {
      postMap.set(post.id, post);
    });

    return Array.from(postMap.values());
  };

  const downloadData = async () => {
    if (!userState.supplier?.id) return;
    let posts: Post[] = [];

    await SupplierApi.getSupplierPosts(userState.supplier.id)
      .then((values) => {
        posts = values;
        dispatch(setMyPost(values));
      })
      .catch((err) => {
        console.log(`WallPage - ${err}`);
      });

    if (!userState.user?.id) return;

    await UserApi.getPosts(userState.user.id)
      .then((values) => {
        dispatch(changeWallState({ posts: mergeUniquePosts(values, posts) }));
      })
      .catch((err: Response) => {
        console.log(`WallPage - ${err}`);
      });
  };

  if (isMobile) {
    return (
      <div className="f-page-center fd-c mag-b-8" style={{ paddingTop: "8px" }}>
        {userState.user?.isSupplier && (
          <div className="d-flex fd-c gap-16 mag-b-16">
            <AddPostComponent />
            <div className="d-flex gap-8">
              <Button
                className="width-fill"
                sizeFrame="sm"
                kind={wallState.filter === "all" ? "primary" : "tertiary"}
                label="Wszystkie posty"
                fontStyle="bold"
                onClick={() => dispatch(changeWallState({ filter: "all" }))}
              />
              <Button
                className="width-fill"
                sizeFrame="sm"
                kind={wallState.filter !== "all" ? "primary" : "tertiary"}
                label="Tylko moje posty"
                fontStyle="bold"
                onClick={() => dispatch(changeWallState({ filter: "mine" }))}
              />
            </div>
          </div>
        )}
        <PostListComponent />
      </div>
    );
  }

  return (
    <div className="f-fill ai-center">
      <div className="frame-main gap-16 width-fill">
        <div className="frame-scroll ai-center gap-16 pad-r-8">
          <div className="d-flex gap-32">
            <div className="d-flex fd-c gap-16" style={{ width: "630px" }}>
              {userState.user?.isSupplier && (
                <>
                  <AddPostComponent />
                  <div className="d-flex gap-8">
                    <Button
                      className="width-fill"
                      sizeFrame="sm"
                      kind={wallState.filter === "all" ? "primary" : "tertiary"}
                      label="Wszystkie posty"
                      fontStyle="bold"
                      onClick={() => dispatch(changeWallState({ filter: "all" }))}
                    />
                    <Button
                      className="width-fill"
                      sizeFrame="sm"
                      kind={wallState.filter !== "all" ? "primary" : "tertiary"}
                      label="Tylko moje posty"
                      fontStyle="bold"
                      onClick={() => dispatch(changeWallState({ filter: "mine" }))}
                    />
                  </div>
                </>
              )}
              <PostListComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WallPage;
