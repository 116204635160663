import React from "react";
import * as Icon from "commons/iconManager";
import { Order } from "models/new/Order";
import OrdersProcessComponent from "./OrdersProcessComponent";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import { MultiButton } from "controls/MultiButton";
import { DeliveryType, PaymentType, StatusOrder, showStatusOrderCodeTag } from "models/new/Dictionaries";
import { Tag } from "controls/Tag";
import AddressComponent from "components/AddressComponent";
import { EntryDictionary } from "models/new/EntryDictionary";
import DealerCardComponent from "components/DealerCardComponent";
import { useWindowSize } from "hooks/useWindowSize";
import { getLastStatus } from "models/new/OrderStatus";

const changeStatus: EntryDictionary[] = [
  {
    code: "1",
    name: "Oczekuje na zatwierdzenie",
    key: "bg-yellow",
    type: StatusOrder.New,
    data: [
      { name: "Odrzucono", value: StatusOrder.Canceled },
      { name: "W przygotowaniu", value: StatusOrder.InProgress },
    ],
    value: <Icon.PendingSVG className="icon-black" />,
  },
  {
    code: "2",
    name: "Odrzucono",
    key: "bg-red",
    type: StatusOrder.Canceled,
    data: [],
    value: <Icon.DangerousSVG className="icon-black" />,
  },
  {
    code: "3",
    name: "Oczekuje na płatność",
    key: "bg-blue",
    type: StatusOrder.WaitingForPayment,
    data: [
      { name: "W przygotowaniu", value: StatusOrder.InProgress },
      { name: "Gotowe do odbioru/wysyłki", value: StatusOrder.Ready },
    ],
    value: <Icon.PaymentsSVG className="icon-black" />,
  },
  {
    code: "4",
    name: "W przygotowaniu",
    key: "bg-blue",
    type: StatusOrder.InProgress,
    data: [
      { name: "Gotowe do odbioru/wysyłki", value: StatusOrder.Ready },
      { name: "Wysłane", value: StatusOrder.Sent },
    ],
    value: <Icon.GrocerySVG className="icon-black" />,
  },
  {
    code: "5",
    name: "Gotowe do odbioru/wysyłki",
    key: "bg-blue",
    type: StatusOrder.Ready,
    data: [
      { name: "Wysłane", value: StatusOrder.Sent },
      { name: "Zrealizowane", value: StatusOrder.Completed },
    ],
    value: <Icon.InventorySVG className="icon-black" />,
  },
  {
    code: "6",
    name: "Wysłane",
    key: "bg-yellow",
    type: StatusOrder.Sent,
    data: [{ name: "Zrealizowane", value: StatusOrder.Completed }],
    value: <Icon.LocalShippingSVG className="icon-black" />,
  },
  {
    code: "7",
    name: "Zrealizowane",
    key: "bg-green",
    type: StatusOrder.Completed,
    data: [],
    value: <Icon.OrdersSVG className="icon-black" />,
  },
];

const OrdersItemDetailsComponent: React.FC<{ order: Order }> = ({ order }) => {
  const { isMobile } = useWindowSize();

  const showTag = (state: any) => {
    return showStatusOrderCodeTag(state, "sml");
  };

  const getKindPayment = () => {
    const tag: React.ReactNode =
      order.payment === PaymentType.Blik ? (
        <Icon.BlikSVG style={{ minHeight: "32px", minWidth: "32px", height: "32px", width: "32px" }} />
      ) : order.payment === PaymentType.Transfer ? (
        <Icon.AccountBalanceSVG style={{ minHeight: "32px", minWidth: "32px" }} />
      ) : order.payment === PaymentType.Cash ? (
        <Icon.PaymentsSVG style={{ minHeight: "32px", minWidth: "32px" }} />
      ) : order.payment === PaymentType.Card ? (
        <Icon.CreditCardSVG style={{ minHeight: "32px", minWidth: "32px" }} />
      ) : (
        <Icon.BlikSVG style={{ minHeight: "32px", minWidth: "48px", height: "32px", width: "48px" }} />
      );
    return showComponent("Płatność", tag);
  };

  const getKindDelivery = () => {
    const tag: React.ReactNode =
      order.deliveryType === DeliveryType.Post ? (
        <Tag sizeFrame="sml" kind="yellow" label="Przesyłka kurierska" icon={<Icon.LocalShippingSVG />} />
      ) : order.deliveryType === DeliveryType.PickUpAtThePoint ? (
        <Tag sizeFrame="sml" kind="yellow" label="Odbiór w punkcie" icon={<Icon.StorefrontSVG />} />
      ) : order.deliveryType === DeliveryType.PickupOnSite ? (
        <Tag sizeFrame="sml" kind="yellow" label="Odbiór osobity" icon={<Icon.GrocerySVG />} />
      ) : (
        <></>
      );
    return showComponent("Rodzaj przesyłki", tag);
  };

  const getAddressDelivery = () => {
    return (
      <div className="d-flex">
        {order.deliveryType === DeliveryType.Post ? (
          showComponent("Dane przesyłkowe", <AddressComponent address={order.address} />)
        ) : (
          <div className="d-flex width-fill fd-c">
            {showComponent("Adres odbioru", <AddressComponent address={order.address} />)}
            {showComponent(
              "Data i czas odbioru",
              <div className="d-flex fd-c">
                <Label
                  fontSize="sm"
                  fontStyle="semibold"
                  content={new Date(order.pickUpDate ?? "").toFormatMoment("DD MMMM yyyy")}
                />
                <Label
                  fontSize="sm"
                  fontStyle="semibold"
                  content={new Date(order.pickUpDate ?? "").toFormatMoment("HH:mm")}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getChangeStateButton = (): JSX.Element => {
    const status = getLastStatus(order.statuses);
    const find = changeStatus.find((x) => x.type === status);
    if (find && find.data.length > 0) {
      return (
        <MultiButton label={find.name} sizeFrame="sml" kind="transparent" className={find.key} icon={find.value}>
          {find.data.map((item) => {
            return (
              <div className="d-flex ai-center gap-4 hover-tertiary pad-4 rounded-12 c-pointer">
                {showTag(find.type)} <Icon.ArrowForwardSVG className="icon-color" height={16} /> {showTag(item.value)}
              </div>
            );
          })}
        </MultiButton>
      );
    }
    return showTag(status);
  };

  const showComponent = (title: string, element: React.ReactNode): React.ReactNode => {
    return (
      <div
        className="width-fill pad-t-16 pad-b-16 border-bottom"
        style={{ display: "grid", gridTemplateColumns: "180px auto" }}
      >
        <div style={{ gridColumn: 1 }}>
          <Label fontSize="sm" fontColor="secondary" fontStyle="semibold" content={title} />
        </div>
        <div style={{ gridColumn: 2 }}>{element}</div>
      </div>
    );
  };

  const showComponentMobile = (title: string, element: React.ReactNode): React.ReactNode => {
    return (
      <div className="d-flex fd-c width-fill pad-t-16 pad-b-16 gap-4 border-bottom">
        <Label fontSize="sm" fontColor="secondary" fontStyle="semibold" content={title} />
        {element}
      </div>
    );
  };

  if (isMobile) {
    return (
      <div id="details" className="d-flex fd-c width-fill gap-8 pad-t-16" style={{ maxWidth: "700px" }}>
        <InfoBox
          className="width-fill"
          backgroundColor="secondary"
          style={{ minWidth: "300px", maxWidth: "700px" }}
          sizeFrame="md"
        >
          <div className="width-fill d-flex fd-c " style={{ flexWrap: "wrap", overflow: "hidden" }}>
            <Label fontSize="lg" fontStyle="bold" content="Szczegóły zamówienia" />
            {showComponentMobile("Numer zamówienia", <Label wrap fontStyle="semibold" content={order.code} />)}
            {showComponentMobile(
              "Sprzedawca",
              <DealerCardComponent supplier={order.supplier} rating={order.supplierRating} />
            )}
            {showComponent("Status zamówienia", showTag(getLastStatus(order.statuses)))}
            {showComponentMobile("Stan zamówienia", <OrdersProcessComponent order={order} />)}
            {getKindPayment()}
            {getKindDelivery()}
            {getAddressDelivery()}
            {showComponentMobile(
              "Dodatkowe informacje",
              <Label wrap fontStyle="semibold" content={order.description} />
            )}
          </div>
        </InfoBox>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c width-fill gap-8 pad-t-16" style={{ maxWidth: "700px" }}>
      <Label fontStyle="bold" content="Szczegóły zamówienia" />
      <InfoBox
        className="width-fill"
        backgroundColor="secondary"
        style={{ minWidth: "300px", maxWidth: "700px" }}
        sizeFrame="md"
      >
        <div className="width-fill d-flex fd-c " style={{ flexWrap: "wrap", overflow: "hidden" }}>
          {showComponent("Numer zamówienia", <Label wrap fontStyle="semibold" content={order.code} />)}
          {showComponent("Sprzedawca", <DealerCardComponent supplier={order.supplier} rating={order.supplierRating} />)}
          {showComponent("Status zamówienia", showTag(getLastStatus(order.statuses)))}
          {showComponent("Stan zamówienia", <OrdersProcessComponent order={order} />)}
          {getKindPayment()}
          {getKindDelivery()}
          {getAddressDelivery()}
          {showComponent("Dodatkowe informacje", <Label wrap fontStyle="semibold" content={order.description} />)}
        </div>
      </InfoBox>
    </div>
  );
};

export default OrdersItemDetailsComponent;
