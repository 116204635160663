import React from "react";
import * as Icon from "commons/iconManager";
import PostComponent from "src/pages/wall/components/PostComponent";
import EmptyComponent from "components/EmptyComponent";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { MainState, SearchState } from "models/new/State";
import { changeMainState } from "reducers/mainReducer";

const SearchSupplierPostComponent: React.FC = () => {
  const mainState: MainState = useSelector((state: any) => state.main);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  return (
    <div className={`filters ${mainState.showBlog && "filters-show"}`}>
      <div className="frame-main pad-r-8 pad-l-8 gap-16" style={{ position: "relative" }}>
        <div className="d-flex ai-center jc-sb width-fill">
          <Label fontStyle="semibold" content="Ostatnie wpisy" />
          <Icon.CloseSVG
            className="i-button close rounded-12"
            onClick={() => dispatch(changeMainState({ showBlog: false }))}
          />
        </div>
        <div className="frame-scroll gap-8 pad-r-4">
          {searchState.supplierInfo?.posts.length === 0 ? (
            <EmptyComponent style={{ height: "50%", width: '100%' }} icon={<Icon.DescriptionSVG />} message="Brak postów od tego sprzedawcy" />
          ) : (
            <>
              {searchState.supplierInfo?.posts.map((post) => {
                return <PostComponent post={post} />;
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchSupplierPostComponent;
