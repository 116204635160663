import React from "react";
import * as Icon from "commons/iconManager";
import { useSelector } from "react-redux";
import { BasketState } from "models/new/State";
import BasketItemComponent from "./BasketItemComponent";
import EmptyComponent from "components/EmptyComponent";

const BasketManagerComponent: React.FC = () => {
  const basketState: BasketState = useSelector((state: any) => state.basket);

  return (
    <div className="f-fill">
      {basketState.baskets?.length === 0 ? (
        <EmptyComponent message="Brak produktów w koszyku" icon={<Icon.ShoppingBasketSVG />}/>        
      ) : (
        <div className="f-scroll gap-16 pad-8 mag-0">
          {basketState.baskets?.map((item) => {
            return <BasketItemComponent item={item} />;
          })}
        </div>
      )}
    </div>
  );
};

export default BasketManagerComponent;
