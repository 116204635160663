import React from "react";
import * as Icon from "commons/iconManager";
import { useDispatch, useSelector } from "react-redux";
import { BasketState } from "models/new/State";
import { Label } from "controls/Label";
import InfoBox from "controls/InfoBox";
import { changeSummaryBasket } from "reducers/basketReducer";
import { PaymentType } from "models/new/Dictionaries";
import { useWindowSize } from "hooks/useWindowSize";

const BasketSummaryPaymentComponent: React.FC = () => {
  const { isMobile } = useWindowSize();
  const basketState: BasketState = useSelector((state: any) => state.basket);
  const dispatch = useDispatch();

  if (isMobile) {
    return (
      <div className="d-flex fd-c width-fill pad-b-32 pad-t-32 gap-16 border-bottom">
        <Label fontStyle="bold" wrap content={"Płatności"} />
        {basketState.valideError.paymentType.length > 0 && (
          <InfoBox
            className="width-fill ai-center"
            fontStyle="bold"
            sizeFrame="sml"
            iconSize="sml"
            backgroundColor="red"
            icon="Error"
            value={basketState.valideError.paymentType}
          />
        )}
        <InfoBox
          className="c-pointer width-fill hover-box"
          sizeFrame="sm"
          backgroundColor={basketState.summary?.paymentType === PaymentType.Cash ? "green" : "secondary"}
          onClick={() =>
            dispatch(
              changeSummaryBasket({
                basket: { paymentType: PaymentType.Cash },
                errors: { paymentType: '' },
              })
            )
          }
        >
          <div className="d-flex ai-center gap-16 c-pointer">
            <Icon.PaymentsSVG
              className={basketState.summary?.paymentType === PaymentType.Cash ? "icon-dark" : "icon-color"}
              style={{ minHeight: "48px", minWidth: "48px" }}
            />
            <div className="d-flex fd-c gap-2">
              <Label
                pointer
                fontStyle="bold"
                fontColor={basketState.summary?.paymentType === PaymentType.Cash ? "black" : "primary"}
                content="Płatność gotówką"
              />
              <Label
                pointer
                fontSize="sml"
                fontStyle="semibold"
                fontColor={basketState.summary?.paymentType === PaymentType.Cash ? "black" : "primary"}
                wrap
                content="Płatność gotówką u sprzedającego"
              />
            </div>
          </div>
        </InfoBox>
      
        <InfoBox
          className="c-pointer width-fill hover-box"
          sizeFrame="sm"
          backgroundColor={basketState.summary?.paymentType === PaymentType.Blik ? "green" : "secondary"}
          onClick={() =>
            dispatch(
              changeSummaryBasket({
                basket: { paymentType: PaymentType.Blik },
                errors: { paymentType: '' },
              })
            )
          }
        >
          <div className="d-flex ai-center gap-16 c-pointer">
            <Icon.BlikSVG
              className="icon-color"
              style={{ minHeight: "48px", minWidth: "48px", height: "48px", width: "48px" }}
            />
            <div className="d-flex fd-c gap-2">
              <Label
                pointer
                fontStyle="bold"
                fontColor={basketState.summary?.paymentType === PaymentType.Blik ? "black" : "primary"}
                content="Płatność blik"
              />
              <Label
                pointer
                fontSize="sml"
                fontStyle="semibold"
                fontColor={basketState.summary?.paymentType === PaymentType.Blik ? "black" : "primary"}
                wrap
                content="Płatność za pomocą kodu blik"
              />
            </div>
          </div>
        </InfoBox>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c width-fill pad-16 gap-16 border-bottom">
      <Label fontSize="lg" fontStyle="bold" wrap content={"Płatności"} />
      {basketState.valideError.paymentType.length > 0 && (
        <InfoBox
          className="width-fill ai-center"
          fontStyle="bold"
          sizeFrame="sml"
          iconSize="sml"
          backgroundColor="red"
          icon="Error"
          value={basketState.valideError.paymentType}
        />
      )}
      <InfoBox
        className="c-pointer width-fill hover-box"
        sizeFrame="sm"
        backgroundColor={basketState.summary?.paymentType === PaymentType.Cash ? "green" : "secondary"}
        onClick={() =>
          dispatch(
            changeSummaryBasket({
              basket: { paymentType: PaymentType.Cash },
              errors: { paymentType: '' },
            })
          )
        }
      >
        <div className="d-flex ai-center gap-16 c-pointer">
          <Icon.PaymentsSVG
            className={basketState.summary?.paymentType === PaymentType.Cash ? "icon-dark" : "icon-color"}
            style={{ minHeight: "32px", minWidth: "32px" }}
          />
          <div className="d-flex fd-c gap-2">
            <Label
              pointer
              fontStyle="bold"
              fontColor={basketState.summary?.paymentType === PaymentType.Cash ? "black" : "primary"}
              content="Płatność gotówką"
            />
            <Label
              pointer
              fontSize="sml"
              fontStyle="semibold"
              fontColor={basketState.summary?.paymentType === PaymentType.Cash ? "black" : "primary"}
              wrap
              content="Płatność gotówką u sprzedającego"
            />
          </div>
        </div>
      </InfoBox>
      
      <InfoBox
        className="c-pointer width-fill hover-box"
        sizeFrame="sm"
        backgroundColor={basketState.summary?.paymentType === PaymentType.Blik ? "green" : "secondary"}
        onClick={() =>
          dispatch(
            changeSummaryBasket({
              basket: { paymentType: PaymentType.Blik },
              errors: { paymentType: '' },
            })
          )
        }
      >
        <div className="d-flex ai-center gap-16 c-pointer">
          <Icon.BlikSVG
            className="icon-color"
            style={{ minHeight: "32px", minWidth: "32px", height: "32px", width: "32px" }}
          />
          <div className="d-flex fd-c gap-2">
            <Label
              pointer
              fontStyle="bold"
              fontColor={basketState.summary?.paymentType === PaymentType.Blik ? "black" : "primary"}
              content="Płatność blik"
            />
            <Label
              pointer
              fontSize="sml"
              fontStyle="semibold"
              fontColor={basketState.summary?.paymentType === PaymentType.Blik ? "black" : "primary"}
              wrap
              content="Płatność za pomocą kodu blik"
            />
          </div>
        </div>
      </InfoBox>
      
    </div>
  );
};

export default BasketSummaryPaymentComponent;


/*
mobilne 
  <InfoBox
          className="c-pointer width-fill hover-box"
          sizeFrame="sm"
          backgroundColor={basketState.summary?.paymentType === PaymentType.Card ? "green" : "secondary"}
          onClick={() =>
            dispatch(
              changeSummaryBasket({
                basket: { paymentType: PaymentType.Card },
                errors: { ...basketState.valideError },
              })
            )
          }
        >
          <div className="d-flex ai-center gap-16 c-pointer">
            <Icon.CreditCardSVG
              className={basketState.summary?.paymentType === PaymentType.Card ? "icon-dark" : "icon-color"}
              style={{ minHeight: "48px", minWidth: "48px" }}
            />
            <div className="d-flex fd-c gap-2">
              <Label
                pointer
                fontStyle="bold"
                fontColor={basketState.summary?.paymentType === PaymentType.Card ? "black" : "primary"}
                content="Płatność kartą"
              />
              <Label
                pointer
                fontSize="sml"
                fontColor={basketState.summary?.paymentType === PaymentType.Card ? "black" : "primary"}
                fontStyle="semibold"
                wrap
                content="Płatność kartą debetową"
              />
            </div>
          </div>
        </InfoBox>
        
        <InfoBox
          className="c-pointer width-fill hover-box"
          sizeFrame="sm"
          backgroundColor={basketState.summary?.paymentType === PaymentType.Transfer ? "green" : "secondary"}
          onClick={() =>
            dispatch(
              changeSummaryBasket({
                basket: { paymentType: PaymentType.Transfer },
                errors: { ...basketState.valideError },
              })
            )
          }
        >
          <div className="d-flex ai-center gap-16 c-pointer">
            <Icon.AccountBalanceSVG
              className={basketState.summary?.paymentType === PaymentType.Transfer ? "icon-dark" : "icon-color"}
              style={{ minHeight: "48px", minWidth: "48px" }}
            />
            <div className="d-flex fd-c gap-2">
              <Label
                pointer
                fontStyle="bold"
                fontColor={basketState.summary?.paymentType === PaymentType.Transfer ? "black" : "primary"}
                content="Przelew"
              />
              <Label
                pointer
                fontSize="sml"
                fontStyle="semibold"
                fontColor={basketState.summary?.paymentType === PaymentType.Transfer ? "black" : "primary"}
                wrap
                content="Płatność za pomocą przelewu tradycyjnego"
              />
            </div>
          </div>
        </InfoBox>
*/

/*
<InfoBox
        className="c-pointer width-fill hover-box"
        sizeFrame="sm"
        backgroundColor={basketState.summary?.paymentType === PaymentType.Card ? "green" : "secondary"}
        onClick={() =>
          dispatch(
            changeSummaryBasket({
              basket: { paymentType: PaymentType.Card },
              errors: { ...basketState.valideError },
            })
          )
        }
      >
        <div className="d-flex ai-center gap-16 c-pointer">
          <Icon.CreditCardSVG
            className={basketState.summary?.paymentType === PaymentType.Card ? "icon-dark" : "icon-color"}
            style={{ minHeight: "32px", minWidth: "32px" }}
          />
          <div className="d-flex fd-c gap-2">
            <Label
              pointer
              fontStyle="bold"
              fontColor={basketState.summary?.paymentType === PaymentType.Card ? "black" : "primary"}
              content="Płatność kartą"
            />
            <Label
              pointer
              fontSize="sml"
              fontColor={basketState.summary?.paymentType === PaymentType.Card ? "black" : "primary"}
              fontStyle="semibold"
              wrap
              content="Płatność kartą debetową"
            />
          </div>
        </div>
      </InfoBox>

      <InfoBox
        className="c-pointer width-fill hover-box"
        sizeFrame="sm"
        backgroundColor={basketState.summary?.paymentType === PaymentType.Transfer ? "green" : "secondary"}
        onClick={() =>
          dispatch(
            changeSummaryBasket({
              basket: { paymentType: PaymentType.Transfer },
              errors: { ...basketState.valideError },
            })
          )
        }
      >
        <div className="d-flex ai-center gap-16 c-pointer">
          <Icon.AccountBalanceSVG
            className={basketState.summary?.paymentType === PaymentType.Transfer ? "icon-dark" : "icon-color"}
            style={{ minHeight: "32px", minWidth: "32px" }}
          />
          <div className="d-flex fd-c gap-2">
            <Label
              pointer
              fontStyle="bold"
              fontColor={basketState.summary?.paymentType === PaymentType.Transfer ? "black" : "primary"}
              content="Przelew"
            />
            <Label
              pointer
              fontSize="sml"
              fontStyle="semibold"
              fontColor={basketState.summary?.paymentType === PaymentType.Transfer ? "black" : "primary"}
              wrap
              content="Płatność za pomocą przelewu tradycyjnego"
            />
          </div>
        </div>
      </InfoBox>
*/