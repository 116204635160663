import React from "react";
import * as Icon from "commons/iconManager";
import { Order } from "models/new/Order";
import { EntryDictionary } from "models/new/EntryDictionary";
import { Label } from "controls/Label";
import { StatusOrder } from "models/new/Dictionaries";
import { OrderStatus } from "models/new/OrderStatus";

const statuses: EntryDictionary[] = [
  {
    code: "1",
    name: "Nowy",
    key: StatusOrder.New.toString(),
    data: [StatusOrder.WaitingForPayment, StatusOrder.InProgress, StatusOrder.Ready, StatusOrder.Completed],
    value: <Icon.PendingSVG className="c-blue" />,
  },
  {
    code: "2",
    name: "Oczekuje na płatność",
    key: StatusOrder.WaitingForPayment.toString(),
    data: [StatusOrder.InProgress, StatusOrder.Ready, StatusOrder.Completed],
    value: <Icon.GrocerySVG className="c-blue" />,
  },
  {
    code: "3",
    name: "W przygotowaniu",
    key: StatusOrder.InProgress.toString(),
    data: [StatusOrder.Ready, StatusOrder.Completed],
    value: <Icon.GrocerySVG className="c-blue" />,
  },
  {
    code: "4",
    name: "Gotowe do odbioru/wysyłki",
    key: StatusOrder.Ready.toString(),
    data: [StatusOrder.Completed],
    value: <Icon.InventorySVG className="c-blue" />,
  },
  {
    code: "5",
    name: "Zrealizowane",
    key: StatusOrder.Completed.toString(),
    data: [],
    value: <Icon.OrdersSVG className="c-green" />,
  },
  {
    code: "6",
    name: "Anulowane",
    key: StatusOrder.Canceled.toString(),
    data: [],
    value: <Icon.CancelSVG className="c-red" />,
  },
  {
    code: "7",
    name: "Wysłane",
    key: StatusOrder.Sent.toString(),
    data: [StatusOrder.Completed],
    value: <Icon.LocalShippingSVG className="c-blue" />,
  },
];

const OrdersProcessComponent: React.FC<{ order: Order }> = ({ order }) => {
  const showStatus = (status: OrderStatus): JSX.Element => {
    const find = statuses.find((x) => x.key === status.status.toString());
    return (
      <div className="d-flex gap-16">
        <div className="process-box">
          <div className="process-box-dot">
            <div className="process-box-dot">{find?.value}</div>
          </div>
          <div className="process-box-item process-box-item-check"></div>
        </div>
        <div className="d-flex fd-c pad-t-8 gap-8">
          <Label fontSize="sm" fontStyle="semibold" content={find?.name} />
          <Label
            fontSize="sml"
            fontStyle="bold"
            content={new Date(status.createdAt ?? "").toFormatMoment("DD.MM.yyyy | HH:mm")}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {[...order.statuses]
        .sort((a, b) => {
          return new Date(a.createdAt ?? "").getTime() - new Date(b.createdAt ?? "").getTime();
        })
        .map((status) => {
          return showStatus(status);
        })}
    </div>
  );
};

export default OrdersProcessComponent;

