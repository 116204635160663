import React from "react";
import * as Icon from "commons/iconManager";
import * as PostApi from "api/PostApi";
import PictureBoxComponent from "components/PictureBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { Button } from "controls/Button";
import { InputArea } from "controls/InputArea";
import { Picture } from "models/new/Picture";
import { processFile } from "commons/fileManager";
import { Post } from "models/new/Post";
import { generateGUID } from "commons/stringManager";
import { ValidationFailure } from "models/new/ValidationFailure";
import { changeWallState } from "reducers/wallReducer";
import { getRandomInt } from "commons/numberManager";
import { useWindowSize } from "hooks/useWindowSize";
import { NotificationType } from "models/new/Dictionaries";
import PostPictureManagerComponent from "./PostPictureManagerComponent";
import { useNotify } from "hooks/useNotify";

const AddPostComponent: React.FC = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const [description, setDescription] = React.useState<string>();
  const [showPicture, setShowPicture] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<File[]>();
  const { isMobile } = useWindowSize();
  const { addNotify } = useNotify();
  const dispatch = useDispatch();
  const pictures = React.useRef<Picture[]>([]);

  const onChange = async (files?: File[]) => {
    if (!files) {
      pictures.current = [];
      return;
    }

    const newPictures: Picture[] = [];
    files?.forEach((file) => {
      const picture = processFile(file, userState.supplier?.pictures);
      newPictures.push(picture);
    });
    pictures.current = newPictures;
  };

  const publicPost = async () => {
    if (!description) return;

    const toDelete: string[] = [];
    let savePicture: Picture[] = [];
    const formData = new FormData();

    pictures.current.map((pic) => {
      savePicture.push({ id: pic.id, url: pic.url });
    });

    pictures.current.forEach((file, index) => {
      if (file.file && !file.url) {
        formData.append(`files`, file.file!);
        toDelete.push(file.id);
      }
    });

    if (toDelete.length > 0) {
      await PostApi.savePostPicture(formData)
        .then((value) => {
          savePicture = savePicture?.map((picture) => {
            const saved = value.find((x) => x.id === picture.id);
            if (saved) {
              return saved;
            }
            return picture;
          });
        })
        .catch((err) => {
          addNotify({ title: "Błąd", content: err, type: NotificationType.Error });
          return;
        });
    }

    const postId = generateGUID();
    const post: Post = {
      date: new Date(),
      description: description,
      id: postId,
      pictures: savePicture.map((p) => {
        return { id: p.id, postId: postId, url: p.url };
      }),
      supplierId: userState.supplier!.id,
    };

    await PostApi.createPost(post)
      .then(async (value) => {
        addNotify({
          title: "Sukcess!",
          content: "Post został dodany na Twoją tablice!",
          type: NotificationType.Success,
        });
        setDescription("");
        setFiles([]);
        setShowPicture(false);
        dispatch(changeWallState({ refresh: getRandomInt(0, 100) }));
      })
      .catch((err) => {
        setError(err);
        toDelete.forEach((del) => {
          PostApi.deletePostPicture(del);
        });
      });
  };

  const setError = (err: ValidationFailure[] | string) => {
    if (typeof err === "string") {
      addNotify({
        title: "Błąd",
        content: `${err}`,
        type: NotificationType.Error,
      });
    } else {
      let errorString = "";

      err.forEach((e) => {
        errorString += ` ${e.ErrorMessage}`;
      });
      addNotify({
        title: "Błąd",
        content: `${errorString}`,
        type: NotificationType.Error,
      });
    }
  };

  if (isMobile) {
    return (
      <div className="d-flex gap-8 width-fill">
        <PictureBoxComponent className="picture-50 c-pointer" url={userState.supplier?.logoUrl} />
        <div className="d-flex fd-c width-fill gap-16">
          <InputArea
            frameClassName="width-fill mag-b-16"
            sizeFrame="md"
            kind="tertiary"
            minLength={80}
            maxLength={500}
            placeholder="Co nowego w gospodarstwie?"
            heightAuto={true}
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <div className="d-flex jc-sb gap-8 width-fill">
            <div className="d-flex gap-8 width-fill">
              <Button
                sizeFrame="sm"
                kind="tertiary"
                icon={!showPicture ? <Icon.AddPhotoAlternateSVG /> : <Icon.HideImageSVG />}
                onClick={() => setShowPicture(!showPicture)}
              />
              <Button
                sizeFrame="sm"
                kind="tertiary"
                icon={<Icon.SentimentSatisfiedSVG />}
                toolTip={"Dodaj emotikone"}
              />
            </div>
            <Button
              sizeFrame="sm"
              kind="primary"
              fontStyle="bold"
              label="Opublikuj"
              toolTip={"Opublikuj post"}
              icon={<Icon.SendSVG />}
              iconPosition="right"
              onClick={publicPost}
            />
          </div>
          {showPicture && <PostPictureManagerComponent files={files} onChange={onChange} />}
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex gap-8 width-fill">
      <PictureBoxComponent className="picture-75 c-pointer" url={userState.supplier?.logoUrl} />
      <div className="d-flex fd-c width-fill gap-8">
        <InputArea
          frameClassName="width-fill mag-b-16"
          sizeFrame="md"
          kind="tertiary"
          minLength={80}
          maxLength={500}
          placeholder="Co nowego w gospodarstwie?"
          heightAuto={true}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
        />
        {showPicture && <PostPictureManagerComponent files={files} onChange={onChange} />}
        <div className="d-flex jc-sb gap-8 width-fill">
          <div className="d-flex gap-8 width-fill">
            <Button
              sizeFrame="sm"
              kind="tertiary"
              icon={!showPicture ? <Icon.AddPhotoAlternateSVG /> : <Icon.HideImageSVG />}
              onClick={() => setShowPicture(!showPicture)}
            />
          </div>
          <Button
            sizeFrame="sm"
            kind="primary"
            fontStyle="bold"
            label="Opublikuj"
            toolTip={"Opublikuj post"}
            icon={<Icon.SendSVG />}
            iconPosition="right"
            onClick={publicPost}
          />
        </div>
      </div>
    </div>
  );
};

export default AddPostComponent;
