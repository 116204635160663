import { Certificate } from "models/new/Certificate";
import * as api from "./api";
import { Supplier } from "models/new/Supplier";
import { SupplierInArea } from "models/new/SupplierInArea";
import { Product } from "models/new/Product";
import { Rating } from "models/new/Rating";
import { Address } from "models/new/Address";
import { Post } from "models/new/Post";
import { Picture } from "models/new/Picture";
import { SaleCoupon } from "models/new/SaleCoupon";
import { SearchProductFilter } from "models/new/SearchProductFilter";
import { SubscriptionDto } from "models/DTO/SubscriptionDto";
import { Subscription } from "models/new/Subscription";
import { SupplierDto } from "models/DTO/SupplierDto";

export function getSupplierByAppUserId(appUserId: string): Promise<Supplier> {
  return api.getWithoutToken(`/suppliers`, { params: { appUserId: appUserId } });
}

export function getSuppliers(searchProductFilter: SearchProductFilter): Promise<SupplierInArea[]> {
  let query = `/suppliers/search?LocLat=${searchProductFilter.locLat}&LocLng=${searchProductFilter.locLng}&LatMin=${searchProductFilter.latMin}&LatMax=${searchProductFilter.latMax}&LngMin=${searchProductFilter.lngMin}&LngMax=${searchProductFilter.lngMax}&Zoom=${searchProductFilter.zoom}&Distance=${searchProductFilter.distance}&Promotion=${searchProductFilter.promotion}&Take=${searchProductFilter.take}`;

  if (searchProductFilter.sort !== undefined) {
    query += `&Sort=${searchProductFilter.sort}`;
  }
  if (searchProductFilter.priceFrom !== undefined) {
    query += `&PriceFrom=${searchProductFilter.priceFrom}`;
  }
  if (searchProductFilter.priceTo !== undefined) {
    query += `&PriceTo=${searchProductFilter.priceTo}`;
  }
  if (searchProductFilter.stars !== undefined) {
    query += `&Stars=${searchProductFilter.stars}`;
  }
  if (searchProductFilter.phrase && searchProductFilter.phrase.length > 0) {
    searchProductFilter.phrase.forEach((x) => {
      query += `&Phrase=${x}`;
    });
  }
  if (searchProductFilter.categoryId !== undefined) {
    query += `&CategoryId=${searchProductFilter.categoryId}`;
  }

  return api.getWithoutToken(query);
}

export function getSupplier(id: string): Promise<Supplier> {
  return api.getWithoutToken(`/suppliers`, { params: { supplierId: id } });
}
export function getSupplierProducts(id: string): Promise<Product[]> {
  return api.getWithoutToken(`/suppliers/${id}/products`);
}
export function getSupplierRatings(id: string): Promise<Rating[]> {
  return api.getWithoutToken(`/suppliers/${id}/ratings`);
}
export function getSupplierCertificates(id: string): Promise<Certificate[]> {
  return api.getWithoutToken(`/suppliers/${id}/certificates`);
}
export function getSupplierAddresses(id: string): Promise<Address[]> {
  return api.getWithoutToken(`/suppliers/${id}/addresses`);
}
export function getSupplierPosts(id: string): Promise<Post[]> {
  return api.getWithoutToken(`/suppliers/${id}/posts`);
}
export function getSupplierPictures(id: string): Promise<Picture[]> {
  return api.getWithoutToken(`/suppliers/${id}/pictures`);
}
export function getSupplierSubscriptions(id: string): Promise<Subscription[]> {
  return api.getWithoutToken(`/suppliers/${id}/subscriptions`);
}
export function getSupplierSaleCoupons(id: string): Promise<SaleCoupon[]> {
  return api.getWithoutToken(`/suppliers/${id}/salecoupons`);
}

export function saveSupplierSubscriptions(subscription: SubscriptionDto): Promise<string> {
  return api.post(`/suppliers/subscriptions`, { subscription: subscription });
}

export function saveSupplier(supplier: SupplierDto): Promise<string> {
  return api.post(`/suppliers`, { supplier: supplier });
}

export function saveSupplierPicture(file: FormData): Promise<Picture[]> {
  return api.postFile(`/files`, file);
}

export function deleteSupplierPicture(id: string): Promise<string> {
  return api.del(`/files/${id}`);
}
