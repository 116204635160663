import * as api from "./api";
import { ReceivedChatMessageDto } from "models/DTO/ReceivedChatMessageDto";
import { Chat } from "models/new/Chat";

export function sendMessage(message:  ReceivedChatMessageDto): Promise<string> {
  return api.post(`/conversations`, { message: message });
}

export function getUserConversations(id: string): Promise<Chat[]> {
  return api.get(`/conversations/${id}`);
}

export function putMarkAsRead(chatId: string, appUserId: string): Promise<Chat[]> {
  return api.put(`/conversations/read`, { chatId: chatId, appUserId: appUserId });
}
