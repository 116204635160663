import React from "react";
import * as Icon from "commons/iconManager";
import { Label, StandardLabelProps } from "./Label";
import { useWindowSize } from "hooks/useWindowSize";

export interface SliderProps extends StandardLabelProps {
  show: boolean;
  size?: number;
  onHide?: () => void;
  header?: React.ReactNode | string;
  children?: React.ReactNode;
  className?: string;
}

export const Slider: React.FC<SliderProps> = (props) => {
  const { isMobile, windowSize } = useWindowSize();
  const [isShow, setIsShow] = React.useState<boolean>(props.show);
  const refSliderBg = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const refSlider = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const size = isMobile || !props.size ? windowSize.width : props.size;

  React.useEffect(() => {
    // setTimeout(() => {
    if (isMobile && refSlider.current) {
      //refSlider.current.style.position = "absolute";
      //refSlider.current.style.top = window.scrollY + "px";
      //refSlider.current.style.bottom = "0px";
      
      refSlider.current.style.position = "fixed";
      refSlider.current.style.top = "0px";

      const main = document.getElementById("main-view");
      refSliderBg.current.style.height = (main?.clientHeight ?? 1000) + "px" ;
    }
    //}, 200);

    document.body.style.overflow = isShow ? "hidden" : "unset";

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [isShow]);

  React.useEffect(() => {
    setIsShow(props.show);
  }, [props.show]);

  const onHide = () => {
    if (isShow) {
      setIsShow(false);
      props.onHide?.();
    }
  };
  const handleClick = (event) => {
    if (!isShow) return;
    const clickedElement = event.target as HTMLElement;

    if (
      !refSlider.current?.contains(clickedElement) &&
      clickedElement.clientHeight > 0 &&
      clickedElement.id !== "popup-cover" &&
      !isMobile
    ) {
      onHide();
    }
  };

  const showHeader = (): JSX.Element => {
    if (!props.header) return <></>;

    if (typeof props.header === "string") {
      return (
        <div className="header" id="slider_header">
          <Label
            wrap
            fontSize={props.fontSize ?? "lg"}
            fontStyle={props.fontStyle ?? "semibold"}
            fontColor={props.fontColor ?? "primary"}
            content={props.header}
          />
          <Icon.CloseSVG className="i-button close rounded-12 bg-primary" onClick={onHide} />
        </div>
      );
    }

    return (
      <div className="header" id="slider_header">
        {props.header}
        <Icon.CloseSVG className="i-button close" onClick={onHide} />
      </div>
    );
  };

  if (!isShow) return <></>;

  return (
    <div
      ref={refSliderBg}
      className="slider-cover"
      style={{
        left: isShow ? "0" : "100%",
      }}
    >
      <div
        ref={refSlider}
        className={`slider box-shadow-25 ${props.className ?? ""}`}
        style={{ width: size, right: isShow ? "0" : `-${size + 50}px` }}
      >
        {showHeader()}
        {props.show ? <div className="frame-main">{props.children}</div> : <></>}
      </div>
    </div>
  );
};
