import React from "react";
import * as Icon from "commons/iconManager";
import * as CategoryApi from "api/CategoryApi";
import * as GoogleMaps from "commons/googleManager";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { MainState, SearchState } from "models/new/State";
import { changeSearchState, clearSearchFilters, initialFilter } from "reducers/searchReducer";
import { InputComboBox } from "controls/InputComboBox";
import { InputNumber } from "controls/InputNumber";
import { InputCheckBox } from "controls/InputCheckBox";
import { Button } from "controls/Button";
import { EntryDictionary } from "models/new/EntryDictionary";
import { InputRange } from "controls/InputRange";
import { SearchFilter } from "models/new/SearchFilter";
import { changeMainState } from "reducers/mainReducer";
import { distance } from "commons/listManager";
import { Category } from "models/new/Category";
import { SearchProductFilter } from "models/new/SearchProductFilter";

const dictSort: EntryDictionary[] = [
  { code: "sortRate", name: "Najwyżej ocenianych" },
  { code: "sortPrice", name: "Ceny rosnąco" },
];

const SearchFilterComponent: React.FC = () => {
  const [filter, setFilter] = React.useState<SearchFilter>(initialFilter);
  const mainState: MainState = useSelector((state: any) => state.main);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
    }, 250);
    return () => clearTimeout(timeout);
  }, [filter]);

  React.useEffect(() => {
    setFilter(searchState.filters);
  }, [mainState.showFilter]);

  const downloadData = async () => {
    const bounds = GoogleMaps.getBoundsForPoint(searchState.geoLocation, filter.distance);
    let searchProductFilter: SearchProductFilter = {
      locLat: searchState.geoLocation.lat,
      locLng: searchState.geoLocation.lng,
      latMin: bounds.latLo,
      latMax: bounds.latHi,
      lngMin: bounds.lngLo,
      lngMax: bounds.lngHi,
      zoom: 10,
      phrase: filter.phrase,
      distance: filter.distance,
      promotion: filter.promotion,
      sort: filter.sort,
      priceFrom: filter.priceFrom,
      priceTo: filter.priceTo,
      stars: filter.stars,
      take: filter.take,
    };

    await CategoryApi.getFiltered(searchProductFilter)
      .then((values) => {
        dispatch(changeSearchState({ categories: values }));
      })
      .catch((err) => console.log("SearchFilterComponent - downloadData"));
  };
  const commitFilter = () => {
    dispatch(changeSearchState({ ...searchState, filters: { ...filter, take: 30 } }));
    dispatch(changeMainState({ showFilter: false }));
  };
  const clearFilter = () => {
    setFilter(initialFilter);
  };
  const onDisplayContent = (category: Category) => {
    return (
      <Button
        className={!category.parentId && category.id !== filter.categoryId ? "bg-secondary mag-b-2" : "mag-b-2"}
        sizeFrame="sm"
        kind={category.id === filter.categoryId ? "menu-seleceted" : "menu"}
        label={`${category.name} (${category.count})`}
        onClick={(e) => setFilter({ ...filter, categoryId: category.id })}
      />
    );
  };

  return (
    <div className={`filters ${mainState.showFilter && "filters-show"} border-left`}>
      <div className="frame-main pad-r-8 pad-l-8 gap-16" style={{ position: "relative" }}>
        <div className="d-flex ai-center jc-sb width-fill">
          <Label fontStyle="semibold" content="Panel filtrowania" />
          <Icon.CloseSVG
            className="i-button close rounded-12"
            onClick={() => dispatch(changeMainState({ showFilter: false }))}
          />
        </div>
        <Label fontStyle="semibold" content="Sortuj" />
        <InputComboBox
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          displayMember="name"
          placeholder="Wybierz sortowanie"
          itemSource={dictSort}
          selectedItem={dictSort.find((x) => x.code === filter.sort)}
          onSelectionChanged={(e) => setFilter({ ...filter, sort: e.code })}
        />
        <Label fontStyle="semibold" content="Filtruj" />
        <InputComboBox
          frameClassName="width-fill"
          displayMember="name"
          itemSource={searchState.categories ?? []}
          sizeFrame="sm"
          kind="tertiary"
          label="Kategoria"
          placeholder="Wybierz kategorie"
          onDisplayContent={onDisplayContent}
          selectedItem={searchState.categories?.find((x) => x.id === filter.categoryId)}
          onSelectionChanged={(e) => !e && setFilter({ ...filter, categoryId: undefined })}
        />
        <div className="d-flex gap-8">
          <InputNumber
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Cena od"
            placeholder="Od"
            min={0}
            fixed={2}
            max={filter.priceTo}
            value={filter.priceFrom}
            canClear={false}
            onSubmit={(e) =>
              setFilter({
                ...filter,
                priceFrom: isNaN(e.currentTarget.valueAsNumber) ? undefined : e.currentTarget.valueAsNumber,
              })
            }
          />
          <InputNumber
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Cena do"
            placeholder="Do"
            min={filter.priceFrom}
            max={20}
            fixed={2}
            value={filter.priceTo}
            canClear={false}
            onSubmit={(e) =>
              setFilter({
                ...filter,
                priceTo: isNaN(e.currentTarget.valueAsNumber) ? undefined : e.currentTarget.valueAsNumber,
              })
            }
          />
        </div>
        <InputRange
          min={0}
          max={20}
          value={{ min: filter.priceFrom, max: filter.priceTo }}
          onChange={(e) => setFilter({ ...filter, priceFrom: e.min, priceTo: e.max })}
        />
        <div className="d-flex fd-c">
          <Label className="pad-l-16" fontSize="sml" fontStyle="semibold" content="Oceny powyżej" />
          <div className="choose-button width-fill">
            <Button
              className="width-fill"
              sizeFrame="sm"
              label="1"
              kind={filter?.stars !== undefined && filter?.stars >= 1 ? "primary" : "text"}
              onClick={() => setFilter({ ...filter, stars: filter.stars === 1 ? undefined : 1 })}
            />
            <Button
              className="width-fill"
              sizeFrame="sm"
              label="2"
              kind={filter?.stars !== undefined && filter?.stars >= 2 ? "primary" : "text"}
              onClick={() => setFilter({ ...filter, stars: filter.stars === 2 ? undefined : 2 })}
            />
            <Button
              className="width-fill"
              sizeFrame="sm"
              label="3"
              kind={filter?.stars !== undefined && filter?.stars >= 3 ? "primary" : "text"}
              onClick={() => setFilter({ ...filter, stars: filter.stars === 3 ? undefined : 3 })}
            />
            <Button
              className="width-fill"
              sizeFrame="sm"
              label="4"
              kind={filter?.stars !== undefined && filter?.stars >= 4 ? "primary" : "text"}
              onClick={() => setFilter({ ...filter, stars: filter.stars === 4 ? undefined : 4 })}
            />
            <Button
              className="width-fill"
              sizeFrame="sm"
              label="5"
              kind={filter?.stars !== undefined && filter?.stars >= 5 ? "primary" : "text"}
              onClick={() => setFilter({ ...filter, stars: filter.stars === 5 ? undefined : 5 })}
            />
          </div>
        </div>

        <InputComboBox
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Dystans do sprzedawcy"
          displayMember="name"
          selectedItem={distance?.find((x) => x.value === filter.distance)}
          itemSource={distance}
          canClear={false}
          onSelectionChanged={(e) => setFilter({ ...filter, distance: e.value })}
          error={searchState.geoLocationState ? "" : "Wskaż swój adres inaczej filtr nie zadziała!"}
        />
        <InputCheckBox
          frameClassName="width-fill"
          sizeFrame="sml"
          kind="tertiary"
          checkboxLabel="Tylko oferty promocyjne"
          fontSize="sm"
          fontStyle="semibold"
          checked={filter.promotion}
          onChange={(e) => setFilter({ ...filter, promotion: e.target.checked })}
        />
        <div className="d-flex gap-8 pad-r-8 width-fill" style={{ position: "absolute", bottom: "0" }}>
          <Button kind="tertiary" sizeFrame="md" fontStyle="bold" label="Wyczyść" onClick={clearFilter} />
          <Button
            className="width-fill"
            sizeFrame="md"
            fontStyle="bold"
            kind="primary"
            label="Filtruj"
            icon={<Icon.TuneSVG />}
            onClick={commitFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilterComponent;
