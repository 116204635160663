import React from "react";
import { SearchState } from "models/new/State";
import { useSelector } from "react-redux";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import RatingStarComponent from "components/RatingStarComponent";
import RatingCommentComponent from "components/RatingCommentComponent";
import RatingComponent from "components/RatingComponent";
import { Button } from "controls/Button";
import WaitOnLoadingComponent from "components/WaitOnLoadingComponent";
import { useWindowSize } from "hooks/useWindowSize";

const SearchSupplierInfoRatingsComponent: React.FC = () => {
  const [selectedRate, setSelectedRate] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [seeMore, setSeeMore] = React.useState<boolean>(false);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    if (searchState.supplierInfo?.ratings) {
      setIsLoading(false);
    }
  }, [searchState.supplierInfo]);

  if (isMobile) {
    return (
      <div className="width-fill mag-b-16">
        {isLoading ? (
          <WaitOnLoadingComponent
            isLoading={isLoading}
            count={1}
            className="width-fill rounded-12"
            style={{ minHeight: "350px", marginTop: "8px", marginRight: "4px" }}
          />
        ) : (
          <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
            <div className="d-flex fd-c gap-8 width-fill">
              <Label fontStyle="semibold" fontSize="lg" content="Oceny i komentarze" />
              <div className="d-flex fd-c gap-8 width-fill">
                <RatingStarComponent
                  ratings={searchState.supplierInfo?.ratings}
                  selectedRate={selectedRate}
                  setSelectedRate={setSelectedRate}
                />
                <RatingCommentComponent ratings={searchState.supplierInfo?.ratings} />
              </div>
              <div className="f-scroll-hidden-r gap-8" style={{ maxHeight: "500px" }}>
                {searchState.supplierInfo?.ratings
                  ?.filter((x) => !selectedRate || x.rate === selectedRate)
                  .map((rating, idx) => {
                    return seeMore ? (
                      <RatingComponent rating={rating} />
                    ) : idx < 1 ? (
                      <RatingComponent rating={rating} />
                    ) : null;
                  })}
              </div>
              <Button
                sizeFrame="sml"
                kind="tertiary"
                label={seeMore ? "Mniej" : "Wiecej"}
                onClick={() => setSeeMore(!seeMore)}
              />
            </div>
          </InfoBox>
        )}
      </div>
    );
  }

  return (
    <div className="width-fill">
      {isLoading ? (
        <WaitOnLoadingComponent
          isLoading={isLoading}
          count={1}
          className="width-fill rounded-12"
          style={{ minHeight: "350px", marginTop: "8px", marginRight: "4px" }}
        />
      ) : (
        <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
          <div className="d-flex fd-c gap-8 width-fill">
            <Label fontStyle="semibold" fontSize="lg" content="Oceny i komentarze" />
            <div className="d-flex fd-c gap-8 width-fill">
              <RatingStarComponent
                ratings={searchState.supplierInfo?.ratings}
                selectedRate={selectedRate}
                setSelectedRate={setSelectedRate}
              />
              <RatingCommentComponent ratings={searchState.supplierInfo?.ratings} />
            </div>
            <div className="frame-main gap-8 width-fill">
              {searchState.supplierInfo?.ratings
                ?.filter((x) => !selectedRate || x.rate === selectedRate)
                .map((rating, idx) => {
                  return seeMore ? (
                    <RatingComponent rating={rating} />
                  ) : idx < 1 ? (
                    <RatingComponent rating={rating} />
                  ) : null;
                })}
            </div>
            <Button
              sizeFrame="sml"
              kind="tertiary"
              label={seeMore ? "Mniej" : "Wiecej"}
              onClick={() => setSeeMore(!seeMore)}
            />
          </div>
        </InfoBox>
      )}
    </div>
  );
};

export default SearchSupplierInfoRatingsComponent;
