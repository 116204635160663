import React from "react";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";

const LandingDealerBenefitsComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div className="d-flex fd-c ai-center" style={{ gap: "64px", marginBlock: "64px" }}>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-l" src="/img/supplier_map.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-16 mag-r-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Daj się znaleźć!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Załóż konto sprzedawcy i zaprezentuj się użytkownikom, szukających lokalnie producentów zdrowej i naturalnej żywności. Dołącz zdjęcia i uzyskane certyfikaty, żeby przyciągnąć kupujących."
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-r" src="/img/supplier_market.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-4 mag-r-16" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Uniezależnij się od skupów!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Dodawaj swoje sezonowe produkty, ustalaj własne ceny, określaj dostępności - to Ty kontrolujesz kiedy, co i za ile sprzedajesz."
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-l" src="/img/supplier_managment.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-16 mag-r-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Kontroluj otrzymane zamówienia!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Dzięki liście zamówionych produktów oraz opcjom ich grupowania, możesz przygotować dokładne takie ilości, jakie musisz dostarczyć w danym dniu."
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-r" src="/img/supplier_city_sale.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-4 mag-r-16" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Dostarczaj zdrowe produkty!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Sprzedawaj klientom, którzy przyjadą do Twojego gospodarstwa, lub wyznacz dodatkowe punkty odbioru - czy sprzedajesz na targu, czy przyjeżdżasz na osiedle na którym mieszka kilku Twoich stałych odbiorców, daj znać, gdzie można Cię znaleźć!"
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-l" src="/img/supplier_contact.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-16 mag-r-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Komunikuj się ze swoimi klientami!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Dzięki wbudowanemu komunikatorowi oraz funkcjom społecznościowym, pokazuj swoje gospodarstwo, informuj o nadchodzących zbiorach i buduj trwałe relacje ze swoimi odbiorcami."
              />
            </div>
          </InfoBox>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c ai-center" style={{ maxWidth: "1136px", gap: "64px", marginBlock: "64px" }}>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-l" src="/img/supplier_map.jpg" height={400} />
        </div>
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Daj się znaleźć!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Załóż konto sprzedawcy i zaprezentuj się użytkownikom, szukających lokalnie producentów zdrowej i naturalnej żywności. Dołącz zdjęcia i uzyskane certyfikaty, żeby przyciągnąć kupujących."
          />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Uniezależnij się od skupów!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Dodawaj swoje sezonowe produkty, ustalaj własne ceny, określaj dostępności - to Ty kontrolujesz kiedy, co i za ile sprzedajesz."
          />
        </div>
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-r" src="/img/supplier_market.jpg" height={400} />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-l" src="/img/supplier_managment.jpg" height={400} />
        </div>
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Kontroluj otrzymane zamówienia!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Dzięki liście zamówionych produktów oraz opcjom ich grupowania, możesz przygotować dokładne takie ilości, jakie musisz dostarczyć w danym dniu."
          />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Dostarczaj zdrowe produkty!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Sprzedawaj klientom, którzy przyjadą do Twojego gospodarstwa, lub wyznacz dodatkowe punkty odbioru - czy sprzedajesz na targu, czy przyjeżdżasz na osiedle na którym mieszka kilku Twoich stałych odbiorców, daj znać, gdzie można Cię znaleźć!"
          />
        </div>
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-r" src="/img/supplier_city_sale.jpg" height={400} />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-l" src="/img/supplier_contact.jpg" height={400} />
        </div>
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Komunikuj się ze swoimi klientami!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Dzięki wbudowanemu komunikatorowi oraz funkcjom społecznościowym, pokazuj swoje gospodarstwo, informuj o nadchodzących zbiorach i buduj trwałe relacje ze swoimi odbiorcami."
          />
        </div>
      </div>
    </div>
  );
};

export default LandingDealerBenefitsComponent;
