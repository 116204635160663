import React from "react";
import * as Icon from "commons/iconManager";

interface PictureBox {
  url?: string;
  className: string;
  onClick?: () => void;
}

const PictureBoxComponent: React.FC<PictureBox> = ({ url, className, onClick }) => {
  if (url) return <img className={className} src={url} onClick={() => onClick?.()} />;

  return (
    <div className={`d-flex ai-center jc-center ${className}`}>
      <Icon.NoPhotographySVG className="icon-color" height={32} width={32} />
    </div>
  );
};

export default PictureBoxComponent;
