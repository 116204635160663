import React from "react";
import * as Icon from "commons/iconManager";
import * as ProductApi from "api/ProductApi";
import PathComponent from "components/PathPageComponent";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { useURLParams } from "hooks/useURLParams";
import GroceryDataComponent from "./GroceryDataComponent";
import GroceryAvailabilityComponent from "./GroceryAvailabilityComponent";
import GrocerySaleComponent from "./GrocerySaleComponent";
import GroceryDeliveryComponent from "./GroceryDeliveryComponent";
import GroceryRateComponent from "./GroceryRateComponent";
import GroceryHistoryComponent from "./GroceryHistoryComponent";
import GroceryStatisticComponent from "./GroceryStatisticComponent";
import { useDispatch, useSelector } from "react-redux";
import { cleanValideErrorProduct, selectedProduct, setValideErrorProduct } from "reducers/productReducer";
import GroceryCertificatesComponent from "./GroceryCertificatesComponent";
import { ProductState, ProductValideError, UserState } from "models/new/State";
import { ProductInfo } from "models/new/ProductInfo";
import { Picture } from "models/new/Picture";
import { ValidationFailure } from "models/new/ValidationFailure";
import { ProductDto } from "models/DTO/ProductDto";
import { NotificationType, stringToAvailableType } from "models/new/Dictionaries";
import { useWindowSize } from "hooks/useWindowSize";
import GroceryMobileManagerComponent from "./mobile/GroceryMobileManagerComponent";
import { PopUp } from "controls/PopUp";
import { Label } from "controls/Label";
import { useNotify } from "hooks/useNotify";

export interface GroceryTabProps {
  id: string;
}

const GroceryManagerComponent: React.FC = () => {
  const productState: ProductState = useSelector((state: any) => state.product);
  const userState: UserState = useSelector((state: any) => state.user);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { isMobile } = useWindowSize();
  const { addNotify } = useNotify();
  const { navigateTo } = useNavigation();
  const dispatch = useDispatch();

  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const { params } = useURLParams();
  const action = params.get("action") ?? "";
  const tab = params.get("tab") ?? "";
  const id = params.get("id") ?? "";

  React.useEffect(() => {
    downloadData();
    setTimeout(() => {
      const childElement = document.getElementById(tab);
      childElement?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    return () => {
      dispatch(selectedProduct(undefined));
    };
  }, []);

  const downloadData = async () => {
    if (action === "create") {
      document.title = `Ogrosa | Nowy produkt`;
    } else {
      let downloadLoading: boolean = true;
      setTimeout(() => {
        setIsLoading(downloadLoading);
      }, 200);

      scrollRef.current?.scroll({ top: 0, behavior: "smooth" });
      const product = ProductApi.getProduct(id);
      const productAddresses = ProductApi.getProductAddresses(id);
      const productRatings = ProductApi.getProductRatings(id);
      const productCertificates = ProductApi.getProductCertificates(id);
      const productPictures = ProductApi.getProductPictures(id);
      const productSaleCoupons = ProductApi.getProductSaleCoupons(id);

      await Promise.all([
        product,
        productAddresses,
        productRatings,
        productCertificates,
        productPictures,
        productSaleCoupons,
      ])
        .then((values) => {
          const productInfo: ProductInfo = values[0];
          productInfo.addresses = values[1];
          productInfo.ratings = values[2];
          productInfo.certificates = values[3];
          productInfo.pictures = values[4];
          productInfo.saleCoupons = values[5];
          dispatch(selectedProduct(productInfo));
          downloadLoading = false;
          document.title = `Ogrosa | ${productInfo.name}`;
        })
        .catch((err) => console.log("GroceryManagerComponent - downloadData"))
        .finally(() => setIsLoading(false));
    }
  };

  function goTo(tab: string): void {
    navigateTo("grocery_manager", `?action=${action}&tab=${tab}&id=${id}`);
    const childElement = document.getElementById(tab);
    childElement?.scrollIntoView({ behavior: "smooth" });
  };

  const save = async () => {
    setIsLoading(true);
    PopUp.show(
      scrollRef.current,
      <div className="d-flex fd-c ai-center gap-32">
        <Icon.LoadingSVG height={128} width={128} />
        <Label fontSize="md" fontStyle="bold" content="Trwa zapisywanie..." />
      </div>,
      undefined,
      undefined,
      undefined,
      true
    );
    dispatch(cleanValideErrorProduct());
    let clone = structuredClone(productState.selected);
    if (!clone?.supplierId) {
      clone = { ...clone!, supplierId: userState.supplier!.id };
    }

    const toDelete: string[] = [];
    let savePicture: Picture[] =
      productState.selected?.pictures?.map((pic) => {
        return { id: pic.id, url: pic.url };
      }) ?? [];

    const formData = new FormData();
    if (productState.selected?.pictures && productState.selected?.pictures?.length > 0) {
      productState.selected?.pictures.forEach((file, index) => {
        if (file.file && !file.url) {
          formData.append(`files`, file.file!);
          toDelete.push(file.id);
        }
      });
    }
    if (toDelete.length > 0) {
      await ProductApi.savePictureProduct(formData)
        .then((value) => {
          savePicture = savePicture?.map((picture) => {
            const saved = value.find((x) => x.id === picture.id);
            if (saved) {
              return saved;
            }
            return picture;
          });
        })
        .catch((err) => {
          addNotify({ title: "Błąd", content: err, type: NotificationType.Error });
          setIsLoading(false);
          return;
        });
    }

    const productDto: ProductDto = {
      id: clone.id,
      supplierId: clone.supplierId,
      name: clone.name,
      description: clone.description,
      price: clone.price,
      salePrice: clone.salePrice,
      quantity: clone.quantity,
      unit: clone.unit,
      noLimitQuantity: clone.noLimitQuantity,
      availableType: stringToAvailableType(clone.availableType),
      whenAvailable: clone.whenAvailable,
      photoUrl: clone.photoUrl,
      blockReservation: clone.blockReservation,
      courierDelivery: clone.courierDelivery,
      personalCollectionDelivery: clone.personalCollectionDelivery,
      localDelivery: clone.localDelivery,
      categoryId: clone.categoryId,
      certificates: clone.certificates,
      saleCoupons: clone.saleCoupons ?? [],
      pictures: savePicture,
    };

    await ProductApi.saveProduct(productDto)
      .then(async (value) => {
        addNotify({
          title: "Świetnie",
          content: "Zmiany na produkcie został zapisane!",
          type: NotificationType.Success,
        });
        navigateTo("grocery");
      })
      .catch((err) => {
        setError(err);
        toDelete.forEach((del) => {
          ProductApi.deleteProductPicture(del);
        });
      })
      .finally(() => setIsLoading(false));
    PopUp.onHide();
  };

  const setError = (err: ValidationFailure[] | string) => {
    if (typeof err === "string") {
      addNotify({
        title: "Błąd",
        content: `Wystąpił błąd podczas zapisu produktu: ${err}`,
        type: NotificationType.Error,
      });
    } else {
      const valideError: ProductValideError = {
        name: err?.find((x) => x.PropertyName?.toLowerCase() === "name")?.ErrorMessage ?? "",
        availableType: err?.find((x) => x.PropertyName?.toLowerCase() === "availabletype")?.ErrorMessage ?? "",
        whenAvailable: err?.find((x) => x.PropertyName?.toLowerCase() === "whenavailable")?.ErrorMessage ?? "",
        unit: err?.find((x) => x.PropertyName?.toLowerCase() === "unit")?.ErrorMessage ?? "",
        deliveryOption: err?.find((x) => x.PropertyName?.toLowerCase() === "deliveryoption")?.ErrorMessage ?? "",
        price: err?.find((x) => x.PropertyName?.toLowerCase() === "price")?.ErrorMessage ?? "",
        salePrice: err?.find((x) => x.PropertyName?.toLowerCase() === "saleprice")?.ErrorMessage ?? "",
        quantity: err?.find((x) => x.PropertyName?.toLowerCase() === "quantity")?.ErrorMessage ?? "",
        productPictures: err?.find((x) => x.PropertyName?.toLowerCase() === "photourl")?.ErrorMessage ?? "",
        description: err?.find((x) => x.PropertyName?.toLowerCase() === "description")?.ErrorMessage ?? "",
        category: err?.find((x) => x.PropertyName?.toLowerCase() === "categoryid")?.ErrorMessage ?? "",
      };
      dispatch(setValideErrorProduct(valideError));
      addNotify({
        title: "Błąd",
        content: "Wystąpił błąd podczas zapisu produktu",
        type: NotificationType.Error,
      });
    }
  };

  if (isMobile) return <GroceryMobileManagerComponent />;

  return (
    <div className="f-fill gap-16">
      <div className="frame-header jc-sb">
        <PathComponent />
        {action !== "preview" && (
          <div className="d-flex fd-r gap-8">
            <Button
              sizeFrame="sm"
              kind="tertiary"
              fontStyle="bold"
              label="Anuluj"
              onClick={() => navigateTo("grocery")}
            />
            <Button
              sizeFrame="sm"
              kind="primary"
              fontStyle="bold"
              label="Zapisz"
              onClick={save}
              isLoading={isLoading}
              icon={<Icon.SaveSVG />}
            />
          </div>
        )}
      </div>
      <div className="frame-main fd-r">
        <div
          className="d-flex fd-c gap-8 pad-16 height-fill border-right"
          style={{ width: "250px", marginBlock: "16px" }}
        >
          <Button
            sizeFrame="sm"
            kind={tab === "data" ? "menu-seleceted" : "menu"}
            label="Dane podstawowe"
            icon={<Icon.InfoSVG />}
            error={productState.valideError.name.length > 0}
            onClick={() => goTo("data")}
          />
          <Button
            sizeFrame="sm"
            kind={tab === "sale" ? "menu-seleceted" : "menu"}
            label="Cena i promocje"
            icon={<Icon.ShoppingModeSVG />}
            onClick={() => goTo("sale")}
            error={productState.valideError.price.length > 0}
          />
          <Button
            sizeFrame="sm"
            kind={tab === "available" ? "menu-seleceted" : "menu"}
            label="Dostępność"
            icon={<Icon.WarehouseSVG />}
            onClick={() => goTo("available")}
            error={
              productState.valideError.quantity.length > 0 ||
              productState.valideError.availableType.length > 0 ||
              productState.valideError.unit.length > 0
            }
          />
          <Button
            sizeFrame="sm"
            kind={tab === "certificates" ? "menu-seleceted" : "menu"}
            label="Certyfikaty"
            icon={<Icon.LicenseSVG />}
            onClick={() => goTo("certificates")}
          />
          <Button
            sizeFrame="sm"
            kind={tab === "delivery" ? "menu-seleceted" : "menu"}
            label="Sposoby przesyłki"
            icon={<Icon.LocalShippingSVG />}
            onClick={() => goTo("delivery")}
            error={productState.valideError.deliveryOption.length > 0}
          />
          <Button
            sizeFrame="sm"
            kind={tab === "rate" ? "menu-seleceted" : "menu"}
            label="Oceny i komentarze"
            icon={<Icon.CommentSVG />}
            onClick={() => goTo("rate")}
          />
          <Button
            sizeFrame="sm"
            kind={tab === "history" ? "menu-seleceted" : "menu"}
            label="Historia zamówień"
            icon={<Icon.HistorySVG />}
            onClick={() => goTo("history")}
          />
          <Button
            style={{ width: "100%" }}
            sizeFrame="sm"
            kind={tab === "statistic" ? "menu-seleceted" : "menu"}
            label="Statystyki"
            icon={<Icon.TrendingUpSVG />}
            onClick={() => goTo("statistic")}
          />
        </div>
        <div ref={scrollRef} className="frame-scroll">
          <GroceryDataComponent id="data" />
          <GrocerySaleComponent id="sale" />
          <GroceryAvailabilityComponent id="available" />
          <GroceryCertificatesComponent id="certificates" />
          <GroceryDeliveryComponent id="delivery" />
          <GroceryRateComponent id="rate" />
          <GroceryHistoryComponent id="history" />
          <GroceryStatisticComponent id="statistic" />
        </div>
      </div>
    </div>
  );
};

export default GroceryManagerComponent;
