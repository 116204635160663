import React from "react";

export interface BadgeProps {
  show: boolean;
  children: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = (props) => {
  return (
    <div className="d-flex ai-center jc-center" style={{ position: "relative" }}>
      {props.show && <div className="circle" />}
      {props.children}
    </div>
  );
};
