import React from "react";
import { Label } from "controls/Label";
import * as Icon from "commons/iconManager";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import { Button } from "controls/Button";

interface PictureManager {
  title?: string;
  files?: File[];
  onChange: (files?: File[]) => void;
  disabled?: boolean;
}

const PictureManagerComponent: React.FC<PictureManager> = (props) => {
  const [files, setFiles] = React.useState<File[] | undefined>(props.files);
  const [overDrop, setOverDrop] = React.useState<boolean>(false);
  const [mouseOver, setMouseOver] = React.useState<number>(-1);
  const refInput = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  const selectFile = (file?: File) => {
    if (props.disabled) return;

    if (file) {
      setFiles(files?.filter((x) => x !== file));
      return;
    }
    refInput.current.click();
  };

  const handleFileInputChange = (event: any) => {
    const file: File = event.target.files[0];
    addImage(file);
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file: File = e.dataTransfer.files[0];
      addImage(file);
    }
  };
  const onDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onDragEnter = (e: any) => {
    if (props.disabled) return;
    setOverDrop(true);
    e.preventDefault();
    e.stopPropagation();
  };
  const onDragLeave = (e: any) => {
    setTimeout(() => {
      setOverDrop(false);
      e.preventDefault();
      e.stopPropagation();
    }, 2000);
  };

  const addImage = (file: File) => {
    if (file === undefined) {
      return;
    }
    if (files && files.length > 2) {
      ModalDialog.show(
        <MessageBox
          content={`Przekroczono limit zdjęć! Maksymalnie możesz dodać tylko trzy zdjęcia.`}
          icon="Information"
        />,
        "Limit",
        ["Ok"]
      );
      return;
    }
    if (file.size > 5242880) {
      ModalDialog.show(
        <MessageBox
          content={`Przekroczono limit rozmiaru zdjęcia! Maksymalny rozmiar zdjęcia to 5MB.`}
          icon="Information"
        />,
        "Limit",
        ["Ok"]
      );
      return;
    }
    if (files?.some((x) => x.name === file.name)) {
      ModalDialog.show(<MessageBox content="Istnieje zdjęcie o podobnej nazwie!" icon="Information" />, "Limit", [
        "Ok",
      ]);
      return;
    }
    if (file.type.includes("png") || file.type.includes("jpg") || file.type.includes("jpeg")) {
      let newFileList: File[] = [];
      if (files) {
        newFileList = [...files!];
      }
      newFileList.push(file);
      setFiles(newFileList);
      props.onChange(newFileList);
      mouseMoveOver(-1);
      refInput.current.value = "";
    } else {
      ModalDialog.show(
        <MessageBox content={`Wybierz zdjęcie z rozszerzeniem png, jpg lub jpeg.`} icon="Information" />,
        "Złe rozszrzenie pliku!",
        ["Ok"]
      );
      return;
    }
  };

  const clearImage = (file: File) => {
    setTimeout(() => {
      let newFileList = files?.filter((x) => x !== file);
      setFiles(newFileList);
      props.onChange(newFileList);
    }, 200);
  };

  const mouseMoveOver = (value: number) => {
    if (props.disabled) return;
    setMouseOver(value);
  };

  return (
    <div className="d-flex fd-c ai-s-flex gap-16">
      <div className="d-flex ai-center jc-sb width-fill gap-4">
        <div className="d-flex fd-c ai-s-flex gap-4">
          <div className="d-flex gap-4">
            <Label fontSize="lg" fontStyle="bold" content={props.title ?? "Zdjęcia"} />
            <Label fontSize="lg" fontStyle="bold" fontColor="red" content={"*"} />
          </div>
          <Label fontSize="md" fontStyle="semibold" content="Pierwsze zdjęcie będzie zdjęciem głównym." />
        </div>
        <Button
          kind="transparent"
          sizeFrame="lg"
          style={{ padding: "0px" }}
          icon={<Icon.InfoSVG />}
          toolTip="Przeciągnij zdjęcie w miejsce obrazka aparatu lub wybierz z dysku klikając w aparat pamiętaj, że maksymalny rozmiar zdjęcia to 5MB"
        />
      </div>

      <div className="d-flex fd-r ai-s-flex jc-sb gap-16 width-fill">
        {[0, 1, 2].map((x) => {
          const file = files?.[x];
          return (
            <div
              className="add-picture-box"
              onDrop={onDrop}
              onDragOver={onDragOver}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onMouseOver={() => mouseMoveOver(x)}
              onMouseLeave={() => mouseMoveOver(-1)}
              onClick={() => selectFile(file)}
              style={{ cursor: props.disabled ? "default" : "pointer" }}
            >
              {file ? (
                <>
                  {mouseOver === x ? (
                    <div className="d-flex fd-c jc-center ai-center gap-4" onClick={() => clearImage(file)}>
                      <Icon.DeleteSVG className="icon" />
                      <Label fontSize="sml" fontStyle="semibold" pointer content="Usuń zdjęcie" />
                    </div>
                  ) : (
                    <img
                      className="picture"
                      src={file.size === 0 ? file.type : URL.createObjectURL(file)}
                      alt={file.name}
                    />
                  )}
                </>
              ) : (
                <div className="d-flex fd-c jc-center ai-center gap-4">
                  <Icon.PhotoCameraSVG className={`icon ${overDrop && "icon--move"}`} />
                  <Label fontSize="sml" fontStyle="semibold" pointer content="Dodaj zdjęcie" />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <input
        style={{ display: "none" }}
        ref={refInput}
        type="file"
        accept=".png, .jpg, .jpeg"
        multiple={false}
        onChange={(e) => handleFileInputChange(e)}
      />
    </div>
  );
};

export default PictureManagerComponent;
