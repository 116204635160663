import React from "react";
import * as Icons from "commons/iconManager";
import RatingStarComponent from "components/RatingStarComponent";
import { Label } from "controls/Label";
import { useNavigation } from "hooks/useNavigation";
import PriceComponent from "components/PriceComponent";
import { useDispatch, useSelector } from "react-redux";
import {  setOverDealer } from "reducers/searchReducer";
import PictureBoxComponent from "components/PictureBoxComponent";
import { Product } from "models/new/Product";
import { Button } from "controls/Button";
import { SearchState } from "models/new/State";
import { addProductToBasket } from "reducers/basketReducer";
import { Tag } from "controls/Tag";
import { convertProductToOrderProduct } from "commons/productManager";
import { changeMainState } from "reducers/mainReducer";
import { useWindowSize } from "hooks/useWindowSize";
import { NotificationType } from "models/new/Dictionaries";
import { useNotify } from "hooks/useNotify";

interface SearchProductProps {
  product: Product;
  withDealer?: boolean;
}

const SearchProductComponent: React.FC<SearchProductProps> = React.memo(({ product, withDealer }) => {
  const { isMobile } = useWindowSize();
  const { navigateTo } = useNavigation();
  const { addNotify } = useNotify();
  const searchState: SearchState = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  const goTo = () => {
    if (withDealer) {
      navigateTo("search", `?product_id=${product.id}&dealer_id=${product.supplierId}`);
      dispatch(changeMainState({ showBlog: false }));
    } else {
      navigateTo("search", `?product_id=${product.id}`);
      dispatch(changeMainState({ showBlog: false }));
    }
  };

  const onAddToBasket = async () => {
    const prod = convertProductToOrderProduct(product);
    dispatch(
      addProductToBasket({
        dealer: searchState.supplierInfo!,
        orderProducts: { ...prod, quantity: 1 },
      })
    );
      addNotify({
        title: "Dodano",
        content: `Dodano do koszyka produkt ${product.name} ilość ${1} ${product.unit}!`,
        type: NotificationType.Success,
      });
  };

  const getAvailableInfo = (): JSX.Element => {
    if (!withDealer) return <></>;

    if (product.availableType === "Available")
      return <Tag sizeFrame="xsm" fontStyle="bold" kind="green" label="Dostępny" />;
    if (product.availableType === "TemporarilyUnavailable")
      return (
        <Tag
          sizeFrame="xsm"
          fontStyle="bold"
          kind="yellow"
          label={`Dostępny od ${new Date(product.whenAvailable ?? "")?.toFormat("dd.MM.yyyy")}`}
        />
      );
    if (product.availableType === "Unavailable")
      return <Tag sizeFrame="xsm" fontStyle="bold" kind="red" label="Niedostępny" />;
    return <></>;
  };

  if(isMobile){
    return (
      <div
        onMouseOver={() =>
          dispatch(
            setOverDealer({
              userId: product.supplierId,
              latitude: product.latitude,
              longitude: product.longitude,
            })
          )
        }
        onMouseLeave={() => dispatch(setOverDealer(undefined))}
        style={{ position: "relative" }}
      >
        <div className="product" onClick={goTo}>
          <PictureBoxComponent className="image-on-search-list" url={product.photoUrl ?? ""} />
          <div className="d-flex fd-c ai-s-flex width-fill">
            {getAvailableInfo()}
            <Label className="max-l-2" fontStyle="semibold" pointer wrap content={product.name} />
            <Label
              className="max-l-2"
              fontSize="sml"
              fontStyle="semibold"
              fontColor="secondary"
              pointer
              wrap
              content={product.companyName}
            />
            <RatingStarComponent rating={product.rating} ratingQty={product.ratingQty} />
            <PriceComponent product={product} />
          </div>
        </div>
        {withDealer && product.availableType !== "Unavailable" && (
          <Button
            style={{ position: "absolute", right: "0px", top: "4px" }}
            sizeFrame="sm"
            kind="tertiary"
            fontStyle="semibold"
            toolTip="Dodaj do koszyka"
            icon={<Icons.AddShoppingCartSVG />}
            iconPosition="right"
            onClick={onAddToBasket}
          />
        )}
      </div>
    );
  }

  return (
    <div
      onMouseOver={() =>
        dispatch(
          setOverDealer({
            userId: product.supplierId,
            latitude: product.latitude,
            longitude: product.longitude,
          })
        )
      }
      onMouseLeave={() => dispatch(setOverDealer(undefined))}
      style={{ position: "relative" }}
    >
      <div className="product" onClick={goTo}>
        <PictureBoxComponent className="image-on-search-list" url={product.photoUrl ?? ""} />
        <div className="d-flex fd-c ai-s-flex gap-4 width-fill">
          {getAvailableInfo()}
          <Label className="max-l-2" fontSize="lg" fontStyle="semibold" pointer wrap content={product.name} />
          <Label
            className="max-l-2"
            fontSize="sm"
            fontStyle="semibold"
            fontColor="secondary"
            pointer
            wrap
            content={product.companyName}
          />
          <RatingStarComponent rating={product.rating} ratingQty={product.ratingQty} />
          <PriceComponent product={product} />
        </div>
      </div>
      {withDealer && product.availableType !== "Unavailable" && (
        <Button
          style={{ position: "absolute", right: "0px", top: "4px" }}
          sizeFrame="sm"
          kind="tertiary"
          fontStyle="semibold"
          toolTip="Dodaj do koszyka"
          icon={<Icons.AddShoppingCartSVG />}
          iconPosition="right"
          onClick={onAddToBasket}
        />
      )}
    </div>
  );
});

export default SearchProductComponent;
