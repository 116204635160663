import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { GroceryTabProps } from "./GroceryManagerComponent";
import InfoBox from "controls/InfoBox";
import { useDispatch, useSelector } from "react-redux";
import { ProductState } from "models/new/State";
import { updateProduct } from "reducers/productReducer";
import { useURLParams } from "hooks/useURLParams";
import { useWindowSize } from "hooks/useWindowSize";

const GroceryDeliveryComponent: React.FC<GroceryTabProps> = (props) => {
  const productState: ProductState = useSelector((state: any) => state.product);
  const dispatch = useDispatch();
  const { params } = useURLParams();
  const { isMobile } = useWindowSize();
  const action = params.get("action") ?? "";

  if (isMobile) {
    return (
      <div
        id={props.id}
        className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill"
        style={{ maxWidth: "500px" }}
      >
        <Label fontStyle="bold" fontSize="md" content="Sposoby przesyłki" />
        {productState.valideError.deliveryOption.length > 0 && (
          <InfoBox
            className="width-fill ai-center"
            icon="Error"
            iconSize="sml"
            fontStyle="semibold"
            backgroundColor="red"
            value={productState.valideError.deliveryOption}
          />
        )}
        <div id={props.id} className="d-flex fd-c gap-32 pad-b-8 width-fill">
          <InfoBox
            className="hover-box"
            style={{ width: "calc(100% - 16px)" }}
            sizeFrame="sm"
            backgroundColor={productState.selected?.personalCollectionDelivery ? "green" : "secondary"}
            onClick={() => {
              if (action === "preview") return;
              dispatch(
                updateProduct({
                  product: {
                    personalCollectionDelivery: !productState.selected?.personalCollectionDelivery,
                  },
                  errors: { deliveryOption: "" },
                })
              );
            }}
          >
            <div className="d-flex ai-center gap-16 c-pointer">
              <Icon.HomePinSVG
                className={productState.selected?.personalCollectionDelivery ? "icon-dark" : "icon-color"}
                style={{ minHeight: "32px", minWidth: "32px" }}
              />
              <div className="d-flex fd-c gap-2">
                <Label
                  pointer
                  fontStyle="bold"
                  fontColor={productState.selected?.personalCollectionDelivery ? "black" : "primary"}
                  content="Odbiór osobisty"
                />
                <Label
                  pointer
                  fontSize="sml"
                  fontStyle="semibold"
                  fontColor={productState.selected?.personalCollectionDelivery ? "black" : "primary"}
                  wrap
                  content="Odbiór osobisty to opcja, która pozwala klientom na samodzielne odebranie zakupionych produktów w wyznaczonym miejscu i czasie."
                />
              </div>
            </div>
          </InfoBox>
          <InfoBox
            className="hover-box"
            style={{ width: "calc(100% - 16px)" }}
            sizeFrame="sm"
            backgroundColor={productState.selected?.localDelivery ? "green" : "secondary"}
            onClick={() => {
              if (action === "preview") return;
              dispatch(
                updateProduct({
                  product: {
                    localDelivery: !productState.selected?.localDelivery,
                  },
                  errors: { deliveryOption: "" },
                })
              );
            }}
          >
            <div className="d-flex ai-center gap-16 c-pointer">
              <Icon.StorefrontSVG
                className={productState.selected?.localDelivery ? "icon-dark" : "icon-color"}
                style={{ minHeight: "32px", minWidth: "32px" }}
              />
              <div className="d-flex fd-c gap-2">
                <Label
                  pointer
                  fontStyle="bold"
                  fontColor={productState.selected?.localDelivery ? "black" : "primary"}
                  content="Dostawa lokalna"
                />
                <Label
                  pointer
                  fontSize="sml"
                  fontStyle="semibold"
                  fontColor={productState.selected?.localDelivery ? "black" : "primary"}
                  wrap
                  content="Dostawa lokalna jest dostępna dla klientów znajdujących się w określonym obszarze, którzy mogą odebrać swoje zmówienie w punktach odbioru wskazanych w ustawieniach profilu."
                />
              </div>
            </div>
          </InfoBox>
        </div>
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Sposoby przesyłki" />
      {productState.valideError.deliveryOption.length > 0 && (
        <InfoBox
          className="width-fill ai-center"
          icon="Error"
          iconSize="sml"
          fontStyle="semibold"
          backgroundColor="red"
          value={productState.valideError.deliveryOption}
        />
      )}
      <div id={props.id} className="d-flex fd-c gap-16 pad-b-8 width-fill">
        <InfoBox
          className="hover-box"
          style={{ width: "calc(100% - 16px)" }}
          sizeFrame="sm"
          backgroundColor={productState.selected?.personalCollectionDelivery ? "green" : "secondary"}
          onClick={() => {
            if (action === "preview") return;
            dispatch(
              updateProduct({
                product: {
                  personalCollectionDelivery: !productState.selected?.personalCollectionDelivery,
                },
                errors: { deliveryOption: "" },
              })
            );
          }}
        >
          <div className="d-flex ai-center gap-16 c-pointer">
            <Icon.HomePinSVG
              className={productState.selected?.personalCollectionDelivery ? "icon-dark" : "icon-color"}
              style={{ minHeight: "32px", minWidth: "32px" }}
            />
            <div className="d-flex fd-c gap-2">
              <Label
                pointer
                fontStyle="bold"
                fontColor={productState.selected?.personalCollectionDelivery ? "black" : "primary"}
                content="Odbiór osobisty"
              />
              <Label
                pointer
                fontSize="sml"
                fontStyle="semibold"
                fontColor={productState.selected?.personalCollectionDelivery ? "black" : "primary"}
                wrap
                content="Odbiór osobisty to opcja, która pozwala klientom na samodzielne odebranie zakupionych produktów w wyznaczonym miejscu i czasie."
              />
            </div>
          </div>
        </InfoBox>
        <InfoBox
          className="hover-box"
          style={{ width: "calc(100% - 16px)" }}
          sizeFrame="sm"
          backgroundColor={productState.selected?.localDelivery ? "green" : "secondary"}
          onClick={() => {
            if (action === "preview") return;
            dispatch(
              updateProduct({
                product: {
                  localDelivery: !productState.selected?.localDelivery,
                },
                errors: { deliveryOption: "" },
              })
            );
          }}
        >
          <div className="d-flex ai-center gap-16 c-pointer">
            <Icon.StorefrontSVG
              className={productState.selected?.localDelivery ? "icon-dark" : "icon-color"}
              style={{ minHeight: "32px", minWidth: "32px" }}
            />
            <div className="d-flex fd-c gap-2">
              <Label
                pointer
                fontStyle="bold"
                fontColor={productState.selected?.localDelivery ? "black" : "primary"}
                content="Dostawa lokalna"
              />
              <Label
                pointer
                fontSize="sml"
                fontStyle="semibold"
                fontColor={productState.selected?.localDelivery ? "black" : "primary"}
                wrap
                content="Dostawa lokalna jest dostępna dla klientów znajdujących się w określonym obszarze, którzy mogą odebrać swoje zmówienie w punktach odbioru wskazanych w ustawieniach profilu."
              />
            </div>
          </div>
        </InfoBox>
      </div>
    </div>
  );
};

export default GroceryDeliveryComponent;

/*
  MOBILE
          <InfoBox
            className="hover-box"
            style={{ width: "calc(100% - 16px)" }}
            sizeFrame="sm"
            backgroundColor={productState.selected?.courierDelivery ? "green" : "secondary"}
            onClick={() => {
              if (action === "preview") return;
              dispatch(
                updateProduct({
                  product: {
                    courierDelivery: !productState.selected?.courierDelivery,
                  },
                })
              );
            }}
          >
            <div className="d-flex ai-center gap-16 c-pointer">
              <Icon.LocalShippingSVG
                className={productState.selected?.courierDelivery ? "icon-dark" : "icon-color"}
                style={{ minHeight: "32px", minWidth: "32px" }}
              />
              <div className="d-flex fd-c gap-2">
                <Label
                  pointer
                  fontStyle="bold"
                  fontColor={productState.selected?.courierDelivery ? "black" : "primary"}
                  content="Dostawa kurierska"
                />
                <Label
                  pointer
                  fontSize="sml"
                  fontStyle="semibold"
                  fontColor={productState.selected?.courierDelivery ? "black" : "primary"}
                  wrap
                  content="Dostawa kurierska to wygodna opcja dla klientów, którzy chcą otrzymać swoje zamówienie bezpośrednio pod wskazany adres."
                />
              </div>
            </div>
          </InfoBox>
   */

/*
          
        <InfoBox
          className="hover-box"
          style={{ width: "calc(100% - 16px)" }}
          sizeFrame="sm"
          backgroundColor={productState.selected?.courierDelivery ? "green" : "secondary"}
          onClick={() => {
            if (action === "preview") return;
            dispatch(
              updateProduct({
                product: {
                  courierDelivery: !productState.selected?.courierDelivery,
                },
              })
            );
          }}
        >
          <div className="d-flex ai-center gap-16 c-pointer">
            <Icon.LocalShippingSVG
              className={productState.selected?.courierDelivery ? "icon-dark" : "icon-color"}
              style={{ minHeight: "32px", minWidth: "32px" }}
            />
            <div className="d-flex fd-c gap-2">
              <Label
                pointer
                fontStyle="bold"
                fontColor={productState.selected?.courierDelivery ? "black" : "primary"}
                content="Dostawa kurierska"
              />
              <Label
                pointer
                fontSize="sml"
                fontStyle="semibold"
                fontColor={productState.selected?.courierDelivery ? "black" : "primary"}
                wrap
                content="Dostawa kurierska to wygodna opcja dla klientów, którzy chcą otrzymać swoje zamówienie bezpośrednio pod wskazany adres."
              />
            </div>
          </div>
        </InfoBox>
          */
