import React from "react";
import * as Icon from "commons/iconManager";
import PostComponent from "src/pages/wall/components/PostComponent";
import EmptyComponent from "components/EmptyComponent";
import { useSelector } from "react-redux";
import { SearchState } from "models/new/State";

const SearchMobilePostComponent: React.FC = () => {
  const searchState: SearchState = useSelector((state: any) => state.search);

  return (
    <div className="f-scroll gap-8 pad-l-4 pad-r-4">
      {searchState.supplierInfo?.posts.length === 0 ? (
        <EmptyComponent
          style={{ height: "50%", width: "100%" }}
          icon={<Icon.DescriptionSVG />}
          message="Brak postów od tego sprzedawcy"
        />
      ) : (
        <>
          {searchState.supplierInfo?.posts.map((post) => {
            return <PostComponent post={post} />;
          })}
        </>
      )}
    </div>
  );
};

export default SearchMobilePostComponent;
