import React from "react";
import * as Icon from "commons/iconManager";
import { SaleCoupon } from "models/new/SaleCoupon";
import { Button } from "controls/Button";
import { InputNumber } from "controls/InputNumber";
import { InputText } from "controls/InputText";
import { InputDate } from "controls/InputDate";
import { ModalDialogProps } from "models/new/ModalDialogProps";
import { generateGUID } from "commons/stringManager";

interface SaleCouponManagerProps extends ModalDialogProps {
  productId?: string;
  supplierId: string;
  coupon?: SaleCoupon;
}

const initialError = {
  errorCode: "",
  errorPercent: "",
};

const SaleCouponManagerComponent: React.FC<SaleCouponManagerProps> = (props) => {
  const [coupon, setCoupon] = React.useState<SaleCoupon>(
    props.coupon ?? {
      dateFrom: new Date(),
      id: generateGUID(),
      percent: 0,
      code: "",
      productId: props.productId,
      supplierId: props.supplierId,
    }
  );
  const [error, setError] = React.useState<{ errorCode: string; errorPercent: string }>(initialError);

  const onApproved = () => {
    if (!isValid()) return;

    props.onChangeValue?.(coupon);
    props.onCloseModalDialog?.("Approve");
  };

  const isValid = (): boolean => {
    let result: boolean = true;
    let newError = initialError;

    if (coupon.code.length === 0) {
      newError = { ...newError, errorCode: "Brak kodu promocyjnego" };
      result = false;
    }
    if (!coupon.percent || coupon.percent === 0) {
      newError = { ...newError, errorPercent: "Rabat musi być wiekszy od 0" };
      result = false;
    }

    if (!result) {
      setError({ ...error, ...newError });
    }

    return result;
  };

  function onChangeDateRange(range: { from: Date; to?: Date }): void {
    setCoupon({ ...coupon, dateFrom: range.from, dateTo: range.to });
  }

  return (
    <div className="d-flex fd-c ai-s-flex gap-16 pad-b-16 width-fill">
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Kod promocyjny"
        placeholder="np. PROMO10"
        error={error.errorCode}
        value={coupon.code.toUpperCase()}
        disabled={props.coupon?.id !== undefined}
        onChange={(e) => setCoupon({ ...coupon, code: e.target.value.toUpperCase() })}
      />
      <InputNumber
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Rabat procentowy"
        placeholder="np. 10"
        unit="%"
        min={0}
        max={99}
        fixed={0}
        error={error.errorPercent}
        value={coupon.percent?.toNumberFixed(0)}
        onSubmit={(e) =>
          setCoupon({ ...coupon, percent: isNaN(e.currentTarget.valueAsNumber) ? 0 : e.currentTarget.valueAsNumber })
        }
      />
      <InputDate
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Zakres ważności"
        rangeValue={{ from: new Date(coupon.dateFrom), to: new Date(coupon.dateTo ?? "") }}
        onChangeRange={(e) => onChangeDateRange(e)}
      />
      <div className="frame-footer width-fill">
        <Button
          label="Zatwierdź"
          kind="primary"
          sizeFrame="md"
          fontStyle="bold"
          icon={<Icon.DoneSVG />}
          onClick={onApproved}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default SaleCouponManagerComponent;
