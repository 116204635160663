import React from "react";
import * as Icons from "commons/iconManager";
import { useDispatch, useSelector } from "react-redux";
import { MainState } from "models/new/State";
import { changeMainRoute } from "reducers/mainReducer";
import RoutePage from "models/new/RoutePage";
import { useNavigation } from "hooks/useNavigation";
import { Label } from "controls/Label";

interface PathProps {
  customName?: string;
  onRefreshClick?: () => void;
  onChange?: (path: string) => Promise<boolean>;
  canChangePage?: () => boolean;
}

const PathComponent: React.FC<PathProps> = (props) => {
  const { navigateTo, routeStack } = useNavigation();
  const [pageStack, setPageStack] = React.useState<RoutePage[]>([]);
  const dispatch = useDispatch();
  const mainState: MainState = useSelector((state: any) => state.main);

  React.useEffect(() => {
    createPath();
  }, []);

  React.useEffect(() => {
    createPath();
  }, [mainState.typeRoutePage]);

  const createPath = () => {
    const pageStack: RoutePage[] = [];
    let routePage: RoutePage;
    const find = routeStack.find((x) => mainState.typeRoutePage === x.id);
    if (find) {
      routePage = find;
      pageStack.push(routePage);
      while (routePage.parent) {
        let parent: RoutePage | undefined = routeStack.find((x) => x.id === routePage.parent);
        if (parent) {
          routePage = parent;
          pageStack.unshift(routePage);
        } else {
          break;
        }
      }
    }

    if (pageStack.length > 0) {
      dispatch(changeMainRoute(pageStack[0].id));
    }

    setPageStack(pageStack);
  };

  return (
    <div className="d-flex ai-center gap-8">
      {pageStack.length > 1 && (
        <Icons.ArrowBackSVG
          className="i-button i-button-small"
          onClick={() => {
            navigateTo(pageStack[pageStack.length - 2].id);
          }}
        />
      )}
      <div className="gap-8">
        {pageStack.map((page, idx) => {
          if (pageStack.length - 1 !== idx) {
            return (
              <Label
                key={page.name}
                fontColor="secondary"
                pointer
                islink
                content={`${page.name} / `}
                fontSize="lg"
                fontStyle="semibold"
                onClick={() => navigateTo(page.id)}
              />
            );
          } else {
            return <Label key={page.name} content={`${props.customName ?? page.name}`} fontSize="lg" fontStyle="semibold" />;
          }
        })}
      </div>
      {props.onRefreshClick && (
        <Icons.RefreshSVG className="i-button i-button-small" onClick={() => props.onRefreshClick?.()} />
      )}
    </div>
  );
};

export default PathComponent;
