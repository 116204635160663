import { OrderProduct } from "models/new/OrderProduct";
import { Product } from "models/new/Product";
import { Label } from "controls/Label";
import { Tag } from "controls/Tag";
import { calculateValueAfterPercent, calculateValueByPercent } from "./numberManager";
import { ProductInfo } from "models/new/ProductInfo";
import { generateGUID } from "./stringManager";

const calculateSavings = (product: Product | OrderProduct): number => {
  let price = product.salePrice ?? product.price;
  price = price * product.quantity;
  if (product?.saleCouponCode) {
    price = calculateValueByPercent(price, product.saleCouponPercent, 2);
    return price;
  }
  return 0;
};
const calculateSummary = (product: Product | OrderProduct): number => {
  let price = product.salePrice ?? product.price;
  price = price * product.quantity;

  if (product.saleCouponCode) {
    price = calculateValueAfterPercent(price, product.saleCouponPercent, 2);
  }

  return price;
};
const calculateSummaryPrice = (product: Product | OrderProduct): number => {
  let price = product.price;
  price = price * product.quantity;

  if (product.saleCouponCode) {
    price = calculateValueAfterPercent(price, product.saleCouponPercent, 2);
  }

  return price;
};
export const showValueOrderProduct = (product: Product | OrderProduct) => {
  let price = calculateSummary(product);
  return <Label fontSize="sm" fontStyle="bold" content={`Wartość: ${price.toLocaleFixed(2)} zł`} />;
};
export const showCouponOrderProduct = (product: Product | OrderProduct) => {
  if (product.saleCouponCode) {
    return (
      <Tag
        sizeFrame="xsm"
        fontStyle="bold"
        kind="green"
        label={`${product.saleCouponPercent}% - ${product.saleCouponCode}`}
      />
    );
  }
  return <Label fontSize="md" fontStyle="semibold" content={""} />;
};
export const showSavedOrderProduct = (product: Product | OrderProduct) => {
  const saving = calculateSavings(product);
  if (saving > 0) {
    return <Label fontSize="sm" fontStyle="semibold" content={`Rabat: -${saving.toLocaleFixed(2)} zł`} />;
  } else {
    return <Label fontSize="sm" fontStyle="semibold" content={""} />;
  }
};
export const summarySavingsOrderProduct = (products: Product[] | OrderProduct[]): number => {
  if(!products) return 0;
  
  let saving: number = 0;
  products?.forEach((product) => {
    if (product.salePrice) {
      const diff = product.price - product.salePrice;
      saving = saving + diff * product.quantity;
    }
  });

  return saving.toNumberFixed(2);
};
export const summaryOrderProduct = (products: Product[] | OrderProduct[]): number => {
  if(!products) return 0;

  let summary: number = 0;
  products?.forEach((product) => {
    summary += calculateSummary(product);
  });
  return summary.toNumberFixed(2);
};

export const summaryOrderProductPrice = (products: Product[] | OrderProduct[]): number => {
  if(!products) return 0;

  let summary: number = 0;
  products?.forEach((product) => {
    summary += calculateSummaryPrice(product);
  });
  return summary.toNumberFixed(2);
};

export const productList: string[] = [
  // Fruits
  "Pomarańcze", // Oranges
  "Cytryny", // Lemons
  "Limetki", // Limes
  "Grejpfruty", // Grapefruits
  "Mandarynki", // Mandarins
  "Klementynki", // Clementines
  "Pomelo", // Pomelo
  "Jeżyny", // Blackberries
  "Borówki amerykańskie", // Blueberries
  "Jagody leśne", // Wild berries
  "Nektarynki", // Nectarines
  "Wiśnie", // Cherries
  "Ananasy", // Pineapples
  "Mango", // Mangoes
  "Papaja", // Papayas
  "Marakuja", // Passion fruits
  "Gujawa", // Guavas
  "Kiwi", // Kiwi
  "Granat", // Pomegranates
  "Kaki", // Persimmons
  "Lichi", // Lychee
  "Mangostan", // Mangosteen
  "Karambola", // Star fruit
  "Figi", // Fresh figs
  "Pitaja", // Dragon fruit

  // Vegetables
  "Rzepa", // Turnips
  "Pasternak", // Parsnips
  "Cykoria", // Chicory
  "Topinambur", // Jerusalem artichoke
  "Sałata lodowa", // Iceberg lettuce
  "Sałata rzymska", // Romaine lettuce
  "Kapusta pekińska", // Chinese cabbage
  "Kapusta włoska", // Savoy cabbage
  "Boćwina", // Swiss chard
  "Endywia", // Endive
  "Dynia hokkaido", // Hokkaido pumpkin
  "Dynia piżmowa", // Butternut squash
  "Cukinia zielona", // Green zucchini
  "Cukinia żółta", // Yellow zucchini
  "Kabaczek", // Gourd
  "Patison", // Pattypan squash
  "Fasolka szparagowa zielona", // Green beans
  "Fasolka szparagowa żółta", // Yellow beans
  "Groch cukrowy", // Sugar peas
  "Bób", // Broad beans
  "Ciecierzyca", // Chickpeas
  "Soja", // Soybeans
  "Pomidor bawole serce", // Beefsteak tomato
  "Papryka chili", // Chili peppers
  "Cebula czerwona", // Red onion
  "Szalotka", // Shallots
  "Dymka", // Spring onions
  "Kalarepa", // Kohlrabi
  "Brukselka", // Brussels sprouts
  "Ogórek gruntowy", // Pickling cucumber
  "Ogórek szklarniowy", // Greenhouse cucumber

  // Herbs and Spices
  "Natka pietruszki", // Parsley
  "Koperek", // Dill
  "Kolendra", // Coriander
  "Bazylia", // Basil
  "Oregano", // Oregano
  "Mięta", // Mint
  "Tymianek", // Thyme
  "Rozmaryn", // Rosemary
  "Lubczyk", // Lovage
  "Estragon", // Tarragon
  "Szałwia", // Sage
  "Imbir świeży", // Fresh ginger
  "Kurkuma świeża", // Fresh turmeric
  "Trawa cytrynowa", // Lemongrass

  // Dried Fruits and Vegetables
  "Suszone morele", // Dried apricots
  "Suszone figi", // Dried figs
  "Daktyle", // Dates
  "Mango suszone", // Dried mango
  "Banany suszone", // Dried bananas
  "Suszona cebula", // Dried onion
  "Suszona papryka", // Dried peppers
  "Suszony czosnek", // Dried garlic
  "Suszona marchew", // Dried carrots

  // Other
  "Sok buraczany", // Beet juice
  "Sok z selera", // Celery juice
  "Konfitura z pomarańczy", // Orange marmalade
  "Rzodkiew kiszona", // Pickled radish
  "Buraki kiszone", // Pickled beets
  "Pasta z papryki", // Pepper paste
  "Hummus", // Hummus
  "Orzechy brazylijskie", // Brazil nuts
  "Chia", // Chia seeds
  "Len", // Flaxseeds
  "Quinoa", // Quinoa
  "Pieczarki brunatne", // Brown mushrooms
  "Kurki", // Chanterelle mushrooms
  "Maślaki", // Slippery jack mushrooms
  "Borowiki", // Porcini mushrooms
  "Suszone kurki", // Dried chanterelle mushrooms
  "Suszone podgrzybki", // Dried bay boletes
  "Ziemniaki czerwone", // Red potatoes
  "Ziemniaki fioletowe", // Purple potatoes
  "Bataty fioletowe", // Purple sweet potatoes
  "Bataty białe", // White sweet potatoes
];

export function convertProductToOrderProduct(prod: ProductInfo): OrderProduct {
  const product = structuredClone(prod);
  return {
    id: generateGUID(),
    productId: product.id,
    name: product.name,
    price: product.price,
    salePrice: product.salePrice ?? null,
    quantity: product.quantity,
    unit: product.unit,
    photoUrl: product.photoUrl ?? '',
    saleCouponCode: product.saleCouponCode,
    saleCouponPercent: product.saleCouponPercent,
  };
}
export const commonSearchProduct: string[] = [
  productList[2],
  productList[5],
  productList[7],
  productList[8],
  productList[11],
];
