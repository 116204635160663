import { createReducer, createAction } from "@reduxjs/toolkit";
import { MainState } from "../models/new/State";
import { TypeRoutePage } from "../models/new/Dictionaries";

const initialState = {
  menuOpened: false,
  isMapLoaded: false,
  showShoppingBasket: false,
  showNotification: false,
  showMail: false,
  showPost: false,
  showMap: false,
  showDealerOnMap: false,
  showBlog: false,
  showFilter: false,
  showFilterOrder: false,
  showFilterSale: false,
  showLocation: false,
  showSearch: false,
  showOrderRating: false,
  showSubscriptionWizzard: false,
  theme: "light",
} as MainState;

export const changeMainState = createAction<Partial<MainState>>("main/changeMainState");
export const changeOpenMenu = createAction("main/changeOpenMenu");
export const changeTheme = createAction<string>("main/changeTheme");
export const changeMainRoute = createAction<TypeRoutePage | undefined>("main/changeMainRoute");
export const changeTypeRoutePage = createAction<TypeRoutePage | undefined>("main/changeTypeRoutePage");
export const setMapLoaded = createAction<boolean>("main/setMapLoaded");
export const setShoppingBasket = createAction<boolean>("main/setShoppingBasket");
export const setShowNotification = createAction<boolean>("main/setShowNotification");
export const setShowMail = createAction<boolean>("main/setShowMail");
export const setShowPost = createAction<boolean>("main/setShowPost");

export const setMapBounds = createAction<{ latLo: number; latHi: number; lngLo: number; lngHi: number; zoom: number }>(
  "main/setMapBounds"
);

export const mainReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeMainState, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(changeOpenMenu, (state, action) => {
      state.menuOpened = !state.menuOpened;
    })
    .addCase(changeTheme, (state, action) => {
      state.theme = action.payload;
    })
    .addCase(changeMainRoute, (state, action) => {
      state.mainRoute = action.payload;
    })
    .addCase(changeTypeRoutePage, (state, action) => {
      state.typeRoutePage = action.payload;
    })
    .addCase(setMapBounds, (state, action) => {
      state.mapBounds = action.payload;
    })
    .addCase(setMapLoaded, (state, action) => {
      state.isMapLoaded = action.payload;
    })
    .addCase(setShoppingBasket, (state, action) => {
      state.showShoppingBasket = action.payload;
    })
    .addCase(setShowNotification, (state, action) => {
      state.showNotification = action.payload;
    })
    .addCase(setShowMail, (state, action) => {
      state.showMail = action.payload;
    })
    .addCase(setShowPost, (state, action) => {
      state.showPost = action.payload;
    });
});
