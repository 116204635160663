import * as api from "./api";

export function update(id: string, isRead?: boolean, isShowed?: boolean): Promise<string> {
  return api.put(`/notifications`, { id: id, isRead: isRead, isShowed: isShowed });
}

export function deleteNotification(id: string): Promise<string> {
  return api.del(`/notifications/${id}`);
}

export function deleteAll(appUserId: string): Promise<string> {
  return api.del(`/notifications/${appUserId}/all`);
}
