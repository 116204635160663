import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { useNavigation } from "hooks/useNavigation";
import { Button } from "controls/Button";
import { useDispatch, useSelector } from "react-redux";
import { setShoppingBasket } from "reducers/mainReducer";
import { useLogin } from "hooks/useLogin";
import { ConversationState, UserState } from "models/new/State";
import { AppUser } from "models/new/AppUser";
import { addConversation } from "reducers/conversationReducer";
import { generateGUID } from "commons/stringManager";
import { Rating } from "models/new/Rating";
import StarSelectionComponent from "./StarSelectionComponent";
import PictureBoxComponent from "./PictureBoxComponent";

const ClientCardComponent: React.FC<{ client?: AppUser; rating?: Rating }> = (props) => {
  const { navigateTo } = useNavigation();
  const { signIn } = useLogin();
  const userState: UserState = useSelector((state: any) => state.user);
  const conversationState: ConversationState = useSelector((state: any) => state.conversation);
  const dispatch = useDispatch();

  const onClickEmail = async () => {
    if (!userState.token) await signIn();
    else {
      const conversation = conversationState.conversations.find((x) =>
        x.chatMembers.some((z) => z.appUserId === props.client?.id)
      );
      if (conversation) {
        dispatch(addConversation({ chat: conversation, isOpen: true }));
      } else {
        dispatch(
          addConversation({
            chat: {
              id: generateGUID(),
              chatMembers: [
                { appUserId: props.client!.id, joinedAt: new Date(), appUser: props.client! },
                { appUserId: userState.user!.id, joinedAt: new Date(), appUser: userState.user! },
              ],
              chatMessages: [],
              isGroupChat: false,
              isOpen: true,
              createdAt: new Date(),
            },
            isOpen: true,
          })
        );
      }
      dispatch(setShoppingBasket(false));
    }
  };

  const onClickPhone = async () => {
    if (!userState.token) await signIn();
    else {
    }
  };

  return (
    <div className="d-flex fd-c gap-8">
      <div className="d-flex gap-16">
        <PictureBoxComponent
          className="picture-50 c-pointer"
          url={props.client?.photoUrl}
          onClick={() => navigateTo("search", `?client_id=${props.client?.id}`)}
        />
        <div className="d-flex fd-c gap-4">
          <Label
            className="pad-l-4"
            fontSize="lg"
            fontStyle="semibold"
            content={`${props.client?.firstName} ${props.client?.lastName}`}
          />
          <div className="d-flex fd-r gap-4">
            <Button
              sizeFrame="sml"
              icon={<Icon.MailSVG />}
              toolTip={"Rozpocznij czat"}
              toolTipPosition="top"
              onClick={onClickEmail}
            />
            <Button
              sizeFrame="sml"
              icon={<Icon.CallSVG />}
              toolTip={"Pokaż numer telefonu"}
              toolTipPosition="top"
              onClick={onClickPhone}
            />
          </div>
        </div>
      </div>
      {props.rating && (
        <div className="d-flex fd-c gap-4">
          <Label
            className="pad-l-4"
            fontSize="sm"
            fontColor="secondary"
            fontStyle="semibold"
            content="Ocena i komentarz"
          />
          <StarSelectionComponent rating={props.rating.rate} hover={false} />
          {props.rating.comment.length > 0 && (
            <Label
              className="border rounded-12 pad-8 width-fill"
              fontSize="sm"
              fontStyle="semibold"
              wrap
              content={props.rating.comment}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ClientCardComponent;
