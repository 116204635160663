import React from "react";
import * as Icon from "commons/iconManager";
import { useSelector } from "react-redux";
import MessagerItemComponent from "./MessagerItemComponent";
import EmptyComponent from "../EmptyComponent";
import { ConversationState } from "models/new/State";
import { useWindowSize } from "hooks/useWindowSize";
import MessageMobileContentComponent from "./mobile/MessageMobileContentComponent";
import { Chat } from "models/new/Chat";

const MessagerManagerComponent: React.FC = () => {
  const conversationState: ConversationState = useSelector((state: any) => state.conversation);
  const { isMobile } = useWindowSize();

  const sorted = (): Chat[] => {
    return conversationState.conversations
      .filter((x) => x.chatMessages.length > 0)
      .map((c) => ({
        ...c,
        latestMessageDate: Math.max(...c.chatMessages!.map((m) => new Date(m.sentAt).getTime())),
      }))
      .sort((a, b) => b.latestMessageDate - a.latestMessageDate);
      return [];
  };

  return (
    <div className="frame-main">
      {isMobile && conversationState.activeConversations.length > 0 ? (
        <MessageMobileContentComponent conversation={conversationState.activeConversations[0]} />
      ) : (
        <>
          {conversationState.conversations.filter((x) => x.chatMessages.length > 0)?.length === 0 ? (
            <EmptyComponent icon={<Icon.MailSVG />} message="Brak wiadomości" />
          ) : (
            <div className="frame-scroll gap-4 pad-4 mag-4">
              {sorted().map((c) => {
                return <MessagerItemComponent key={c.id} conversation={c} />;
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MessagerManagerComponent;
