import React from "react";
import { Label } from "controls/Label";
import * as Icon from "commons/iconManager";
import { Tag } from "controls/Tag";
import InfoBox from "controls/InfoBox";
import PriceComponent from "./PriceComponent";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { useDispatch } from "react-redux";
import { removeProductFromBasket, updateProductInBasket } from "reducers/basketReducer";
import { InputNumber } from "controls/InputNumber";
import { Product } from "models/new/Product";
import { Supplier } from "models/new/Supplier";
import DealerCardComponent from "./DealerCardComponent";
import { OrderProduct } from "models/new/OrderProduct";
import {
  showCouponOrderProduct,
  showSavedOrderProduct,
  showValueOrderProduct,
  summaryOrderProduct,
  summaryOrderProductPrice,
  summarySavingsOrderProduct,
} from "commons/productManager";
import { useWindowSize } from "hooks/useWindowSize";
import { Rating } from "models/new/Rating";
import StarSelectionComponent from "./StarSelectionComponent";

interface ProductSummaryProps {
  products: Product[] | OrderProduct[];
  ratings?: Rating[];
  dealer?: Supplier;
  deliveryCost?: number;
  isEdit?: boolean;
}

const ProductSummaryOrderComponent: React.FC<ProductSummaryProps> = (props) => {
  const dispatch = useDispatch();
  const { navigateTo } = useNavigation();
  const { isMobile } = useWindowSize();

  const onChangeQty = (product: OrderProduct, qty: number) => {
    dispatch(updateProductInBasket({ ...product, quantity: qty }));
  };
  const getProduct = (product: OrderProduct): JSX.Element => {
    const rating = props.ratings?.find((x) => x.productId === product.productId);
    return (
      <div className="width-fill d-flex gap-8 bg-secondary rounded-12 pad-8">
        <div className="width-fill d-flex gap-16 bg-secondary rounded-12 pad-8">
          <img className="image-on-search-list" src={product.photoUrl} />
          <div className="d-flex fd-c jc-sb gap-8">
            <Label
              fontSize="sm"
              fontStyle="semibold"
              islink
              content={product.name}
              onClick={() => navigateTo("search", `?product_id=${product.id}`)}
            />

            {props.isEdit ? (
              <div className="d-flex gap-4">
                <Button
                  sizeFrame="sml"
                  icon={<Icon.RemoveSVG />}
                  disabled={product.quantity === 0}
                  onClick={(e) => onChangeQty(product, (product.quantity ?? 0) - 1)}
                />
                <InputNumber
                  style={{ width: "50px" }}
                  sizeFrame="sml"
                  kind="secondary"
                  canClear={false}
                  min={0}
                  value={product.quantity}
                  hideButton={true}
                  onChange={(e) => onChangeQty(product, e.target.valueAsNumber)}
                />
                <Button
                  sizeFrame="sml"
                  icon={<Icon.AddSVG />}
                  onClick={(e) => onChangeQty(product, (product.quantity ?? 0) + 1)}
                />
              </div>
            ) : (
              <Label fontSize="md" fontStyle="bold" content={`${product.quantity} ${product.unit}`} />
            )}

            <PriceComponent product={product} />
          </div>
          <div className="d-flex fd-c ai-e-flex jc-sb gap-4" style={{ marginLeft: "auto" }}>
            {showCouponOrderProduct(product)}
            {showSavedOrderProduct(product)}
            {showValueOrderProduct(product)}
          </div>
          {props.isEdit && (
            <Button
              sizeFrame="sml"
              kind="text"
              icon={<Icon.DeleteSVG />}
              toolTip={"Usuń z koszyka"}
              onClick={() => dispatch(removeProductFromBasket(product))}
            />
          )}
        </div>
        {rating && (
          <div className="d-flex fd-c gap-4">
            <Label
              className="pad-l-4"
              fontSize="sm"
              fontColor="secondary"
              fontStyle="semibold"
              content="Ocena i komentarz"
            />
            <StarSelectionComponent rating={rating.rate} hover={false}/>
            {rating.comment.length > 0 && (
              <Label
                className="border rounded-12 pad-8 width-fill"
                fontSize="sm"
                fontStyle="semibold"
                wrap
                content={rating.comment}
              />
            )}
          </div>
        )}
      </div>
    );
  };
  const getProductOrder = (product: OrderProduct): JSX.Element => {
    const rating = props.ratings?.find((x) => x.productId === product.productId);
    return (
      <div className="width-fill fd-c d-flex gap-8 border-bottom pad-8">
        <div className="width-fill d-flex gap-16 pad-8">
          <img className="image-on-search-list" src={product.photoUrl} />
          <div className="d-flex fd-c jc-sb gap-8">
            <Label
              fontSize="sm"
              fontStyle="semibold"
              islink
              content={product.name}
              onClick={() => navigateTo("search", `?product_id=${product.productId}`)}
            />

            <Label fontSize="md" fontStyle="bold" content={`${product.quantity} ${product.unit}`} />
            <PriceComponent product={product} />
          </div>
          <div className="d-flex fd-c ai-e-flex jc-sb gap-4" style={{ marginLeft: "auto" }}>
            {showCouponOrderProduct(product)}
            {showSavedOrderProduct(product)}
            {showValueOrderProduct(product)}
          </div>
        </div>
        {rating && (
          <div className="d-flex fd-c gap-4">
            <Label
              className="pad-l-4"
              fontSize="sm"
              fontColor="secondary"
              fontStyle="semibold"
              content="Ocena i komentarz"
            />
            <StarSelectionComponent rating={rating.rate} hover={false}/>
            {rating.comment.length > 0 && (
              <Label
                className="border rounded-12 pad-8 width-fill"
                fontSize="sm"
                fontStyle="semibold"
                wrap
                content={rating.comment}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  if (isMobile) {
    return (
      <InfoBox
        id={"products_summary"}
        className="width-fill"
        sizeFrame="sm"
        backgroundColor="secondary"
        style={{ position: "relative" }}
      >
        <div className="frame-main d-flex fd-c gap-8">
          {props.dealer && (
            <>
              <Label fontSize="lg" fontStyle="bold" content={"Sprzedawca"} />
              <DealerCardComponent supplier={props.dealer} />
            </>
          )}
          <Label fontSize="lg" fontStyle="bold" content={"Kupione produkty"} />
          {props.products?.map((prod) => {
            return "productId" in prod ? getProductOrder(prod) : getProduct(prod);
          })}
          <div className="frame-footer width-fill fd-c rounded-12 bg-secondary pad-16">
            <Label fontSize="lg" fontStyle="bold" content={"Podsumowanie"} />
            <div className=" width-fill d-flex jc-sb ai-center">
              <Label fontSize="sm" fontStyle="semibold" content={"Wartość zakupu"} />
              <Label
                fontSize="sm"
                fontStyle="bold"
                content={`${summaryOrderProductPrice(props.products).toLocaleFixed(2)} zł`}
              />
            </div>
            {summarySavingsOrderProduct(props.products) > 0 && (
            <div className=" width-fill d-flex jc-sb ai-center">
              <Label fontSize="sm" fontStyle="semibold" content={"Zaoszczędzone"} />
              <Label
                fontSize="sm"
                fontStyle="bold"
                content={`-${summarySavingsOrderProduct(props.products).toLocaleFixed(2)} zł`}
              />
            </div>
          )}
            <div className="width-fill d-flex pad-t-8 jc-sb ai-center">
              <Label fontSize="sm" fontStyle="semibold" content={"Podsumowanie"} />
              <Tag
                sizeFrame="xsm"
                fontStyle="bold"
                kind="green"
                label={`${(summaryOrderProduct(props.products) + (props.deliveryCost ?? 0)).toLocaleFixed(2)} zł`}
              />
            </div>
          </div>
        </div>
      </InfoBox>
    );
  }

  return (
    <InfoBox
      sizeFrame="sm"
      backgroundColor="transparent"
      style={{ width: "630px", position: "sticky", height: "fit-content", top: "0px" }}
    >
      <div className="frame-main d-flex fd-c gap-8">
        {props.dealer && (
          <>
            <Label fontSize="lg" fontStyle="bold" content={"Sprzedawca"} />
            <DealerCardComponent supplier={props.dealer} />
          </>
        )}
        <Label fontSize="lg" fontStyle="bold" content={"Kupione produkty"} />
        {props.products?.map((prod) => {
          return "productId" in prod ? getProductOrder(prod) : getProduct(prod);
        })}
        <div className="frame-footer width-fill fd-c rounded-12 bg-secondary pad-16">
          <Label fontSize="lg" fontStyle="bold" content={"Podsumowanie"} />
          <div className=" width-fill d-flex jc-sb ai-center">
            <Label fontSize="sm" fontStyle="semibold" content={"Wartość zakupu"} />
            <Label
              fontSize="sm"
              fontStyle="bold"
              content={`${summaryOrderProductPrice(props.products).toLocaleFixed(2)} zł`}
            />
          </div>
          {summarySavingsOrderProduct(props.products) > 0 && (
            <div className=" width-fill d-flex jc-sb ai-center">
              <Label fontSize="sm" fontStyle="semibold" content={"Zaoszczędzone"} />
              <Label
                fontSize="sm"
                fontStyle="bold"
                content={`-${summarySavingsOrderProduct(props.products).toLocaleFixed(2)} zł`}
              />
            </div>
          )}
          <div className="width-fill d-flex pad-t-8 jc-sb ai-center">
            <Label fontSize="sm" fontStyle="semibold" content={"Podsumowanie"} />
            <Tag
              sizeFrame="xsm"
              fontStyle="bold"
              kind="green"
              label={`${(summaryOrderProduct(props.products) + (props.deliveryCost ?? 0)).toLocaleFixed(2)} zł`}
            />
          </div>
        </div>
      </div>
    </InfoBox>
  );
};

export default ProductSummaryOrderComponent;

/*
 <div className="frame-scroll gap-8 pad-r-8">
            {props.products.map((prod) => {
              return 'productId' in prod ? getProductOrder(prod) : getProduct(prod);
            })}
          </div>
*/


/*

            <div className=" width-fill d-flex jc-sb ai-center">
              <Label fontSize="sm" fontStyle="semibold" content={"Koszt przesyłki"} />
              <Label fontSize="sm" fontStyle="bold" content={`${(props.deliveryCost ?? 0)?.toLocaleFixed(2)} zł`} />
            </div>
*/