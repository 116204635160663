import { Address, getNewAddress } from "../models/new/Address";
import { MyLock } from "./toolsManager";

let geocoderObj: google.maps.Geocoder;
export const searchByLoc = async (lat: number, lng: number): Promise<google.maps.GeocoderResult[]> => {
  return new Promise(async (resolve, reject) => {
    if (!geocoderObj) {
      geocoderObj = new google.maps.Geocoder();
    }

    var currentLatLng = new google.maps.LatLng(lat, lng);
    geocoderObj.geocode({ location: currentLatLng }, function (results, status) {
      if (results !== null && status == google.maps.GeocoderStatus.OK && results.length) {
        resolve(results);
      } else {
        resolve([]);
      }
    });
  });
};

export const findResult = (results: google.maps.GeocoderAddressComponent[], name: string): string => {
  let findedValue: google.maps.GeocoderAddressComponent | undefined;
  results.forEach((element) => {
    if (element.types.some((x) => x === name)) {
      findedValue = element;
    }
  });
  return findedValue ? findedValue.long_name : "";
};

export const getAddress = (results: google.maps.GeocoderResult[]): Address | undefined => {
  let findedValue: google.maps.GeocoderResult | undefined;

  findedValue = results[0];
  if (findedValue) {
    let initAddress: Address = {
      ...getNewAddress(),
      street: findResult(findedValue.address_components, "route"),
      buildingNumber:
        findResult(findedValue.address_components, "street_number") !== ""
          ? findResult(findedValue.address_components, "street_number")
          : findResult(findedValue.address_components, "premise"),
      localNumber: findResult(findedValue.address_components, ""),
      city: findResult(findedValue.address_components, "locality"),
      postalCode: findResult(findedValue.address_components, "postal_code"),
      country: findResult(findedValue.address_components, "country"),
      latitude: findedValue.geometry.location.lat(),
      longitude: findedValue.geometry.location.lng(),
    };
    return initAddress;
  }
  return undefined;
};

let autocompleteServiceObj: google.maps.places.AutocompleteService;
export const autoCompleteService = (value: string): Promise<google.maps.places.AutocompletePrediction[]> => {
  return new Promise(async (resolve, reject) => {
    if (!autocompleteServiceObj) {
      autocompleteServiceObj = new google.maps.places.AutocompleteService();
    }
    if (autocompleteServiceObj && value.length >= 3) {
      const request = {
        input: value,
        componentRestrictions: { country: "PL" },
      };

      autocompleteServiceObj.getPlacePredictions(request, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions !== null) {
          resolve(predictions);
        } else {
          resolve([]);
        }
      });
    } else {
      resolve([]);
    }
  });
};

let placesServiceObj: google.maps.places.PlacesService;
export const placesService = (placeId: string): Promise<{ lat: number; lng: number }> => {
  return new Promise(async (resolve, reject) => {
    if (!placesServiceObj) {
      placesServiceObj = new google.maps.places.PlacesService(document.createElement("div"));
    }
    if (placesServiceObj) {
      const request = {
        placeId,
        fields: ["name", "geometry"],
      };

      placesServiceObj.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (place?.geometry?.location) {
            resolve({ lat: place?.geometry?.location?.lat(), lng: place?.geometry?.location?.lng() });
          } else {
            resolve({ lat: 0, lng: 0 });
          }
        } else {
          resolve({ lat: 0, lng: 0 });
        }
      });
    }
  });
};

export const getStringAddress = (addressComponent: google.maps.GeocoderAddressComponent[]): string => {
  let result = "";
  var street = findResult(addressComponent, "route");
  if (street) {
    result += `${street} `;
  }
  var streetNumber = findResult(addressComponent, "street_number");
  if (streetNumber) {
    result += `${streetNumber}, `;
  }
  var city = findResult(addressComponent, "locality");
  if (city) {
    result += `${city}, `;
  }
  var country = findResult(addressComponent, "country");
  if (country) {
    result += `${country} `;
  }
  return result;
};

let lastSearch: {
  origin: { lat: number; lng: number };
  destination: { lat: number; lng: number };
  result: google.maps.DirectionsResult;
};

const lock = new MyLock();
let directionsServiceObj: google.maps.DirectionsService;
export const checkDistance = (
  origin: { lat: number; lng: number },
  destination: { lat: number; lng: number }
): Promise<google.maps.DirectionsResult> => {
  return new Promise(async (resolve, reject) => {
    await lock.acquire();

    if (!directionsServiceObj) {
      directionsServiceObj = new google.maps.DirectionsService();
    }

    if (
      JSON.stringify(lastSearch?.origin) === JSON.stringify(origin) &&
      JSON.stringify(lastSearch?.destination) === JSON.stringify(destination)
    ) {
      resolve(lastSearch.result);
    } else {
      const results = await directionsServiceObj.route({
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      });
      lastSearch = { origin, destination, result: results };
      resolve(results);
    }
    lock.release();
  });
};

export const getBoundsByLoc = (origin: {
  lat: number;
  lng: number;
}): { latLo: number; latHi: number; lngLo: number; lngHi: number; zoom: number } => {
  return {
    latLo: origin.lat - 0.25,
    latHi: origin.lat + 0.25,
    lngLo: origin.lng - 1,
    lngHi: origin.lat + 1,
    zoom: 12,
  };
};

const lat = 0.009;
const lng = 0.0145;
export const getBoundsForPoint = (
  origin: { lat: number; lng: number },
  distance: number
): { latLo: number; latHi: number; lngLo: number; lngHi: number } => {
  const latLo = origin.lat - distance * lat;
  const latHi = origin.lat + distance * lat;
  const lngLo = origin.lng - distance * lng;
  const lngHi = origin.lng + distance * lng;
  return { latLo, latHi, lngLo, lngHi };
};

export const navigate = (
  origin: { lat: number; lng: number; name?: string },
  destination: { lat: number; lng: number },
  device: "desktop" | "mobile" = "desktop"
) => {
  if (device === "desktop") {
    window.open(
      `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}`,
      "_blank"
    );
  } else {
    window.open(
      `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}`
    );
  }
};

export const darkModeForMaps = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [{ color: "#cecece" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#1D6587" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#0E3244" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#1D6587" }],
  },
  {
    featureType: "poi.business",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.government",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const lightModeForMaps = [
  {
    featureType: "poi.business",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.government",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const getGeoLocation = async (): Promise<{ lat: number; lng: number }> => {
  return new Promise(async (resolve, reject) => {
    function showPosition(position) {
      console.log("Latitude: " + position.coords.latitude + ", Longitude: " + position.coords.longitude);
      resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
    }
    function showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.");
          reject("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.");
          reject("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          console.log("The request to get user location timed out.");
          reject("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          console.log("An unknown error occurred.");
          reject("An unknown error occurred.");
          break;
      }
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
      reject("Geolocation is not supported by this browser.");
    }
  });
};
