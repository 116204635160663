import React from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import * as GoogleMaps from "commons/googleManager";
import { Label } from "controls/Label";
import { Address } from "models/new/Address";
import { ModalDialogProps } from "models/new/ModalDialogProps";
import SearchComponent from "src/pages/search/components/SearchBoxLocalizationComponent";
import { PopUp } from "controls/PopUp";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { Button } from "controls/Button";
import { useSelector } from "react-redux";
import { MainState, SearchState } from "models/new/State";
import { ModalDialog } from "controls/ModalDialog";
import MessageBox from "controls/MessageBox";
import { useWindowSize } from "hooks/useWindowSize";

interface ErrorAddress {
  buildingNumber?: string;
  postalCode?: string;
  city?: string;
}
interface ProfileSelectAddressProps extends ModalDialogProps {
  address?: Address;
}

const ProfileSelectAddressComponent: React.FC<ProfileSelectAddressProps> = (props) => {
  const [styleMaps, setStyleMaps] = React.useState<google.maps.MapTypeStyle[] | undefined>();
  const [address, setAddress] = React.useState<Address | undefined>(props.address);
  const [errorAddress, setErrorAddress] = React.useState<Partial<ErrorAddress>>();
  const [mapRef, setMapRef] = React.useState<google.maps.Map>();
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchGeolocation, setSearchGeolocation] = React.useState<
    { name: string; prediction: google.maps.places.AutocompletePrediction }[]
  >([]);
  const { isMobile } = useWindowSize();

  const mainState: MainState = useSelector((state: any) => state.main);
  const searchState: SearchState = useSelector((state: any) => state.search);

  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const divRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    mainState.theme === "dark" ? setStyleMaps(GoogleMaps.darkModeForMaps) : setStyleMaps(GoogleMaps.lightModeForMaps);
  }, [mainState.theme]);

  const onChangeInput = (value: string) => {
    if (value.length === 0) {
      setSearchGeolocation([]);
    }
  };

  const onChange = async (search: string) => {
    const pred: { name: string; prediction: google.maps.places.AutocompletePrediction }[] = [];

    if (search && search.length >= 3) {
      const result = await GoogleMaps.autoCompleteService(search);
      result?.forEach((place) => {
        pred.push({ name: place.description, prediction: place });
      });
    }

    setSearchGeolocation(pred);
    PopUp.onRefresh(
      <SearchComponent
        itemSource={pred}
        onChange={onChange}
        onDisplayItem={onDisplayItem}
        onSubmit={(item) => onSelectedChange(item)}
      />
    );
  };

  const showPopUp = async () => {
    await PopUp.show(
      divRef.current,
      <SearchComponent
        value={searchValue}
        itemSource={searchGeolocation}
        onChange={onChange}
        onDisplayItem={onDisplayItem}
        onSubmit={(item) => onSelectedChange(item)}
      />,
      "onto"
    );
  };

  const onDisplayItem = (item: any, selected?: any) => {
    return (
      <div
        className={`search-box ${item === selected && "search-box-selected"}`}
        onClick={() => onSelectedChange(item)}
      >
        <Icon.LocationOnSVG className="icon-search icon-color" />
        <Label fontSize="sm" fontStyle="semibold" pointer content={item.name} />
      </div>
    );
  };

  const onSelectedChange = async (item: any) => {
    PopUp.onHide();
    inputRef.current.value = item.name;
    const result = await GoogleMaps.placesService(item.prediction.place_id);
    const findAddress = await GoogleMaps.searchByLoc(result.lat, result.lng);
    let newAddress: Address | undefined = GoogleMaps.getAddress(findAddress);
    setAddress(newAddress);
    setTimeout(() => {
      setSearchValue(item.name);
    }, 200);
  };

  const end = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const findAddress = await GoogleMaps.searchByLoc(e.latLng.lat(), e.latLng.lng());
      let newAddress: Address | undefined = GoogleMaps.getAddress(findAddress);
      if (newAddress) {
        newAddress = { ...newAddress, latitude: e.latLng.lat(), longitude: e.latLng.lng() };
        setAddress(newAddress);
      }
    }
  };

  const onApproved = async () => {
    const adres = structuredClone(address);

    valide(address);

    if (!adres?.city) {
      return;
    }

    const result = await GoogleMaps.autoCompleteService(
      `${adres.street ?? ""} ${adres.buildingNumber} ${adres.localNumber} ${adres.postalCode} ${adres.city}`
    );

    const place = result[0];
    if (!place) {
      await ModalDialog.show(
        <MessageBox content="Zbyt mało danych nie znaleśliśmy poprawnego adresu." icon="Warning" />,
        "Ostrzeżenie",
        ["Ok"]
      );
      return;
    }

    const geoLocal = await GoogleMaps.placesService(place.place_id);
    adres.latitude = geoLocal.lat;
    adres.longitude = geoLocal.lng;

    props.onChangeValue?.(adres);
    props.onCloseModalDialog?.("Approve");
  };

  const valide = (adddres?: Address) => {
    const errorAdderss: ErrorAddress = {};
    if (!address || adddres?.postalCode?.length === 0) {
      errorAdderss.postalCode = "Wypełnij kod pocztowy";
    }

    if (!address || adddres?.buildingNumber?.length === 0) {
      errorAdderss.buildingNumber = "Wypełnij number budynku";
    }

    if (!address || adddres?.city?.length === 0) {
      errorAdderss.city = "Wypełnij miejscowość";
    }

    setErrorAddress(errorAdderss);
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c width-fill gap-16">
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Ulica"
          value={address?.street}
          onChange={(e) => setAddress({ ...address!, street: e.target.value })}
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Numer domu"
          isRequired
          value={address?.buildingNumber}
          error={errorAddress?.buildingNumber}
          onChange={(e) => {
            setAddress({ ...address!, buildingNumber: e.target.value });
            setErrorAddress({ buildingNumber: undefined });
          }}
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Numer lokalu"
          value={address?.localNumber}
          onChange={(e) => setAddress({ ...address!, localNumber: e.target.value })}
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Kod pocztowy"
          isRequired
          value={address?.postalCode}
          error={errorAddress?.postalCode}
          onChange={(e) => {
            setAddress({ ...address!, postalCode: e.target.value });
            setErrorAddress({ postalCode: undefined });
          }}
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Miejscowość"
          isRequired
          value={address?.city}
          error={errorAddress?.city}
          onChange={(e) => {
            setAddress({ ...address!, city: e.target.value });
            setErrorAddress({ city: undefined });
          }}
        />
        <Button
          label="Zatwierdź"
          kind="primary"
          sizeFrame="md"
          fontStyle="bold"
          icon={<Icon.DoneSVG />}
          onClick={onApproved}
          style={{ width: "100%" }}
        />
      </div>
    );
  }

  return (
    <div className="d-flex fd-c width-fill gap-16" style={{ width: "920px" }}>
      <InputText
        frameRef={divRef}
        ref={inputRef}
        style={{ width: "460px" }}
        sizeFrame="md"
        kind="tertiary"
        placeholder="np. Zamkowa 14/12 Warszawa"
        value={searchValue}
        icon={<Icon.MyLocationSVG />}
        onClick={showPopUp}
        onChange={(e) => onChangeInput(e.target.value)}
      />
      <div className="d-flex fd-r width-fill gap-16 ">
        <div className="d-flex fd-c gap-16 pad-r-16 border-right" style={{ minWidth: "320px", maxWidth: "320px" }}>
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Ulica"
            autoComplete="address-line1"
            value={address?.street}
            onChange={(e) => setAddress({ ...address!, street: e.target.value })}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer domu"
            autoComplete="address-line2"
            isRequired
            value={address?.buildingNumber}
            onChange={(e) => setAddress({ ...address!, buildingNumber: e.target.value })}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Numer lokalu"
            value={address?.localNumber}
            onChange={(e) => setAddress({ ...address!, localNumber: e.target.value })}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Kod pocztowy"
            autoComplete="postal-code"
            isRequired
            value={address?.postalCode}
            onChange={(e) => setAddress({ ...address!, postalCode: e.target.value })}
          />
          <InputText
            frameClassName="width-fill"
            sizeFrame="sm"
            kind="tertiary"
            label="Miejscowość"
            autoComplete="address-level2"
            isRequired
            value={address?.city}
            onChange={(e) => setAddress({ ...address!, city: e.target.value })}
          />
        </div>
        <div className="d-flex fd-c width-fill">
          <GoogleMap
            zoom={12}
            center={{
              lat: address?.latitude ?? searchState.geoLocation.lat ?? 51.73848,
              lng: address?.longitude ?? searchState.geoLocation.lng ?? 19.46728,
            }}
            mapContainerClassName="map-container"
            options={{
              streetViewControl: false,
              styles: styleMaps,
              zoomControl: false,
              gestureHandling: "greedy",
            }}
            onLoad={(map) => {
              setMapRef(map);
            }}
            onClick={(e) => end(e)}
          >
            {
              <MarkerF
                draggable
                onDragEnd={(e) => end(e)}
                position={{ lat: address?.latitude ?? 51.73848, lng: address?.longitude ?? 19.46728 }}
              />
            }
          </GoogleMap>
        </div>
      </div>
      <div className="frame-footer">
        <Button
          label="Zatwierdź"
          kind="primary"
          sizeFrame="md"
          fontStyle="bold"
          icon={<Icon.DoneSVG />}
          onClick={onApproved}
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default ProfileSelectAddressComponent;
