import { OrderProduct } from "models/new/OrderProduct";
import { Product } from "../models/new/Product";

export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomNumber = (min: number, max: number, fixed: number = 2): number => {
  const randomNumber = Math.random() * (max - min) + min;
  return Number(randomNumber.toFixed(fixed));
};

export const getRandomBoolean = (): boolean => {
  return getRandomInt(0, 1) === 1;
};

export const calculatePercentFromOneHundred = (val1?: number, val2?: number, fixed: number = 0): number | undefined => {
  if (!val1 || !val2) {
    return undefined;
  }

  if (val2 === 0) {
    return 0;
  }

  const percent = ((val2 * 100) / val1).toNumberFixed(fixed);
  return 100 - percent;
};

export const calculatePercent = (val1?: number, val2?: number, fixed: number = 0): number => {
  if (!val1 || !val2) {
    return 0;
  }

  if (val2 === 0) {
    return 0;
  }

  const percent = ((val2 * 100) / val1).toNumberFixed(fixed);
  return percent;
};

export const calculateValueByPercent = (val1?: number, percent?: number, fixed: number = 0): number => {
  if (!val1) {
    return 0;
  }

  if (!percent || percent === 0) {
    return 0;
  }

  const value = ((val1 * percent) / 100).toNumberFixed(fixed);
  return value;
};

export const calculateValueAfterPercent = (val1?: number, percent?: number, fixed: number = 0): number => {
  if (!val1) {
    return 0;
  }

  if (!percent || percent === 0) {
    return val1;
  }

  const value = (val1 - (val1 * percent) / 100).toNumberFixed(fixed);
  return value;
};

export const calculateAVG = (values?: number[], fixed: number = 1): number => {
  if (!values || values.length === 0) {
    return 0;
  }
  var sum = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (sum / values.length).toNumberFixed(fixed);
};

export const calculatePriceValue = (product: Product | OrderProduct): number => {
  const price = product.salePrice ?? product.price ?? 0;
  return (price * product.quantity).toNumberFixed(2);
};

export const calculateSum = (products: Product[] | OrderProduct[]): number => {
  let result: number = 0;
  products.forEach((prod) => {
    const price = prod.salePrice ?? prod.price ?? 0;
    result += price * (prod.quantity ?? 0);
  });
  return result.toNumberFixed(2);
};
