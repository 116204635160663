import React from "react";
import * as SupplierApi from "api/SupplierApi";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { useNavigation } from "hooks/useNavigation";
import { useURLParams } from "hooks/useURLParams";
import { useDispatch, useSelector } from "react-redux";
import { MainState, SearchState } from "models/new/State";
import InfoBox from "controls/InfoBox";
import { Button } from "controls/Button";
import DealerCardComponent from "components/DealerCardComponent";
import { clearSearched, setSupplierInfo } from "reducers/searchReducer";
import SearchSupplierInfoCertificationComponent from "./SearchSupplierInfoCertificationComponent";
import SearchLocationComponent from "./SearchLocationComponent";
import SearchSupplierInfoFarmPictureComponent from "./SearchSupplierInfoFarmPictureComponent";
import { Supplier } from "models/new/Supplier";
import SearchSupplierInfoRatingsComponent from "./SearchSupplierInfoRatingsComponent";
import SearchSupplierInfoProductsComponent from "./SearchSupplierInfoProductsComponent";
import { changeMainState } from "reducers/mainReducer";
import { useWindowSize } from "hooks/useWindowSize";

const SearchSupplierInfoComponent: React.FC = () => {
  const { navigateTo, navigate } = useNavigation();
  const { isMobile } = useWindowSize();
  const { params } = useURLParams();
  const dealerId = params.get("dealer_id") ?? "";
  const page = params.get("page");

  const dispatch = useDispatch();

  const mainState: MainState = useSelector((state: any) => state.main);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    dispatch(changeMainState({ showFilter: false }));
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downloadData();
    }, 150);
    return () => clearTimeout(timeout);
  }, [dealerId]);

  const downloadData = async () => {
    if (searchState.supplierInfo && searchState.supplierInfo.id === dealerId) {
      document.title = `Ogrosa | ${searchState.supplierInfo.companyName}`;
      return;
    }

    const supplier = SupplierApi.getSupplier(dealerId);
    const supplierProducts = SupplierApi.getSupplierProducts(dealerId);
    const supplierAddresses = SupplierApi.getSupplierAddresses(dealerId);
    const supplierRatings = SupplierApi.getSupplierRatings(dealerId);
    const supplierPosts = SupplierApi.getSupplierPosts(dealerId);
    const supplierCertificates = SupplierApi.getSupplierCertificates(dealerId);
    const supplierPictures = SupplierApi.getSupplierPictures(dealerId);

    await Promise.all([
      supplier,
      supplierProducts,
      supplierAddresses,
      supplierRatings,
      supplierPosts,
      supplierCertificates,
      supplierPictures,
    ])
      .then((values) => {
        const supplierInfo: Supplier = values[0];
        supplierInfo.products = values[1];
        supplierInfo.addresses = values[2];
        supplierInfo.ratings = values[3];
        supplierInfo.posts = values[4];
        supplierInfo.certificates = values[5];
        supplierInfo.pictures = values[6];
        dispatch(setSupplierInfo(supplierInfo));
        document.title = `Ogrosa | ${supplierInfo.companyName}`;
      })
      .catch((err) => console.log("SearchDealerInfoComponent - downloadData"));
  };

  const goTo = () => {
    if (page) {
      navigate(-1);
    } else {
      navigateTo("search");
    }
    dispatch(changeMainState({ showBlog: false }));
    dispatch(clearSearched());
  };

  if (isMobile) {
    return (
      <>
        <div id="top-page" className="f-page-top">
          <Icon.ArrowBackSVG className="i-button i-button-small" onClick={goTo} />
          <div className="d-flex ai-center jc-sb width-fill gap-16">
            <Label
              style={{ width: "65%", overflow: "hidden", textOverflow: "ellipsis" }}
              fontSize="lg"
              fontStyle="semibold"
              content={searchState.supplierInfo?.companyName}
            />
            <Button
              sizeFrame="sml"
              label="Posty"
              fontStyle="bold"
              icon={<Icon.PublicSVG />}
              kind={mainState.showBlog ? "primary" : "tertiary"}
              style={{ position: "absolute", right: "8px" }}
              toolTip={"Sprawdź ostatnie posty sprzedawcy"}
              toolTipPosition="left"
              onClick={() => dispatch(changeMainState({ showBlog: !mainState.showBlog }))}
            />
          </div>
        </div>
        <div className="f-page-center">
          <div ref={scrollRef} className="f-scroll gap-16">
            <SearchSupplierInfoFarmPictureComponent />
            <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
              <div className="d-flex fd-c width-fill gap-8" style={{ overflow: "hidden" }}>
                <Label fontSize="lg" fontStyle="semibold" content="Sprzedawca" />
                <DealerCardComponent supplier={searchState.supplierInfo} showIcon />
              </div>
            </InfoBox>
            <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
              <div className="d-flex fd-c width-fill gap-8">
                <Label fontSize="lg" fontStyle="semibold" content={"Opis gospodarstwa"} />
                <Label fontSize="sm" wrap fontStyle="regular" content={searchState.supplierInfo?.description} />
              </div>
            </InfoBox>
            <SearchSupplierInfoCertificationComponent />
            <SearchLocationComponent addresses={searchState.supplierInfo?.addresses ?? []} />
            <SearchSupplierInfoProductsComponent />
            <SearchSupplierInfoRatingsComponent />
            <div className="d-flex width-fill ai-center jc-sb mag-b-16">
              <Label
                fontSize="sm"
                wrap
                fontStyle="regular"
                content={`Wyświetlenia: ${searchState.supplierInfo?.visitorsQty?.toString()}`}
              />
              <Button
                kind="text"
                sizeFrame="sml"
                label="Zgłoś"
                fontColor="red"
                icon={<Icon.FlagSVG className="c-red" />}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="f-fill fd-c gap-8">
      <div className="d-flex ai-center gap-8">
        <Icon.ArrowBackSVG className="i-button i-button-small" onClick={goTo} />
        <div className="d-flex ai-center jc-sb width-fill gap-16" style={{ position: "relative" }}>
          <Label
            style={{ width: "80%", overflow: "hidden", textOverflow: "ellipsis" }}
            fontSize="lg"
            fontStyle="semibold"
            content={searchState.supplierInfo?.companyName}
          />
          <Button
            sizeFrame="sml"
            label="Posty"
            fontStyle="bold"
            icon={<Icon.PublicSVG />}
            kind={mainState.showBlog ? "primary" : "tertiary"}
            style={{ position: "absolute", right: "8px" }}
            toolTip={"Sprawdź ostatnie posty sprzedawcy"}
            toolTipPosition="left"
            onClick={() => dispatch(changeMainState({ showBlog: !mainState.showBlog }))}
          />
        </div>
      </div>
      <div ref={scrollRef} className="f-scroll pad-r-4 gap-8">
        <SearchSupplierInfoFarmPictureComponent />
        <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
          <div className="d-flex fd-c width-fill gap-8" style={{ overflow: "hidden" }}>
            <Label fontSize="lg" fontStyle="semibold" content="Sprzedawca" />
            <DealerCardComponent supplier={searchState.supplierInfo} showIcon />
          </div>
        </InfoBox>
        <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
          <div className="d-flex fd-c width-fill gap-8">
            <Label fontSize="lg" fontStyle="semibold" content={"Opis gospodarstwa"} />
            <Label fontSize="sm" wrap fontStyle="regular" content={searchState.supplierInfo?.description} />
          </div>
        </InfoBox>
        <SearchSupplierInfoCertificationComponent />
        <SearchLocationComponent addresses={searchState.supplierInfo?.addresses ?? []} />
        <SearchSupplierInfoProductsComponent />
        <SearchSupplierInfoRatingsComponent />
        <div className="d-flex width-fill ai-center jc-sb mag-b-8">
          <Label
            fontSize="sm"
            wrap
            fontStyle="regular"
            content={`Wyświetlenia: ${searchState.supplierInfo?.visitorsQty?.toString()}`}
          />
          <Button kind="text" sizeFrame="sml" label="Zgłoś" fontColor="red" icon={<Icon.FlagSVG className="c-red" />} />
        </div>
      </div>
    </div>
  );
};

export default SearchSupplierInfoComponent;
