import React from "react";
import * as Icon from "commons/iconManager";
import * as SupplierApi from "api/SupplierApi";
import ProfileRatingComponent from "../ProfileRatingComponent";
import ProfileCertificatesComponent from "../ProfileCertificatesComponent";
import ProfileSaleCouponsComponent from "../ProfileSaleCouponsComponent";
import ProfileSubscriptionComponent from "../ProfileSubscriptionComponent";
import ProfileDataDealerComponent from "../ProfileDataDealerComponent";
import ProfilePointsComponent from "../ProfilePointsComponent";
import ProfileDataComponent from "../ProfileDataComponent";
import { Button } from "controls/Button";
import { useNavigation } from "hooks/useNavigation";
import { useURLParams } from "hooks/useURLParams";
import { useDispatch, useSelector } from "react-redux";
import { UserState, UserValideError } from "models/new/State";
import { cleanValideErrorUser, setValideErrorUser } from "reducers/userReducer";
import { Picture } from "models/new/Picture";
import { ValidationFailure } from "models/new/ValidationFailure";
import { SupplierDto } from "models/DTO/SupplierDto";
import { SupplierAddressDto } from "models/DTO/SupplierAddressDto";
import { PopUp } from "controls/PopUp";
import { Label } from "controls/Label";
import { useLogin } from "hooks/useLogin";
import { NotificationType } from "models/new/Dictionaries";
import { useNotify } from "hooks/useNotify";

export interface ProfileTabProps {
  id: string;
}

const ProfileMobileManagerComponent: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { navigateTo } = useNavigation();
  const { addNotify } = useNotify();
  const { reLogin } = useLogin();
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const scrollRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const { params } = useURLParams();
  const tab = params.get("tab") ?? "";

  React.useEffect(() => {
    dispatch(cleanValideErrorUser());
    setTimeout(() => {
      const childElement = document.getElementById(tab);
      childElement?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  }, []);

  const goTo = (tab: string) => {
    navigateTo("profile", `?tab=${tab}`);
    const childElement = document.getElementById(tab);
    childElement?.scrollIntoView({ behavior: "smooth" });
  };

  const saveUser = async () => {
    if (userState.supplier?.addresses.some((x) => x.openHours === undefined)) {      
        addNotify({
          title: "Brak danych",
          content: "Przy jednej z lokalizacji brakuje godzin otwarcia - uzupełnij dane",
          type: NotificationType.Warning,
        });
      return;
    }
    setLoading(true);
    PopUp.show(
      scrollRef.current,
      <div className="d-flex fd-c ai-center gap-32">
        <Icon.LoadingSVG height={128} width={128} />
        <Label fontSize="md" fontStyle="bold" content="Trwa zapisywanie..." />
      </div>
    );
    dispatch(cleanValideErrorUser());
    const toDelete: string[] = [];
    let savePicture: Picture[] = [];
    const formData = new FormData();

    const photoUrlPicture = userState.user?.photoFile;
    if (photoUrlPicture) {
      if (photoUrlPicture.file && !photoUrlPicture.url) {
        formData.append(`files`, photoUrlPicture.file!);
        toDelete.push(photoUrlPicture.id);
        savePicture.push({ id: photoUrlPicture.id, url: "" });
      }
    }

    const logoUrlPicture = userState.supplier?.logoFile;
    if (logoUrlPicture) {
      if (logoUrlPicture.file && !logoUrlPicture.url) {
        formData.append(`files`, logoUrlPicture.file!);
        toDelete.push(logoUrlPicture.id);
        savePicture.push({ id: logoUrlPicture.id, url: "" });
      }
    }

    if (userState.user?.isSupplier) {
      userState.supplier?.pictures?.map((pic) => {
        savePicture.push({ id: pic.id, url: pic.url });
      });
    }

    if (userState.supplier?.pictures && userState.supplier?.pictures?.length > 0) {
      userState.supplier.pictures.forEach((file, index) => {
        if (file.file && !file.url) {
          formData.append(`files`, file.file!);
          toDelete.push(file.id);
        }
      });
    }

    if (toDelete.length > 0) {
      await SupplierApi.saveSupplierPicture(formData)
        .then((value) => {
          savePicture = savePicture?.map((picture) => {
            const saved = value.find((x) => x.id === picture.id);
            if (saved) {
              return saved;
            }
            return picture;
          });
        })
        .catch((err) => {
          addNotify({ title: "Błąd", content: err, type: NotificationType.Error });
          setLoading(false);
          return;
        });
    }

    let addresses: SupplierAddressDto[] = [];
    userState.supplier?.addresses.forEach((adr) => {
      addresses.push({
        id: adr.id,
        supplierId: userState.supplier?.id ?? "",
        latitude: adr.latitude,
        longitude: adr.longitude,
        street: adr.street,
        buildingNumber: adr.buildingNumber,
        localNumber: adr.localNumber,
        city: adr.city,
        postalCode: adr.postalCode,
        country: adr.country ?? "Polska",
        isMainAddress: adr.isMainAddress,
        openHours:
          adr.openHours?.map((x) => {
            return {
              ...x,
              supplierAddressId: adr.id,
              closeHour: `${x.closeHour.slice(0, 5)}:00`,
              startHour: `${x.startHour.slice(0, 5)}:00`,
            };
          }) ?? [],
      });
    });

    const supplier: SupplierDto = {
      appUserId: userState.user?.id,
      profilUrl: userState.user?.photoUrl ?? savePicture.find((x) => x.id === userState.user?.photoFile?.id)?.url,
      firstName: userState.user?.firstName,
      lastName: userState.user?.lastName,
      email: userState.user?.email,
      phoneNumber: userState.user?.phoneNumber,

      id: userState.supplier?.id,
      arimr: userState.supplier?.arimr,
      rhd: userState.supplier?.rhd,
      description: userState.supplier?.description,
      logoUrl: userState.supplier?.logoUrl ?? savePicture.find((x) => x.id === userState.supplier?.logoFile?.id)?.url,
      companyName: userState.supplier?.companyName,
      pictures: savePicture.filter((x) => userState.supplier?.pictures.some((z) => z.id === x.id)),
      addresses: addresses,
      certificates: userState.supplier?.certificates,
      saleCoupons: userState.supplier?.saleCoupons,
    };

    await SupplierApi.saveSupplier(supplier)
      .then((value) => {
        reLogin();
          addNotify({ title: "Świetnie", content: "Zmiany zostały zapisane!", type: NotificationType.Success });        
      })
      .catch((err) => {
        setError(err);
        toDelete.forEach((del) => {
          SupplierApi.deleteSupplierPicture(del);
        });
      })
      .finally(() => setLoading(false));

    PopUp.onHide();
  };

  const setError = (err: ValidationFailure[] | string) => {
    if (typeof err === "string") {
        addNotify({
          title: "Błąd",
          content: `Wystąpił błąd podczas zapisu produktu: ${err}`,
          type: NotificationType.Error,
        });
    } else {
      const valideError: UserValideError = {
        subscriptionType: err?.find((x) => x.PropertyName?.toLowerCase() === "type")?.ErrorMessage ?? "",
        firstName: err?.find((x) => x.PropertyName?.toLowerCase() === "firstname")?.ErrorMessage ?? "",
        lastName: err?.find((x) => x.PropertyName?.toLowerCase() === "lastname")?.ErrorMessage ?? "",
        companyName: err?.find((x) => x.PropertyName?.toLowerCase() === "companyname")?.ErrorMessage ?? "",
        phoneNumber: err?.find((x) => x.PropertyName?.toLowerCase() === "phonenumber")?.ErrorMessage ?? "",
        email: err?.find((x) => x.PropertyName?.toLowerCase() === "email")?.ErrorMessage ?? "",
        description: err?.find((x) => x.PropertyName?.toLowerCase() === "description")?.ErrorMessage ?? "",
        logoUrl: err?.find((x) => x.PropertyName?.toLowerCase() === "logourl")?.ErrorMessage ?? "",
        pictures: err?.find((x) => x.PropertyName?.toLowerCase() === "pictures")?.ErrorMessage ?? "",
        addresses: err?.find((x) => x.PropertyName?.toLowerCase() === "addresses")?.ErrorMessage ?? "",
        addressBuildingNumber:
          err?.find((x) => x.PropertyName?.toLowerCase() === "address.buildingnumber")?.ErrorMessage ?? "",
        addressCity: err?.find((x) => x.PropertyName?.toLowerCase() === "address.city")?.ErrorMessage ?? "",
        addressPostalCode: err?.find((x) => x.PropertyName?.toLowerCase() === "address.postalcode")?.ErrorMessage ?? "",
        certificate: err?.find((x) => x.PropertyName?.toLowerCase() === "certificate")?.ErrorMessage ?? "",
        longitude: err?.find((x) => x.PropertyName?.toLowerCase() === "longitude")?.ErrorMessage ?? "",
        latitude: err?.find((x) => x.PropertyName?.toLowerCase() === "latitude")?.ErrorMessage ?? "",
      };
      dispatch(setValideErrorUser(valideError));
        addNotify({
          title: "Błąd",
          content: "Wystąpił błąd podczas zapisu danych użytkownika",
          type: NotificationType.Error,
        });
    }
  };

  const checkErrorData = (): boolean => {
    let result = false;
    if (userState.valideError.firstName && userState.valideError.firstName.length > 0) return true;
    if (userState.valideError.lastName && userState.valideError.lastName.length > 0) return true;
    if (userState.valideError.phoneNumber && userState.valideError.phoneNumber.length > 0) return true;
    if (userState.valideError.email && userState.valideError.email.length > 0) return true;
    return result;
  };

  const checkErrorSupplierData = (): boolean => {
    let result = false;
    if (userState.valideError.companyName && userState.valideError.companyName.length > 0) return true;
    if (userState.valideError.description && userState.valideError.description.length > 0) return true;
    if (userState.valideError.logoUrl && userState.valideError.logoUrl.length > 0) return true;
    if (userState.valideError.pictures && userState.valideError.pictures.length > 0) return true;
    return result;
  };

  const checkErrorAddress = (): boolean => {
    let result = false;
    if (userState.valideError.addresses && userState.valideError.addresses.length > 0) return true;
    if (userState.valideError.addressBuildingNumber && userState.valideError.addressBuildingNumber.length > 0)
      return true;
    if (userState.valideError.addressCity && userState.valideError.addressCity.length > 0) return true;
    if (userState.valideError.addressPostalCode && userState.valideError.addressPostalCode.length > 0) return true;
    if (userState.valideError.longitude && userState.valideError.longitude.length > 0) return true;
    if (userState.valideError.latitude && userState.valideError.latitude.length > 0) return true;
    return result;
  };

  return (
    <>
      <div id="top-page" className="f-page-top">
        <div className="f-scroll-hidden-r gap-8">
          <Button
            sizeFrame="md"
            kind={tab === "data" ? "primary" : "tertiary"}
            label="Dane podstawowe"
            icon={<Icon.InfoSVG />}
            onClick={() => goTo("data")}
            error={checkErrorData()}
          />
          {!userState.user?.isSupplier && (
            <Button
              sizeFrame="md"
              label="Dołącz do sprzedawców"
              icon={<Icon.AddBusinessSVG />}
              kind={tab === "subscription" ? "primary" : "tertiary"}
              onClick={() => goTo("subscription")}
            />
          )}
          {userState.user?.isSupplier && (
            <>
              <Button
                sizeFrame="md"
                label="Subskrypcja"
                icon={<Icon.AddBusinessSVG />}
                kind={tab === "subscription" ? "primary" : "tertiary"}
                onClick={() => goTo("subscription")}
              />
              <Button
                sizeFrame="md"
                kind={tab === "dealer" ? "primary" : "tertiary"}
                label="Dane sprzedawcy"
                icon={<Icon.StorefrontSVG />}
                error={checkErrorSupplierData()}
                onClick={() => goTo("dealer")}
              />
              <Button
                sizeFrame="md"
                kind={tab === "points" ? "primary" : "tertiary"}
                label="Dodatkowe lokalizacje"
                error={checkErrorAddress()}
                icon={<Icon.AddNavigationSVG />}
                onClick={() => goTo("points")}
              />
              <Button
                sizeFrame="md"
                kind={tab === "certificates" ? "primary" : "tertiary"}
                label="Certyfikaty"
                icon={<Icon.LicenseSVG />}
                onClick={() => goTo("certificates")}
              />
              <Button
                sizeFrame="md"
                kind={tab === "rate" ? "primary" : "tertiary"}
                label="Oceny i komentarze"
                icon={<Icon.CommentSVG />}
                onClick={() => goTo("rate")}
              />
            </>
          )}
        </div>
      </div>
      <div className="f-page-center" style={{ marginBottom: "78px" }}>
        <div ref={scrollRef} className="f-scroll">
          <ProfileDataComponent id={"data"} />
          <ProfileSubscriptionComponent id={"subscription"} />
          {userState.user?.isSupplier && (
            <>
              <ProfileDataDealerComponent id={"dealer"} />
              <ProfilePointsComponent id={"points"} />
              <ProfileCertificatesComponent id={"certificates"} />
              <ProfileRatingComponent id={"rate"} />
            </>
          )}
        </div>
        <Button
          sizeFrame="lg"
          kind="primary"
          style={{ position: "fixed", bottom: "16px", right: "16px" }}
          fontStyle="bold"
          fontSize="md"
          icon={<Icon.SaveSVG />}
          label="Zapisz"
          onClick={saveUser}
        />
      </div>
    </>
  );
};

export default ProfileMobileManagerComponent;

/*

              <Button
                sizeFrame="md"
                kind={tab === "sale" ? "primary" : "tertiary"}
                label="Kupony promocyjne"
                icon={<Icon.ShoppingModeSVG />}
                onClick={() => goTo("sale")}
              />

              <ProfileSaleCouponsComponent id={"sale"} />
*/

/*

<div className="frame-main d-flex fd-c gap-16">
      <div className="frame-header jc-sb">
        <PathComponent />
        <div className="d-flex fd-r gap-8">
          <Button sizeFrame="sm" kind="tertiary" fontStyle="bold" label="Anuluj" onClick={() => navigate(-1)} />
          <Button
            sizeFrame="sm"
            kind="primary"
            fontStyle="bold"
            label="Zapisz"
            icon={<Icon.SaveSVG />}
            onClick={saveUser}
          />
        </div>
      </div>
      <div className="frame-main fd-r">
        <div
          className="d-flex fd-c gap-8 pad-16 height-fill border-right"
          style={{ width: "250px", marginBlock: "16px" }}
        >
          <Button
            sizeFrame="sm"
            kind={tab === "data" ? "menu-seleceted" : "menu"}
            label="Dane podstawowe"
            icon={<Icon.InfoSVG />}
            onClick={() => goTo("data")}
            error={checkErrorData()}
          />
          {!userState.user?.isSupplier && userState.view === "supplier" && (
            <Button
              sizeFrame="sm"
              label="Dołącz do sprzedawców"
              icon={<Icon.AddBusinessSVG />}
              kind={tab === "subscription" ? "menu-seleceted" : "menu"}
              onClick={() => goTo("subscription")}
            />
          )}
          {userState.user?.isSupplier && userState.view === "supplier" && (
            <>
              <Button
                sizeFrame="sm"
                label="Subskrypcja"
                icon={<Icon.AddBusinessSVG />}
                kind={tab === "subscription" ? "menu-seleceted" : "menu"}
                onClick={() => goTo("subscription")}
              />
              <Button
                sizeFrame="sm"
                kind={tab === "dealer" ? "menu-seleceted" : "menu"}
                label="Dane sprzedawcy"
                icon={<Icon.StorefrontSVG />}
                error={checkErrorSupplierData()}
                onClick={() => goTo("dealer")}
              />
              <Button
                sizeFrame="sm"
                kind={tab === "points" ? "menu-seleceted" : "menu"}
                label="Dodatkowe lokalizacje"
                error={checkErrorAddress()}
                icon={<Icon.AddNavigationSVG />}
                onClick={() => goTo("points")}
              />
              <Button
                sizeFrame="sm"
                kind={tab === "sale" ? "menu-seleceted" : "menu"}
                label="Kupony promocyjne"
                icon={<Icon.ShoppingModeSVG />}
                onClick={() => goTo("sale")}
              />
              <Button
                sizeFrame="sm"
                kind={tab === "certificates" ? "menu-seleceted" : "menu"}
                label="Certyfikaty"
                icon={<Icon.LicenseSVG />}
                onClick={() => goTo("certificates")}
              />
              <Button
                sizeFrame="sm"
                kind={tab === "rate" ? "menu-seleceted" : "menu"}
                label="Oceny i komentarze"
                icon={<Icon.CommentSVG />}
                onClick={() => goTo("rate")}
              />
            </>
          )}
        </div>
        <div ref={scrollRef} className="frame-scroll">
          <ProfileDataComponent id={"data"} />
          <ProfileSubscriptionComponent id={"subscription"} />
          {userState.user?.isSupplier && userState.view === "supplier" && (
            <>
              <ProfileDataDealerComponent id={"dealer"} />
              <ProfilePointsComponent id={"points"} />
              <ProfileSaleCouponsComponent id={"sale"} />
              <ProfileCertificatesComponent id={"certificates"} />
              <ProfileRatingComponent id={"rate"} />
            </>
          )}
        </div>
      </div>
    </div>
*/
