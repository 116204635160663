import React from "react";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { MainState, OrderState } from "models/new/State";
import { InputComboBox } from "controls/InputComboBox";
import { Button } from "controls/Button";
import { EntryDictionary } from "models/new/EntryDictionary";
import { changeMainState } from "reducers/mainReducer";
import { delivertType, orderStatus, sort } from "commons/listManager";
import { SearchOrderFilter } from "models/new/SearchOrderFilter";
import { DeliveryType, StatusOrder } from "models/new/Dictionaries";
import { changeOrderFilter, clearOrderFilter, initialFilter } from "reducers/orderReducer";

const OrderMobileFilterComponent: React.FC = () => {
  const [filter, setFilter] = React.useState<SearchOrderFilter>(initialFilter);
  const mainState: MainState = useSelector((state: any) => state.main);
  const orderState: OrderState = useSelector((state: any) => state.order);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setFilter(orderState.filter);
  }, [mainState.showFilterOrder]);

  const commitFilter = () => {
    dispatch(changeOrderFilter({ ...filter, take: 30 }));
    dispatch(changeMainState({ showFilterOrder: false }));
  };
  const clearFilter = () => {
    dispatch(clearOrderFilter());
    dispatch(changeMainState({ showFilterOrder: false }));
  };

  const onDisplayContentOrderStatusCombobox = (item: EntryDictionary): JSX.Element => {
    let icon;
    switch (item.value) {
      case StatusOrder.New:
        icon = <Icon.AddSVG />;
        break;
      case StatusOrder.Canceled:
        icon = <Icon.DangerousSVG />;
        break;
      case StatusOrder.InProgress:
        icon = <Icon.GrocerySVG />;
        break;
      case StatusOrder.Ready:
        icon = <Icon.CheckCircleSVG />;
        break;
      case StatusOrder.Sent:
        icon = <Icon.LocalShippingSVG />;
        break;
      case StatusOrder.Completed:
        icon = <Icon.OrdersSVG />;
        break;
      case StatusOrder.WaitingForPayment:
        icon = <Icon.PaymentsSVG />;
        break;
    }
    return (
      <Button
        style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
        sizeFrame="lg"
        kind={filter.statusOrder === item.value ? "menu-seleceted" : "menu"}
        label={item.name}
        icon={icon}
      />
    );
  };
  const onDisplayContentAddressCombobox = (item: EntryDictionary): JSX.Element => {
    if (item.value === DeliveryType.All) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="lg"
          kind={filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={"Wszystkie"}
        />
      );
    }
    if (item.value === DeliveryType.PickUpAtThePoint) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="lg"
          kind={filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.AddNavigationSVG />}
        />
      );
    }
    if (item.value === DeliveryType.PickupOnSite) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="lg"
          kind={filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.LocationOnSVG />}
        />
      );
    }
    if (item.value === DeliveryType.Post) {
      return (
        <Button
          style={{ width: "100%", marginBlock: "2px", gap: "8px" }}
          sizeFrame="lg"
          kind={filter.deliveryType === item.value ? "menu-seleceted" : "menu"}
          label={item.name}
          icon={<Icon.LocalShippingSVG />}
        />
      );
    }
    return <></>;
  };

  return (
    <div className="frame-main pad-8 gap-16">
      <Label fontStyle="semibold" content="Sortuj" />
      <InputComboBox
        frameClassName="width-fill"
        label="Sortuj według"
        placeholder="Sortuj według"
        sizeFrame="sm"
        kind="tertiary"
        displayMember="name"
        canClear={false}
        itemSource={sort}
        selectedItem={sort.find((x) => x.code === filter.sort)}
        onSelectionChanged={(e) => setFilter({ ...filter, sort: e.code })}
      />
      <Label fontStyle="semibold" content="Filtruj" />

      <InputComboBox
        frameClassName="width-fill"
        label="Status zamówienia"
        placeholder="Status zamówienia"
        sizeFrame="sm"
        kind="tertiary"
        displayMember="name"
        canClear={false}
        itemSource={orderStatus}
        selectedItem={orderStatus.find((x) => x.value === filter.statusOrder)}
        onSelectionChanged={(e) => setFilter({ ...filter, statusOrder: e.value })}
        onDisplayContent={onDisplayContentOrderStatusCombobox}
      />
      <InputComboBox
        frameClassName="width-fill"
        label="Typ przesyłki"
        placeholder="Typ przesyłki"
        sizeFrame="sm"
        kind="tertiary"
        displayMember="name"
        canClear={false}
        itemSource={delivertType}
        selectedItem={delivertType.find((x) => x.value === filter.deliveryType)}
        onSelectionChanged={(e) => setFilter({ ...filter, deliveryType: e.value })}
        onDisplayContent={onDisplayContentAddressCombobox}
      />

      <div className="d-flex gap-8 width-fill" style={{ marginTop: "auto" }}>
        <Button kind="tertiary" sizeFrame="md" fontStyle="bold" label="Wyczyść" onClick={clearFilter} />
        <Button
          className="width-fill"
          sizeFrame="md"
          fontStyle="bold"
          kind="primary"
          label="Filtruj"
          icon={<Icon.TuneSVG />}
          onClick={commitFilter}
        />
      </div>
    </div>
  );
};

export default OrderMobileFilterComponent;

/*
  <InputDate
        frameClassName="width-fill"
        label="Zakres dat"
        placeholder="Zakres dat"
        sizeFrame="sm"
        kind="tertiary"
        rangeValue={{ from: filter.dateFrom, to: filter.dateTo }}
        onChangeRange={(e) => setFilter({ ...filter, dateFrom: e.from, dateTo: e.to })}
      />

*/
