import { createAction, createReducer } from "@reduxjs/toolkit";
import { AppUser } from "models/new/AppUser";
import { Chat } from "models/new/Chat";
import { ChatMessage } from "models/new/ChatMessage";
import { MessageStatusType } from "models/new/Dictionaries";
import { ConversationState } from "models/new/State";
import { Supplier } from "models/new/Supplier";

const initialState = { conversations: [], activeConversations: [] } as ConversationState;

export const setConversation = createAction<Chat[]>("conversation/setConversation");
export const addConversation = createAction<{ chat: Chat; isOpen: boolean }>("conversation/addConversation");
export const addConversationSignal = createAction<Chat>("conversation/addConversationSignal");
export const showConversation = createAction<Chat>("conversation/showConversation");
export const closeConversation = createAction<Chat>("conversation/closeConversation");
export const markAsRead = createAction<{ conversationId: string; senderId: string }>("conversation/markAsRead");
export const getMessageConversation = createAction<ChatMessage>("conversation/getMessageConversation");
export const setConversationUsers = createAction<{
  conversationId: string;
  user?: AppUser;
  supplier1?: Supplier;
  supplier2?: Supplier;
}>("conversation/setConversationUsers");

export const conversationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setConversation, (state, action) => {
      state.conversations = action.payload;
      if (action.payload.length === 0) {
        state.activeConversations = action.payload;
      }
    })
    .addCase(addConversation, (state, action) => {
      const existConv = state.conversations.find((x) => action.payload.chat.id && x.id === action.payload.chat.id);
      if (existConv) {
        state.conversations = state.conversations.map((x) =>
          x.id === existConv.id ? { ...x, isOpen: action.payload.isOpen } : x
        );
      } else {
        state.conversations = [...state.conversations, action.payload.chat];
      }

      const existConvActiv = state.activeConversations.find(
        (x) => action.payload.chat.id && x.id === action.payload.chat.id
      );
      if (existConvActiv) {
        state.activeConversations = state.activeConversations.map((x) =>
          x.id === existConvActiv.id ? { ...x, isOpen: action.payload.isOpen } : x
        );
      } else {
        state.activeConversations = [
          ...state.activeConversations,
          { ...action.payload.chat, isOpen: action.payload.isOpen },
        ];
      }
    })
    .addCase(addConversationSignal, (state, action) => {
      state.conversations = [...state.conversations, action.payload];
      const existChats = hasSameChatMembers(state.activeConversations, action.payload);
      if (existChats) {
        state.activeConversations = [
          ...state.activeConversations.filter((x) => x.id !== existChats.id),
          { ...action.payload, isOpen: true },
        ];
      }
    })
    .addCase(showConversation, (state, action) => {
      state.activeConversations = state.activeConversations.map((x) =>
        x.id === action.payload.id ? { ...x, isOpen: !x.isOpen } : x
      );
    })
    .addCase(closeConversation, (state, action) => {
      state.activeConversations = state.activeConversations.filter((x) => x.id !== action.payload.id);
    })
    .addCase(markAsRead, (state, action) => {
      state.conversations = state.conversations.map((x) =>
        x.id === action.payload.conversationId
          ? {
              ...x,
              chatMessages: x.chatMessages?.map((z) =>
                z.appUserId !== action.payload.senderId
                  ? {
                      ...z,
                      chatMessageStatuses: z.chatMessageStatuses.map((y) =>
                        y.appUserId === action.payload.senderId ? { ...y, status: MessageStatusType.Read } : y
                      ),
                    }
                  : z
              ),
            }
          : x
      );
      state.activeConversations = state.activeConversations.map((x) =>
        x.id === action.payload.conversationId
          ? {
              ...x,
              chatMessages: x.chatMessages?.map((z) =>
                z.appUserId !== action.payload.senderId
                  ? {
                      ...z,
                      chatMessageStatuses: z.chatMessageStatuses.map((y) =>
                        y.appUserId === action.payload.senderId ? { ...y, status: MessageStatusType.Read } : y
                      ),
                    }
                  : z
              ),
            }
          : x
      );
    })
    .addCase(getMessageConversation, (state, action) => {
      const existConv = state.conversations.find((x) => x.id === action.payload!.chatId);
      if (existConv) {
        state.conversations = state.conversations.map((x) =>
          x.id === existConv.id ? { ...x, isOpen: true, chatMessages: [action.payload, ...(x.chatMessages ?? [])] } : x
        );
      }

      const existConvActiv = state.activeConversations.find((x) => x.id === action.payload!.chatId);
      if (existConvActiv) {
        state.activeConversations = state.activeConversations.map((x) =>
          x.id === existConvActiv.id ? { ...x, chatMessages: [action.payload, ...(x.chatMessages ?? [])] } : x
        );
      }
    })
    .addCase(setConversationUsers, (state, action) => {
      const existConv = state.conversations.find((x) => x.id === action.payload.conversationId);
      if (existConv) {
        state.conversations = state.conversations.map((x) =>
          x.id === existConv.id
            ? {
                ...x,
                appUser: action.payload.user,
                supplier1: action.payload.supplier1,
                supplier2: action.payload.supplier2,
              }
            : x
        );
      }
    });
});

function hasSameChatMembers(existingChats: Chat[], newChat: Chat): Chat | undefined {
  const newMembers = newChat.chatMembers.map((member) => member.appUserId).sort();

  for (const chat of existingChats) {
    const existingMembers = chat.chatMembers.map((member) => member.appUserId).sort();

    if (existingMembers.length !== newMembers.length) {
      continue;
    }

    const areSameMembers = existingMembers.every((member, index) => member === newMembers[index]);

    if (areSameMembers) {
      return chat;
    }
  }

  return undefined;
}
