export const sortArray = (a: any, b: any, name: string) => {
  if (a[name] < b[name]) {
    return -1;
  }
  if (a[name] > b[name]) {
    return 1;
  }
  return 0;
};

export const sortArrayDesc = (a: any, b: any, name: string) => {
  if (a[name] < b[name]) {
    return 1;
  }
  if (a[name] > b[name]) {
    return -1;
  }
  return 0;
};

export const sortArrayWithOutKey = (a: any, b: any) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const sortArrayDescWithOutKey = (a: any, b: any) => {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

export function shuffleArray<T>(array: T[]): T[] {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
