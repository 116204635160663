import React from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import * as CategoryApi from "api/CategoryApi";
import PictureManagerComponent from "components/PictureManagerComponent";
import { InputArea } from "controls/InputArea";
import { Label } from "controls/Label";
import { GroceryTabProps } from "./GroceryManagerComponent";
import { useDispatch, useSelector } from "react-redux";
import { CategoryState, ProductState } from "models/new/State";
import { updateProduct } from "reducers/productReducer";
import InfoBox from "controls/InfoBox";
import { Picture } from "models/new/Picture";
import { generateGUID } from "commons/stringManager";
import { useURLParams } from "hooks/useURLParams";
import { useWindowSize } from "hooks/useWindowSize";
import { changeCategoryState } from "reducers/categoryReducer";
import { InputComboBox } from "controls/InputComboBox";
import { Category } from "models/new/Category";
import { Button } from "controls/Button";

const GroceryDataComponent: React.FC<GroceryTabProps> = (props) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const { isMobile } = useWindowSize();
  const productState: ProductState = useSelector((state: any) => state.product);
  const categoryState: CategoryState = useSelector((state: any) => state.category);
  const dispatch = useDispatch();
  const { params } = useURLParams();
  const action = params.get("action") ?? "";

  React.useEffect(() => {
    downloadData();
  }, []);

  React.useEffect(() => {
    if (!productState.selected?.pictures) return;

    getFiles().then((value) => {
      setFiles(value);
    });
  }, [productState.selected?.pictures]);

  const downloadData = async () => {
    await CategoryApi.get()
      .then((values) => {
        dispatch(changeCategoryState({ categories: values }));
      })
      .catch((err) => console.log("GroceryDataComponent - downloadData"));
  };

  const onChange = async (files?: File[]) => {
    const newPictures: Picture[] = [];
    files?.forEach((file) => {
      let picture = productState.selected?.pictures?.find((x) => x.id === file.name);
      if (!picture) {
        const guid = generateGUID();
        const newFile = new File([file], guid, {
          type: file.type,
          lastModified: file.lastModified,
        });
        picture = {
          id: guid,
          url: "",
          file: newFile,
        };
      } else {
        picture = { ...picture, file: file };
      }
      newPictures.push(picture);
    });

    dispatch(updateProduct({ product: { pictures: newPictures }, errors: { productPictures: "" } }));
  };

  const getFiles = async (): Promise<File[]> => {
    const files: File[] = [];
    if (!productState.selected?.pictures) return files;

    const promises = productState.selected.pictures.map(async (img) => {
      try {
        if (img.file) return img.file;

        const response = await fetch(img.url);
        const blob = await response.blob();
        const file = new File([blob], img.id, { type: blob.type });
        return file;
      } catch (error) {
        const file = new File([], img.id, { type: img.url });
        return file;
      }
    });
    const results = await Promise.all(promises);

    results.forEach((file) => {
      if (file) files.push(file);
    });

    return files;
  };

  const onDisplayContent = (category: Category) => {
    return (
      <Button
        className={!category.parentId ? "bg-secondary mag-b-2" : "mag-b-2"}
        sizeFrame={isMobile ? "md" : "sm"}
        kind={category.id === productState.selected?.categoryId ? "menu-seleceted" : "menu"}
        label={category.name}
        onClick={(e) => {
          dispatch(
            updateProduct({ product: { categoryId: category.parentId ? category.id : -1 }, errors: { category: "" } })
          );
        }}
      />
    );
  };

  if (isMobile) {
    return (
      <div
        id={props.id}
        className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill"
        style={{ maxWidth: "500px" }}
      >
        <Label fontStyle="bold" fontSize="md" content="Dane podstawowe" />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Nazwa produktu"
          placeholder="np. Pomidory malinowe"
          disabled={action === "preview"}
          value={productState.selected?.name}
          error={productState.valideError.name}
          onChange={(e) => dispatch(updateProduct({ product: { name: e.target.value }, errors: { name: "" } }))}
        />
        <InputComboBox
          frameClassName="width-fill"
          displayMember="name"
          itemSource={categoryState.categories}
          sizeFrame="sm"
          kind="tertiary"
          label="Kategoria"
          placeholder="Wybierz kategorie"
          canClear={false}
          disabled={action === "preview"}
          onDisplayContent={onDisplayContent}
          selectedItem={categoryState.categories.find((x) => x.id === productState.selected?.categoryId)}
          error={productState.valideError.category}
        />
        {productState.valideError.productPictures.length > 0 && (
          <InfoBox
            className="width-fill ai-center"
            icon="Error"
            iconSize="sml"
            fontStyle="semibold"
            backgroundColor="red"
            value={productState.valideError.productPictures}
          />
        )}
        <PictureManagerComponent files={files} onChange={onChange} disabled={action === "preview"} />
        <InputArea
          frameClassName="width-fill"
          style={{ height: "200px" }}
          sizeFrame="sm"
          kind="tertiary"
          label="Opis"
          minLength={80}
          maxLength={500}
          disabled={action === "preview"}
          placeholder="Opis musi zawierać od 80 do 500 znaków"
          value={productState.selected?.description}
          error={productState.valideError.description}
          onChange={(e) =>
            dispatch(updateProduct({ product: { description: e.target.value }, errors: { description: "" } }))
          }
        />
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Dane podstawowe" />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Nazwa produktu"
        placeholder="np. Pomidory malinowe"
        disabled={action === "preview"}
        value={productState.selected?.name}
        error={productState.valideError.name}
        onChange={(e) => dispatch(updateProduct({ product: { name: e.target.value }, errors: { name: "" } }))}
      />
      <InputComboBox
        frameClassName="width-fill"
        displayMember="name"
        itemSource={categoryState.categories}
        sizeFrame="sm"
        kind="tertiary"
        label="Kategoria"
        placeholder="Wybierz kategorie"
        canClear={false}
        disabled={action === "preview"}
        onDisplayContent={onDisplayContent}
        selectedItem={categoryState.categories.find((x) => x.id === productState.selected?.categoryId)}
        error={productState.valideError.category}
      />
      {productState.valideError.productPictures.length > 0 && (
        <InfoBox
          className="width-fill ai-center"
          icon="Error"
          iconSize="sml"
          fontStyle="semibold"
          backgroundColor="red"
          value={productState.valideError.productPictures}
        />
      )}
      <PictureManagerComponent files={files} onChange={onChange} disabled={action === "preview"} />
      <InputArea
        frameClassName="width-fill"
        style={{ height: "200px" }}
        sizeFrame="sm"
        kind="tertiary"
        label="Opis"
        minLength={80}
        maxLength={500}
        disabled={action === "preview"}
        placeholder="Opis musi zawierać od 80 do 500 znaków"
        value={productState.selected?.description}
        error={productState.valideError.description}
        onChange={(e) =>
          dispatch(updateProduct({ product: { description: e.target.value }, errors: { description: "" } }))
        }
      />
    </div>
  );
};

export default GroceryDataComponent;
