import { createAction, createReducer } from "@reduxjs/toolkit";
import { UserState, UserValideError } from "../models/new/State";
import { Token } from "../models/new/Token";
import { AppUser } from "models/new/AppUser";
import { Supplier } from "models/new/Supplier";
import { Post, PostComment, PostLike } from "models/new/Post";

export const initialError: UserValideError = {
  subscriptionType: undefined,
  firstName: undefined,
  lastName: undefined,
  companyName: undefined,
  phoneNumber: undefined,
  email: undefined,
  description: undefined,
  profileUrl: undefined,
  logoUrl: undefined,
  pictures: undefined,
  addresses: undefined,
  addressCity: undefined,
  addressPostalCode: undefined,
  addressBuildingNumber: undefined,
  certificate: undefined,
  latitude: undefined,
  longitude: undefined,
};

const initialState = { valideError: initialError, view: "client" } as UserState;

export const setUserToken = createAction<Token | undefined>("user/setUserToken");
export const changeView = createAction<"client" | "supplier">("user/changeView");
export const setValideErrorUser = createAction<UserValideError>("user/setValideErrorUser");

export const changeUserState = createAction<Partial<UserState>>("user/changeUserState");
export const changeUser = createAction<{ user: Partial<AppUser>; validError?: Partial<UserValideError> }>(
  "user/changeUser"
);
export const changeSupplier = createAction<{ supplier?: Partial<Supplier>; validError?: Partial<UserValideError> }>(
  "user/changeSupplier"
);
export const cleanValideErrorUser = createAction("user/cleanValideErrorUser");
export const logOut = createAction("user/logOut");

export const setFollow = createAction<string>("user/setFollow");

export const setUnfollow = createAction<string>("user/setUnfollow");

export const setMyPost = createAction<Post[]>("search/setMyPost");
export const setMyPostLike = createAction<PostLike>("search/setMyPostLike");
export const setMyPostUnlike = createAction<PostLike>("search/setMyPostUnlike");
export const setMyPostComment = createAction<PostComment>("search/setMyPostComment");

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeView, (state, action) => {
      state.view = action.payload;
    })
    .addCase(changeUserState, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addCase(changeUser, (state, action) => {
      state.user = { ...state.user!, ...action.payload.user };
      state.valideError = { ...state.valideError, ...action.payload.validError };
    })
    .addCase(changeSupplier, (state, action) => {
      state.supplier = { ...state.supplier!, ...action.payload.supplier };
      state.valideError = { ...state.valideError, ...action.payload.validError };
    })
    .addCase(setValideErrorUser, (state, action) => {
      state.valideError = action.payload;
    })
    .addCase(setUserToken, (state, action) => {
      state.token = action.payload;
    })
    .addCase(cleanValideErrorUser, (state, action) => {
      state.valideError = initialError;
    })
    .addCase(logOut, (state) => {
      return initialState;
    })
    .addCase(setFollow, (state, action) => {
      if (!state.user?.followes) {
        state.user!.followes = [];
      }

      state.user?.followes?.push({ appUserId: state.user.id, supplierId: action.payload });
    })
    .addCase(setUnfollow, (state, action) => {
      if (!state.user?.followes) {
        state.user!.followes = [];
      }
      state.user!.followes = state.user?.followes?.filter((x) => x.supplierId !== action.payload);
    })
    .addCase(setMyPost, (state, action) => {
      if (!state.supplier?.posts) return;

      state.supplier.posts = action.payload;
    })
    .addCase(setMyPostLike, (state, action) => {
      if (!state.supplier?.posts) return;

      state.supplier.posts = state.supplier?.posts.map((post) =>
        post.id === action.payload.postId ? { ...post, likes: [...(post?.likes ?? []), action.payload] } : post
      );
    })
    .addCase(setMyPostUnlike, (state, action) => {
      if (!state.supplier?.posts) return;

      state.supplier.posts = state.supplier.posts.map((post) =>
        post.id === action.payload.postId
          ? { ...post, likes: post?.likes?.filter((x) => x.id !== action.payload.id) }
          : post
      );
    })
    .addCase(setMyPostComment, (state, action) => {
      if (!state.supplier?.posts) return;

      state.supplier.posts = state.supplier.posts.map((post) =>
        post.id === action.payload.postId ? { ...post, comments: [...(post.comments ?? []), action.payload] } : post
      );
    });
});
