import React from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "models/new/State";
import { ProfileTabProps } from "./ProfileManagerComponent";
import PictureProfileComponent from "components/PictureProfileComponent";
import InfoBox from "controls/InfoBox";
import { ModalDialog } from "controls/ModalDialog";
import { changeUser } from "reducers/userReducer";
import { getFileOrFetch, processFile } from "commons/fileManager";
import { useWindowSize } from "hooks/useWindowSize";

const ProfileDataComponent: React.FC<ProfileTabProps> = (props) => {
  const [file, setFile] = React.useState<File>();
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  React.useEffect(() => {
    getFile().then((value) => {
      setFile(value);
    });
  }, [userState.user?.photoUrl]);

  const onChange = async (file?: File) => {
    if (!file) {
      dispatch(
        changeUser({ user: { photoFile: undefined, photoUrl: undefined }, validError: { profileUrl: undefined } })
      );
      return;
    }
    let picture = processFile(file, userState?.user?.photoFile ? [userState.user.photoFile] : []);
    dispatch(changeUser({ user: { photoFile: picture, photoUrl: undefined }, validError: { profileUrl: undefined } }));
  };
  const getFile = async (): Promise<File | undefined> => {
    if (!userState.user?.photoUrl) return undefined;
    return getFileOrFetch(userState.user?.photoUrl);
  };
  const showInfo = async () => {
    ModalDialog.show(
      <div className="d-flex fd-c gap-32" style={{ maxWidth: "500px" }}>
        <Icon.OgrosaFullSVG height={80} />
        <Label
          wrap
          fontSize="md"
          fontStyle="bold"
          content="Twój klucz do lepszej obsługi!"
        />
        <div className="d-flex fd-c gap-4">
          <Label wrap fontSize="md" fontStyle="bold" content="Szybki Kontakt" />
          <Label
            wrap
            fontSize="sm"
            fontStyle="semibold"
            content="W razie jakichkolwiek pytań lub potrzeby szybkiej zmiany w zamówieniu, będziemy mogli natychmiast się z Tobą skontaktować."
          />
        </div>
        <div className="d-flex fd-c gap-4">
          <Label wrap fontSize="md" fontStyle="bold" content="Aktualizacje Zamówień" />
          <Label
            wrap
            fontSize="sm"
            fontStyle="semibold"
            content="Otrzymuj powiadomienia SMS o statusie Twojego zamówienia, od momentu złożenia aż po dostawę."
          />
        </div>
        <div className="d-flex fd-c gap-4">
          <Label wrap fontSize="md" fontStyle="bold" content="Bezproblemowa Dostawa" />
          <Label
            wrap
            fontSize="sm"
            fontStyle="semibold"
            content="W przypadku trudności z dostawą, szybki kontakt telefoniczny pomoże nam znaleźć rozwiązanie, aby Twoje zamówienie dotarło na czas."
          />
        </div>
        <div className="d-flex fd-c gap-4">
          <Label wrap fontSize="md" fontStyle="bold" content="Wyjątkowe Oferty" />
          <Label
            wrap
            fontSize="sm"
            fontStyle="semibold"
            content="Bądź na bieżąco z naszymi najnowszymi promocjami i specjalnymi ofertami!"
          />
        </div>
        <Label
          wrap
          fontSize="sm"
          fontStyle="bold"
          content="Twoje bezpieczeństwo jest dla nas priorytetem – obiecujemy, że Twój numer telefonu będzie używany wyłącznie w celach związanych z realizacją zamówienia i obsługą klienta."
        />
      </div>,
      "Dlaczego warto podać numer telefonu?",
      ["Ok"]
    );
  };

  if (isMobile) {
    return (
      <div id={props.id} className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill">
        <Label fontStyle="bold" fontSize="md" content="Dane podstawowe" />
        {userState.valideError?.profileUrl && (
          <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
            <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.profileUrl} />
          </InfoBox>
        )}
        <PictureProfileComponent file={file} onChange={onChange} required={false} />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Imię"
          placeholder="np. Jan"
          autoComplete="given-name"
          error={userState.valideError?.firstName}
          isRequired
          value={userState.user?.firstName}
          onChange={(e) =>
            dispatch(changeUser({ user: { firstName: e.target.value }, validError: { firstName: undefined } }))
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Nazwisko"
          placeholder="np. Kowalski"
          autoComplete="family-name"
          error={userState.valideError?.lastName}
          isRequired
          value={userState.user?.lastName}
          onChange={(e) =>
            dispatch(changeUser({ user: { lastName: e.target.value }, validError: { lastName: undefined } }))
          }
        />
        <InfoBox
          className="width-fill"
          style={{ cursor: "pointer", alignItems: "center" }}
          value={"Dlaczego warto podać numer telefonu? Więcej..."}
          onClick={showInfo}
          fontStyle="bold"
          fontSize="md"
          pointer
          icon="Information"
          iconSize="sm"
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Numer telefonu"
          placeholder="np. 789456123"
          autoComplete="tel"
          maxLength={9}
          error={userState.valideError?.phoneNumber}
          isRequired={userState.user?.isSupplier}
          value={userState.user?.phoneNumber}
          onChange={(e) =>
            dispatch(changeUser({ user: { phoneNumber: e.target.value }, validError: { phoneNumber: undefined } }))
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Email"
          autoComplete="email"
          disabled
          error={userState.valideError?.email}
          isRequired
          placeholder="np. jan.kowalski@gmail.com"
          value={userState.user?.email}
        />
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Dane podstawowe" />
      {userState.valideError?.profileUrl && (
        <InfoBox className="width-fill ai-center" backgroundColor="red" sizeFrame="xsm" icon="Error" iconSize="xsm">
          <Label wrap pointer fontStyle="semibold" fontColor="black" content={userState.valideError?.profileUrl} />
        </InfoBox>
      )}
      <PictureProfileComponent file={file} onChange={onChange} required={false} />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Imię"
        placeholder="np. Jan"
        autoComplete="given-name"
        error={userState.valideError?.firstName}
        isRequired
        value={userState.user?.firstName}
        onChange={(e) =>
          dispatch(changeUser({ user: { firstName: e.target.value }, validError: { firstName: undefined } }))
        }
      />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Nazwisko"
        placeholder="np. Kowalski"
        autoComplete="family-name"
        error={userState.valideError?.lastName}
        isRequired
        value={userState.user?.lastName}
        onChange={(e) =>
          dispatch(changeUser({ user: { lastName: e.target.value }, validError: { lastName: undefined } }))
        }
      />
      <InfoBox
        className="width-fill"
        style={{ cursor: "pointer", alignItems: "center" }}
        value={"Dlaczego warto podać numer telefonu? Więcej..."}
        onClick={showInfo}
        fontStyle="bold"
        fontSize="md"
        pointer
        icon="Information"
        iconSize="sm"
      />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Numer telefonu"
        placeholder="np. 789456123"
        autoComplete="tel"
        maxLength={9}
        error={userState.valideError?.phoneNumber}
        isRequired={userState.user?.isSupplier}
        value={userState.user?.phoneNumber}
        onChange={(e) =>
          dispatch(changeUser({ user: { phoneNumber: e.target.value }, validError: { phoneNumber: undefined } }))
        }
      />
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        label="Email"
        autoComplete="email"
        disabled
        error={userState.valideError?.email}
        isRequired
        placeholder="np. jan.kowalski@gmail.com"
        value={userState.user?.email}
      />
    </div>
  );
};

export default ProfileDataComponent;
