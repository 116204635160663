import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "../store";
import { Provider } from "react-redux";

export type PopUpPosition = "left" | "right" | "top" | "bottom" | "onto";

export const PopUp = {
  isVisible: false,
  container: null as HTMLDivElement | null,
  root: null as ReactDOM.Root | null,
  onClose: null as (() => void) | null,
  handleClick: null as ((event: MouseEvent) => void) | null,
  resizeListener: null as (() => void) | null,
  urlInterval: null as any | null,
  element: null as HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement | null,
  content: null as string | React.ReactNode | null,

  onHide: function () {
    this.isVisible = false;
    if (this.container) {
      this.container.remove();
      this.container = null;
    }
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
    if (this.onClose) {
      this.onClose();
    }
    if (this.urlInterval) {
      clearInterval(this.urlInterval);
      this.urlInterval = null;
    }
    if (this.handleClick !== null) window.removeEventListener("click", this.handleClick);
    if (this.resizeListener !== null) window.removeEventListener("resize", this.resizeListener);

    document.body.style.overflow = "unset";
  },

  show: function (
    element: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement,
    content: string | React.ReactNode,
    position: PopUpPosition = "bottom",
    delay: number = 150,
    onClose?: () => void,
    saving?: boolean
  ) {
    if (!element || !content) {
      return;
    }
    this.onClose = () => onClose?.();
    this.container = createContainer();
    let popupContainer: HTMLDivElement | null;
    let showed: boolean = false;
    this.element = element;
    this.content = content;

    let lastUrl = window.location.href;
    this.urlInterval = setInterval(() => {
      const currentUrl = window.location.href;
      if (lastUrl !== currentUrl) {
        this.onHide();
        lastUrl = currentUrl;
      }
    }, 1000);

    this.resizeListener = () => {
      this.onHide();
    };
    window.addEventListener("resize", this.resizeListener);

    this.handleClick = (event) => {
      const clickedElement = event.target;
      //@ts-ignore
      if (popupContainer && !popupContainer.contains(clickedElement) && showed && clickedElement.clientHeight > 0 && !saving) {
        this.onHide();
      }
    };
    window.addEventListener("click", this.handleClick);

    const showPopUp = () => {
      if (!this.isVisible) {
        this.isVisible = true;
        const message: React.ReactNode = (
          <div id="popup" className="popup">
            <PopUpComponent>{content}</PopUpComponent>
          </div>
        );
        document.body.appendChild(this.container!);
        this.container!.style.opacity = "0";
        this.root = ReactDOM.createRoot(this.container!);
        this.root.render(message);
        setTimeout(() => {
          popupContainer = document.getElementById("popup") as HTMLDivElement;
          if (popupContainer !== null) {
            showed = true;
            popupContainer.style.opacity = "0";
            setPopUpPosition(element, popupContainer, position, saving);
            this.container!.style.opacity = "1";
          }
        }, delay);
      }
    };

    showPopUp();
  },
  onRefresh: function (content?: string | React.ReactNode) {
    if (this.root !== null) {
      const message: React.ReactNode = (
        <div id="popup" className="popup">
          <PopUpComponent>{content ?? this.content}</PopUpComponent>
        </div>
      );
      this.root.render(message);
    }
  },
};

const createContainer = (): HTMLDivElement => {
  const container = document.createElement("div");
  container.className = "popup-cover";
  container.id = "popup-cover";
  return container;
};

interface PopUpComponentProps {
  children: React.ReactNode;
}

const PopUpComponent: React.FC<PopUpComponentProps> = (props) => {
  React.useEffect(() => {
    setTimeout(() => {
      const findMe = document.getElementById(`popup-cover`);
      if (findMe) {
        findMe.style.opacity = "1";
        findMe.style.position = "absolute";
        findMe.style.top = window.scrollY + "px";

        document.body.style.overflow = "hidden";
      }
    }, 100);
  }, []);

  return (
    <Provider store={store}>
      <div className="popup-content">{props.children}</div>
    </Provider>
  );
};

export function getElementTopRelativeToDocument(
  element: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement
) {
  const rect = element.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop;
}

export function getElementLeftRelativeToDocument(
  element: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement
) {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  return rect.left + scrollLeft;
}

const setPopUpPosition = (
  parent: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement,
  popUp: HTMLDivElement,
  position: PopUpPosition,
  saving?: boolean
) => {
  const parentLocation = parent.getBoundingClientRect();
  const popUpLocation = popUp.getBoundingClientRect();

  const realTop = getElementTopRelativeToDocument(parent);
  const realLeft = getElementLeftRelativeToDocument(parent);

  if (window.innerWidth <= 768) {
    popUp.style.left = "8px";
    popUp.style.top = "";
    popUp.style.bottom = "0px";
    popUp.style.opacity = "1";
    return;
  }

  if (saving) {
    popUp.style.left = "50%";
    popUp.style.transform = "translateX(-50%)";
    popUp.style.top = "32px";
    popUp.style.opacity = "1";
    popUp.style.width = "350px";
    return;
  }

  const showDefault = () => {
    var diff = (parentLocation.width - popUpLocation.width) / 2;
    var top = realTop + parentLocation.height + 10;
    var left = realLeft + diff;
    popUp.style.top = top + "px";
    popUp.style.left = left + "px";
    popUp.style.opacity = "1";
  };

  switch (position) {
    case "top":
      var diff = (parentLocation.width - popUpLocation.width) / 2;
      var top = realTop - popUpLocation.height - 10;
      var left = realLeft + diff;
      if (checkPosition(parent, popUp, position, popUpLocation, top, left)) {
        popUp.style.top = top + "px";
        if (realLeft <= window.innerWidth / 2) {
          popUp.style.left = realLeft + "px";
        } else {
          popUp.style.left = parentLocation.right - popUpLocation.width + "px";
        }
        popUp.style.opacity = "1";
      }
      break;
    case "bottom":
      var diff = (parentLocation.width - popUpLocation.width) / 2;
      var top = realTop + parentLocation.height + 10;
      var left = realLeft + diff;
      if (checkPosition(parent, popUp, position, popUpLocation, top, left)) {
        popUp.style.top = top + "px";
        if (realLeft <= window.innerWidth / 2) {
          popUp.style.left = realLeft + "px";
        } else {
          popUp.style.left = parentLocation.right - popUpLocation.width + "px";
        }
        popUp.style.opacity = "1";
      } else {
        showDefault();
      }
      break;
    case "left":
      var diff = (parentLocation.height - popUpLocation.height) / 2;
      var top = realTop + diff;
      var left = realLeft - popUpLocation.width - 10;
      if (checkPosition(parent, popUp, position, popUpLocation, top, left)) {
        popUp.style.top = top + "px";
        popUp.style.left = left + "px";
        popUp.style.opacity = "1";
      }
      break;
    case "right":
      var diff = (parentLocation.height - popUpLocation.height) / 2;
      var top = realTop + diff;
      var left = realLeft + parentLocation.width + 10;
      if (checkPosition(parent, popUp, position, popUpLocation, top, left)) {
        popUp.style.top = top + "px";
        popUp.style.left = left + "px";
        popUp.style.opacity = "1";
      }
      break;
    case "onto":
      popUp.style.top = realTop + "px";
      popUp.style.left = realLeft + "px";
      popUp.style.width = parentLocation.width - 16 + "px";
      popUp.style.opacity = "1";
      break;
  }
};

const checkPosition = (
  parent: HTMLButtonElement | HTMLDivElement | SVGAElement | SVGSVGElement | HTMLAnchorElement,
  popUp: HTMLDivElement,
  position: PopUpPosition,
  popUpRect: DOMRect,
  top: number,
  left: number
): boolean => {
  if (position === "left" && left < 0) {
    setPopUpPosition(parent, popUp, "bottom", false);
    return false;
  } else if (position === "top" && top < 0) {
    setPopUpPosition(parent, popUp, "bottom", false);
    return false;
  } else if (position === "right" && left + popUpRect.width > window.outerWidth) {
    setPopUpPosition(parent, popUp, "bottom", false);
    return false;
  } else if (position === "bottom" && top + popUpRect.height > window.innerHeight) {
    return false;
  }
  return true;
};
