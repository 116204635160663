import React from "react";
import { Sale } from "models/new/Sale";
import SalesItemDetailsComponent from "./SalesItemDetailsComponent";
import ProductSummaryOrderComponent from "components/ProductSummaryOrderComponent";

const SalesItemComponent: React.FC<{ sale?: Sale }> = ({ sale }) => {
  if (!sale) return <></>;
  return (
      <div className="frame-scroll">
        <div className="d-flex jc-center gap-8 width-fill">
          <SalesItemDetailsComponent sale={sale} />
          <ProductSummaryOrderComponent products={sale.products} deliveryCost={sale.deliveryCost} ratings={sale.productRatings}/>
        </div>
      </div>
  );
};

export default SalesItemComponent;
