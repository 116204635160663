import React from "react";
import { Order } from "models/new/Order";
import OrdersItemDetailsComponent from "./OrdersItemDetailsComponent";
import ProductSummaryOrderComponent from "components/ProductSummaryOrderComponent";

const OrdersItemComponent: React.FC<{ order?: Order }> = ({ order }) => {
  if (!order) return <></>;
  return (
    <div className="frame-scroll">
      <div className="d-flex jc-center gap-8 width-fill">
        <OrdersItemDetailsComponent order={order} />
        <ProductSummaryOrderComponent products={order.products} deliveryCost={order.deliveryCost} ratings={order.productRatings} />
      </div>
    </div>
  );
};

export default OrdersItemComponent;
