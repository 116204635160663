import { createAction, createReducer } from "@reduxjs/toolkit";
import { ProductState, ProductValideError } from "../models/new/State";
import { ProductInfo } from "models/new/ProductInfo";
import { Picture } from "models/new/Picture";

const initalValideError: ProductValideError = {
  name: "",
  availableType: "",
  whenAvailable: "",
  unit: "",
  price: "",
  salePrice: "",
  quantity: "",
  deliveryOption: "",
  productPictures: "",
  description: "",
  category: "",
};

const initialState = { products: [], searchName: "", valideError: initalValideError } as ProductState;

export const selectedProduct = createAction<ProductInfo | undefined>("product/selectedProduct");
export const updateProduct = createAction<{
  product: Partial<ProductInfo> | undefined;
  errors?: Partial<ProductValideError>;
}>("product/updateProduct");
export const deleteProduct = createAction<ProductInfo>("product/deleteProduct");

export const setValideErrorProduct = createAction<ProductValideError>("product/setValideErrorProduct");
export const cleanValideErrorProduct = createAction("product/cleanValideErrorProduct");

export const setProductPictures = createAction<Picture[]>("product/setProductPictures");

export const productReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(selectedProduct, (state, action) => {
      state.selected = action.payload;
      if (!action.payload) {
        state.valideError = initalValideError;
      }
    })
    .addCase(updateProduct, (state, action) => {
      state.selected = { ...state.selected!, ...action.payload.product };
      state.valideError = { ...state.valideError!, ...action.payload.errors };
    })
    .addCase(setValideErrorProduct, (state, action) => {
      state.valideError = action.payload;
    })
    .addCase(cleanValideErrorProduct, (state, action) => {
      state.valideError = initalValideError;
    })
    .addCase(setProductPictures, (state, action) => {
      if (state.selected) {
        state.selected = { ...state.selected!, pictures: action.payload };
      }
    });
});
