import React from "react";
import { Label } from "controls/Label";
import { useDispatch, useSelector } from "react-redux";
import { BasketState, UserState } from "models/new/State";
import { InputText } from "controls/InputText";
import { setSummaryBasketAddress } from "reducers/basketReducer";
import { useWindowSize } from "hooks/useWindowSize";

const BasketSummaryPersonalDataComponent: React.FC = () => {
  const { isMobile } = useWindowSize();
  const basketState: BasketState = useSelector((state: any) => state.basket);
  const userState: UserState = useSelector((state: any) => state.user);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (basketState.summary && userState.user) {
      dispatch(
        setSummaryBasketAddress({
          address: {
            email: userState.user?.email,
            phoneNumber: userState.user?.phoneNumber,
            firstName: userState.user?.firstName,
            lastName: userState.user?.lastName,
          },
        })
      );
    }
  }, [userState.user]);

  if (isMobile) {
    return (
      <div id="details" className="d-flex fd-c width-fill pad-b-32 gap-16 border-bottom">
        <Label fontStyle="bold" wrap content={"Dane osobowe"} />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Imię"
          placeholder="Wprowadź imię"
          value={basketState.summary?.address?.firstName}
          error={basketState.valideError?.firstName}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { firstName: e.target.value }, errors: { firstName: "" } }))
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Nazwisko"
          placeholder="Wprowadź nazwisko"
          value={basketState.summary?.address?.lastName}
          error={basketState.valideError?.lastName}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { lastName: e.target.value }, errors: { lastName: "" } }))
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Numer telefonu"
          placeholder="Wprowadź numer telefonu"
          maxLength={9}
          value={basketState.summary?.address?.phoneNumber}
          error={basketState.valideError?.phoneNumber}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { phoneNumber: e.target.value }, errors: { phoneNumber: "" } }))
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Email"
          placeholder="Wprowadź email"
          value={basketState.summary?.address?.email}
          error={basketState.valideError?.email}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { email: e.target.value }, errors: { email: "" } }))
          }
        />
      </div>
    );
  }

  return (
    <div className="d-flex fd-c width-fill pad-16 gap-16 border-bottom">
      <Label fontSize="lg" fontStyle="bold" wrap content={"Dane osobowe"} />
      <div className="d-flex width-fill gap-16">
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Imię"
          placeholder="Wprowadź imię"
          value={basketState.summary?.address?.firstName}
          error={basketState.valideError?.firstName}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { firstName: e.target.value }, errors: { firstName: "" } }))
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Nazwisko"
          placeholder="Wprowadź nazwisko"
          value={basketState.summary?.address?.lastName}
          error={basketState.valideError?.lastName}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { lastName: e.target.value }, errors: { lastName: "" } }))
          }
        />
      </div>
      <div className="d-flex width-fill gap-16">
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Numer telefonu"
          placeholder="Wprowadź numer telefonu"
          value={basketState.summary?.address?.phoneNumber}
          error={basketState.valideError?.phoneNumber}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { phoneNumber: e.target.value }, errors: { phoneNumber: "" } }))
          }
        />
        <InputText
          frameClassName="width-fill"
          sizeFrame="sm"
          kind="tertiary"
          label="Email"
          placeholder="Wprowadź email"
          value={basketState.summary?.address?.email}
          error={basketState.valideError?.email}
          onChange={(e) =>
            dispatch(setSummaryBasketAddress({ address: { email: e.target.value }, errors: { email: "" } }))
          }
        />
      </div>
    </div>
  );
};

export default BasketSummaryPersonalDataComponent;
