import React from "react";
import { UserState } from "models/new/State";
import { useNavigation } from "hooks/useNavigation";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

const MenuRoutesComponent: React.FC = () => {
  const { routeStack } = useNavigation();
  const userState: UserState = useSelector((state: any) => state.user);

  return (
    <>
      {userState.token !== undefined ? (
        <Routes>
          {routeStack
            .filter((x) => x.isLogin && (x.view === userState.view || x.view === "both"))
            .map((route) => {
              return <Route key={`${route.id}_${route.isLogin}`} path={route.path} element={route.element} />;
            })}
        </Routes>
      ) : (
        <Routes>
          {routeStack
            .filter((x) => !x.isLogin && (x.view === userState.view || x.view === "both"))
            .map((route) => {
              return <Route key={`${route.id}_${route.isLogin}`} path={route.path} element={route.element} />;
            })}
        </Routes>
      )}
    </>
  );
};

export default MenuRoutesComponent;
