import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "controls/Button";
import { Label } from "controls/Label";
import { GroceryTabProps } from "./GroceryManagerComponent";
import RatingCommentComponent from "components/RatingCommentComponent";
import { useSelector } from "react-redux";
import { ProductState } from "models/new/State";
import RatingStarComponent from "components/RatingStarComponent";
import RatingComponent from "components/RatingComponent";
import EmptyComponent from "components/EmptyComponent";
import { useWindowSize } from "hooks/useWindowSize";

const GroceryRateComponent: React.FC<GroceryTabProps> = (props) => {
  const [selectedRate, setSelectedRate] = React.useState<number>();
  const [seeMore, setSeeMore] = React.useState<boolean>(false);
  const productState: ProductState = useSelector((state: any) => state.product);
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div
        id={props.id}
        className="d-flex fd-c pad-t-16 pad-b-16 gap-32 border-bottom width-fill"
        style={{ maxWidth: "500px" }}
      >
        <Label fontStyle="bold" fontSize="md" content="Oceny i komentarze" />
        {productState.selected?.ratings?.length === 0 ? (
          <EmptyComponent message="Brak ocen i komentarzy" icon={<Icon.CommentSVG />} />
        ) : (
          <>
            <div id={props.id} className="d-flex fd-c gap-8 width-fill">
              <RatingStarComponent
                ratings={productState.selected?.ratings}
                selectedRate={selectedRate}
                setSelectedRate={setSelectedRate}
              />
              <RatingCommentComponent ratings={productState.selected?.ratings} />
            </div>
            <div className="f-scroll-hidden-r gap-8" style={{ maxHeight: "500px"}}>
              {productState.selected?.ratings
                ?.filter((x) => !selectedRate || x.rate === selectedRate)
                .map((rating, idx) => {
                  return seeMore ? (
                    <RatingComponent rating={rating} />
                  ) : idx < 1 ? (
                    <RatingComponent rating={rating} />
                  ) : null;
                })}
            </div>
            <Button
              sizeFrame="sml"
              kind="tertiary"
              label={seeMore ? "Mniej" : "Wiecej"}
              onClick={() => setSeeMore(!seeMore)}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div
      id={props.id}
      className="d-flex fd-c pad-16 pad-b-32 mag-16 gap-32 border-bottom width-fill"
      style={{ maxWidth: "500px" }}
    >
      <Label fontStyle="bold" fontSize="xl" content="Oceny i komentarze" />
      {productState.selected?.ratings?.length === 0 ? (
        <EmptyComponent message="Brak ocen i komentarzy" icon={<Icon.CommentSVG />} />
      ) : (
        <>
          <div id={props.id} className="d-flex fd-c gap-8 width-fill">
            <RatingStarComponent
              ratings={productState.selected?.ratings}
              selectedRate={selectedRate}
              setSelectedRate={setSelectedRate}
            />
            <RatingCommentComponent ratings={productState.selected?.ratings} />
          </div>
          <div className="frame-scroll gap-8" style={{ width: "516px", maxHeight: "500px", paddingRight: "8px" }}>
            {productState.selected?.ratings
              ?.filter((x) => !selectedRate || x.rate === selectedRate)
              .map((rating, idx) => {
                return seeMore ? (
                  <RatingComponent rating={rating} />
                ) : idx < 1 ? (
                  <RatingComponent rating={rating} />
                ) : null;
              })}
          </div>
          <Button
            sizeFrame="sml"
            kind="tertiary"
            label={seeMore ? "Mniej" : "Wiecej"}
            onClick={() => setSeeMore(!seeMore)}
          />
        </>
      )}
    </div>
  );
};

export default GroceryRateComponent;
