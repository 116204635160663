import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";

const LandingVerificationComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <div className="d-flex fd-c ai-center" style={{ gap: "64px", marginTop: "64px" }}>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-l" src="/img/client_map.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-16 mag-r-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Wybieraj lokalnie!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Korzystając z wygodnej mapy, przeglądaj gospodarstwa najbliżej Ciebie, mijane w drodze do pracy lub na trasie planowanej wakacyjnej wyprawy."
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-r" src="/img/client_order3.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-4 mag-r-16" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Wyszukuj produkty! " />
              <Label
                wrap
                fontStyle="semibold"
                content="Poszukujesz specyficznych warzyw lub owoców? A może masz długą listę zakupów i chcesz zrealizować je w jednym miejscu? Z pomocą naszej wyszukiwarki znajdziesz sprzedających którzy najbardziej odpowiadają Twoim potrzebom."
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-l" src="/img/client_pause.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-16 mag-r-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Jedz zdrowo!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Obawiasz się, że kupowane przez Ciebie BIO produkty wcale nie są tak naturalne i dobre jakościowo? Przeglądaj certyfikaty i opinie sprzedających, aby upewnić się, że kupujesz ze sprawdzonego źródła!"
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-r" src="/img/client_posts.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-4 mag-r-16" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Bądź na bieżąco!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Obserwuj ulubionych sprzedawców i kontaktuj się z nimi, aby zawsze mieć dostęp do najświeższych produktów, prosto ze zbiorów."
              />
            </div>
          </InfoBox>
        </div>
        <div className="d-flex fd-c ai-center">
          <img className="l-img-box-l" src="/img/client_order.jpg" style={{ marginBottom: "-64px" }} />
          <InfoBox className="width-fill mag-l-16 mag-r-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label fontSize="xl" fontStyle="extra-bold" wrap content="Kupuj wygodnie!" />
              <Label
                wrap
                fontStyle="semibold"
                content="Złóż zamówienie bezpośrednio na naszym portalu, wybierz dogodny termin i lokalizację odbioru zamówienia i ciesz się naturalnymi produktami dla Ciebie i Twojej rodziny!"
              />
            </div>
          </InfoBox>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex fd-c ai-center" style={{ maxWidth: "1136px", gap: "64px", marginTop: "64px" }}>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-l" src="/img/client_map.jpg" height={400} />
        </div>
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Wybieraj lokalnie!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Korzystając z wygodnej mapy, przeglądaj gospodarstwa najbliżej Ciebie, mijane w drodze do pracy lub na trasie planowanej wakacyjnej wyprawy."
          />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Wyszukuj produkty! " />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Poszukujesz specyficznych warzyw lub owoców? A może masz długą listę zakupów i chcesz zrealizować je w jednym miejscu? Z pomocą naszej wyszukiwarki znajdziesz sprzedających którzy najbardziej odpowiadają Twoim potrzebom."
          />
        </div>
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-r" src="/img/client_order3.jpg" height={400} />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-l" src="/img/client_pause.jpg" height={400} />
        </div>
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Jedz zdrowo!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Obawiasz się, że kupowane przez Ciebie BIO produkty wcale nie są tak naturalne i dobre jakościowo? Przeglądaj certyfikaty i opinie sprzedających, aby upewnić się, że kupujesz ze sprawdzonego źródła!"
          />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Bądź na bieżąco!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Obserwuj ulubionych sprzedawców i kontaktuj się z nimi, aby zawsze mieć dostęp do najświeższych produktów, prosto ze zbiorów."
          />
        </div>
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-r" src="/img/client_posts.jpg" height={400} />
        </div>
      </div>
      <div className="d-flex gap-32">
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-l" src="/img/client_order.jpg" height={400} />
        </div>
        <div className="d-flex fd-c gap-32 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label fontSize="xxl" fontStyle="extra-bold" wrap content="Kupuj wygodnie!" />
          <Label
            wrap
            fontStyle="semibold"
            style={{ fontSize: "20px" }}
            content="Złóż zamówienie bezpośrednio na naszym portalu, wybierz dogodny termin, lokalizację odbioru zamówienia i ciesz się naturalnymi produktami dla Ciebie i Twojej rodziny!"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingVerificationComponent;
