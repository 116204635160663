import React from "react";
import InfoBox from "controls/InfoBox";
import { Label } from "controls/Label";
import { Address } from "models/new/Address";
import SearchAddressComponent from "./SearchAddressComponent";

const SearchLocationComponent: React.FC<{ addresses: Address[] }> = ({ addresses }) => {
  return (
    <div className="width-fill">
      <InfoBox className="width-fill" backgroundColor="secondary" sizeFrame="sm">
        <div className="d-flex fd-c width-fill gap-16">
          <Label fontSize="lg" fontStyle="semibold" content="Punkty odbioru" />
          {[...addresses]
            .sort((a, b) => Number(b.isMainAddress) - Number(a.isMainAddress))
            .map((adres) => {
              return <SearchAddressComponent address={adres} />;
            })}
        </div>
      </InfoBox>
    </div>
  );
};

export default SearchLocationComponent;
