import React from "react";
import { Label } from "controls/Label";
import * as Icon from "commons/iconManager";

export const LazyLoadComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisible(true);
        observer.disconnect();
      }
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return (
    <div className="width-fill" ref={ref}>
      {isVisible ? <>{children}</> : <PlaceholderComponent />}
    </div>
  );
};

const Placeholder = () => (
  <div className="width-fill d-flex ai-center jc-center gap-16" style={{ height: "100px" }}>
    <Icon.LoadingSVG height={50} width={50} />
    <Label fontSize="md" fontStyle="semibold" wrap content="Wczytywanie..." />
  </div>
);

const PlaceholderComponent = () => (
  <div className={`wait-on-loading width-fill rounded-12`} style={{ minHeight: "115px", marginTop: '8px', marginRight: '4px' }}></div>
);