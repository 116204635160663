import React from "react";
import * as Icon from "commons/iconManager";
import { Button } from "./Button";
import { PopUp } from "./PopUp";
import { InputTextProps, getFontFamily, getKind, getSize, getSizeForIcon } from "./InputText";
import { Label } from "./Label";
import { useWindowSize } from "hooks/useWindowSize";

export interface InputComboBoxProps extends InputTextProps, React.InputHTMLAttributes<HTMLInputElement> {
  itemSource: any[] | JSX.Element;
  selectedItem?: any;
  displayMember: string;
  canClear?: boolean;
  onSelectionChanged?: (item: any) => void;
  onDisplayContent?: (item: any, selected?: any) => JSX.Element;
}

export const InputComboBox = React.forwardRef<HTMLInputElement, InputComboBoxProps>((props, ref) => {
  const [focused, setFocused] = React.useState<boolean>(false);
  const [isExpand, setIsExpand] = React.useState<boolean>(false);
  const [itemSource, setItemSource] = React.useState<any[] | JSX.Element>(props.itemSource);
  const [selectedItem, setSelectedItem] = React.useState<any>(props.selectedItem);
  const { isMobile } = useWindowSize();

  const divRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useImperativeHandle(ref, () => inputRef.current);

  React.useEffect(() => {
    setItemSource(props.itemSource);
  }, [props.itemSource]);

  React.useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  const getStyle = (): string => {
    let style: string = getKind(props.kind);
    style += getSize(props.sizeFrame);
    style += getFontFamily(props.family);

    if (focused) {
      style += " input-focused";
    }
    if (props.error) {
      style += " mag-b-16";
    }
    if (props.label) {
      style += " mag-t-16";
    }
    if (props.error) {
      style += " input-error";
    }

    return `${style} ${props.frameClassName}`;
  };

  let clonedIcon;
  if (props.icon) {
    const size = getSizeForIcon(props.sizeFrame);
    clonedIcon = React.cloneElement(props.icon, {
      className: props.icon.props.className ?? "icon-on-input",
      style: {
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
      },
    });
  }
  const onFocus = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onFocus?.(evt);
    setFocused(true);
  };
  const onBlur = (evt?: React.FocusEvent<HTMLInputElement>) => {
    evt && props.onBlur?.(evt);
    setFocused(false);
  };

  const onClearSelection = () => {
    setSelectedItem(undefined);
    props.onSelectionChanged?.(undefined);
  };
  const onClickItem = (item: any) => {
    setSelectedItem(item);
    props.onSelectionChanged?.(item);
    setIsExpand(false);
    PopUp.onHide();
  };

  const getList = (): JSX.Element => {
    if (Array.isArray(itemSource)) {
      return (
        <div className="popup-list">
          {itemSource.map((item) => {
            if (props.onDisplayContent) {
              const box = props.onDisplayContent(item, selectedItem);
              const enhancedBox = React.cloneElement(box, {
                onClick: (event) => {
                  if (box.props.onClick) {
                    box.props.onClick(event);
                  }
                  onClickItem(item);
                },
              });
              return enhancedBox;
            }
            return (
              <Button
                style={{ width: "100%", marginBlock: "2px" }}
                sizeFrame={isMobile ? "lg" :"sm"}
                kind={selectedItem === item ? "menu-seleceted" : "menu"}
                label={item[props.displayMember]}
                onClick={() => onClickItem(item)}
              />
            );
          })}
        </div>
      );
    }
    return itemSource;
  };
  const showPopUp = async (expand: boolean) => {
    inputRef.current.blur();
    if (expand) {
      await PopUp.show(divRef.current, getList(), "bottom", 150, () => setIsExpand(false));
    }
    setIsExpand(expand);
  };

  function onChangeValue(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!PopUp.isVisible) {
      showPopUp(true);
    }
  }

  return (
    <div ref={divRef} id={props.frameId} className={getStyle()} style={{ ...props.frameStyle }}>
      {clonedIcon}
      <div className="title d-flex gap-4 ai-center">
        <Label content={props.label} fontSize="sml" fontStyle="semibold" />
        {props.isRequired && <Label content="*" fontColor="red" fontSize="sml" fontStyle="bold" />}
      </div>
      <input
        {...props}
        style={{...props.style, cursor: 'pointer'}}
        ref={inputRef}
        className={`${getFontFamily()}`}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}              
        value={selectedItem?.[props.displayMember] ?? ""}
        onChange={onChangeValue}
        onClick={() => showPopUp(!isExpand)}
      />
      {props.canClear !== false && selectedItem && props.disabled !== true && (
        <Button
          tabIndex={-1}
          style={{ padding: "4px" }}
          sizeFrame="sml"
          kind="text"
          icon={<Icon.CloseSVG className="close" />}
          onClick={onClearSelection}
        />
      )}
      <Button
        tabIndex={-1}
        style={{ padding: "4px" }}
        sizeFrame="sml"
        kind="text"
        icon={<Icon.ExpandMoreSVG className={`icon-expand ${isExpand && "icon-expanded"}`} />}
        disabled={props.disabled}
        onClick={() => showPopUp(!isExpand)}
      />
      <label className="error">{props.error}</label>
    </div>
  );
});
