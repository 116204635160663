import { sortArrayDesc } from "commons/arrayManager";
import { StatusOrder } from "./Dictionaries";

export interface OrderStatus {
  id?: string;
  orderId?: string;
  appUserId: string;
  status: StatusOrder;
  createdAt?: string;
}

export function getLastStatus(statuses: OrderStatus[]): StatusOrder {
  if (statuses.length === 0) return StatusOrder.New;
  const sortedStatuses = [...statuses].sort((a, b) => sortArrayDesc(a, b, "createdAt"));
  return sortedStatuses[0].status;
}
