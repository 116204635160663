import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "commons/iconManager";
import { changeTheme } from "../reducers/mainReducer";
import { MainState, UserState } from "models/new/State";
import { Button } from "controls/Button";

const ThemeModeComponent = () => {
  const mainState: MainState = useSelector((state: any) => state.main);
  const userState: UserState = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const changeThemeStyle = useCallback(
    (theme: string | null) => {
      const themes = ["dark", "light", "dark-supplier", "light-supplier"];

      const themee = `${theme}${userState.view === "client" ? "" : "-supplier"}`;
      // Usuwanie wszystkich klas tematycznych
      themes.forEach((t) => document.body.classList.remove(t));

      if (theme && themes.includes(theme)) {
        document.body.classList.add(themee);
        localStorage.setItem("theme", theme);
        dispatch(changeTheme(theme));
      } else {
        // Domyślnie ustawiamy light, jeśli temat jest null lub nieznany
        document.body.classList.add("light");
        localStorage.setItem("theme", "light");
        dispatch(changeTheme("light"));
      }
    },
    [userState.view]
  );

  useEffect(() => {
    changeThemeStyle(localStorage.getItem("theme"));

    return () => {
      changeThemeStyle(localStorage.getItem("theme"));
    };
  }, [dispatch]);

  useEffect(() => {
    changeThemeStyle(mainState?.theme);
  }, [userState.view]);

  return (
    <Button
      label="Motyw"
      sizeFrame="lg"
      icon={mainState?.theme === "dark" ? <Icon.LightModeSVG /> : <Icon.DarkModeSVG />}
      kind={"menu"}
      onClick={() => changeThemeStyle(mainState?.theme === "dark" ? "light" : "dark")}
    />
  );
};

export default ThemeModeComponent;
