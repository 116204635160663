import { createAction, createReducer } from "@reduxjs/toolkit";
import { SubscriptionValideError, SubscriptionWizardState } from "../models/new/State";
import { Address } from "models/new/Address";

export const initialError: SubscriptionValideError = {
  subscriptionType: undefined,
  firstName: undefined,
  lastName: undefined,
  companyName: undefined,
  phoneNumber: undefined,
  email: undefined,
  description: undefined,
  logoUrl: undefined,
  pictures: undefined,
  address: undefined,
  addressCity: undefined,
  addressPostalCode: undefined,
  addressBuildingNumber: undefined,
  certificate: undefined,
  latitude: undefined,
  longitude: undefined,
  openHour: undefined,
  policy: undefined,
  regulations: undefined,
};

const initialState = {
  valideError: initialError,
} as SubscriptionWizardState;

export const changeSubscriptionWizard = createAction<Partial<SubscriptionWizardState>>(
  "subscriptionwizard/changeSubscriptionWizard"
);
export const changeSubscriptionAddressWizard = createAction<{
  address: Partial<Address>;
  error?: Partial<SubscriptionValideError>;
}>("subscriptionwizard/changeSubscriptionAddressWizard");

export const setSubscriptionValideError = createAction<Partial<SubscriptionValideError>>(
  "subscriptionwizard/setSubscriptionValideError"
);

export const subscriptionWizardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeSubscriptionWizard, (state, action) => {
      Object.assign(state, action.payload);
    })
    .addCase(changeSubscriptionAddressWizard, (state, action) => {
      state.address = { ...state.address, ...action.payload.address };
      state.valideError = { ...state.valideError, ...action.payload.error };
    })
    .addCase(setSubscriptionValideError, (state, action) => {
      state.valideError = { ...state.valideError, ...action.payload };
    });
});
