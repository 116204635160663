import InfoBox from "controls/InfoBox";
import * as Icon from "commons/iconManager";
import { Label } from "controls/Label";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { Button } from "controls/Button";

const LandingMainDealerInfoComponent: React.FC = () => {
  const { isMobile } = useWindowSize();

  const showInfo = (title: string, info: string) => {
    return (
      <div className="d-flex gap-8">
        <Icon.CheckCircleFillSVG className="c-green" style={{ minHeight: "24px", minWidth: "24px" }} />
        <div className="d-flex fd-c">
          <Label wrap fontColor="black" fontSize="lg" fontStyle="bold" content={title} />
          <Label wrap fontColor="black" fontSize="sm" fontStyle="semibold" content={info} />
        </div>
      </div>
    );
  };

  const goToJoin = () => {
    const cta = document.getElementById("cta-landing");
    cta && cta.scrollIntoView({ behavior: "smooth" });
  };

  if (isMobile) {
    return (
      <div className="d-flex fd-c ai-center gap-16 pad-4">
        <div className="d-flex fd-c gap-32">
          <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
            <img className="l-img-box-cta" src="/img/supplier_care.jpg" style={{ marginBottom: "-64px" }} />
          </div>
          <InfoBox className="width-fill mag-4" backgroundColor="secondary">
            <div className="d-flex fd-c gap-4 jc-center pad-8">
              <Label
                fontSize="xl"
                fontStyle="extra-bold"
                wrap
                content={`Wybieraj lokalnie, 
jedz naturalnie!`}
              />
              <Label
                wrap
                fontStyle="semibold"
                content={`Prowadzisz gospodarstwo rolne, hodowlę zwierząt lub wytwarzasz własne produkty spożywcze? Załóż konto na naszym portalu i zyskaj dostęp do miłośników zdrowej żywności, szukających lokalnych produktów!`}
              />
              <Button
                className="mag-t-16 width-fill"
                sizeFrame="lg"
                kind="primary"
                fontStyle="bold"
                fontSize="lg"
                label="Dołącz do nas!"
                onClick={goToJoin}
              />
            </div>
          </InfoBox>
        </div>
        <Label
          wrap
          fontStyle="extra-bold"
          fontSize="xl"
          style={{ textAlign: "center" }}
          content="Dołącz do lokalnych sprzedawców!"
        />
      </div>
    );
  }

  return (
    <div className="d-flex fd-c gap-32 ai-center" style={{ maxWidth: "1136px" }}>
      <div className="d-flex gap-32" style={{ maxWidth: "1136px", marginBlock: "128px" }}>
        <div className="d-flex fd-c gap-16 jc-center" style={{ maxWidth: "663px", height: "auto" }}>
          <Label
            fontSize="xxl"
            fontStyle="extra-bold"
            wrap
            content={`Wybieraj lokalnie, 
jedz naturalnie!`}
          />
          <Label
            fontSize="lg"
            wrap
            fontStyle="semibold"
            content={`Prowadzisz gospodarstwo rolne, hodowlę zwierząt lub wytwarzasz własne produkty spożywcze? Załóż konto na naszym portalu i zyskaj dostęp do miłośników zdrowej żywności, szukających lokalnych produktów!`}
          />
          <Button
            className="mag-t-16"
            sizeFrame="lg"
            kind="primary"
            fontStyle="bold"
            fontSize="lg"
            label="Dołącz do nas!"
            onClick={goToJoin}
          />
        </div>
        <div className="d-flex fd-c" style={{ maxWidth: "663px" }}>
          <img className="l-img-box-cta" src="/img/supplier_care.jpg" />
        </div>
      </div>
      <Label wrap fontStyle="extra-bold" fontSize="xxl" content="Dołącz do lokalnych sprzedawców!" />
    </div>
  );
};

export default LandingMainDealerInfoComponent;

/*
 <InfoBox sizeFrame="md" icon="Information" iconSize="sm" className="width-fill" style={{ borderRadius: "35px" }}>
        <div className="d-flex fd-c gap-16">
          <Label
            wrap
            fontColor="black"
            fontStyle="semibold"
            fontSize="lg"
            content="Odkryj korzyści płynące z dołączenia do naszej aplikacji i zwiększ swoje możliwości sprzedażowe!"
          />
          <Label wrap fontColor="black" fontStyle="extra-bold" fontSize="xl" content="Dlaczego warto?" />
          {showInfo(
            "Bezpośredni dostęp do klientów",
            "Osiągnij szersze grono odbiorców, którzy poszukują świeżych, lokalnych produktów bez pośredników."
          )}
          {showInfo(
            "Zwiększenie sprzedaży",
            "Zyskaj dostęp do nowych rynków i zwiększ swoje przychody dzięki naszej szerokiej bazie użytkowników."
          )}
          {showInfo("Promocja Twojej marki", "Zbuduj rozpoznawalność i promuj swoje produkty w lokalnej społeczności.")}
          {showInfo(
            "Elastyczność sprzedaży",
            "Dostosuj swoją ofertę w czasie rzeczywistym, w zależności od sezonu i dostępności produktów."
          )}
          {showInfo("Budowanie relacji", "Twórz trwałe relacje z klientami i zwiększaj ich lojalność.")}
          {showInfo(
            "Oszczędność czasu",
            "Automatyzuj procesy sprzedażowe, takie jak obsługa zamówień i komunikacja, aby skupić się na tym, co naprawdę ważne."
          )}
          {showInfo(
            "Lepsze zarządzanie produkcją",
            "Planowanie produkcji w oparciu o realne dane minimalizuje marnotrawstwo i zwiększa efektywność."
          )}
          <Label
            wrap
            fontColor="black"
            fontStyle="extra-bold"
            fontSize="lg"
            content={`Dołącz do naszej aplikacji już dziś i wykorzystaj pełny potencjał swojej działalności, wspierając przy tym lokalną społeczność. Razem możemy tworzyć silniejszą, bardziej zrównoważoną przyszłość!`}
          />
        </div>
      </InfoBox>

*/
