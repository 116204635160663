import React, { KeyboardEvent } from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import { Label } from "controls/Label";
import { Button } from "controls/Button";
import EmptyComponent from "components/EmptyComponent";
import { commonSearchProduct, productList } from "commons/productManager";
import { useDispatch, useSelector } from "react-redux";
import { changeMainState } from "reducers/mainReducer";
import { changeSearchFilter } from "reducers/searchReducer";
import { SearchState } from "models/new/State";

const SearchMobileProductComponent: React.FC = () => {
  const [itemSource, setItemSource] = React.useState<string[]>([]);
  const [value, setValue] = React.useState<string>();
  const [selected, setSelected] = React.useState<string[]>([]);

  const searchState: SearchState = useSelector((state: any) => state.search);
  const dispatch = useDispatch();
  const selectedItem = React.useRef<string[]>([]);

  React.useEffect(() => {
    setSelected(searchState?.filters?.phrase ?? []);
    selectedItem.current = searchState?.filters?.phrase ?? [];
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes("+") || e.target.value.includes("/")) {
      e.preventDefault();
      return;
    }

    setValue(e.target.value);
    if (e.target.value.length > 2) {
      const search = productList.filter((x) => x.toLowerCase().includes(e.target.value.toLowerCase()));
      setItemSource(search);
    } else {
      setItemSource([]);
    }
  };

  const onDisplayItem = (item: string): React.ReactNode => {
    return (
      <div className="search-box border" onClick={() => addToSelected(item)}>
        <Icon.KeySVG className="icon-search icon-color" />
        <Label fontSize="sm" fontStyle="bold" pointer content={item} />
      </div>
    );
  };

  const addToSelected = (item: string) => {
    if (selectedItem.current.includes(item)) return;

    selectedItem.current.push(item);
    setSelected(structuredClone(selectedItem.current));
  };

  const removeFromSelected = (item: string) => {
    selectedItem.current = selectedItem.current.filter((x) => x !== item);
    setSelected(selectedItem.current);
  };

  const clearSelected = () => {
    selectedItem.current = [];
    setSelected([]);
    setValue(undefined);
  };

  const onCommit = () => {
    dispatch(changeMainState({ showSearch: false }));
    dispatch(changeSearchFilter({ ...searchState.filters, phrase: selected }));
  };

  return (
    <div className="frame-main gap-16 pad-8">
      <InputText
        frameClassName="width-fill"
        sizeFrame="sm"
        kind="tertiary"
        placeholder="Wyszukaj (min. 3 znaki)"
        icon={<Icon.SearchSVG />}
        value={value}
        onChange={(e) => onChange(e)}
      />

      <div className="d-flex fd-c gap-8">
        <div className="d-flex ai-center">
          <Label content="Wybrane" fontStyle="bold" />
          <Button
            label="Wyczyść"
            icon={<Icon.CloseSVG />}
            iconPosition="right"
            sizeFrame="sml"
            kind="tertiary"
            fontStyle="bold"
            style={{ marginLeft: "auto" }}
            onClick={clearSelected}
          />
        </div>
        <div className="d-flex gap-4 f-wrap">
          {selected.map((prod) => {
            return (
              <Button
                sizeFrame="sml"
                label={prod}
                kind="primary"
                iconPosition="right"
                fontStyle="bold"
                icon={<Icon.CloseSVG />}
                onClick={() => removeFromSelected(prod)}
              />
            );
          })}
        </div>
      </div>

      <div className="d-flex fd-c gap-8 height-fill">
        <div className="d-flex fd-c">
          <Label content="Wybierz z listy" fontStyle="bold" />
        </div>
        {itemSource.length === 0 || !value ? (
          <div className="d-flex jc-center">
            <EmptyComponent icon={<Icon.SearchSVG />} message="Brak wyszukiwań" />
          </div>
        ) : (
          <div className="f-scroll jc-stretch gap-4">
            {itemSource.map((item, idx) => {
              if (idx < 4 && value) {
                return onDisplayItem(item);
              }
            })}
          </div>
        )}
      </div>

      <div className="d-flex width-fill fd-c gap-16" style={{ position: 'fixed', bottom: '8px', paddingRight: '8px' }}>
        <Label content="Najczęściej wyszukiwane" fontStyle="bold" />
        <div className="d-flex gap-4" style={{ flexWrap: "wrap" }}>
          {commonSearchProduct.map((prod) => {
            return <Button sizeFrame="sm" label={prod} kind="tertiary" onClick={() => addToSelected(prod)} />;
          })}
        </div>
        <Button
          className="width-fill"
          label="Wyszukaj produkty"
          icon={<Icon.SearchSVG />}
          sizeFrame="md"
          kind="primary"
          fontStyle="bold"
          onClick={onCommit}
        />
      </div>
    </div>
  );
};

export default SearchMobileProductComponent;
