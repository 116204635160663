import React from "react";
import * as UserApi from "api/UserApi";
import * as NotificationApi from "api/NotificationApi";
import { generateGUID } from "commons/stringManager";
import { NotificationType } from "models/new/Dictionaries";
import { Notify } from "models/new/Notify";
import { UserState } from "models/new/State";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification, setNotifications } from "reducers/notificationReducer";
import { NotificationPopUp } from "controls/NotificationPopUp";
import { useWindowSize } from "./useWindowSize";

let notificationList: Notify[] = [];

export const useNotify = () => {
  const userState: UserState = useSelector((state: any) => state.user);
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      downoload();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [userState.user?.id]);

  const downoload = async () => {
    if (userState.user?.id && notificationList.length === 0) {
      await UserApi.getNotifications(userState.user.id).then((values) => {
        notificationList = values;
        dispatch(setNotifications(notificationList));
      });
    }
  };

  const addNotify = (notify: Partial<Notify>) => {
    const newNotify: Notify = {
      id: notify.id ?? generateGUID(),
      isShowed: notify.isShowed ?? false,
      isRead: notify.isRead ?? false,
      title: notify.title ?? "Powiadomienie",
      date: notify.date ?? new Date(),
      content: notify.content ?? "",
      type: notify.type ?? NotificationType.Success,
      showIcon: notify.showIcon ?? true,
      link: notify.link,
    };
    notificationList = [...notificationList, newNotify];
    dispatch(setNotifications(notificationList));
    NotificationPopUp.show(newNotify);

    const soundName = newNotify.type === NotificationType.Error ? "audio-notify-error" : "audio-notify";
    const notifySound = document.getElementById(soundName) as HTMLAudioElement;
    notifySound?.play();
  };

  const updateNotify = (notify: Notify) => {
    notificationList = notificationList.map((x) => (x.id === notify.id ? notify : x));
    dispatch(setNotifications(notificationList));
    NotificationApi.update(notify.id, notify.isRead, notify.isShowed);
  };

  const deleteNotify = (notify: Notify) => {
    notificationList = notificationList.filter((x) => x.id !== notify.id);
    dispatch(setNotifications(notificationList));
    NotificationApi.deleteNotification(notify.id);
  };

  const deleteAllNotify = () => {
    notificationList = [];
    dispatch(clearNotification());
    userState.user?.id && NotificationApi.deleteAll(userState.user.id);
  };

  return { addNotify, updateNotify, deleteNotify, deleteAllNotify };
};
