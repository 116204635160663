import React from "react";
import * as Icon from "../commons/iconManager";
import { Label } from "./Label";
import { Button } from "./Button";
import { calculatePercent, calculateValueByPercent } from "commons/numberManager";

export interface InputRangeProps {
  min: number;
  max: number;
  value: { min?: number; max?: number };
  onChange?: (range: { min: number; max: number }) => void;
}

export const InputRange: React.FC<InputRangeProps> = (props) => {
  const [range, setRange] = React.useState({
    minRange: props.min,
    maxRange: props.max,
  });
  const [displayRange, setDisplayRange] = React.useState<{ min?: number; max?: number }>({
    min: props.min,
    max: props.max,
  });
  const parent = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const minRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const maxRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const thumbRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const isDragging = React.useRef<boolean>(false);
  const isDraggingSide = React.useRef<"left" | "right">("left");

  React.useEffect(() => {
    minRef.current.style.left = "0px";
    thumbRef.current.style.left = "8px";
    maxRef.current.style.right = "0px";
    thumbRef.current.style.right = "8px";
  }, []);

  React.useEffect(() => {
    if (props.value.min || props.value.max) {
      setDisplayRange({ min: props.value.min, max: props.value.max });
      calculateSlider({ min: props.value.min, max: props.value.max });
    } else {
      if (props.min || props.max) {
        setDisplayRange({ min: props.min, max: props.max });
        calculateSlider({ min: props.min, max: props.max });
      }
    }
  }, [props.value]);

  React.useEffect(() => {
    setDisplayRange({ min: range.minRange, max: range.maxRange });
  }, [range]);

  const calculateRange = () => {
    const locParent = parent.current.getBoundingClientRect();
    const locMin = minRef.current.getBoundingClientRect();
    const locMax = maxRef.current.getBoundingClientRect();

    let minValue = 0,
      maxValue = 0;
    const minDistance = locMin.left - locParent.left;
    const maxDistance = locParent.right - locMax.right;

    const minPercent = calculatePercent(locParent.width, minDistance);
    const maxPercent = calculatePercent(locParent.width, locParent.width - maxDistance);
    minValue = calculateValueByPercent(props.max, minPercent);
    maxValue = calculateValueByPercent(props.max, maxPercent);

    setRange({ minRange: minDistance === 0 ? 0 : minValue, maxRange: maxValue });
    props.onChange?.({ min: minDistance === 0 ? 0 : minValue, max: maxValue });
  };

  const calculateSlider = (value: { min?: number; max?: number }) => {
    if (isDragging.current) return;
    const locParent = parent.current.getBoundingClientRect();

    if (value.min != undefined) {
      const locMin = minRef.current.getBoundingClientRect();
      const percent = calculatePercent(props.max, value.min, 10);
      const left = calculateValueByPercent(locParent.width, percent, 10);
      if (percent === 0) minRef.current.style.left = `${left}px`;
      else minRef.current.style.left = `${left - locMin.width / 2}px`;
      thumbRef.current.style.left = `${left + 2}px`;
    }

    if (value.max != undefined) {
      const locMax = maxRef.current.getBoundingClientRect();
      const percent = calculatePercent(props.max, value.max, 10);
      const right = locParent.width - calculateValueByPercent(locParent.width, percent, 10);
      if (percent === 100) maxRef.current.style.right = `${right}px`;
      else maxRef.current.style.right = `${right - locMax.width / 2}px`;
      thumbRef.current.style.right = `${right + 2}px`;
    }
  };
  const onDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
    const isTouchEvent = e.type === "touchmove";

    const clientX = isTouchEvent ? (e as React.TouchEvent).touches[0].clientX : (e as React.MouseEvent).clientX;

    if (!isDragging.current) return;

    const locParent = parent.current.getBoundingClientRect();
    const locMin = minRef.current.getBoundingClientRect();
    const locMax = maxRef.current.getBoundingClientRect();

    if (isDraggingSide.current === "left") {
      let left = clientX - locParent.left;
      if (clientX < locParent.left || left + locParent.left > locParent.right) return;
      if (locMax.left < left + locParent.left) return;
      minRef.current.style.left = `${left}px`;
      thumbRef.current.style.left = `${left + 8}px`;
      setRange({ ...range, minRange: left });
    } else {
      let right = locParent.right - clientX;
      if (clientX > locParent.right || locParent.right - right < locParent.left) return;
      if (locMin.right > locParent.right - right) return;
      maxRef.current.style.right = `${right}px`;
      thumbRef.current.style.right = `${right + 8}px`;
      setRange({ ...range, maxRange: right });
    }

    calculateRange();
  };

  const startDrag = (side: "left" | "right") => {
    isDragging.current = true;
    isDraggingSide.current = side;
  };
  const stopDrag = () => {
    isDragging.current = false;
  };
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onDrag(e);
  };
  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    onDrag(e);
  };

  return (
    <div
      className="d-flex width-fill ai-center fd-c"
      style={{ position: "relative" }}
      onMouseMove={onMouseMove}
      onMouseUp={stopDrag}
      onMouseLeave={stopDrag}
      onTouchMove={onTouchMove}
      onTouchEnd={stopDrag}
    >
      <div className="d-flex width-fill ai-center" style={{ height: "40px", position: "relative" }}>
        <div ref={parent} className="d-flex width-fill range-thumb" />
        <div ref={thumbRef} className="range-thumb-selected" />
        <div
          ref={minRef}
          className="range-pointer"
          onMouseMove={onDrag}
          onMouseDown={() => startDrag("left")}
          onMouseUp={stopDrag}
        >
          <Button
            kind="transparent"
            sizeFrame="sml"
            icon={<Icon.DragHandleSVG className="icon-black" style={{ transform: "rotate(90deg)" }} />}
          />
        </div>
        <div
          ref={maxRef}
          className="range-pointer"
          onMouseMove={onDrag}
          onMouseDown={() => startDrag("right")}
          onMouseUp={stopDrag}
        >
          <Button
            kind="transparent"
            sizeFrame="sml"
            icon={<Icon.DragHandleSVG className="icon-black" style={{ transform: "rotate(90deg)" }} />}
          />
        </div>
      </div>
      <div
        className="d-flex width-fill ai-center jc-sb"
        style={{ paddingLeft: "8px", paddingRight: "8px", position: "relative" }}
      >
        <Label content={displayRange.min?.toString() ?? range.minRange.toString()} fontSize="sm" fontStyle="bold" />
        <Label content={displayRange.max?.toString() ?? range.maxRange.toString()} fontSize="sm" fontStyle="bold" />
      </div>
    </div>
  );
};

/*

const onDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging.current) return;
    const locParent = parent.current.getBoundingClientRect();
    const locMin = minRef.current.getBoundingClientRect();
    const locMax = maxRef.current.getBoundingClientRect();

    if (isDraggingSide.current === "left") {
      let left = e.clientX - locParent.left;
      if (e.clientX < locParent.left || left + locParent.left > locParent.right) return;
      if (locMax.left < left + locParent.left) return;
      minRef.current.style.left = `${left}px`;
      thumbRef.current.style.left = `${left + 8}px`;
      setRange({ ...range, minRange: left });
    } else {
      let right = locParent.right - e.clientX;
      if (e.clientX > locParent.right || locParent.right - right < locParent.left) return;
      if (locMin.right > locParent.right - right) return;
      maxRef.current.style.right = `${right}px`;
      thumbRef.current.style.right = `${right + 8}px`;
      setRange({ ...range, maxRange: right });
    }

    calculateRange();
  };

*/
