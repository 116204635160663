import React from "react";
import * as Icon from "commons/iconManager";
import * as GoogleManager from "commons/googleManager";
import * as FollowerApi from "api/FollowApi";
import * as UserApi from "api/UserApi";
import * as SupplierApi from "api/SupplierApi";
import PictureBoxComponent from "./PictureBoxComponent";
import MessageBox from "controls/MessageBox";
import StarSelectionComponent from "./StarSelectionComponent";
import { Label } from "controls/Label";
import { useNavigation } from "hooks/useNavigation";
import { Button } from "controls/Button";
import { ModalDialog } from "controls/ModalDialog";
import { useDispatch, useSelector } from "react-redux";
import { ConversationState, SearchState, UserState } from "models/new/State";
import { changeMainState, setShoppingBasket } from "reducers/mainReducer";
import { useLogin } from "hooks/useLogin";
import { Supplier } from "models/new/Supplier";
import { addConversation } from "reducers/conversationReducer";
import { generateGUID } from "commons/stringManager";
import { setFollow, setUnfollow } from "reducers/userReducer";
import { SupplierInArea } from "models/new/SupplierInArea";
import { Rating } from "models/new/Rating";
import { useWindowSize } from "hooks/useWindowSize";

const DealerCardComponent: React.FC<{ supplier?: Supplier; rating?: Rating; showIcon?: boolean }> = (props) => {
  const { navigateTo } = useNavigation();
  const { signIn } = useLogin();
  const { isMobile } = useWindowSize();
  const [supplierInfo, setSupplierInfo] = React.useState<SupplierInArea>();
  const userState: UserState = useSelector((state: any) => state.user);
  const searchState: SearchState = useSelector((state: any) => state.search);
  const conversationState: ConversationState = useSelector((state: any) => state.conversation);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.supplier?.id && !props.supplier?.firstName) {
      SupplierApi.getSupplier(props.supplier!.id).then((value) => setSupplierInfo(value));
    }
  }, []);

  const onClickEmail = async () => {
    if (props.supplier?.appUserId === userState.user?.id) return;

    if (!userState.token) await signIn();
    else if (!userState.user?.firstName || !userState.user?.lastName) {
      await ModalDialog.show(<MessageBox content="Uzupełnij swój profil aby skorzystać z tej funkcji." />, "", ["Ok"]);
      navigateTo("profile");
    } else {
      if (props.supplier) {
        const conversation = conversationState.conversations.find((x) =>
          x.chatMembers.some((z) => z.appUserId === props.supplier?.appUserId)
        );
        if (conversation) {
          dispatch(addConversation({ chat: conversation, isOpen: true }));
        } else {
          await UserApi.getUserById(props.supplier.appUserId)
            .then((value) => {
              dispatch(
                addConversation({
                  chat: {
                    id: generateGUID(),
                    chatMembers: [
                      { appUserId: value.id, joinedAt: new Date(), appUser: value },
                      { appUserId: userState.user!.id, joinedAt: new Date(), appUser: userState.user! },
                    ],
                    chatMessages: [],
                    isGroupChat: false,
                    isOpen: true,
                    createdAt: new Date(),
                  },
                  isOpen: true,
                })
              );
            })
            .catch((err) => alert(`DealerCardComponent - ${err}`));
        }
        dispatch(setShoppingBasket(false));
      }
    }
  };

  const onClickPhone = async () => {
    if (!userState.token) await signIn();
    else {
      await ModalDialog.show(
        <MessageBox
          content={`Numer kontaktowy podany przez sprzedawce:
${props.supplier?.phoneNumber}`}
          icon="Information"
        />,
        "Numer kontaktowy",
        ["Ok"]
      );
    }
  };

  const onClickNavigate = async () => {
    if (!userState.token) await signIn();
    else {
      if (!searchState.geoLocation.name) {
        await ModalDialog.show(
          <MessageBox content={`Nie wiemy skąd nawigować. Wskaż swój adres.`} icon="Information" />,
          "Brak adresu",
          ["OkCancel"]
        );
        return;
      }

      GoogleManager.navigate(searchState.geoLocation, {
        lat: props.supplier!.latitude,
        lng: props.supplier!.longitude,
      });
    }
  };

  const onClickFollow = async (isFollow: boolean) => {
    if (!userState.token) await signIn();
    else {
      if (isFollow) {
        FollowerApi.addFollow({ appUserId: userState.user!.id, supplierId: props.supplier!.id });
        dispatch(setFollow(props.supplier!.id));
      } else {
        FollowerApi.deleteFollow(userState.user!.id, props.supplier!.id);
        dispatch(setUnfollow(props.supplier!.id));
      }
    }
  };

  const onProfilClick = () => {
    dispatch(changeMainState({ showShoppingBasket: false }));
    navigateTo("search", `?dealer_id=${props.supplier?.id}`);
  };

  return (
    <div className="d-flex fd-c gap-8">
      <div className="d-flex fd-r ai-center gap-8">
        <PictureBoxComponent className="picture-50 c-pointer" url={props.supplier?.logoUrl} onClick={onProfilClick} />
        <div className="d-flex fd-c gap-4">
          <Label
            className="pad-l-4"
            fontSize="sm"
            fontStyle="semibold"
            fontColor="secondary"
            islink
            wrap
            content={`${props.supplier?.firstName ?? supplierInfo?.firstName ?? ""} ${
              props.supplier?.lastName ?? supplierInfo?.lastName ?? ""
            }`}
            onClick={onProfilClick}
          />
          <Label
            className="pad-l-4"
            fontSize="lg"
            fontStyle="semibold"
            islink
            wrap
            content={props.supplier?.companyName}
            onClick={onProfilClick}
          />
        </div>
      </div>

      <div className="d-flex fd-r gap-4">
        {userState.user?.followes?.some((x) => x.supplierId === props.supplier?.id) ? (
          <Button
            sizeFrame="sml"
            label="Przestań obserwować"
            fontStyle="semibold"
            icon={<Icon.PersonRemoveSVG />}
            onClick={() => onClickFollow(false)}
          />
        ) : (
          <Button
            sizeFrame="sml"
            label="Obserwuj"
            fontStyle="bold"
            kind="primary"
            icon={<Icon.PersonAddSVG />}
            onClick={() => onClickFollow(true)}
          />
        )}
        <Button
          sizeFrame="sml"
          icon={<Icon.MailSVG />}
          toolTip={"Rozpocznij czat"}
          toolTipPosition="top"
          onClick={onClickEmail}
        />
        <Button
          sizeFrame="sml"
          icon={<Icon.CallSVG />}
          toolTip={"Pokaż numer kontaktowy"}
          toolTipPosition="top"
          onClick={onClickPhone}
        />
        <Button
          sizeFrame="sml"
          fontStyle="bold"
          icon={<Icon.NavigationSVG />}
          toolTip={"Nawiguj do sprzedawcy"}
          toolTipPosition="top"
          onClick={onClickNavigate}
        />
        {isMobile && props.showIcon && (
          <Button
            sizeFrame="sml"
            fontStyle="bold"
            icon={<Icon.MapSVG />}
            onClick={() => dispatch(changeMainState({ showDealerOnMap: true }))}
          />
        )}
      </div>
      {props.rating && (
        <div className="d-flex fd-c gap-4">
          <Label
            className="pad-l-4"
            fontSize="sm"
            fontColor="secondary"
            fontStyle="semibold"
            content="Ocena i komentarz"
          />
          <StarSelectionComponent rating={props.rating.rate} hover={false} />
          {props.rating.comment.length > 0 && (
            <Label
              className="border rounded-12 pad-8 width-fill"
              fontSize="sm"
              fontStyle="semibold"
              wrap
              content={props.rating.comment}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DealerCardComponent;
