import React, { KeyboardEvent } from "react";
import * as Icon from "commons/iconManager";
import { InputText } from "controls/InputText";
import { Label } from "controls/Label";
import { Button } from "controls/Button";
import EmptyComponent from "components/EmptyComponent";
import { commonSearchProduct, productList } from "commons/productManager";

interface SearchProps {
  value?: string;
  selected?: string[];
  onSubmit?: (items?: string[]) => void;
}

const SearchBoxProductComponent: React.FC<SearchProps> = (props) => {
  const [itemSource, setItemSource] = React.useState<string[]>([]);
  const [value, setValue] = React.useState<string | undefined>(props.value);
  const [selected, setSelected] = React.useState<string[]>(props.selected ?? []);
  const [checked, setChecked] = React.useState<string>();
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const currentIndex = React.useRef<number>(-1);
  const selectedItem = React.useRef<string[]>([]);
  const checkedItem = React.useRef<string>();

  React.useEffect(() => {
    setSelected(props.selected ?? []);
    selectedItem.current = structuredClone(props.selected) ?? [];
  }, [props.selected]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    inputRef.current.focus();
  }, [inputRef.current]);

  const handleKeyNavigation = React.useCallback(
    (e: KeyboardEvent) => {
      const itemsLength = itemSource.length;

      if (e.key === "ArrowDown") {
        currentIndex.current = (currentIndex.current + 1) % itemsLength;
        if (isNaN(currentIndex.current)) {
          currentIndex.current = -1;
        }
        setChecked(itemSource[currentIndex.current]);
        checkedItem.current = itemSource[currentIndex.current];
        e.preventDefault();
      } else if (e.key === "ArrowUp") {
        currentIndex.current = (currentIndex.current - 1 + itemsLength) % itemsLength;
        if (isNaN(currentIndex.current)) {
          currentIndex.current = -1;
        }
        setChecked(itemSource[currentIndex.current]);
        checkedItem.current = itemSource[currentIndex.current];
        e.preventDefault();
      } else if (e.key === "Enter") {
        if (checkedItem.current) {
          setTimeout(() => {
            props.onSubmit?.([...selected, checkedItem.current ?? ""]);
          }, 200);
        } else if (value && value?.length > 2) {
          setTimeout(() => {
            props.onSubmit?.([...selected, value]);
          }, 200);
        } else {
          setTimeout(() => {
            props.onSubmit?.(selected);
          }, 200);
        }
      } else if (e.key === "+") {
        checkedItem.current && addToSelected(checkedItem.current);
        checkedItem.current = "";
      } else if (e.key === "Delete") {
        clearSelected();
      } else if (e.key === "Escape") {
        setTimeout(() => {
          props.onSubmit?.(selected);
        }, 200);
      }
    },
    [selected, itemSource, props.onSubmit, value]
  );

  React.useEffect(() => {
    const keydownListener = (evt: any) => handleKeyNavigation(evt);

    document.addEventListener("keydown", keydownListener);
    return () => document.removeEventListener("keydown", keydownListener);
  }, [handleKeyNavigation]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes("+") || e.target.value.includes("/")) {
      e.preventDefault();
      return;
    }

    setValue(e.target.value);
    if (e.target.value.length > 2) {
      const search = productList.filter((x) => x.toLowerCase().includes(e.target.value.toLowerCase()));
      setItemSource(search);
    } else {
      setItemSource([]);
    }
  };

  const onDisplayItem = (item: string): React.ReactNode => {
    return (
      <div className={`search-box ${checked === item && "search-box-selected"}`} onClick={() => addToSelected(item)}>
        <Icon.KeySVG className="icon-search icon-color" />
        <Label fontSize="sm" fontStyle="bold" pointer content={item} />
        {checked === item && (
          <div className="l-button" style={{marginLeft: 'auto'}}>
            <Label fontSize="sml" fontStyle="extra-bold" content="+" />
          </div>
        )}
      </div>
    );
  };

  const addToSelected = (item: string) => {
    if (selectedItem.current.includes(item)) return;

    selectedItem.current.push(item);
    setSelected(structuredClone(selectedItem.current));
  };

  const removeFromSelected = (item: string) => {
    selectedItem.current = selectedItem.current.filter((x) => x !== item);
    setSelected(selectedItem.current);
  };

  const clearSelected = () => {
    selectedItem.current = [];
    setSelected([]);
    setValue(undefined);
  };

  return (
    <div className="frame-main gap-16">
      <InputText
        frameClassName="width-fill"
        ref={inputRef}
        sizeFrame="sm"
        kind="tertiary"
        placeholder="Wyszukaj (min. 3 znaki)"
        icon={<Icon.SearchSVG />}
        value={value}
        onChange={(e) => onChange(e)}
      />

      <div className="d-flex fd-c gap-8">
        <div className="d-flex ai-center">
          <Label content="Wybrane" fontStyle="bold" />
          <Button
            label="Wyczyść"
            icon={<Icon.CloseSVG />}
            iconPosition="right"
            sizeFrame="sml"
            kind="tertiary"
            fontStyle="bold"
            style={{ marginLeft: "auto" }}
            onClick={clearSelected}
          />
        </div>
        <div className="d-flex gap-4 f-wrap">
          {selected.map((prod) => {
            return (
              <Button
                sizeFrame="sml"
                label={prod}
                kind="primary"
                iconPosition="right"
                fontStyle="bold"
                icon={<Icon.CloseSVG />}
                onClick={() => removeFromSelected(prod)}
              />
            );
          })}
        </div>
      </div>

      <div className="d-flex fd-c gap-8">
        <div className="d-flex fd-c">
          <Label content="Wybierz z listy" fontStyle="bold" />
          <Label
            content="Przeuwaj strzałkami po liście - użyj + żeby wybrać zaznaczone"
            fontStyle="semibold"
            fontSize="sml"
          />
        </div>
        <div className="frame-scroll jc-stretch gap-4">
          {itemSource.map((item, idx) => {
            if (idx < 4 && value) {
              return onDisplayItem(item);
            }
          })}
        </div>
        {(itemSource.length === 0 || !value) && (
          <div className="d-flex" style={{ height: "236px" }}>
            <EmptyComponent icon={<Icon.SearchSVG />} message="Brak wyszukiwań" />
          </div>
        )}
      </div>

      <div className="d-flex fd-c gap-8">
        <Label content="Najczęściej wyszukiwane" fontStyle="bold" />
        <div className="d-flex gap-4">
          {commonSearchProduct.map((prod) => {
            return <Button sizeFrame="sml" label={prod} kind="tertiary" onClick={() => addToSelected(prod)} />;
          })}
        </div>
      </div>

      <Button
        label="Wyszukaj produkty"
        icon={<Icon.SearchSVG />}
        sizeFrame="sm"
        kind="primary"
        fontStyle="bold"
        style={{ marginLeft: "auto" }}
        onClick={() => props.onSubmit?.(selected)}
      />

      <div className="d-flex gap-8 pad-t-8 border-top">
        <div className="d-flex ai-center gap-4">
          <div className="l-button">
            <Label fontSize="sml" fontStyle="bold" content="delete" />
          </div>
          <Label fontSize="sml" fontStyle="bold" content="Wyczyść" />
        </div>
        <div className="d-flex ai-center gap-8">
          <div className="l-button">
            <Label fontSize="sml" fontStyle="bold" content="▲" />
          </div>
          <div className="l-button">
            <Label fontSize="sml" fontStyle="bold" content="▼" />
          </div>
          <Label fontSize="sml" fontStyle="bold" content="Wybór" />
        </div>
        <div className="d-flex ai-center gap-8">
          <div className="l-button">
            <Label fontSize="sml" fontStyle="extra-bold" content="+" />
          </div>
          <Label fontSize="sml" fontStyle="bold" content="Dodaj" />
        </div>

        <div className="d-flex ai-center gap-8">
          <div className="l-button">
            <Label fontSize="sml" fontStyle="bold" content="enter" />
          </div>
          <Label fontSize="sml" fontStyle="bold" content="Zatwierdź" />
        </div>
      </div>
    </div>
  );
};

export default SearchBoxProductComponent;
