import React from "react";
import { GridColumnProps } from "./GridColumn";
import { Label } from "./Label";

export interface GridRowProps {
  columns: GridColumnProps[];
  item: any;
}

export const GridRow: React.FC<GridRowProps> = (props) => {
  const getStyle = (column: GridColumnProps): string => {
    let style: string = "";
    switch (column.algin) {
      case "start":
        style += " text-align-start";
        break;
      case "center":
        style += " text-align-center";
        break;
      case "end":
        style += " text-align-end";
        break;
      default:
        style += " text-align-start";
        break;
    }
    switch (column.verticalAlign) {
      case "top":
        style += " vertical-align-top";
        break;
      case "middle":
        style += " vertical-align-middle";
        break;
      case "bottom":
        style += " vertical-align-bottom";
        break;
      default:
        style += " vertical-align-middle";
        break;
    }
    return style;
  };

  const getRow = () => {
    if (props.columns.length === 0 || !props.item) return <></>;

    return props.columns?.map((col) => {
      if (col.onDisplayContent) {
        return <td className={getStyle(col)}>{col.onDisplayContent(props.item)}</td>;
      }

      if (!col.binding)
        return (
          <td className={getStyle(col)}>
            <Label content={""} fontSize="sm" fontStyle="semibold" />
          </td>
        );

      let value = props.item?.[col.binding];
      if (value instanceof Date) {
        value = value.toLocaleDateString();
      }
      return (
        <td className={getStyle(col)}>
          <Label content={value ?? ""} fontSize="sm" fontStyle="semibold" />
        </td>
      );
    });
  };

  return <tr>{getRow()}</tr>;
};
